import type { AiFieldStatus, Field, FieldADTValue, FieldCellValue, HighLightConditions, SchemaProtocol } from '@lighthouse/core'
import {
    ApplicationPreviewEnum,
    editableInCellFieldTypes,
    getIsAppointField,
    notEditableFieldTypes,
    SYSTEM_FIELD,
    useApplicationContext,
    ViewFieldStatusPreviewer
} from '@lighthouse/shared'
import isDeepEqual from 'fast-deep-equal'
import type { atomWithImmer } from 'jotai-immer'
import React, { memo, useCallback, useMemo, useRef } from 'react'
import styled from 'styled-components'

import type { AdvancedTableContentState, AdvancedTableTooltipState } from './AdvancedTableContent'

export interface TableCellProps {
    blockId: string
    dsId: string
    recordId: string
    field: Field
    schema: SchemaProtocol['schema']
    data?: FieldCellValue
    width?: number
    styleConditions?: HighLightConditions
    style?: React.CSSProperties
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    hoverCellColor: string
    primaryDataSourceFieldIds?: Set<string>
    previewType?: ApplicationPreviewEnum
    onOpenEditor?: (data: AdvancedTableContentState) => void
    onOpenTooltip?: (data: AdvancedTableTooltipState) => void
    onCellChange?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onAiGeneration?: (fieldId: string) => Promise<boolean>
}

export const SCxTableCell = styled.div<{ hoverCellColor: string }>`
    position: relative;
    min-height: 40px;
    flex-shrink: 0;

    border-right: 1px solid var(--color-gray-200);

    &:hover {
        background: var(--color-white);
        &:before {
            content: '';
            position: absolute;
            width: calc(100%);
            height: calc(100%);
            left: 0px;
            top: 0;
            border: ${({ hoverCellColor }) => `2px solid ${hoverCellColor}`};
            background-color: transparent;
            /* z-index: 1; */
        }
    }

    &.cell-active {
        z-index: 1;
        background-color: var(--color-white);
        &:before {
            content: '';
            position: absolute;
            width: calc(100%);
            height: calc(100%);
            left: 0px;
            top: 0;
            border: 2px solid var(--color-app-main);
            background-color: transparent;
            /* z-index: 10; */
        }
    }
`

export const SCxTableCellPopperAnchor = styled.div`
    position: absolute;
    left: -2px;
    top: -2px;
    height: 40px;
    z-index: 2;
    overflow: visible;
`

export const SCxTableCellContent = styled.div`
    position: relative;
    width: 100%;
    height: 40px;
    font-size: var(--font-size-normal);
`

export const TableAdvancedValue: React.FC<TableCellProps> = memo(
    ({
        blockId,
        dsId,
        recordId,
        field,
        schema,
        data,
        width,
        styleConditions,
        style,
        aiFieldStatusListAtom,
        hoverCellColor,
        primaryDataSourceFieldIds,
        previewType,
        onOpenEditor,
        onOpenTooltip,
        onCellChange,
        onAiGeneration
    }) => {
        const { id: fieldId } = field
        const { personOptions } = useApplicationContext()
        const editorRef = useRef<HTMLDivElement>(null)

        const isNoneShape = notEditableFieldTypes.has(field.type)
        const isSystemField = getIsAppointField(fieldId, SYSTEM_FIELD)
        const isEditorInCell = editableInCellFieldTypes.has(field.type)
        const popperAnchorRef = useRef<HTMLDivElement>(null)
        const dataKey = useMemo(() => `${blockId}&${recordId}&${fieldId}`, [blockId, fieldId, recordId])

        const isEnableField = useMemo(() => primaryDataSourceFieldIds?.has(fieldId), [fieldId, primaryDataSourceFieldIds])

        const handleCellChange = useCallback(
            (value: FieldADTValue) => {
                if (!onCellChange) {
                    return Promise.resolve(false)
                }
                return onCellChange(recordId, value)
            },
            [onCellChange, recordId]
        )

        const handleOpen = useCallback(() => {
            const cellElement = editorRef.current
            if (!cellElement?.className.includes('active')) {
                return
            }

            // 复选框在单元格内编辑
            if (isEditorInCell) {
                return
            }

            if (!isEnableField) {
                onOpenTooltip?.({
                    toolTipAnchorElement: cellElement,
                    message: '该副表字段不允许编辑单元格内容'
                })
                return
            }

            if (isNoneShape || isSystemField) {
                // setOpened(true)
                onOpenTooltip?.({
                    toolTipAnchorElement: cellElement,
                    message: '该字段类型不允许编辑单元格内容'
                })
                return
            }
            if (popperAnchorRef.current && cellElement) {
                onOpenEditor?.({
                    popperAnchorElement: popperAnchorRef.current,
                    editorElement: cellElement,
                    currentRecordId: recordId,
                    currentWidth: width,
                    currentField: field
                })
            }
        }, [field, isEditorInCell, isEnableField, isNoneShape, isSystemField, onOpenEditor, onOpenTooltip, recordId, width])

        const handlePointerDown = useCallback(() => {
            if (previewType === ApplicationPreviewEnum.mobile) {
                return
            }
            handleOpen()
        }, [handleOpen, previewType])

        const handleTouchStart = useCallback(() => {
            if (previewType !== ApplicationPreviewEnum.mobile) {
                return
            }
            handleOpen()
        }, [handleOpen, previewType])

        return (
            <SCxTableCell
                style={style}
                onPointerDown={handlePointerDown}
                onTouchEnd={handleTouchStart}
                // onTouchStart={handleTouchStart}
                ref={editorRef}
                data-key={dataKey}
                hoverCellColor={hoverCellColor}
            >
                <SCxTableCellContent>
                    <ViewFieldStatusPreviewer
                        dsId={dsId}
                        recordId={recordId}
                        field={field}
                        data={data}
                        isWrap={false}
                        width={width}
                        disabled={!isEnableField}
                        enableEdit
                        personOptions={personOptions}
                        styleConditions={styleConditions}
                        aiFieldStatusListAtom={aiFieldStatusListAtom}
                        onCellChange={handleCellChange}
                        onAiGeneration={onAiGeneration}
                    />
                </SCxTableCellContent>
                <SCxTableCellPopperAnchor data-type="anchor" data-id={fieldId} ref={popperAnchorRef} />
            </SCxTableCell>
        )
    },
    isDeepEqual
)
