import { Toast } from '@byecode/ui'
import {
    applicationUnpublished,
    checkLoginCodeList,
    dataSourceLimitCodeList,
    needRegisterCodeList,
    noAccessCodeList,
    notFoundCodeList,
    noUserCodeList,
    reviewCodeList,
    skipCodeList,
    wechatPayCodeList
} from '@lighthouse/shared'
import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import axios from 'axios'
import { warn } from 'console'
import i18next from 'i18next'
import Qs from 'qs'
import serialize from 'serialize-javascript'

import { applicationIdProxy } from './atoms/application/state'
import { excludeModulaUrl, includeModulaUrl } from './services'

export interface Params<T> {
    body?: T
    params?: T
}

const http: AxiosInstance = axios.create({
    // timeout: 8000,
    withCredentials: true,
    baseURL: '/lighthouse',
    transformRequest: [
        (data, headers) => {
            if (headers?.['Content-Type'] === 'application/json') {
                return serialize(data, { space: 0, isJSON: true })
            }
            if (headers?.['Content-Type'] === 'multipart/form-data') {
                return data
            }
            return Qs.stringify(data)
        }
    ]
})

// const refreshAuthLogic = (failedRequest: AxiosError) => {
//     const refreshToken = refreshTokenProxy.value || ''
//     return http
//         .get(`api/auth/v1/refreshToken?${Qs.stringify({ refreshToken })}`)
//         .then((tokenRefreshResponse: AxiosResponse['data']) => {
//             if (!tokenRefreshResponse.success) {
//                 cookie.remove('jwt')
//                 refreshTokenProxy.value = ''
//                 window.location.href = '/account/login'
//                 return false
//             }
//             return true
//         })
//         .catch(() => {
//             cookie.remove('jwt')
//             refreshTokenProxy.value = ''
//             window.location.href = '/account/login'
//             return false
//         })
// }

// Instantiate the interceptor
// createAuthRefreshInterceptor(http, refreshAuthLogic)

http.interceptors.response.use(
    resp => {
        const { data } = resp ?? {}
        const { code, msg } = data ?? {}

        if (skipCodeList.includes(code)) {
            return data
        }
        if (notFoundCodeList.includes(code)) {
            window.location.href = '/404'
            return data
        }

        if (applicationUnpublished === code) {
            window.location.href = '/unpublished'
            return data
        }
        if (wechatPayCodeList.includes(code)) {
            requestAnimationFrame(() => {
                Toast.error(msg || i18next.t(code))
            })
            return data
        }
        // 用户在管理端被删除时，应用端重载页面
        if (noUserCodeList.includes(code)) {
            window.location.reload()
            return data
        }
        if (needRegisterCodeList.includes(code)) {
            requestAnimationFrame(() => {
                Toast.error(i18next.t(code) || '未知错误')
            })
            return Promise.resolve(data)
        }
        if (reviewCodeList.includes(code)) {
            requestAnimationFrame(() => {
                Toast.error(i18next.t(code) || '未知错误')
            })
            window.location.href = '/account/review'
            return data
        }
        if (checkLoginCodeList.includes(code)) {
            const redirectUrl = encodeURIComponent(window.location.href)
            window.location.href = `/account/login?redirect=${redirectUrl}`
            return data
        }
        // if (limitExceededCodeList.includes(code)) {
        //     return data
        // }
        if (noAccessCodeList.includes(code)) {
            return data
        }
        const levelCode = code?.[2]

        // 暂时处理 jwt 失效，走完 refreshAuthLogic 逻辑后，未重新刷新，是因为进到下面 default，被 reject 了
        // 也是属于之前写这块逻辑的时候埋的坑，刷 token 跟后面这些拦截器本来就不应该走同一套拦截器
        if (!levelCode) {
            return Promise.resolve(resp)
        }

        switch (levelCode) {
            case '0': {
                return data
            }
            case '1': {
                const msg = i18next.t(code)

                if (msg) {
                    requestAnimationFrame(() => {
                        Toast.warning(msg)
                    })
                }
                return data
            }
            case '2': {
                if (dataSourceLimitCodeList.includes(code)) {
                    if (data?.msg) {
                        Toast.error(data.message)
                    }
                    return Promise.resolve(data)
                }
                requestAnimationFrame(() => {
                    Toast.error(i18next.t(code) || '未知错误')
                })
                return Promise.reject(data)
            }
            default: {
                const message = i18next.t(code)
                if (message) {
                    requestAnimationFrame(() => {
                        Toast.error(message)
                    })
                }
                return Promise.reject(data)
            }
        }
    },
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    error => {
        const { response } = error
        if (response?.status === 401) {
            const redirectUrl = encodeURIComponent(window.location.href)
            window.location.href = `/account/login?redirect=${redirectUrl}`
        }
        if (axios.isCancel(error)) {
            return
        }
        return Promise.reject(error)
    }
)

http.interceptors.request.use(config => {
    const suffix = 'v1/'
    const requestUrl = config.url ?? ''
    for (const url of excludeModulaUrl) {
        if (requestUrl.includes(url)) {
            return config
        }
    }
    const reg = new RegExp(`(${suffix})\\w*`, 'g')
    const match = requestUrl.match(reg)
    const suffixUrl = match?.[0]?.replace(`${suffix}/`, '') ?? ''

    if (suffixUrl === applicationIdProxy.id) {
        return config
    }

    if (config.headers?.['Content-Type'] === 'application/json' && typeof config.data === 'string') {
        config.data = JSON.parse(config.data)
    }

    for (const [key, excludeUrl] of Object.entries(includeModulaUrl)) {
        if (requestUrl.includes(excludeUrl)) {
            // const index =  config.url.indexOf('v1/')
            config.url = requestUrl.replace(suffix, `${suffix}${applicationIdProxy.id}/`)
            return config
        }
    }
    return config
})

export default http
