import type { NodeViewProps } from '@tiptap/react'
import { NodeViewWrapper } from '@tiptap/react'
import type { Size } from 're-resizable'
import { Resizable } from 're-resizable'
import type { FC } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { FilePreviewer } from '../../FilePreviewer'

const Container = styled(NodeViewWrapper)`
    position: relative;
    vertical-align: middle;
`
const TopLeftHandle = styled.div`
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 4px;
    height: 4px;
    background-color: var(--color-main);
    pointer-events: none;
`
const TopRightHandle = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    width: 4px;
    height: 4px;
    background-color: var(--color-main);
    pointer-events: none;
`
const BottomLeftHandle = styled.div`
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 4px;
    height: 4px;
    background-color: var(--color-main);
    pointer-events: none;
`
const BottomRightHandle = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    width: 4px;
    height: 4px;
    background-color: var(--color-main);
    pointer-events: none;
`

export const ResizeableImage: FC<NodeViewProps> = ({ node, selected, updateAttributes, editor }) => {
    const { attrs } = node

    const [opened, setOpened] = useState(false)

    const containerRef = useRef<HTMLDivElement | null>(null)
    const ref = useRef<HTMLImageElement | null>(null)

    const beforeResizingWidth = useRef(0)

    return (
        <Container
            ref={containerRef}
            style={{ textAlign: attrs.textAlign }}
            onDoubleClick={() => {
                setOpened(true)
            }}
        >
            <Resizable
                enable={editor.isEditable ? undefined : false}
                style={{ display: 'inline-block' }}
                maxWidth="100%"
                lockAspectRatio
                size={attrs.width ? { width: attrs.width /* height: attrs.height */ } : undefined}
                onResizeStart={() => {
                    if (ref.current) {
                        beforeResizingWidth.current = ref.current.clientWidth
                    }
                }}
                onResize={(e, _, __, d) => {
                    if (e.target instanceof HTMLElement) {
                        const width = beforeResizingWidth.current + d.width

                        const containerEl = containerRef.current
                        if (!containerEl) {
                            return
                        }

                        const ratio = `${(width / containerEl.clientWidth) * 100}%`
                        updateAttributes({ ...attrs, width: ratio /* height */ })
                    }
                }}
                onResizeStop={() => {
                    beforeResizingWidth.current = 0
                }}
            >
                {selected && editor.isEditable && (
                    <>
                        <TopLeftHandle />
                        <TopRightHandle />
                        <BottomLeftHandle />
                        <BottomRightHandle />
                    </>
                )}
                <img ref={ref} className={selected ? 'ProseMirror-selectednode' : ''} src={attrs.src} alt={attrs.alt ?? ''} />
            </Resizable>
            <FilePreviewer
                fileList={[{ url: attrs.src, name: attrs.title ?? attrs.alt ?? '', type: 'image' }]}
                opened={opened}
                onClose={() => {
                    setOpened(false)
                }}
            />
        </Container>
    )
}
