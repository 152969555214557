import type { AnyObject } from 'immer/dist/internal'

import type { BlockType } from '../abstracts'
import type { FieldCellValue } from './field'
import type { FilterGroupTypeFixedReactHookFormTypeBug } from './filter'
import type { IconicProtocol, RecordOpenType } from './general'
import type { RichTextContentProtocol } from './richtext'
import type { VariableADTvalue } from './variable'

export type ButtonTriggerEvent = 'hover' | 'click'

export type ButtonHandleEvent =
    | 'none'
    | 'openPage'
    | 'openLink'
    | 'scrollTo'
    | 'control'
    | 'openFormPage'
    | 'openRecordPage'
    | 'openRecordEditPage'
    | 'goBack'
    | 'notification'
    | 'copy'
    | 'createRecord'
    | 'updateRecord'
    | 'deleteRecord'

export type ButtonShowType = 'icon-name' | 'icon' | 'name'

export enum ButtonPattern {
    primary,
    secondary,
    custom
}

export type ButtonEvents = {
    params: string[]
    handleEvent: ButtonHandleEvent
    triggerEvent: ButtonTriggerEvent
}

// 无动作
export type NoneAction = {
    type: 'none'
    // params: {}
    data: {
        none: {}
    }
}

export type FlowAction = {
    type: 'flow'
    // params: {
    //     flowId: string
    // }
    data: {
        flow: {
            flowId: string
        }
    }
}

export type AutomationAction = {
    type: 'automation'
    // params: {
    //     flowId: string
    // }
    data: {
        automation: {
            flowId: string
        }
    }
}

// 打开页面
export type OpenPageAction = {
    type: 'openPage'
    // params: {
    //     openPageUrl: string
    //     pageOpenType: RecordOpenType
    // }
    data: {
        openPage: {
            openPageUrl: string
            pageOpenType: RecordOpenType
        }
    }
}

// 打开链接
export type OpenLinkAction = {
    type: 'openLink'
    // params: {
    //     link: AnyObject // TiptapEditorJSONContent
    // }
    data: {
        openLink: {
            link: AnyObject
        }
    }
}

// 滚动到
export type ScrollToAction = {
    type: 'scrollTo'
    // params: {
    //     scrollToElementId: string
    // }
    data: {
        scrollTo: {
            scrollToElementId: string
        }
    }
}

/**
 * 视图控制动作
 */
export type ViewControlRefreshAction = {
    type: 'refresh'
}
export type ViewControlPaginateToAction = {
    type: 'paginateTo'
    params: {
        paginateToType: 'next' | 'prev'
    }
}
export type ViewControlHorizontalScrollToAction = {
    type: 'horizontalScrollTo'
    params: {
        horizontalScrollDirection: 'left' | 'right'
        horizontalScrollTo: number | 'end'
    }
}
export type ViewControlAction = ViewControlRefreshAction | ViewControlPaginateToAction | ViewControlHorizontalScrollToAction

/**
 * 容器控制动作
 */
export type ContainerControlSwitchPanelAction = {
    type: 'switchPanel'
    params: {
        switchPanelId: string
    }
}
export type ContainerControlOpenAction = {
    type: 'open'
}
export type ContainerControlCloseAction = {
    type: 'close'
}
export type ContainerControlAction = ContainerControlSwitchPanelAction | ContainerControlOpenAction | ContainerControlCloseAction

type ControlActionParams = ViewControlAction | ContainerControlAction

export type ContainerControlActionWithPayload = {
    blockType: 'container'
    payload: ContainerControlAction
}

export type ViewControlActionWithPayload = {
    blockType: 'view'
    payload: ViewControlAction
}

export type ControlActionWithPayload = ContainerControlActionWithPayload | ViewControlActionWithPayload

export type ControlledActionBlockType = ControlActionWithPayload['blockType']

export type ControlAction = {
    type: 'control'
    // params: {
    //     controlType: 'BACK' | 'FORWARD' | 'REFRESH' | 'CLOSE'
    // }
    data: {
        control: {
            controlElementId: string
            controlElementType: 'view' | 'container'
            controlAction?: ControlActionParams
        }
    }
}

// 打开表单页
export type OpenFormPageAction = {
    type: 'openFormPage'
    // params: {
    //     formPageId: string
    //     pageOpenType: RecordOpenType
    // }
    data: {
        openFormPage: {
            formPageId: string
            pageOpenType: RecordOpenType
        }
    }
}

// 打开详情页
export type OpenRecordPageAction = {
    type: 'openRecordPage'
    // params: {
    //     dataSourceId?: string
    //     recordPageId: string
    //     filter: {
    //         expression: FilterGroupTypeFixedReactHookFormTypeBug
    //     }
    //     pageOpenType: RecordOpenType
    //     selectedRecordType: 'CURRENT_RECORD' | 'MATCHED_RECORD' | 'UPSTREAM_RECORD'
    //     upstreamRecordNodeId?: string // 上游记录 id，当 selectedRecordType 为 UPSTREAM_RECORD 时需要
    // }
    data: {
        openRecordPage: {
            dataSourceId?: string
            recordPageId: string
            filter: {
                expression: FilterGroupTypeFixedReactHookFormTypeBug
            }
            pageOpenType: RecordOpenType
            selectedRecordType: 'CURRENT_RECORD' | 'MATCHED_RECORD' | 'UPSTREAM_RECORD'
            upstreamRecordNodeId?: string // 上游记录 id，当 selectedRecordType 为 UPSTREAM_RECORD 时需要
        }
    }
}

// 打开详情编辑页
export type OpenRecordEditPageAction = {
    type: 'openRecordEditPage'
    // params: {
    //     dataSourceId?: string
    //     recordEditPageId: string
    //     filter: {
    //         expression: FilterGroupTypeFixedReactHookFormTypeBug
    //     }
    //     pageOpenType: RecordOpenType
    //     selectedRecordType: 'CURRENT_RECORD' | 'MATCHED_RECORD' | 'UPSTREAM_RECORD'
    //     upstreamRecordNodeId?: string // 上游记录 id，当 selectedRecordType 为 UPSTREAM_RECORD 时需要
    // }
    data: {
        openRecordEditPage: {
            dataSourceId?: string
            recordEditPageId: string
            filter: {
                expression: FilterGroupTypeFixedReactHookFormTypeBug
            }
            pageOpenType: RecordOpenType
            selectedRecordType: 'CURRENT_RECORD' | 'MATCHED_RECORD' | 'UPSTREAM_RECORD'
            upstreamRecordNodeId?: string // 上游记录 id，当 selectedRecordType 为 UPSTREAM_RECORD 时需要
        }
    }
}

// 返回上一步
export type GoBackAction = {
    type: 'goBack'
    // params: {}
    data: {
        goBack: {}
    }
}

// 通知
export type NotificationAction = {
    type: 'notification'
    // params: {
    //     notificationContent: AnyObject // TiptapEditorJSONContent
    //     notificationStatus: 'SUCCESS' | 'ERROR' | 'INFO'
    // }
    data: {
        notification: {
            notificationContent: AnyObject // TiptapEditorJSONContent
            notificationStatus: 'SUCCESS' | 'ERROR' | 'INFO'
        }
    }
}

// 拷贝
export type CopyAction = {
    type: 'copy'
    // params: {
    //     copyText: AnyObject // TiptapEditorJSONContent
    // }
    data: {
        copy: {
            copyText: AnyObject
        }
    }
}

// 拨打电话
export type CallAction = {
    type: 'call'
    // params: {
    //     phone: VariableADTvalue // TiptapEditorJSONContent
    // }
    data: {
        call: {
            phone: VariableADTvalue // TiptapEditorJSONContent
        }
    }
}

// 创建行
export type CreateRecordAction = {
    type: 'createRecord'
    // params: {
    //     dataSourceId: string
    //     fields: []
    // }
    data: {
        createRecord: {
            dataSourceId: string
            fields: []
        }
    }
}

// 更新行
export type UpdateRecordAction = {
    type: 'updateRecord'
    // params: {
    //     dataSourceId: string
    //     selectType: 'UPSTREAM' | 'CURRENT_RECORD'
    //     nodeId: string
    //     fields: []
    // }
    data: {
        updateRecord: {
            dataSourceId: string
            selectType: 'UPSTREAM' | 'CURRENT_RECORD'
            nodeId: string
            fields: []
        }
    }
}

// 删除行
export type DeleteRecordAction = {
    type: 'deleteRecord'
    // params: {}
    data: {
        deleteRecord: {}
    }
}

export type ShareToWechatAction = {
    type: 'shareToWechat'
    // params: {
    //     title: string
    //     desc: string
    //     imgUrl: string
    // }
    data: {
        shareToWechat: {
            title: string
            desc: string
            imgUrl: string
        }
    }
}

export type DownloadFileAction = {
    type: 'downloadFile'
    // params: {
    //     fileUrl: VariableADTvalue
    // }
    data: {
        downloadFile: {
            fileUrl: VariableADTvalue
        }
    }
}

export type ActionTriggerTypeProtocols = {
    trigger: ButtonTriggerEvent
}

export type IClickAction = {
    type: 'iClick'
    // params: {
    //     email: RichTextContentProtocol | null
    //     mobile: RichTextContentProtocol | null
    //     name: RichTextContentProtocol | null
    //     iClickId: string
    // }
    data: {
        iClick: {
            email: RichTextContentProtocol | null
            mobile: RichTextContentProtocol | null
            name: RichTextContentProtocol | null
            iClickId: string
        }
    }
}
export type AliyunMessageAction = {
    type: 'aliyunMessage'
    // params: {}
    data: {
        aliyunMessage: {
            id: string
            mobileList: VariableADTvalue
            signatureId: string
            templateId: string
            values: { variableName: string; value: RichTextContentProtocol }[]
        }
    }
}

export type WeChatPayAction = {
    type: 'wechatPay'
    data: {
        wechatPay: {
            operationType: 'PAY' | 'REFUND'
            payConfig: {
                nodeId?: string
                descFieldId: string
                /** 订单金额 */
                amountFieldId: string
                succeedFieldSettings: { fieldId: string; value: AnyObject }[]
                failedFieldSettings: { fieldId: string; value: AnyObject }[]
            }
            refundConfig: {
                nodeId?: string
                // 订单id对应的字段id
                outTradeNoFieldId: string
                refundAmountFieldId: string
                reasonFieldId: string
                succeedFieldSettings: { fieldId: string; value: AnyObject }[]
                failedFieldSettings: { fieldId: string; value: AnyObject }[]
            }
        }
    }
}

export type WechatTemplateMsgAction = {
    type: 'wechatTemplateMsg'
    data: {
        wechatTemplateMsg: {
            personList: VariableADTvalue[]
            templateId: string
            values: { variableName: string; value: RichTextContentProtocol }[]
            url: RichTextContentProtocol
        }
    }
}

export type RefreshPageAction = {
    type: 'refreshPage'
    data: {
        refreshPage: {}
    }
}

export type StripeAction = {
    type: 'stripe'
    data: {
        stripe: {
            operationType: 'PAY'
            payConfig: {
                nodeId?: string
                descFieldId: string
                currency: string
                /** 订单金额 */
                amountFieldId: string
                productNameFieldId: string
                productPictureFieldId: string
                succeedFieldSettings: { fieldId: string; value: AnyObject }[]
                failedFieldSettings: { fieldId: string; value: AnyObject }[]
            }
        }
    }
}

export type ActionParams =
    | NoneAction
    | OpenPageAction
    | OpenLinkAction
    | ScrollToAction
    | ControlAction
    | OpenFormPageAction
    | OpenRecordPageAction
    | OpenRecordEditPageAction
    | NotificationAction
    | CopyAction
    | GoBackAction
    | CreateRecordAction
    | UpdateRecordAction
    | DeleteRecordAction
    | FlowAction
    | AutomationAction
    | ShareToWechatAction
    | DownloadFileAction
    | IClickAction
    | AliyunMessageAction
    | CallAction
    | WeChatPayAction
    | WechatTemplateMsgAction
    | StripeAction
    | RefreshPageAction

export type ButtonAction = ActionTriggerTypeProtocols & ActionParams

export type ButtonActionType = ButtonAction['type']

/**
 * 按钮 对象协议
 * buttonBlock or navbarButton
 */
export type ButtonProtocols = {
    name: string

    showType: ButtonShowType

    pattern?: ButtonPattern

    // 暂时还有几个地方用的是这个结构，后续再统一
    events: ButtonEvents

    // 暂时只有按钮组用到这个属性，到时候其他地方都要统一成这个结构
    action: ButtonAction
} & IconicProtocol
