import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import { FloatingArrow, FloatingFocusManager, FloatingOverlay, FloatingPortal, useMergeRefs, useTransitionStyles } from '@floating-ui/react'
import clsx from 'clsx'
import React, { forwardRef } from 'react'

import { Box } from '../../Box'
import { usePopoverContext } from '../Popover.context'
import { useStyles } from './PopoverDropdown.styles'

export type PopoverDropdownStylesNames = Selectors<typeof useStyles>

export interface PopoverDropdownProps extends StyleComponentProps<PopoverDropdownStylesNames>, React.ComponentPropsWithoutRef<'div'> {
    compact?: boolean
}

export const PopoverDropdown = forwardRef<HTMLElement, PopoverDropdownProps>((props, ref) => {
    const { children, compact, style, className, classNames, styles, unstyled, ...rest } = props

    const { classes } = useStyles({ compact }, { name: 'popover', unstyled, classNames, styles })

    const ctx = usePopoverContext()

    const _ref = useMergeRefs([ref, ctx.floating])

    const { isMounted, styles: transitionStyles } = useTransitionStyles(ctx.context)

    if (ctx.disabled) {
        return null
    }

    const dom = (
        <Box
            ref={_ref}
            className={clsx(className, classes.dropdown)}
            style={{
                ...transitionStyles,
                position: ctx.context.strategy,
                zIndex: ctx.zIndex,
                top: ctx.y,
                left: ctx.x,
                width: ctx.width === 'target' ? undefined : ctx.width,
                ...style
            }}
            {...ctx.interactionProps.getFloatingProps({ ...rest })}
        >
            {children}

            {ctx.withArrow ? (
                <FloatingArrow
                    ref={ctx.arrowRef}
                    context={ctx.context}
                    width={ctx.arrowSize * 2}
                    height={ctx.arrowSize}
                    fill={ctx.arrowColor ?? '#fff'}
                />
            ) : null}
        </Box>
    )

    const content = ctx.withinPortal ? <FloatingPortal root={ctx.target}>{dom}</FloatingPortal> : dom

    return isMounted ? (
        <FloatingFocusManager
            context={ctx.context}
            // order={ctx.trapFocus ? undefined : ['reference', 'content']}
            // modal={ctx.trapFocus}
            visuallyHiddenDismiss={ctx.trapFocus}
            returnFocus={ctx.returnFocus}
            initialFocus={ctx.trapFocus ? 0 : -1}
        >
            {ctx.withinOverlay ? <FloatingOverlay>{content}</FloatingOverlay> : content}
        </FloatingFocusManager>
    ) : null
})

PopoverDropdown.displayName = 'Bui-PopoverDropdown'
