import { Group } from '@byecode/ui/components/Group'
import { RichTextEditor } from '@lighthouse/shared'
import styled from 'styled-components'

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 4px;
`

export const CollapseRoot = styled.div`
    padding: 16px 16px 12px;
    border-radius: 12px;
    background-color: var(--color-gray-100);
`
export const CollapseTitle = styled(RichTextEditor)`
    > div {
        font-size: 16px;
        line-height: 24px;
        color: var(--color-gray-900);
    }
`
export const CollapseSubTitle = styled(RichTextEditor)`
    > div {
        font-size: 14px;
        line-height: 22px;
        color: var(--color-gray-800);
    }
`
export const CollapseContent = styled(RichTextEditor)`
    > div {
        color: var(--color-gray-500);
        line-height: 24px;
    }
`

export const Collapse = styled(Group)`
    padding: 8px;
    border-radius: 12px;
    background-color: var(--color-gray-100);

    .byecode-group-label {
        font-size: 16px;
        line-height: 24px;
    }

    .byecode-group-wrapper {
        height: auto;
        border-radius: 6px;
        padding: 8px;
        &:hover {
            background-color: rgba(38, 65, 90, 0.06);
        }
    }
    .byecode-group-collapse {
        padding: 0;
    }
    .byecode-group-rightIcon {
        color: var(--color-gray-400);
    }
`

export const Content = styled.div`
    padding: 4px 8px 8px;
    .ProseMirror p {
        color: var(--color-gray-500);
    }
`
