import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { ByecodeSize } from '@byecode/ui/types'

const sizeStyles = {
    xs: {
        height: '24px',
        lineHeight: 'calc(24px - var(--byecode-select-border-width) * 2)',
        borderRadius: '5px'
    },
    sm: {
        height: '28px',
        lineHeight: 'calc(28px - var(--byecode-select-border-width) * 2)',
        borderRadius: '6px'
    },
    md: {
        height: '32px',
        lineHeight: 'calc(32px - var(--byecode-select-border-width) * 2)',
        borderRadius: '6px'
    },
    lg: {
        height: '36px',
        lineHeight: 'calc(36px - var(--byecode-select-border-width) * 2)',
        borderRadius: '8px'
    },
    xl: {
        height: '40px',
        lineHeight: 'calc(40px - var(--byecode-select-border-width) * 2)',
        borderRadius: '6px'
    }
}

type StylesParams = { size: ByecodeSize; disabled?: boolean; hasPrefix: boolean; focused: boolean; highlighting?: boolean }

export const useStyles = createStyles(({ size, disabled, hasPrefix, focused, highlighting }: StylesParams) => {
    const getInteractionCss = () => {
        if (disabled) {
            return { color: '$colorGray500', cursor: 'not-allowed' }
        }

        if (focused) {
            return {
                backgroundColor: '$colorWhite',
                borderColor: 'var(--color-main)'
            }
        }

        return {
            '&:hover': {
                backgroundColor: '$colorGray200'
            },
            '&:has(input:focus)': {
                backgroundColor: '$colorWhite',
                borderColor: 'var(--color-main)'
            }
        }
    }

    return {
        root: css({
            '--byecode-select-border-width': '1.5px',
            '--byecode-select-prefix-size': '34px',
            '--byecode-select-suffix-size': '24px',

            display: 'flex',
            position: 'relative',
            backgroundColor: '$colorGray100',
            border: '1.5px solid transparent',
            cursor: disabled ? 'not-allowed' : 'pointer',
            paddingLeft: hasPrefix ? undefined : 8,

            ...(highlighting ? { borderColor: '$colorPrimary', boxShadow: '0 0 4px 0 $colorPrimary' } : {}),

            ...getInteractionCss(),
            ...sizeStyles[size]
        }),
        selector: css({
            fontSize: '14px',
            textEllipsis: true
        }),
        placeholder: css({
            fontSize: '14px',
            color: '$colorGray400',
            textEllipsis: true
        }),
        input: css({
            opacity: 0,
            appearance: 'none',
            position: 'absolute',
            inset: 0,
            pointerEvents: 'none'
        }),
        prefix: css({
            pointerEvents: 'none',
            width: 'var(--byecode-select-prefix-size)',
            flexShrink: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 16,
            color: '$colorGray400'
        }),

        suffix: css({
            marginLeft: 'auto',
            width: 'var(--byecode-select-suffix-size)',
            flexShrink: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '$colorGray400'
        })
    }
})
