import { Toast } from '@byecode/ui/components/Toast'
import { nanoid } from '@lighthouse/tools'
import type { UploadOptions } from '@rpldy/uploady'
import type { Node } from '@tiptap/pm/model'
import { Fragment, Slice } from '@tiptap/pm/model'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import type { EditorView } from '@tiptap/pm/view'
import { Decoration, DecorationSet } from '@tiptap/pm/view'

// // 图片上传前占位符
// export const PlaceholderPlugin = new Plugin({
//     state: {
//         init() {
//             return DecorationSet.empty
//         },
//         apply(tr, set) {
//             set = set.map(tr.mapping, tr.doc)
//             const action = tr.getMeta(this as unknown as Plugin<DecorationSet>)
//             if (!action) {
//                 return set
//             }
//             if (action.add) {
//                 const widget = document.createElement('img')
//                 widget.src = action.add.src
//                 widget.style.opacity = '0.6'
//                 widget.style.display = 'block'
//                 const deco = Decoration.widget(action.add.pos, widget, { id: action.add.id })
//                 set = set.add(tr.doc, [deco])
//             }
//             if (action.remove) {
//                 set = set.remove(set.find(undefined, undefined, spec => spec.id === action.remove.id))
//             }
//             return set
//         }
//     },
//     props: {
//         decorations(state) {
//             return this.getState(state)
//         }
//     }
// })

interface ParseImagePluginParams {
    view: EditorView
    uploadOptions?: UploadOptions
}

interface UploadingItem {
    id: string
    file: string | File
}

async function webImg2File(imgUrl: string) {
    const blob = await fetch(imgUrl).then(res => res.blob())
    const ext = blob.type.split('/')[1]

    return new File([blob], `${nanoid(10)}.${ext}`, { type: blob.type })
}

export const UPLOAD_IMAGE_PLUGIN_KEY = new PluginKey('upload-image')
const MAX_SIZE = 10 * 1024 * 1024

class UploadImageManager {
    #uploadOptions?: UploadOptions

    constructor(uploadOptions?: UploadOptions) {
        this.#uploadOptions = uploadOptions
    }

    createPlaceholder(view: EditorView, id: string) {
        return view.state.schema.nodes.uploadImagePlaceholder.create({
            uploadId: id
        })
    }

    findPlaceholder(view: EditorView, id: string) {
        let _pos = -1

        view.state.doc.descendants((node, pos) => {
            if (_pos !== -1) {
                return false
            }

            if (node.type.name === 'uploadImagePlaceholder' && node.attrs.uploadId === id) {
                _pos = pos
                return false
            }
        })

        return _pos
    }

    removePlaceholder(view: EditorView, id: string) {
        const { tr } = view.state
        const pos = this.findPlaceholder(view, id)
        if (pos === -1) {
            return
        }
        tr.delete(pos, pos)
        view.dispatch(tr)
    }

    async upload(view: EditorView, item: UploadingItem) {
        const { destination } = this.#uploadOptions ?? {}

        if (!destination || !destination.url) {
            return
        }

        const file = item.file instanceof File ? item.file : await webImg2File(item.file)
        const pos = this.findPlaceholder(view, item.id)
        if (pos === -1) {
            return
        }
        if (file.size > MAX_SIZE) {
            Toast.error('不能上传超过10MB的图片')
            this.removePlaceholder(view, item.id)
            return
        }

        const formData = new FormData()
        formData.append(destination.filesParamName ?? 'file', file)
        if (typeof destination.params?.dsId === 'string') {
            formData.append('dsId', destination.params.dsId)
        }

        fetch(destination.url, { method: 'POST', body: formData, headers: destination.headers as Record<string, string> })
            .then(async res => {
                const data = await res.json()
                const { url } = data.content

                const imageNode = view.state.schema.nodes.image.create({ src: url })
                const { tr } = view.state
                tr.replaceWith(pos, pos, imageNode)
                view.dispatch(tr)
            })
            .catch(e => {
                this.removePlaceholder(view, item.id)
            })
    }

    createPlaceholderAndUpload(view: EditorView, files: File[], pos: number) {
        const uploadImages: UploadingItem[] = files.map(item => ({ id: nanoid(), file: item }))
        const uploadNodes = uploadImages.map(item => this.createPlaceholder(view, item.id))
        const fragment = Fragment.fromArray(uploadNodes)
        const { tr } = view.state

        tr.insert(pos, fragment)

        view.dispatch(tr)

        uploadImages.forEach(item => {
            this.upload(view, item)
        })
    }
}

// 处理各种本地添加图片逻辑
export const ParseImagePlugin = ({ uploadOptions, view }: ParseImagePluginParams) => {
    const uploadImageManager = new UploadImageManager(uploadOptions)

    return new Plugin({
        key: UPLOAD_IMAGE_PLUGIN_KEY,

        state: {
            init() {
                return null
            },
            apply(tr, value) {
                const files = tr.getMeta(UPLOAD_IMAGE_PLUGIN_KEY)
                if (!files) {
                    return value
                }

                uploadImageManager.createPlaceholderAndUpload(view, [...files], tr.selection.from)

                return value
            }
        },
        props: {
            transformPasted(slice, view) {
                const newNodes: Node[] = []
                const uploadImages: UploadingItem[] = []

                slice.content.forEach(node => {
                    let newNode = node
                    if (node.type.name === 'image') {
                        const id = nanoid()
                        newNode = uploadImageManager.createPlaceholder(view, id)
                        uploadImages.push({
                            id,
                            file: node.attrs.src
                        })

                        newNodes.push(newNode)
                        return
                    }

                    node.content.descendants((node, pos) => {
                        if (node.type.name === 'image') {
                            const id = nanoid()
                            newNode = newNode.replace(
                                pos,
                                pos,
                                new Slice(Fragment.from(uploadImageManager.createPlaceholder(view, id)), 0, 0)
                            )
                            uploadImages.push({
                                id,
                                file: node.attrs.src
                            })
                            return false
                        }
                    })

                    newNodes.push(newNode)
                })

                uploadImages.forEach(item => {
                    uploadImageManager.upload(view, item)
                })

                return new Slice(Fragment.fromArray(newNodes), slice.openStart, slice.openEnd)
            },
            handleDOMEvents: {
                paste(view, event) {
                    const items = [...(event.clipboardData?.items ?? [])].filter(file => /image/i.test(file.type))

                    const files = items.map(item => item.getAsFile()).filter(Boolean) as File[]
                    if (files.length === 0) {
                        return false
                    }

                    event.preventDefault()

                    const { tr } = view.state

                    uploadImageManager.createPlaceholderAndUpload(view, files, tr.selection.from)

                    return false
                },

                drop(view, event) {
                    const hasFiles = event.dataTransfer?.files && event.dataTransfer.files.length > 0
                    if (!hasFiles) {
                        return false
                    }

                    const files = [...event.dataTransfer.files].filter(file => /image/i.test(file.type))

                    if (files.length === 0) {
                        return false
                    }

                    const coordinates = view.posAtCoords({
                        left: event.clientX,
                        top: event.clientY
                    })
                    if (!coordinates) {
                        return false
                    }

                    event.preventDefault()

                    if (!uploadOptions) {
                        return false
                    }

                    uploadImageManager.createPlaceholderAndUpload(view, files, coordinates.pos)

                    return false
                }
            }
        }
    })
}
