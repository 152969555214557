import { IconFont } from '@byecode/ui'
import type { DataSourceAbstract, FieldADTValue, FieldProtocol, RichTextContentProtocol } from '@lighthouse/core'
import { useDebounce } from '@lighthouse/tools'
import type { UploadOptions } from '@rpldy/uploady'
import isDeepEqual from 'fast-deep-equal'
import { clone } from 'rambda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import type { RichTextEditorProps } from '../RichText'
import { isEmptyRichTextValue, RichTextEditor } from '../RichText'

interface NotesValueEditorProps {
    editorHost?: 'dataSource' | 'field' | 'advancedTable'
    readonly?: boolean
    dataSourceInfo: DataSourceAbstract
    recordId: string
    data?: RichTextContentProtocol
    field: FieldProtocol
    onCellChange?: (recordId: string, field: FieldADTValue) => Promise<boolean>
    onCellUpdate?: (recordId: string, field: FieldADTValue) => Promise<boolean>
    onCellActiveKeydown?: () => void
    // onCellFetch?: (recordId: string, field: FieldADTValue) => Promise<boolean>
    onClose?: () => void
    richTextUploadOptions: UploadOptions
}

const SCxNotesValueEditorContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

const SCxNotesValueEditorHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
`

const SCxNotesValueEditorContent = styled.div`
    /* height: 100%; */
    overflow: auto;
    flex: 1;
    box-sizing: content-box;
    background-color: var(--color-white);
    border: 1px solid transparent;

    .ProseMirror {
        min-height: 200px;
    }
`

const CloseIcon = styled(IconFont)`
    cursor: pointer;
`

export const NotesValueEditor: React.FC<NotesValueEditorProps> = ({
    editorHost,
    dataSourceInfo,
    recordId,
    data,
    field,
    readonly = false,
    onCellChange,
    onCellUpdate,
    onCellActiveKeydown,
    onClose,
    richTextUploadOptions
}) => {
    const { name: dsName } = dataSourceInfo
    const contentRef = useRef(data)
    const [content, setContent] = useState(data)
    const isFieldHost = editorHost === 'field'
    const isAdvancedTableHost = editorHost === 'advancedTable'

    // const schema = useMemo(() => generateDsSchema(dataSourceInfo, record), [dataSourceInfo, record])
    const debouncedTitle = useDebounce(content, 300)

    useUpdateEffect(() => {
        if (!debouncedTitle) {
            return
        }
        const isEmptyValueNotes = !debouncedTitle || isEmptyRichTextValue(debouncedTitle)
        const isEmptyDataNotes = !contentRef.current || isEmptyRichTextValue(contentRef.current)
        if (isEmptyValueNotes !== isEmptyDataNotes) {
            onCellChange?.(recordId, { ...field, type: 'notes', value: debouncedTitle })
            contentRef.current = debouncedTitle
            return
        }
        onCellUpdate?.(recordId, { ...field, type: 'notes', value: debouncedTitle })
    }, [debouncedTitle])
    const handleChange = useCallback((value: RichTextContentProtocol) => {
        setContent(value)
    }, [])

    const handleClose = useCallback(() => {
        if (content && !isDeepEqual(clone(contentRef.current), clone(content))) {
            onCellChange?.(recordId, { ...field, type: 'notes', value: content })
        }
        onClose?.()
    }, [content, field, onCellChange, onClose, recordId])

    const handleKeyDown = useCallback(
        (ev: React.KeyboardEvent) => {
            if (ev.key === 'Escape') {
                ev.stopPropagation()
                handleClose()
            }
        },
        [handleClose]
    )

    const containerStyles: React.CSSProperties = useMemo(() => {
        return isFieldHost
            ? {}
            : {
                  padding: '20px 16px',
                  backgroundColor: 'var(--color-gray-100)',
                  borderRadius: 12
              }
    }, [isFieldHost])

    const contentStyles: React.CSSProperties = useMemo(() => {
        return isFieldHost
            ? {}
            : {
                  padding: '0px 12px',
                  borderRadius: 8
              }
    }, [isFieldHost])

    const richTextConfig: RichTextEditorProps['config'] = useMemo(
        () => ({
            history: true,
            heading: true,
            align: true,
            lineHeight: true,
            variable: false,
            image: { uploadOptions: richTextUploadOptions }
        }),
        [richTextUploadOptions]
    )

    return (
        <SCxNotesValueEditorContainer style={containerStyles}>
            {!isFieldHost && (
                <SCxNotesValueEditorHeader>
                    {isAdvancedTableHost ? (
                        <div />
                    ) : (
                        <>
                            {dsName} / {field.name}
                        </>
                    )}
                    <CloseIcon type="Close" fill="var(--color-gray-400)" onClick={handleClose} />
                </SCxNotesValueEditorHeader>
            )}
            <SCxNotesValueEditorContent style={contentStyles}>
                <RichTextEditor
                    key={recordId}
                    styles={{
                        editorContent: {
                            minHeight: 200,
                            padding: 8
                        },
                        toolbarContainer: {
                            borderTopRightRadius: 6,
                            borderTopLeftRadius: 6
                        }
                    }}
                    config={richTextConfig}
                    // shrink={breakPoint === BreakPointSize.xs}
                    readonly={readonly}
                    value={data}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />
            </SCxNotesValueEditorContent>
        </SCxNotesValueEditorContainer>
    )
}
