import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import { styled } from '@stitches/react'

export const useStyles = createStyles((params: { clearable?: boolean; isOpen?: boolean }) => {
    const { clearable, isOpen } = params
    return {
        root: css({
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 30,
            fontSize: 'var(--font-size-normal)',
            /* margin: 8px 14px 8px 14px, */
            border: '1px solid var(--color-gray-200)',
            borderRadius: '5px',
            backgroundColor: 'var(--color-gray-50)',
            cursor: 'pointer',
            '&:hover .arrowIcon': {
                display: clearable ? 'none' : 'block'
            },

            '&:hover .clearIcon': {
                display: clearable ? 'block' : 'none'
            },

            '&.disabled': {
                cursor: 'not-allowed',
                border: '1px solid var(--color-gray-100)'
                // ${CascadeSelectContent} {
                //     color: 'var(--color-gray-300),'
                // }
            }
        }),
        target: css({
            display: 'flex',
            width: '100%',
            height: ' 100%',
            padding: '0 8px',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: 'var(--color-gray-500)'
        }),
        iconWrapper: css({
            // transition: 'transform 0.2s ease',
            // transform: isOpen ? 'rotate(180deg)' : 'none'
        }),
        clearIcon: css({
            display: 'none'
        }),
        targetWrapper: css({
            display: 'flex',
            alignItems: 'center',
            gap: 6,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }),
        arrowIcon: css({
            transition: 'transform 0.2s ease',
            transform: isOpen ? 'rotate(180deg)' : 'none'
        }),
        tag: css({
            display: 'inline-flex',
            alignItems: 'center',
            height: 22,
            padding: '2px 4px 2px 0',
            color: 'var(--color-black)',
            backgroundColor: 'var(--color-gray-100)',
            overflow: 'hidden',
            '&:not(.plain-container):': {
                /* background: #f4e7c3, */
                borderRadius: 4,
                padding: '2px 6px'
            }
        })
    }
})
