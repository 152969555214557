import type { DTFile, FileValue } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import { Divider, Drawer } from '@mantine/core'
import { current } from 'immer'
import { findIndex } from 'rambda'
import React, { useId, useMemo } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { type WithStatusFile } from '../../types'
import { getFileNameByUrl, getFileSizeByUrl, getFileTypeByFileName, getFileTypeByUrl } from '../../utils'
import FileAdd from '../FileUploader/mobile/FileAdd'
import { getFileDrawerConfig } from '../FileUploader/WithoutPopperFileUploader'
import { type UseUploadFileSParameter, useUploadBatchError, useUploadBatchFinished, useUploadBatchUploading } from '../UploadManage'

interface FileDrawerFieldProps {
    value: FileValue
    uploadyOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    readOnly?: boolean
    isControlled?: boolean
    title?: string
    target?: string
    opened: boolean
    onClose: () => void
    onChange?: (val: DTFile[]) => void
}

interface FileValueEditorState {
    value: WithStatusFile[]
}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
`

export const SCxFooter = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 16px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-gray-50);
    z-index: 1;
`

export const FileDrawerField: React.FunctionComponent<FileDrawerFieldProps> = props => {
    const { value: data, uploadyOptions, target, opened, onClose, onChange } = props
    const uploadDropId = useId()
    const listData: WithStatusFile[] = useMemo(() => {
        return data.map(url => {
            const name = getFileNameByUrl(url) || ''
            const type = getFileTypeByFileName(name)
            const size = getFileSizeByUrl(url)
            return {
                uid: nanoid(),
                label: name,
                value: name,
                type,
                url,
                name: name ?? '',
                status: 'success',
                percent: 100,
                size
            }
        })
    }, [data])
    const [innerFiles, setInnerFiles] = useImmer(listData)

    const newUploadyOptions = useMemo(
        () => ({ ...uploadyOptions, info: { ...uploadyOptions.info, id: uploadDropId } }),
        [uploadDropId, uploadyOptions]
    )

    useUploadBatchUploading(uploadDropId, batch => {
        const { items: batchList } = batch
        const items = batchList.map(item => {
            const { file, id } = item
            const { name, type, size } = file
            const fileType = getFileTypeByUrl(name)
            return {
                uid: id,
                name,
                type: fileType,
                status: 'uploading',
                url: '',
                size,
                percent: 0
            }
        })
        setInnerFiles(draft => [...draft, ...items])
    })

    useUploadBatchError(uploadDropId, batch => {
        const { items: batchList } = batch
        setInnerFiles(draft => {
            return draft.map(item => {
                const { uid } = item
                const uploadingFileIndex = findIndex(({ id }) => uid === id, batchList)
                if (uploadingFileIndex !== -1) {
                    const uploadFile = draft[uploadingFileIndex]
                    if (uploadFile) {
                        uploadFile.status = 'error'
                        uploadFile.percent = 100
                    }
                }
                return item
            })
        })
    })

    useUploadBatchFinished(uploadDropId, batch => {
        const { items: batchList } = batch
        setInnerFiles(draft => {
            batchList.forEach(item => {
                const { id, uploadResponse } = item
                const uploadingFileIndex = findIndex(({ uid }) => uid === id, draft)
                const uploadFile = draft[uploadingFileIndex]
                if (uploadingFileIndex !== -1 && uploadResponse.data.content) {
                    if (uploadFile) {
                        uploadFile.url = uploadResponse.data.content.url
                        uploadFile.percent = 100
                        uploadFile.status = 'success'
                    }
                } else {
                    if (uploadFile) {
                        uploadFile.status = 'error'
                        uploadFile.percent = 100
                    }
                }
            })
            onChange?.(current(draft).map(item => item.url))
            onClose()
        })
    })

    return (
        <SCxContainer>
            <Drawer {...getFileDrawerConfig(Boolean(target))} target={target} opened={opened} onClose={onClose}>
                <FileAdd uploadyOptions={newUploadyOptions} isRealityMobile />
                <Divider style={{ borderWidth: 8, marginBottom: 52 }} color="var(--color-gray-100)" />
                <SCxFooter style={{ height: 52 }} onClick={onClose}>
                    取消
                </SCxFooter>
            </Drawer>
        </SCxContainer>
    )
}
