import { Flex } from '@byecode/ui'
import type { AnyObject } from '@byecode/ui/types'
import type { ApplicationSettingAuthenticationField, DataSourceAbstract } from '@lighthouse/core'
import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type { ApplicationPreviewEnum } from '../../../../types'
import { getFieldInputTypeByFieldType } from '../../../../utils'
import { ErrorMessage } from '../../../ErrorMessage'
import { FieldInput } from '../../../FieldInput'
import type { FieldInputConfig } from '../../../FieldInput/types'
import { commonFieldInputConfig } from '../../constant'
import type { AccountPerfectForm } from '../../types'

export type Fields = ApplicationSettingAuthenticationField[]

export const SCxItem = styled.div`
    width: 100%;
`
interface PerfectFieldsProps {
    // data: Fields
    dataSource?: DataSourceAbstract
    errors: AnyObject
    previewType?: ApplicationPreviewEnum
}

export const PerfectFields: React.FunctionComponent<PerfectFieldsProps> = ({ dataSource, errors, previewType }) => {
    const { control, register, setValue } = useFormContext<AccountPerfectForm>()
    const { t } = useTranslation()

    const { fields } = useFieldArray({
        control,
        name: 'fields'
    })
    return (
        <Flex direction="column" gap={20}>
            {fields.map(({ fieldId, title, placeHolder, required }, index) => {
                return (
                    <SCxItem key={fieldId}>
                        <Controller
                            name={`fields.${index}.value`}
                            control={control}
                            rules={required ? { required: t('notEmpty') } : undefined}
                            render={({ field: { value, onChange } }) => (
                                <ErrorMessage name={`fields.${index}.value`} errors={errors}>
                                    <FieldInput
                                        dataSource={dataSource}
                                        mode="datasource"
                                        config={
                                            {
                                                fieldPointer: fieldId,
                                                placeholder: placeHolder ?? t('pleaseFillOut'),
                                                title,
                                                previewType,
                                                required,
                                                ...commonFieldInputConfig,
                                                inputType: getFieldInputTypeByFieldType(dataSource?.schema?.[fieldId]?.type ?? 'text')
                                            } as FieldInputConfig
                                        }
                                        value={value}
                                        style={{ padding: 0 }}
                                        onSaveChange={fieldInputValue => {
                                            onChange(fieldInputValue.value)
                                        }}
                                    />
                                </ErrorMessage>
                            )}
                        />
                    </SCxItem>
                )
            })}
        </Flex>
    )
}
