import { Toast } from '@byecode/ui'
import type { DataSourceAbstract, ViewFieldProps } from '@lighthouse/core'
import { ResultAsync } from 'neverthrow'
import { filter, find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components'

import type { AppendParams, ImportParams } from '../../../types'
import { isCSV, readCsv } from '../../../utils'
import { ImportSetting } from './ImportSetting'
import { ImportTypeSelector } from './ImportTypeSelector'
import type { FileJson, FormValue } from './types'
import UploadClickDropZone from './UploadClickDropZone'
import { UploaderFooter } from './UploaderFooter'
import { UploaderNavigator } from './UploaderNavigator'

interface FileUploaderProps {
    appId: string
    envId: string
    dataSource?: DataSourceAbstract
    dataSourceList?: DataSourceAbstract[]
    defaultImportType?: 'create' | 'append'
    disableSelectDataSource?: boolean
    userImportSetting?: string[]
    isShowRepeatConfig?: boolean
    viewColumns?: ViewFieldProps[]
    onImport?: (params: ImportParams) => void
    onAppend?: (params: AppendParams) => void
    onClose?: () => void
}

const ImportContainer = styled.div`
    outline: none;
    padding: 24px 20px;
    user-select: none;
`

const convertExcelToJson = async (file: File): Promise<FileJson | undefined> => {
    const { name, size } = file

    const { Workbook } = await import('exceljs')
    const workbook = new Workbook()
    const res = await ResultAsync.fromPromise(workbook.xlsx.load(await file.arrayBuffer()), e => e)

    if (res.isErr()) {
        Toast.error('文件解析失败')
        return
    }

    const { worksheets } = res.value

    if (worksheets.length === 0) {
        Toast.error('文件解析失败')
        return
    }

    const worksheetsJson: { name: string; columns: string[] }[] = []
    for (const worksheet of worksheets) {
        const { name, columns } = worksheet
        // 空 sheet 过滤
        if (!columns) {
            continue
        }
        const ensuredColumns: string[] = []
        for (let colIndex = 1, colLen = columns.length; colIndex < colLen++; colIndex++) {
            const cell = worksheet.getCell(1, colIndex)
            const { isMerged, text } = cell

            if (isMerged) {
                Toast.error('文件中存在合并的单元格，请取消合并单元格后重新上传')

                return
            }

            if (!text) {
                break
            }

            ensuredColumns.push(text)
        }

        worksheetsJson.push({ name, columns: ensuredColumns })
    }

    return { sheets: worksheetsJson, fileName: name, fileSize: size, rawFile: file }
}

const convertCSVToJson = async (file: File): Promise<FileJson | undefined> => {
    const { name, size } = file
    const fileData = await readCsv(file)
    if (!fileData) {
        return
    }

    if (fileData.errors.length > 0) {
        Toast.error('文件解析失败')
    }

    const { data } = fileData

    const worksheetsJson: { name: string; columns: string[] }[] = []
    const headerFields = data[0]

    const ensuredColumns = filter(headerField => !!headerField.trim(), headerFields)

    worksheetsJson.push({ name: '', columns: ensuredColumns })

    return { sheets: worksheetsJson, fileName: name, fileSize: size, rawFile: file }
}

export const FileUploader: React.FC<FileUploaderProps> = ({
    appId,
    envId,
    dataSource,
    dataSourceList = [],
    defaultImportType = 'create',
    disableSelectDataSource,
    userImportSetting,
    isShowRepeatConfig,
    viewColumns,
    onImport,
    onAppend,
    onClose
}) => {
    const [step, setStep] = useState(1)
    const [fileJson, setFileJson] = useState<FileJson | null>()
    const formMethods = useForm<FormValue>({
        defaultValues: {
            importType: defaultImportType === 'create' ? '1' : '2',
            sheetName: '',
            dsId: dataSource?.id || '',
            fieldMappings: [{ sheetFieldNo: '', dsFieldId: '', matchFieldId: '' }],
            file: fileJson?.rawFile ?? null,
            repeat: false
        }
    })

    const { watch } = formMethods
    const dsId = watch('dsId')
    const handleFileClear = useCallback(() => {
        setFileJson(null)
        formMethods.reset()
    }, [formMethods])

    const isCSVfile = useMemo(() => isCSV(fileJson?.rawFile), [fileJson?.rawFile])

    const handleNext = useCallback(() => {
        let addNumber = 1
        if (isCSVfile && disableSelectDataSource) {
            addNumber += 1
        }
        setStep(s => {
            if (s === 1) {
                return s + addNumber
            }
            return s + 1
        })
    }, [disableSelectDataSource, isCSVfile])

    const handleBack = useCallback(() => {
        let subductNumber = 1
        if (isCSVfile && disableSelectDataSource) {
            subductNumber += 1
        }
        setStep(s => {
            if (s === 3) {
                return s - subductNumber
            }
            return s - 1
        })
        formMethods.resetField('fieldMappings')
    }, [disableSelectDataSource, formMethods, isCSVfile])

    const handleImport = useCallback(() => {
        const { getValues } = formMethods
        const { importType } = getValues()

        // 直接导入
        if (importType === '1') {
            const file = fileJson?.rawFile
            const { sheetName } = getValues()
            if (!file) {
                return
            }
            onImport?.({ file, sheetName, envId })
        }
        // 追加导入
        if (importType === '2') {
            const { sheetName, dsId, fieldMappings, repeat, importMode, compareFields } = getValues()
            const file = fileJson?.rawFile
            const filteredFiledMappings = filter(field => field.sheetFieldNo !== '', fieldMappings)
            // csv 无需校验 sheetName
            const sheetNameIsValid = !isCSV(file) && !sheetName
            if (!file || sheetNameIsValid || !dsId || filteredFiledMappings.length === 0) {
                return
            }
            if (repeat && (!importMode || !compareFields || compareFields.length === 0)) {
                return
            }
            const appendParams = {
                sheetDto: { appId, dsId, envId, sheetName, fields: filteredFiledMappings, repeat, importMode, compareFields },
                file: fileJson?.rawFile
            }
            onAppend?.(appendParams)
        }
        onClose?.()
    }, [appId, envId, fileJson?.rawFile, formMethods, onAppend, onClose, onImport])

    const convertFileToJson = useCallback(
        async (file: File | null) => {
            if (!file) {
                return
            }

            const convertedResult = await (isCSV(file) ? convertCSVToJson(file) : convertExcelToJson(file))

            if (!convertedResult) {
                return
            }

            setFileJson(convertedResult)
            const { sheets } = convertedResult
            formMethods.setValue('file', file)
            if (sheets[0]) {
                formMethods.setValue('sheetName', sheets[0].name)
            }
        },
        [formMethods]
    )

    const currentDataSource = useMemo(() => {
        if (disableSelectDataSource) {
            return dataSource
        }

        return find(item => item.id === dsId, dataSourceList)
    }, [dataSource, dataSourceList, disableSelectDataSource, dsId])

    const contentView = useMemo(() => {
        switch (step) {
            case 1: {
                return (
                    <UploadClickDropZone file={fileJson?.rawFile ?? null} onFileLoaded={convertFileToJson} onFileClear={handleFileClear} />
                )
            }
            case 2: {
                return (
                    <ImportTypeSelector
                        dataSourceList={dataSourceList}
                        disableSelectDataSource={disableSelectDataSource}
                        fileType={isCSV(fileJson?.rawFile) ? 'csv' : 'excel'}
                        sheets={fileJson?.sheets}
                        fileName={fileJson?.fileName}
                    />
                )
            }
            case 3: {
                return (
                    currentDataSource && (
                        <ImportSetting
                            appId={appId}
                            sheets={fileJson?.sheets}
                            fileName={fileJson?.fileName}
                            dataSource={currentDataSource}
                            dataSourceList={dataSourceList}
                            disableSelectDataSource={disableSelectDataSource}
                            userImportSetting={userImportSetting}
                            isShowRepeatConfig={isShowRepeatConfig}
                            viewColumns={viewColumns}
                        />
                    )
                )
            }
            default: {
                return null
            }
        }
    }, [
        appId,
        convertFileToJson,
        currentDataSource,
        dataSourceList,
        disableSelectDataSource,
        fileJson?.fileName,
        fileJson?.rawFile,
        fileJson?.sheets,
        handleFileClear,
        isShowRepeatConfig,
        step,
        userImportSetting,
        viewColumns
    ])

    return (
        <ImportContainer>
            <FormProvider {...formMethods}>
                <UploaderNavigator onClose={onClose} />
                {contentView}
                {/* {fileJson && JSON.stringify(fileJson)}
                {JSON.stringify(values)} */}
                {fileJson && (
                    <UploaderFooter
                        control={formMethods.control}
                        step={step}
                        fileType={isCSV(fileJson.rawFile) ? 'csv' : 'excel'}
                        onNext={handleNext}
                        onBack={handleBack}
                        onImport={handleImport}
                        onCancel={onClose}
                    />
                )}
            </FormProvider>
        </ImportContainer>
    )
}
