import type { AppUser, RecordLikeProtocol } from '@lighthouse/core'
import { atom } from 'jotai'
import { atomWithReset, atomWithStorage } from 'jotai/utils'
import { atomWithImmer } from 'jotai-immer'
import { atomWithProxy } from 'jotai-valtio'
import cookies from 'js-cookie'
import { proxy, subscribe } from 'valtio'

import { INITIAL_USER_DATA } from './constants'
import type { WxRoot } from './types'

export const userDataAtom = atomWithReset<AppUser>(INITIAL_USER_DATA)

export const userIDAtom = atom(get => get(userDataAtom).userId)

export const signedAtom = atom<boolean>(get => !!get(userIDAtom))

// export const refreshTokenProxy = proxy({ value: localStorage.getItem('refreshToken') || '' })

// export const refreshTokenAtom = atomWithProxy(refreshTokenProxy)

export const authAtom = atom(get => ({
    userId: get(userIDAtom),
    accessToken: cookies.get('jwt')
    // refreshToken: get(refreshTokenAtom).value
}))

export const userRecordAtom = atom<RecordLikeProtocol | undefined>(undefined)

// subscribe(refreshTokenProxy, () => {
//     localStorage.setItem('refreshToken', refreshTokenProxy.value)
// })
