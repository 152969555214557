import { flex, Modal } from '@byecode/ui'
import { IconFont } from '@byecode/ui/components/IconFont'
import type { ButtonEvents, NavigationBaseItem, PageAbstract, VariableADTvalue } from '@lighthouse/core'
import { ApplicationSettingJumpType as AppJumpType, ApplicationType, RecordOpenType } from '@lighthouse/core'
import {
    ApplicationContainer,
    ApplicationPreviewEnum,
    AppLoginMode,
    CollapseManager,
    combineBackgroundStyle,
    commonPages,
    CUSTOM_ROUTE_PAGE_URL_REG,
    getBackgroundStyle,
    getJumpUrl,
    getVeinsStyle,
    LoginAuthType,
    PAGE_CONTAINER_HOST,
    PAGE_CONTAINER_HOST_ID,
    PAGE_LAYOUT_HOST,
    PAGE_URL_REG,
    PageLogo,
    PageOverlay,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import { closeModal } from '@mantine/modals'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { appAtom, languageAtom, websiteApplicationSettingAtom } from '@/atoms/application/state'
import { fetchUserAtom, logoutAtom } from '@/atoms/auth/action'
import { fetchPageListAtom } from '@/atoms/page/action'
import { homePageAtom, lastRootPageOfStackAtom, pageListAtom, pageStackOfFormContainerBlockChangedAtom } from '@/atoms/page/state'
import { NestedPageWrapper } from '@/components/NestedPageWrapper'
import { NotificationContainer } from '@/components/NotificationContainer'
import { RootPageProvider } from '@/context/PageContext'
import { useAccount } from '@/hooks/useAccount'
import { useApplication, useDefaultPageList, usePreview } from '@/hooks/useApplication'
import { usePage, usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { usePageStackActions } from '@/hooks/usePageStackActions'
import { useGetInnerStacks, useSubscriptRouteForPageStack } from '@/hooks/useSubscriptRouteForPageStack'
import { useToggleLang } from '@/hooks/useToggleLang'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'
import { useWechatLogin } from '@/hooks/useWechatLogin'
import { useSubscriptWxRoot } from '@/hooks/useWxAuthRoot'
import * as srv from '@/services'

export interface LayoutProps {
    children?: React.ReactNode
}

export const SCxLayout = styled.div`
    position: relative;
    width: 100%;
    height: calc(100% - 67px);
    ${flex};
    flex-wrap: wrap;
`

export const SCxContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`

const BackButton = styled.button`
    all: unset;
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    border-radius: 6px;
    &:hover {
        background-color: var(--color-gray-200);
    }
`

const PageContainer = styled.div`
    position: relative;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* background-color: #fff; */
`

function getPageId(url: string, pageList: PageAbstract[]) {
    const isCustomRoute = CUSTOM_ROUTE_PAGE_URL_REG.test(url)
    if (isCustomRoute) {
        const page = pageList.find(item => item.route === url.split('/')[0])
        return page?.id || ''
    }

    const [, pageId] = url.split('/')

    return pageId
}

export const Page: React.FC<LayoutProps> = ({ children }) => {
    const modalIdRef = useRef<string>()

    const { run: logout } = useAtomAction(logoutAtom)
    const { run: fetchUser } = useAtomAction(fetchUserAtom)

    const navigate = useNavigate()
    const app = useApplication()
    const pageList = useDefaultPageList()
    const { pathname } = useLocation()
    const pageId = useMemo(() => {
        const matches = pathname.match(PAGE_URL_REG)
        if (!matches) {
            return ''
        }
        const first = matches[0]
        return getPageId(first, pageList)
    }, [pageList, pathname])
    const page = usePage(pageId)

    const visitPage = page?.isAccess ? page : null

    const lastRootPageOfStack = useAtomData(lastRootPageOfStackAtom)
    const { stackId = '' } = lastRootPageOfStack ?? {}

    const userData = useAccount()
    const previewType = usePreview()
    const homePage = useAtomData(homePageAtom)
    const language = useAtomData(languageAtom)
    const { isHideLogo, isPublishApp, appTitle, appLogo, appDescription } = useAtomData(
        appAtom,
        useCallback(s => {
            if (s?.type === ApplicationType.website) {
                return {
                    isHideLogo: s.config.advertising.isHideLogo,
                    isPublishApp: s.config.webApp?.isPublishApp,
                    appTitle: s?.config.webTag?.title || '未命名应用',
                    appLogo: s?.config.webTag?.logo,
                    appDescription: s?.config?.webTag?.title
                }
            }
            return {}
        }, [])
    )

    const isMobile = previewType === ApplicationPreviewEnum.mobile

    // 订阅路由信息自动维护pageStack
    useSubscriptRouteForPageStack(pageList)

    // 获取权限并发起授权
    useSubscriptWxRoot()

    const pageInnerStack = useGetInnerStacks(pageId)
    // 获取当前根页面的最后一个子级页面
    const lastInnerStack = useMemo(() => pageInnerStack[pageInnerStack.length - 1], [pageInnerStack])
    const innerPageName = useAtomData(
        pageListAtom,
        useCallback(s => lastInnerStack && s.find(item => item.id === lastInnerStack.pageId)?.name, [lastInnerStack])
    )
    const { closeCurrentPageLayer, closeAllPageLayers } = usePageStackActions()
    const isFormExistValue = useAtomData(
        pageStackOfFormContainerBlockChangedAtom,
        useCallback(s => s[lastInnerStack?.stackId], [lastInnerStack?.stackId])
    )

    const handleModalClose = useCallback(() => {
        if (!isFormExistValue) {
            closeAllPageLayers()
            return
        }
        Modal.confirm({
            title: '表单中的数据未提交',
            content: `关闭页面后数据不会保存`,
            okText: '关闭页面'
        })
            .then(isConfirm => {
                isConfirm && closeAllPageLayers()
            })
            // eslint-disable-next-line no-console
            .catch(console.warn)
    }, [isFormExistValue, closeAllPageLayers])

    const handleModalBack = useCallback(() => {
        if (!isFormExistValue) {
            closeCurrentPageLayer()
            return
        }
        Modal.confirm({
            title: '表单中的数据未提交',
            content: `关闭页面后数据不会保存`,
            okText: '关闭页面'
        })
            .then(isConfirm => {
                isConfirm && closeCurrentPageLayer()
            })
            // eslint-disable-next-line no-console
            .catch(console.warn)
    }, [closeCurrentPageLayer, isFormExistValue])

    const { handleWxBrowserWeChatLogin } = useWechatLogin(window.location.href)
    const handleUpdateBindAccount = useCallback(
        async (isBind: boolean, bindType: LoginAuthType) => {
            if (isBind) {
                switch (bindType) {
                    case LoginAuthType.wechat: {
                        handleWxBrowserWeChatLogin('merge')
                        break
                    }
                    case LoginAuthType.follow_wechat: {
                        navigate('/account/bindWechat?manualBinding=1')
                        break
                    }
                    case LoginAuthType.mobile: {
                        // 手动绑定
                        navigate('/account/bindMobile?manualBinding=1')
                        break
                    }
                    case LoginAuthType.email: {
                        navigate('/account/bindEmail?manualBinding=1')
                        break
                    }
                    default: {
                        break
                    }
                }
                return false
            }
            const res = await srv.removeBindAccount(bindType)
            fetchUser()
            return res
        },
        [fetchUser, handleWxBrowserWeChatLogin, navigate]
    )

    // 当前要展示的子级页面
    const layer = useMemo(() => {
        if (!lastInnerStack) {
            return
        }

        const { pageId, appId, dsId, viewId, recordId, stackId, stackDisplayType } = lastInnerStack

        switch (stackDisplayType) {
            case RecordOpenType.modal:
            case RecordOpenType.drawer: {
                return {
                    type: stackDisplayType,
                    props: {
                        title: innerPageName,
                        // color: theme.mainColor,
                        onClose: handleModalClose,
                        leftSlot: pageInnerStack.length > 1 && (
                            <BackButton onClick={handleModalBack}>
                                <IconFont type="ArrowLeft" />
                            </BackButton>
                        ),
                        target: PAGE_CONTAINER_HOST,
                        children: (
                            <NestedPageWrapper
                                pageId={pageId}
                                appId={appId}
                                dsId={dsId}
                                viewId={viewId}
                                recordId={recordId}
                                stackId={stackId}
                            />
                        )
                    }
                }
            }
            default: {
                break
            }
        }
    }, [handleModalBack, handleModalClose, innerPageName, lastInnerStack, pageInnerStack.length])

    const collapseMap = useMemo(
        () => Object.fromEntries(Object.keys(app?.config.navbar.linkList.list ?? {}).map(id => [id, false])),
        [app?.config.navbar.linkList.list]
    )

    useEffect(() => {
        CollapseManager.setData(collapseMap)
    }, [app?.config?.navbar?.showMode, collapseMap])

    useEffect(() => {
        return () => {
            if (modalIdRef.current) {
                closeModal(modalIdRef.current)
                modalIdRef.current = undefined
            }
        }
    }, [])

    const handleToLink = useCallback(
        (navigation?: NavigationBaseItem) => {
            if (!navigation) {
                return
            }
            const { value: linkUrl, jumpType } = navigation
            if (!linkUrl) {
                return
            }
            const linkPage = find(page => page.id === linkUrl.replace('/page/', ''), pageList)
            const redirectUrl = encodeURIComponent(window.location.href)
            switch (jumpType) {
                case AppJumpType.linkUrl: {
                    return window.open(linkUrl)
                }
                case AppJumpType.page: {
                    if (commonPages.includes(linkUrl)) {
                        return navigate({ pathname: linkUrl, search: `redirect=${redirectUrl}` })
                    }
                    return linkPage && navigate({ pathname: linkPage.route ? `/${linkPage.route}` : `/P/${linkPage.id}` })
                }
                default: {
                    return navigate({ pathname: linkUrl })
                }
            }
        },
        [pageList, navigate]
    )

    const handleCommonCallBack = useCallback(
        (buttonEvents: ButtonEvents) => {
            const { params, handleEvent } = buttonEvents
            const isHaveNoneParam = !params.some(Boolean)
            if (isHaveNoneParam) {
                return
            }
            const redirectUrl = encodeURIComponent(window.location.href)
            switch (handleEvent) {
                case 'openPage': {
                    const pagePath = params[0]
                    const linkPage = find(page => page.id === pagePath.replace('/page/', ''), pageList)
                    if (commonPages.includes(pagePath)) {
                        return navigate({ pathname: pagePath, search: `redirect=${redirectUrl}` })
                    }
                    linkPage && navigate({ pathname: linkPage.route ? `/${linkPage.route}` : `/P/${linkPage.id}` })
                    break
                }
                case 'openLink': {
                    const linkUrl = params[0]
                    window.open(getJumpUrl(linkUrl))
                    break
                }
                default: {
                    break
                }
            }
        },
        [pageList, navigate]
    )

    // 退出登录
    const handleLogout = useCallback(async () => {
        await logout()
        window.location.reload()
    }, [logout])

    // 页面背景
    const { prev, curr } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })

    const { renderLabel } = useVariableValueRender(prev.recordId ?? '', curr.recordId)
    const parseBackgroundVariableImage = useCallback(
        (value: VariableADTvalue | undefined) => renderLabel(value, { useFileUrl: true }),
        [renderLabel]
    )

    const palettes = useAtomData(
        websiteApplicationSettingAtom,
        useCallback(s => s?.theme.palettes ?? [], [])
    )

    // 切换语言
    const { handleToggleLang } = useToggleLang()

    const renderApp = useMemo(() => {
        if (app?.type === ApplicationType.wxApplet) {
            return null
            // <WxAppletAppContain customConfig={customConfig} onToPage={handleToPage}>
            //     <Outlet />
            // </WxAppletAppContain>
        }
        return (
            <ApplicationContainer
                application={app}
                customConfig={app?.config}
                userData={userData}
                activePage={pageId}
                pageList={pageList}
                id={PAGE_CONTAINER_HOST_ID}
                // onToPage={handleToPage}
                previewMode={isMobile ? 'mobile' : 'website'}
                language={language}
                styles={{
                    layout: {
                        ...combineBackgroundStyle([
                            getBackgroundStyle(visitPage?.design?.background, parseBackgroundVariableImage, palettes),
                            getVeinsStyle(visitPage?.design?.veins, palettes)
                        ])
                    }
                }}
                extraNode={
                    <PageLogo
                        isMobile={isMobile}
                        isHideLogo={isHideLogo}
                        isPublishApp={isPublishApp}
                        appTitle={appTitle}
                        appLogo={appLogo}
                        appDescription={appDescription}
                    />
                }
                onToLink={handleToLink}
                onLogout={handleLogout}
                onCommonCallBack={handleCommonCallBack}
                onChangeLanguage={handleToggleLang}
                onUpdateBindAccount={handleUpdateBindAccount}
                notificationBox={
                    app &&
                    userData?.userId && (
                        <NotificationContainer
                            appId={app.id}
                            messageEvents={{
                                onFetchMessages: srv.listMessage,
                                onFetchMessageDetail: srv.getApproveDetail,
                                approveReject: srv.approveReject,
                                approveAccept: srv.approveAccept
                            }}
                        />
                    )
                }
            >
                <RootPageProvider value={pageId}>
                    <PageContainer id={PAGE_LAYOUT_HOST}>{children}</PageContainer>
                    <PageOverlay layerProps={layer} />
                </RootPageProvider>
            </ApplicationContainer>
        )
    }, [
        app,
        userData,
        pageId,
        pageList,
        isMobile,
        language,
        visitPage?.design?.background,
        visitPage?.design?.veins,
        parseBackgroundVariableImage,
        palettes,
        isHideLogo,
        isPublishApp,
        appTitle,
        appLogo,
        appDescription,
        handleToLink,
        handleLogout,
        handleCommonCallBack,
        handleToggleLang,
        handleUpdateBindAccount,
        children,
        layer
    ])

    if (!app?.config) {
        return null
    }

    return (
        <ErrorBoundary FallbackComponent={() => <div />}>
            <SCxContainer>{renderApp}</SCxContainer>
        </ErrorBoundary>
    )
}
