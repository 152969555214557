import type {
    BarChartOptionProtocol,
    BaseChartOption,
    ChartLikeProtocol,
    ChartOptionProtocol,
    Direction,
    Series,
    SingleChartOptionProtocol,
    StriationChartOptionProtocol,
    YAxisParam
} from '@lighthouse/core'
import { ChartType } from '@lighthouse/core'
import { divide, multiply } from 'rambda'

import type { ECOption } from '../../components'

export interface AxisOption {
    xAxis: ECOption['xAxis']
    yAxis: ECOption['yAxis']
}

export interface BarOption {
    label: string
    parent?: string
}

type GridPosition = {
    top?: string | number
    bottom: number
    left: number
    right?: string | number
    containLabel?: boolean
}

export const hasAbbreviationAxisChartType = [ChartType.bar, ChartType.line, ChartType.composite, ChartType.striation]
// export const chartTypeConfigurator: Record<ChartType, (keyof ChartOptionProtocol)[]> = {
//     bar: ['showAbbreviationAxis'],
//     line: ['showAbbreviationAxis'],
//     composite: ['showAbbreviationAxis'],
//     striation: [],
//     pie: [],
//     funnel: [],
//     indicator: ['showAbbreviationAxis']
// }

export const defaultColors = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']

export const legendWidth = 150

export const topLegendHeight = 40

export const titleGap = 30

export const yAxisTitleHeight = 30

export const legendOffset = 15

export const exportOffset = 60

export const HeadTitleHeight = 50

export const abbreviationAxisHeight = 45

export const initGridTop = 20
// export const getGrid = (params: ChartOptionProtocol): ECOption['grid'] => {
//     const { showLegend, legendTranspose } = params
//     if(!showLegend){
//         return {
//             left: 0,
//             right: 0
//         }
//     }
//     switch (legendTranspose) {
//         case 'top':
//         case 'bottom': {
//             return {
//                 left: 40,
//                 right: 40
//             }
//         }
//         case 'left': {
//             return {
//                 left: 200,
//                 right: 40
//             }
//         }
//         case 'right': {
//             return {
//                 left: 40,
//                 right: 200
//             }
//         }
//         default: {
//             break
//         }
//     }

// }
export const formatNumber = (number: number) => {
    if (number < 1000) {
        return number.toString()
    }
    if (number < 10000) {
        return `${divide(number, 1000).toFixed(1)}千`
    }
    if (number < 10000000) {
        return `${divide(number, 10000).toFixed(1)}万`
    }
    if (number < 100000000) {
        return `${divide(number, 10000000).toFixed(1)}千万`
    }
    return `${divide(number, 100000000).toFixed(1)}亿`
}

export const getXAxisItem = (itemData: BarOption[]) => {
    const textArr: string[] = []
    const possessions: Record<string, string[]> = {}
    itemData.forEach((data, index) => {
        const { label, parent } = data
        const str = parent ? `${parent}-${label}` : label
        // if(find(item => item.label === ,textArr))

        if (!textArr.includes(str)) {
            textArr.push(str)
            possessions[str] = [String(index)]
            return
        }
        possessions[str].push(String(index))
    })
    return possessions
}

export const getXAxisDataArr = (data: string[][]) => {
    const arr: BarOption[][] = []
    data.forEach(item => {
        item.forEach((val, index) => {
            let parent = ''
            if (index > 0) {
                for (let i = index - 1; i >= 0; i--) {
                    parent += item[i]
                }
            }
            if (arr[index]) {
                arr[index].push({
                    label: val,
                    parent
                })
                return
            }
            arr[index] = [
                {
                    label: val,
                    parent
                }
            ]
        })
    })
    return arr
}

export const getNumericalInterval = (minNum: number, maxNum: number, segmentCount: number) => {
    const segmentRange = Math.ceil(maxNum / segmentCount)
    const magnitude = 10 ** Math.floor(Math.log10(segmentRange)) // 获取范围大小的数量级

    if (magnitude === 0) {
        const step = Math.ceil(maxNum / segmentCount)
        return { maxValue: maxNum, step: step > 1 ? step : 1 }
    }
    const secondMagnitude = magnitude / 10
    const step = Math.ceil(segmentRange / secondMagnitude) * secondMagnitude
    const maxValue = multiply(step, segmentCount)
    return { maxValue, step }
}

export const getYAxis = (series: Series[]) => {
    return series.reduce<{ max: number; min: number; yAxisIndex?: number }[]>((prev, cur) => {
        const i = prev.findIndex(item => item.yAxisIndex === cur?.yAxisIndex)
        const listValue = cur.value.map(Number)
        const max = Math.max(...listValue)
        const min = Math.min(...listValue)
        if (i >= 0) {
            prev[i].max = prev[i].max > max ? prev[i].max : max
            prev[i].min = prev[i].min < min ? prev[i].min : min
            return prev
        }
        prev.push({
            max,
            min,
            yAxisIndex: cur.yAxisIndex
        })
        return prev
    }, [])
}

export const getPositionTop = (params: StriationChartOptionProtocol | BarChartOptionProtocol) => {
    const ySecondaryAxisTitle = params.chartType === ChartType.striation ? undefined : params.ySecondaryAxisTitle
    const { showLegend, legendTranspose, showHeadTitle, showAxisTitle, yMainAxisTitle } = params
    let top = initGridTop
    if (showHeadTitle) {
        top += HeadTitleHeight
    }
    if (showLegend && legendTranspose === 'top') {
        top += topLegendHeight
    }

    if (showAxisTitle && (yMainAxisTitle || ySecondaryAxisTitle)) {
        top += yAxisTitleHeight
    }
    return top
}

export const getAxisOffset = (axis: YAxisParam) => {
    const { max } = axis
    if (String(max).length < 6) {
        const labelLength = String(max).length - Math.floor(divide(String(max).length, 3))
        return multiply(labelLength, 8) + 22
    }
    const labelLength = String(max).length - Math.floor(divide(String(max).length, 5.8))
    return multiply(labelLength, 8) + 22
}

export const getAbbreviationAxisDirection = (chartType: ChartType, showAbbreviationAxis?: boolean): Direction | undefined => {
    if (!showAbbreviationAxis) {
        return undefined
    }
    return chartType === 'striation' ? 'vertical' : 'horizontal'
}

export const getDataZoom = (params: StriationChartOptionProtocol | BarChartOptionProtocol, showAbbreviationAxis?: boolean) => {
    const { chartType, legendTranspose } = params
    const direction = getAbbreviationAxisDirection(chartType, showAbbreviationAxis)
    if (!direction) {
        return undefined
    }
    if (direction === 'horizontal') {
        return [
            {
                type: 'inside'
            },
            {
                type: 'slider'
            }
        ]
    }
    const left = legendTranspose === 'left' ? 100 : 0
    return [
        {
            type: 'inside'
        },
        {
            type: 'slider',
            yAxisIndex: 0,
            left,
            start: 0,
            end: 100
        }
    ]
}

export const getGridPosition = (
    params: StriationChartOptionProtocol | BarChartOptionProtocol,
    chartData: ChartLikeProtocol,
    yAxis: YAxisParam[],
    offsetY: number
) => {
    const { series } = chartData
    const { chartType } = params
    const noData = !(series?.[0]?.value.length > 0)
    console.log('🚀 ~ file: common.ts ~ line 269 ~ noData', noData)
    const [mainAxis, secondAxis] = yAxis
    const leftOffset = mainAxis ? getAxisOffset(mainAxis) : 40
    const rightOffset = secondAxis ? getAxisOffset(secondAxis) : 40

    const { showLegend, showAxisTitle, xAxisTitle, legendTranspose, showAbbreviationAxis } = params
    const abbreviationAxisDirection = getAbbreviationAxisDirection(chartType, showAbbreviationAxis)
    // const isHorizontalAbbreviationAxis = showAbbreviationAxis && chartType === 'striation'
    const offsetTitle = showAxisTitle && xAxisTitle ? 30 : 0
    const positionTop = getPositionTop(params)
    const position: GridPosition = {
        top: positionTop,
        bottom: noData ? 40 : offsetY,
        left: leftOffset,
        right: rightOffset,
        containLabel: chartType === 'striation'
    }
    if (showAxisTitle) {
        position.bottom += offsetTitle
    }
    if (abbreviationAxisDirection === 'horizontal') {
        position.bottom += abbreviationAxisHeight
    }
    if (abbreviationAxisDirection === 'vertical') {
        position.left += 10
    }
    if (showLegend && legendTranspose === 'bottom') {
        position.bottom += 30
    }

    // if (showLegend && legendTranspose === 'top') {

    // }
    if (showLegend && legendTranspose === 'left') {
        position.left = legendWidth + leftOffset
    }
    if (showLegend && legendTranspose === 'right') {
        position.right = legendWidth + rightOffset
    }

    return position
}

export const getCommonOption = (
    params: StriationChartOptionProtocol | BarChartOptionProtocol,
    chartData: ChartLikeProtocol,
    yAxis: YAxisParam[],
    offsetY = 0
) => {
    const { colors, showAbbreviationAxis, showHeadTitle, title } = params
    const commonOption: Pick<ECOption, 'color' | 'legend' | 'title' | 'grid' | 'dataZoom'> = {
        color: colors?.length > 0 ? colors : defaultColors,
        dataZoom: getDataZoom(params, showAbbreviationAxis),
        grid: {
            ...getGridPosition(params, chartData, yAxis, offsetY)
        },
        title: {
            text: showHeadTitle && title ? title : undefined,
            textStyle: {
                fontSize: 24,
                fontWeight: 500,
                color: '#101828'
            }
        }
    }
    return commonOption
}


