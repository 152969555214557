import { Button, useDisclosure } from '@byecode/ui'
import type { DataSourceAbstract, FieldInputADTValue, SubFormColumn } from '@lighthouse/core'
import type { AppendParams, ImportParams } from '@lighthouse/shared';
import { getEmptyFieldInputValue, ImportModal } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import React from 'react'
import styled from 'styled-components'

import type { SubFormRecord } from './types'

interface SubFormFooterProps {
    appId: string
    envId?: string
    dataSource?: DataSourceAbstract
    onRecordsAdd: () => void
    onImport?: (params: ImportParams) => void
    onAppend?: (params: AppendParams) => void
}

const SCxContainer = styled.div`
    /*  */
    margin-top: 12px;
    display: flex;
    gap: 8px;
`
export const SubFormFooter: React.FunctionComponent<SubFormFooterProps> = ({ envId = '', appId,dataSource, onRecordsAdd,onAppend,onImport }) => {
    const [opened, { close, open }] = useDisclosure(false)
    return (
        <SCxContainer>
            <Button radius={6} style={{ height: 28 }} onClick={() => onRecordsAdd()}>
                添加一行
            </Button>
            {/* <Button radius={6} style={{ height: 28 }} onClick={open}>
                批量导入
            </Button> */}
            <ImportModal open={opened} envId={envId} appId={appId} dataSource={dataSource} disableSelectDataSource   defaultImportType="append" onClose={close} onAppend={onAppend} onImport={onImport} />
        </SCxContainer>
    )
}
