import { Toast } from '@lighthouse/bui'
import type { FieldInputADTValue, InputValueItem, ViewRecordOperateProtocol } from '@lighthouse/core'
import { type FieldBlockAbstract, PageType, RecordOpenType } from '@lighthouse/core'
import {
    type ApplicationPreviewEnum,
    aliyunVideoProtocolList,
    fieldFileFilter,
    fileMaxUploadSize,
    fileSuffixRegex,
    getFieldInputError,
    getFileSizeToMB,
    getPrimaryDataSourceEnableFieldIds,
    getUrlNameByOpenType,
    useAtomAction,
    useAtomData,
    useFormModuleContext
} from '@lighthouse/shared'
import { find } from 'rambda'
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { languageAtom } from '@/atoms/application/state'
import { pageAtomFamily, pageStackOfFieldBlockAtom, pageStackOfFormContainerBlockChangedAtom } from '@/atoms/page/state'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { useCurrentAppId, useCurrentEnvId, usePreview } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useFieldBlockMethods } from '@/hooks/useFieldBlockMethods'
import { usePageCurrentDsAndRecordByBlock } from '@/hooks/usePageCurrentDsAndRecordByBlock'
import { useRichTextToTitle } from '@/hooks/useRichTextToTitle'
import * as srv from '@/services'
import { uploadInDataSourceManagerParams, uploadVideoInDataSourceManagerParams } from '@/utils/auth'

const FieldBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.FieldBlock })))

interface FieldBlockControllerProps {
    blockData: FieldBlockAbstract
    previewType?: ApplicationPreviewEnum
}

const FieldBlockController: React.FC<FieldBlockControllerProps> = ({ blockData: fieldBlockData }) => {
    const { id: blockId, config } = fieldBlockData
    const { inputType, fieldPointer = '', required, canEdit } = config
    const [isValidFieldInput, setIsValidFieldInput] = useState(false)

    const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const previewType = usePreview()
    // const dataSource = useDataSource(appId, pointer)
    const navigate = useNavigate()
    const dataSourceList = useDataSourceList(appId)
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const currentPage = useAtomData(pageAtomFamily(pageId))
    const formModule = useFormModuleContext()
    const pointer = formModule?.pointer || (currentPage?.dsId ?? '')
    const currentPageDataSource = useDataSource(appId, envId, pointer)
    const language = useAtomData(languageAtom)
    const { handleRenderTitle } = useRichTextToTitle()

    const primaryDataSourceFieldIds = useMemo(() => {
        if (!currentPageDataSource) {
            return
        }
        return getPrimaryDataSourceEnableFieldIds(currentPageDataSource, dataSourceList)
    }, [currentPageDataSource, dataSourceList])

    const joinFieldCanEdit = useMemo(() => {
        if (currentPage?.type === PageType.creator || currentPage?.type === PageType.edit) {
            return primaryDataSourceFieldIds?.has(fieldPointer)
        }
        return true
    }, [currentPage?.type, fieldPointer, primaryDataSourceFieldIds])

    const blockData = useMemo(
        () => ({ ...fieldBlockData, config: { ...config, canEdit: canEdit && joinFieldCanEdit } }),
        [fieldBlockData, config, canEdit, joinFieldCanEdit]
    )

    const { currentBlockRecord, currentPageRecord, initRecord, fieldContent, currentValue, handleValueChange, setValue } =
        usePageCurrentDsAndRecordByBlock({
            blockData,
            pointer,
            disabled: Boolean(formModule?.type === 'form')
        })

    const pageName = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.name ?? '', [])
    )
    const { run: setPageStackOfFieldBlockAtom } = useAtomAction(pageStackOfFieldBlockAtom)
    const { run: setPageStackOfFormContainerBlockChangedAtom } = useAtomAction(pageStackOfFormContainerBlockChangedAtom)
    const fieldBlockValueMap = useAtomData(
        pageStackOfFieldBlockAtom,
        useCallback(s => s?.[stackId], [stackId])
    )

    const {
        isValid = true,
        errors,
        defaultValue: { inputList = [] },
        onChange: formOnChange
    } = formModule ?? {}

    const formCellValue = useMemo(() => find(item => item.id === blockId, inputList), [blockId, inputList])
    const field = useMemo(() => currentPageDataSource?.schema[fieldPointer], [currentPageDataSource?.schema, fieldPointer])

    const fieldError = useMemo(
        () =>
            field && isValidFieldInput
                ? getFieldInputError({
                      value: { value: currentValue, type: inputType } as FieldInputADTValue,
                      rule: {
                          required: {
                              label: '不能为空',
                              value: required ?? false
                          }
                      },
                      fieldType: field.type
                  })
                : undefined,
        [currentValue, field, inputType, isValidFieldInput, required]
    )

    const { value, record, dataSource, initValue, error } = useMemo(() => {
        const initCellContent = find(item => item.id === blockId, formModule?.defaultValue?.initInputList ?? [])
        return formModule?.type === 'form'
            ? {
                  value: formCellValue?.value,
                  initValue: initCellContent?.value,
                  dataSource: formModule?.dataSource,
                  error: errors?.[blockId]
              }
            : {
                  value: fieldContent,
                  initValue: initCellContent?.value,
                  record: currentBlockRecord,
                  dataSource: currentPageDataSource,
                  error: fieldError
              }
    }, [
        formModule?.defaultValue?.initInputList,
        formModule?.type,
        formModule?.dataSource,
        formCellValue?.value,
        errors,
        blockId,
        fieldContent,
        currentBlockRecord,
        currentPageDataSource,
        fieldError
    ])

    const handleSaveChange = useCallback(
        (v: FieldInputADTValue) => {
            formOnChange?.(blockId, v)
            setValue(v.value)
            setIsValidFieldInput(false)
            if (formModule.type === 'form') {
                setPageStackOfFormContainerBlockChangedAtom(draft => {
                    draft[stackId] = true
                })
            }
        },
        [blockId, formModule.type, formOnChange, setPageStackOfFormContainerBlockChangedAtom, setValue, stackId]
    )

    const handleChange = useCallback(
        (v: FieldInputADTValue) => {
            setValue(v.value)
            if (formModule.type === 'field') {
                handleValueChange(v)
            }
        },
        [formModule.type, handleValueChange, setValue]
    )

    const handleChangeSmsCode = useCallback(
        (v: string) => {
            if (formModule.type === 'form') {
                formModule?.onCodeChange?.(blockId, v)
            }
        },
        [blockId, formModule]
    )

    const handleOpenPage = useCallback(
        (params: ViewRecordOperateProtocol['creatingConfig']) => {
            const { page, openType = RecordOpenType.page } = params ?? {}
            if (page) {
                navigate(`./${getUrlNameByOpenType(openType)}/${page}`, { relative: 'path' })
            }
        },
        [navigate]
    )

    /** 获取关联数据源数据 开始 */
    const relativeDataSourceConfig = useMemo(
        () => [
            {
                config,
                value: formModule?.type === 'form' ? initValue : initRecord?.content?.[fieldPointer]?.value
            }
        ],
        [config, fieldPointer, formModule?.type, initRecord?.content, initValue]
    )

    const { onFetchDataSource, onLoadMoreData, onFetchPersonOptions, relativeDataSource, onFetchCascadeOptions } = useFieldBlockMethods({
        fieldBlocks: relativeDataSourceConfig,
        fieldBlockValueMap
    })
    /**  获取关联数据源数据 结束 */

    useEffect(() => {
        if (formModule.type === 'form' && formCellValue) {
            setPageStackOfFieldBlockAtom(draft => {
                if (!draft?.[stackId]) {
                    draft[stackId] = {}
                }
                draft[stackId][blockId] = { ...formCellValue, source: 'form', dsId: dataSource?.id }
            })
            return
        }
        setPageStackOfFieldBlockAtom(draft => {
            if (!draft?.[stackId]) {
                draft[stackId] = {}
            }

            draft[stackId][blockId] = {
                id: blockId,
                fieldId: fieldPointer,
                dsId: dataSource?.id,
                type: inputType,
                value: currentValue ?? '',
                fieldType: field?.type,
                source: 'field'
            } as InputValueItem
        })
    }, [
        blockId,
        currentValue,
        dataSource?.id,
        field?.type,
        fieldPointer,
        formCellValue,
        formModule.type,
        inputType,
        setPageStackOfFieldBlockAtom,
        stackId
    ])

    useEffect(() => {
        return () => {
            setPageStackOfFieldBlockAtom(draft => {
                if (draft?.[stackId]) {
                    Reflect.deleteProperty(draft?.[stackId], stackId)
                }
            })
        }
    }, [blockId, setPageStackOfFieldBlockAtom, stackId])

    const uploadyOptions = useMemo(
        () => ({
            // TODO: @kidrue id后续处理掉 不需要此参数
            info: { label: pageName, id: '', groupId: pageId },
            options: {
                ...uploadInDataSourceManagerParams({ dsId: pointer, appId, fieldId: fieldPointer, recordId: currentBlockRecord?.id }),
                fileFilter: fieldFileFilter
            }
        }),
        [appId, currentBlockRecord?.id, fieldPointer, pageId, pageName, pointer]
    )

    const videoUploadyOption = useMemo(
        () => ({
            // TODO: @kidrue id后续处理掉 不需要此参数
            info: { label: pageName, id: '', groupId: pageId },
            options: {
                ...uploadVideoInDataSourceManagerParams({ dsId: pointer, appId, fieldId: fieldPointer, recordId: currentBlockRecord?.id }),
                fileFilter: (file: File | string, index: number) => {
                    if (file instanceof File) {
                        if (file.size > fileMaxUploadSize) {
                            Toast.error(`不能上传大于 ${getFileSizeToMB(fileMaxUploadSize)}mb 的文件`)
                            return false
                        }
                        const extension = fileSuffixRegex.exec(file.name.toLocaleLowerCase())?.[1]?.toLocaleLowerCase()
                        if (!extension || !aliyunVideoProtocolList.includes(extension)) {
                            Toast.error('视频格式错误，请上传 MP4 格式的视频')
                            return false
                        }
                        return true
                    }
                    return true
                }
            }
        }),
        [appId, currentBlockRecord?.id, fieldPointer, pageId, pageName, pointer]
    )

    return (
        <Suspense fallback={<div />}>
            <FieldBlock
                videoUploadyOption={videoUploadyOption}
                uploadyOptions={uploadyOptions}
                richTextUploadOptions={uploadInDataSourceManagerParams({
                    dsId: pointer,
                    appId,
                    fieldId: fieldPointer,
                    recordId: currentBlockRecord?.id
                })}
                isValid={isValid}
                previewType={previewType}
                blockData={blockData}
                value={value}
                language={language}
                onChange={handleChange}
                onSaveChange={handleSaveChange}
                blockType={formModule?.type === 'form' ? 'form' : 'field'}
                dataSource={dataSource}
                record={record}
                error={error}
                onChangeSmsCode={handleChangeSmsCode}
                onOpenPage={handleOpenPage}
                onFetchDataSource={onFetchDataSource}
                onLoadMoreData={onLoadMoreData}
                onFetchCascadeOptions={onFetchCascadeOptions}
                dataSourceList={dataSourceList}
                relativeDataSource={relativeDataSource}
                onFetchDataSourceMeta={({ dsId }) => srv.getDsMeta(appId, dsId, pageId)}
                onFetchSmsCode={formModule?.type === 'form' ? mobile => srv.getVerifyCode({ mobile, blockId, pageId }) : undefined}
                onFetchPersonOptions={onFetchPersonOptions}
                onRenderTitle={handleRenderTitle}
            />
        </Suspense>
    )
}

export default FieldBlockController
