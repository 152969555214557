import { IconFont } from '@byecode/ui'
import { Checkbox } from '@mantine/core'
import cls from 'classnames'
import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'

export interface TableCheckerProps {
    index?: number
    checked?: boolean
    isRecordSelecting?: boolean
    height?: number
    checkable?: boolean
    showOpenerIcon?: boolean
    className?: string
    borderTop?: boolean
    extraBorderTop?: boolean
    // borderBottom?: boolean
    // extraBorder?: boolean
    isStripe?: boolean
    // isLast?: boolean
    borderRadius?: boolean
    disableCheck?: boolean
    onChange?: (checked: boolean) => void
    onRecordOpen?: () => void
}

const SCxTableRecordIndex = styled.p`
    position: absolute;
    color: var(--color-gray-400);
    opacity: 1;
`

const SCxTableRecordCheckerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    opacity: 0;
`

const SCxTableChecker = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 100%;
`

const SCxTableExtra = styled.div`
    position: absolute;
    right: 4px;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
`

const SCxTableOpener = styled(IconFont)`
    cursor: pointer;
    z-index: 1;
`

const SCxIcon = styled(IconFont)`
    font-size: 18px;
    color: var(--color-black);
`

export const SCxTableCheckerContent = styled.div<{ borderTop?: boolean; isStripe?: boolean }>`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${({ isStripe }) => (isStripe ? 'var(--color-gray-100)' : 'none')};
    border-top: ${props => (props.borderTop ? '1px solid var(--color-gray-200)' : 'none')};
`

export const SCxCheckerPrefix = styled.div<{
    borderTop?: boolean
    isStripe?: boolean
    borderRadius?: boolean
    height?: number
}>`
    width: 8px;
    height: ${props => (props.height ? `${props.height}px` : '37px')};
    background-color: ${({ isStripe }) => (isStripe ? 'var(--color-gray-100)' : 'none')};
    border-top: ${({ borderTop }) => (borderTop ? '1px solid var(--color-gray-200)' : 'none')};

    ${props =>
        props.borderRadius &&
        css`
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        `}


`

const SCxTableCheckerWrapper = styled.div<{ height?: number; border?: boolean }>`
    position: sticky;
    top: 0px;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: ${props => (props.height ? `${props.height}px` : '36px')};
    flex-shrink: 0;
    font-size: var(--font-size-normal);
    background-color: var(--color-white);
    overflow: hidden;

    border-top: ${props => (props.border ? '1px solid var(--color-gray-200)' : 'none')};

    &:hover {
        ${SCxTableExtra} {
            opacity: 1;
        }
    }

    &.table-checkable {
        &.checked,
        &:not(.table-checked):hover {
            ${SCxTableRecordCheckerWrapper} {
                opacity: 1;
            }
            ${SCxTableRecordIndex} {
                opacity: 0;
            }
            ${SCxIcon} {
                display: none;
            }
        }
    }
`

export const SCXCheckBox = styled(Checkbox)`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    [type='checkbox']:checked {
        border-color: var(--color-app-main);
        background-color: var(--color-app-main);
    }
`

export const TableChecker: React.FC<TableCheckerProps> = ({
    index,
    height,
    showOpenerIcon,
    checked,
    checkable,
    isRecordSelecting,
    className,
    borderTop,
    extraBorderTop,
    isStripe,
    borderRadius,
    disableCheck,
    onChange,
    onRecordOpen
}) => {
    const handleChange = useCallback(
        (ev: React.MouseEvent | React.ChangeEvent) => {
            ev.stopPropagation()
            if (!checkable) {
                return
            }
            onChange?.(!checked)
        },
        [checkable, checked, onChange]
    )
    return (
        <SCxTableCheckerWrapper height={height} className={cls({ checked, 'table-checkable': checkable }, className)}>
            <SCxCheckerPrefix height={height} borderRadius={borderRadius} isStripe={isStripe} borderTop={extraBorderTop} />

            {!disableCheck && (
                <SCxTableCheckerContent borderTop={borderTop} isStripe={isStripe}>
                    <SCxTableChecker>
                        {index === undefined ? <SCxIcon type="PropertyNumber" /> : <SCxTableRecordIndex>{index + 1}</SCxTableRecordIndex>}
                        <SCxTableRecordCheckerWrapper onClick={handleChange} style={isRecordSelecting ? { opacity: 1 } : {}}>
                            <SCXCheckBox size="xs" checked={checked} onChange={handleChange} />
                        </SCxTableRecordCheckerWrapper>
                    </SCxTableChecker>
                    {showOpenerIcon && (index || index === 0) && (
                        <SCxTableExtra>
                            <SCxTableOpener size={16} type="Open" onClick={onRecordOpen} />
                        </SCxTableExtra>
                    )}
                </SCxTableCheckerContent>
            )}
        </SCxTableCheckerWrapper>
    )
}
