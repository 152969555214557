import type { PopoverProps } from '@byecode/ui'
import { IconFont } from '@byecode/ui'
import { findParentScroller } from '@lighthouse/tools'
import { MantineProvider, Popover } from '@mantine/core'
import type { FC } from 'react'
import React, { startTransition, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { debounce } from 'throttle-debounce'

import type { VariableSelectDropDownProps } from '../../'
import { useTipTapContext } from '../Context'
import type { UploadImageOptions } from '../UploadImage'
import type { VariableExtensionOptions } from '../VariableBlock'
import { CodeMenu, ImageMenu, LineMenu, LinkMenu, QuoteMenu } from './AdvancedGroup'
import { AlignMenu } from './Align'
import { BgColorMenu, BoldMenu, ItalicMenu, StrikeMenu, TextColorMenu, UnderlineMenu } from './BasicGroup'
import { EraserMenu } from './Eraser'
import { FontSizeMenu } from './FontSize'
import { HeadingMenu } from './Heading'
import { RedoMenu, UndoMenu } from './History'
import { LineHeightMenu } from './LineHeight'
import { BulletListMenu, OrderedListMenu, TaskListMenu } from './ListGroup'
import { CustomActionIcon } from './styles'
import { TextGroup } from './Text'
import { VariableMenu } from './Variable'

const Container = styled.div`
    position: relative;
    box-sizing: border-box;
    height: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 8px;
    background-color: var(--color-white);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    /* border-bottom: 1px solid var(--color-gray-200); */
    /* box-shadow: var(--box-shadow); */
`

const SCxContainerDisabledMask = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: not-allowed;
`

const MenuList = styled.div`
    height: 24px;
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    overflow: hidden;
`

const DropdownContainer = styled(Container)`
    gap: 8px;
    border-radius: 6px;
    border-bottom: none;
`
export type ToolbarStyles = 'toolbarContainer' | 'toolbarMenuList'
export interface ToolbarProps {
    config?: {
        history?: boolean
        variable?: false | (Partial<VariableExtensionOptions> & Omit<PopoverProps, 'onSelect' | 'open' | 'children'>)
        image?: boolean | Partial<UploadImageOptions>
        line?: false
        heading?: boolean
        orderedList?: false
        bulletList?: false
        taskList?: boolean
        link?: boolean
        codeBlock?: boolean
        quote?: false
        basic?: boolean
        fontSize?: false
        align?: boolean
        lineHeight?: boolean
    }
    shrink?: boolean
    disabled?: boolean
    previewType?: 'mobile' | 'desktop'
    styles?: Partial<Record<ToolbarStyles, React.CSSProperties>>
}

type ToolbarListType = {
    key: string
    group: 'history' | 'heading' | 'eraser' | 'variable' | 'fontSize' | 'textGroup' | 'basic' | 'list' | 'advanced'
    component: FC<
        { shrink?: boolean; orderedList?: boolean; bulletList?: boolean; taskList?: boolean } & Partial<
            Pick<VariableSelectDropDownProps, 'options' | 'userOption' | 'systemOption'>
        >
    >
}

const toolbarBtnList: ToolbarListType[] = [
    { key: 'undo', group: 'history', component: UndoMenu },
    { key: 'redo', group: 'history', component: RedoMenu },

    { key: 'heading', group: 'heading', component: HeadingMenu },

    { key: 'variable', group: 'variable', component: VariableMenu },

    { key: 'eraser', group: 'eraser', component: EraserMenu },
    { key: 'fontSize', group: 'fontSize', component: FontSizeMenu },
    { key: 'textGroup', group: 'textGroup', component: TextGroup },

    { key: 'bold', group: 'basic', component: BoldMenu },
    { key: 'italic', group: 'basic', component: ItalicMenu },
    { key: 'strike', group: 'basic', component: StrikeMenu },
    { key: 'underline', group: 'basic', component: UnderlineMenu },
    { key: 'textColor', group: 'basic', component: TextColorMenu },
    { key: 'bgColor', group: 'basic', component: BgColorMenu },
    { key: 'align', group: 'basic', component: AlignMenu },
    { key: 'lineHeight', group: 'basic', component: LineHeightMenu },

    { key: 'orderedList', group: 'list', component: OrderedListMenu },
    { key: 'bulletList', group: 'list', component: BulletListMenu },
    { key: 'taskList', group: 'list', component: TaskListMenu },

    { key: 'link', group: 'advanced', component: LinkMenu },
    { key: 'codeBlock', group: 'advanced', component: CodeMenu },
    { key: 'image', group: 'advanced', component: ImageMenu },
    { key: 'quote', group: 'advanced', component: QuoteMenu },
    { key: 'line', group: 'advanced', component: LineMenu }
]

const TEXT_OPTIONS = new Set(['bold', 'italic', 'underline', 'strike'])

export const Toolbar: FC<ToolbarProps> = ({ config, shrink = true, disabled, previewType, styles }) => {
    const {
        history,
        heading,
        variable,
        bulletList = true,
        orderedList = true,
        taskList = true,
        line = true,
        link = true,
        image = true,
        quote = true,
        codeBlock = true,
        basic = true,
        fontSize = true,
        align = false,
        lineHeight = false
    } = config ?? {}

    const ref = useRef<HTMLDivElement | null>(null)

    // 超出隐藏的下标
    const [collapseIndex, setCollapseIndex] = useState(-1)

    const menuList = useMemo(() => {
        let list = toolbarBtnList

        if (!history) {
            list = list.filter(item => item.group !== 'history')
        }

        if (!heading && !bulletList && !orderedList && !taskList) {
            list = list.filter(item => item.group !== 'heading')
        }

        if (!variable) {
            list = list.filter(item => item.group !== 'variable')
        }

        if (!fontSize) {
            list = list.filter(item => item.key !== 'fontSize')
        }

        if (!align) {
            list = list.filter(item => item.key !== 'align')
        }
        if (!lineHeight) {
            list = list.filter(item => item.key !== 'lineHeight')
        }

        if (shrink) {
            list = list.filter(item => !TEXT_OPTIONS.has(item.key))
        } else {
            list = list.filter(item => item.group !== 'textGroup')
        }

        if (!basic) {
            list = list.filter(item => item.group !== 'basic')
        }

        if (!bulletList || shrink) {
            list = list.filter(item => item.key !== 'bulletList')
        }

        if (!orderedList || shrink) {
            list = list.filter(item => item.key !== 'orderedList')
        }
        if (!taskList || shrink) {
            list = list.filter(item => item.key !== 'taskList')
        }
        if (!line) {
            list = list.filter(item => item.key !== 'line')
        }
        if (!link) {
            list = list.filter(item => item.key !== 'link')
        }
        if (!image) {
            list = list.filter(item => item.key !== 'image')
        }
        if (!quote) {
            list = list.filter(item => item.key !== 'quote')
        }
        if (!codeBlock) {
            list = list.filter(item => item.key !== 'codeBlock')
        }

        return list
    }, [
        history,
        heading,
        bulletList,
        orderedList,
        taskList,
        variable,
        fontSize,
        align,
        lineHeight,
        shrink,
        basic,
        line,
        link,
        image,
        quote,
        codeBlock
    ])

    /** 监听尺寸变化，计算需要收起的工具栏按钮 */
    useEffect(() => {
        const content = ref.current
        if (!content || previewType === 'mobile') {
            return
        }

        const childWidthCache: number[] = []

        const handleResize = debounce(1000 / 60, (entries: ResizeObserverEntry[]) => {
            const [entry] = entries
            const contentWidth = entry.contentRect.width
            const { length } = content.children
            const gap = 8
            let reverseIndex = -1
            let currentChildWidth = 0

            for (let index = 0; index < length; index++) {
                const element = content.children.item(index)
                if (!element) {
                    break
                }

                if (element.clientWidth !== 0) {
                    childWidthCache.splice(index, 1, element.getBoundingClientRect().width)
                }
            }

            for (const [index, width] of childWidthCache.entries()) {
                currentChildWidth += width + gap

                if (currentChildWidth > contentWidth) {
                    reverseIndex = index
                    break
                }
            }

            setCollapseIndex(reverseIndex)
        })
        const observer = new ResizeObserver(handleResize)

        observer.observe(content)

        return () => observer.unobserve(content)
    }, [menuList.length, previewType])

    const editor = useTipTapContext()

    const [toolbarFocused, setToolbarFocused] = useState(false)

    /** 监听交叉区域，吸附时添加阴影 */
    const rootRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        const root = rootRef.current
        if (!root) {
            return
        }

        const observer = new IntersectionObserver(
            ([e]) => {
                if (e.intersectionRatio < 1) {
                    root.style.setProperty('border-bottom', '1px solid var(--color-gray-200)')
                } else {
                    root.style.removeProperty('border-bottom')
                }
            },
            { threshold: [1], root: findParentScroller(root), rootMargin: '-1px 0px 0px 0px' }
        )

        if (editor?.isFocused || toolbarFocused) {
            observer.observe(root)
        }

        return () => {
            root.style.removeProperty('border-bottom')
            observer.unobserve(root)
        }
    }, [editor?.isFocused, toolbarFocused])

    const variableConfig = useMemo(() => {
        if (typeof config?.variable === 'boolean' || !config?.variable) {
            return
        }
        return config.variable
    }, [config?.variable])

    return (
        <MantineProvider
            inherit
            theme={{
                colors: {
                    blue: [
                        'var(--color-theme-1)',
                        'var(--color-theme-2)',
                        'var(--color-theme-3)',
                        'var(--color-theme-4)',
                        'var(--color-theme-5)',
                        'var(--color-theme-6)',
                        'var(--color-theme-7)',
                        'var(--color-theme-8)',
                        'var(--color-theme-9)',
                        'var(--color-theme-10)'
                    ]
                }
            }}
        >
            <Container
                ref={rootRef}
                tabIndex={-1}
                onFocusCapture={e => {
                    setToolbarFocused(true)
                }}
                onBlurCapture={() => startTransition(() => setToolbarFocused(false))}
                style={{
                    ...styles?.toolbarContainer,
                    ...(editor?.isEditable && (editor.isFocused || toolbarFocused) ? { position: 'sticky', top: 0, zIndex: 1 } : {})
                }}
            >
                <MenuList ref={ref} style={styles?.toolbarMenuList}>
                    {menuList.slice(0, collapseIndex === -1 ? menuList.length : collapseIndex).map(item => (
                        <item.component
                            key={item.key}
                            {...variableConfig}
                            shrink={shrink}
                            taskList={taskList}
                            bulletList={bulletList}
                            orderedList={orderedList}
                        />
                    ))}
                </MenuList>
                {/* 超出时显示的菜单按钮 */}
                <Popover
                    withinPortal
                    position="bottom"
                    positionDependencies={[collapseIndex]}
                    shadow="md"
                    styles={{ dropdown: { padding: 0 } }}
                >
                    <Popover.Target>
                        <CustomActionIcon
                            // color="blue"
                            // variant="subtle"
                            style={{
                                display: collapseIndex === -1 ? 'none' : 'block',
                                pointerEvents: collapseIndex === -1 ? 'none' : undefined
                            }}
                        >
                            <IconFont type="DotsThree" />
                        </CustomActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <DropdownContainer>
                            {menuList.slice(collapseIndex, collapseIndex === -1 ? collapseIndex : undefined).map(item => (
                                <item.component key={`collapse-${item.key}`} {...variableConfig} />
                            ))}
                        </DropdownContainer>
                    </Popover.Dropdown>
                </Popover>
                {disabled && <SCxContainerDisabledMask />}
            </Container>
        </MantineProvider>
    )
}
