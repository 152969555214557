import { getAssetUrl } from '@lighthouse/assets'
import { Empty, IconFont } from '@lighthouse/bui'
import type { DTFile, FileBlockAbstract } from '@lighthouse/core'
import { FILE_ICON_MAP, FileItem, FilePreviewer, getFileNameByUrl, getFileSizeByUrl, getFileTypeByUrl } from '@lighthouse/shared'
import { getFileSizeWithUnit, nanoid } from '@lighthouse/tools'
import { clsx, Flex, Image, Text } from '@mantine/core'
import React, { useMemo, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import styled from 'styled-components'

interface FileBlockProps {
    id: string
    value?: DTFile[]
    config: FileBlockAbstract['config']
}

const SCxContainer = styled.div`
    padding: 4px;
    height: 100%;
    width: 100%;
    overflow: hidden;
`

const SCxWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`

const SCxFileContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    overflow: hidden;

    ::before {
        position: absolute;
        content: '';
        inset: 0;
        z-index: 1;
    }

    &.selected {
        box-shadow: 0 0 0 3px var(--color-gray-200);
        border: 1px solid var(--color-gray-500);
    }
`

const SCxCard = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    gap: 8px;
    background-color: var(--color-gray-100);
    border-radius: 8px;

    &.selected {
        box-shadow: 0 0 0 3px var(--color-gray-200);
        border: 1px solid var(--color-gray-500);
    }
`

const SCxTag = styled.div``

const FileBlock: React.FunctionComponent<FileBlockProps> = ({ id, config, value }) => {
    const { previewMode, fileSource } = config
    const [opened, setOpened] = useState(false)
    const [selected, setSelected] = useState(false)
    const ref = useRef<HTMLDivElement>(null)
    const modalId = nanoid()

    const fileUrl = useMemo(
        () =>
            value?.find(url => {
                const type = getFileTypeByUrl(url.split('?')[0])
                return type !== 'image' && type !== 'video'
            }),
        [value]
    )

    const file = useMemo(() => {
        if (!fileUrl) {
            return
        }
        return {
            name: getFileNameByUrl(fileUrl) || '',
            url: fileUrl,
            size: getFileSizeByUrl(fileUrl),
            type: getFileTypeByUrl(fileUrl)
        }
    }, [fileUrl])

    useClickAway(ref, e => {
        if (e.target instanceof Element) {
            const modalEle = document.querySelector(`[data-filePreviewId = '${modalId}']`)
            if (e.target && modalEle?.contains(e.target)) {
                return
            }
            setSelected(false)
        }
    })

    const contentEle = useMemo(() => {
        if (!fileSource) {
            return (
                <Empty
                    styles={{
                        wrapper: {
                            gap: 12
                        }
                    }}
                    icon={<Image height={140} fit="contain" src={getAssetUrl('empty', 'file_block_no_source_empty.png')} />}
                    description="请在右侧选择文件来源"
                />
            )
        }
        if (!file) {
            return (
                <Empty
                    icon={<Image height={195} fit="contain" src={getAssetUrl('empty', 'file_block_empty.png')} />}
                    description="没有文件"
                />
            )
        }
        if (previewMode === 'introduce') {
            return (
                <SCxCard className={clsx({ selected })}>
                    <SCxTag>
                        <IconFont type={FILE_ICON_MAP[file.type]} size={48} />{' '}
                    </SCxTag>
                    <Flex direction="column" gap={4}>
                        <Text size={12} color="var(--color-gray-600)" lineClamp={1} style={{ lineHeight: '24px' }} weight={500}>
                            {file.name}
                        </Text>
                        <Text size={12} color="var(--color-gray-400)" lineClamp={1} style={{ lineHeight: '20px' }}>
                            附件大小： {getFileSizeWithUnit(file.size)}
                        </Text>
                    </Flex>
                </SCxCard>
            )
        }
        return (
            <SCxFileContainer className={clsx({ selected })}>
                <FileItem prefix={`${id}_`} disabledSlider active file={file} />
            </SCxFileContainer>
        )
    }, [file, fileSource, id, previewMode, selected])

    return (
        <SCxContainer ref={ref}>
            <SCxWrapper onClick={() => setSelected(true)} onDoubleClick={e => setOpened(true)}>
                {contentEle}
            </SCxWrapper>
            <FilePreviewer fileList={file ? [file] : []} onClose={() => setOpened(false)} opened={opened} data-filePreviewId={modalId} />
        </SCxContainer>
    )
}

export default FileBlock
