import { Loading, Toast } from '@byecode/ui'
import type {
    AccountBindAccountForm,
    AccountBindMobileForm,
    AccountLoginForm,
    AccountMergeAccount,
    AccountPerfectForm,
    AccountRegisterForm,
    AppLoginMode,
    ErrorCode,
    SendCodeType
} from '@lighthouse/shared'
import {
    AccountPage,
    BindMobileResultStatus,
    LoginAuthType,
    needRegisterCodeList,
    ScanStatus,
    useAtomAction,
    useAtomData,
    useAuthenticationContext,
    USER_DATASOURCE
} from '@lighthouse/shared'
import { isWechatBrowser, removeURLParameter } from '@lighthouse/tools'
import i18next from 'i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { setLanguageAtom } from '@/atoms/application/action'
import { appAtom, languageAtom, websiteApplicationSettingAtom } from '@/atoms/application/state'
import { fetchUserAtom, loginAtom } from '@/atoms/auth/action'
import { homePageAtom } from '@/atoms/page/state'
import { useAccount } from '@/hooks/useAccount'
import { useApplication, useCurrentAppId, useCurrentEnvId, useCurrentVersionId, usePreview } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import { useWechatLogin } from '@/hooks/useWechatLogin'
import * as srv from '@/services'
import { useAppGroups } from '@/shared/reusable'

interface AccountProps {
    isFixedMode?: boolean
    defaultMode: AppLoginMode
}

const SCxContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    overflow: hidden;
`

export const WechatLoginLoading = styled(Loading)`
    position: absolute;
    inset: 0;
    background-color: rgba(255, 255, 255, 0.6);
`

export const Account: React.FC<AccountProps> = ({ isFixedMode, defaultMode }) => {
    const navigate = useNavigate()
    const [search] = useSearchParams()
    const appSetting = useAtomData(websiteApplicationSettingAtom)
    const isWxOfficialAccount = useAtomData(
        appAtom,
        useCallback(s => !!s?.integrations?.weChatOfficialAccount?.appId, [])
    )
    const authentication = useAuthenticationContext()
    const { login, perfect, register } = authentication
    const isReviewedRegister = register.isReviewed

    // const { ref } = useFixedBodyHeight()
    const { data: AppGroups } = useAppGroups()
    const previewType = usePreview()
    const appId = useCurrentAppId()
    const versionId = useCurrentVersionId()
    const envId = useCurrentEnvId()
    const dataSource = useDataSource(appId, envId, USER_DATASOURCE)
    const language = useAtomData(languageAtom)

    const { run: setLanguage } = useAtomAction(setLanguageAtom)
    const { run: doLogin } = useAtomAction(loginAtom)

    const accountDefaultMode = useMemo(() => {
        if (isFixedMode) {
            return defaultMode
        }
        const loginWayMap: Record<
            Exclude<AppLoginMode, 'bindMobile' | 'perfect' | 'register' | 'home' | 'bindEmail' | 'bindWechat'>,
            boolean | undefined
        > = {
            weChatLogin: login?.weChat.isOpened,
            emailLogin: login?.email.isOpened,
            mobileLogin: login?.phone.isOpened
        }
        const loginWayList = Object.entries(loginWayMap).filter(([name, isOpened]) => isOpened)
        const loginWay = loginWayList[0]
        if (loginWayList.length === 1 && loginWay[0] !== 'weChatLogin') {
            return loginWay[0] as AppLoginMode
        }
        return defaultMode
    }, [defaultMode, isFixedMode, login?.email.isOpened, login?.phone.isOpened, login?.weChat.isOpened])

    const [mode, setMode] = useState<AppLoginMode>(accountDefaultMode)

    const homePage = useAtomData(homePageAtom)
    const { run: fetchUserInfo } = useAtomAction(fetchUserAtom)

    const redirectUrl = useMemo(() => {
        const redirect = search.get('redirect') ?? ''
        const url = decodeURIComponent(redirect)
        return removeURLParameter(url, ['code', 'state'])
    }, [search])

    const navigateUrl = useMemo(() => {
        const landPageId = login.landPageId || homePage?.id
        return landPageId ? `/P/${landPageId}` : '/P'
    }, [login.landPageId, homePage?.id])

    const wxLoginRedirectUrl = useMemo(() => {
        search.delete('code')
        search.delete('state')
        const urlSearch = search.toString() ? `?${search.toString()}` : ''
        return redirectUrl || `${window.location.origin}/${navigateUrl}${urlSearch}`
    }, [navigateUrl, redirectUrl, search])

    const { handleWxBrowserWeChatLogin } = useWechatLogin(wxLoginRedirectUrl)

    useEffect(() => {
        setMode(accountDefaultMode)
    }, [accountDefaultMode])

    const skipMode: AppLoginMode = useMemo(() => {
        const loginWay = [
            login?.email.isOpened && 'emailLogin',
            login?.phone.isOpened && 'mobileLogin',
            login?.google.isOpened && 'googleLogin',
            login?.weChat && 'weChatLogin'
        ].filter(Boolean)
        return loginWay.length > 0 ? 'home' : ((loginWay[0] || 'home') as AppLoginMode)
    }, [login?.email.isOpened, login?.google.isOpened, login?.phone.isOpened, login?.weChat])

    const handleLogin = useCallback(
        async (data: AccountLoginForm) => {
            const { authType, code, mobile, email, mark } = data
            const [isSuccess, errCode] = await doLogin({ authType, mobile, email, verificationCode: code, appId, versionId, mark })
            if (needRegisterCodeList.includes(errCode.toString() as ErrorCode)) {
                register?.allowRegister && setMode('register')
                return
            }
            if (isSuccess) {
                Toast.success(i18next.t('loginSuccess'))
                const user = await fetchUserInfo()
                // 微信登录时，是否需要绑定手机号
                if (user.bindMobile) {
                    return setMode('bindMobile')
                }
                // 登录后是否需要完善信息
                if (perfect?.isOpened && !user.checkPerfect) {
                    return setMode('perfect')
                }
                redirectUrl ? window.location.replace(redirectUrl) : navigate(navigateUrl)
            }
        },
        [appId, doLogin, fetchUserInfo, navigate, navigateUrl, perfect?.isOpened, redirectUrl, register?.allowRegister, versionId]
    )
    const handleRegister = useCallback(
        async (params: AccountRegisterForm) => {
            const isSuccess = await srv.register(params)
            if (isSuccess) {
                // 是否需要完善信息
                if (perfect?.isOpened) {
                    setMode('perfect')
                    return
                }
                fetchUserInfo()
                // 是否需要审核
                if (isReviewedRegister) {
                    return navigate('/account/review')
                }
                return navigate({ pathname: navigateUrl }, { replace: true })
            }
        },
        [fetchUserInfo, isReviewedRegister, navigate, navigateUrl, perfect?.isOpened]
    )

    const handlePerfect = useCallback(
        async (params: AccountPerfectForm) => {
            const isSuccess = await srv.perfect(params)
            if (isSuccess) {
                fetchUserInfo()
                if (isReviewedRegister) {
                    return navigate('/account/review')
                }
                return redirectUrl ? window.location.replace(redirectUrl) : navigate(navigateUrl)
            }
        },
        [fetchUserInfo, isReviewedRegister, navigate, navigateUrl, redirectUrl]
    )

    const handleSendCodeHandle = useCallback((type: SendCodeType, param: string) => {
        return type === 'email' ? srv.getEmailCode(param) : srv.getSmsCode(param)
    }, [])

    const handleWeChatLogin = useCallback(() => {
        const isWxBrowser = isWechatBrowser()
        if (isWxBrowser) {
            handleWxBrowserWeChatLogin('login')
            return Promise.resolve(undefined)
        }
        return srv.getWechatLoginQr()
    }, [handleWxBrowserWeChatLogin])

    const handleBindMobile = useCallback(
        async (params: AccountBindMobileForm) => {
            const res = await srv.bindMobile(params)
            const user = await fetchUserInfo()
            if (res.status === BindMobileResultStatus.SUCCESS) {
                if (user.checkPerfect) {
                    redirectUrl ? window.location.replace(redirectUrl) : navigate(navigateUrl)
                } else {
                    setMode('perfect')
                }
            }
            return res.status === BindMobileResultStatus.SUCCESS
        },
        [fetchUserInfo, navigate, navigateUrl, redirectUrl]
    )

    const handleBindAccount = useCallback(
        async (params: AccountBindAccountForm) => {
            const { code, account = '' } = params
            const manualBinding = search.get('manualBinding')
            if (manualBinding) {
                const res = await srv.bindAccount(params)
                if (res === 'SUCCESS') {
                    await fetchUserInfo()
                    redirectUrl ? window.location.replace(redirectUrl) : navigate(navigateUrl)
                    return true
                }
                return false
            }
            return handleBindMobile({ code, mobile: account })
        },
        [fetchUserInfo, handleBindMobile, navigate, navigateUrl, redirectUrl, search]
    )

    const handleCheckWechatLogin = useCallback(
        async (mark: string) => {
            const res = await srv.checkWechatScanner(mark)
            if (res === ScanStatus.LOGIN || res === ScanStatus.REGISTER) {
                accountDefaultMode === 'bindWechat'
                    ? handleBindAccount({ code: mark, type: LoginAuthType.follow_wechat })
                    : handleLogin({ mark, authType: LoginAuthType.follow_wechat, mobile: '', email: '', code: '', fields: [] })
            }
            return res
        },
        [accountDefaultMode, handleBindAccount, handleLogin]
    )

    if (!authentication || !appSetting?.navbar) {
        return <Loading />
    }

    return (
        <SCxContainer>
            <AccountPage
                mode={mode}
                authentication={authentication}
                previewType={previewType}
                dataSource={dataSource}
                groups={AppGroups}
                language={language}
                navbar={appSetting?.navbar}
                isWxOfficialAccount={isWxOfficialAccount}
                onChangeMode={setMode}
                onLogin={handleLogin}
                onRegister={handleRegister}
                onSendCode={handleSendCodeHandle}
                onPerfect={handlePerfect}
                onWeChatLogin={handleWeChatLogin}
                onBindAccount={handleBindAccount}
                onChangeLanguage={v => {
                    setLanguage(v)
                    setMode(accountDefaultMode)
                }}
                onHomeBack={() => {
                    window.location.href = window.location.origin
                }}
                onCheckWechatLogin={handleCheckWechatLogin}
            />
            {/* <DomainFiling data={domain} isMobile={previewType === ApplicationPreviewEnum.mobile} /> */}
        </SCxContainer>
    )
}
