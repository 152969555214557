import type { ChartType, FileType, SmsPlatForm, ViewType } from '@lighthouse/core'

import type { AvatarSize } from '../types'

export const PAGE_CONTAINER_HOST = '#host-page-container'
export const PAGE_CONTAINER_HOST_ID = 'host-page-container'
export const PAGE_SCROLL_CONTAINER_HOST = 'page-scroll-container'
export const PAGE_MODAL = 'page-model'
export const APP_MAIN_COLOR_VARIABLE = '--color-app-main'

export const PAGE_LAYOUT_HOST = 'host-page-layout'

export const defaultDateFormat = 'yyyy/MM/dd HH:mm'

export const defaultZhDateFormat = 'yyyy年MM月dd日'

export const CURRENT_USER = { userId: '{currentUserId}', uniqueUserId: '{currentUserId}', username: '当前用户', mobile: '' }

export const DOMAIN_EDIT_SUFFIX = '-edit'
// export const FILE_EXCEL_ACCEPT = { 'application/vnd.ms-excel': [/* '.xls',  */'.xlsx', '.csv'] }
export const FILE_EXCEL_ACCEPT = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [/* '.xls',  */ '.xlsx'],
    'text/csv': ['.csv']
}

export const avatarSize: Record<AvatarSize, number> = {
    large: 40,
    middle: 32,
    small: 20,
    xLarge: 80
}

export const CHART_ICON_MAP: Record<ChartType, string> = {
    bar: 'ChartBar',
    line: 'ChartLine',
    composite: 'ChartCompound',
    pie: 'ChartPie',
    funnel: 'ChartFunnel',
    indicator: 'ChartNumCard',
    striation: 'ChartBarHorizontal'
}

export const VIEW_ICON_MAP: Record<ViewType, string> = {
    table: 'NewTableBlock',
    advancedTable: 'NewTableBlock',
    list: 'ListBlock',
    gallery: 'Gallery',
    kanban: 'Kanban',
    calendar: 'CalendarBlock',
    custom: 'CustomizingViews'
}
// 文件图标
export const FILE_ICON_MAP: Record<FileType, string> = {
    image: 'Image',
    video: 'FileVideo',
    audio: 'FileAudio',
    pdf: 'FilePdf',
    doc: 'FileDoc',
    ppt: 'FilePpt',
    txt: 'FileTxt',
    xls: 'FileXsl',
    archive: 'FileZip',
    other: 'FileNothing',
    xlsx: 'FileXsl'
}

// 文件后缀到类型映射表
export const FILE_EXTENSION_TO_TYPE: Record<string, FileType> = {
    // image type
    jpg: 'image',
    gif: 'image',
    bmp: 'image',
    png: 'image',
    jpeg: 'image',
    svg: 'image',
    webp: 'image',
    // video type
    mp4: 'video',
    // avi: 'video',
    mov: 'video',
    // wmv: 'video',
    hls: 'video',
    '3gp': 'video',
    mkv: 'video',
    rmvb: 'video',
    flv: 'video',
    webm: 'video',
    // audio type
    mp3: 'audio',
    wma: 'audio',
    rm: 'audio',
    mpg: 'audio',
    // document type
    pdf: 'pdf',
    doc: 'doc',
    docx: 'doc',
    ppt: 'ppt',
    pptx: 'ppt',
    xls: 'xls',
    xlsx: 'xls',
    txt: 'txt',
    // archive type
    zip: 'archive',
    rar: 'archive'
}

export const RATIO_OPTIONS = [
    {
        label: '原图比例',
        value: '0'
    },
    {
        label: '2:3',
        value: '1',
        icon: '23',
        iconColor: 'var(--color-gray-400)'
    },
    {
        label: '3:4',
        value: '2',
        icon: '34',
        iconColor: 'var(--color-gray-400)'
    },
    {
        label: '9:16',
        value: '3',
        icon: '916',
        iconColor: 'var(--color-gray-400)'
    },
    {
        label: '3:2',
        value: '4',
        icon: '32',
        iconColor: 'var(--color-gray-400)'
    },
    {
        label: '4:3',
        value: '5',
        icon: '43',
        iconColor: 'var(--color-gray-400)'
    },
    {
        label: '16:9',
        value: '6',
        icon: '169',
        iconColor: 'var(--color-gray-400)'
    },
    {
        label: '16:6',
        value: '8',
        icon: '166',
        iconColor: 'var(--color-gray-400)'
    },
    {
        label: '1:1',
        value: '7',
        icon: 'Square',
        iconColor: 'var(--color-gray-400)',
        disable: true
    }
]

export const DATE_FORMAT_OPTIONS = [
    { label: '年/月/日 00:00', value: 'yyyy/MM/dd HH:mm' },
    { label: '年-月-日 00:00', value: 'yyyy-MM-dd HH:mm' },
    { label: '日-月-年 00:00', value: 'dd-MM-yyyy HH:mm' },
    { label: '年/月/日', value: 'yyyy/MM/dd' },
    { label: '年-月-日', value: 'yyyy-MM-dd' },
    { label: '日-月-年', value: 'dd-MM-yyyy' },
    { label: '年/月', value: 'yyyy/MM' },
    { label: '年-月', value: 'yyyy-MM' }
]

export const NUMBER_FORMAT_OPTIONS = [
    { label: '1', value: '0' },
    { label: '1.0', value: '1' },
    { label: '1.00', value: '2' },
    { label: '1.000', value: '3' },
    { label: '1.0000', value: '4' },
    { label: '1.00000', value: '5' },
    { label: '1.000000', value: '6' }
]

export const popoverMaxHeight = 600

export const CURRENT_RECORD = 'CURRENT_RECORD'

export const SERVICES_IP = '47.100.53.7'

export const SORT_OPTIONS = [
    { label: '升序', value: 'ASC', icon: 'SortDescending' },
    { label: '降序', value: 'DESC', icon: 'SortAscending' }
]

export const smsPlatformMap: Record<SmsPlatForm, string> = { ALIYUN: '阿里云短信' }
