import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import clsx from 'clsx'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { render } from 'react-dom'
import { useToggle } from 'react-use'

import { Box } from '../Box'
import { Empty } from '../Empty'
import { IconFont } from '../IconFont'
import { Input } from '../Input'
import { Text } from '../Text'
import { useStyles } from './CascadeList.styles'
import type { CascadeListProps, CascadeOption } from './CascadeList.types'

const emptyArr: CascadeOption[] = []
export const CascadeList = React.forwardRef<HTMLDivElement, CascadeListProps>(
    (
        {
            data,
            options = emptyArr,
            columnWidth = 212,
            lastLevel = true,
            multiple = false,
            searchPlaceholder = '搜索',
            searchable,
            classNames,
            unstyled,
            tabIndex,
            showPath,
            className,
            emptyProps,
            styles,
            style,
            itemChildrenComponent,
            onPathChange,
            onChange
        },
        ref
    ) => {
        const cursorRef = useRef<HTMLInputElement>(null)
        const [keywords, setKeywords] = useState('')
        const [_value = [], _Change] = useUncontrolled({ value: data, onChange })

        const [list, setList] = useState([options])

        const { classes } = useStyles(
            { columnWidth: keywords ? '100%' : columnWidth },
            { name: 'CascadeList', classNames, styles, unstyled }
        )

        const flatOptions = useMemo(() => {
            function getChildOption(option: CascadeOption, list: CascadeOption[]) {
                const { value, label, path, labelPath, children = [] } = option
                let arrList = list
                for (const child of children) {
                    const newChild = {
                        ...child,
                        path: `${path}/${child.value}`,
                        labelPath: `${labelPath}/${child.label}`,
                        isLast: (child.children ?? []).length === 0
                    }
                    arrList = [...arrList, newChild, ...getChildOption(newChild, [])]
                }
                return arrList
            }
            return options.reduce<CascadeOption[]>((pre, cur) => {
                const newOption = { ...cur, path: cur.value, labelPath: cur.label, isLast: (cur.children ?? []).length === 0 }
                return [...pre, newOption, ...getChildOption(newOption, [])]
            }, [])
        }, [options])

        const valueOptionPath = useMemo(
            () =>
                _value
                    .map(v => {
                        return flatOptions.find(option => option.value === v)?.labelPath ?? ''
                    })
                    .join(','),
            [_value, flatOptions]
        )

        useEffect(() => {
            setList([options])
        }, [options])

        const handleSearchValueChange = useCallback(
            (ev: React.ChangeEvent<HTMLInputElement>) => {
                const word = ev.target.value.trim()
                setKeywords(word)
                const newOptions = flatOptions.filter(option => option.label.includes(word) && (lastLevel ? option.isLast : true))
                setList([word === '' ? options : newOptions])
            },
            [flatOptions, lastLevel, options]
        )

        const handleSelect = useCallback(
            (data: CascadeOption, index: number) => {
                const option = flatOptions.find(option => {
                    return option.value === data.value
                })
                if (!option?.path) {
                    return
                }
                if (multiple) {
                    const newValue = [..._value, option.value]
                    _Change(newValue)
                    return
                }
                _Change([option.value])
            },
            [_Change, _value, flatOptions, multiple]
        )

        const handlePathSelect = useCallback(
            (data: CascadeOption, index: number) => {
                const option = flatOptions.find(option => {
                    return option.value === data.value
                })
                if (!option) {
                    return
                }
                const isLast = (option.children ?? []).length === 0
                const newList = list.slice(0, index + 1)
                setList(isLast ? newList : [...newList, option.children ?? []])
                onPathChange?.(option)
                // 如果是最后 或者 不强制选择最后一级时始终调用handleSelect
                if (isLast || !lastLevel) {
                    handleSelect(option, index)
                }
            },
            [flatOptions, list, onPathChange, lastLevel, handleSelect]
        )

        return (
            <Box className={clsx(classes.root, className)} ref={ref} style={style} tabIndex={tabIndex}>
                <Box className={classes.content}>
                    {searchable && (
                        <Box className={classes.cursor}>
                            <Input
                                value={keywords}
                                placeholder={searchPlaceholder}
                                onChange={handleSearchValueChange}
                                ref={cursorRef}
                                prefix={<IconFont type="SearchLine" color="var(--color-gray-400)" size={16} />}
                                className={classes.search}
                                suffix={
                                    keywords && (
                                        <IconFont
                                            type="CloseCircle"
                                            color="var(--color-gray-400)"
                                            size={16}
                                            onClick={() => {
                                                setKeywords('')
                                                setList([options])
                                            }}
                                        />
                                    )
                                }
                            />
                        </Box>
                    )}
                    {showPath && (
                        <Box classNames={classes.path}>
                            <Text color="var(--color-gray-400)" size={14}>
                                已选：{valueOptionPath}
                            </Text>{' '}
                        </Box>
                    )}
                    <Box className={classes.columns}>
                        {list.map((columns, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Box className={classes.column} key={index}>
                                <Box className={classes.list}>
                                    {columns.map(column => (
                                        <Box
                                            key={column.value}
                                            className={clsx(classes.item, { active: true })}
                                            onClick={() => {
                                                handlePathSelect(column, index)
                                            }}
                                        >
                                            {itemChildrenComponent ? (
                                                itemChildrenComponent?.(column)
                                            ) : (
                                                <>
                                                    <Text
                                                        className={classes.label}
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                keywords === ''
                                                                    ? column.label
                                                                    : column.labelPath?.replaceAll(
                                                                          keywords,
                                                                          `<span style="color: var(--color-main);">${keywords}</span>`
                                                                      ) ?? ''
                                                        }}
                                                    />
                                                    {_value.includes(column.value) && (
                                                        <IconFont
                                                            onClick={e => {
                                                                e.stopPropagation()
                                                                handlePathSelect(column, index)
                                                            }}
                                                            type="Tick"
                                                            size={16}
                                                            className={classes.activeIcon}
                                                            color="var(--color-main)"
                                                        />
                                                    )}
                                                    {(column.children ?? []).length > 0 && (
                                                        <IconFont
                                                            onClick={e => {
                                                                e.stopPropagation()
                                                                handlePathSelect(column, index)
                                                            }}
                                                            type="ArrowRightSmall"
                                                            size={16}
                                                            color="var(--color-gray-400)"
                                                            className={classes.arrow}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                    ))}

                                    {columns.length === 0 && (
                                        <Empty description="没有找到数据" icon="Nodata-8i554976" style={{ height: '100%' }} {...emptyProps} />
                                    )}
                                </Box>
                            </Box>
                        ))}
                        {list.length === 0 && (
                            <Empty className={classes.empty} icon="Nodata-8i554976" description="没有找到数据" style={{ height: '100%' }} {...emptyProps} />
                        )}
                    </Box>
                </Box>
            </Box>
        )
    }
)
