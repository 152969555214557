import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { CSSProperties } from 'react'

interface ImageStylesParams {
    radius?: CSSProperties['borderRadius']
    fit?: CSSProperties['objectFit']
    width?: string | number
    height?: string | number
}

export const useStyles = createStyles(({ radius, fit, height, width }: ImageStylesParams) => {
    return {
        root: css({
            width,
            height
        }),
        image: css({
            objectFit: fit,
            borderRadius: radius,
            width: '100%',
            height: '100%'
        })
    }
})
