import { atom } from 'jotai'
import { RESET } from 'jotai/utils'

import * as srv from '@/services'
import type { LoginProtocolPayload } from '@/services/types'

import { fetchApplicationAtom } from '../application/action'
import { appAtom, currentAppIdAtom, currentEnvIdAtom } from '../application/state'
import { userDataAtom, userRecordAtom /*  refreshTokenAtom,  */ } from '../auth/state'

export const loginAtom = atom(null, async (_, set, payload: LoginProtocolPayload) => {
    const res = await srv.login(payload)
    const [isSuccess, errCode]= res
    if (isSuccess) {
        set(appAtom, s => {
            if (s) {
                s.loggedIn = true
            }
        })
    }
    return res
})

export const logoutAtom = atom(null, async (_, set) => {
    const isSuccess = await srv.logout()
    if (isSuccess) {
        set(userDataAtom, RESET)
        set(userRecordAtom, undefined)
        set(appAtom, draft => {
            if (draft) {
                draft.loggedIn = false
            }
        })
    }
})

export const fetchUserAtom = atom(null, async (get, set) => {
    const userData = await srv.getUser()
    if (userData?.userId) {
        const appId = get(currentAppIdAtom)
        const envId = get(currentEnvIdAtom)
        set(userDataAtom, userData)
        const res = await srv.getUserRecord(appId, envId)
        if (res.record) {
            set(userRecordAtom, res.record)
        }
        return userData
    }
    return get(userDataAtom)
})

// export const setRefreshTokenAtom = atom(null, (_, set, payload: { value: string }) => {
//     set(refreshTokenAtom, payload)
// })
