import { DIRECTION } from '@lighthouse/core'
import { mergeRefs } from '@lighthouse/tools'
import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'

import { useFlowContainerContext, useFlowLayoutContext } from '../../Context'
import { useContainerHeight } from '../../hooks/useContainerHeight'
import { ContainerOutline } from '../../Sortable/ContainerOutline'
import { useResizeEventsContext, useSortableMonitor } from '../../Sortable/Context'
import type { FlowLayoutCustomNode } from '../../types'
import { Toolbar } from './Toolbar'

type StyledProps = {
    disabled?: boolean
}

const OutlineWrapper = styled.div`
    position: absolute;
    inset: 0;
    pointer-events: none;
    display: none;
`

const StyledNode = styled.div<StyledProps>`
    flex-shrink: 0;
    position: relative;
    ${({ disabled }) => [
        !disabled && [
            css`
                &:not([data-selected='true']):hover ${OutlineWrapper} {
                    display: block;
                }
            `
        ]
    ]}

    &[data-box-selection='true'], &[data-selected='true'] {
        outline: 2px solid var(--color-main);
        outline-offset: -1px;
    }

    &[data-highlight='true'] {
        background-color: #5551ff1f;
        outline: 2px solid var(--color-main);
        outline-offset: -1px;
    }
`

export interface FlowLayoutCustomNodeProps extends React.ComponentPropsWithoutRef<'div'> {
    data: FlowLayoutCustomNode
    unstyled?: boolean
    disabled?: boolean
    onDataDrawerVisible?: (val: boolean) => void
}

export const CustomNode = forwardRef<HTMLDivElement, FlowLayoutCustomNodeProps>((props, ref) => {
    const { data, style, unstyled, disabled: propDisabled, onDataDrawerVisible, children, ...rest } = props
    const { isMobile, highlight, rows, disabled: globalDisabled, nodeRender: NodeRender = React.Fragment } = useFlowLayoutContext()
    const disabled = propDisabled ?? globalDisabled
    const { direction, mobileAdaptive, nodeUnitWidth } = useFlowContainerContext()
    const { isResizing } = useResizeEventsContext()
    const { selectedId, boxSelectionIds, activeId } = useSortableMonitor()

    const { ref: innerRef, height } = useContainerHeight()

    const isDragging = !!activeId
    const isSelected = selectedId === data.id
    const isBoxSelection = !!boxSelectionIds?.includes(data.id)

    const width = direction === DIRECTION.horizontal && isMobile && mobileAdaptive ? rows : data.width
    const mergeStyle: React.CSSProperties = {
        width: unstyled ? '100%' : `calc(${width} * var(--node-width-unit))`,
        height: unstyled ? '100%' : data.height ? `calc(${data.height} * var(--node-height-unit))` : undefined,
        ...style
    }

    return (
        <StyledNode
            ref={mergeRefs([innerRef, ref])}
            style={mergeStyle}
            data-type="custom"
            data-selected={!unstyled && !disabled && isSelected}
            data-box-selection={!unstyled && !disabled && isBoxSelection}
            data-node-id={data.id}
            data-highlight={!!highlight?.[data.id]?.self}
            disabled={disabled || isDragging || isResizing}
            {...rest}
        >
            <NodeRender node={data} />
            {children}

            {/* 交互时指示边框 */}
            {disabled ? null : (
                <OutlineWrapper>
                    <ContainerOutline width={width * nodeUnitWidth} height={height} />
                </OutlineWrapper>
            )}

            {/* 工具栏 */}
            {isSelected && !unstyled && !disabled && !isDragging && (
                <Toolbar data={data} referenceRef={innerRef} onDataDrawerVisible={onDataDrawerVisible} />
            )}
        </StyledNode>
    )
})
