import { arrow, useFloating } from '@floating-ui/react'
import type { AnyObject } from 'immer/dist/internal'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { get, useFormContext } from 'react-hook-form'
import styled, { css } from 'styled-components'

import type { ErrorMessageProps } from './types'

const SCxContainer = styled.div<{ isRelative?: boolean; zIndex?: number | string }>`
    width: 100%;
    flex: 1;
    z-index: ${({ zIndex }) => zIndex ?? 10};

    ${({ isRelative }) =>
        isRelative &&
        css`
            position: relative;
        `}
`

// const PortalErrorMessageTooltip = styled(Tooltip)`
//     outline: none;
//     &.arrow-left {
//         .MuiTooltip-arrow {
//             transform: translate3d(4px, 0px, 0px) !important;
//         }

//         .MuiTooltip-tooltip {
//             margin-bottom: -2px !important;
//         }
//     }

//     .MuiTooltip-arrow {
//         color: #ec7671;
//         margin-bottom: -7px !important;
//     }

//     .MuiTooltip-tooltip {
//         padding-top: 6px;
//         padding-bottom: 6px;
//         background-color: #ec7671;
//         margin-bottom: 0px !important;
//     }
// `

const SCxErrorMessageTooltip = styled.div`
    opacity: 1;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform-origin: center bottom;
    margin-bottom: -12px !important;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: #f88f90;
    border-radius: 2px;
    color: rgb(255, 255, 255);
    padding: 4px 8px;
    font-size: 14px;
    max-width: 300px;
    margin: 0px;
    outline: none;
    overflow-wrap: break-word;
    font-weight: 500;
    position: relative;
`

const SCxArrow = styled.div`
    color: #f88f90;
    /* margin-bottom: -9px !important; */
    transform: translate3d(8px, 0px, 0px) !important;
    position: absolute;
    bottom: -6px;
    overflow: hidden;
    width: 1em;
    height: 0.71em;
    box-sizing: border-box;

    :before {
        content: '';
        margin: auto;
        display: block;
        width: 100%;
        height: 100%;
        background-color: currentcolor;
        transform: rotate(45deg);
        transform-origin: 100% 0px;
    }
`

export function ErrorMessage<TFieldErrors extends AnyObject>({
    show = true,
    errors,
    name,
    message = '',
    children,
    style,
    arrowAlign,
    enablePortal = true,
    isRelative = true,
    zIndex,
    ...rest
}: ErrorMessageProps<TFieldErrors>) {
    const methods = useFormContext()
    const arrowRef = useRef(null)
    const [open, setOpen] = useState(false)

    const error: Error | undefined = get(errors || (methods ? methods.formState.errors : undefined), name)

    const messageFromRegister = error?.message ?? message

    useEffect(() => {
        if (messageFromRegister) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [messageFromRegister])
    // const props = Object.assign({}, rest, {
    //     children: messageFromRegister || message
    // })

    const alignCenter = arrowAlign === 'center'

    const { x, y, refs, strategy } = useFloating({
        placement: alignCenter ? 'top' : 'top-start',
        middleware: [arrow({ element: arrowRef })],
        strategy: enablePortal ? 'absolute' : 'fixed'
    })

    const toolTip = useMemo(() => {
        if (enablePortal) {
            return (
                <SCxErrorMessageTooltip
                    ref={refs.setFloating}
                    style={{
                        position: strategy,
                        top: y ? y - 6 : '',
                        left: x ?? '',
                        zIndex: 1
                    }}
                >
                    {messageFromRegister}
                    <SCxArrow ref={arrowRef} style={{ left: alignCenter ? 'calc(50% - 6px)' : 0 }} />
                </SCxErrorMessageTooltip>
            )
        }
        return createPortal(
            <SCxErrorMessageTooltip
                ref={refs.setFloating}
                style={{
                    position: strategy,
                    top: y ? y - 6 : '',
                    left: x ?? '',
                    zIndex: 2000
                }}
            >
                {messageFromRegister}
                <SCxArrow ref={arrowRef} style={{ left: alignCenter ? 'calc(50% - 6px)' : 0 }} />
            </SCxErrorMessageTooltip>,
            document.body
        )
    }, [alignCenter, enablePortal, messageFromRegister, refs.setFloating, strategy, x, y])

    return (
        <SCxContainer isRelative={isRelative} zIndex={zIndex}>
            {show && open && toolTip}
            <div style={style} ref={refs.setReference}>
                {children}
            </div>
        </SCxContainer>
    )
}
