import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import type { FC } from 'react'
import React from 'react'

import { Box } from '../../Box'
import { IconFont } from '../../IconFont'
import { Input } from '../../Input'
import type { SegmentedControlItem } from '../../SegmentedControl'
import { SegmentedControl } from '../../SegmentedControl'
import type { TableProps } from '../Table.type'

const useStyles = createStyles(() => ({
    toolbar: css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 16
    }),
    'toolbar-left': css(),
    'toolbar-right': css({
        display: 'flex',
        alignItems: 'center',
        gap: '4px'
    })
}))

export type TableToolbarStyleNames = Selectors<typeof useStyles>

export interface TableToolbarProps
    extends Pick<TableProps, 'tabs' | 'search' | 'onSearchValueChange' | 'actions'>,
        StyleComponentProps<TableToolbarStyleNames>,
        React.ComponentPropsWithoutRef<'div'> {}

export const TableToolbar: FC<TableToolbarProps> = props => {
    const { styles, classNames, unstyled, className, tabs, search, onSearchValueChange, actions, ...rest } = props
    const { classes } = useStyles({}, { name: 'toolbar', styles, classNames, unstyled })
    return (
        <Box className={clsx(classes.toolbar, className)} {...rest}>
            <Box className={classes['toolbar-left']}>{tabs && <SegmentedControl {...tabs} />}</Box>
            <Box className={classes['toolbar-right']}>
                {search && <Input prefix={<IconFont type="Search" />} placeholder="搜索" onChange={onSearchValueChange} />}
                {actions}
            </Box>
        </Box>
    )
}
