import type {
    AppUser,
    BlockAbstract,
    BoolValue,
    DataSourceAbstract,
    Field,
    FieldADTValue,
    FieldBlockWithDsId,
    FieldCellValue,
    FilterBlockValue,
    FilterCommonCondition,
    FilterConditions,
    FilterGroupType,
    FilterOption,
    InputValueItem,
    NumberValue,
    RecordData,
    RichTextContentProtocol,
    SelectValue,
    SimpleTextValue,
    SystemVariable,
    VariableADTField
} from '@lighthouse/core'
import {
    type Conditions,
    type FilterFormType,
    type ValueVariable,
    type VariableADTvalue,
    DateFieldRange,
    FilterStatus,
    VariableType
} from '@lighthouse/core'
import {
    addDays,
    addWeeks,
    endOfDay,
    endOfMinute,
    endOfMonth,
    endOfYear,
    getDay,
    isDate,
    isSameDay,
    isSameMinute,
    lightFormat,
    previousDay,
    startOfDay,
    startOfMinute,
    startOfMonth,
    startOfYear,
    subDays,
    subMonths
} from 'date-fns'
import equal from 'fast-deep-equal'
import { clone, find, intersection, isEmpty } from 'rambda'

import { generateText, isEmptyRichTextValue } from '../components'
import { defaultDateFormat, defaultFormat, USER_DATASOURCE, variableSystemNameMap, visibleFilterOperatorText } from '../constants'
import type { CurrPageDatasourceForVariable, PrevPageDatasourceForVariable } from '../types'
import { resolveFilter } from './actionsEngine'
import { isCheckboxValue, isIdsValue, isNumberValue, isTextValue } from './helper'
// id 为记录的ID字段的值 或 用户id
export type CustomViewVisibleData = {
    name: string
    dataSource: DataSourceAbstract
}

export const getDateConfig = (field: Field | undefined) => {
    if (field?.type === 'date') {
        const format = field.date?.format || defaultDateFormat
        if (format?.includes('HH')) {
            return { range: DateFieldRange.MINUTE, format }
        }
        if (format?.includes('dd')) {
            return { range: DateFieldRange.DAY, format }
        }
        return { range: DateFieldRange.MONTH, format }
    }
    return { range: DateFieldRange.MINUTE, format: defaultDateFormat }
}

const getRecordCondition: (conditions: Conditions, id?: string) => Conditions = (conditions, id) => {
    return conditions.map(item => {
        // const condition = item as FilterConditions
        if (item.conditions) {
            return {
                ...item,
                conditions: getRecordCondition(item.conditions, id)
            }
        }
        // TODO： @kidrue
        // const newParams = item.params.map((param: string) => {
        //     if (param === '{currentRecordId}' && item.fieldId) {
        //         return id ?? ''
        //     }
        //     return param
        // })
        return {
            ...item,
            params: []
        }
    })
}

export const getNumber = (value: string | number) => {
    return value || value === 0 ? Number(value) : undefined
}

export const booleanToFilterStatus = (bool: boolean) => {
    if (bool) {
        return FilterStatus.pass
    }
    return FilterStatus.noPass
}

export const getRecordPageFilter = (filter?: FilterFormType, id?: string): FilterFormType | undefined => {
    if (!filter) {
        return filter
    }
    if (filter?.expression && filter.expression?.conditions) {
        const newConditions = getRecordCondition(filter.expression.conditions, id)
        return {
            expression: {
                where: filter.expression?.where || 'AND',
                conditions: newConditions
            }
        }
    }
    return filter
}

export const getDateSystemValue: (systemVariable: SystemVariable) => FieldADTValue | undefined = systemVariable => {
    const systemValue = systemVariable.systemVariable?.value

    return { id: '', value: systemValue, type: 'date' } as FieldADTValue
}

export const getDateByOperator = (operator: string, date: number, index: number) => {
    switch (operator) {
        case '=': {
            return date
        }
        case '<=': {
            return endOfDay(date).valueOf()
        }
        case '>=': {
            return startOfDay(date).valueOf()
        }
        case 'between': {
            if (index === 0) {
                return startOfDay(date).valueOf()
            }
            return endOfDay(date).valueOf()
        }
        default: {
            return date
        }
    }
}

type GetDateValuePayload = {
    firstParam: FieldCellValue
    secondParam?: FieldCellValue
}

export const getDateValue: (payload: GetDateValuePayload) => number | [number, number] | undefined = ({ firstParam, secondParam }) => {
    const today = Date.now()
    switch (firstParam) {
        case 'AFTER_SOMEWHAT_DAYS': {
            if (secondParam && Number.isNaN(Number(secondParam))) {
                const first = addDays(today, Number(secondParam)).valueOf()
                return [startOfDay(first).valueOf(), Infinity]
            }
            return undefined
        }
        case 'BEFORE_SOMEWHAT_DAYS': {
            if (secondParam && Number.isNaN(Number(secondParam))) {
                const first = subDays(today, Number(secondParam)).valueOf()
                // return getDateByOperator(operator, first, index)
                return [0, first]
            }
            return undefined
        }
        case 'NOW':
        case 'TODAY': {
            return [startOfDay(today).valueOf(), endOfDay(today).valueOf()]
            // return getDateByOperator(operator, today, index)
        }
        case 'TOMORROW': {
            const date = addDays(today, 1).valueOf()
            return [startOfDay(date).valueOf(), endOfDay(date).valueOf()]
        }
        case 'YESTERDAY': {
            const date = subDays(today, 1).valueOf()
            return [startOfDay(date).valueOf(), endOfDay(date).valueOf()]
        }
        case 'THIS_WEEK': {
            // nextDay
            const day = getDay(today)
            const weekStartDay = startOfDay(previousDay(today, -day + 1)).valueOf()
            const weekEndDay = endOfDay(addDays(today, 7 - day)).valueOf()
            return [weekStartDay, weekEndDay]
        }
        case 'LAST_WEEK': {
            const day = getDay(today)
            const prevDay = addWeeks(today, -1)
            const weekStartDay = startOfDay(previousDay(prevDay, -day + 1)).valueOf()
            const weekEndDay = endOfDay(addDays(prevDay, 7 - day)).valueOf()
            return [weekStartDay, weekEndDay]
        }
        case 'THIS_MONTH': {
            const start = startOfMonth(today).valueOf()
            const end = endOfMonth(today).valueOf()
            return [start, end]
        }
        case 'LAST_MONTH': {
            const prevMonthDay = subMonths(today, 1)
            const start = startOfMonth(prevMonthDay).valueOf()
            const end = endOfMonth(prevMonthDay).valueOf()
            return [start, end]
        }
        case 'THIS_YEAR': {
            const start = startOfYear(today).valueOf()

            const end = endOfYear(today).valueOf()
            return [start, end]
        }
        case 'PAST_SEVEN_DAYS': {
            const start = startOfDay(subDays(today, 7)).valueOf()
            const end = endOfDay(subDays(today, 7)).valueOf()
            return [start, endOfDay(today).valueOf()]
        }
        case 'FUTURE_SEVEN_HEAVENS': {
            const start = startOfDay(addDays(today, 7)).valueOf()
            const end = endOfDay(addDays(today, 7)).valueOf()
            return [startOfDay(today).valueOf(), end]
        }
        case 'PAST_THIRTY_DAYS': {
            const start = startOfDay(subDays(today, 30)).valueOf()
            const end = endOfDay(subDays(today, 30)).valueOf()
            return [start, endOfDay(today).valueOf()]
        }
        case 'FUTURE_THIRTY_HEAVENS': {
            const start = startOfDay(addDays(today, 30)).valueOf()
            const end = endOfDay(addDays(today, 30)).valueOf()
            return [startOfDay(today).valueOf(), end]
        }

        default: {
            return isNumberValue(firstParam) ? Number(firstParam) : undefined
        }
    }
}

type GetVariableToFieldValuePayload = {
    idVariable: VariableADTvalue
    selfRecord?: RecordData
    currentRecord?: RecordData
    prevRecord?: RecordData
    viewRecord?: RecordData
    userRecord?: RecordData
    pageStackFormState?: Record<string, InputValueItem>
    isValidValue?: boolean
    currentPageLink?: string
    dataSourceList?: DataSourceAbstract[]
}

export const getVariableToFieldValue: (payload: GetVariableToFieldValuePayload) => FieldADTValue | undefined | false = ({
    idVariable,
    selfRecord,
    currentRecord,
    prevRecord,
    viewRecord,
    userRecord,
    pageStackFormState,
    isValidValue,
    currentPageLink,
    dataSourceList
}) => {
    if (idVariable.type === VariableType.FIELD_ID) {
        if (!idVariable.fieldIdVariable?.fieldId) {
            return false
        }
        const { fieldId } = idVariable.fieldIdVariable
        const selfRecordContent = selfRecord?.record?.content
        const selfSchema = selfRecord?.datasource?.schema
        if (!selfRecordContent) {
            return false
        }

        const field = selfSchema?.[fieldId]
        if (!field) {
            return false
        }
        return { ...field, value: selfRecordContent?.[fieldId]?.value } as FieldADTValue
    }
    if (idVariable.type === VariableType.VALUE) {
        const idVariableValue = idVariable.valueVariable?.value
        if (isValidValue) {
            return { ...idVariable.valueVariable, value: idVariableValue, type: idVariable.valueVariable?.type, id: '' } as FieldADTValue
        }
        if (
            !idVariableValue &&
            idVariableValue !== 0 && // 处理 0 的情况
            idVariable.valueVariable?.type !== 'checkbox' &&
            typeof idVariableValue !== 'boolean'
        ) {
            return undefined
        }
        if (Array.isArray(idVariableValue) && idVariableValue.length === 0) {
            return undefined
        }

        return { ...idVariable.valueVariable, value: idVariableValue, type: idVariable.valueVariable?.type, id: '' } as FieldADTValue
    }

    if (idVariable.type === VariableType.USER) {
        if (!userRecord) {
            return false
        }
        const { datasource, record } = userRecord
        if (datasource) {
            const { schema: userSchema } = datasource
            const { fieldId = '' } = idVariable.userVariable || {}
            const userField = userSchema?.[fieldId]
            if (!userField) {
                return false
            }
            const userFieldValue = record?.content?.[fieldId]?.value
            return { ...userField, value: userFieldValue } as FieldADTValue
        }
        return false
    }

    if (idVariable.type === VariableType.PAGE_LINK) {
        return { value: currentPageLink, type: 'url', innerType: 'TEXT', id: '' } as FieldADTValue
    }

    if (idVariable.type === VariableType.INPUT) {
        // dataSourceList

        const { blockId = '' } = idVariable.inputVariable ?? {}
        const blockValue = pageStackFormState?.[blockId]
        if (!blockValue) {
            return false
        }
        const { fieldType, value, dsId, fieldId } = blockValue
        const ds = find(ds => ds.id === dsId, dataSourceList || [])
        if (!ds) {
            return false
        }
        const { schema } = ds
        const field = schema[fieldId]
        if (!field) {
            return false
        }
        return { value, ...field } as FieldADTValue
    }

    if (idVariable.type === VariableType.SYSTEM) {
        return getDateSystemValue(idVariable)
    }

    if (idVariable.type === VariableType.VIEW) {
        const fieldId = idVariable.viewVariable?.fieldId
        if (!fieldId) {
            return false
        }
        const viewRecordContent = viewRecord?.record?.content
        const viewSchema = viewRecord?.datasource?.schema
        const cellValue = viewRecordContent?.[fieldId]?.value
        const field = viewSchema?.[fieldId]
        if (!field) {
            return false
        }
        return { ...field, value: cellValue } as FieldADTValue
    }

    if (idVariable.type === VariableType.PAGE) {
        if (!idVariable.pageVariable?.fieldId) {
            return false
        }
        const currentRecordContent = currentRecord?.record?.content
        const currentSchema = currentRecord?.datasource?.schema

        const prevRecordContent = prevRecord?.record?.content
        const prevSchema = prevRecord?.datasource?.schema
        const { fieldId = '' } = idVariable.pageVariable

        const cellValue =
            idVariable.pageVariable.type === 'parentPage' ? prevRecordContent?.[fieldId]?.value : currentRecordContent?.[fieldId]?.value
        const field = idVariable.pageVariable.type === 'parentPage' ? prevSchema?.[fieldId] : currentSchema?.[fieldId]
        if (!field) {
            return false
        }
        return { ...field, value: cellValue } as FieldADTValue
    }
    return undefined
}

export const verifyTextFilter = (value: SimpleTextValue | SimpleTextValue[], operator: string, param: FieldCellValue) => {
    const paramValue = Array.isArray(param) ? String(param.join(',')) : String(param)
    const textValue = Array.isArray(value) ? value.join(',') : value || ''
    switch (operator) {
        case '=': {
            return booleanToFilterStatus(textValue === paramValue)
        }
        case '!=': {
            return booleanToFilterStatus(textValue !== paramValue)
        }
        case 'contains': {
            if (textValue && !paramValue) {
                return FilterStatus.noPass
            }
            return booleanToFilterStatus(textValue.includes(paramValue))
        }
        case 'notContains': {
            if (textValue && !paramValue) {
                return FilterStatus.noPass
            }
            return booleanToFilterStatus(!textValue.includes(paramValue))
        }
        case 'startWith': {
            if (textValue && !paramValue) {
                return FilterStatus.noPass
            }
            return booleanToFilterStatus(textValue.startsWith(paramValue))
        }
        case 'endWith': {
            if (textValue && !paramValue) {
                return FilterStatus.noPass
            }
            return booleanToFilterStatus(textValue.endsWith(paramValue))
        }
        case 'isEmpty': {
            return booleanToFilterStatus(!textValue)
        }
        case 'isNotEmpty': {
            return booleanToFilterStatus(!!textValue)
        }
        default: {
            return FilterStatus.init
        }
    }
}

export const verifyNumberFilter = (value: NumberValue | NumberValue[], operator: string, param: FieldCellValue) => {
    const paramValue = param || param === 0 ? Number(param) : undefined
    const paramNum = paramValue ?? Number.NaN
    const originNumberValue = Array.isArray(value) ? value?.[0] : value
    const num = getNumber(originNumberValue)
    const numValue = num ?? Number.NaN
    switch (operator) {
        case '=': {
            return booleanToFilterStatus(num === paramValue)
        }
        case '!=': {
            return booleanToFilterStatus(num !== paramValue)
        }
        case 'contains': {
            if (Array.isArray(value)) {
                return booleanToFilterStatus(value.includes(paramValue || ''))
            }

            return FilterStatus.noPass
        }
        case 'notContains': {
            if (Array.isArray(value)) {
                return booleanToFilterStatus(!value.includes(paramValue || ''))
            }

            return FilterStatus.noPass
        }
        case '>': {
            return booleanToFilterStatus(numValue > paramNum)
        }
        case '>=': {
            return booleanToFilterStatus(numValue >= paramNum)
        }
        case '<': {
            return booleanToFilterStatus(numValue < paramNum)
        }
        case '<=': {
            return booleanToFilterStatus(numValue <= paramNum)
        }
        case 'isEmpty': {
            return booleanToFilterStatus(Number.isNaN(numValue))
        }
        case 'isNotEmpty': {
            return booleanToFilterStatus(!Number.isNaN(numValue))
        }
        default: {
            return FilterStatus.init
        }
    }
}

export const verifySelectFilter = (value: SelectValue, operator: string, paramValue: FieldCellValue) => {
    const selectValue = value || []
    const data = isIdsValue(paramValue) ? paramValue : isTextValue(paramValue) ? [paramValue] : []
    switch (operator) {
        case '=': {
            return booleanToFilterStatus(equal(selectValue, data))
        }
        case '!=': {
            return booleanToFilterStatus(!equal(selectValue, data))
        }
        case 'contains': {
            return booleanToFilterStatus(intersection(selectValue, data).length > 0)
        }
        case 'notContains': {
            return booleanToFilterStatus(intersection(selectValue, data).length === 0)
        }
        case 'isEmpty': {
            return booleanToFilterStatus(isEmpty(selectValue) || !value)
        }
        case 'isNotEmpty': {
            return booleanToFilterStatus(!isEmpty(selectValue) && !!value)
        }
        default: {
            return FilterStatus.init
        }
    }
}

export const verifyCheckboxFilter = (value: BoolValue, operator: string, paramValue: FieldCellValue) => {
    const data = isCheckboxValue(paramValue) ? paramValue : isTextValue(paramValue) ? paramValue : undefined

    // if (data === undefined) {
    //     return FilterStatus.init
    // }
    const textValue = Array.isArray(value) ? value.join(',') : value || false
    switch (operator) {
        case '=': {
            return booleanToFilterStatus(equal(textValue, data))
        }
        case '!=': {
            return booleanToFilterStatus(!equal(textValue, data))
        }

        case 'contains': {
            if (typeof textValue === 'string' && typeof data === 'string') {
                return booleanToFilterStatus(textValue.includes(data))
            }
            return FilterStatus.noPass
        }
        case 'notContains': {
            if (typeof textValue === 'string' && typeof data === 'string') {
                return booleanToFilterStatus(!textValue.includes(data))
            }
            return FilterStatus.noPass
        }
        // case 'contains': {
        //     return booleanToFilterStatus(intersection(value, data).length > 0)
        // }
        // case 'notContains': {
        //     return booleanToFilterStatus(intersection(value, data).length === 0)
        // }
        case 'isEmpty': {
            return booleanToFilterStatus(isEmpty(value) || !value)
        }
        case 'isNotEmpty': {
            return booleanToFilterStatus(!isEmpty(value) && !!value)
        }
        default: {
            return FilterStatus.init
        }
    }
}

export const verifyNoteFilter = (
    value: RichTextContentProtocol | RichTextContentProtocol[],
    operator: string,
    paramValue: FieldCellValue
) => {
    switch (operator) {
        case 'contains': {
            if (Array.isArray(value)) {
                return booleanToFilterStatus(
                    value.some(itemValue => {
                        const fieldValue = generateText(itemValue)
                        return fieldValue.includes(String(paramValue))
                    })
                )
            }
            const fieldValue = generateText(value)
            return booleanToFilterStatus(fieldValue.includes(String(paramValue)))
        }
        case 'notContains': {
            if (Array.isArray(value)) {
                return booleanToFilterStatus(
                    value.every(itemValue => {
                        const fieldValue = generateText(itemValue)
                        return !fieldValue.includes(String(paramValue))
                    })
                )
            }
            const fieldValue = generateText(value)
            return booleanToFilterStatus(!fieldValue.includes(String(paramValue)))
        }
        case 'isEmpty': {
            if (Array.isArray(value)) {
                return booleanToFilterStatus(
                    value.every(itemValue => {
                        return isEmptyRichTextValue(itemValue)
                    })
                )
            }
            return booleanToFilterStatus(isEmptyRichTextValue(value))
        }
        case 'isNotEmpty': {
            if (Array.isArray(value)) {
                return booleanToFilterStatus(
                    value.some(itemValue => {
                        return !isEmptyRichTextValue(itemValue)
                    })
                )
            }
            return booleanToFilterStatus(!isEmptyRichTextValue(value))
        }
        default: {
            return FilterStatus.init
        }
    }
}

// const getDateFieldValue = (value: DateValue, range: DateFieldRange | undefined) => {
//     if()
// }

export const verifyDateFilter = (dateFieldValue: FieldADTValue, operator: string, paramList: FieldCellValue[]) => {
    if (dateFieldValue.type !== 'date') {
        return FilterStatus.noPass
    }
    const { value } = dateFieldValue
    const firstParam = paramList?.[0]
    const secondParam = paramList?.[1]
    // if (!firstParam) {
    //     return FilterStatus.init
    // }
    const { range } = getDateConfig(dateFieldValue) || {}
    const num = getNumber(value)
    const numValue = num || Number.NaN

    const dateValue = getDateValue({ firstParam, secondParam })
    if (!dateValue && operator !== 'isEmpty' && operator !== 'isNotEmpty') {
        return FilterStatus.noPass
    }
    switch (operator) {
        case '=': {
            if (Array.isArray(dateValue)) {
                if (range === DateFieldRange.MONTH) {
                    const start = startOfMonth(numValue).valueOf()
                    const end = endOfMonth(numValue).valueOf()
                    return booleanToFilterStatus(dateValue[0] <= start && end <= dateValue[1])
                }
                return booleanToFilterStatus(dateValue[0] <= numValue && numValue <= dateValue[1])
            }
            if (range === DateFieldRange.MONTH) {
                const start = startOfMonth(numValue).valueOf()
                const end = endOfMonth(numValue).valueOf()
                return booleanToFilterStatus(start <= Number(dateValue) && Number(dateValue) <= end)
            }
            if (range === DateFieldRange.MINUTE) {
                return booleanToFilterStatus(isSameMinute(Number(value), Number(dateValue)))
            }
            return booleanToFilterStatus(isSameDay(Number(value), Number(dateValue)))
        }
        case 'between':
        case 'contains': {
            if (Array.isArray(dateValue)) {
                if (range === DateFieldRange.MONTH) {
                    const start = startOfMonth(numValue).valueOf()
                    const end = endOfMonth(numValue).valueOf()
                    return booleanToFilterStatus(dateValue[0] <= start && end <= dateValue[1])
                }
                return booleanToFilterStatus(dateValue[0] <= numValue && numValue <= dateValue[1])
            }
            if (range === DateFieldRange.MONTH) {
                const start = startOfMonth(numValue).valueOf()
                const end = endOfMonth(numValue).valueOf()
                return booleanToFilterStatus(start <= Number(dateValue) && Number(dateValue) <= end)
            }
            if (range === DateFieldRange.MINUTE) {
                const start = startOfMinute(numValue).valueOf()
                const end = endOfMinute(numValue).valueOf()
                return booleanToFilterStatus(start <= Number(dateValue) && Number(dateValue) <= end)
            }
            if (range === DateFieldRange.DAY) {
                const start = startOfDay(numValue).valueOf()
                const end = endOfDay(numValue).valueOf()
                return booleanToFilterStatus(start <= Number(dateValue) && Number(dateValue) <= end)
            }
            return booleanToFilterStatus(isSameDay(Number(value), Number(dateValue)))
        }
        case '<=': {
            if (Array.isArray(dateValue)) {
                if (range === DateFieldRange.MONTH) {
                    const v = endOfMonth(numValue).valueOf()
                    return booleanToFilterStatus(v <= dateValue[0])
                }
                if (range === DateFieldRange.DAY) {
                    const v = endOfDay(numValue).valueOf()
                    return booleanToFilterStatus(v <= dateValue[0])
                }
                const v = endOfMinute(numValue).valueOf()
                return booleanToFilterStatus(v <= dateValue[0])
            }
            if (range === DateFieldRange.MONTH) {
                const v = endOfMonth(numValue).valueOf()
                return booleanToFilterStatus(v <= Number(dateValue))
            }
            if (range === DateFieldRange.DAY) {
                const v = endOfDay(numValue).valueOf()
                return booleanToFilterStatus(v <= Number(dateValue))
            }
            const v = endOfMinute(numValue).valueOf()
            return booleanToFilterStatus(v <= Number(dateValue))
        }
        case '>=': {
            if (Array.isArray(dateValue)) {
                if (range === DateFieldRange.MONTH) {
                    const v = startOfMonth(numValue).valueOf()
                    return booleanToFilterStatus(v >= dateValue[0])
                }
                if (range === DateFieldRange.DAY) {
                    const v = startOfDay(numValue).valueOf()
                    return booleanToFilterStatus(v >= dateValue[0])
                }
                const v = startOfMinute(numValue).valueOf()
                return booleanToFilterStatus(v >= dateValue[0])
            }
            if (range === DateFieldRange.MONTH) {
                const v = startOfMonth(numValue).valueOf()
                return booleanToFilterStatus(v >= Number(dateValue))
            }
            if (range === DateFieldRange.DAY) {
                const v = startOfDay(numValue).valueOf()
                return booleanToFilterStatus(v >= Number(dateValue))
            }
            const v = startOfMinute(numValue).valueOf()
            return booleanToFilterStatus(v >= Number(dateValue))
        }
        case '<': {
            if (Array.isArray(dateValue)) {
                if (range === DateFieldRange.MONTH) {
                    const v = endOfMonth(numValue).valueOf()
                    return booleanToFilterStatus(v < dateValue[0])
                }
                if (range === DateFieldRange.DAY) {
                    const v = endOfDay(numValue).valueOf()
                    return booleanToFilterStatus(v < dateValue[0])
                }
                const v = endOfMinute(numValue).valueOf()
                return booleanToFilterStatus(v < dateValue[0])
            }
            if (range === DateFieldRange.MONTH) {
                const v = endOfMonth(numValue).valueOf()
                return booleanToFilterStatus(v < Number(dateValue))
            }
            if (range === DateFieldRange.DAY) {
                const v = endOfDay(numValue).valueOf()
                return booleanToFilterStatus(v < Number(dateValue))
            }
            const v = endOfMinute(numValue).valueOf()
            return booleanToFilterStatus(v < Number(dateValue))
        }
        case '>': {
            if (Array.isArray(dateValue)) {
                if (range === DateFieldRange.MONTH) {
                    const v = startOfMonth(numValue).valueOf()
                    return booleanToFilterStatus(v > dateValue[0])
                }
                if (range === DateFieldRange.DAY) {
                    const v = startOfDay(numValue).valueOf()
                    return booleanToFilterStatus(v > dateValue[0])
                }
                const v = startOfMinute(numValue).valueOf()
                return booleanToFilterStatus(v > dateValue[0])
            }
            if (range === DateFieldRange.MONTH) {
                const v = startOfMonth(numValue).valueOf()
                return booleanToFilterStatus(v > Number(dateValue))
            }
            if (range === DateFieldRange.DAY) {
                const v = startOfDay(numValue).valueOf()
                return booleanToFilterStatus(v > Number(dateValue))
            }
            const v = startOfMinute(numValue).valueOf()
            return booleanToFilterStatus(v > Number(dateValue))
        }
        case 'isEmpty': {
            return booleanToFilterStatus(!value || Number.isNaN(Number(value)))
        }
        case 'isNotEmpty': {
            return booleanToFilterStatus(!!value && isDate(new Date(value)))
        }
        default: {
            return FilterStatus.init
        }
    }
}

export const verifyDefaultFilter = (value: FieldCellValue, operator: string) => {
    switch (operator) {
        case 'isEmpty': {
            if (Array.isArray(value)) {
                return booleanToFilterStatus(value.length === 0)
            }
            return booleanToFilterStatus(!value)
        }
        case 'isNotEmpty': {
            if (Array.isArray(value)) {
                return booleanToFilterStatus(value.length > 0)
            }
            return booleanToFilterStatus(!!value)
        }
        default: {
            return FilterStatus.init
        }
    }
}

type CheckOperatorPayload = {
    selfRecord?: RecordData
    currentRecord?: RecordData
    prevRecord?: RecordData
    viewRecord?: RecordData
    userRecord: RecordData
    fieldValue: FieldADTValue | false | undefined
    operator: string
    paramList: VariableADTvalue[] | undefined
    pageStackFormState: Record<string, InputValueItem> | undefined
    currentPageLink?: string
    dataSourceList?: DataSourceAbstract[]
}

export const checkOperator: (payload: CheckOperatorPayload) => FilterStatus = payload => {
    const {
        selfRecord,
        currentRecord,
        prevRecord,
        viewRecord,
        userRecord,
        fieldValue,
        operator,
        paramList,
        pageStackFormState,
        currentPageLink,
        dataSourceList
    } = payload
    if (typeof fieldValue === 'boolean') {
        return FilterStatus.noPass
    }
    let checkStatus: FilterStatus | undefined
    const data = paramList?.map((item, index) => {
        if (item.type === VariableType.FIELD_ID) {
            checkStatus = FilterStatus.init
        }

        const filterValue = getVariableToFieldValue({
            idVariable: item,
            selfRecord,
            currentRecord,
            prevRecord,
            viewRecord,
            userRecord,
            pageStackFormState,
            currentPageLink,
            dataSourceList
        })
        if (typeof filterValue === 'boolean') {
            checkStatus = FilterStatus.noPass
            return {
                value: filterValue
            }
        }
        if (!filterValue) {
            if (index === 0) {
                checkStatus = FilterStatus.init
            }
            return { value: filterValue }
        }
        if (!filterValue.value && typeof filterValue.value === 'boolean') {
            return { value: filterValue.value }
        }
        const format = filterValue.type === 'date' ? filterValue.date?.format : undefined
        return {
            value: filterValue?.value ?? '',
            format
        }
    }) as { value: FieldCellValue; format?: string }[]
    const isHasParams = isEmptyOperator(operator)
    if ((checkStatus === FilterStatus.noPass || checkStatus === FilterStatus.init) && !isHasParams) {
        return checkStatus
    }
    const firstParamValue = data?.[0].value
    if (firstParamValue === undefined && !isHasParams) {
        return FilterStatus.init
    }

    const { type, value, innerType } = fieldValue || { type: 'richText', value: '', innerType: 'TEXT' }

    switch (type) {
        case 'textGeneration':
        case 'id':
        case 'text':
        case 'url':
        case 'phoneNumber':
        case 'userGroup':
        case 'email': {
            return verifyTextFilter(value, operator, firstParamValue)
        }
        case 'number': {
            return verifyNumberFilter(value, operator, firstParamValue)
        }
        case 'userDepartment':
        case 'selectGenerationByText':
        case 'select': {
            return verifySelectFilter(value, operator, firstParamValue)
        }
        case 'person': {
            // const paramValue = firstParam.valueVariable?.value
            const data = isIdsValue(firstParamValue) ? firstParamValue : []
            const ids = data.map(id => {
                if (id === '{currentUserId}' && userRecord.record) {
                    return userRecord.record.content?.['ID']?.value || id
                }
                return id
            })

            return verifySelectFilter(value, operator, ids)
        }

        case 'date': {
            if (!fieldValue) {
                return FilterStatus.noPass
            }
            return verifyDateFilter(fieldValue, operator, data)
        }

        case 'checkbox': {
            return verifyCheckboxFilter(value, operator, firstParamValue)
        }
        case 'notes': {
            return verifyNoteFilter(value, operator, firstParamValue)
        }
        case 'aggregation':
        case 'formula': {
            if (innerType === 'TEXT') {
                return verifyTextFilter(String(value || ''), operator, firstParamValue)
            }
            if (innerType === 'NUMBER') {
                return verifyNumberFilter(value as number, operator, firstParamValue)
            }
            if (innerType === 'DATE') {
                if (!fieldValue) {
                    return FilterStatus.noPass
                }
                return verifyDateFilter(fieldValue, operator, data)
            }
            if (innerType === 'ARRAY') {
                const arrayValue = value || []
                if (isIdsValue(arrayValue)) {
                    return verifySelectFilter(arrayValue, operator, firstParamValue)
                }
                return verifyTextFilter(String(arrayValue || ''), operator, firstParamValue)
            }
            if (innerType === 'BOOL') {
                const boolValue = value === undefined ? false : value
                if (typeof boolValue === 'boolean') {
                    return verifyCheckboxFilter(boolValue, operator, firstParamValue)
                }
                return FilterStatus.noPass
            }
            return FilterStatus.noPass
        }
        default: {
            return verifyDefaultFilter(value, operator)
        }
    }
}

export const getIsEmptyValueVariable = (data: ValueVariable[], operator: string | undefined) => {
    if (operator === 'between') {
        return data.length !== 2
    }
    return data.reduce((prev, cur) => {
        if (!cur.valueVariable?.value || isEmpty(cur.valueVariable?.value)) {
            prev = true
        }
        return prev
    }, false)
}

export const filterStatusToBoolean = (filterStatus: FilterStatus) => {
    return filterStatus === FilterStatus.init || filterStatus === FilterStatus.pass
}

type FilterRecordUnmatchedPayload = {
    where: 'AND' | 'OR'
    conditions: FilterConditions[]
    selfRecord?: RecordData
    currentRecord?: RecordData
    prevRecord?: RecordData
    viewRecord?: RecordData
    userRecord: RecordData
    pageStackFormState: Record<string, InputValueItem> | undefined
    currentPageLink?: string
    dataSourceList?: DataSourceAbstract[]
}

export const filterRecordUnmatched: (payload: FilterRecordUnmatchedPayload) => FilterStatus = ({
    where,
    conditions,
    selfRecord,
    currentRecord,
    prevRecord,
    userRecord,
    viewRecord,
    pageStackFormState,
    currentPageLink,
    dataSourceList
}) => {
    if (conditions.length === 0) {
        return FilterStatus.init
    }
    const statusArr = conditions.reduce<FilterStatus[]>((prev, cur) => {
        const childrenConditions = (cur as FilterGroupType)?.conditions
        const childrenWhere = (cur as FilterGroupType)?.where
        if (childrenConditions && childrenWhere) {
            const status = filterRecordUnmatched({
                where: childrenWhere,
                conditions: childrenConditions,
                currentRecord,
                prevRecord,
                viewRecord,
                userRecord,
                pageStackFormState,
                dataSourceList
            })
            if (status !== FilterStatus.init) {
                prev.push(status)
            }
            return prev
        }
        const condition = cur as FilterCommonCondition
        const { idVariable, operator, paramList } = condition
        // const field = find(item => item.fieldId === fieldId, viewFields)
        if (!idVariable || !operator || !paramList) {
            return prev
        }
        // const data: ValueVariable[] = paramList?.filter(item => item.type === VariableType.VALUE) as ValueVariable[] || []
        // const isEmptyValueVariable = getIsEmptyValueVariable(data)
        // if (isEmptyValueVariable) {
        //     return prev
        // }
        const fieldValue = getVariableToFieldValue({
            idVariable,
            selfRecord,
            currentRecord,
            prevRecord,
            viewRecord,
            userRecord,
            pageStackFormState,
            isValidValue: true,
            currentPageLink,
            dataSourceList
        })
        // if (fieldValue === undefined) {
        //     return prev
        // }
        const verify = checkOperator({
            selfRecord,
            currentRecord,
            prevRecord,
            viewRecord,
            userRecord,
            fieldValue,
            operator,
            paramList,
            pageStackFormState,
            currentPageLink,
            dataSourceList
        })
        if (verify !== FilterStatus.init) {
            prev.push(verify)
        }

        return prev
    }, [])

    if (where === 'OR') {
        return booleanToFilterStatus(
            statusArr.reduce((accumulator, currentValue) => accumulator || filterStatusToBoolean(currentValue), false)
        )
    }
    return booleanToFilterStatus(statusArr.reduce((accumulator, currentValue) => accumulator && filterStatusToBoolean(currentValue), true))
}

type FilterRecordCheckerPayload = {
    filter: FilterFormType
    selfRecord?: RecordData
    currentRecord?: RecordData
    prevRecord?: RecordData
    viewRecord?: RecordData
    userRecord: RecordData
    pageStackFormState?: Record<string, InputValueItem>
    currentPageLink?: string
    dataSourceList?: DataSourceAbstract[]
}

export const filterRecordChecker = (payload: FilterRecordCheckerPayload) => {
    const { filter, currentRecord, prevRecord, viewRecord, userRecord, pageStackFormState, currentPageLink, dataSourceList } = payload
    // const currentRecordContent = currentRecord?.content
    // const prevRecordContent = prevRecord?.content
    const conditions = filter.expression?.conditions
    const where = filter.expression?.where
    if (conditions && where) {
        const filterStatus = filterRecordUnmatched({
            where,
            conditions,
            currentRecord,
            prevRecord,
            viewRecord,
            userRecord,
            pageStackFormState,
            currentPageLink,
            dataSourceList
        })
        return filterStatus === FilterStatus.init || filterStatus === FilterStatus.pass
    }
    return true
}

type GetDataSourceByIdVariablePayload = {
    customViewData?: CustomViewVisibleData
    dataSourceList: DataSourceAbstract[]
    idVariable?: VariableADTField
    fieldBlocksWithDsId: FieldBlockWithDsId[]
}

export const getDataSourceByIdVariable = (payload: GetDataSourceByIdVariablePayload) => {
    const { customViewData, dataSourceList, idVariable, fieldBlocksWithDsId } = payload
    if (!idVariable) {
        return
    }
    if (idVariable.type === VariableType.FIELD_ID) {
        return
    }
    if (idVariable.type === VariableType.VALUE && idVariable.valueVariable?.value === '{currentUserId}') {
        return
    }
    if (idVariable.type === VariableType.PAGE) {
        return find(item => item.id === idVariable.pageVariable?.dsId, dataSourceList)
    }
    if (idVariable.type === VariableType.VIEW) {
        return customViewData?.dataSource
    }
    if (idVariable.type === VariableType.USER) {
        return find(item => item.id === USER_DATASOURCE, dataSourceList)
    }
    if (idVariable.type === VariableType.INPUT) {
        const fieldBlockWithDsId = find(item => item.id === idVariable?.inputVariable?.blockId, fieldBlocksWithDsId)
        return find(item => item.id === fieldBlockWithDsId?.dsId, dataSourceList)
    }
    return undefined
}

export const getFieldByIdVariable = (
    dataSource: DataSourceAbstract | undefined,
    idVariable: VariableADTField | undefined,
    fieldBlocksWithDsId: FieldBlockWithDsId[]
) => {
    if (!idVariable) {
        return
    }
    if (idVariable.type === VariableType.FIELD_ID) {
        return
    }
    if (idVariable.type === VariableType.VALUE && idVariable.valueVariable?.value === '{currentUserId}') {
        return { id: '', type: 'person' } as Field
    }
    if (idVariable.type === VariableType.PAGE) {
        const field = dataSource?.schema[idVariable.pageVariable?.fieldId || '']
        if (!field) {
            return
        }
        return field
    }
    if (idVariable.type === VariableType.VIEW) {
        const field = dataSource?.schema[idVariable.viewVariable?.fieldId || '']
        if (!field) {
            return
        }
        return field
    }
    if (idVariable.type === VariableType.USER) {
        const field = dataSource?.schema[idVariable.userVariable?.fieldId || '']
        if (!field) {
            return
        }
        return field
    }

    if (idVariable.type === VariableType.INPUT) {
        // idVariable.inputVariable.blockId
        const fieldBlock = find(item => item.id === idVariable.inputVariable?.blockId, fieldBlocksWithDsId)
        if (!fieldBlock) {
            return
        }
        const field = dataSource?.schema[fieldBlock.config?.fieldPointer || '']
        if (!field) {
            return
        }
        return field
    }
    return undefined
}

type IdVariableToStringPayload = {
    customViewData?: CustomViewVisibleData
    currentPage: CurrPageDatasourceForVariable
    prevPage: PrevPageDatasourceForVariable
    dataSourceList: DataSourceAbstract[]
    fieldBlocksWithDsId: FieldBlockWithDsId[]
    idVariable: VariableADTvalue | undefined
}

export const idVariableToString = (payload: IdVariableToStringPayload) => {
    const { customViewData, currentPage, prevPage, dataSourceList, fieldBlocksWithDsId, idVariable } = payload
    if (!idVariable) {
        return
    }
    if (idVariable.type === VariableType.VALUE && idVariable.valueVariable?.value === '{currentUserId}') {
        return '当前登录用户'
    }
    if (idVariable.type === VariableType.PAGE_LINK && idVariable.pageLinkVariable?.value === 'CURRENT_PAGE') {
        return '当前页面链接'
    }
    if (idVariable.type === VariableType.USER) {
        const dataSource = getDataSourceByIdVariable({ customViewData, dataSourceList, idVariable, fieldBlocksWithDsId })
        const field = getFieldByIdVariable(dataSource, idVariable, fieldBlocksWithDsId)
        if (dataSource && field) {
            return `${dataSource.name}.${field.name}`
        }
    }
    if (idVariable.type === VariableType.PAGE) {
        const dataSource = getDataSourceByIdVariable({ customViewData, dataSourceList, idVariable, fieldBlocksWithDsId })
        const field = getFieldByIdVariable(dataSource, idVariable, fieldBlocksWithDsId)
        const prevPageName = prevPage.page?.name || '-'
        const currentPageName = currentPage.page?.name || '-'
        const name = idVariable.pageVariable?.type === 'parentPage' ? prevPageName : currentPageName
        if (dataSource && field) {
            return `${name}.${dataSource.name}.${field.name}`
        }
    }

    if (idVariable.type === VariableType.VIEW) {
        const dataSource = getDataSourceByIdVariable({ customViewData, dataSourceList, idVariable, fieldBlocksWithDsId })
        const field = getFieldByIdVariable(dataSource, idVariable, fieldBlocksWithDsId)
        if (dataSource && field) {
            return `${dataSource.name}.${field.name}`
        }
    }

    if (idVariable.type === VariableType.INPUT) {
        const { blockId } = idVariable?.inputVariable ?? {}
        const fieldBlock = find(item => item.id === blockId, fieldBlocksWithDsId)
        if (fieldBlock) {
            return `输入框${fieldBlock.title}`
        }
    }
    return undefined
}

export const paramToString: (field: Field, fieldValue: FieldCellValue, personOptions: AppUser[]) => string = (
    field,
    fieldValue,
    personOptions
) => {
    if (!fieldValue && typeof fieldValue !== 'boolean') {
        return ''
    }

    const { type } = field
    switch (type) {
        case 'textGeneration':
        case 'id':
        case 'text':
        case 'url':
        case 'phoneNumber':
        case 'userGroup':
        case 'email':
        case 'number':
        case 'notes':
        case 'userDepartment': {
            return String(fieldValue)
        }
        case 'person': {
            if (!Array.isArray(fieldValue)) {
                return ''
            }
            return fieldValue
                .map(id => {
                    if (typeof id !== 'string') {
                        return ''
                    }
                    if (id === '{currentUserId}') {
                        return '当前用户'
                    }
                    const user = find(item => item.userId === id, personOptions)
                    return user?.username || ''
                })
                .filter(Boolean)
                .join(',')
        }
        case 'selectGenerationByText':
        case 'select': {
            if (!Array.isArray(fieldValue)) {
                return ''
            }
            return fieldValue.join(',')
        }

        case 'date': {
            // const secondFieldValue = data?.[1]?.valueVariable?.value
            // const secondDate = secondFieldValue && typeof secondFieldValue === 'number' ? lightFormat(secondFieldValue, 'yyyy-MM-dd') : ''
            // if (operator === 'between' && !secondDate) {
            //     return ''
            // }
            return typeof fieldValue === 'number' ? lightFormat(fieldValue, 'yyyy-MM-dd') : ''
        }
        case 'aggregation':
        case 'formula': {
            if (Array.isArray(fieldValue)) {
                return fieldValue.join(',')
            }
            return String(fieldValue)
        }

        case 'checkbox': {
            return fieldValue ? 'true' : 'false'
        }
        default: {
            return ''
        }
    }
}

type ParamListToStringPayload = {
    customViewData?: CustomViewVisibleData
    currentPage: CurrPageDatasourceForVariable
    prevPage: PrevPageDatasourceForVariable
    dataSourceList: DataSourceAbstract[]
    fieldBlocksWithDsId: FieldBlockWithDsId[]
    field: Field
    operator: string
    paramList: VariableADTvalue[]
    personOptions: AppUser[]
}

export const paramListToString: (payload: ParamListToStringPayload) => string = ({
    customViewData,
    currentPage,
    prevPage,
    dataSourceList,
    fieldBlocksWithDsId,
    field,
    operator,
    paramList,
    personOptions
}) => {
    const data = paramList
        .map(item => {
            if (item.type === VariableType.VALUE) {
                const variableValue = item.valueVariable?.value
                if (variableValue !== undefined) {
                    return paramToString(field, variableValue, personOptions)
                }
            }
            if (item.type === VariableType.FIELD_ID) {
                return ''
            }
            if (item.type === VariableType.SYSTEM) {
                return item.systemVariable?.value ? variableSystemNameMap?.[item.systemVariable.value] || '' : ''
            }
            return idVariableToString({
                customViewData,
                currentPage,
                prevPage,
                dataSourceList,
                fieldBlocksWithDsId,
                idVariable: item
            })
        })
        .filter(Boolean)

    if (operator === 'between' && data.length < 2) {
        return ''
    }
    return data.join(' ')
}

export const isEmptyOperator = (operator: string) => {
    return operator === 'isEmpty' || operator === 'isNotEmpty'
}

export const verifyParamsText = (operator: string, paramText: string) => {
    if (isEmptyOperator(operator)) {
        return true
    }
    return paramText
}

type ConditionToStringPayload = {
    customViewData?: CustomViewVisibleData
    currentPage: CurrPageDatasourceForVariable
    prevPage: PrevPageDatasourceForVariable
    dataSourceList: DataSourceAbstract[]
    conditions: FilterConditions[]
    where: 'OR' | 'AND'
    personOptions: AppUser[]
    fieldBlocksWithDsId: FieldBlockWithDsId[]
    level?: number
}

export const conditionToString: (payload: ConditionToStringPayload) => string[] = ({
    customViewData,
    currentPage,
    prevPage,
    dataSourceList,
    conditions,
    where,
    personOptions,
    fieldBlocksWithDsId,
    level = 0
}) => {
    return conditions.reduce<string[]>((prev, cur) => {
        const childrenConditions = (cur as FilterGroupType)?.conditions
        const childrenWhere = (cur as FilterGroupType)?.where
        if (childrenConditions && childrenWhere) {
            const childrenText = conditionToString({
                customViewData,
                currentPage,
                prevPage,
                dataSourceList,
                conditions: childrenConditions,
                where: childrenWhere,
                personOptions,
                fieldBlocksWithDsId,
                level: level + 1
            })
            return [...prev, ...childrenText]
        }
        const condition = cur as FilterCommonCondition
        const { idVariable, operator, paramList } = condition
        const fieldText = idVariableToString({ customViewData, currentPage, prevPage, dataSourceList, fieldBlocksWithDsId, idVariable })
        const dataSource = getDataSourceByIdVariable({ customViewData, dataSourceList, idVariable, fieldBlocksWithDsId })
        const field = getFieldByIdVariable(dataSource, idVariable, fieldBlocksWithDsId)
        if (!fieldText || !field || !operator || !paramList) {
            return prev
        }
        const OperatorText = visibleFilterOperatorText[field?.innerType || 'NULL']?.[operator]
        const paramText = paramListToString({
            customViewData,
            currentPage,
            prevPage,
            dataSourceList,
            fieldBlocksWithDsId,
            field,
            operator,
            paramList,
            personOptions
        })
        if (!OperatorText || !verifyParamsText(operator, paramText)) {
            return prev
        }
        const realParamText = isEmptyOperator(operator) ? '' : paramText
        const whereText = where === 'AND' ? '且' : '或'
        const levelArr = Array.from({ length: level })
        const levelText = levelArr.map(item => '    ').join('')
        prev.push(`${levelText}${whereText} ${fieldText} ${OperatorText} ${realParamText}`)
        return prev
    }, [])
}

type FilterToStringPayload = {
    customViewData?: CustomViewVisibleData
    currentPage: CurrPageDatasourceForVariable
    prevPage: PrevPageDatasourceForVariable
    dataSourceList: DataSourceAbstract[]
    filter: FilterFormType
    personOptions: AppUser[]
    fieldBlocksWithDsId: FieldBlockWithDsId[]
}

export const filterToString = (payload: FilterToStringPayload) => {
    const { customViewData, currentPage, prevPage, dataSourceList, filter, personOptions, fieldBlocksWithDsId } = payload
    const conditions = filter.expression?.conditions
    const where = filter.expression?.where
    if (!conditions || !where) {
        return
    }
    return conditionToString({
        customViewData,
        currentPage,
        prevPage,
        dataSourceList,
        conditions,
        where,
        personOptions,
        fieldBlocksWithDsId
    })
}

export const getFilterOfDeepBlockIds = (filter?: FilterFormType): Set<string> => {
    const blockIds = new Set<string>()
    if (!filter) {
        return blockIds
    }

    const conditions = clone(filter.expression?.conditions)
    const where = clone(filter.expression?.where)
    if (!conditions || !where) {
        return blockIds
    }

    const queue: FilterConditions[] = conditions
    while (queue.length > 0) {
        const condition = queue.shift()
        const childrenConditions = (condition as FilterGroupType)?.conditions
        const childrenWhere = (condition as FilterGroupType)?.where
        if (childrenConditions && childrenWhere) {
            for (const childrenCondition of childrenConditions) {
                queue.push(childrenCondition as FilterConditions)
            }
        }
        const filterCondition = condition as FilterCommonCondition
        const { idVariable, paramList } = filterCondition ?? {}
        if (idVariable && idVariable.type === VariableType.INPUT && idVariable.inputVariable?.blockId) {
            blockIds.add(idVariable.inputVariable.blockId)
        }
        if (paramList) {
            for (const param of paramList) {
                if (param.type === VariableType.INPUT && param.inputVariable?.blockId) {
                    blockIds.add(param.inputVariable.blockId)
                }
            }
        }
    }
    return blockIds
}

export const transformExpressionToNormalExpression = (
    expression: FilterConditions,
    fieldInputValueMap?: Record<string, InputValueItem>
): FilterConditions => {
    if ('conditions' in expression) {
        const { conditions } = expression
        if (!conditions) {
            return expression
        }
        const newConditions = conditions.map(condition => transformExpressionToNormalExpression(condition, fieldInputValueMap))
        return { ...expression, conditions: newConditions }
    }
    if ('idVariable' in expression) {
        const { paramList, operator } = expression
        let newOperator = operator
        const newParamList = paramList?.map(param => {
            if (param.type === VariableType.INPUT) {
                const { inputVariable } = param
                if (!inputVariable) {
                    return { type: VariableType.VALUE }
                }
                const fieldBlockValue = fieldInputValueMap?.[inputVariable.blockId]
                if (fieldBlockValue?.value === '' || (Array.isArray(fieldBlockValue?.value) && fieldBlockValue?.value?.length === 0)) {
                    newOperator = 'isEmpty'
                }
                return {
                    type: VariableType.VALUE,
                    valueVariable: {
                        value: fieldBlockValue?.value,
                        type: fieldBlockValue?.fieldType
                    }
                }
            }
            return param
        }) as VariableADTvalue[]
        return { ...expression, operator: newOperator, paramList: newParamList }
    }
    return expression
}

export const transformFilterNormalFilter = (
    filter?: FilterFormType,
    fieldInputValueMap?: Record<string, InputValueItem>
): FilterFormType | undefined => {
    const newFilter = clone(filter)
    if (!newFilter) {
        return newFilter
    }
    const conditions = newFilter.expression?.conditions
    const where = newFilter.expression?.where
    if (!conditions || !where || !newFilter.expression) {
        return newFilter
    }
    const newExpression = transformExpressionToNormalExpression(newFilter.expression, fieldInputValueMap)
    if (!newExpression) {
        return newFilter
    }
    return { ...newFilter, expression: newExpression as FilterGroupType }
}

export const getFieldIdInFilter = (filter?: FilterFormType): Set<string> => {
    const fieldIds = new Set<string>()
    if (!filter) {
        return fieldIds
    }

    const conditions = clone(filter.expression?.conditions)
    const where = clone(filter.expression?.where)
    if (!conditions || !where) {
        return fieldIds
    }

    const queue: FilterConditions[] = conditions
    while (queue.length > 0) {
        const condition = queue.shift()
        const childrenConditions = (condition as FilterGroupType)?.conditions
        const childrenWhere = (condition as FilterGroupType)?.where
        if (childrenConditions && childrenWhere) {
            for (const childrenCondition of childrenConditions) {
                queue.push(childrenCondition as FilterConditions)
            }
        }
        const filterCondition = condition as FilterCommonCondition
        const { idVariable, paramList } = filterCondition ?? {}
        if (idVariable && idVariable.type === VariableType.FIELD_ID && idVariable.fieldIdVariable?.fieldId) {
            fieldIds.add(idVariable.fieldIdVariable.fieldId)
        }
    }
    return fieldIds
}

export type FilterItemIds = {
    blockId: string
    itemId: string
}

export const getFilterBlockItemIdsInFilter = (filter?: FilterFormType) => {
    const filterBlockItemIds: FilterItemIds[] = []
    // const filterBlockIds: Set<string> = new Set()
    if (!filter) {
        return { filterBlockItemIds }
    }

    const conditions = clone(filter.expression?.conditions)
    const where = clone(filter.expression?.where)
    if (!conditions || !where) {
        return { filterBlockItemIds }
    }

    const queue: FilterConditions[] = conditions
    while (queue.length > 0) {
        const condition = queue.shift()
        const childrenConditions = (condition as FilterGroupType)?.conditions
        const childrenWhere = (condition as FilterGroupType)?.where
        if (childrenConditions && childrenWhere) {
            for (const childrenCondition of childrenConditions) {
                queue.push(childrenCondition as FilterConditions)
            }
        }
        const filterCondition = condition as FilterCommonCondition
        const { idVariable, paramList } = filterCondition ?? {}
        if (idVariable && idVariable.type === VariableType.FILTER && idVariable.filterVariable?.itemId) {
            filterBlockItemIds.push({
                blockId: idVariable.filterVariable.blockId,
                itemId: idVariable.filterVariable.itemId
            })
        }
        if (paramList) {
            paramList.forEach(param => {
                if (param && param.type === VariableType.FILTER && param.filterVariable?.itemId) {
                    filterBlockItemIds.push({
                        blockId: param.filterVariable.blockId,
                        itemId: param.filterVariable.itemId
                    })
                }
            })
        }
    }
    return { filterBlockItemIds }
}

type GenerateLinkFilterParams = {
    filterBlockItemIds: FilterItemIds[]
    filterOptions: Record<string, FilterOption[]>
    linkFilterController?: FilterFormType
    filterValue: {
        blockId: string
        data: FilterBlockValue
    }[]
    filterBlocks: BlockAbstract[]
}

export const generateLinkFilter = ({
    filterBlockItemIds,
    filterOptions,
    linkFilterController,
    filterValue,
    filterBlocks
}: GenerateLinkFilterParams) => {
    const usedFilterOptions = filterBlockItemIds.reduce<Record<string, FilterOption[]>>((prev, filterItem) => {
        const id = `${filterItem.blockId}-${filterItem.itemId}`
        const options = filterOptions[id]
        if (options) {
            prev[id] = options
        }
        return prev
    }, {})
    return linkFilterController
        ? resolveFilter({
              filter: linkFilterController,
              extraParams: {
                  filterBlockParams: {
                      filterValue,
                      filterOptions: usedFilterOptions,
                      filterBlocks
                  }
              }
          })
        : undefined
}

// // 图层验证筛选条件
// type ConditionFilterEmptyPayload = {
//     customViewData?: CustomViewVisibleData
//     currentPage: CurrPageDatasourceForVariable
//     prevPage: PrevPageDatasourceForVariable
//     dataSourceList: DataSourceAbstract[]
//     filter: FilterFormType
//     personOptions: AppUser[]
//     fieldBlocksWithDsId: FieldBlockWithDsId[]
// }

// export const conditionFilterEmpty = (filter: FilterFormType) => {

// }

// export const getFilterBlockItemIdsInListFilter = (filterList?: FilterFormType[]) => {
//     const blockIds: Set<string> = new Set()
//     const itemIds: Set<string> = new Set()
//     if (!filterList) {
//         return { filterBlockIds: blockIds, filterItemIds: itemIds }
//     }
//     filterList.forEach(filter => {
//         const { filterItemIds} = getFilterBlockItemIdsInFilter(filter)
//         filterBlockIds.forEach(id => {
//             blockIds.add(id)
//         })
//         filterItemIds.forEach(id => {
//             itemIds.add(id)
//         })
//     })
//     return { filterBlockIds: blockIds, filterItemIds: itemIds }
// }

// export const getFilterBlockItemIdsInFilter = (filter?: FilterFormType) => {
//     const blockIdItemIds: Record<BlockId, Set<string>> = {}
//     if (!filter) {
//         return blockIdItemIds
//     }

//     const conditions = clone(filter.expression?.conditions)
//     const where = clone(filter.expression?.where)
//     if (!conditions || !where) {
//         return blockIdItemIds
//     }

//     const queue: FilterConditions[] = conditions
//     while (queue.length > 0) {
//         const condition = queue.shift()
//         const childrenConditions = (condition as FilterGroupType)?.conditions
//         const childrenWhere = (condition as FilterGroupType)?.where
//         if (childrenConditions && childrenWhere) {
//             for (const childrenCondition of childrenConditions) {
//                 queue.push(childrenCondition as FilterConditions)
//             }
//         }
//         const filterCondition = condition as FilterCommonCondition
//         const { idVariable, paramList } = filterCondition ?? {}
//         if (idVariable && idVariable.type === VariableType.FILTER && idVariable.filterVariable?.itemId) {
//             blockIdItemIds[idVariable.filterVariable.blockId].add(idVariable.filterVariable.itemId)
//         }
//         if (paramList) {
//             paramList.forEach(param => {
//                 if (param && param.type === VariableType.FILTER && param.filterVariable?.itemId) {
//                     if (blockIdItemIds?.[param.filterVariable.blockId]) {
//                         blockIdItemIds[param.filterVariable.blockId].add(param.filterVariable.itemId)
//                         return
//                     }
//                     blockIdItemIds[param.filterVariable.blockId] = new Set([param.filterVariable.itemId])

//                 }
//             })
//         }
//     }
//     return blockIdItemIds
// }
