// import { ChartBlock } from '@lighthouse/block'
import { Toast } from '@byecode/ui'
import type { ChartBlockAbstract, FilterOption } from '@lighthouse/core'
import { getFilterBlockItemIdsInFilter, pageStackPubSub, resolveFilter, useAtomAsyncAction, useAtomData } from '@lighthouse/shared'
import fastEqual from 'fast-deep-equal'
import { useAtomValue } from 'jotai'
import React, { memo, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { fetchChartBlockAtom } from '@/atoms/blockRecordsDict/action'
import { filterBlockOptionsAtom } from '@/atoms/blockRecordsDict/state'
import { pageBlocksAtom } from '@/atoms/page/state'
import { filterBlockIdsCacheAtomFamily } from '@/atoms/storage/state'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { useCurrentAppId, useCurrentEnvId } from '@/hooks/useApplication'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import * as srv from '@/services'

const ChartBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.ChartBlock })))

interface GalleryBlockControllerProps {
    blockData: ChartBlockAbstract
    onBlockChange?: (values: ChartBlockAbstract, origin: ChartBlockAbstract) => Promise<void> | void
}

const ChartBlockController: React.FC<GalleryBlockControllerProps> = ({ blockData, onBlockChange }) => {
    const { id, config, title } = blockData
    const appId = useCurrentAppId()
    const { linkFilterController, pointer } = config
    const { pageId } = useCurrentPageContext()
    const envId = useCurrentEnvId()
    const stackId = useCurrentStackIdContext()
    const { filterBlockItemIds } = useMemo(() => getFilterBlockItemIdsInFilter(linkFilterController), [linkFilterController])
    const filterBlockIds = useMemo(() => filterBlockItemIds.map(item => item.blockId), [filterBlockItemIds])
    const filterBlocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s[pageId]?.filter(block => block.type === 'filter' && filterBlockIds.includes(block.id)), [filterBlockIds, pageId])
    )
    const filterOptions = useAtomData(filterBlockOptionsAtom)
    const { curr, prev } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const filterBlockIdsValue = useAtomValue(
        filterBlockIdsCacheAtomFamily({ appId, envId, pageId, recordId: curr.recordId, filterBlockItemIds })
    )

    const usedFilterOptions = useMemo(() => {
        return filterBlockItemIds.reduce<Record<string, FilterOption[]>>((prev, filterItem) => {
            const id = `${filterItem.blockId}-${filterItem.itemId}`
            const options = filterOptions[id]
            if (options) {
                prev[id] = options
            }
            return prev
        }, {})
    }, [filterBlockItemIds, filterOptions])

    const [localFilterOptions, setLocalFilterOptions] = useState(usedFilterOptions)

    const resolvedFilter = useMemo(() => {
        return linkFilterController
            ? resolveFilter({
                  filter: linkFilterController,
                  extraParams: {
                      filterBlockParams: {
                          filterValue: filterBlockIdsValue,
                          filterOptions: localFilterOptions,
                          filterBlocks
                      }
                  }
              })
            : {}
    }, [linkFilterController, filterBlockIdsValue, localFilterOptions, filterBlocks])

    const configRef = useRef({
        resolvedFilter
    })

    const { run: fetchChartBlock, loading, value: chartData } = useAtomAsyncAction(fetchChartBlockAtom)

    const handleExport = useCallback(async () => {
        Toast.warning('正在导出, 请勿重复点击')
        const res = await srv.exportChart(
            {
                blockId: id,
                currentRecordId: curr.recordId,
                parentRecordId: prev.recordId,
                linkFilter: resolvedFilter
            },
            pageId
        )
        const { data, headers } = res
        const fileName = headers?.['content-disposition']?.replace?.("attachment;filename*=utf-8''", '') ?? `${title}.xlsx`
        const dom = document.createElement('a')
        const url = window.URL.createObjectURL(data)
        dom.href = url
        dom.download = decodeURI(fileName)
        dom.style.display = 'none'
        document.body.append(dom)
        dom.click()
        dom.remove()
        window.URL.revokeObjectURL(url)
    }, [id, curr.recordId, prev.recordId, resolvedFilter, pageId, title])

    const isFetch = useMemo(() => {
        // if (config.chartType === 'indicator') {
        //     return config.indicatorOptions.some(item => item.pointer)
        // }
        return !!pointer
    }, [pointer])

    useUpdateEffect(() => {
        if (fastEqual(localFilterOptions, usedFilterOptions)) {
            return
        }
        setLocalFilterOptions(usedFilterOptions)
    }, [usedFilterOptions])

    // 监听筛选控制器
    useUpdateEffect(() => {
        if (fastEqual(configRef.current.resolvedFilter, resolvedFilter)) {
            return
        }
        configRef.current.resolvedFilter = resolvedFilter
        fetchChartBlock({
            blockId: id,
            pageId,
            currentRecordId: curr.recordId,
            parentRecordId: prev.recordId,
            linkFilter: resolvedFilter
        })
    }, [resolvedFilter])

    useEffect(() => {
        if (!pointer) {
            return
        }
        const { subscribeId, unSubscribe } = pageStackPubSub.subscribe(`${pointer}-ADD`, () => {
            fetchChartBlock({
                blockId: id,
                pageId,
                currentRecordId: curr.recordId,
                parentRecordId: prev.recordId,
                linkFilter: resolvedFilter
            })
        })
        return () => unSubscribe(subscribeId)
    }, [curr.recordId, fetchChartBlock, id, pageId, pointer, prev.recordId, resolvedFilter])

    useEffect(() => {
        if (isFetch) {
            fetchChartBlock({
                blockId: id,
                pageId,
                currentRecordId: curr.recordId,
                parentRecordId: prev.recordId,
                linkFilter: resolvedFilter
            })
        }
    }, [fetchChartBlock, id, pageId, isFetch, curr.recordId, prev.recordId, resolvedFilter])

    return (
        <Suspense fallback={<div />}>
            <ChartBlock loading={loading} blockData={blockData} chartData={chartData} onExport={handleExport} />
        </Suspense>
    )
}

export default memo(ChartBlockController, fastEqual)
