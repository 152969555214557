import type { NavigationShowMode } from '@lighthouse/core'
import React, { createContext, useContext } from 'react'

interface AppContainerContextProviderProps {
    scale?: number
    showMode?: NavigationShowMode
    disabledPageScroll?: boolean
    children?: React.ReactNode
}

interface AppContainerContextProps {
    scale?: number
    showMode?: NavigationShowMode
    disabledPageScroll?: boolean
}

const AppContainerContext = createContext<AppContainerContextProps>({})

export const AppContainerContextProvider = ({ children, ...rest }: AppContainerContextProviderProps) => {
    return <AppContainerContext.Provider value={rest}>{children}</AppContainerContext.Provider>
}

export const useAppContainerContext = () => useContext(AppContainerContext)
