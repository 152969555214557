import type { CascadeOption } from '@byecode/ui'
import type {
    AppUser,
    Conditions,
    DataSourceAbstract,
    FieldCellValue,
    FieldInputConfigProtocol,
    FilterFormType,
    InputValueItem,
    PaginationProtocol,
    RecordLikeProtocol,
    RelativeSelectConfig,
    SortableProtocol
} from '@lighthouse/core'
import type { RelativeOption } from '@lighthouse/shared'
import {
    getCascadeOption,
    getFilterOfDeepBlockIds,
    pageStackPubSub,
    transformFilterNormalFilter,
    useHandleAbortPrevious,
    USER_DATASOURCE
} from '@lighthouse/shared'
import equal from 'fast-deep-equal'
import type { AnyObject } from 'immer/dist/internal'
import { clone, find, findIndex, pick, reduce } from 'rambda'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import useSWRMutation from 'swr/mutation'
import { debounce } from 'throttle-debounce'
import { useImmer } from 'use-immer'

import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import * as srv from '@/services'
import type { DataSourceAndRecordsResult } from '@/services/types'

import { useCurrentAppId, useCurrentEnvId } from './useApplication'
import { usePageDataSourceForVariableSelector } from './usePage'
import { useUserRecord } from './useUserRecord'

type DataSourcePayload = {
    pagination: PaginationProtocol['pagination']
    search?: string
    dsId: string
    appId: string
    filter?: FilterFormType
    disabledFilter?: FilterFormType
    currentRecordId?: string
    parentRecordId?: string
    pageId: string
    abort?: AbortController
} & SortableProtocol

type DataSourceOptionPayload = {
    dsId: string
    filter?: FilterFormType
    disabledFilter?: FilterFormType
    fieldId: string
    showFieldId: string
    currentRecordId?: string
    parentRecordId?: string
    pageId: string
    isDebounce?: boolean
} & SortableProtocol

type RelativeDataSourceConfigItem = { config: RelativeSelectConfig; value?: FieldCellValue }

type FieldInputConfigItem = { config: FieldInputConfigProtocol; value?: FieldCellValue }

/** 获取关联数据源数据 */
export function useFieldBlockMethods(params: { fieldBlocks: FieldInputConfigItem[]; fieldBlockValueMap?: Record<string, InputValueItem> }) {
    const { fieldBlocks, fieldBlockValueMap } = params
    const initLoadRef = useRef(false)
    const prevFieldBlockValueRef = useRef({})

    const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const stackId = useCurrentStackIdContext()
    const { pageId } = useCurrentPageContext()
    const { record: userRecord } = useUserRecord()

    const {
        curr: { recordId: currentRecordId },
        prev: { recordId: parentRecordId }
    } = usePageDataSourceForVariableSelector({ stackId, pageId })

    const [newConfigList, blockIds] = useMemo(() => {
        const configList = fieldBlocks
        let blockIds: string[] = []
        return [
            configList.reduce<RelativeDataSourceConfigItem[]>((preVal, item) => {
                const { config } = item
                if (config.inputType === 'relativeSelect') {
                    const { relativeSelect } = config
                    const newFilterBlockIds = getFilterOfDeepBlockIds(relativeSelect?.filter)
                    const newDisabledFilterBlockIds = getFilterOfDeepBlockIds(relativeSelect?.disabledFilter)
                    blockIds = Object.keys(Object.fromEntries([...newFilterBlockIds, ...newDisabledFilterBlockIds].map(id => [id, id])))
                    return [...preVal, item]
                }
                return preVal
            }, []),
            blockIds
        ]
    }, [fieldBlocks])

    const usedFieldInputValueMap = useMemo(() => {
        const newFieldInputValueMap = pick(blockIds, fieldBlockValueMap)
        if (equal(prevFieldBlockValueRef.current, newFieldInputValueMap)) {
            return prevFieldBlockValueRef.current
        }
        prevFieldBlockValueRef.current = newFieldInputValueMap
        return newFieldInputValueMap
    }, [blockIds, fieldBlockValueMap])

    const [relativeDataSource, setRelativeDataSource] = useImmer<{
        dataSourceList: DataSourceAbstract[]
        records: RecordLikeProtocol[]
        userRecord?: RecordLikeProtocol
        options: RelativeOption[]
    }>({
        records: [],
        dataSourceList: [],
        options: []
    })

    useEffect(() => {
        setRelativeDataSource(draft => {
            draft.userRecord = userRecord
        })
    }, [setRelativeDataSource, userRecord])

    const handleChange = useCallback(
        (params: DataSourcePayload, dataSource: DataSourceAndRecordsResult, count?: number) => {
            const { pagination, search, appId, dsId, filter, sorts, currentRecordId, parentRecordId } = params
            const { datasource, records = [] } = dataSource ?? {}
            if (!datasource) {
                return
            }
            setRelativeDataSource(draft => {
                const newDataSource = clone(datasource)
                newDataSource.viewOptions.pagination = {
                    ...pagination,
                    rowTotal: count
                }
                const isDataSourceExit = find(item => item.id === dsId && item.appId === appId, draft.dataSourceList)
                const newRecordIds = new Set(records.map(item => item.id))
                const newRecords = draft.records.filter(item => !newRecordIds.has(item.id))
                draft.records = [...newRecords, ...records]
                if (!isDataSourceExit) {
                    draft.dataSourceList.push(newDataSource)
                    return
                }
                const dataSourceIndex = findIndex(item => item.id === newDataSource.id, draft.dataSourceList)
                if (dataSourceIndex !== -1) {
                    draft.dataSourceList[dataSourceIndex].viewOptions.pagination = pagination
                }
            })
        },
        [setRelativeDataSource]
    )

    const { trigger: getInitRecords } = useSWRMutation(`getDs-init-records`, async (_, { arg }: { arg: DataSourcePayload }) => {
        const { pagination, search, appId, dsId, filter, sorts, currentRecordId, parentRecordId, pageId } = arg
        const data = await srv.getDs({ appId, envId, dsId, pagination, search, filter, sorts, currentRecordId, parentRecordId, pageId })
        if (data) {
            handleChange(arg, data)
        }
        return data
    })

    const { trigger: getDsSwr } = useSWRMutation(`getDs-fetch`, async (_, { arg }: { arg: DataSourcePayload }) => {
        const { pagination, search, appId, dsId, filter, sorts, currentRecordId, parentRecordId, disabledFilter, pageId, abort } = arg
        const data = await srv.getDs({
            appId,
            envId,
            dsId,
            pagination,
            search,
            filter,
            sorts,
            currentRecordId,
            parentRecordId,
            disabledFilter,
            pageId,
            abort
        })
        if (data) {
            handleChange(arg, data)
        }
        return data
    })

    const { trigger: onLoadMoreData } = useSWRMutation(`getDs-loadMore`, async (_, { arg }: { arg: DataSourcePayload }) => {
        const { pagination, search, appId, dsId, filter, sorts, currentRecordId, parentRecordId, disabledFilter, pageId } = arg
        const data = await srv.getDs({
            appId,
            envId,
            dsId,
            pagination,
            search,
            filter,
            sorts,
            currentRecordId,
            parentRecordId,
            disabledFilter,
            pageId
        })
        if (!data) {
            return []
        }
        setRelativeDataSource(draft => {
            const index = findIndex(dataSource => dataSource.id === dsId && dataSource.appId === appId, draft.dataSourceList)
            if (index !== -1) {
                draft.dataSourceList[index].viewOptions.pagination.currentPage = pagination.currentPage
            }
            data.records.forEach(record => {
                draft.records.push(record)
            })
        })
        return data.records
    })

    const { trigger: getDsOption } = useSWRMutation(`getDs-options`, async (_, { arg }: { arg: DataSourceOptionPayload }) => {
        const { fieldId, dsId, filter, sorts, showFieldId, currentRecordId, parentRecordId, disabledFilter, pageId } = arg
        const data = await srv.getRelativeOptions({
            dsId,
            fieldId,
            filter,
            sorts,
            currentRecordId,
            parentRecordId,
            showFieldId,
            disabledFilter,
            pageId
        })
        setRelativeDataSource(draft => {
            draft.options = data
        })
        return data
    })

    const { mutation: updateDataSource } = useHandleAbortPrevious(getDsSwr)

    const debounceGetDsOption = useMemo(() => debounce(1000, getDsOption), [getDsOption])

    const handleFetchDataSourceOptions = useCallback(
        (params: Omit<DataSourceOptionPayload, 'sorts' | 'pageId'>) => {
            const { dsId, isDebounce } = params
            const relativeSelect = find(item => item.config.relativeSelect?.relativePointer === dsId, newConfigList)?.config.relativeSelect
            if (!dsId || !relativeSelect) {
                return Promise.resolve(undefined)
            }
            const { sorts } = relativeSelect
            initLoadRef.current = true
            const reqParams = {
                sorts,
                currentRecordId,
                parentRecordId,
                pageId,
                ...params
            }
            return isDebounce ? debounceGetDsOption(reqParams) : getDsOption(reqParams)
        },
        [newConfigList, currentRecordId, parentRecordId, pageId, debounceGetDsOption, getDsOption]
    )

    const handleFetchDataDataSource = useCallback(
        (params: Omit<DataSourcePayload, 'appId' | 'pageId'>) => {
            const { dsId } = params
            const relativeSelect = find(item => item.config.relativeSelect?.relativePointer === dsId, newConfigList)?.config.relativeSelect
            if (!dsId || !relativeSelect) {
                return Promise.resolve(undefined)
            }
            const { filter, sorts, disabledFilter } = relativeSelect
            return updateDataSource({
                ...params,
                sorts,
                currentRecordId,
                parentRecordId,
                appId,
                pageId,
                filter: transformFilterNormalFilter(filter, usedFieldInputValueMap),
                disabledFilter: transformFilterNormalFilter(disabledFilter, usedFieldInputValueMap)
            })
        },
        [newConfigList, updateDataSource, currentRecordId, parentRecordId, appId, pageId, usedFieldInputValueMap]
    )

    const handleLoadMoreData = useCallback(
        (params: Omit<DataSourcePayload, 'appId' | 'pageId'>) => {
            const { dsId } = params
            const relativeSelect = find(item => item.config.relativeSelect?.relativePointer === dsId, newConfigList)?.config.relativeSelect
            if (!dsId || !relativeSelect) {
                return Promise.resolve(undefined)
            }
            const { filter, sorts, disabledFilter } = relativeSelect
            return onLoadMoreData({
                ...params,
                sorts,
                currentRecordId,
                parentRecordId,
                appId,
                pageId,
                filter: transformFilterNormalFilter(filter, usedFieldInputValueMap),
                disabledFilter: transformFilterNormalFilter(disabledFilter, usedFieldInputValueMap)
            })
        },
        [newConfigList, onLoadMoreData, currentRecordId, parentRecordId, appId, pageId, usedFieldInputValueMap]
    )

    const handleFetchCascadeOptions = useCallback(
        async (params: {
            dsId: string
            sortFieldPointer: string
            fieldPointer: string
            showFieldPointer: string
            filter?: FilterFormType | undefined
            parentFieldPointer: string
        }): Promise<CascadeOption[] | undefined> => {
            const { dsId, parentFieldPointer, fieldPointer, sortFieldPointer, filter, showFieldPointer } = params
            const isEmptyConfig = !dsId || !parentFieldPointer || !fieldPointer
            if (isEmptyConfig) {
                return
            }

            const res = await srv.getDs({
                appId,
                envId,
                dsId,
                pageId,
                filter: transformFilterNormalFilter(filter, usedFieldInputValueMap),
                pagination: { currentPage: 1, pageSize: 1000 },
                currentRecordId,
                parentRecordId,
                sorts: [{ id: 'cascadeSortId', order: 'ASC', fieldId: sortFieldPointer }]
            })
            const { records } = res ?? {}
            const usedRecords = records?.filter(record => record?.content?.[fieldPointer]?.value)
            return (
                usedRecords
                    ?.filter(record => !record?.content?.[parentFieldPointer]?.value)
                    .map(record => getCascadeOption({ parentFieldPointer, fieldPointer, showFieldPointer, record, records: usedRecords }))
                    .filter(item => item.value) ?? []
            )
        },
        [appId, currentRecordId, envId, pageId, parentRecordId, usedFieldInputValueMap]
    )

    const handleFetchPersonOptions = useCallback(
        async (filter: FilterFormType): Promise<AppUser[]> => {
            const res = await srv.getDs({
                appId,
                envId,
                dsId: USER_DATASOURCE,
                filter,
                pageId,
                pagination: { currentPage: 1, pageSize: 20000 },
                currentRecordId,
                parentRecordId
            })
            if (res) {
                const { records } = res
                return records.map(r => {
                    const { content, id } = r
                    return {
                        userId: content['ID'].value?.toString() ?? '',
                        username: content['USERNAME']?.value?.toString() ?? '',
                        uniqueUserId: id,
                        avatar: content['AVATAR']?.value?.toString() ?? '',
                        mobile: content['MOBILE']?.value?.toString() ?? ''
                    }
                })
            }
            return []
        },
        [appId, currentRecordId, envId, pageId, parentRecordId]
    )

    useEffect(() => {
        newConfigList.forEach(({ config: { relativeSelect }, value }) => {
            const { relativePointer, relativeFieldPointer } = relativeSelect ?? {}
            if (value === '' || value === undefined) {
                return
            }
            const initExpression: Conditions = reduce<string, AnyObject[]>(
                (preVal, curVal) => {
                    if (curVal === '') {
                        return preVal
                    }
                    const condition = {
                        idVariable: {
                            type: 'FIELD_ID',
                            fieldIdVariable: {
                                fieldId: relativeFieldPointer
                            }
                        },
                        operator: '=',
                        paramList: [
                            {
                                type: 'VALUE',
                                valueVariable: {
                                    value: curVal
                                }
                            }
                        ]
                    }
                    return [...preVal, condition]
                },
                [],
                (typeof value === 'string' || typeof value === 'number' ? value.toString() : '')?.split(',') ?? []
            )

            if (initExpression.length === 0) {
                return
            }
            const initFilter: FilterFormType = {
                expression: {
                    conditions: initExpression,
                    where: 'OR'
                }
            }
            if (relativePointer) {
                getInitRecords({
                    appId,
                    dsId: relativePointer,
                    pagination: { currentPage: 1, pageSize: 50 },
                    filter: initFilter,
                    pageId,
                    currentRecordId,
                    parentRecordId
                })
            }
        })
    }, [getInitRecords, newConfigList, appId, pageId, currentRecordId, parentRecordId])

    // 修改输入框配置，重新调用接口刷新
    useEffect(() => {
        newConfigList.forEach(({ config: { relativeSelect } }) => {
            const { relativePointer, relativeFieldPointer, relativeShowFieldPointer, filter, sorts, showMode, disabledFilter } =
                relativeSelect ?? {}
            if (relativeShowFieldPointer && relativePointer && showMode !== 'input' && relativeFieldPointer) {
                handleFetchDataSourceOptions({
                    dsId: relativePointer,
                    fieldId: relativeFieldPointer,
                    showFieldId: relativeShowFieldPointer,
                    filter: transformFilterNormalFilter(filter, usedFieldInputValueMap),
                    disabledFilter: transformFilterNormalFilter(disabledFilter, usedFieldInputValueMap),
                    isDebounce: initLoadRef.current
                })
            }
        })
    }, [handleFetchDataSourceOptions, newConfigList, pageId, setRelativeDataSource, usedFieldInputValueMap])

    useEffect(() => {
        const { relativeSelect } = newConfigList[0]?.config ?? {}
        const { relativePointer, relativeFieldPointer, relativeShowFieldPointer, filter, disabledFilter } = relativeSelect ?? {}
        if (!relativePointer) {
            return
        }
        const { subscribeId, unSubscribe } = pageStackPubSub.subscribe(`${relativePointer}-ADD`, (record?: RecordLikeProtocol) => {
            if (!relativePointer || !relativeFieldPointer || !relativeShowFieldPointer) {
                return
            }
            handleFetchDataSourceOptions({
                dsId: relativePointer,
                fieldId: relativeFieldPointer,
                showFieldId: relativeShowFieldPointer,
                filter: transformFilterNormalFilter(filter, usedFieldInputValueMap),
                disabledFilter: transformFilterNormalFilter(disabledFilter, usedFieldInputValueMap)
            })
        })
        return () => unSubscribe(subscribeId)
    }, [handleFetchDataSourceOptions, newConfigList, pageId, usedFieldInputValueMap])

    return useMemo(
        () => ({
            relativeDataSource,
            onFetchDataSource: handleFetchDataDataSource,
            onLoadMoreData: handleLoadMoreData,
            setRelativeDataSource,
            onFetchCascadeOptions: handleFetchCascadeOptions,
            onFetchPersonOptions: handleFetchPersonOptions
        }),
        [
            handleFetchCascadeOptions,
            handleFetchDataDataSource,
            handleFetchPersonOptions,
            handleLoadMoreData,
            relativeDataSource,
            setRelativeDataSource
        ]
    )
}
