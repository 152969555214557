import { ClickAwayListener } from '@byecode/ui'
import type { DataSourceAbstract, Field, FieldADTValue, RecordLikeProtocol, ViewField } from '@lighthouse/core'
import type { UseUploadFileSParameter } from '@lighthouse/shared'
import {
    ApplicationPreviewEnum,
    FieldValueEditor,
    FieldValueMobileEditor,
    getIsAppointField,
    notEditableFieldTypes,
    SYSTEM_FIELD,
    useApplicationContext,
    useCellEditorPosition,
    useFlowLayoutContext,
    WYSIWYGFieldTypes
} from '@lighthouse/shared'
import { findParentScroller, isSafaris } from '@lighthouse/tools'
import { Drawer, Modal } from '@mantine/core'
import type { UploadyProps } from '@rpldy/uploady'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

interface TablePopoverProps {
    field: Field
    blockId: string
    scrollRef: React.RefObject<HTMLDivElement>
    editorElement?: HTMLDivElement
    popperAnchorElement?: HTMLDivElement
    dataSourceInfo: DataSourceAbstract
    viewFields: ViewField[]
    records: RecordLikeProtocol[]
    recordId: string
    width?: number
    required?: boolean
    previewType?: ApplicationPreviewEnum
    getUploadOptions: (fieldId: string, recordId: string) => Pick<UseUploadFileSParameter, 'info' | 'options'>
    getRichTextUploadOptions: (fieldId: string, recordId: string) => UploadyProps
    getVideoUploadOptions: (fieldId: string, recordId: string) => Pick<UseUploadFileSParameter, 'info' | 'options'>
    onCellChange?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onCellUpdate?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onClose?: () => void
}

export const SCxFieldEditorDrawer = styled(Drawer)``

export const SCxDiv = styled.div``

export const SCxWindowShapePopperAnchor = styled.div`
    position: absolute;
    left: -2px;
    top: -2px;
`

export const SCxWindowShapeEditorPopover = styled.div`
    position: absolute;
    min-width: 100px;
    min-height: 40px;
    overflow: hidden;
    display: inline-table;
    opacity: 0;
    box-shadow: var(--box-shadow);
    background-color: var(--color-white);
    margin-left: 2px !important;
    margin-top: 2px !important;
    z-index: 200;
`
export const SCxWindowShapeEditorModal = styled(Modal)``

export const SCxFieldEditorDrawerContent = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% - 45px);
`

export const SCxEditorContent = styled.div`
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`

export const SCxFieldEditorDrawerEditorContent = styled.div`
    flex: 1;
    height: 100%;
`

export const SCxWindowShapeEditorModalOverlay = styled.div`
    outline: none;
    overflow: hidden;
`

export const TablePopover: React.FC<TablePopoverProps> = ({
    field,
    blockId,
    scrollRef,
    editorElement,
    popperAnchorElement,
    dataSourceInfo,
    viewFields,
    records,
    width = 160,
    required,
    recordId,
    previewType,
    getUploadOptions,
    getRichTextUploadOptions,
    getVideoUploadOptions,
    onCellChange,
    onCellUpdate,
    onClose
}) => {
    const { rootElement } = useFlowLayoutContext()
    const containerElement = useMemo(() => findParentScroller(rootElement), [rootElement])
    const [open, setOpen] = useState(false)
    const { personOptions, pageTarget } = useApplicationContext()
    const { appId, id: dsId } = dataSourceInfo
    const { id: fieldId } = field
    const fieldValue = useMemo(
        () => find(item => item.appId === appId && item.dsId === dsId && item.id === recordId, records)?.content?.[fieldId]?.value,
        [appId, dsId, fieldId, recordId, records]
    )
    const [currentData, setCurrentData] = useState(fieldValue)
    const isMobile = previewType === ApplicationPreviewEnum.mobile
    const isUserField = getIsAppointField(fieldId, SYSTEM_FIELD)
    const isNoneShape = notEditableFieldTypes.has(field?.type || '')
    const isInput = WYSIWYGFieldTypes.has(field?.type || '')
    const isPopperShape = !isNoneShape && !['notes'].includes(field?.type || '') && !isUserField
    const isModalShape = useMemo(() => ['notes'].includes(field?.type || ''), [field?.type])

    const { popoverRef, handleClearAnchorPosition, position } = useCellEditorPosition({
        rootElement: containerElement,
        anchorElement: popperAnchorElement,
        open,
        onOpenChange: setOpen
    })

    const uploadOptions = useMemo(() => getUploadOptions(fieldId, recordId), [fieldId, getUploadOptions, recordId])

    const richTextUploadOptions = useMemo(() => getRichTextUploadOptions(fieldId, recordId), [fieldId, getRichTextUploadOptions, recordId])

    const videoUploadOptions = useMemo(() => getVideoUploadOptions(fieldId, recordId), [fieldId, getVideoUploadOptions, recordId])

    const handleClickOutside = useCallback(
        (isActive?: boolean) => {
            setTimeout(() => {
                onClose?.()
            }, 0)
        },
        [onClose]
    )

    const handleCellChange = useCallback(
        async (recordId: string, fieldValue: FieldADTValue) => {
            setCurrentData(fieldValue.value)
            const isUpdate = await onCellChange?.(recordId, fieldValue)
            return !!isUpdate
        },
        [onCellChange]
    )

    const handleClose = useCallback(() => {
        setOpen(false)
        onClose?.()
        handleClearAnchorPosition()
    }, [handleClearAnchorPosition, onClose])

    const mouseEvent = useMemo(() => {
        if (isSafaris()) {
            return 'onMouseUp'
        }
        return 'onMouseDown'
    }, [])

    return useMemo(() => {
        if (isMobile) {
            if (isModalShape) {
                return (
                    <Modal
                        opened={open}
                        target={pageTarget}
                        styles={{
                            root: {
                                position: 'absolute'
                            },
                            modal: {
                                height: '100%'
                            },
                            inner: {
                                padding: 0
                            },
                            close: {
                                display: 'none'
                            },
                            overlay: {
                                borderRadius: pageTarget ? '55px!important' : 0
                            },
                            header: {
                                marginBottom: 0
                            },
                            body: {
                                position: 'relative',
                                height: '100%'
                            }
                        }}
                        padding={0}
                        onClose={handleClickOutside}
                    >
                        <SCxEditorContent>
                            {field && (
                                <SCxFieldEditorDrawerEditorContent>
                                    <FieldValueEditor
                                        uploadOptions={uploadOptions}
                                        videoUploadOptions={videoUploadOptions}
                                        richTextUploadOptions={richTextUploadOptions}
                                        dataSourceInfo={dataSourceInfo}
                                        data={currentData}
                                        scrollElement={scrollRef.current}
                                        personOptions={personOptions}
                                        field={field}
                                        recordId={recordId}
                                        autoFocus
                                        onCellChange={handleCellChange}
                                        onCellUpdate={onCellUpdate}
                                        onClose={handleClickOutside}
                                    />
                                </SCxFieldEditorDrawerEditorContent>
                            )}
                        </SCxEditorContent>
                    </Modal>
                )
            }

            if (!isInput) {
                return (
                    <FieldValueMobileEditor
                        opened={open}
                        onClose={handleClickOutside}
                        field={field}
                        recordId={recordId}
                        value={currentData}
                        target={pageTarget}
                        onChange={handleCellChange}
                        personOptions={personOptions}
                        uploadyOptions={uploadOptions}
                    />
                )
            }
        }

        if (isModalShape) {
            return (
                <SCxWindowShapeEditorModal
                    centered
                    closeOnClickOutside={false}
                    padding={0}
                    radius={12}
                    size={720}
                    // zIndex={9999}
                    overlayColor="var(--color-black)"
                    overlayOpacity={0.75}
                    withCloseButton={false}
                    opened={open}
                    // closeOnEscape={false}
                    onClose={handleClickOutside}
                >
                    <SCxWindowShapeEditorModalOverlay style={{ height: 'calc(100vh - 344px)' }}>
                        {field && (
                            <FieldValueEditor
                                editorHost="advancedTable"
                                uploadOptions={uploadOptions}
                                videoUploadOptions={videoUploadOptions}
                                richTextUploadOptions={richTextUploadOptions}
                                dataSourceInfo={dataSourceInfo}
                                data={currentData}
                                scrollElement={scrollRef.current}
                                field={field}
                                recordId={recordId}
                                onCellChange={handleCellChange}
                                onCellUpdate={onCellUpdate}
                                onClose={handleClickOutside}
                            />
                        )}
                    </SCxWindowShapeEditorModalOverlay>
                </SCxWindowShapeEditorModal>
            )
        }

        if (isPopperShape) {
            const extraStyles: React.CSSProperties = isInput
                ? {
                      border: '2px solid var(--color-app-main)',
                      ...position
                  }
                : {
                      border: '1px solid var(--color-gray-200)',
                      ...position
                  }
            return (
                <SCxDiv>
                    {open &&
                        containerElement &&
                        popperAnchorElement &&
                        createPortal(
                            <ClickAwayListener data-ignore-click-away mouseEvent={mouseEvent} onClickAway={handleClose}>
                                <SCxWindowShapeEditorPopover
                                    onClick={e => e.stopPropagation()}
                                    style={{ width: width - 5, ...extraStyles }}
                                    data-ignore-click-away
                                    ref={popoverRef}
                                >
                                    {field && (
                                        <FieldValueEditor
                                            uploadOptions={uploadOptions}
                                            videoUploadOptions={videoUploadOptions}
                                            richTextUploadOptions={richTextUploadOptions}
                                            dataSourceInfo={dataSourceInfo}
                                            data={currentData}
                                            scrollElement={scrollRef.current}
                                            personOptions={personOptions}
                                            isHiddenId
                                            width={`${width - 5}px`}
                                            field={field}
                                            recordId={recordId}
                                            autoFocus
                                            onCellChange={handleCellChange}
                                            onCellUpdate={onCellUpdate}
                                            onClose={handleClickOutside}
                                        />
                                    )}
                                </SCxWindowShapeEditorPopover>
                            </ClickAwayListener>,
                            containerElement
                        )}
                </SCxDiv>
            )
        }

        return null
    }, [
        isMobile,
        isModalShape,
        isPopperShape,
        isInput,
        open,
        pageTarget,
        handleClickOutside,
        field,
        uploadOptions,
        videoUploadOptions,
        richTextUploadOptions,
        dataSourceInfo,
        currentData,
        scrollRef,
        personOptions,
        recordId,
        handleCellChange,
        onCellUpdate,
        position,
        containerElement,
        popperAnchorElement,
        mouseEvent,
        handleClose,
        width,
        popoverRef
    ])
}
