import { mergeDeepRight } from 'rambda'
import React, { forwardRef } from 'react'

import type { BaseModalProps } from '../base'
import { BaseModal } from '../base'

export const MobileModal = forwardRef<HTMLDivElement, BaseModalProps>((props, ref) => {
    const { styles, width, ...rest } = props

    return (
        <BaseModal
            ref={ref}
            width="100%"

            styles={mergeDeepRight(
                {
                    root: {
                        alignItems: 'flex-end !important'
                    },
                    modal: {
                        marginTop: 'auto',
                        height: '80% !important',
                        borderRadius: '16px 16px 0 0 !important'
                    }
                },
                styles ?? {}
            )}
            transition={{
                from: { y: '100%' },
                to: { y: '0' }
            }}
            trapFocus={false}
            {...rest}
        />
    )
})
