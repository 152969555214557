import type { Field } from '@lighthouse/core'
import {
    type AppUser,
    type DateValue,
    type DTSelectItem,
    type InclusionRelation,
    type VariableADTvalue,
    type ViewFieldProps,
    VariableType
} from '@lighthouse/core'
import React, { useCallback, useMemo } from 'react'

import { defaultFormat, FieldNameMap, filterModeIndex, propertyMode } from '../../constants'
import { isDateValue, isIdsValue } from '../../utils'
import { DateDrawer, InputDrawer, PersonDrawer, SelectDrawer } from '../FieldDrawer'

interface ParamsDrawerProps {
    field?: Field
    operator: InclusionRelation
    opened: boolean
    value: VariableADTvalue[]
    paramsIndex?: 0 | 1
    selectOptions?: DTSelectItem[]
    personOption?: AppUser[]
    target?: string
    onFinish?: (val: VariableADTvalue[]) => void
    onInputChange?: (val: string) => void
    onClose: () => void
}

export const ParamsDrawer: React.FC<ParamsDrawerProps> = ({
    field,
    operator,
    opened,
    value,
    paramsIndex,
    selectOptions = [],
    personOption = [],
    target,
    onClose,
    onInputChange,
    onFinish
}) => {
    const mode = useMemo(() => {
        const index = filterModeIndex[operator]
        if (!field) {
            return
        }
        if (!index && index !== 0) {
            return
        }
        return propertyMode?.[field.type]?.[index]
    }, [field, operator])

    const title = useMemo(() => {
        if (field && field.type) {
            return FieldNameMap[field.type]
        }
        return '选择值'
    }, [field])

    const handleDateFinish = useCallback(
        (val: DateValue) => {
            if (paramsIndex === 0) {
                onFinish?.([
                    {
                        type: VariableType.VALUE,
                        valueVariable: {
                            type: 'date',
                            value: val,
                            format: defaultFormat
                        }
                    },
                    value?.[1]
                ])
            }
            if (paramsIndex === 1) {
                onFinish?.([
                    value?.[0],
                    {
                        type: VariableType.VALUE,
                        valueVariable: {
                            type: 'date',
                            value: val,
                            format: defaultFormat
                        }
                    }
                ])
            }
        },
        [onFinish, paramsIndex, value]
    )

    const content = useMemo(() => {
        const param = value?.[paramsIndex ?? 0] || {
            type: VariableType.VALUE
        }

        switch (mode) {
            case 'input': {
                const data = param.type === VariableType.VALUE ? param.valueVariable?.value : ''
                return (
                    <InputDrawer
                        target={target}
                        opened={opened}
                        onClose={onClose}
                        value={typeof data === 'string' ? data : ''}
                        onChange={onInputChange}
                        onFinish={val =>
                            onFinish?.([
                                {
                                    type: VariableType.VALUE,
                                    valueVariable: {
                                        type: 'text',
                                        value: val
                                    }
                                }
                            ])
                        }
                    />
                )
            }
            case 'multiPerson':
            case 'person': {
                const data = param.type === VariableType.VALUE ? param.valueVariable?.value : []
                return (
                    <PersonDrawer
                        selectOptions={personOption}
                        target={target}
                        opened={opened}
                        isMultiple={mode === 'multiPerson'}
                        title={title}
                        value={isIdsValue(data) ? data : []}
                        onClose={onClose}
                        onChange={val =>
                            onFinish?.([
                                {
                                    type: VariableType.VALUE,
                                    valueVariable: {
                                        type: 'person',
                                        value: val
                                    }
                                }
                            ])
                        }
                    />
                )
            }
            case 'select':
            case 'multiSelect': {
                const data = param.type === VariableType.VALUE ? param.valueVariable?.value : []
                return (
                    <SelectDrawer
                        target={target}
                        opened={opened}
                        title={title}
                        isMultiple={mode === 'multiSelect'}
                        onClose={onClose}
                        value={isIdsValue(data) ? data : []}
                        selectOptions={selectOptions}
                        onChange={val =>
                            onFinish?.([
                                {
                                    type: VariableType.VALUE,
                                    valueVariable: {
                                        type: 'select',
                                        value: val
                                    }
                                }
                            ])
                        }
                    />
                )
            }
            case 'date':
            case 'rangeDate': {
                const data =
                    param.type === VariableType.VALUE ? (isDateValue(param.valueVariable?.value) ? [param.valueVariable?.value] : []) : []
                const showTime = field?.type === 'date' && !!field.date.format && field.date.format.includes('HH')
                return (
                    <DateDrawer
                        showTime={showTime}
                        target={target}
                        opened={opened}
                        onClose={onClose}
                        value={data?.[paramsIndex || 0]}
                        onChange={handleDateFinish}
                    />
                )
            }
            default: {
                return null
            }
        }
    }, [
        value,
        paramsIndex,
        mode,
        target,
        opened,
        onClose,
        onInputChange,
        onFinish,
        personOption,
        title,
        selectOptions,
        field,
        handleDateFinish
    ])

    if (!opened) {
        return null
    }

    return content
}
