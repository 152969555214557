import type { FilterBlockItemConfig, FilterBlockValue } from '@lighthouse/core'
import { FilterBlockMode } from '@lighthouse/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import type { FilterItemProps } from '../types'
import { NormalFilterItem } from './NormalFilterItem'
import { TileFilterItem } from './TileFilterItem'

const SCxNormalFilterList = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 8px;
    max-width: 100%;
    /* overflow: hidden; */
`

interface DesktopFilterProps extends Pick<FilterItemProps, 'labelWidth' | 'recordId' | 'dataSourceList' | 'onChange' | 'onFetchTextOptions' | 'onFetchCascadeOptions'> {
    list: FilterBlockItemConfig[]
    mode: FilterBlockMode
    filterData: FilterBlockValue
}

export const DesktopFilter: React.FC<DesktopFilterProps> = ({
    list,
    mode,
    labelWidth,
    filterData,
    dataSourceList,
    recordId,
    onChange,
    onFetchCascadeOptions,
    onFetchTextOptions
}) => {
    return (
        <>
            {mode === FilterBlockMode.normal ? (
                <SCxNormalFilterList>
                    {list.map(item => (
                        <NormalFilterItem
                            key={item.id}
                            config={item}
                            value={filterData?.[item.id]?.value}
                            customValue={filterData?.[item.id]?.customValue}
                            dataSourceList={dataSourceList}
                            recordId={recordId}
                            onChange={onChange}
                            onFetchCascadeOptions={onFetchCascadeOptions}
                            onFetchTextOptions={onFetchTextOptions}
                        />
                    ))}
                </SCxNormalFilterList>
            ) : (
                list.map(item => (
                    <TileFilterItem
                        key={item.id}
                        config={item}
                        labelWidth={labelWidth}
                        value={filterData?.[item.id]?.value}
                        customValue={filterData?.[item.id]?.customValue}
                        dataSourceList={dataSourceList}
                        recordId={recordId}
                        onChange={onChange}
                        onFetchCascadeOptions={onFetchCascadeOptions}
                        onFetchTextOptions={onFetchTextOptions}
                    />
                ))
            )}
        </>
    )
}
