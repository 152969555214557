import type { SelectItemProps } from '@byecode/ui'
import { type Option, Divider, SelectItem } from '@byecode/ui'
import { type Field, type FieldType } from '@lighthouse/core'
import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { fileOperatorOptions, filterModeIndex, propertyFilter } from '../../constants/filter'
import { DEFAULT_FILTER_VALUE_VARIABLE, FILTER_OPERATOR } from '../'
import * as SC from './styles'

interface OperatorProps {
    field?: Field
    prefixName: string
    style?: React.CSSProperties
    clearable?: boolean
    enableFilterVariable?: boolean
    disabled?: boolean
}

const OperatorOptionComponent: React.FC<SelectItemProps> = ({ ...rest }) => {
    if (rest.value === 'filterController') {
        return (
            <SC.SelectItemWrapper>
                <Divider style={{ margin: '8px 0' }} />
                <SelectItem {...rest} />
            </SC.SelectItemWrapper>
        )
    }
    return <SelectItem {...rest} />
}

export const Operator: React.FC<OperatorProps> = ({ field, prefixName, style, clearable = true, enableFilterVariable, disabled }) => {
    const { control, setValue, getValues } = useFormContext()

    const handleParams = useCallback(
        (originVal: string, val: string, type?: FieldType) => {
            if (!type) {
                return
            }

            const index = filterModeIndex[val]
            const originIndex = filterModeIndex[originVal]

            if (index === 2) {
                setValue(`${prefixName}.paramList`, [DEFAULT_FILTER_VALUE_VARIABLE, DEFAULT_FILTER_VALUE_VARIABLE])
            }
            if (index === 1 && type === 'date') {
                setValue(`${prefixName}.paramList`, [DEFAULT_FILTER_VALUE_VARIABLE])
            }
            if (index === 0 && type === 'date') {
                setValue(`${prefixName}.paramList`, [DEFAULT_FILTER_VALUE_VARIABLE])
            }
            if (originIndex === 4 || index === 4) {
                setValue(`${prefixName}.paramList`, [DEFAULT_FILTER_VALUE_VARIABLE])
            }
        },
        [prefixName, setValue]
    )

    const getOperatorOption: (field?: Field) => Option[] | undefined = useCallback(
        (field?: Field) => {
            if (!field || !field?.innerType) {
                return []
            }
            if (field.type === 'file') {
                return fileOperatorOptions
            }
            if (enableFilterVariable) {
                return [...propertyFilter[field.innerType], { label: '使用筛选控制器', value: FILTER_OPERATOR }]
            }
            return propertyFilter[field.innerType]
        },
        [enableFilterVariable]
    )
    return (
        <Controller
            name={`${prefixName}.operator`}
            control={control}
            render={({ field: ctlField }) => {
                return (
                    <SC.ParamsSelect
                        style={style}
                        {...ctlField}
                        styles={{
                            root: {
                                width: 120
                            }
                        }}
                        optionComponent={OperatorOptionComponent}
                        disabled={!field || disabled}
                        clearable={clearable}
                        options={getOperatorOption(field)}
                        onChange={val => {
                            if (val !== ctlField.value) {
                                ctlField.onChange(val)
                                handleParams(ctlField.value, val, field?.type)
                            }
                        }}
                    />
                )
            }}
        />
    )
}
