export const errorCodeMap = {
    '0000000': '成功',
    '0011004': '应用端应用不存在',
    '0011005': '页面无访问权限',
    '0011010': '名称重复',
    '0011013': '无法启用工作流，请检查配置后重试',
    '0011019': '用户已注册',
    '0011021': '您的帐号正在审核',
    '0011023': '测试账号连接失败',
    '0011024': '可发布应用数量达到上限',
    '0011025': '记录条数达到上限',
    // '0011026': '工作流执行次数超出限制',
    '0011027': '附件容量达到上限',
    '0011028': 'AI使用次数达到上限',
    '0011030': '手机号重复',
    '0011031': '部门名称重复',
    '0011032': '用户数量达到上限',
    '0011033': '绑定自有域名的应用达到上限',
    '0011034': '数据查询算力达到上限',
    '0011035': '本月数据表查询算力达到上限',
    '0011036': '导出超过 500 条',
    '0011037': '导出超过 50MB',
    '0011039': '账号异常，请联系管理员',
    '0011042': '微信公众号未授权',
    '0011043': '邮箱重复',
    '0011044': '手机号已绑定',
    '0011045': '微信支付异常',
    '0011048': '不能通过动作删除用',
    '0011049': '找不到记录',
    '0011050': '域名格式错误',
    '0011051': '微信模板发送消息 消息参数不符合规范',
    '0011052': '微信模板不可用',
    '0011053': '微信模板获取失败, 请检查相关配置',
    '0011054': '环境存在异常，禁止合并',
    '0011055': '正式版本禁止修改数据',
    '0011057': '该应用用户已被其他应用绑定，无法再绑定其他应用',
    '0011058': '私人模板禁止操作',
    '0011059': '微信不可重复绑定',
    '0020000': '页面不存在或页面未公开！', // FIXME: 临时解决频繁切换应用和页面报错 @keLin
    '0021002': '该用户未注册',
    '0021003': '空间不存在',
    '0021004': '应用不存在',
    '0021005': '页面不存在',
    '0021006': '邀请token无效',
    '0021007': '邀请空间被删除',
    '0021008': '数据源已被删除',
    '0021009': '用户不存在',
    '0021011': '验证码已失效',
    '0021012': '邀请码无效',
    '0021013': '无权限执行该操作',
    '0021014': '继承异常',
    '0021017': '应用已下线',
    '0021018': '域名已存在，请重新输入',

    '0021020': '请刷新后重试',

    '0021029': '应用无权限',
    '0021038': '数据源关联的数据量过多',
    '0021039': '应用模板不存在',
    '0030000': '过滤异常，无需抛出',
    '0031000': '循环引用',
    '0120001': '无操作权限',
    '0311002': '请先登录',
    '0311004': '登录过期,需要重新登录',
    '0311005': '身份验证失效',
    '0321001': '用户名或密码错误',
    '0321003': '验证码错误，请重新输入',
    '0321004': 'token已过期',
    '0321006': '账号不存在，请注册'
}

export type ErrorCode = keyof typeof errorCodeMap

export const notFoundCodeList: ErrorCode[] = ['0021004', '0011004', '0021029']

export const notFoundTemplateCodeList: ErrorCode[] = ['0021039']

export const applicationUnpublished: ErrorCode = '0021017'

export const checkLoginCodeList: ErrorCode[] = ['0311002', '0311004', '0311005']

export const noAccessCodeList: ErrorCode[] = ['0011005', '0021005', '0311002']

export const reviewCodeList: ErrorCode[] = ['0011021']

export const noUserCodeList: ErrorCode[] = ['0021009']

export const needRegisterCodeList: ErrorCode[] = ['0321006']

export const skipCodeList: ErrorCode[] = ['0020000', '0021020', '0011042', '0030000'] // 暂时解决报错问题，路由引起的报错，后续需要优化，APPID 和 pageId 无法对应

export const limitExceededCodeList: ErrorCode[] = [
    '0011024',
    '0011025',
    /** '0011026', */ '0011027',
    '0011028',
    '0011032',
    '0011033',
    '0011034',
    '0011035'
]

export const dataSourceLimitCodeList: ErrorCode[] = ['0021038']

export const wechatPayCodeList: ErrorCode[] = ['0011045']
