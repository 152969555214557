import { Enum } from '@lighthouse/tools'

import type { Arrangement, BlockSize, DesignProtocol, Direction } from '../../protocols'

export const CUSTOM_VIEW_LAYOUT = Enum('waterfall', 'grid')
export type CustomViewLayout = Enum<typeof CUSTOM_VIEW_LAYOUT>

export interface CustomViewOptions {
    layout?: CustomViewLayout
    direction?: Direction
    arrangement?: Arrangement
    size?: Exclude<BlockSize, 'xs'>
    cols?: number
    gap?: number

    design?: DesignProtocol
}
