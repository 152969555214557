import type { DividerBlockAbstract } from '@lighthouse/core'
import type { FC } from 'react'
import React from 'react'
import styled from 'styled-components'

const DividerRoot = styled.div`
    margin: 4px;
    display: flex;
    align-items: center;
`

const DividerContent = styled.div<Pick<DividerBlockAbstract['config'], 'visible'>>`
    flex: 1;
    height: 1px;
    background-color: var(--color-gray-200);
    opacity: ${p => (p.visible ? 1 : 0)};
`

interface DividerBlockProps {
    blockData: DividerBlockAbstract
}

const DividerBlock: FC<DividerBlockProps> = ({ blockData }) => {
    return (
        <DividerRoot style={{ height: `${blockData.config.height}rem` }}>
            <DividerContent visible={blockData.config.visible} />
        </DividerRoot>
    )
}

export default DividerBlock
