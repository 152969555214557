import { singleTextEllipsis } from '@byecode/ui'
import type { DepartmentValue, FieldProtocol, HighLightConditions } from '@lighthouse/core'
import React from 'react'
import styled, { css } from 'styled-components'

import { TooltipList, useWidth } from '../TooltipList'

export interface DepartmentValuePreviewerProps extends Pick<React.CSSProperties, 'justifyContent'> {
    field: FieldProtocol
    data?: DepartmentValue
    width?: number
    isWrap?: boolean
}

export interface DepartmentItemPreviewerProps {
    children?: React.ReactNode
    id: string
    data: string
    colorless?: boolean
    isLast?: boolean
    isVertical?: boolean
    isWrap?: boolean
    direction?: 'horizontal' | 'vertical'
    textStyles?: React.CSSProperties
}

const SCxDepartmentListWrapper = styled.div<{ isWrap: boolean; isPadding?: boolean } & Pick<React.CSSProperties, 'justifyContent'>>`
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent};
    padding-right: ${({ isPadding }) => (isPadding ? '20px' : '0')};
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
    overflow: hidden;
`

const SCxCellValue = styled.div<{ isLast?: boolean }>`
    line-height: 16px;
    padding: 4px 6px;
    height: 24px;
    color: var(--color-black);
    font-size: var(--font-size-normal);
    border-radius: 4px;
    background-color: var(--color-gray-200);
    white-space: nowrap;
    ${({ isLast }) => isLast && singleTextEllipsis}
`

export const DepartmentItemPreviewer: React.FC<DepartmentItemPreviewerProps> = ({ id, data, isLast = false, textStyles }) => {
    // const { label, color } = data
    const { ref: widthRef } = useWidth<HTMLDivElement>(id)
    return (
        <SCxCellValue key={data} ref={widthRef} isLast={isLast} style={textStyles}>
            {data}
        </SCxCellValue>
    )
}

export const DepartmentValuePreviewer: React.FC<DepartmentValuePreviewerProps> = ({
    field,
    data = [],
    width = 160,
    isWrap = true,
    justifyContent = 'flex-start'
}) => {
    return (
        <TooltipList
            data={data.map((item, index) => ({ label: item, value: String(index) }))}
            width={width}
            key={data.join('-')}
            disabled={isWrap}
            render={visibleNum => {
                const renderList = data.slice(0, visibleNum)
                return (
                    <SCxDepartmentListWrapper justifyContent={justifyContent} isPadding={visibleNum < data.length} isWrap={isWrap}>
                        {renderList.map((item, index) => (
                            <DepartmentItemPreviewer
                                key={String(index)}
                                id={String(index)}
                                data={item}
                                isLast={index === renderList.length - 1}
                                isWrap={isWrap}
                            />
                        ))}
                    </SCxDepartmentListWrapper>
                )
            }}
        />
    )
}
