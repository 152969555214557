import { Enum } from '@lighthouse/tools'
import type { AnyArray } from 'immer/dist/internal'
import type React from 'react'

import type { FilterFormType, HighLightConditions, QuickFilterConfig } from './filter'
import type { JoinConfig } from './join'
import type { ViewField } from './view'

export type AppID = string

export type BlockID = string

export type UserID = string

export interface SizedProtocol {
    width: number
    height?: number
    minWidth?: number
    minHeight?: number
    maxWidth?: number
    maxHeight?: number
    initialWidth?: number
    initialHeight?: number
}

export interface SelectBaseOptionProtocol {
    value: string
    label: string
}

export interface Pagination {
    currentPage?: number
    pageSize?: number
    rowTotal?: number
    custom?: boolean
}

export interface PaginationProtocol {
    pagination: Pagination
}
/**
 * 可选择协议
 */
export interface SelectableProtocol {
    selected?: boolean
    isSelectable?: boolean
}

/**
 * 可拖拽协议
 */
export interface DraggableProtocol {
    dragging?: boolean
    isDraggable?: boolean
    dragHandle?: string
}

/**
 * 可选择与可拖动协议
 */
export type SelectableDraggableProtocol = SelectableProtocol &
    DraggableProtocol & {
        selectNodesOnDrag?: boolean
    }

/**
 * 可缩放的角度
 */
type ResizeHandle = 's' | 'w' | 'e' | 'n' | 'sw' | 'nw' | 'se' | 'ne'

/**
 * 可缩放协议
 */
export type ResizableProtocol = SizedProtocol & {
    isResizable?: boolean
    resizeObserver?: ResizeObserver
    resizeHandles?: ResizeHandle[]
}

/**
 * 栅格子元素协议
 */
export type GridItemProtocol = SelectableDraggableProtocol & ResizableProtocol

/**
 * 图标协议
 */
export interface IconicProtocol {
    name: string

    icon?: string

    defaultIcon?: string
}

/**
 * style 协议
 */
export interface StyledProtocol {
    style: React.CSSProperties
}

export type Sorter = {
    id: string
    order: string
    fieldId: string
}

export type GroupBy = {
    label: string
    value: string
    visible: boolean
}

export interface TableColumn {
    id: string
    visible: boolean
    width: number
    required?: boolean
}

export type TableColumns = TableColumn[]

export type FieldSettingContent = {
    sort?: Sorter[]
    filter?: FilterFormType
    required?: boolean
    fieldPros?: TableColumns
}

export type FieldSetting = Record<string, FieldSettingContent>
export interface TablePropsProtocol {
    tableProps: TableColumns
}

export interface JoinProtocol {
    joinConfig?: JoinConfig
}

export interface AggregateProtocol {
    analyticConfig?: {
        nodes: AnyArray
        edges: AnyArray
    }
    draftAnalyticConfig?: {
        nodes: AnyArray
        edges: AnyArray
    }
}

export type kanbanGroupConfigure = {
    kanbanGroupByField?: string
    kanbanGroupConfig?: GroupBy[]
}

export type GroupConfigure = {
    groupByFieldId: string
    canHiddenTabValue?: boolean
    groupConfig: GroupBy[]
}

export interface GroupByProtocol {
    canGroup?: boolean
    groupConfigure?: GroupConfigure
}
export type SortableProtocol = {
    sorts?: Sorter[]
}

export type FilterableProtocol = {
    filter?: FilterFormType
    linkFilterController?: FilterFormType
}

export interface ViewFilterableProtocol extends FilterableProtocol {
    quickFilter: QuickFilterConfig
}

export interface HighlightingProtocol {
    highLightRules?: HighLightConditions
}

export interface PromptProtocol {
    prompt?: string
}

export interface CreatableProtocol {
    createdTime?: number
    updatedTime?: number
    createdBy?: string
    updatedBy?: string
}

/**
 * 可编辑协议
 */
export interface EditableProtocol {
    canEdit?: boolean
}

export const RecordOpenType = Enum('modal', 'drawer', 'page')
/** 记录页面打开方式 */
export type RecordOpenType = Enum<typeof RecordOpenType>

export interface RequiredProtocol {
    required?: boolean
}

export interface RelationFieldProtocol {
    fieldProps?: TableColumns
}

export interface ViewFieldSettingProtocol {
    // viewPrimaryField?: string
    viewFieldSettings: ViewField[]
}

/** 视图记录可操作协议 */
export interface ViewRecordOperateProtocol {
    canCreateRecord?: boolean
    creatingConfig?: {
        page?: string
        openType?: RecordOpenType
        label?: string
    }
    canDeleteRecord?: boolean
}

/** 视图记录可编辑协议 */
export interface ViewRecordEditProtocol {
    editingConfig?: {
        page?: string
        openType?: RecordOpenType
    }
    canViewEdit?: boolean
}

export interface SearchableProtocol {
    canSearch?: boolean
}

export interface ImportProtocol {
    canImport?: boolean
    userImportSetting?: string[]
    triggerWorkflow?: boolean
    repeat?: boolean
    compareFields?: string[]
    importType?: string
    importMode?: string
}

export interface ExportProtocol {
    canExport?: boolean
    exportFields?: string[]
}

export interface ChartExportProtocol {
    canExport?: boolean
}

export interface PrintProtocol {
    canPrint?: boolean
}

export interface SortProtocol {
    canSort?: boolean
    userSortSetting?: string[]
}

export interface DisplayProtocol {
    canDisplay?: boolean
}

export interface SearchProtocol {
    search?: string
}

export interface PaginableProtocol {
    canPaginate?: boolean
}

export interface RecordViewableProtocol {
    canViewRecord?: boolean
    viewingConfig?: {
        page?: string
        openType?: RecordOpenType
    }
}

export interface DownloadableProtocol {
    canDownload?: boolean
}

/** 可多选协议  */
export interface MultipleChoiceProtocol {
    canMultipleChoice?: boolean
}

export interface NoRepeatProtocol {
    noRepeat?: boolean
}

export type VideoSourceProtocol = 'youku' | 'youtube' | 'bilibili' | 'byecode' | 'aliyun' | 'other'

