import type { FormResult } from '@lighthouse/block'
import type { ApplicationWebsiteSetting, FormRule, HttpResp, StripePayStatus, TextAdvanceOption, WechatPayStatus } from '@lighthouse/core'
import {
    type ApplicationAbstract,
    type AppUser,
    type BlockAbstract,
    type DataSourceAbstract,
    type DataSourceProtocol,
    type FieldInputValue,
    type FilterCommonCondition,
    type FilterFormType,
    type GroupRecordCount,
    type PageAbstract,
    type RecordLikeProtocol,
    type Sorter,
    DataSourceType
} from '@lighthouse/core'
import type {
    AccountBindAccountForm,
    AccountBindMobileForm,
    AccountMergeAccount,
    AccountPerfectForm,
    AccountRegisterForm,
    AliyunMessageActionPayload,
    AppUserGroup,
    BindMobileResult,
    ChartExportParams,
    DsExportParams,
    LoginAuthType,
    ScanStatus,
    StripeAccountItem,
    UpdateWorkflowLogPayload,
    ViewAppendParams,
    ViewExportParams,
    WeChatPayAccountItem,
    WeChatPublicAccountItem,
    WeChatWebsiteAccountItem,
    WxTemplateMessageActionPayload
} from '@lighthouse/shared'
import {
    type ActiveAddIClickUserActionPayload,
    type ActiveDingTalkRobotActionPayload,
    type ActiveFindSingleRecordActionPayload,
    type ActiveSendEmailActionPayload,
    type ActiveStationMessageActionPayload,
    type ActiveWxRobotActionPayload,
    type ApprovePayload,
    type CreateRecordWithFieldsPayload,
    type GenerateAiTableParams,
    type LoginUser,
    type MessageDetailDataRes,
    type MessageListDataRes,
    type RelativeOption,
    type TriggerAutomationActionPayload,
    type UpdateRecordWithFieldsPayload,
    type ViewPrintWithTemplateParams,
    type Workflow,
    getMainTableRecordId
} from '@lighthouse/shared'
import type { AxiosResponse } from 'axios'
import type { AnyObject } from 'immer/dist/internal'
import { multiply, subtract } from 'rambda'
import type { FieldValues } from 'react-hook-form'

import type { DeleteViewRecordAtomPayload } from '@/atoms/blockRecordsDict/types'
import http from '@/http'

import type {
    BindAccountStatus,
    ChartData,
    CreateRecordFromFormData,
    CreateRecordPayload,
    DataSourceAndRecordsRes,
    DataSourceAndRecordsResult,
    DataSourceAndRecordsWithConfigRes,
    DataSourceAndRecordsWithConfigResult,
    GetDataSourcePayload,
    GetDsCountPayload,
    GetDsGraphViewPayload,
    GetRecordByFilterPayload,
    GetViewByBlockPayload,
    GroupRecordCountPayload,
    InvokeWechatPayResult,
    LoginProtocolPayload,
    OtherPlatform,
    PageContentRes,
    PageRecordRes,
    PageRecordResult,
    PerfectInfoCode,
    RecordRes,
    RecordWithPageIdPayload,
    StripePayPayload,
    StripePayResult,
    SubmitFormPayload,
    SubmitFormRes,
    UpdateCellPayload,
    UserDataSourceRes,
    UserDataSourceResult,
    WechatPayPayload,
    WechatRefoundPayload,
    WechatShareDataParams,
    WechatShareResult,
    WechatSignature
} from './types'

const JsonContentType = { 'Content-Type': 'application/json' }
const formContentType = { 'Content-Type': 'multipart/form-data' }

export const includeModulaUrl = {
    open: 'api/open/v1',
    user: 'api/v1/user',
    page: 'api/v1/page',
    ds: 'api/v1/ds',
    integration: 'api/v1/integration',
    approve: 'api/v1/approve',
    workflow: 'api/v1/workflow',
    tokenRotation: 'api/v1/tokenRotation',
    oss: 'api/v1/oss',
    action: 'api/v1/action'
}

const addPageAuth = function (url: string, pageId: string) {
    if (!pageId) {
        return url
    }
    return url.replace('api/v1/', `api/v1/page-auth/${pageId}/`)
}

export const excludeModulaUrl = ['api/open/v1/getAppId', 'api/open/v1/login']
/**
 * 获取appId
 * @returns
 */
export async function getAppId(url: string): Promise<[string, boolean]> {
    const res = await http
        .get<unknown, HttpResp>(`${includeModulaUrl.open}/getAppId`, {
            params: {
                url
            }
        })
        .catch(error => error)
    return [res?.content, res?.success]
}

/**
 * 获取应用信息
 * @returns
 */
export async function getAppInfo() {
    const res = await http.get<
        unknown,
        HttpResp<
            ApplicationAbstract & {
                loggedIn?: boolean
                integrations: {
                    wechatPay: WeChatPayAccountItem
                    weChatOfficialAccount: WeChatPublicAccountItem
                    wechatWebsite: WeChatWebsiteAccountItem
                    stripe: StripeAccountItem
                }
                version: {
                    config: Pick<ApplicationWebsiteSetting, 'navbar' | 'authentication' | 'language' | 'theme'>
                }
            }
        >
    >(`${includeModulaUrl.open}/getAppInfo`)

    const { version, config, ...rest } = res.content
    const { config: versionConfig, ...resetVersion } = version

    return {
        ...rest,
        config: { ...config, ...versionConfig },
        version: resetVersion
    } as ApplicationAbstract & {
        loggedIn?: boolean
        integrations: {
            wechatPay: WeChatPayAccountItem
            weChatOfficialAccount: WeChatPublicAccountItem
            wechatWebsite: WeChatWebsiteAccountItem
            stripe: StripeAccountItem
        }
    }
}

/**
 *
 * @returns
 */
export async function tokenRotation() {
    const res = await http.get<unknown, HttpResp>(includeModulaUrl.tokenRotation)
    return res.success
}

/**
 * 退出登录
 * @param
 * @returns
 */
export async function logout(): Promise<boolean> {
    const res = await http.get<unknown, HttpResp<LoginUser>>(`${includeModulaUrl.user}/logout`)
    return res.success
}

/**
 * 登录
 * @param payload
 * @returns
 */
export async function login(payload: LoginProtocolPayload): Promise<[boolean, string]> {
    const res = await http.post<unknown, HttpResp<LoginUser & { user: AppUser }>>(`${includeModulaUrl.open}/login`, payload)
    return [res.success, res.code]
}

/**
 * 检查是否完善信息
 * @param payload
 * @returns
 */
export async function checkPerfect() {
    const res = await http.get<unknown, HttpResp<PerfectInfoCode>>(`${includeModulaUrl.user}/checkPerfect`)
    return res.content
}


/**
 * 绑定手机号
 * @param payload
 * @returns
 */
export async function bindMobile(params: AccountBindMobileForm) {
    const res = await http.post<unknown, HttpResp<BindMobileResult>>(`${includeModulaUrl.user}/bindMobile`, params, {
        headers: JsonContentType
    })
    return res.content
}

/**
 * 注册
 */
export async function register(params: AccountRegisterForm): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<{ accessToken: string }>>(`${includeModulaUrl.open}/register`, params, {
        headers: JsonContentType
    })
    return res.success
}

/**
 * 完善信息
 * @param payload
 * @returns
 */
export async function perfect(params: AccountPerfectForm) {
    const res = await http.post<unknown, HttpResp<boolean>>(`${includeModulaUrl.user}/perfectInfo`, params, {
        headers: JsonContentType
    })
    return res.success
}
/**
 * 用户信息
 * @param
 * @returns
 */
export async function getUser(): Promise<AppUser> {
    const res = await http.get<unknown, HttpResp<AppUser>>(`${includeModulaUrl.user}/getUser`)
    return res.content
}

/**
 * 获取应用的用户列表
 * @return {*}  {Promise<AppUser[]>}
 */

export async function getAppUsers(appId: string): Promise<AppUser[]> {
    const res = await http.get<unknown, HttpResp<AppUser[]>>(`${includeModulaUrl.open}/app/getUsers`, { params: { appId } })
    return res.content
}
/**
 * 获取应用的用户组
 * @return {*}  {Promise<AppUser[]>}
 */
export async function getAppGroups(): Promise<AppUserGroup[]> {
    const res = await http.get<unknown, HttpResp<AppUserGroup[]>>(`${includeModulaUrl.open}/getGroups`)
    return res.content
}

/**
 * 获得页面列表
 * @param
 * @returns
 */
export async function getPages(): Promise<PageAbstract[]> {
    const res = await http.get<unknown, HttpResp<PageAbstract[]>>(`${includeModulaUrl.open}/page/list`)
    return res.content
}

/**
 * 获取验证码
 * @param payload
 * @returns
 */
export async function getSmsCode(mobile: string): Promise<boolean> {
    const res = await http.get<unknown, HttpResp<boolean>>(`${includeModulaUrl.open}/sms/getSmsCode`, {
        params: {
            mobile
        }
    })
    return res.success
}
/**
 * 获取邮箱验证码
 * @param payload
 * @returns
 */
export async function getEmailCode(email: string): Promise<boolean> {
    const res = await http.get<unknown, HttpResp<boolean>>(`${includeModulaUrl.open}/email/getEmailCode`, {
        params: {
            email
        }
    })
    return res.success
}

/**
 * 获取应用下数据源列表
 * @return {*}
 */
export async function listDs(appId?: string): Promise<DataSourceAbstract[]> {
    const res = await http.get<unknown, HttpResp<DataSourceAbstract[]>>(`${includeModulaUrl.open}/app/listDs`, { params: { appId } })
    return res.content
}

/**
 * 获取数据源数据
 * @param {string} dataSourceId
 * @return {*}  {Promise<boolean>}
 */

export async function getDs(params: GetDataSourcePayload): Promise<DataSourceAndRecordsResult> {
    const { appId, envId, dsId, pagination, search, filter, sorts, currentRecordId, parentRecordId, disabledFilter, abort, pageId } = params
    const { currentPage, pageSize } = pagination
    const res = await http.post<unknown, HttpResp<DataSourceAndRecordsRes>>(
        addPageAuth(`${includeModulaUrl.ds}/getDs`, pageId),
        { appId, dsId, currentPage, pageSize, search, filter, sorts, currentRecordId, parentRecordId, disabledFilter },
        {
            headers: JsonContentType,
            signal: abort?.signal
        }
    )
    const { datasource, records } = res.content
    const dsIncludesAppIdAndEnvId = {
        ...datasource,
        appId,
        envId
    }
    return {
        datasource: dsIncludesAppIdAndEnvId,
        records: records.map(record => ({
            appId,
            dsId,
            envId,
            ...record
        }))
    }
}

export async function getDsMeta(appId: string, dsId: string, pageId: string): Promise<DataSourceAbstract> {
    const res = await http.post<unknown, HttpResp<DataSourceAbstract>>(addPageAuth(`${includeModulaUrl.ds}/getSchema`, pageId), {
        appId,
        dsId
    })
    return res.content
}

// /**
//  * 获取视图中关联表格数据行数
//  * @param {string} viewId
//  * @param {string} fieldId
//  * @param {string | undefined} search
//  * @return {*}  {Promise<boolean>}
//  */
// export async function getRelatedDsCount(viewId: string, fieldId: string, search?: string): Promise<number> {
//     const res = await http.get<unknown, HttpResp<number>>('/api/v1/ds/relatedDsCount', { params: { viewId, fieldId, search } })
//     return res.content
// }

/**
 * 获取数据源行数
 * @param {string} dataSourceId
 * @return {*}  {Promise<boolean>}
 */
export async function getDsCount(params: GetDsCountPayload): Promise<number | undefined> {
    const { appId, dsId, search, filter, currentRecordId, parentRecordId, pageId, abort } = params
    const res = await http.post<unknown, HttpResp<number> | undefined>(
        addPageAuth(`${includeModulaUrl.ds}/dsCount`, pageId),
        { appId, dsId, search, filter, currentRecordId, parentRecordId },
        {
            headers: JsonContentType,
            signal: abort?.signal
        }
    )
    return res?.content
}

/**
 * 获取图表数据
 * @param {string} viewId
 * @param {string} versionId
 * @return {*}  {Promise<number>}
 */
export async function getDsGraphView(payload: GetDsGraphViewPayload) {
    const { pageId } = payload
    const res = await http.post<unknown, HttpResp<ChartData>>(addPageAuth(`${includeModulaUrl.ds}/getDsGraphView`, pageId), payload, {
        headers: JsonContentType
    })
    return res.content
}

/**
 * 更新单元格
 * @param {UpdateCellPayload} payload
 * @return {*}  {Promise<boolean>}
 */

export async function updateCell(payload: UpdateCellPayload): Promise<boolean> {
    // TODO: 临时解决连接表问题
    const params = {
        ...payload,
        id: getMainTableRecordId(payload.id)
    }
    const res = await http.post<unknown, HttpResp>(addPageAuth(`${includeModulaUrl.ds}/updateCell`, params.pageId), params, {
        headers: JsonContentType
    })
    return res.success
}

/**
 * 生成ai字段内容
 * @param params
 * @returns
 */
export async function aiGenerate(payload: { dsId: string; recordId: string; fieldId: string; pageId: string }) {
    // TODO: 临时解决连接表问题
    const params = {
        ...payload,
        recordId: getMainTableRecordId(payload.recordId)
    }
    const res = await http.get<unknown, HttpResp<string>>(addPageAuth(`${includeModulaUrl.ds}/aiGenerate`, params.pageId), {
        params
    })
    return res.success
}

/**
 * 获取视图各个分组的记录总数
 * @param {GroupRecordCountPayload} params
 * @return {*} Promise<boolean>
 */
export async function getGroupRecordCount(params: GroupRecordCountPayload): Promise<GroupRecordCount[] | undefined> {
    const res = await http.post<unknown, HttpResp<GroupRecordCount[]> | undefined>(
        addPageAuth(`${includeModulaUrl.ds}/getViewGroupRecordCount`, params.pageId),
        params,
        {
            headers: JsonContentType,
            signal: params?.abort?.signal
        }
    )
    return res?.content
}

/**
 * 获取blocks下的数据源
 * @param {string} blockId
 * @return {*} Promise<boolean>
 */
export async function getViewByBlock(payload: GetViewByBlockPayload): Promise<DataSourceAndRecordsWithConfigResult | undefined> {
    const { appId, envId, viewId, pageId, pagination, params, search, currentRecordId, parentRecordId, abort } = payload
    const { currentPage, pageSize } = pagination
    const res = await http.post<unknown, HttpResp<DataSourceAndRecordsWithConfigRes> | undefined>(
        addPageAuth(`${includeModulaUrl.ds}/getView`, pageId),
        {
            viewId,
            currentPage,
            pageSize,
            ...params,
            search,
            pageId,
            currentRecordId,
            parentRecordId
        },
        {
            headers: JsonContentType,
            signal: abort?.signal
        }
    )
    if (!res) {
        return
    }

    const { datasource, records, config } = res.content
    const dsIncludesAppIdAndEnvId = {
        ...datasource,
        appId,
        envId
    }
    const {
        view: {
            pagination: { currentPage: pageNum = 1, pageSize: size = 50 }
        }
    } = config
    const { type, id: dsId } = datasource
    if (type === DataSourceType.joinDataSource) {
        const startIndex = multiply(subtract(pageNum, 1), size)
        const newRecord = records.map((item, index) => ({
            ...item,
            appId,
            envId,
            dsId,
            id: `${item.id}__${viewId}${startIndex + index}`
        }))
        return {
            datasource: dsIncludesAppIdAndEnvId,
            config,
            records: newRecord
        }
    }

    return {
        datasource: dsIncludesAppIdAndEnvId,
        config,
        records: records.map(record => ({
            appId,
            dsId,
            envId,
            ...record
        }))
    }
}

/**
 * 获取blocks下的总行数
 * @param {string} viewId
 * @param {string} search
 * @return {*}  {Promise<number>}
 */

export async function getViewCount(payload: {
    viewId: string
    pageId: string
    quickFilters?: FilterCommonCondition[]
    search?: string
    currentRecordId?: string
    parentRecordId?: string
    linkFilter?: FilterFormType
    abort?: AbortController
}): Promise<number | undefined> {
    const { viewId, pageId, quickFilters, search, currentRecordId, parentRecordId, linkFilter, abort } = payload
    const res = await http.post<unknown, HttpResp<number> | undefined>(
        addPageAuth(`${includeModulaUrl.ds}/viewCount`, pageId),
        { viewId, quickFilters, search, currentRecordId, parentRecordId, pageId, linkFilter },
        {
            headers: JsonContentType,
            signal: abort?.signal
        }
    )
    return res?.content
}

/**
 * 更新block
 * @param {Block} data
 * @return {*}  {Promise<boolean>}
 */
export async function updateBlock(data: BlockAbstract, pageId: string) {
    const { type, config, ...rest } = data
    const formatData = { ...rest, type, config: { [type]: config }, pageId }
    const res = await http.post<unknown, HttpResp>(addPageAuth(`${includeModulaUrl.page}/updateBlock`, pageId), formatData, {
        headers: JsonContentType
    })
    return res.success
}

type UpdateRecordParams = {
    dsId: string
    recordId: string
    pageId: string
    fields: { fieldId: string; value?: FieldInputValue }[]
}

/**
 * 更新一条记录的多个字段
 * @param {UpdateRecordParams} params
 * @returns
 */
export async function updateRecord(payload: UpdateRecordParams) {
    // TODO: 临时解决连接表问题
    const params = {
        ...payload,
        recordId: getMainTableRecordId(payload.recordId)
    }
    const res = await http.post<unknown, HttpResp<RecordLikeProtocol>>(
        addPageAuth(`${includeModulaUrl.ds}/updateRecord`, payload.pageId),
        params,
        {
            headers: JsonContentType
        }
    )
    return res.content
}

/**
 * 创建行（记录）
 * @param {string} id
 * @param {string} dsId
 * @return {*}  {Promise<boolean>}
 */

export async function createRecord(payload: CreateRecordPayload): Promise<RecordLikeProtocol> {
    const { appId, envId, dsId, pageId } = payload
    const res = await http.post<unknown, HttpResp<RecordRes>>(addPageAuth(`${includeModulaUrl.ds}/createRecord`, pageId), payload, {
        headers: JsonContentType
    })
    return {
        appId,
        envId,
        dsId,
        ...res.content
    }
}

/**
 * 删除行（记录）
 * @param {string} dsId
 * @param {string[]} recordIds
 * @return {*}  {Promise<boolean>}
 */

export async function deleteRecord(
    appId: string,
    dsId: string,
    pageId: string,
    recordIds: string[],
    mode = 'CURRENT_PAGE'
): Promise<boolean> {
    // TODO: 临时解决连接表问题
    const rIds = [...new Set(recordIds.map(id => getMainTableRecordId(id)))]
    const res = await http.post<unknown, HttpResp>(
        addPageAuth(`${includeModulaUrl.ds}/deleteRecord`, pageId),
        { appId, id: dsId, recordIds: rIds, pageId, mode },
        {
            headers: JsonContentType
        }
    )
    return res.success
}

export async function deleteViewRecord(payload: DeleteViewRecordAtomPayload): Promise<boolean> {
    // TODO: 临时解决连接表问题
    const params = {
        ...payload,
        recordIds: [...new Set(payload.recordIds.map(id => getMainTableRecordId(id)))]
    }
    const res = await http.post<unknown, HttpResp>(addPageAuth(`${includeModulaUrl.ds}/deleteViewRecords`, payload.pageId), params, {
        headers: JsonContentType
    })
    return res.success
}

/**
 * 获取页面的blocks及nodes
 * @param {string} pageId
 * @return {*}  {Promise<boolean>}
 */
export async function getPageContent(pageId: string) {
    const res = await http.get<unknown, HttpResp<PageContentRes>>(addPageAuth(`${includeModulaUrl.page}/getBlocks`, pageId), {
        params: { id: pageId }
    })
    const { blockVos, ...rest } = res.content ?? {}
    const transformBlocks = blockVos?.map(item => {
        const { config, type, ...rest } = item
        return { ...rest, config: config[type] ?? {}, type }
    }) as BlockAbstract[]

    return {
        ...rest,
        code: res.code,
        success: res.success,
        blocks: transformBlocks
    }
}

/**
 * 获取指定的行数据
 * @param {RecordWithPageIdPayload} payload
 * @return {*}  {Promise<boolean>}
 */
export async function getRecord(payload: RecordWithPageIdPayload): Promise<PageRecordResult> {
    const { appId, envId, dsId, pageId, recordId } = payload
    const newParams = { dsId, recordId: getMainTableRecordId(recordId) }
    const res = await http.get<unknown, HttpResp<PageRecordRes>>(addPageAuth(`${includeModulaUrl.page}/getRecord`, pageId), {
        params: newParams
    })

    const { dataSource, record, ...rest } = res.content
    return {
        dataSource: {
            appId,
            envId,
            ...dataSource
        },
        record: {
            appId,
            envId,
            dsId,
            ...record
        },
        ...rest
    }
}

export async function submitFormPage(appId: string, dsId: string, data: FieldValues) {
    const fieldDTOS = Object.entries(data).reduce<CreateRecordFromFormData['fieldDTOS']>((prev, [k, v]) => {
        return [...prev, { fieldId: k, value: v }]
    }, [])

    const res = await http.post<unknown, HttpResp<string>>(
        `${includeModulaUrl.page}/saveForm`,
        { appId, dsId, fieldDTOS },
        {
            headers: JsonContentType
        }
    )
    return res.content
}

/**
 * ai 表格
 * ------------------------------ start
 */
export async function generateAiTable(params: GenerateAiTableParams): Promise<AnyObject> {
    const res = await http.get<unknown, HttpResp<AnyObject>>(addPageAuth(`${includeModulaUrl.page}/getAiTable`, params.pageId), { params })
    return res.content
}
/**
 * ai 表格
 * ------------------------------ end
 */

/** 提交表单视图 */
export async function submitForm(payload: SubmitFormPayload): Promise<FormResult> {
    const res = await http.post<unknown, HttpResp<SubmitFormRes>>(
        addPageAuth(`${includeModulaUrl.page}/submitForm`, payload.pageId),
        payload,
        {
            headers: JsonContentType
        }
    )
    const { appId, envId, dsId } = payload
    const { record, repeatFieldIds, failureMessage } = res.content
    return {
        record: record
            ? {
                  ...record,
                  appId,
                  envId,
                  dsId
              }
            : undefined,
        repeatFieldIds,
        failureMessage
    }
}

/**
 * 获取空间下数据源列表
 * @return {*}
 */
export async function listSpaceDs(): Promise<DataSourceAbstract[]> {
    const res = await http.get<unknown, HttpResp<DataSourceAbstract[]>>(`${includeModulaUrl.open}/listSpaceDs`)
    return res.content
}

/**
 * 获取应用下消息
 * @return {*}
 */
export async function listMessage(): Promise<MessageListDataRes> {
    const res = await http.get<unknown, HttpResp<MessageListDataRes>>(`${includeModulaUrl.open}/app/getMsg`, {
        params: { currentPage: 1, pageSize: 10000 }
    })
    return res.content
}

/**
 * 获取消息详情
 * @return {*}
 */
export async function getApproveDetail(msgId: string): Promise<MessageDetailDataRes> {
    const res = await http.get<unknown, HttpResp<MessageDetailDataRes>>(`${includeModulaUrl.open}/app/getDetail`, { params: { msgId } })
    return res.content
}

/**
 * 确认消息已读
 */
export async function resolveMessage(msgId: string): Promise<boolean> {
    const res = await http.get<unknown, HttpResp<boolean>>(`${includeModulaUrl.open}/app/resolveMessage`, { params: { msgId } })
    return res.success
}

/**
 * 审批通过
 * @return {*}
 */
export async function approveAccept(params: ApprovePayload): Promise<boolean> {
    // eslint-disable-next-line no-return-await
    return await Promise.resolve(true)
    // const res = await http.post<unknown, HttpResp<boolean>>(`${includeModulaUrl.approve}/accept`, params, { headers: JsonContentType })
    // return res.success
}

/**
 * 审批拒绝
 * @return {*}
 */
export async function approveReject(params: ApprovePayload): Promise<boolean> {
    // eslint-disable-next-line no-return-await
    return await Promise.resolve(true)
    // const res = await http.post<unknown, HttpResp<boolean>>(`${includeModulaUrl.approve}/reject`, params, { headers: JsonContentType })
    // return res.success
}

/** 获取工作流列表 */
export async function listFlowNodes(): Promise<Workflow[]> {
    const res = await http.get<unknown, HttpResp<Workflow[]>>(`${includeModulaUrl.open}/workflow/list`)
    return res.content
}

/**
 * 创建动作流执行日志
 * @param flowId
 * @returns
 */
export async function createWorkflowLog(workflowId: string, pageId: string) {
    console.log('🚀 ~ createWorkflowLog ~ pageId:', pageId)
    const res = await http.post<unknown, HttpResp<string>>(
        addPageAuth(`${includeModulaUrl.workflow}/createWorkflowLog`, pageId),
        { workflowId },
        { headers: JsonContentType }
    )
    return res.content
}

/**
 * 更新动作流执行日志
 */
export async function updateWorkflowLog(payload: UpdateWorkflowLogPayload & { pageId: string }): Promise<string> {
    const { pageId, ...restPayload } = payload
    const res = await http.post<unknown, HttpResp<string>>(
        addPageAuth(`${includeModulaUrl.workflow}/updateWorkflowLog`, pageId),
        restPayload,
        {
            headers: JsonContentType
        }
    )

    return res.content
}

/**
 * @description 动作流与动作中创建记录
 * @returns
 */
export async function createRecordWithFields(params: CreateRecordWithFieldsPayload & { pageId: string }): Promise<RecordLikeProtocol> {
    const { appId, envId, dsId, pageId } = params
    const res = await http.post<unknown, HttpResp<RecordRes>>(addPageAuth(`${includeModulaUrl.workflow}/createRecord`, pageId), params, {
        headers: JsonContentType
    })
    return {
        appId,
        envId,
        dsId,
        ...res.content
    }
}

/**
 * @description 动作流与动作中更新记录
 * @returns
 */
export async function updateRecordWithFields(payload: UpdateRecordWithFieldsPayload & { pageId: string }): Promise<RecordLikeProtocol> {
    const { appId, envId, dsId, recordId, pageId } = payload
    const params = {
        ...payload,
        recordId: getMainTableRecordId(recordId)
    }
    const res = await http.post<unknown, HttpResp<RecordRes>>(addPageAuth(`${includeModulaUrl.workflow}/updateRecord`, pageId), params, {
        headers: JsonContentType
    })
    return {
        appId,
        envId,
        dsId,
        ...res.content
    }
}

/**
 * @description 动作流查询单条记录
 * @returns
 */
export async function findSingleRecord(payload: ActiveFindSingleRecordActionPayload & { pageId: string }): Promise<RecordLikeProtocol> {
    const { appId, envId, dsId, pageId } = payload
    const res = await http.post<unknown, HttpResp<RecordRes>>(
        addPageAuth(`${includeModulaUrl.workflow}/activeFindSingleRecordAction`, pageId),
        payload,
        {
            headers: JsonContentType
        }
    )
    return {
        appId,
        envId,
        dsId,
        ...res.content
    }
}

/**
 *
 * @description 动作流与动作中发送微信消息
 * @returns
 */
export async function activeWxRobotAction(params: ActiveWxRobotActionPayload & { pageId: string }): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>(
        addPageAuth(`${includeModulaUrl.workflow}/activeWxRobotAction`, params.pageId),
        params,
        {
            headers: JsonContentType
        }
    )
    return res.success
}

/**
 *
 * @description 动作流与动作中发送钉钉消息
 * @returns
 */
export async function activeDingTalkRobotAction(params: ActiveDingTalkRobotActionPayload & { pageId: string }): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>(
        addPageAuth(`${includeModulaUrl.workflow}/activeDingTalkRobotAction`, params.pageId),
        params,
        {
            headers: JsonContentType
        }
    )
    return res.success
}

/**
 *
 * @description 动作流与动作中发送站内信消息
 * @returns
 */
export async function activeStationMessageAction(params: ActiveStationMessageActionPayload & { pageId: string }): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>(
        addPageAuth(`${includeModulaUrl.workflow}/activeStationMessageAction`, params.pageId),
        params,
        {
            headers: JsonContentType
        }
    )
    return res.success
}

/**
 *
 * @description 动作流与动作中发送阿里云短信
 * @returns
 */
export async function activeAliyunMessageAction(params: AliyunMessageActionPayload & { pageId: string }): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>(
        addPageAuth(`${includeModulaUrl.workflow}/activeAliyunMessageAction`, params.pageId),
        params,
        {
            headers: JsonContentType
        }
    )
    return res.success
}

/**
 *
 * @description 动作流与动作中发送邮件
 * @returns
 */
export async function activeSendEmailAction(params: ActiveSendEmailActionPayload & { pageId: string }): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>(
        addPageAuth(`${includeModulaUrl.workflow}/activeSendEmailAction`, params.pageId),
        params,
        {
            headers: JsonContentType
        }
    )
    return res.success
}

/**
 * @description 动作流与动作中添加用户到ICLICK
 * @returns
 */
export async function activeAddIClickUser(params: ActiveAddIClickUserActionPayload & { pageId: string }): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>(
        addPageAuth(`${includeModulaUrl.workflow}/addIClickUser`, params.pageId),
        params,
        {
            headers: JsonContentType
        }
    )
    return res.success
}

/**
 * @description 动作流与动作中触发自动化
 * @returns
 */
export async function triggerAutomationAction(params: TriggerAutomationActionPayload & { pageId: string }): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>(
        addPageAuth(`${includeModulaUrl.workflow}/triggerAutomationAction`, params.pageId),
        params
    )
    return res.success
}

/**
 * 通过追加方式导入 Excel 数据
 * @return {*}  {Promise<boolean>}
 */

export async function appendDataToDataSource(params: ViewAppendParams & { pageId: string }): Promise<boolean> {
    const formData = new FormData()
    const requestParams = Object.entries(params)
    for (const [requestParamKey, requestParamValue] of requestParams) {
        const serializedValue = requestParamKey === 'sheetDto' ? JSON.stringify(requestParamValue) : (requestParamValue as File | string)

        formData.append(requestParamKey, serializedValue)
    }
    const res = await http.post<unknown, HttpResp<Pick<DataSourceProtocol, 'id' | 'name'>>>(
        addPageAuth(`${includeModulaUrl.ds}/importInAppendMode`, params.pageId),
        formData,
        {
            headers: formContentType
        }
    )
    return res.success
}

/**

 * 获取视图打印模板列表
 * @param dsId
 * @returns
 */
export async function getPrintTemplateList(dsId: string, pageId: string) {
    const res = await http.get<unknown, HttpResp<{ templateId: string; name: string; dsId: string; url: string }[]>>(
        addPageAuth(`${includeModulaUrl.ds}/getTemplateList`, pageId),
        {
            params: {
                dsId
            }
        }
    )
    return res.content
}

/**
 * 按模板导出/打印视图
 * @param params
 * @returns
 */
export async function printByTemplate(params: ViewPrintWithTemplateParams & { pageId: string }) {
    // eslint-disable-next-line no-return-await
    return await http.post<unknown, AxiosResponse>(addPageAuth(`${includeModulaUrl.ds}/exportTemplate`, params.pageId), params, {
        headers: JsonContentType,
        responseType: 'blob'
    })
}

/**
 * 按模板导出/打印视图
 * @param params
 * @returns
 */
export async function exportView(params: ViewExportParams, pageId: string) {
    // eslint-disable-next-line no-return-await
    return await http.post<unknown, AxiosResponse>(addPageAuth(`${includeModulaUrl.ds}/exportView`, pageId), params, {
        headers: JsonContentType,
        responseType: 'blob'
    })
}

/**
 * 图表导出数据
 * @param params
 * @returns
 */
export async function exportChart(params: ChartExportParams, pageId: string) {
    // eslint-disable-next-line no-return-await
    return await http.post<unknown, AxiosResponse>(addPageAuth(`${includeModulaUrl.ds}/exportDsGraphView`, pageId), params, {
        headers: JsonContentType,
        responseType: 'blob'
    })
}

/**
 * 导出数据源
 * @param params
 * @returns
 */
export async function dsExport(params: DsExportParams) {
    // eslint-disable-next-line no-return-await
    return await http.post<unknown, AxiosResponse>(`${includeModulaUrl.ds}/export`, params, {
        headers: JsonContentType,
        responseType: 'blob'
    })
}

/**
 * 获取用户记录数据
 * @param params
 * @returns
 */
export async function getUserRecord(appId: string, envId: string): Promise<UserDataSourceResult> {
    const res = await http.get<unknown, HttpResp<UserDataSourceRes>>(`${includeModulaUrl.ds}/getUserRecord`)
    const { datasource, record } = res.content
    // return res.content
    return {
        datasource: datasource ? { appId, envId, ...datasource } : datasource,
        record: record ? { appId, envId, dsId: datasource?.id || '', ...record } : record
    }
}

/*
 *
 * wechat 相关接口
 */

export async function getWechatSignature(url: string): Promise<WechatSignature> {
    const res = await http.get<unknown, HttpResp<WechatSignature>>(`${includeModulaUrl.open}/getSignature`, {
        params: { url }
    })
    return res.content
}

export async function getWechatShareData(params: WechatShareDataParams): Promise<WechatShareResult> {
    const res = await http.get<unknown, HttpResp<WechatShareResult>>(
        addPageAuth(`${includeModulaUrl.page}/getPageOutline`, params.pageId),
        {
            params
        }
    )
    return res.content
}
/**
 * 生成选项
 * @param params
 * @returns
 *
 */
export async function getRelativeOptions(params: {
    dsId: string
    fieldId: string
    filter?: FilterFormType
    disabledFilter?: FilterFormType
    sorts?: Sorter[]
    currentRecordId?: string
    parentRecordId?: string
    showFieldId: string
    pageId: string
}) {
    const res = await http.post<unknown, HttpResp<RelativeOption[]>>(
        addPageAuth(`${includeModulaUrl.ds}/getSelectByField`, params.pageId),
        params,
        {
            headers: JsonContentType
        }
    )
    return res.content
}

/**
 * 获取第三方应用集成的所有应用账户
 * @return {*}
 */
export async function getOtherPlatform(id: string): Promise<OtherPlatform> {
    const res = await http.get<unknown, HttpResp<OtherPlatform>>(`${includeModulaUrl.open}/integration/getIntegration`, {
        params: {
            id
        }
    })
    return res.content
}

/**
 * 发送微信权限获得的code
 * @return {*}
 */
export async function sendWxOfficialRootCode(params: { code: string; accountType: string }): Promise<boolean> {
    const res = await http.get<unknown, HttpResp<boolean>>(`${includeModulaUrl.open}/integration/wechat/official/code`, {
        params
    })
    return res.success
}
/**
 * 检验当前应用集成的微信集成是否获得微信的权限
 * @return {*}
 */
export async function checkWxOfficialRoot(): Promise<boolean> {
    const res = await http.get<unknown, HttpResp<boolean>>(`${includeModulaUrl.open}/integration/wechat/official/isAuthorized`)
    return res.content
}

// /**
//  * 获取block预设
//  */
// export async function getBlockPresets(pageId: string) {
//     const res = await http.get<unknown, HttpResp<TextAdvanceOption[]>>(addPageAuth(`${includeModulaUrl.page}/blockPresetList`, pageId))
//     return res.content
// }
/**
 * 执行微信支付
 * @return {*}
 */
export async function invokeWechatPay(params: WechatPayPayload): Promise<InvokeWechatPayResult> {
    const newParams = { ...params, recordId: getMainTableRecordId(params.recordId) }
    const res = await http.post<unknown, HttpResp<InvokeWechatPayResult>>(
        addPageAuth(`${includeModulaUrl.workflow}/activeWechatPay`, params.pageId),
        newParams,
        {
            headers: JsonContentType
        }
    )
    return res.content
}

/**
 * 执行微信退款
 * @return {*}
 */
export async function activeWechatRefund(params: WechatRefoundPayload): Promise<boolean> {
    const newParams = { ...params, recordId: getMainTableRecordId(params.recordId) }
    const res = await http.post<unknown, HttpResp<boolean>>(
        addPageAuth(`${includeModulaUrl.workflow}/activeWechatRefund`, params.pageId),
        newParams,
        {
            headers: JsonContentType
        }
    )
    return res.success
}

/**
 * 查询支付状态
 * @return {*}
 */
export async function getWechatPayStatus(params: { outTradeNo: string; pageId: string }): Promise<WechatPayStatus> {
    const res = await http.get<unknown, HttpResp<WechatPayStatus>>(
        addPageAuth(`${includeModulaUrl.workflow}/getWechatPayStatus`, params.pageId),
        {
            params
        }
    )
    return res.content
}

/**
 * 获取阿里云视频播放凭证
 */
export async function getVideoPlayAuth(videoId: string) {
    const res = await http.get<unknown, HttpResp<{ playAuth: string }>>(`${includeModulaUrl.oss}/getVideoPlayAuth`, {
        params: {
            videoId
        }
    })
    return res.content?.playAuth
}

/**
 *  合并用户账号
 */
export async function combinedAccount(params: AccountMergeAccount) {
    const formData = new FormData()
    const requestParams = Object.entries(params)
    for (const [requestParamKey, requestParamValue] of requestParams) {
        const serializedValue = requestParamKey === 'sheetDto' ? JSON.stringify(requestParamValue) : (requestParamValue as File | string)

        formData.append(requestParamKey, serializedValue)
    }
    const res = await http.post<unknown, HttpResp>(`${includeModulaUrl.user}/combinedAccount`, formData, {
        headers: formContentType
    })
    return res.success
}

// /**
//  * 获取 manifest
//  */
// export async function getManifest(params: PWAParams) {
//     const res = await http.get<unknown, AnyObject>(`${includeModulaUrl.open}/pwa/manifest.json?startUrl=${params.startUrl}`)
//     return res.data
// }

/**
 * 获取输入框默认值
 * @param params
 * @returns
 */
export async function getRecordByFilter(payload: GetRecordByFilterPayload): Promise<RecordLikeProtocol> {
    const { appId, envId, dsId, pageId } = payload
    const res = await http.post<unknown, HttpResp<RecordRes>>(addPageAuth(`${includeModulaUrl.ds}/getRecordByFilter`, pageId), payload, {
        headers: JsonContentType
    })
    return {
        appId,
        envId,
        dsId,
        ...res.content
    }
}

/**
 *
 * @description 微信模版消息
 * @returns
 */
export async function wxTemplateMessageAction(params: WxTemplateMessageActionPayload & { pageId: string }): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>(
        addPageAuth(`${includeModulaUrl.workflow}/wxTemplateMessageAction`, params.pageId),
        params,
        {
            headers: JsonContentType
        }
    )
    return res.success
}

/**
 * 获取验证码
 * @return {*}
 */
export async function getVerifyCode(params: { mobile: string; blockId: string; pageId: string }): Promise<boolean> {
    const res = await http.get<unknown, HttpResp<boolean>>(addPageAuth(`${includeModulaUrl.open}/sms/getFormVerifyCode`, params.pageId), {
        params
    })
    return res.success
}

/**
 * stripe 获取支付链接
 * @return {*}
 */
export async function activeStripePay(params: StripePayPayload): Promise<StripePayResult> {
    const newParams = { ...params, recordId: getMainTableRecordId(params.recordId), payType: 'STRIPE_CHECKOUT' }
    const res = await http.post<unknown, HttpResp<StripePayResult>>(
        addPageAuth(`${includeModulaUrl.action}/activeStripePay`, params.pageId),
        newParams,
        {
            headers: JsonContentType
        }
    )
    return res.content
}

/**
 * 检查 stripe 订单支付状态
 * @return {*}
 */
export async function getStripePayStatus(params: { outTradeNo: string; pageId: string }): Promise<StripePayStatus> {
    const res = await http.get<unknown, HttpResp<StripePayStatus>>(
        addPageAuth(`${includeModulaUrl.action}/getStripePayStatus`, params.pageId),
        {
            params
        }
    )
    return res.content
}

/**
 * 获取微信登陆二维码
 * @return {*}
 */
export async function getWechatLoginQr() {
    const res = await http.get<unknown, HttpResp<{ url: string; mark: string }>>(`${includeModulaUrl.open}/getLoginQr`)
    return res.content
}

/**
 * 检查微信是否扫码登陆完成
 * @return {*}
 */
export async function checkWechatScanner(mark: string) {
    const res = await http.get<unknown, HttpResp<ScanStatus>>(`${includeModulaUrl.open}/poll`, {
        params: {
            mark
        }
    })
    return res.content
}

/**
 * 用户绑定账号
 * @return {*}
 */
export async function bindAccount(params: AccountBindAccountForm) {
    const res = await http.put<unknown, HttpResp<BindAccountStatus>>(`${includeModulaUrl.user}/bindAccount`, params)
    return res.content
}

/**
 * 用户接触绑定账号
 * @return {*}
 */
export async function removeBindAccount(type: LoginAuthType, account?: string, code?: string) {
    const res = await http.put<unknown, HttpResp<boolean>>(`${includeModulaUrl.user}/removeBindAccount`, {
        type,
        account,
        code
    })
    return res.success
}
