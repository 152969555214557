import type { EmptyStylesNames } from '@byecode/ui'
import { Button, Empty, flex, IconFont } from '@byecode/ui'
import type { StyleComponentProps } from '@byecode/ui/theme/types'
import { getAssetUrl } from '@lighthouse/assets'
import * as React from 'react'
import styled from 'styled-components'

const SCxContainer = styled.div`
    width: 100%;
    flex: 1;
    height: 100%;
    background-color: var(--color-gray-50);
    overflow-x: hidden;
    /* padding: 60px;
    padding-bottom: 200px; */
    ${flex}
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const SCxAppNotFound = styled.div`
    font-size: 24px;
    color: var(--color-gray-400);
    margin-top: 20px;
`

const SCxCreatePageBtn = styled(Button)`
    /* width: 300px; */
    width: 142px;
`

const ScxExtraEmptyTip = styled.div`
    margin-bottom: 8px;
`

interface NoPageProps extends StyleComponentProps<EmptyStylesNames> {
    image?: string
    title?: string
    icon?: string
    desc?: string
    btnText?: string
    imgWidth?: number
    onBtnClick?: () => void
}

export const NoFound: React.FC<NoPageProps> = ({
    image,
    title = '找不到应用',
    icon = '404',
    desc = '应用无法访问，请联系管理员',
    btnText = '免费注册',
    imgWidth = 134,
    styles,
    onBtnClick = () => {
        window.location.href = 'https://www.byecode.com'
    }
}) => {
    return (
        <SCxContainer>
            <Empty
                // icon={image ? <img width={imgWidth} src={image} alt="" /> : <IconFont type={icon} size={300} color="#DCDDDD" />}
                icon={<img width={imgWidth} src={getAssetUrl('common', 'byecode-logo.png')} alt="" />}
                title={title}
                description={
                    <div>
                        <ScxExtraEmptyTip>此应用由ByeCode搭建--最简单的无代码平台</ScxExtraEmptyTip>
                        <ScxExtraEmptyTip>注册ByeCode，发布属于您自己的应用</ScxExtraEmptyTip>
                    </div>
                }
                styles={{
                    root: {
                        height: 'auto',
                        flex: 0,
                        marginBottom: 20
                    },
                    title: {
                        fontSize: 24,
                        color: '$colorGray900',
                        lineHeight: '32px',
                        margin: '24px 0',
                        fontWeight: 600
                    },
                    ...styles
                }}
            />
            {onBtnClick && (
                <SCxCreatePageBtn /* block */ type="primary" size="lg" onClick={() => onBtnClick?.()}>
                    {btnText}
                </SCxCreatePageBtn>
            )}
        </SCxContainer>
    )
}
