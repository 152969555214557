import { Toast } from '@byecode/ui'
import type { FieldBlockAbstract, FieldCellValue, FieldInputADTValue } from '@lighthouse/core'
import { getEmptyFieldInputValue, isEmptyFieldInputValue, pageStackPubSub, useAtomAction } from '@lighthouse/shared'
import { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { updateCellAtom } from '@/atoms/dataSource/action'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'

import { useCurrentAppId, useCurrentEnvId } from './useApplication'
import { useDataSource, useRecord } from './useDataSource'
import { usePageDataSourceForVariableSelector } from './usePage'

export interface IUsePageCurrentDsFirstRecord {
    blockId: string
    appId: string
    pointer: string
    fieldPointer: string
    disabled?: boolean
}

export interface IUsePageCurrentDsAndRecord {
    blockData: FieldBlockAbstract
    pointer: string
    disabled?: boolean
}

export const usePageCurrentDsFirstRecord = (
    { blockId, appId, pointer, fieldPointer, disabled }: IUsePageCurrentDsFirstRecord,
    isMatchUser?: boolean
) => {
    const { pageId } = useCurrentPageContext()
    const envId = useCurrentEnvId()
    const stackId = useCurrentStackIdContext()

    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const pageDepsDs = curr.datasource ?? prev.datasource
    const pageDepsRecordId = curr.recordId ?? prev.recordId

    const currentPageRecord = useRecord(appId, envId, pageDepsDs?.id || '', pageDepsRecordId || '')

    const [firstRecordId, setFirstRecordId] = useState<string>()

    const currentBlockRecord = useRecord(appId, envId, pointer, pageDepsRecordId ?? firstRecordId ?? '')

    const [initRecord, setInitRecord] = useState(currentPageRecord)

    const isNotConfigure = !pointer

    return {
        dataSource: pageDepsDs,
        record: currentBlockRecord,
        pageRecord: currentPageRecord,
        initRecord
    }
}

export const usePageCurrentDsAndRecordByBlock = ({ blockData, pointer, disabled }: IUsePageCurrentDsAndRecord) => {
    const { id: blockId, config } = blockData
    const { fieldPointer = '', required, inputType } = config
    const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const dataSource = useDataSource(appId, envId, pointer)
    const { pageId } = useCurrentPageContext()

    const { run: updateCell } = useAtomAction(updateCellAtom)

    const {
        dataSource: currentDataSource,
        record: currentDataRecord,
        pageRecord,
        initRecord: currentInitRecord
    } = usePageCurrentDsFirstRecord({ blockId, appId, pointer, fieldPointer, disabled })
    const currentPageRecordId = currentDataRecord?.id
    const currentPageDataSourceId = currentDataSource?.id
    const currentPageRecord = useRecord(appId, envId, pointer, currentPageRecordId ?? '')
    const isNotConfigure = !pointer || !fieldPointer

    const field = currentDataSource?.schema?.[fieldPointer]

    const fieldContent: FieldCellValue | undefined = useMemo(() => {
        if (isNotConfigure) {
            return
        }
        if (pointer !== currentPageDataSourceId) {
            return currentDataRecord?.content?.[fieldPointer]?.value
        }
        // 如果匹配当前用户这一逻辑不生效，则尝试匹配视图记录页跳转携带过来的数据源和行数据
        if (!currentPageRecord) {
            return
        }
        return currentPageRecord.content?.[fieldPointer]?.value
    }, [currentDataRecord?.content, currentPageDataSourceId, currentPageRecord, fieldPointer, isNotConfigure, pointer])

    const initCellValue = useMemo(
        () => fieldContent ?? getEmptyFieldInputValue(inputType, dataSource?.schema?.[fieldPointer]?.type),
        [dataSource?.schema, fieldContent, fieldPointer, inputType]
    )

    const [value, setValue] = useState<FieldCellValue>(
        fieldContent ?? getEmptyFieldInputValue(inputType, dataSource?.schema?.[fieldPointer]?.type)
    )

    useUpdateEffect(() => {
        setValue(initCellValue)
    }, [currentPageRecord?.id])

    const handleValueChange = useCallback(
        async (fieldValue: FieldInputADTValue) => {
            const { value } = fieldValue
            // 如果是匹配当前用户，这里直接 return
            if (!fieldPointer || !currentPageRecordId) {
                return
            }

            const isUpdateWillNotPass = required && isEmptyFieldInputValue(fieldValue, field?.type)

            // 如果为空，且是必填项，进行错误提示
            if (isUpdateWillNotPass) {
                Toast.error('必填项不能为空~')
            }

            // 如果匹配当前用户，那么当前数据表和行数据都是匹配到的，直接更新即可
            const recordId = currentDataRecord?.id ?? ''
            const dsId = currentDataSource?.id ?? ''

            await updateCell(
                {
                    recordId,
                    dsId,
                    appId,
                    pageId,
                    fieldId: fieldPointer,
                    value: { value }
                },
                { local: isUpdateWillNotPass }
            )
            // 创建行以后，需要通知对应订阅更新数据
            pageStackPubSub.emit(`${dsId}-ADD`)
        },
        [appId, currentDataRecord?.id, currentDataSource?.id, currentPageRecordId, field?.type, fieldPointer, pageId, required, updateCell]
    )

    return {
        currentPageDataSource: currentDataSource,
        currentBlockRecord: currentDataRecord,
        currentPageRecord: pageRecord,
        fieldContent: fieldContent ?? value,
        initRecord: currentInitRecord,
        currentValue: value,
        handleValueChange,
        setValue
    }
}
