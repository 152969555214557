import type { Batch } from '@rpldy/uploady'
import { FILE_STATES } from '@rpldy/uploady'
import { useEffect, useRef } from 'react'

import { useListenersContext } from '../UploadManage.context'

export const useUploadBatchUploading = (id: string, func: (batch: Batch) => void) => {
    const { batchListener } = useListenersContext()

    const latestFunc = useRef<(data: Batch) => void>(func)
    latestFunc.current = func

    useEffect(() => {
        batchListener.add(latestFunc.current, id, FILE_STATES.UPLOADING)

        return () => {
            batchListener.remove(id)
        }
    }, [id, batchListener])
}
