import type { AppUser, DataSourceAbstract, FieldADTValue, RecordLikeProtocol, SchemaProtocol } from '@lighthouse/core'
import { find } from 'rambda'

import { fieldConvertValue } from '../../../../utils'

interface getConvertValueParams {
    recordId: string
    fieldId: string
    records: RecordLikeProtocol[]
    schema: SchemaProtocol['schema']
    personOptions: AppUser[]
    isReallyNumber?: boolean
}

export function getConvertValue(params: getConvertValueParams) {
    const { recordId, fieldId, records, schema, personOptions, isReallyNumber = true } = params
    const record = find(item => item.id === recordId, records)
    if (!record || !schema) {
        return null
    }
    const value = record?.content?.[fieldId]?.value || ''
    const fieldValue = {
        ...schema[fieldId],
        value
    } as FieldADTValue
    return fieldConvertValue(fieldValue, { personOptions, isReallyNumber })
}
