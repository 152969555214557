import type {
    BlockAbstract,
    DataSourceAbstract,
    FieldBlockAbstract,
    FieldInputADTValue,
    FieldInputValue,
    FieldType,
    InputValueItem,
    PageNode,
    RecordLikeProtocol
} from '@lighthouse/core'
import type { FieldInputErrors } from '@lighthouse/shared'
import {
    CURRENT_USER,
    getEmptyFieldInputValue,
    getFieldInputError,
    getFieldInputRules,
    isEmptyFieldInputValue,
    isTextValue
} from '@lighthouse/shared'
import { find, flatten, reduce } from 'rambda'

import type { SubFormRecord } from '../../blocks'
import type { FieldBlockInfo } from './types'

export const getDiffInputValueList = function (longList: InputValueItem[], shortList: InputValueItem[]): InputValueItem[] {
    return reduce<InputValueItem, InputValueItem[]>(
        (preVal, curVal) => {
            const { type, id } = curVal
            const isExit = find(item => id === item.id, shortList)
            if (!isExit) {
                return [...preVal, { value: getEmptyFieldInputValue(type), type, id } as InputValueItem]
            }
            return preVal
        },
        [],
        longList
    )
}

/**
 * 转换携带过来的初始值给字段block
 * @param type
 * @param value
 * @returns
 */
export const transformInitialValue = (type: FieldType, value: string | number): FieldInputValue => {
    switch (type) {
        case 'select': {
            return [value]
        }

        case 'date': {
            return value
        }

        default: {
            return value
        }
    }
}

const getFieldBLockInfo = (block: FieldBlockAbstract) => {
    const { config, id, title } = block
    const { fieldPointer = '', inputType, initialValue } = config
    return { id, fieldId: fieldPointer, inputType, initialValue, config: { ...config, title } } as FieldBlockInfo
}

export const getFieldBlockInfo = ({ blocks, node }: { blocks: BlockAbstract[]; node: PageNode }): FieldBlockInfo[] => {
    const list: FieldBlockInfo[] = []
    const { id, children: childrenNodes = [] } = node

    function getChildNode({ blocks, nodes, list }: { blocks: BlockAbstract[]; nodes: PageNode[]; list: FieldBlockInfo[] }) {
        for (const node of nodes) {
            const { id, children: childrenNodes = [] } = node
            const block = find(item => item.id === id, blocks)
            if (block?.type === 'formContainer') {
                return list
            }
            if (block?.type === 'field') {
                list.push(getFieldBLockInfo(block))
            }
            getChildNode({ nodes: childrenNodes, blocks, list })
        }
        return list
    }
    return getChildNode({ nodes: childrenNodes, blocks, list })
}

export const getFocusErrorBlockId = function (params: {
    errors: FieldInputErrors
    schema: DataSourceAbstract['schema']
    inputList: InputValueItem[]
}) {
    const { inputList, errors, schema } = params
    return reduce<InputValueItem, string | null>(
        (preVal, curVal, index) => {
            const field = schema?.[curVal.fieldId]
            const isEmpty = isEmptyFieldInputValue(curVal, field?.type)
            return preVal ?? (isEmpty || errors[curVal.id]?.repeat ? curVal.id : preVal)
        },
        null,
        inputList
    )
}

export const getFormErrors = function (params: {
    configs: FieldBlockInfo[]
    value: InputValueItem[]
    schema: DataSourceAbstract['schema']
    repeatList?: string[]
}): FieldInputErrors {
    const { configs, value, schema, repeatList } = params
    // [isRequiredEmpty, isAllEmpty]
    const errors = reduce<InputValueItem, FieldInputErrors>(
        (preVal, curVal, index) => {
            const blockConfig = find(item => item.id === curVal.id, configs)
            const field = schema?.[curVal.fieldId]
            if (!field || !blockConfig) {
                return preVal
            }
            const isRepeat = repeatList?.includes(blockConfig?.fieldId ?? '')
            const error = getFieldInputError({
                value: { value: curVal.value, type: blockConfig.inputType } as FieldInputADTValue,
                rule: getFieldInputRules({
                    config: blockConfig?.config,
                    title: blockConfig.config.title ?? '',
                    custom: [
                        'repeat',
                        {
                            label: `${blockConfig.config.title}不可重复`,
                            value: blockConfig?.config.noRepeat ?? false
                        }
                    ]
                }),
                fieldType: field.type,
                isRepeat
            })
            const errorNum = Object.keys(error).length
            if (errorNum > 0) {
                preVal[curVal.id] = error
            }
            return preVal
        },
        {},
        value
    )
    const errorsNum = Object.keys(errors).length
    if (errorsNum === 0) {
        const isAllEmpty = reduce<InputValueItem, boolean>(
            (preVal, curVal) => {
                const field = schema?.[curVal.fieldId]
                const isEmpty = isEmptyFieldInputValue(curVal, field?.type)
                return Boolean(isEmpty && preVal)
            },
            false,
            value
        )
        const errors: FieldInputErrors = {
            [`${value[0].id}`]: {
                required: {
                    message: '不能为空',
                    type: 'required'
                }
            }
        }
        return isAllEmpty ? errors : {}
    }

    return errors
}

export const toErrorFocusBlockId = function (params: {
    errors: FieldInputErrors
    inputList: InputValueItem[]
    schema: DataSourceAbstract['schema']
}) {
    const { errors, inputList, schema } = params
    const focusBlockId = getFocusErrorBlockId({ inputList, errors, schema })
    const focusEle = document.querySelector(`#node-${focusBlockId}`)
    focusEle && focusEle.scrollIntoView(true)
}

export const getSaveFieldInputList = function (inputList: InputValueItem[], userRecord?: RecordLikeProtocol) {
    return inputList.map(item => {
        if (item.type === 'relativeSelect' && typeof item.value === 'string' && item.value.includes(CURRENT_USER.userId)) {
            const userValue = reduce<string, string[]>(
                (preVaL, label) => {
                    if (label.includes(CURRENT_USER.userId)) {
                        const userFieldPointer = label.replace(`${CURRENT_USER.userId}-`, '')
                        if (!userRecord || !userFieldPointer) {
                            return preVaL
                        }
                        const userValue = userRecord.content[userFieldPointer].value
                        return [...preVaL, isTextValue(userValue) ? userValue : '']
                    }
                    return [...preVaL, label]
                },
                [],
                item.value.split(',')
            )
            return { ...item, value: userValue.join(',') }
        }
        return item
    })
}

export function getPageNode(nodes: PageNode[], id: string): PageNode | undefined {
    for (const node of nodes) {
        if (node.id === id) {
            return node
        }
        const childNode = getPageNode(node.children ?? [], id)
        if (childNode) {
            return childNode
        }
    }
}

export function getFormOfFieldCodeValidator(list: InputValueItem[], blocks: BlockAbstract[]) {
    return reduce<InputValueItem, Record<string, string>>(
        (obj, cur) => {
            const { value, id, fieldId, code = '', fieldType } = cur
            const block = find(block => block.id === id, blocks)
            if (block?.type === 'field' && block.config.inputType === 'phoneNumber') {
                if (!block.config.phoneNumber?.isOpened) {
                    return obj
                }
                if (fieldType === 'phoneNumber' && typeof value === 'string' && value) {
                    obj[value] = code
                }
            }

            return obj
        },
        {},
        list
    )
}

export function getFormOfSubFormCodeValidator(list: SubFormRecord[], blocks: BlockAbstract[]) {
    return reduce<SubFormRecord, Record<string, string>>(
        (obj, cur) => {
            const { id, content } = cur
            Object.entries(content).forEach(([columnId, fieldInputData]) => {
                const { id, value, fieldType, code } = fieldInputData
                const block = find(block => block.id === id, blocks)
                if (block?.type === 'subForm') {
                    const column = find(v => v.id === columnId, block.config.columns ?? [])
                    if (column && column.config.inputType === 'phoneNumber') {
                        if (!column.config.phoneNumber?.isOpened) {
                            return
                        }
                        if (fieldType === 'phoneNumber' && typeof value === 'string' && value && code) {
                            obj[value] = code
                        }
                    }
                }
            })
            return obj
        },
        {},
        list
    )
}
