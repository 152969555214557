import type {
    AiFieldIdentity,
    AiFieldStatus,
    DataSourceAbstract,
    DataSourceBase,
    DataSourceIdentity,
    RecordIdentity,
    RecordLikeProtocol
} from '@lighthouse/core'
import { getMainTableRecordId, USER_DATASOURCE } from '@lighthouse/shared'
import type { Draft } from 'immer'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { atomWithImmer } from 'jotai-immer'
import { find } from 'rambda'
import shallowEqual from 'shallowequal'

import { userDataAtom } from '../auth/state'
import { applyDraftPayload } from '../utils/applyDraftPayload'

export const dataSourceListIdsAtom = atomWithImmer<DataSourceBase[]>([])
export const dataSourcePoolAtom = atomWithImmer<DataSourceAbstract[]>([])
export const recordPoolAtom = atomWithImmer<RecordLikeProtocol[]>([])
export const currentDataSourceIdAtom = atom('')
export const aiFieldStatusListAtom = atomWithImmer<AiFieldStatus[]>([])

export const dataSourceBaseAtomFamily = atomFamily((dsId: string) => {
    return atom(
        get => get(dataSourceListIdsAtom).find(item => item.id === dsId),
        (_, set, payload: DataSourceBase | ((draft: Draft<DataSourceBase>) => void)) => {
            set(dataSourceListIdsAtom, draft => {
                const dataSourceBase = draft.find(item => item.id === dsId)
                if (!dataSourceBase) {
                    return
                }
                applyDraftPayload(dataSourceBase, payload)
            })
        }
    )
})

export const dataSourceAtomFamily = atomFamily((params: DataSourceIdentity) => {
    const { appId, envId, dsId } = params
    return atom(
        get => get(dataSourcePoolAtom).find(item => item.appId === appId && item.id === dsId && item.envId === envId),
        (_, set, payload: DataSourceAbstract | undefined | ((draft: Draft<DataSourceAbstract | undefined>) => void)) =>
            set(dataSourcePoolAtom, draft => {
                const dataSource = draft.find(item => item.id === dsId && item.appId === appId)
                applyDraftPayload(dataSource, payload)
            })
    )
}, shallowEqual)

export const recordAtomFamily = atomFamily((params: RecordIdentity) => {
    const { appId, envId, recordId, dsId } = params
    return atom(
        get => get(recordPoolAtom).find(item => item.appId === appId && item.envId === envId && item.dsId === dsId && item.id === recordId),
        (_, set, payload: RecordLikeProtocol | ((draft: Draft<RecordLikeProtocol>) => void)) =>
            // set(recordPoolAtom, draft => {
            //     const record = draft.find(item => item.appId === appId && item.id === recordId && item.dsId === dsId)
            //     if (!record) {
            //         return
            //     }
            //     applyDraftPayload(record, payload)
            // })
            // TODO: 临时解决连接表问题
            set(recordPoolAtom, draft => {
                const recordsIndex = draft.reduce<number[]>((prev, cur, index) => {
                    if (cur.appId === appId && cur.dsId === dsId && getMainTableRecordId(cur.id) === getMainTableRecordId(recordId)) {
                        prev.push(index)
                    }
                    return prev
                }, [])
                if (recordsIndex.length === 0) {
                    return
                }
                recordsIndex.forEach(i => {
                    applyDraftPayload(draft[i], payload)
                })
            })
    )
}, shallowEqual)

// export const aiFieldStatusAtomFamily = atomFamily((params: AiFieldIdentity) => {
//     const { dataSourceId, fieldId, recordId } = params
//     return atom(
//         get =>
//             get(aiFieldStatusListAtom).find(item => item.dataSourceId === dataSourceId && item.id === recordId && item.fieldId === fieldId),
//         (_, set, payload: AiFieldStatus | ((draft: Draft<AiFieldStatus>) => void)) =>
//             set(aiFieldStatusListAtom, draft => {
//                 const record = draft.find(item => item.dataSourceId === dataSourceId && item.id === recordId && item.fieldId === fieldId)
//                 if (!record) {
//                     return
//                 }
//                 applyDraftPayload(record, payload)
//             })
//     )
// }, shallowEqual)
