import type { DataSourceAbstract } from '../abstracts'
import type { ApplicationId } from './application'
import type { BlockLikeProtocol } from './blocks'
import type { SchemaProtocol } from './field'
import type { IconicProtocol } from './general'
import type { RecordLikeProtocol } from './record'
import type { DataViewProtocol } from './view'

export type DataSourceId = string

export type DataSourceBase = Pick<DataSourceAbstract, 'id' | 'envId' | 'name' | 'type' | 'appId' | 'appName' | 'sync' | 'schema' | 'viewOptions'>

export type ApplicationDataSourceDatum = Record<ApplicationId, DataSourceAbstract[]>
export interface DataSourceIdentity {
    appId: string
    envId: string
    dsId: string
}

// export interface PageDataSourceIdentity {
//     appId: string
//     dsId: string
// }


/**
 * 数据指针类型
 */
export type PointerType = 'dataSource' | 'query'

/**
 * 数据源类型   2: 用户数据源   5: 连接表 6: 数据源 7: 聚合数据源
 */
export enum DataSourceType {
    'userDataSource' = 2,
    'joinDataSource' = 5,
    'dataSource' = 6,
    'aggregateDataSource' = 7
}

/**
 * 同步数据源
 */
export type DataSourceSynchronized = boolean
/**
 * 数据指针协议
 */
export interface DataPointerProtocol {
    pointer: string
    appId: string
}

export interface DataSourceLikeProtocol {
    primaryField: string
    records: RecordLikeProtocol[]
    // ...
}

export type DataSourceProtocol = IconicProtocol & DataSourceLikeProtocol & BlockLikeProtocol & DataViewProtocol & SchemaProtocol
