import {
    type ActionsProtocol,
    type AiFieldStatus,
    type ButtonAction,
    type Field,
    type HighLightConditions,
    type RecordLikeProtocol,
    type RichTextContentProtocol,
    type SchemaProtocol,
    type TableViewOptions,
    type ViewField
} from '@lighthouse/core'
import { Divider } from '@mantine/core'
import type { atomWithImmer } from 'jotai-immer'
import type React from 'react'
import styled, { css } from 'styled-components'

export const SCxListItemsWrapper = styled.div<{ direction?: React.CSSProperties['flexDirection'] }>`
    position: relative;
    display: flex;

    ${props =>
        props.direction === 'column'
            ? css`
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
              `
            : css`
                  width: calc(100% - 40px);
                  justify-content: flex-start;
                  align-items: center;
              `}
`

export const SCxListAvatarWrapper = styled.div<{ margin: number }>`
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: ${({ margin }) => `${margin}px`};
`

export const SCxListRecord = styled.div``

export const SCxListRecordContainer = styled.div<{ openable?: boolean; hoverColor: string; background?: string }>`
    position: relative;
    display: flex;
    border-radius: 8px;
    background: ${({ background }) => background || 'transparent'};
    padding: 12px 8px 12px 12px;

    &.record-active {
        background-color: ${({ hoverColor }) => hoverColor};
    }

    ${props =>
        props.openable &&
        css`
            cursor: pointer;
            &:hover {
                background-color: ${props.hoverColor};
            }

            &:active {
                background-color: ${props.hoverColor};
            }
        `}
`

export const SCxListRecordContent = styled.div<{ direction?: React.CSSProperties['flexDirection'] }>`
    display: flex;
    flex: 1;
    overflow: hidden;
    flex-direction: ${({ direction }) => direction || 'row'};
    ${props =>
        props.direction === 'column' &&
        css`
            justify-content: center;
        `}
`

export const SCxListMainItemWrapper = styled.div<{ padding: number; isFullWidth?: boolean }>`
    width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '240px')};
    padding: ${({ padding }) => `${padding}px 0`};
`

export const SCxListItemWrapper = styled.div<{ isFullWidth?: boolean }>`
    width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '240px')};
    font-weight: 600;
    margin-bottom: 4px;
`

export const SCxListPrimaryItemWrapper = styled.div`
    padding: 2px 0;
`

export const SCxListField = styled.div<{
    direction?: React.CSSProperties['flexDirection']
    justify?: React.CSSProperties['justifyContent']
    isWidth?: boolean
}>`
    display: flex;
    flex: 1;
    overflow: hidden;
    width: ${({ isWidth }) => isWidth && '100%'};
    justify-content: ${({ justify }) => justify || 'flex-start'};
    flex-direction: ${({ direction }) => direction || 'row'};
`

export const SCxListSecondaryItem = styled.div<{ isWidth?: boolean; marginBottom: number }>`
    /* width: 100%; */
    display: flex;
    flex-shrink: 0;
    width: ${({ isWidth }) => isWidth && '100%'};
    height: 28px;

    &:not(:last-child) {
        margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
    }
`

export const SCxEmphasizeText = styled.div<{ size: number; lineHeight: number; marginBottom: number }>`
    font-size: ${({ size }) => `${size}px`};
    color: var(--color-app-main);
    font-weight: 500;
    line-height: ${({ lineHeight }) => `${lineHeight}px`};
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
`

export const SCxHandle = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* width: 24px; */
`

export const SCxDivider = styled(Divider)<{ margin: number }>`
    margin: ${({ margin }) => `0 ${margin}px`};
`
export interface ListRecordProps extends ActionsProtocol {
    record: RecordLikeProtocol
    index: number
    blockId: string
    primaryField?: Field
    viewFields: ViewField[]
    schema: SchemaProtocol['schema']
    hoverColor: string
    isMobile?: boolean
    recordOpenable?: boolean
    canDeleteRecord?: boolean
    canRecordEdit?: boolean
    rowStyleConditions: Record<string, HighLightConditions>
    selectedRecords?: string[]
    checkable?: boolean
    designConfig: TableViewOptions
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    isLast: boolean
    onRecordSelect?: (recordIds: string[]) => void
    onRecordClick?: (recordId: string) => void
    onRecordEdit?: (recordId: string) => void
    onRecordDelete?: (recordId: string) => void
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
}
