import type { Field, FieldInputConfigProtocol, FieldType, TypeInstanceMap } from '@lighthouse/core'
import { DataSourceType } from '@lighthouse/core'

export const DATASOURCE_CELL_DATA_KEY = 'data-key'

export const DATASOURCE_FIELD_DATA_KEY = 'data-field'

export const DATASOURCE_CELL_FIELD_DATA_KEY = 'data-cell-field'

export const VIEW_CELL_DATA_KEY = 'data-key' // 'data-view-key'

export const VIEW_FIELD_DATA_KEY = 'data-field' // 'data-view-field'

export const VIEW_CELL_FIELD_DATA_KEY = 'data-cell-field' // 'data-view-cell-field'

export const SYSTEM_USER_FIELD = new Set<string>([
    'USERNAME',
    'AVATAR',
    'MOBILE',
    'USER_GROUP',
    'EMAIL',
    'DEPARTMENT',
    // 'WECHAT_UNION_ID',
    // 'WECHAT_PC_OPENID',
    'WECHAT_OPENID',
    'WECHAT_ID'
])
export const SYSTEM_ID = new Set<string>(['ID'])
export const SYSTEM_FIELD = new Set<string>([
    ...SYSTEM_USER_FIELD,
    'createdTime',
    'updatedTime',
    'updatedBy',
    'createdBy',
    'ID',
    'APPROVAL_STATE'
])
export const UPDATABLE_SYSTEM_FIELD = new Set<string>(['USERNAME', 'AVATAR', 'USER_GROUP'])
export const APPROVAL_IGNORE_FIELD = new Set<FieldType>(['formula'])
/**
 * table not editor cell
 */
export const createFieldNeedFetchData = new Set<FieldType>(['formula'])

export const noRealFieldTypes = new Set<FieldType>(['formula', 'aggregation'])

export const updateFieldNeedFetchData = new Set<FieldType>(['select', 'formula'])

export const noFormulaField = new Set<FieldType>(['notes', 'file', 'video'])

export const notEditableFieldTypes = new Set<Partial<FieldType>>([
    'id',
    'formula',
    'textGeneration',
    'selectGenerationByText',
    'aggregation'
])

export const editableInCellFieldTypes = new Set<Partial<FieldType>>(['checkbox'])

export const pureTextFieldTypes = new Set<FieldType>(['id', 'text', 'email', 'phoneNumber', 'url'])

export const emphasizeFieldTypes = new Set<FieldType>(['text', 'email', 'phoneNumber', 'url', 'number'])

export const notImportableFieldTypes = new Set<Partial<FieldType>>([
    'formula',
    'notes',
    /* 'file', */
    'textGeneration',
    'selectGenerationByText'
])

export const notMatchImportableFieldTypes = new Set<Partial<FieldType>>([
    'formula',
    /* 'file', */
    'textGeneration',
    'selectGenerationByText',
    'file',
    'video',
    'notes',
    'date'
])

export const aiFieldType = new Set<FieldType>(['textGeneration', 'selectGenerationByText'])

export const notFieldTypeSwitch = new Set<FieldType>(aiFieldType)

export const notWriteFieldType = new Set<FieldType>(aiFieldType)

export const hasIconFieldType = new Set<FieldType>(aiFieldType)

export const WYSIWYGFieldTypes = new Set<Partial<FieldType>>(['notes', 'url', 'phoneNumber', 'email', 'text', 'number'])

export const inputFieldTypes = new Set<Partial<FieldType>>([
    'id',
    'text',
    'userGroup',
    'phoneNumber',
    'url',
    'email',
    'formula',
    'number',
    'textGeneration'
])

export const dataSourceNotSettingFieldTypes = new Set<FieldType>([])

export const viewNotFilterSettingFieldTypes = new Set<FieldType>([])

export const viewSettingFieldTypes = new Set<FieldType>(['text', 'date', 'number'])

export const extraFieldTypes = new Set<FieldType>(['notes', 'formula'])

export const textGenerationNotFieldTypes = new Set<FieldType>(['notes'])

export const systemPersonFieldId = new Set<string>(['createdBy', 'updatedBy'])
// 特殊系统字段 UI 处理
export const signFieldId = new Set<string>(['ID', 'createdBy', 'updatedBy', 'createdTime', 'updatedTime', 'APPROVAL_STATE'])

export const dateFieldTypes = new Set<FieldType>(['date'])

export const numberFieldTypes = new Set<FieldType>(['number'])

export const personFieldTypes = new Set<FieldType>(['person'])

export const boolFieldTypes = new Set<FieldType>(['checkbox'])

export const extraFieldDataSource = new Set<DataSourceType>([
    DataSourceType.userDataSource,
    DataSourceType.joinDataSource,
    DataSourceType.dataSource
])

export const disableJoinDataSourceType = new Set<DataSourceType>([DataSourceType.joinDataSource, DataSourceType.aggregateDataSource])

export const signFieldIconColor = 'var(--color-blue-500)'

export const simpleFieldTypes = new Set<Partial<FieldType>>([
    'id',
    'text',
    'number',
    'select',
    'email',
    'phoneNumber',
    'url',
    'date',
    'notes'
])

export const joinDataSourceFieldType = new Set<FieldType>(['formula'])

export const datasourceFieldTypes: FieldType[] = [
    'id',
    'text',
    'number',
    'file',
    'video',
    'person',
    'select',
    'email',
    'phoneNumber',
    'url',
    'date',
    'notes',
    'formula',
    'checkbox',
    'userGroup',
    'userDepartment',
    'textGeneration',
    'selectGenerationByText'
]

export const dataSourceInnerTypes: TypeInstanceMap[] = ['TEXT', 'NUMBER', 'DATE', 'BOOL', 'ARRAY', 'NULL']

export const FieldTypeColorMap: Record<FieldType, string | undefined> = {
    textGeneration: 'var(--color-peach-400)',
    textGenerationByAudio: 'var(--color-peach-400)',
    textGenerationByImage: 'var(--color-peach-400)',
    booleanGenerationByText: 'var(--color-peach-400)',
    selectGenerationByText: 'var(--color-peach-400)',
    dateGenerationByText: 'var(--color-peach-400)',
    numberGenerationByText: 'var(--color-peach-400)',
    textGenerationByFile: 'var(--color-peach-400)',
    id: '',
    text: '',
    number: '',
    email: '',
    phoneNumber: '',
    url: '',
    select: '',
    date: '',
    file: '',
    video: '',
    notes: signFieldIconColor,
    person: '',
    formula: signFieldIconColor,
    userGroup: '',
    checkbox: '',
    userDepartment: '',
    aggregation: signFieldIconColor
}

export const FieldClassMap: Record<FieldType, Set<FieldType>> = {
    textGeneration: simpleFieldTypes,
    textGenerationByAudio: simpleFieldTypes,
    textGenerationByImage: simpleFieldTypes,
    booleanGenerationByText: boolFieldTypes,
    selectGenerationByText: new Set<FieldType>([]),
    dateGenerationByText: dateFieldTypes,
    numberGenerationByText: numberFieldTypes,
    textGenerationByFile: simpleFieldTypes,
    id: simpleFieldTypes,
    text: simpleFieldTypes,
    number: numberFieldTypes,
    email: simpleFieldTypes,
    phoneNumber: simpleFieldTypes,
    url: simpleFieldTypes,
    select: new Set<FieldType>([]),
    date: dateFieldTypes,
    file: new Set<FieldType>([]),
    video: new Set<FieldType>([]),
    notes: simpleFieldTypes,
    person: personFieldTypes,
    formula: new Set<FieldType>([]),
    userGroup: new Set<FieldType>([]),
    checkbox: boolFieldTypes,
    userDepartment: new Set<FieldType>([]),
    aggregation: new Set<FieldType>([])
}
/**
 * table view column min width
 */
export const COL_MIN_WIDTH = 100

/**
 * map field to icon type
 */

export const SystemFieldIconTypeMap: Record<string, string> = {
    USERNAME: 'PropertyText',
    AVATAR: 'BlockFile',
    MOBILE: 'PropertyPhone',
    USER_GROUP: 'PropertyText',
    ID: 'PropertyID',
    createdTime: 'PropertyCalendar',
    updatedTime: 'PropertyCalendar',
    updatedBy: 'PropertyEditor',
    createdBy: 'PropertyCreator',
    EMAIL: 'PropertyEmail',
    APPROVAL_STATE: 'PropertySingleSelect',
    DEPARTMENT: 'PropertyText',
    WECHAT_UNION_ID: 'WeChatLogo',
    WECHAT_PC_OPENID: 'WeChatLogo',
    WECHAT_OPENID: 'WeChatLogo',
    WECHAT_ID: 'WeChatLogo'
}

export const FieldIconTypeMap: Record<string, string> = {
    textGeneration: 'PropertyAIText',
    textGenerationByAudio: 'StationMessage',
    textGenerationByImage: 'BlockImage',
    booleanGenerationByText: 'PropertyCheckbox',
    selectGenerationByText: 'PropertySingleSelect',
    dateGenerationByText: 'PropertyCalendar',
    numberGenerationByText: 'PropertyNumber',
    textGenerationByFile: 'BlockFile',
    id: 'PropertyID',
    text: 'PropertyText',
    number: 'PropertyNumber',
    email: 'PropertyEmail',
    phoneNumber: 'PropertyPhone',
    url: 'PropertyLink',
    select: 'PropertySingleSelect',
    date: 'PropertyCalendar',
    file: 'BlockFile',
    video: 'BlockVideo',
    person: 'PropertyPerson',
    relation: 'PropertyRelation',
    relationed: 'PropertyRelationed',
    formula: 'PropertyFormula',
    notes: 'PropertyNote',
    createdBy: 'PropertyCreator',
    createdTime: 'PropertyCalendar',
    updatedBy: 'PropertyCreator',
    updatedTime: 'PropertyCalendar',
    userGroup: 'PropertyText',
    cascade: 'Cascade',
    checkbox: 'PropertyCheckbox',
    userDepartment: 'PropertySingleSelect',
    aggregation: ''
}

export const FieldNameMap: Record<FieldType, string> = {
    id: '自动编号',
    text: '文本',
    number: '数值',
    email: '邮箱',
    phoneNumber: '电话',
    url: '链接',
    select: '选项',
    date: '日期',
    file: '附件',
    video: '视频',
    person: '人员',
    notes: '笔记',
    formula: '计算',
    userGroup: '归属角色',
    checkbox: '复选框',
    userDepartment: '部门',
    textGeneration: '生成文本',
    textGenerationByAudio: '音频转文本',
    textGenerationByImage: '图像转文本',
    booleanGenerationByText: '文本转布尔',
    selectGenerationByText: '自动选项',
    dateGenerationByText: '文本转日期',
    numberGenerationByText: '文本转数字',
    textGenerationByFile: '文件转文本',
    aggregation: '聚合'
}

export const themeCellTypes: Field['type'][] = ['text', 'email', 'phoneNumber', 'url', 'number', 'notes']

export const MultipleFieldTypes: Field['type'][] = ['file']

export const FieldInputNameMap: Record<FieldInputConfigProtocol['inputType'], string> = {
    text: '文本',
    number: '数值',
    email: '邮箱',
    phoneNumber: '电话',
    url: '链接',
    // select: '选项',
    date: '日期',
    file: '附件',
    notes: '笔记',
    relativeSelect: '选择器',
    person: '人员',
    checkbox: '复选框',
    slider: '滑动输入条',
    cascade: '级联选择'
}

export const DataSourceNameMap: Record<DataSourceType, string> = {
    2: '用户数据源',
    5: '连接表',
    6: '数据源',
    7: '聚合数据源'
}

export const FieldInputIconTypeMap: Record<FieldInputConfigProtocol['inputType'], string> = {
    text: 'PropertyText',
    number: 'PropertyNumber',
    email: 'PropertyEmail',
    phoneNumber: 'PropertyPhone',
    url: 'PropertyLink',
    // select: 'PropertySingleSelect',
    date: 'PropertyCalendar',
    file: 'BlockFile',
    notes: 'PropertyNote',
    relativeSelect: 'PropertySingleSelect',
    person: 'PropertyPerson',
    checkbox: 'PropertyCheckbox',
    slider: 'PropertyNumber',
    cascade: 'PropertyCascade'
}

export const innerTypeToFieldType: Record<TypeInstanceMap, FieldType | undefined> = {
    NUMBER: 'number',
    TEXT: 'text',
    DATE: 'date',
    BOOL: 'checkbox',
    RICHTEXT: 'notes',
    ARRAY: undefined,
    NULL: undefined
}

export const FieldNameMapByInnerType: Record<TypeInstanceMap, string> = {
    NUMBER: '数值',
    TEXT: '文本',
    DATE: '日期',
    BOOL: '复选框',
    ARRAY: '数组',
    RICHTEXT: '富文本',
    NULL: 'NULL'
}

export const InnerTypeMapByFieldType: Record<FieldType, TypeInstanceMap> = {
    id: 'TEXT',
    text: 'TEXT',
    number: 'NUMBER',
    email: 'TEXT',
    phoneNumber: 'TEXT',
    url: 'TEXT',
    select: 'ARRAY',
    date: 'DATE',
    file: 'ARRAY',
    video: 'ARRAY',
    person: 'ARRAY',
    notes: 'TEXT',
    formula: 'NULL',
    userGroup: 'TEXT',
    checkbox: 'BOOL',
    userDepartment: 'ARRAY',
    textGeneration: 'TEXT',
    textGenerationByAudio: 'TEXT',
    textGenerationByImage: 'TEXT',
    booleanGenerationByText: 'BOOL',
    selectGenerationByText: 'ARRAY',
    dateGenerationByText: 'DATE',
    numberGenerationByText: 'NUMBER',
    textGenerationByFile: 'TEXT',
    aggregation: 'NULL'
}
// export const innerTypeToOperatorMap: Record<TypeInstanceMap, FieldType> = {
//     NUMBER: 'number',
//     TEXT: 'text',
//     DATE: 'date',
//     BOOL: 'checkbox',
//     USER: 'person',
//     FILE: 'file',
//     SELECT: 'select',
//     RICH_TEXT: 'notes',
//     NULL: 'file',
//     ARRAY_OF_NUMBER: 'formula',
//     ARRAY_OF_TEXT: 'formula',
//     ARRAY_OF_DATE: 'formula',
//     ARRAY_OF_BOOL: 'formula',
//     ARRAY_OF_USER: 'person',
//     ARRAY_OF_FILE: 'file',
//     ARRAY_OF_SELECT: 'select'
// }

export const innerTypeToFieldTypeMapInCondition: Record<TypeInstanceMap, FieldType | undefined> = {
    NUMBER: 'number',
    TEXT: 'text',
    DATE: 'date',
    BOOL: 'checkbox',
    ARRAY: 'select',
    RICHTEXT: 'notes',
    NULL: undefined
}

export const innerTypeToFieldTypeIconMap: Record<TypeInstanceMap, string> = {
    NUMBER: 'PropertyNumber',
    TEXT: 'PropertyText',
    DATE: 'PropertyCalendar',
    BOOL: 'PropertyCheckbox',
    ARRAY: 'PropertyArray',
    NULL: 'PropertyFormulaError',
    RICHTEXT: 'PropertyNote'
}

// export const innerTypeToFieldTypeNoArrayMap: Record<TypeInstanceMap, FieldType | undefined> = {
//     NUMBER: 'number',
//     TEXT: 'text',
//     DATE: 'date',
//     BOOL: 'text',
//     USER: 'person',
//     FILE: 'file',
//     SELECT: 'select',
//     RICH_TEXT: 'notes',
//     NULL: undefined,
//     ARRAY_OF_NUMBER: undefined,
//     ARRAY_OF_TEXT: undefined,
//     ARRAY_OF_DATE: undefined,
//     ARRAY_OF_BOOL: undefined,
//     ARRAY_OF_USER: 'person',
//     ARRAY_OF_FILE: undefined,
//     ARRAY_OF_SELECT: 'select'
// }

export const fieldValueSettingMap: Record<FieldType, FieldType[]> = {
    textGeneration: [
        'text',
        'url',
        'email',
        'phoneNumber',
        'id',
        'userGroup',
        'select',
        'person',
        'number',
        'textGeneration',
        'userDepartment',
        'selectGenerationByText'
    ],
    textGenerationByAudio: [],
    textGenerationByImage: [],
    booleanGenerationByText: [],
    selectGenerationByText: ['select', 'selectGenerationByText'],
    dateGenerationByText: [],
    numberGenerationByText: [],
    textGenerationByFile: [],
    text: [
        'text',
        'url',
        'email',
        'phoneNumber',
        'id',
        'userGroup',
        'select',
        'person',
        'number',
        'textGeneration',
        'userDepartment',
        'selectGenerationByText'
    ],
    url: [
        'text',
        'url',
        'email',
        'phoneNumber',
        'id',
        'userGroup',
        'select',
        'person',
        'number',
        'textGeneration',
        'userDepartment',
        'selectGenerationByText'
    ],
    email: [
        'text',
        'url',
        'email',
        'phoneNumber',
        'id',
        'userGroup',
        'select',
        'person',
        'number',
        'textGeneration',
        'userDepartment',
        'selectGenerationByText'
    ],
    phoneNumber: [
        'text',
        'url',
        'email',
        'phoneNumber',
        'id',
        'userGroup',
        'select',
        'person',
        'number',
        'textGeneration',
        'userDepartment',
        'selectGenerationByText'
    ],
    number: ['number'],
    date: ['date'],
    file: ['file'],
    video: ['video'],
    notes: ['notes'],
    person: ['person'],
    id: [
        'text',
        'url',
        'email',
        'phoneNumber',
        'id',
        'userGroup',
        'number',
        'textGeneration',
        'userDepartment',
        'selectGenerationByText'
    ],
    select: ['select', 'selectGenerationByText'],
    formula: ['text', 'url', 'email', 'phoneNumber', 'id', 'userGroup'],
    userGroup: ['text', 'url', 'email', 'phoneNumber', 'id', 'userGroup'],
    checkbox: ['checkbox'],
    userDepartment: [
        'text',
        'url',
        'email',
        'phoneNumber',
        'id',
        'userGroup',
        'select',
        'person',
        'number',
        'textGeneration',
        'userDepartment',
        'selectGenerationByText'
    ],
    aggregation: []
}

export const placeholderFromFieldType: Record<FieldType, string> = {
    textGeneration: '请输入',
    textGenerationByAudio: '请输入',
    textGenerationByImage: '请输入',
    booleanGenerationByText: '请选择',
    selectGenerationByText: '请选择',
    dateGenerationByText: '请选择',
    numberGenerationByText: '请输入',
    textGenerationByFile: '请输入',
    text: '请输入',
    url: '请输入',
    email: '请输入',
    phoneNumber: '请输入',
    number: '请输入',
    date: '请选择',
    file: '请选择',
    video: '请选择',
    notes: '请输入',
    person: '请选择',
    id: '请输入',
    select: '请选择',
    formula: '请选择',
    userGroup: '请选择',
    checkbox: '请选择',
    userDepartment: '请选择',
    aggregation: '请选择'
}

export const USER_DATASOURCE = 'USER_DATASOURCE'

export const USER_CODE = 'USER_CODE'

export const editableUserFieldId = new Set<string>(['USERNAME', 'AVATAR'])

export const FieldInnerTypeOfDataTypeNameMap: Record<TypeInstanceMap, string> = {
    NUMBER: '数值',
    TEXT: '文本',
    DATE: '日期',
    BOOL: '布尔',
    ARRAY: '数组',
    NULL: '错误',
    RICHTEXT: '富文本'
}

export const FieldTypeOfDataTypeNameMap: Record<FieldType, string> = {
    id: '文本',
    text: '文本',
    number: '数值',
    email: '文本',
    phoneNumber: '文本',
    url: '文本',
    select: '数组',
    date: '日期',
    file: '数组',
    video: '数组',
    person: '数组',
    notes: '文本',
    formula: '动态',
    aggregation: '动态',
    userGroup: '文本',
    checkbox: '布尔',
    userDepartment: '数组',
    textGeneration: '文本',
    textGenerationByAudio: '',
    textGenerationByImage: '',
    booleanGenerationByText: '',
    selectGenerationByText: '数组',
    dateGenerationByText: '',
    numberGenerationByText: '',
    textGenerationByFile: ''
}
