import type { AppUser, FormulaField, FormulaValue, HighLightConditions } from '@lighthouse/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { CellHighlighter } from '../Highlighter'
import { innerOverview } from './InnerTypeRender'

interface FormulaValuePreviewerProps {
    field: FormulaField
    data?: FormulaValue
    width?: number
    isWrap?: boolean
    isBold?: boolean
    styleConditions?: HighLightConditions
    personOptions?: AppUser[]
}

const SCxFormula = styled.div<{ isWrap: boolean; isBold?: boolean }>`
    position: relative;
    width: 100%;
    white-space: ${({ isWrap }) => (isWrap ? 'normal' : 'nowrap')};
    font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
    word-break: break-word;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
    justify-content: flex-start;
    line-height: 24px;
`

export const FormulaValuePreviewer: React.FC<FormulaValuePreviewerProps> = ({
    field,
    data,
    width = 160,
    isWrap = true,
    isBold,
    styleConditions,
    personOptions = []
}) => {
    const { innerType, id } = field

    const formulaRender = useMemo(() => {
        if (!innerType) {
            return
        }
        return innerOverview(field, data, innerType, width, personOptions, isWrap, isBold)
    }, [data, field, innerType, isBold, isWrap, personOptions, width])

    return (
        <>
            <SCxFormula isWrap={isWrap} isBold={isBold}>
                {formulaRender}
            </SCxFormula>
        </>
    )
}
