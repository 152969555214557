import type { MakeADT } from 'ts-adt/MakeADT'

import type { ApplicationId } from './application'
import type { DTCheckbox, DTDate, DTNumber, DTSelectGenerationByText, DTTextGeneration, TypeInstanceMap } from './data'
import type { EnvironmentId } from './environment'
import type {
    BoolValue,
    DateValue,
    FieldId,
    FieldType,
    FileValue,
    FormulaValue,
    NotesValue,
    NumberValue,
    PersonValue,
    SelectValue,
    SimpleTextValue,
    TextValue
} from './field'
import type {
    AggregateProtocol,
    FilterableProtocol,
    HighlightingProtocol,
    JoinProtocol,
    PaginationProtocol,
    PromptProtocol,
    SortableProtocol,
    TablePropsProtocol
} from './general'

export type ViewId = string

export type ViewType = 'table' | 'advancedTable' | 'list' | 'gallery' | 'kanban' | 'calendar' | 'custom'

export type RelatedViewType = 'relatedTable' | 'relatedAdvancedTable' | 'relatedList' | 'relatedGallery'

export type ExtraViewFieldType = 'formula'

export enum SelectedMode {
    'ALL' = 'ALL',
    'CURRENT_PAGE' = 'CURRENT_PAGE'
}

export interface DataViewProtocol {
    viewOptions: TablePropsProtocol &
    PaginationProtocol &
    SortableProtocol &
    FilterableProtocol &
    HighlightingProtocol &
    PromptProtocol &
    JoinProtocol &
    AggregateProtocol
}

export interface ViewTypeProtocol {
    viewType: ViewType
}

export type RichTextViewFieldMode = 'TEXT' | 'PERSON'

export type NumberViewFieldMode = 'NUMBER' | 'PERCENTAGE'

export interface ExtraBaseViewField {
    fieldId: string
    title: string
}

export type GroupRecordCount = {
    value: string
    count: number | undefined
}

export type GroupTab = {
    label: string
    value: string
    count: number | undefined
}

export type TableColumnWidth = Record<string, number>

export interface ViewFieldProtocol {
    title: string
    fieldId: string
    type: FieldType
    innerType?: TypeInstanceMap
    dsId?: string
    dsName?: string

      // 连接表中字段
      join?: {
        joinDsId: string
        joinFieldId: string
    }
}

export interface ViewFieldProps extends ViewFieldProtocol {
    width?: number
    required?: boolean
    visible: boolean
}

export interface TextViewField extends ViewFieldProps { }

export interface DateViewField extends ViewFieldProps {
    date: DTDate
}

export interface NumberViewField extends ViewFieldProps {
    number: DTNumber
}

export interface CheckboxViewField extends ViewFieldProps {
    checkbox: DTCheckbox
}

export type TextGenerationViewField = ViewFieldProps & {
    textGeneration: DTTextGeneration
}

export type SelectGenerationByTextViewField = ViewFieldProps & {
    selectGenerationByText: DTSelectGenerationByText
}

export interface BaseViewField extends ViewFieldProps { }

export type ViewField = MakeADT<
    'type',
    {
        id: BaseViewField
        text: TextViewField
        url: BaseViewField
        phoneNumber: BaseViewField
        email: BaseViewField
        file: BaseViewField
        video: BaseViewField
        person: BaseViewField
        createdTime: DateViewField
        updatedTime: DateViewField
        number: NumberViewField
        select: BaseViewField
        date: DateViewField
        formula: BaseViewField
        notes: BaseViewField
        userGroup: BaseViewField
        checkbox: CheckboxViewField
        textGeneration: TextGenerationViewField
        selectGenerationByText: SelectGenerationByTextViewField
    }
>

export type ViewFieldADTValue = MakeADT<
    'type',
    {
        id: BaseViewField & { value: SimpleTextValue }
        text: TextViewField & { value: TextValue }
        url: BaseViewField & { value: SimpleTextValue }
        phoneNumber: BaseViewField & { value: SimpleTextValue }
        email: BaseViewField & { value: SimpleTextValue }
        file: BaseViewField & { value: FileValue }
        video: BaseViewField & { value: FileValue }
        person: BaseViewField & { value: PersonValue }
        createdTime: DateViewField & { value: DateValue }
        updatedTime: DateViewField & { value: DateValue }
        number: NumberViewField & { value: NumberValue }
        select: BaseViewField & { value: SelectValue }
        date: DateViewField & { value: DateValue }
        formula: ViewFieldProtocol & { value: FormulaValue }
        notes: BaseViewField & { value: NotesValue }
        userGroup: BaseViewField & { value: SimpleTextValue }
        checkbox: CheckboxViewField & { value: BoolValue }
        textGeneration: TextGenerationViewField & { value: SimpleTextValue }
        selectGenerationByText: SelectGenerationByTextViewField & { value: SelectValue }
    }
>

export type KanbanColumnsSortItem = {
    value: string
    visible: boolean
}

export type KanbanColumnsSort = Record<FieldId, KanbanColumnsSortItem[]>
