import { Box, DatePicker, Input } from '@byecode/ui'
import type { NumberRangeArray } from '@lighthouse/core'
import { DateDrawer, useApplicationContext } from '@lighthouse/shared'
import { lightFormat } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import type { CustomProps } from './types'

interface TileCustomDateFilterProps extends CustomProps {
    isMobile?: boolean
    style?: React.CSSProperties
}

const SCxInputWrapper = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    padding: 4px 16px;
    gap: 4px;
    align-items: center;
`

const dateFormat = 'yyyy/MM/dd'

export const CustomDateFilter: React.FC<TileCustomDateFilterProps> = ({ customValue, disabled, isMobile, style, onCustomChange }) => {
    const [range, setRange] = useState(customValue)
    const [startOpened, setStartOpened] = useState(false)
    const [endOpened, setEndOpened] = useState(false)
    const { pageTarget } = useApplicationContext()

    const [startDate, endDate] = useMemo(() => {
        const start = range?.[0]
        const end = range?.[1]
        const startDate = start ? new Date(start) : undefined
        const endDate = end ? new Date(end) : undefined
        return [startDate, endDate]
    }, [range])

    const handleStartChange = useCallback(
        (val?: Date) => {
            const newValue: NumberRangeArray = [val ? val.valueOf() : val, range?.[1]]
            setRange(newValue)
            onCustomChange?.(newValue)
        },
        [onCustomChange, range]
    )

    const handleEndChange = useCallback(
        (val?: Date) => {
            const newValue: NumberRangeArray = [range?.[0], val ? val.valueOf() : val]
            setRange(newValue)
            onCustomChange?.(newValue)
        },
        [onCustomChange, range]
    )

    const ele = useMemo(() => {
        if (isMobile) {
            return (
                <>
                    <Input
                        readOnly
                        placeholder="开始时间"
                        defaultValue={startDate && lightFormat(startDate, dateFormat)}
                        onClick={() => setStartOpened(true)}
                    />
                    <Box style={{ color: 'var(--color-black)', fontSize: 14 }}>-</Box>
                    <Input
                        readOnly
                        placeholder="结束时间"
                        defaultValue={endDate && lightFormat(endDate, dateFormat)}
                        onClick={() => setEndOpened(true)}
                    />
                    <DateDrawer
                        opened={startOpened}
                        value={startDate?.getDate()}
                        target={pageTarget}
                        maxDate={endDate}
                        showTime={false}
                        onChange={v => handleStartChange(new Date(v))}
                        onClose={() => setStartOpened(false)}
                    />

                    <DateDrawer
                        opened={endOpened}
                        value={endDate?.getDate()}
                        target={pageTarget}
                        minDate={startDate}
                        showTime={false}
                        onChange={v => handleEndChange(new Date(v))}
                        onClose={() => setEndOpened(false)}
                    />
                </>
            )
        }

        return (
            <>
                <DatePicker
                    disableIcon
                    format={dateFormat}
                    showTime={false}
                    style={{ flex: 1 }}
                    disabled={disabled || isMobile}
                    placeholder="开始时间"
                    maxDate={endDate}
                    clearable
                    hideNow
                    onChange={handleStartChange}
                    value={startDate}
                />

                <Box style={{ color: 'var(--color-black)', fontSize: 14 }}>-</Box>

                <DatePicker
                    disableIcon
                    format={dateFormat}
                    showTime={false}
                    disabled={disabled}
                    clearable
                    hideNow
                    placeholder="结束时间"
                    minDate={startDate}
                    onChange={handleEndChange}
                    value={endDate}
                    style={{ flex: 1 }}
                />
            </>
        )
    }, [disabled, endDate, endOpened, handleEndChange, handleStartChange, isMobile, pageTarget, startDate, startOpened])

    return <SCxInputWrapper style={style}>{ele}</SCxInputWrapper>
}
