/* eslint-disable react/no-array-index-key */
import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import React, { forwardRef, useLayoutEffect, useRef } from 'react'

import { styled } from '../../../theme/stitches.config'
import type { Selectors, StyleComponentProps } from '../../../theme/types'
import { Box } from '../../Box'
import { useStyles } from './TimeCalendar.style'

export type TimeCalendarStylesName = Selectors<typeof useStyles>

const Column = styled('div', {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    height: 268,
    position: 'relative',
    overflow: 'scroll',
    hiddenScroll: true,
    '&:not(:first-child)': {
        borderLeft: '1px solid $colorGray200'
    },
    '&::after': {
        content: '',
        display: 'block',
        flex: `0 0 ${268 - 30}px`
    }
})

interface TimeCalendarProps extends StyleComponentProps<TimeCalendarStylesName> {
    disabled?: boolean
    date?: Date
    defaultDate?: Date
    onChange?: (date: Date) => void
    disableSecond?: boolean
}

export const TimeCalendar = forwardRef<HTMLDivElement, TimeCalendarProps>((props, ref) => {
    const { disabled, date, defaultDate, onChange, disableSecond, classNames, styles, unstyled, ...rest } = props

    const { classes } = useStyles({}, { name: 'Calendar', classNames, styles, unstyled })

    const hourRef = useRef<HTMLDivElement | null>(null)
    const minuteRef = useRef<HTMLDivElement | null>(null)
    const secondRef = useRef<HTMLDivElement | null>(null)

    const [innerDate, setInnerDate] = useUncontrolled({
        value: date,
        defaultValue: defaultDate || new Date(),
        onChange
    })

    useLayoutEffect(() => {
        const hourColumnEl = hourRef.current
        const minuteColumnEl = minuteRef.current
        const secondColumnEl = secondRef.current

        if (!hourColumnEl || !minuteColumnEl || !secondColumnEl) {
            return
        }

        const hourSelectedEl = hourColumnEl.querySelector<HTMLDivElement>('[data-selected]')
        const minuteSelectedEl = minuteColumnEl.querySelector<HTMLDivElement>('[data-selected]')
        const secondSelectedEl = secondColumnEl.querySelector<HTMLDivElement>('[data-selected]')

        if (hourSelectedEl) {
            hourColumnEl.scrollTo({ top: hourSelectedEl.offsetTop, behavior: 'smooth' })
        }
        if (minuteSelectedEl) {
            minuteColumnEl.scrollTo({ top: minuteSelectedEl.offsetTop, behavior: 'smooth' })
        }
        if (secondSelectedEl) {
            secondColumnEl.scrollTo({ top: secondSelectedEl.offsetTop, behavior: 'smooth' })
        }
    }, [innerDate])

    return (
        <Box ref={ref} className={classes.timeBody} {...rest}>
            <Column ref={hourRef}>
                {Array.from({ length: 24 }).map((_, i) => (
                    <Box
                        key={i}
                        className={classes.timeCell}
                        onClick={e => {
                            if (disabled) {
                                return
                            }
                            const newDate = new Date(innerDate)
                            newDate.setHours(i)
                            setInnerDate(newDate)

                            // hourRef.current?.scrollTo({ top: e.currentTarget.offsetTop, behavior: 'smooth' })
                        }}
                        data-selected={innerDate.getHours() === i || undefined}
                    >
                        {String(i).padStart(2, '0')}
                    </Box>
                ))}
            </Column>
            <Column ref={minuteRef}>
                {Array.from({ length: 60 }).map((_, i) => (
                    <Box
                        key={i}
                        className={classes.timeCell}
                        onClick={e => {
                            if (disabled) {
                                return
                            }
                            const newDate = new Date(innerDate)
                            newDate.setMinutes(i)
                            setInnerDate(newDate)

                            // minuteRef.current?.scrollTo({ top: e.currentTarget.offsetTop, behavior: 'smooth' })
                        }}
                        data-selected={innerDate.getMinutes() === i || undefined}
                    >
                        {String(i).padStart(2, '0')}
                    </Box>
                ))}
            </Column>

            {!disableSecond && (
                <Column ref={secondRef}>
                    {Array.from({ length: 60 }).map((_, i) => (
                        <Box
                            key={i}
                            className={classes.timeCell}
                            onClick={e => {
                                if (disabled) {
                                    return
                                }
                                const newDate = new Date(innerDate)
                                newDate.setSeconds(i)
                                setInnerDate(newDate)

                                // secondRef.current?.scrollTo({ top: e.currentTarget.offsetTop, behavior: 'smooth' })
                            }}
                            data-selected={innerDate.getSeconds() === i || undefined}
                        >
                            {String(i).padStart(2, '0')}
                        </Box>
                    ))}
                </Column>
            )}
        </Box>
    )
})
