import type { AppUser } from '@lighthouse/core'
import {
    type DataSourceAbstract,
    type FilterCommonCondition,
    type QuickFilterConfig,
    type VariableADTvalue,
    type ViewBlockAbstract,
    type ViewType,
    VariableType
} from '@lighthouse/core'
import { find, intersection } from 'rambda'

import { DEFAULT_FILTER_VALUE_VARIABLE, getInitQuickFilter } from '../components'
import { calendarPageSize, kanbanPageSize } from '../constants'
import { getVariableFieldId } from './condition'
import { isIdsValue } from './helper'

export const getRefetchViewCurrentPage = (viewType: ViewType, currentPage: number) => {
    if (viewType === 'kanban') {
        return 1
    }
    if (viewType === 'calendar') {
        return 1
    }
    return currentPage
}

export const getRefetchViewPageSize = (viewType: ViewType, pageSize?: number) => {
    if (viewType === 'kanban') {
        return kanbanPageSize
    }
    if (viewType === 'calendar') {
        return calendarPageSize
    }
    return pageSize
}

export const getRefetchViewCount = (block: ViewBlockAbstract) => {
    if (block.config.viewType === 'kanban') {
        return kanbanPageSize
    }
    if (block.config.viewType === 'calendar') {
        return calendarPageSize
    }
    return block.config.pagination.rowTotal
}

interface getQuickFilterRuleParams {
    quickFiltersCache: FilterCommonCondition[]
    quickFilter: QuickFilterConfig
    dataSource: DataSourceAbstract
    personOptions: AppUser[]
}

export const getQuickFilterRule = ({ quickFiltersCache, quickFilter, dataSource, personOptions }: getQuickFilterRuleParams) => {
    const rules = [...quickFiltersCache]
    if (quickFilter.mode === 'disable') {
        return []
    }
    return (
        quickFilter.fieldIds?.reduce<FilterCommonCondition[]>((acc, fieldId) => {
            const rule = find(rule => getVariableFieldId(rule.idVariable) === fieldId, rules)
            if (!dataSource) {
                return acc
            }
            const field = dataSource.schema[fieldId]
            if (!field) {
                return acc
            }
            if (rule && field.type === 'select') {
                const { options } = field.select
                const labels = options.map(item => item.label)
                const newParamList: VariableADTvalue[] = rule?.paramList?.map(param => {
                    if (param.type === VariableType.VALUE) {
                        const value = param.valueVariable?.value
                        if (isIdsValue(value)) {
                            const newValue = intersection(labels, value)
                            return {
                                type: VariableType.VALUE,
                                valueVariable: {
                                    value: newValue,
                                    type: 'select'
                                }
                            }
                        }
                    }
                    return param
                }) || [DEFAULT_FILTER_VALUE_VARIABLE]
                const r: FilterCommonCondition = { ...rule, paramList: newParamList }
                const newRule = getInitQuickFilter(field, quickFilter.mode, r)
                acc.push(newRule)
                return acc
            }
            if (rule && field.type === 'person') {
                const userIds = personOptions.reduce<string[]>(
                    (prev, cur) => {
                        if (cur.isDepart) {
                            return prev
                        }
                        prev.push(cur.userId)
                        return prev
                    },
                    ['{currentUserId}']
                )
                const newParamList: VariableADTvalue[] = rule?.paramList?.map(param => {
                    if (param.type === VariableType.VALUE) {
                        const value = param.valueVariable?.value
                        if (isIdsValue(value)) {
                            const newValue = intersection(userIds, value)
                            return {
                                type: VariableType.VALUE,
                                valueVariable: {
                                    value: newValue,
                                    type: 'person'
                                }
                            }
                        }
                    }
                    return param
                }) || [DEFAULT_FILTER_VALUE_VARIABLE]
                const r: FilterCommonCondition = { ...rule, paramList: newParamList }
                const newRule = getInitQuickFilter(field, quickFilter.mode, r)
                acc.push(newRule)
                return acc
            }
            const newRule = getInitQuickFilter(field, quickFilter.mode, rule)
            acc.push(newRule)
            return acc
        }, []) || []
    )
}
