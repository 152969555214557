import { isSuccessfulCallToToast } from '@lighthouse/shared';
import type { UploadyProps } from '@rpldy/shared-ui'

import http from '@/http'

export const uploadAuth = (): Pick<UploadyProps, 'params' | 'headers'> => {
    return {
        headers: http.defaults.headers.common
    }
}


type UploadInDataSourceParams = {
    appId: string
    dsId: string
    fieldId: string
    recordId?: string
}


export const uploadInDataSourceManagerParams = (params: UploadInDataSourceParams): UploadyProps => {
    const { appId, dsId } = params
    return {
        destination: { url: `/lighthouse/api/v1/${appId}/oss/uploadInDataSource`, ...uploadAuth(), params: { dsId } },
        isSuccessfulCall: isSuccessfulCallToToast
    }
}


type UploadVodInDataSourceParams = {
    appId: string
    dsId: string
    fieldId: string
    recordId?: string
}


/** 上传视频管理器使用 */
export const uploadVideoInDataSourceManagerParams = (params: UploadVodInDataSourceParams): UploadyProps => {
    const { appId, ...rest } = params
    return {
        destination: { url: `/lighthouse/api/v1/${appId}/oss/uploadVod`, ...uploadAuth(), params: rest },
        isSuccessfulCall: isSuccessfulCallToToast
    }
}
