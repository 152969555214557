import { Button, Flex } from '@byecode/ui'
import type { SubFormColumn } from '@lighthouse/core'
import { FieldInput } from '@lighthouse/shared'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { CMxEmpty, CMxEndIndex, CMxFirstIndex, CMxRecord } from './styles'
import { SubFormCell } from './SubFormCell'
import { useSubFormFieldInputContext } from './SubFormFieldInputProvider'
import type { SubFormRecord } from './types'

interface SubFormRecordProps {
    columns: SubFormColumn[]
    data: SubFormRecord
    index: number
}


export const SubFormTableRecord: React.FunctionComponent<SubFormRecordProps> = ({ columns, data, index }) => {
    const recordProps = useSubFormFieldInputContext()

    return (
        <CMxRecord style={{ width: columns.length === 0 ? '100%' : 'max-content' }}>
            <CMxFirstIndex>{index + 1}</CMxFirstIndex>
            {columns.map(column => <SubFormCell key={column.id} recordId={data.id} value={data.content?.[column.id]} column={column} /> )}
            {columns.length === 0 && <CMxEmpty />}
            <CMxEndIndex>
                <Button
                    type="text"
                    style={{ height: 20, fontSize: 'var(--font-size-sm)', color: 'var(--color-gray-600)', whiteSpace: 'nowrap' }}
                    onClick={() => recordProps.onRecordCopy?.(data.id)}
                >
                    复制
                </Button>
                <Button
                    type="text"
                    style={{ height: 20, fontSize: 'var(--font-size-sm)', color: 'var(--color-red-300)', whiteSpace: 'nowrap' }}
                    onClick={() => recordProps.onRecordRemove?.(data.id)}
                >
                    删除
                </Button>
            </CMxEndIndex>
        </CMxRecord>
    )
}
