import type { VariableADTvalue } from '@lighthouse/core'
import { DIRECTION, FLEX_ALIGN, MAX_CONTAINER_WIDTH_SIZE } from '@lighthouse/core'
import { mergeRefs } from '@lighthouse/tools'
import React, { forwardRef, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { useMediaQueryRemBase } from '../../../hooks/useMediaQueryRemBase'
import { DEFAULT_LAYOUT_CONTEXT_VALUE, FlowContainerProvider, FlowLayoutProvider } from '../Context'
import { SIZE_OPTIONS, useContainerWidth } from '../hooks/useContainerWidth'
import { ContainerOutline } from '../Sortable/ContainerOutline'
import type { ContainerHighlight, LayoutProperty, NodeRenderProps } from '../types'

const OutlineWrapper = styled.div`
    position: absolute;
    inset: 0;
    pointer-events: none;
    display: none;
`
const Container = styled.div<{ disabled?: boolean }>`
    min-height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: var(--container-align-x);
    padding-top: var(--container-padding-top);
    padding-right: var(--container-padding-right);
    padding-bottom: var(--container-padding-bottom);
    padding-left: var(--container-padding-left);
    ${({ disabled }) => [
        !disabled &&
            css`
                &:not(:has(div[data-type='container']:hover)):hover > ${OutlineWrapper} {
                    display: block;
                }
            `
    ]}
`

interface CanvasProps extends React.ComponentPropsWithoutRef<'div'> {
    size?: LayoutProperty['size']
    gap?: LayoutProperty['gap']
    direction?: LayoutProperty['direction']
    padding?: LayoutProperty['padding']
    alignX?: LayoutProperty['alignX']
    alignY?: LayoutProperty['alignY']
    veins?: LayoutProperty['veins']
    background?: LayoutProperty['background']

    scale?: number

    parseBackgroundVariableImage?: (value: VariableADTvalue | undefined) => string

    disabled?: boolean
    highlight?: Record<string, ContainerHighlight>
    nodeRender?: (props: NodeRenderProps) => JSX.Element | null
    labelRender?: (id: string) => React.ReactNode
}

export const Canvas = forwardRef<HTMLDivElement, CanvasProps>(
    (
        {
            children,
            size = MAX_CONTAINER_WIDTH_SIZE.unlimited,
            direction = DIRECTION.vertical,
            gap = 0,
            alignX = FLEX_ALIGN['flex-start'],
            alignY = FLEX_ALIGN['flex-start'],
            veins,
            background,
            padding,

            scale,

            parseBackgroundVariableImage,
            disabled,
            highlight,
            style,
            nodeRender,
            labelRender,
            ...props
        },
        ref
    ) => {
        const remBase = useMediaQueryRemBase()

        const [left = 0, top = 0, right = 0, bottom = 0] = padding || []

        const { ref: internalRef, width, rect } = useContainerWidth(size)
        const el = internalRef.current

        const isMobile = rect.width < 598

        const contextValue = useMemo(
            () => ({
                fullWidth: width,
                contentWidth: width,
                nodeUnitWidth: width / DEFAULT_LAYOUT_CONTEXT_VALUE.rows,
                size,
                padding,
                gap,
                direction,
                alignX,
                alignY
            }),
            [alignX, alignY, direction, gap, padding, size, width]
        )

        return (
            <FlowLayoutProvider
                value={{
                    ...DEFAULT_LAYOUT_CONTEXT_VALUE,
                    rootElement: el,
                    disabled,
                    isMobile,
                    nodeRender,
                    labelRender,
                    highlight,
                    parseBackgroundVariableImage,
                    scale
                }}
            >
                <FlowContainerProvider value={contextValue}>
                    <Container
                        ref={mergeRefs([ref, internalRef])}
                        disabled={disabled}
                        style={{
                            opacity: width === 0 ? 0 : undefined,
                            maxWidth: size === MAX_CONTAINER_WIDTH_SIZE.unlimited ? undefined : SIZE_OPTIONS[size],
                            margin: '0 auto',
                            ...style,

                            // ...backgroundStyle,
                            // backgroundAttachment: 'fixed',
                            '--node-height-unit': `${DEFAULT_LAYOUT_CONTEXT_VALUE.unitHeight}px`,
                            '--node-width-unit': `${contextValue.nodeUnitWidth}px`,
                            '--container-direction': direction ?? DIRECTION.vertical,
                            '--container-align-x': alignX,
                            '--container-align-y': alignY,
                            '--container-gap': `${gap}rem`,
                            '--container-padding-left': `${left}rem`,
                            '--container-padding-top': `${top}rem`,
                            '--container-padding-right': `${right}rem`,
                            '--container-padding-bottom': `${bottom}rem`
                        }}
                        {...props}
                    >
                        {children}
                        {disabled ? null : (
                            <OutlineWrapper>
                                <ContainerOutline
                                    width={rect.width + left * remBase + right * remBase}
                                    height={rect.height + top * remBase + bottom * remBase}
                                />
                            </OutlineWrapper>
                        )}
                    </Container>
                </FlowContainerProvider>
            </FlowLayoutProvider>
        )
    }
)
