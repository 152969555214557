import { Message, Toast } from '@byecode/ui'
import { useCustomViewBlockContext } from '@lighthouse/block'
import { type ButtonAction, type ButtonItem, type RecordLikeProtocol, WechatPayStatus, WechatPayType } from '@lighthouse/core'
import type { IClickActionConfig, UpdateRecordActionConfig } from '@lighthouse/shared'
import {
    ANONYMOUS,
    blockListenerSystem,
    commonPages,
    downloadFile,
    dummyTriggerNodeId,
    generateAdtValue,
    generateVariableValue,
    getActiveAddIClickUserVariableValue,
    getCreateRecordVariableValue,
    getMainTableRecordId,
    getSearchParams,
    getUpdateRecordVariableValue,
    getWechatPayType,
    getWindowUrl,
    openNewBrowserPage,
    pageStackPubSub,
    resolveDownloadFileUrls,
    resolveFilter,
    resolveVariable,
    scroll2FlowNode,
    useAtomData
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import copyToClipboard from 'copy-to-clipboard'
import type { AnyObject } from 'immer/dist/internal'
import { useAtomCallback } from 'jotai/utils'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { actionWorker } from '@/actionsEngine'
import { currentAppIdAtom, currentEnvIdAtom } from '@/atoms/application/state'
import { userDataAtom } from '@/atoms/auth/state'
import { recordAtomFamily } from '@/atoms/dataSource/state'
import { lastPageOfStackAtom, pageStackAtomFamily, pageStackOfFieldBlockAtom } from '@/atoms/page/state'
import { workflowListAtom } from '@/atoms/workflow/state'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import * as srv from '@/services'
import type { StripePaySearch, WechatPaySearch } from '@/services/types'

// import { openWechatAuthModal } from '@/utils'
import { useAccount } from './useAccount'
import { useActionPay } from './useActionPay'
import { useActionRuntimeParams } from './useActionRuntimeParams'
import { usePreview } from './useApplication'
import { useDataSourceList, useRecord } from './useDataSource'
import { usePageDataSourceForVariableSelector } from './usePage'
import { useUserRecord } from './useUserRecord'
import { sendWxRoot } from './useWxAuthRoot'

export const useActionTrigger = (useActionTriggerParams?: { list?: ButtonItem[]; record?: RecordLikeProtocol }) => {
    const workflows = useAtomData(workflowListAtom)
    const currentAppId = useAtomData(currentAppIdAtom)
    const envId = useAtomData(currentEnvIdAtom)
    const { pageId } = useCurrentPageContext()
    // 所有数据源
    const dataSourceList = useDataSourceList(currentAppId)
    // 用户数据源，当前用户数据
    const userRecord = useUserRecord()
    const user = useAccount()
    const { record: customViewRecord } = useCustomViewBlockContext()
    const previewType = usePreview()

    const navigate = useNavigate()
    const currentPage = useCurrentPageContext()
    const currentStackId = useCurrentStackIdContext()
    const pageStackAtom = pageStackAtomFamily({ stackId: currentStackId, pageId: currentPage?.pageId })

    const {  currentPageId } = useAtomData(
        pageStackAtom,
        useCallback(s => ({ currentPageId: s?.pageId ?? '' }), [])
    )
    const pageStackFormState = useAtomData(pageStackOfFieldBlockAtom, useCallback(s => s[currentStackId] ,[currentStackId]))
    const {
        curr: { recordId: currentRecordId, datasource: currentDataSource },
        prev: { recordId: prevRecordId, datasource: prevDataSource }
    } = usePageDataSourceForVariableSelector({ pageId: currentPageId, stackId: currentStackId })
    const pageRecord = useRecord(currentAppId, envId, currentDataSource?.id ?? '', currentRecordId ?? '')
    const prevRecord = useRecord(currentAppId, envId, prevDataSource?.id ?? '', prevRecordId ?? '')

    useActionPay()
    const actionRuntimeParams = useActionRuntimeParams()

    const sequenceSingleWorker = useCallback(
        (action: ButtonAction) => {
            const { type, data } = action
            if (type === 'flow') {
                const flowData = workflows.find(w => w.id === data.flow.flowId)
                if (flowData) {
                    actionWorker.sequenceWorker(flowData)
                }
            }
        },
        [workflows]
    )

    const getClickTriggerNodeParams = useAtomCallback(
        useCallback(
            (get, set, params?: { record?: RecordLikeProtocol; prevRecord?: RecordLikeProtocol; appId: string; dsId: string }) => {
                const currentAppId = get(currentAppIdAtom)
                const record = params?.record
                // 实现当前页面所在记录，也就是触发节点逻辑的记录
                const currentPageMeta = get(lastPageOfStackAtom)

                const appId = currentPageMeta?.appId ?? params?.appId ?? ''
                const dsId = currentPageMeta?.dsId ?? params?.dsId ?? ''
                const recordId = currentPageMeta?.recordId ?? ''

                const currentRecord = record ?? get(recordAtomFamily({ appId, envId, dsId, recordId }))

                // 获取当前用户
                const currentUserId = get(userDataAtom)?.userId || ANONYMOUS

                return {
                    clickTriggerNodeParams: {
                        record: currentRecord,
                        prevRecord: params?.prevRecord,
                        currentAppId,
                        currentEnvId: envId,
                        currentUserId,
                        currentPageId: currentPageMeta?.pageId ?? ''
                    }
                }
            },
            [envId]
        )
    )

    // 导航栏点击回调
    const handleActionTrigger = useCallback(
        async (
            action: ButtonAction,
            options?: {
                formRecord?: RecordLikeProtocol
                viewRecord?: RecordLikeProtocol
            }
        ): Promise<boolean | undefined> => {
            const invokeId = nanoid()
            const isFlowAction = action.type === 'flow'
            const isAutomationAction = action.type === 'automation'

            const { formRecord, viewRecord } = options ?? {}

            const initialRecord = formRecord || customViewRecord || viewRecord
            const usedViewRecord = customViewRecord || viewRecord

            const clickTriggerNodeDepsParams = initialRecord
                ? { record: initialRecord, prevRecord, appId: initialRecord.appId, envId: initialRecord.envId, dsId: initialRecord.dsId }
                : { prevRecord, appId: currentAppId, dsId: prevRecord?.dsId ?? '' }

            const clickTriggerNodeParams = getClickTriggerNodeParams(clickTriggerNodeDepsParams)

            const { record } = clickTriggerNodeParams.clickTriggerNodeParams

            if (isFlowAction) {
                const { data } = action
                const flowData = workflows.find(w => w.id === data.flow.flowId)

                // 必须要启用才可以执行
                if (!flowData?.enabled) {
                    return
                }
                if (!flowData) {
                    return
                }

                sequenceSingleWorker(action)

                actionWorker.injectRuntimeParams(invokeId, flowData, {
                    dataSourceList,
                    userRecord,
                    formRecord,
                    viewRecord: usedViewRecord,
                    pageRecord,
                    getCurrentPageLink: () => getWindowUrl(),
                    pageStackFormState,
                    nodes: flowData.content?.nodes ?? [],
                    ...actionRuntimeParams,
                    ...clickTriggerNodeParams
                })
                actionWorker.invoke(flowData.id, invokeId)
                return
            }
            if (isAutomationAction) {
                const { data } = action
                const flowData = workflows.find(w => w.id === data.automation.flowId)

                if (!flowData) {
                    return
                }

                // 必须要启用才可以执行
                if (!flowData?.enabled) {
                    Toast.error('执行失败，因为工作流未启用')
                    return
                }
                // 执行
                try {
                    const success = await srv.triggerAutomationAction({
                        flowId: flowData.id,
                        recordId: record?.id ?? '',
                        pageId
                    })
                    if (success) {
                        Toast.success('执行成功')
                    } else {
                        Toast.error('执行失败')
                    }
                } catch {
                    Toast.error('执行失败')
                }
                return
            }
            const { type, data } = action
            const dummyTriggerNodeParams = {
                userRecord,
                pageStackFormState,
                formRecord,
                viewRecord: usedViewRecord,
                ...clickTriggerNodeParams,
                [dummyTriggerNodeId]: {
                    record
                },
                pageRecord,
                getCurrentPageLink: () => getWindowUrl(),
                dataSourceList
            }
            switch (type) {
                case 'openPage': {
                    const params = data[type]
                    const isHaveNoneParam = !params.openPageUrl
                    if (isHaveNoneParam) {
                        return
                    }
                    const { openPageUrl, pageOpenType } = params
                    // const openType = params.pageOpenType
                    // const linkPage = find(pages => pages.id === pageId, pageList)

                    if (commonPages.includes(openPageUrl)) {
                        navigate({ pathname: openPageUrl })
                        break
                    }
                    const rId = getMainTableRecordId(record?.id)
                    actionRuntimeParams.actionEvents.openPage({
                        pageId: openPageUrl,
                        openType: pageOpenType,
                        appId: record?.appId,
                        dsId: record?.dsId,
                        recordId: rId
                        // onSaveCallback: () => {
                        //     actionRuntimeParams.actionEvents.refreshPage?.()
                        // }
                    })
                    break
                }
                case 'openLink': {
                    const params = data[type]
                    const linkUrl = params.link
                    if (!linkUrl) {
                        return
                    }
                    const url = generateVariableValue({ jsonContent: linkUrl, extraParams: dummyTriggerNodeParams })

                    if (!url) {
                        break
                    }

                    const realUrl = Array.isArray(url) ? '' : url
                    const isWithProtocol = realUrl.startsWith('http://') || realUrl.startsWith('https://')
                    const withProtocolUrl = isWithProtocol ? realUrl : `https://${realUrl}`
                    // window.open(withProtocolUrl, '_blank')
                    openNewBrowserPage(withProtocolUrl)
                    break
                }
                case 'notification': {
                    const params = data[type]
                    if (!params.notificationContent || !params.notificationStatus) {
                        return
                    }
                    const { notificationStatus, notificationContent } = params
                    const toastType = notificationStatus.toLocaleLowerCase() as 'info' | 'error' | 'success'
                    const variableValue = generateVariableValue({ jsonContent: notificationContent, extraParams: dummyTriggerNodeParams })
                    Message[toastType]({
                        title: variableValue,
                        device: previewType
                    })
                    break
                }
                case 'goBack': {
                    actionRuntimeParams.actionEvents.closePage()
                    break
                }
                case 'copy': {
                    const params = data[type]
                    const { copyText } = params
                    const text = generateVariableValue({ jsonContent: copyText, extraParams: dummyTriggerNodeParams })
                    if (text) {
                        copyToClipboard(Array.isArray(text) ? '' : text)
                        Toast.success('复制成功')
                    }
                    break
                }
                case 'createRecord': {
                    const params = data[type]
                    const { dataSourceId, fields } = params
                    const fieldsValue = getCreateRecordVariableValue({ dataSourceId, nodeId: '', fields }, dummyTriggerNodeParams)

                    const record = await srv.createRecordWithFields({
                        appId: currentAppId,
                        envId,
                        dsId: dataSourceId,
                        fields: fieldsValue,
                        pageId
                    })
                    Toast.success('数据创建成功')
                    break
                }
                case 'updateRecord': {
                    const params = data[type]
                    const updateRecordParams = params as UpdateRecordActionConfig
                    const { selectType } = updateRecordParams
                    const { fields } = getUpdateRecordVariableValue(params as UpdateRecordActionConfig, dummyTriggerNodeParams)
                    if (selectType !== 'CURRENT_RECORD') {
                        break
                    }
                    const dsId = record?.dsId
                    const recordId = record?.id ?? ''
                    if (!dsId) {
                        break
                    }

                    const newRecord = await actionRuntimeParams.actionEvents.updateRecord({
                        appId: currentAppId,
                        envId,
                        dsId,
                        recordId,
                        fields,
                        pageId
                    })

                    // 更新行以后，需要通知对应订阅更新数据
                    pageStackPubSub.emit(`${dsId}-ADD`, newRecord)

                    actionRuntimeParams.actionEvents.refreshPage?.()
                    Toast.success('数据更新成功')
                    break
                }
                case 'openFormPage': {
                    const params = data[type]
                    const { formPageId, pageOpenType } = params

                    if (!formPageId) {
                        break
                    }

                    actionRuntimeParams.actionEvents.openPage({
                        pageId: formPageId,
                        openType: pageOpenType,
                        appId: record?.appId,
                        dsId: record?.dsId,
                        recordId: record?.id,
                        onSaveCallback: () => {
                            actionRuntimeParams.actionEvents.refreshPage?.()
                        }
                    })

                    break
                }
                case 'openRecordPage': {
                    const params = data[type]
                    const { recordPageId, dataSourceId = '', filter, pageOpenType, selectedRecordType } = params

                    if (!recordPageId) {
                        return
                    }

                    let depRecord = { ...record, id: getMainTableRecordId(record?.id) }

                    if (selectedRecordType === 'MATCHED_RECORD' /*  && filter?.expression?.conditions?.length */) {
                        const resolvedFilter = resolveFilter({ filter, extraParams: dummyTriggerNodeParams })
                        // 兜底数据
                        const dsId = selectedRecordType === 'MATCHED_RECORD' ? dataSourceId : '' // 动作不会有 UPSTREAM_RECORD
                        depRecord = { ...record, id: getMainTableRecordId(record?.id), dsId, appId: currentAppId }

                        // 为了过滤掉空的条件，处理变量值找不到的场景
                        // if (resolvedFilter?.expression?.conditions?.length !== 0) {
                        const data = await srv.getDs({
                            appId: record?.appId ?? currentAppId,
                            envId: record?.envId ?? envId,
                            dsId,
                            pagination: { currentPage: 1, pageSize: 1 },
                            filter: resolvedFilter,
                            pageId
                        })
                        if (!data) {
                            return
                        }
                        const { records } = data

                        const resRecord = records[0]
                        if (resRecord) {
                            depRecord = resRecord
                        }
                        // }
                    }

                    actionRuntimeParams.actionEvents.openPage({
                        pageId: recordPageId,
                        openType: pageOpenType,
                        appId: depRecord?.appId,
                        dsId: depRecord?.dsId,
                        recordId: depRecord?.id,
                        onSaveCallback: () => {
                            actionRuntimeParams.actionEvents.refreshPage?.()
                        }
                    })

                    break
                }
                case 'openRecordEditPage': {
                    const params = data[type]
                    const { recordEditPageId, dataSourceId = '', filter, pageOpenType, selectedRecordType } = params

                    if (!recordEditPageId) {
                        return
                    }

                    let depRecord = { ...record, id: getMainTableRecordId(record?.id) }

                    if (selectedRecordType === 'MATCHED_RECORD' /*  && filter?.expression?.conditions?.length */) {
                        const resolvedFilter = resolveFilter({ filter, extraParams: dummyTriggerNodeParams })

                        // 兜底数据
                        const dsId = selectedRecordType === 'MATCHED_RECORD' ? dataSourceId : '' // 动作不会有 UPSTREAM_RECORD
                        depRecord = { ...record, id: getMainTableRecordId(record?.id), dsId, appId: currentAppId }

                        // 为了过滤掉空的条件，处理变量值找不到的场景
                        // if (resolvedFilter?.expression?.conditions?.length !== 0) {
                        const data = await srv.getDs({
                            appId: record?.appId ?? currentAppId,
                            envId: record?.envId ?? envId,
                            dsId,
                            pagination: { currentPage: 1, pageSize: 1 },
                            filter: resolvedFilter,
                            pageId
                        })

                        if (!data) {
                            return
                        }
                        const { records } = data

                        if (records[0]) {
                            depRecord = records[0]
                        }
                        // }
                    }

                    actionRuntimeParams.actionEvents.openPage({
                        pageId: recordEditPageId,
                        openType: pageOpenType,
                        appId: depRecord?.appId,
                        dsId: depRecord?.dsId,
                        recordId: depRecord?.id,
                        onSaveCallback: () => {
                            actionRuntimeParams.actionEvents.refreshPage?.()
                        }
                    })

                    break
                }
                case 'deleteRecord': {
                    const pageId = actionRuntimeParams.getCurrentPageDeps()?.pageId ?? ''
                    const manualDeps = record ? { appId: record.appId, dsId: record.dsId, pageId, recordId: record.id } : undefined
                    const isDeleted = actionRuntimeParams.actionEvents.deleteRecord(manualDeps)

                    if (!isDeleted) {
                        return
                    }

                    const currentPageStack = actionRuntimeParams.actionEvents.peekCurrentPageStack()
                    if (currentPageStack?.openType === 'drawer' || currentPageStack?.openType === 'modal') {
                        actionRuntimeParams.actionEvents.closeCurrentPageLayer()
                    }
                    break
                }
                case 'iClick': {
                    const params = data[type]
                    const addIClickUserParams = getActiveAddIClickUserVariableValue(params as IClickActionConfig, dummyTriggerNodeParams)

                    if (!addIClickUserParams) {
                        break
                    }

                    srv.activeAddIClickUser({ ...addIClickUserParams, pageId })

                    break
                }
                case 'downloadFile': {
                    const params = data[type]
                    const downloadFileParams = resolveDownloadFileUrls(params, dummyTriggerNodeParams)

                    if (downloadFileParams) {
                        downloadFile(downloadFileParams)
                    }

                    break
                }
                case 'call': {
                    const params = data[type]
                    const phone = resolveVariable(params.phone, dummyTriggerNodeParams)
                    if (typeof phone === 'string') {
                        const a = document.createElement('a')
                        a.href = `tel:${phone}`
                        a.click()
                        a.remove()
                    }

                    break
                }
                case 'wechatPay': {
                    const params = data[type]
                    const { record } = dummyTriggerNodeParams[dummyTriggerNodeId]
                    if (!record || !pageId) {
                        return
                    }
                    const { operationType, payConfig, refundConfig } = params
                    if (!user.userId) {
                        window.location.href = '/account/login'
                        return
                    }
                    const weChatPayType = getWechatPayType()
                    // 微信支付
                    if (operationType === 'PAY') {
                        if (weChatPayType === WechatPayType.JSAPI) {
                            const isPermissionGranted = await srv.checkWxOfficialRoot()
                            if (!isPermissionGranted) {
                                // openWechatAuthModal(() => {
                                sendWxRoot()
                                // })
                                return
                            }
                        }
                        const { amountFieldId, succeedFieldSettings, descFieldId, failedFieldSettings } = payConfig
                        if (!record) {
                            return
                        }
                        const res = await srv.invokeWechatPay({
                            dsId: record.dsId,
                            pageId,
                            payType: weChatPayType,
                            recordId: record.id,
                            amountFieldId,
                            descFieldId,
                            succeedFieldSettings: succeedFieldSettings.map(field => ({
                                ...field,
                                value: generateVariableValue({ jsonContent: field.value, extraParams: dummyTriggerNodeParams }) as AnyObject
                            })),
                            failedFieldSettings: failedFieldSettings.map(field => ({
                                ...field,
                                value: generateVariableValue({ jsonContent: field.value, extraParams: dummyTriggerNodeParams }) as AnyObject
                            }))
                        })
                        if (!res) {
                            break
                        }
                        const { outTradeNo, jsApiResponse, nativeResponse } = res
                        // const regArr = window.location.pathname.match(PAGE_URL_REG)
                        // const pageUrl = !regArr || regArr.length === 1 ? window.location.pathname : `/${regArr.slice(0, -1).join('/')}`
                        // 表单使用数据时计算关闭当前页面后的路径
                        // const redirect = isFormAction ? `${window.location.origin}${pageUrl}` : window.location.href
                        const redirect = window.location.href
                        switch (weChatPayType) {
                            case WechatPayType.H5: {
                                break
                            }
                            case WechatPayType.JSAPI: {
                                const { appId, timeStamp, nonceStr, paySign, packageVal, signType } = jsApiResponse
                                const newSearch: WechatPaySearch = {
                                    appId,
                                    timeStamp: timeStamp.toString(),
                                    nonceStr,
                                    paySign,
                                    packageVal,
                                    signType,
                                    pageId,
                                    outTradeNo,
                                    redirect: encodeURIComponent(redirect)
                                }
                                const search = getSearchParams(newSearch)
                                navigate(`/pay/?${search}`)
                                break
                            }
                            case WechatPayType.NATIVE: {
                                const { codeUrl } = nativeResponse
                                const newSearch: WechatPaySearch = {
                                    pageId,
                                    outTradeNo,
                                    codeUrl,
                                    redirect: encodeURIComponent(redirect)
                                }
                                const search = getSearchParams(newSearch)
                                navigate(`/pay/?${search}`)
                                break
                            }
                            default: {
                                break
                            }
                        }
                    }
                    // 退款流程
                    if (operationType === 'REFUND') {
                        const { outTradeNoFieldId, succeedFieldSettings, failedFieldSettings, refundAmountFieldId, reasonFieldId } =
                            refundConfig
                        const isEmpty = Object.entries(refundConfig).some((key, v) => {
                            return typeof v === 'string' ? v === '' : false
                        })
                        if (isEmpty || !record) {
                            return
                        }
                        await srv.activeWechatRefund({
                            dsId: record.dsId,
                            pageId,
                            recordId: record.id,
                            outTradeNoFieldId,
                            refundAmountFieldId,
                            reasonFieldId,
                            succeedFieldSettings: succeedFieldSettings.map(field => ({
                                ...field,
                                value: generateVariableValue({ jsonContent: field.value, extraParams: dummyTriggerNodeParams }) as AnyObject
                            })),
                            failedFieldSettings: failedFieldSettings.map(field => ({
                                ...field,
                                value: generateVariableValue({ jsonContent: field.value, extraParams: dummyTriggerNodeParams }) as AnyObject
                            }))
                        })
                        const res = await srv.getRecord({
                            appId: record.appId,
                            envId: record.envId,
                            dsId: record.dsId,
                            recordId: record.id,
                            pageId
                        })
                        if (res) {
                            const { record, dataSource } = res
                            pageStackPubSub.emit(`${record.dsId}-ADD`, record)
                            return true
                        }
                    }

                    break
                }
                case 'wechatTemplateMsg': {
                    const params = data[type]
                    const { templateId, personList, values, url } = params

                    const personListValues = personList.map(person => generateAdtValue(person, dummyTriggerNodeParams))
                    const userIds = [...new Set(personListValues)]
                    const valuesValues = values.map(value => ({
                        variableName: value.variableName,
                        value: generateVariableValue({
                            jsonContent: value.value,
                            extraParams: dummyTriggerNodeParams,
                            isFormat: true
                        }) as string
                    }))
                    const urlValue = generateVariableValue({ jsonContent: url, extraParams: dummyTriggerNodeParams }) as string
                    await srv.wxTemplateMessageAction({
                        userIds,
                        templateId,
                        url: urlValue,
                        values: valuesValues,
                        pageId
                    })
                    break
                }
                case 'stripe': {
                    const config = data[type]
                    const { record } = dummyTriggerNodeParams[dummyTriggerNodeId]
                    if (!record || !pageId) {
                        return
                    }
                    if (!user.userId) {
                        window.location.href = '/account/login'
                        return
                    }
                    const { payConfig } = config
                    // Stripe支付
                    const {
                        amountFieldId,
                        succeedFieldSettings,
                        descFieldId,
                        failedFieldSettings,
                        nodeId = '',
                        productNameFieldId,
                        productPictureFieldId,
                        currency
                    } = payConfig
                    if (!record) {
                        return
                    }
                    const res = await srv.activeStripePay({
                        dsId: record.dsId,
                        recordId: record.id,
                        pageId,
                        amountFieldId,
                        descFieldId,
                        currency,
                        productPictureFieldId,
                        productNameFieldId,
                        succeedFieldSettings: succeedFieldSettings.map(field => ({
                            ...field,
                            value: generateVariableValue({ jsonContent: field.value, extraParams: dummyTriggerNodeParams }) as AnyObject
                        })),
                        failedFieldSettings: failedFieldSettings.map(field => ({
                            ...field,
                            value: generateVariableValue({ jsonContent: field.value, extraParams: dummyTriggerNodeParams }) as AnyObject
                        }))
                    })
                    if (!res.outTradeNo) {
                        Toast.error(`获取Stripe支付订单异常`)
                        return
                    }
                    const redirectUrl = window.location.href
                    const { expiresAt, outTradeNo, clientSecret } = res
                    const newSearch: StripePaySearch = {
                        clientSecret,
                        outTradeNo,
                        expiresAt,
                        pageId,
                        redirect: encodeURIComponent(redirectUrl)
                    }
                    const search = getSearchParams(newSearch)
                    navigate(`/pay/stripe?${search}`)
                    break
                }
                case 'refreshPage': {
                    window.location.reload()
                    break
                }
                case 'aliyunMessage': {
                    const params = data[type]
                    const { id, mobileList, signatureId, templateId, values } = params
                    const mobileListValue = (resolveVariable(mobileList, dummyTriggerNodeParams) ?? '') as string
                    const valuesListValue = values.map(value => ({
                        variableName: value.variableName,
                        value: generateVariableValue({ jsonContent: value.value, extraParams: dummyTriggerNodeParams }) as string
                    }))

                    const payload = {
                        id,
                        pageId,
                        mobileList: mobileListValue,
                        signatureId,
                        templateId,
                        values: valuesListValue
                    }

                    await srv.activeAliyunMessageAction(payload)
                    break
                }
                case 'scrollTo': {
                    const params = data[type]
                    const { scrollToElementId } = params
                    scroll2FlowNode(scrollToElementId)
                    break
                }
                case 'control': {
                    const params = data[type]
                    const { controlElementId, controlElementType, controlAction } = params

                    controlAction?.type &&
                        blockListenerSystem.emit(controlElementId, controlElementType, {
                            event: controlAction.type,
                            params: 'params' in controlAction ? controlAction.params : undefined
                        })

                    break
                }
                default: {
                    break
                }
            }
        },
        [
            actionRuntimeParams,
            currentAppId,
            customViewRecord,
            dataSourceList,
            envId,
            getClickTriggerNodeParams,
            navigate,
            pageId,
            pageRecord,
            pageStackFormState,
            prevRecord,
            previewType,
            sequenceSingleWorker,
            user.userId,
            userRecord,
            workflows
        ]
    )

    return {
        handleActionTrigger
    }
}
