import type { FieldBlockWithDsId, FieldValue, TypeInstanceMap } from '@lighthouse/core'
import {
    type DataSourceAbstract,
    type Field,
    type FieldADTValue,
    type FieldCellValue,
    type FieldType,
    type VariableADTField,
    DataSourceType,
    VariableType
} from '@lighthouse/core'
import { find, reduce } from 'rambda'

import { isEmptyRichTextValue } from '../components/RichText/helper'
import {
    aiFieldType,
    FieldIconTypeMap,
    FieldNameMap,
    FieldNameMapByInnerType,
    innerTypeToFieldTypeIconMap,
    SYSTEM_FIELD,
    SystemFieldIconTypeMap
} from '../constants'
import type { FlowNode } from '../types'
import { getPrimaryDataSourceEnableFieldIds } from './dataSource'
import { getNodeDataSourceId } from './flow'
import { isArrayCellValue, isIdsValue, isNumberValue, isTextValue } from './helper'

export const getFieldIcon = (id: string, type: FieldType, innerType?: TypeInstanceMap, isFormula?: boolean) => {
    const list = id?.split('__') || ['']
    const fieldId = list[list.length - 1]
    if (SYSTEM_FIELD.has(fieldId)) {
        return SystemFieldIconTypeMap[fieldId]
    }
    if (type === 'aggregation') {
        return isFormula ? FieldIconTypeMap['formula'] : innerTypeToFieldTypeIconMap[innerType || 'NULL']
    }
    {
        return FieldIconTypeMap?.[type] || ''
    }
}

export const getFieldName = (type: FieldType, innerType: TypeInstanceMap | undefined, isFormula?: boolean) => {
    if (type === 'aggregation') {
        if (isFormula) {
            return FieldNameMap['formula']
        }
        return FieldNameMapByInnerType[innerType || 'NULL']
    }
    return FieldNameMap[type]
}

export const getRealFieldByInnerType: (field: Field, innerTypeMap: Record<TypeInstanceMap, FieldType | undefined>) => Field = (
    field,
    innerTypeMap
) => {
    if (field.type === 'formula' && field.innerType) {
        const type = innerTypeMap[field.innerType]
        if (!type) {
            return field
        }
        return {
            id: field.id,
            type,
            dsId: field.dsId,
            [type]: {},
            innerType: field.innerType,
            name: field.name
        } as Field
    }
    return field
}

export const getRealField = (field: Field) => {
    return field
}

export const getIsJoinField = (field: Field) => {
    return !!field.join?.joinDsId
}

export function getInputVariableField(blockId: string, dataSourceList: DataSourceAbstract[], fieldBlocksWithDsId?: FieldBlockWithDsId[]) {
    // const blockId = idVariable.inputVariable?.blockId || ''
    const fieldBlock = find(item => item.id === blockId, fieldBlocksWithDsId || [])
    const dsId = fieldBlock?.dsId
    const fieldId = fieldBlock?.config?.fieldPointer || ''
    const ds = find(item => item.id === dsId, dataSourceList)
    const field = ds?.schema[fieldId]
    if (!field) {
        return undefined
    }
    return field
}

export function getIdVariableData(params: {
    idVariable: VariableADTField
    dataSourceList: DataSourceAbstract[]
    sourceFlowNode?: FlowNode[]
    dataSource?: DataSourceAbstract
    fieldBlocksWithDsId?: FieldBlockWithDsId[]
    viewDataSource?: DataSourceAbstract
}): Field | undefined {
    const { idVariable, dataSourceList, sourceFlowNode = [], dataSource, viewDataSource, fieldBlocksWithDsId } = params
    if (idVariable.type === VariableType.SYSTEM) {
        return undefined
    }

    if (idVariable.type === VariableType.INPUT) {
        return getInputVariableField(idVariable.inputVariable?.blockId || '', dataSourceList, fieldBlocksWithDsId)
    }

    if (idVariable.type === VariableType.USER) {
        const fieldId = idVariable.userVariable?.fieldId || ''
        const ds = find(item => item.id === 'USER_DATASOURCE', dataSourceList)
        const field = ds?.schema[fieldId]
        if (!field) {
            return undefined
        }
        return field
    }

    if (idVariable.type === VariableType.VIEW) {
        const fieldId = idVariable.viewVariable?.fieldId || ''
        const ds = find(item => item.id === viewDataSource?.id, dataSourceList)
        const field = ds?.schema[fieldId]
        if (!field) {
            return undefined
        }
        return field
    }

    if (idVariable.type === VariableType.VALUE) {
        if (idVariable.valueVariable?.value === '{currentUserId}') {
            return {
                id: '{currentUserId}',
                type: 'person',
                innerType: 'ARRAY'
            } as Field
        }
        return undefined
    }
    if (idVariable.type === VariableType.VARIABLE) {
        const dataSourceId = idVariable.fieldVariable?.dsId
        const fieldId = idVariable.fieldVariable?.fieldId || ''
        const ds = find(item => item.id === dataSourceId, dataSourceList)
        const field = ds?.schema[fieldId]
        if (!field) {
            return undefined
        }
        return field
    }
    if (idVariable.type === VariableType.PAGE) {
        const dataSourceId = idVariable.pageVariable?.dsId
        const fieldId = idVariable.pageVariable?.fieldId || ''
        const ds = find(item => item.id === dataSourceId, dataSourceList)
        const field = ds?.schema[fieldId]
        if (!field) {
            return undefined
        }
        return field
    }
    if (idVariable.type === VariableType.FIELD_ID) {
        const fieldId = idVariable.fieldIdVariable?.fieldId || ''
        const field = dataSource?.schema[fieldId]
        if (!field) {
            return undefined
        }
        return field
    }

    if (idVariable.type === VariableType.UPSTREAM) {
        const nodeId = idVariable.upstreamVariable?.nodeId
        const fieldId = idVariable.upstreamVariable?.fieldId || ''
        const node = find(item => item.id === nodeId, sourceFlowNode || [])
        if (!node) {
            return undefined
        }
        const dataSourceId = getNodeDataSourceId(node, sourceFlowNode)
        // if (!node || !node.data.config || !('dataSourceId' in node.data.config)) {
        //     return undefined
        // }
        // const { dataSourceId } = node.data.config
        const ds = find(item => item.id === dataSourceId, dataSourceList)
        const field = ds?.schema[fieldId]
        if (!field) {
            return undefined
        }
        return field
    }

    // const fieldId =
    return undefined
}

export const isEmptyCellValue = function (cellValue: FieldADTValue): boolean {
    const { type, value, innerType } = cellValue ?? {}

    if (!type) {
        return true
    }
    switch (cellValue.type) {
        case 'textGeneration':
        case 'number':
        case 'text':
        case 'url':
        case 'phoneNumber':
        case 'email':
        case 'id':
        case 'date': {
            return value === '' || value === undefined
        }
        case 'userDepartment':
        case 'selectGenerationByText':
        case 'select':
        case 'person':
        case 'userGroup':
        case 'video':
        case 'file': {
            return (cellValue?.value ?? []).length === 0
        }
        case 'notes': {
            return isEmptyRichTextValue(cellValue?.value)
        }
        case 'formula': {
            return isArrayCellValue(cellValue?.value) ? cellValue?.value.length === 0 : String(cellValue?.value).length === 0
        }
        case 'aggregation': {
            // 关联字段值合并之后，如果是数组则直接判断数组长度，否则按常规值处理
            if (Array.isArray(value)) {
                return value.length === 0
            }
            if (innerType === 'TEXT' || innerType === 'NUMBER') {
                return value === '' || value === undefined
            }
            return true
        }
        case 'checkbox': {
            return !value
        }
        default: {
            return true
        }
    }
}

export const getFieldValue = (type: FieldType, value: FieldCellValue): FieldValue => {
    switch (type) {
        case 'textGeneration':
        case 'text':
        case 'url':
        case 'phoneNumber':
        case 'email':
        case 'id': {
            return {
                value: isTextValue(value) ? value : ''
            }
        }
        case 'number':
        case 'date': {
            return {
                value: isNumberValue(value) ? value : ''
            }
        }

        case 'selectGenerationByText':
        case 'select':
        case 'person': {
            return {
                value: isIdsValue(value) ? value : []
            }
        }
        case 'aggregation': {
            return { value }
        }

        default: {
            return {
                value: ''
            }
        }
    }
    // return { value }
}

export const getEmptyCellValue = function (type: FieldType): FieldCellValue {
    switch (type) {
        case 'textGeneration':
        case 'number':
        case 'text':
        case 'url':
        case 'phoneNumber':
        case 'email':
        case 'id':
        case 'date': {
            return ''
        }
        case 'selectGenerationByText':
        case 'select':
        case 'person':
        case 'userGroup':
        case 'video':
        case 'file': {
            return []
        }
        case 'aggregation':
        case 'notes': {
            return ''
        }
        default: {
            return ''
        }
    }
}

export const getAppointField = (dataSource: DataSourceAbstract, initFieldId: string) => {
    const { schema, viewOptions } = dataSource
    if (dataSource.type !== DataSourceType.joinDataSource) {
        return schema[initFieldId]
    }
    const primaryDsId = viewOptions.joinConfig?.primaryDsId
    const columns = Object.values(schema)
    return find(item => {
        const list = item.id.split('__')
        const fieldId = list[list.length - 1]
        return item.dsId === primaryDsId && fieldId === initFieldId
    }, columns)
}

// 获取指定id字段是否在指定字段列表中， 注： 连接表的字段id会进行拼接 dsId-fieldId
export const getIsAppointField = (id: string | undefined, fieldSet: Set<string>) => {
    const list = id?.split('__') || ['']
    const fieldId = list[list.length - 1]
    return fieldSet.has(fieldId)
}

export const getRealFieldId = (id: string): string | undefined => {
    const list = id?.split('__') || [undefined]
    return list[list.length - 1]
}

export const getFieldActiveColor = (params: { field: Field; dataSource: DataSourceAbstract; dataSourceList: DataSourceAbstract[] }) => {
    const { field, dataSource, dataSourceList } = params
    const isSystemField = getIsAppointField(field.id, SYSTEM_FIELD)
    // const mainFieldIds = getPrimaryDataSourceEnableFieldIds(dataSource, dataSourceList)
    // if (isSystemField || mainFieldIds.has(field.id) || field.type === 'formula') {
        if (isSystemField || field.type === 'formula') {
        return 'var(--color-blue-500)'
    }

    if (aiFieldType.has(field.type)) {
        return 'var(--color-pink-500)'
    }

    return 'var(--color-gray-500)'
}

export const getFieldActiveCellColor = (params: { field: Field; dataSource: DataSourceAbstract; dataSourceList: DataSourceAbstract[] }) => {
    const { field, dataSource, dataSourceList } = params
    const isSystemField = getIsAppointField(field.id, SYSTEM_FIELD)
    // const mainFieldIds = getPrimaryDataSourceEnableFieldIds(dataSource, dataSourceList)
    // if (isSystemField || !mainFieldIds.has(field.id) || field.type === 'formula') {
    if (isSystemField || field.type === 'formula') {
        return 'var(--color-blue-50)'
    }

    if (aiFieldType.has(field.type)) {
        return 'var(--color-pink-50)'
    }

    return 'var(--color-gray-50)'
}

export interface FieldOption {
    label: string
    icon: string
    type: FieldType
    value: string
    innerType?: TypeInstanceMap
}

export const getFieldOptions = function (dataSource?: DataSourceAbstract, filter?: (field: Field) => boolean) {
    if (!dataSource) {
        return []
    }
    const {
        viewOptions: { tableProps },
        schema
    } = dataSource

    const newFieldIds = Object.entries(schema).reduce(
        (preVal, [fieldId]) => (find(item => item === fieldId, preVal) ? preVal : [...preVal, fieldId]),
        [...tableProps.map(item => item.id)]
    )
    return reduce<string, FieldOption[]>(
        (preVal, id) => {
            const field = schema[id]
            return field && (filter ? filter?.(field) : true)
                ? [
                    ...preVal,
                    {
                        label: field?.name,
                        value: id,
                        icon: getFieldIcon(field?.id, field?.type, field.innerType),
                        type: field?.type,
                        innerType: field?.innerType
                    }
                ]
                : preVal
        },
        [],
        newFieldIds
    )
}
