export function initBaiduTracker(siteId: string) {
    console.log('🚀 ~ baidu track init successfully')
    // @ts-expect-error
    window._hmt = window._hmt || []

    const hm = document.createElement('script')
    hm.src = `https://hm.baidu.com/hm.js?${siteId}`
    hm.defer = true
    document.body.append(hm)
}
