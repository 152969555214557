import type {
    AiFieldStatus,
    BaseFieldConfig,
    ButtonAction,
    GalleryViewOptions,
    HighLightConditions,
    ObjectFitConfig,
    RatioConfig,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SchemaProtocol,
    ViewField,
    ViewOptions
} from '@lighthouse/core'
import type { atomWithImmer } from 'jotai-immer'
import type { ReactNode } from 'react'
import React from 'react'
import styled from 'styled-components'

import { GallerySpacing } from './constants'
import { GalleryRecord } from './GalleryRecord'

const Root = styled.div`
    display: grid;
`

interface VerticalViewProps {
    cols: number
    records: RecordLikeProtocol[]
    canViewRecord?: boolean
    headCover?: Omit<BaseFieldConfig, 'align'> & ObjectFitConfig & RatioConfig
    schema: SchemaProtocol['schema']
    columns: ViewField[]
    rowStyleConditions: Record<string, HighLightConditions>
    selectedRecords: string[]
    recordEditOpenable: boolean
    recordDeleteAble: boolean
    galleryItemCheckable?: boolean
    headAvatar: GalleryViewOptions['headAvatar']
    headTitle?: BaseFieldConfig
    headTags: GalleryViewOptions['headTags']
    contentTags: GalleryViewOptions['contentTags']
    footTags: GalleryViewOptions['footTags']
    recordStyle: GalleryViewOptions['style']
    actions: ViewOptions['actions']
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    renderItem?: (record: RecordLikeProtocol) => ReactNode
    onClickItem?: (recordId: string) => void
    onSelectRecord?: (checked: boolean, id: string) => void
    onRecordEdit: (recordId: string) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (params: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (params: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
}

export const VerticalView = (props: VerticalViewProps) => {
    const {
        cols,
        records,
        canViewRecord,
        headCover,
        schema,
        columns,
        recordDeleteAble,
        recordEditOpenable,
        recordStyle,
        rowStyleConditions,
        renderItem,
        selectedRecords,
        contentTags,
        onRecordDelete,
        onRecordEdit,
        onRenderButtonTitle,
        onAiGeneration,
        onClickItem,
        onRecordClickedActionTrigger,
        onRecordOperatorActionTrigger,
        onSelectRecord,
        headAvatar,
        headTags,
        headTitle,
        actions,
        aiFieldStatusListAtom,
        footTags,
        galleryItemCheckable
    } = props

    return (
        <Root style={{ gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`, gap: GallerySpacing }}>
            {records.map(record => (
                <GalleryRecord
                    key={record.id}
                    record={record}
                    canViewRecord={canViewRecord}
                    headCover={headCover}
                    schema={schema}
                    columns={columns}
                    recordEditOpenable={recordEditOpenable}
                    recordDeleteAble={recordDeleteAble}
                    rowStyleConditions={rowStyleConditions}
                    selectedRecords={selectedRecords}
                    galleryItemCheckable={galleryItemCheckable}
                    headAvatar={headAvatar}
                    headTitle={headTitle}
                    headTags={headTags}
                    contentTags={contentTags}
                    footTags={footTags}
                    recordStyle={recordStyle}
                    aiFieldStatusListAtom={aiFieldStatusListAtom}
                    actions={actions}
                    renderItem={renderItem}
                    onClickItem={onClickItem}
                    onRecordEdit={onRecordEdit}
                    onRecordDelete={onRecordDelete}
                    onSelectRecord={onSelectRecord}
                    onAiGeneration={onAiGeneration}
                    onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
                    onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                    onRenderButtonTitle={onRenderButtonTitle}
                />
            ))}
        </Root>
    )
}
