import { singleTextEllipsis } from '@byecode/ui'
import type { FieldProtocol, HighLightConditions, SimpleTextValue } from '@lighthouse/core'
import { mergeRefs, nanoid } from '@lighthouse/tools'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { CellHighlighter } from '../Highlighter'
import { TooltipList, useWidth } from '../TooltipList'
import { TooltipText } from '../TooltipText'

const UserGroupItemWrapper = styled.div<{ isLast?: boolean }>`
    max-width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    line-height: 20px;
    align-items: flex-start;
    margin-right: 6px;
    ${({ isLast }) => isLast && singleTextEllipsis}

    &:nth-last-of-type(1) {
        margin-right: 0;
    }
`

const UserGroupTextAvatar = styled.span`
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    font-size: 12px;
    margin-top: 2px;
    margin-right: 4px;
    border-radius: 6px;
    background-color: var(--color-gray-100);
    border: 1px solid var(--color-gray-200);
`

const SCxUserGroupListWrapper = styled.div<{ isWrap: boolean; isPadding?: boolean }>`
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: ${({ isPadding }) => (isPadding ? '20px' : '0')};
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
    overflow: hidden;
`

const UserGroupName = styled.span`
    margin-top: 2px;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

interface UserGroupItemPreviewerProps {
    children?: React.ReactNode
    id: string
    data: SimpleTextValue
    isLast?: boolean
    isWrap: boolean
    disableTooltip?: boolean
}

interface UserGroupListPreviewerProps {
    data: SimpleTextValue[]
    width: number
    isWrap: boolean
}

interface UserGroupPreviewerProps {
    field: FieldProtocol
    children?: React.ReactNode
    width?: number
    data?: SimpleTextValue | SimpleTextValue[]
    isWrap?: boolean
    styleConditions?: HighLightConditions
}

export const UserGroupItemPreviewer: React.FC<UserGroupItemPreviewerProps> = ({ id, data, isLast, isWrap, disableTooltip }) => {
    const { ref: widthRef } = useWidth<HTMLDivElement>(id)
    const avatarStyles = { width: 20, height: 20 }
    const UserGroupNameStyles = { fontSize: 'var(--font-size-normal)' }

    return (
        <TooltipText
            title={data}
            disabled={isWrap || disableTooltip}
            render={ref => {
                return (
                    <UserGroupItemWrapper ref={mergeRefs([ref, widthRef])} isLast={isLast}>
                        <UserGroupTextAvatar style={avatarStyles}>{data.charAt(0)}</UserGroupTextAvatar>
                        <UserGroupName style={UserGroupNameStyles}>{data}</UserGroupName>
                    </UserGroupItemWrapper>
                )
            }}
        />
    )
}

export const UserGroupListPreviewer: React.FC<UserGroupListPreviewerProps> = ({ data, width, isWrap }) => {
    return (
        <TooltipList
            data={data.map((label, index) => ({ label, value: String(index) }))}
            width={width}
            key={data.join('-')}
            disabled={isWrap}
            render={visibleNum => {
                const renderList = data.slice(0, visibleNum)

                return (
                    <SCxUserGroupListWrapper isPadding={visibleNum < data.length} isWrap={isWrap}>
                        {renderList.map((item, index) => (
                            <UserGroupItemPreviewer
                                id={item}
                                key={index}
                                data={item}
                                isWrap={isWrap}
                                disableTooltip
                                isLast={index === renderList.length - 1}
                            />
                        ))}
                    </SCxUserGroupListWrapper>
                )
            }}
        />
    )
}

export const UserGroupValuePreviewer: React.FC<UserGroupPreviewerProps> = ({
    field,
    data = '',
    width = 160,
    isWrap = true,
    styleConditions
}) => {
    const { id, type } = field

    const value = useMemo(() => {
        if (Array.isArray(data)) {
            return data.map(String)
        }
        return String(data)
    }, [data])

    const content = useMemo(() => {
        if (!data) {
            return ''
        }
        if (Array.isArray(data)) {
            return <UserGroupListPreviewer data={data} width={width} isWrap={isWrap} />
        }
        const id = nanoid()
        return <UserGroupItemPreviewer id={id} isWrap={isWrap} data={data} isLast />
    }, [data, isWrap, width])

    return (
        <>
            <CellHighlighter fieldId={id} type={type} value={value} conditions={styleConditions} />
            {content}
        </>
    )
}
