import type { DataSourceAbstract, Field } from '@lighthouse/core'
import { DataSourceType } from '@lighthouse/core'
import type { AnyObject } from 'immer/dist/internal'
import React, { useCallback, useMemo } from 'react'

import { SYSTEM_FIELD, SYSTEM_ID } from '../../../constants'
import { getIsAppointField, getIsJoinField } from '../../../utils'
import type { InsertDirection } from '../types'
import { ConfiguratorOpItems } from './constants'
import * as SC from './styles'

export interface TableFieldConfiguratorProps {
    children?: React.ReactNode
    field: Field
    dataSource: DataSourceAbstract
    disableFindUse?: boolean
    onSwitchEditMode?: () => void
    onDeleteField?: () => Promise<boolean>
    onInsertField?: (sourceId: string, direction: InsertDirection) => void
    onFindUse?: (fieldId: string) => void
    onClose?: () => void
}

export const TableFieldConfigurator: React.FC<TableFieldConfiguratorProps> = ({
    field,
    dataSource,
    disableFindUse,
    onSwitchEditMode,
    onDeleteField,
    onInsertField,
    onFindUse,
    onClose
}) => {
    const { id: dsId, type: dataSourceType, viewOptions } = dataSource
    const { id: fieldId, type, join, dsId: fieldDsId } = field

    const allowArea = useMemo(() => {
        if (dataSourceType === DataSourceType.aggregateDataSource) {
            return ['query', 'column-op']
        }

        // 连接表自身的公式字段才能编辑和删除
        if (dataSourceType === DataSourceType.joinDataSource) {
            // const primaryDsId = viewOptions.joinConfig?.primaryDsId
            // const fieldJoinDsId = join?.joinDsId
            if (!fieldId) {
                return ['query', 'column-op', 'column-setting']
            }
            const isJoinField = getIsJoinField(field)
            if (isJoinField) {
                return ['query', 'column-op', 'column-setting']
            }
            return ['edit', 'query', 'column-op', 'column-setting']
        }

        if (getIsAppointField(fieldId, SYSTEM_ID)) {
            return ['edit', 'query', 'column-op']
        }

        // 系统字段/聚合字段 不能编辑 不能删除
        if (getIsAppointField(fieldId, SYSTEM_FIELD) || type === 'aggregation') {
            return ['query', 'column-op']
        }

        return ['edit', 'query', 'column-op', 'column-setting']
    }, [dataSourceType, field, fieldId, type])

    // const closePopover = useCallback(() => {
    //     onClose?.()
    // }, [onClose])

    const handler: AnyObject = useMemo(
        () => ({
            // edit
            edit() {
                onSwitchEditMode?.()
            },
            // query
            _sort(order: 'ASC' | 'DESC') {
                onClose?.()
            },
            // 筛选
            filter() {
                onClose?.()
            },
            // 从 A -> Z
            sortASC() {
                onClose?.()
            },
            // 从 Z -> A
            sortDESC() {
                onClose?.()
            },
            // op
            _insert() {
                onClose?.()
            },
            insertLeft() {
                onInsertField?.(fieldId, 'left')
            },
            insertRight() {
                onInsertField?.(fieldId, 'right')
            },
            findUse() {
                onFindUse?.(fieldId)
            },
            // setting
            hide() {
                onClose?.()
            },
            duplicate() {
                onClose?.()
            },
            delete() {
                onDeleteField?.()
                onClose?.()
            }
        }),
        [fieldId, onClose, onDeleteField, onFindUse, onInsertField, onSwitchEditMode]
    )
    const handleItemClick = useCallback(
        (actionName: string) => {
            handler[actionName](1)
        },
        [handler]
    )

    const renderItemSuffix = useCallback((name: string) => {
        switch (name) {
            case 'edit': {
                return (
                    <SC.ItemExtraWrapper>
                        <SC.EditGuideText>名称和类型</SC.EditGuideText>
                        <SC.Arrow type="ArrowRightSmall" />
                    </SC.ItemExtraWrapper>
                )
            }
            case 'wrap': {
                return (
                    <SC.ItemExtraWrapper>
                        <SC.WrapSwitcher />
                    </SC.ItemExtraWrapper>
                )
            }
            default: {
                return null
            }
        }
    }, [])

    // const menuOptions = ConfiguratorOpItems.filter(({ area }) => {
    //     return allowArea.includes(area)
    // })
    const menuOptions = ConfiguratorOpItems.reduce<typeof ConfiguratorOpItems>((prev, cur) => {
        if (allowArea.includes(cur.area)) {
            const list = cur.items.reduce<typeof cur.items>((l, item) => {
                if (item.name === 'findUse' && disableFindUse) {
                    return l
                }
                l.push(item)
                return l
            }, [])
            prev.push({
                area: cur.area,
                items: list
            })
        }
        return prev
    }, [])

    const list = menuOptions.map(({ area, items }, index, areas) => {
        const isTail = index === areas.length - 1
        return (
            <SC.ListArea key={area}>
                {items.map(({ name, icon, value }) => (
                    <SC.ListItem
                        key={name}
                        onClick={() => {
                            handleItemClick(name)
                        }}
                    >
                        <SC.ItemIcon type={icon} />
                        <SC.ListItemText>{value}</SC.ListItemText>
                        {renderItemSuffix(name)}
                    </SC.ListItem>
                ))}
                {/* {!isTail && area === 'edit' && <SC.ItemDivider />} */}
                {!isTail && items.length > 0 && <SC.ItemDivider />}
            </SC.ListArea>
        )
    })

    return <SC.TableFieldConfiguratorContainer>{list}</SC.TableFieldConfiguratorContainer>
}
