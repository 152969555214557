import type { AiFieldStatus, Field, FieldCellValue } from '@lighthouse/core'
import { useApplicationContext, ViewFieldStatusPreviewer } from '@lighthouse/shared'
import { Modal } from '@mantine/core'
import type { atomWithImmer } from 'jotai-immer'
import { usePopupState } from 'material-ui-popup-state/hooks'
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export interface ListCellProps {
    dsId: string
    recordId: string
    field: Field
    data?: FieldCellValue
    size?: number
    isColumn?: boolean
    isBold?: boolean
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    onAiGeneration?: (fieldId: string) => Promise<boolean>
}

export const SCxListCell = styled.div<{ size?: number }>`
    position: relative;
    width: 100%;
    flex-shrink: 0;
    font-size: var(--font-size-normal);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 8px;

    & div {
        font-size: ${({ size }) => (size ? `${size}px` : 'unset')};
    }
`

export const SCxListCellPopperAnchor = styled.div`
    position: absolute;
    left: -2px;
    top: -2px;
`

export const SCxListCellEditorModal = styled(Modal)``

export const SCxListCellEditorModalOverlay = styled.div`
    outline: none;
    user-select: none;
`

export const ListValue: React.FC<ListCellProps> = ({
    dsId,
    recordId,
    field,
    data,
    size,
    isColumn,
    isBold,
    aiFieldStatusListAtom,
    onAiGeneration
}) => {
    const listCellRef = useRef<HTMLDivElement>(null)
    const [width, setWidth] = useState(0)
    const { personOptions } = useApplicationContext()

    const popperAnchorRef = useRef<HTMLDivElement>(null)
    const popperState = usePopupState({
        variant: 'popper',
        popupId: 'ListCellEditorPopup'
    })

    useLayoutEffect(() => {
        if (!listCellRef.current) {
            return
        }
        const { width } = listCellRef.current.getBoundingClientRect()
        setWidth(width)
    }, [isColumn])

    const handleOpen = useCallback(() => {
        popperState.open(popperAnchorRef.current)
    }, [popperState])

    return (
        <SCxListCell onDoubleClick={handleOpen} size={size} ref={listCellRef}>
            <ViewFieldStatusPreviewer
                dsId={dsId}
                recordId={recordId}
                width={isColumn ? width : undefined}
                personOptions={personOptions}
                field={field}
                disabled
                isBold={isBold}
                data={data}
                isWrap={false}
                aiFieldStatusListAtom={aiFieldStatusListAtom}
                onAiGeneration={onAiGeneration}
            />
        </SCxListCell>
    )
}
