import type { Field } from '@lighthouse/core'
import { type SchemaProtocol, VariableType } from '@lighthouse/core'
import React, { useCallback, useMemo } from 'react'
import type { ControllerRenderProps, FieldValues } from 'react-hook-form'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { filterModeIndex, propertyMode } from '../../constants/filter'
import { useApplicationContext } from '../../contexts'
import { isIdsValue, isNumberValue, isTextValue } from '../../utils'
import { DEFAULT_FILTER_VALUE_VARIABLE } from '../'
import { CheckButton } from './CheckButton'
import * as SC from './styles'

interface ParamsTileModeProps {
    field: Field
    schema: SchemaProtocol['schema']
    prefixName: string
    width?: string
    focusOutLine?: string
    isMobile?: boolean
}

const Box = styled.div<{ isMobile?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 12px;
    gap: 7px;

    ${props =>
        props.isMobile &&
        css`
            flex: 1;
        `}

    overflow: hidden;
`

export const ParamsTileMode: React.FC<ParamsTileModeProps> = ({ field, schema, prefixName, focusOutLine, width = 'auto', isMobile }) => {
    const { control } = useFormContext()
    const { personOptions } = useApplicationContext()

    const personOptionsWithCurrentUser = useMemo(
        () => [
            {
                userId: '{currentUserId}',
                username: '当前登录用户',
                isDepart: false
            },
            ...personOptions
        ],
        [personOptions]
    )
    const operator: string = useWatch({
        control,
        name: `${prefixName}.operator`
    })

    // const realField = useMemo(() => {
    //     if(noRealFieldTypes.has(field.type)){
    //         return {
    //             ...field,
    //             type: innerTypeToFieldTypeMapInCondition[field.innerType]
    //         }

    //     }
    //     return field
    // }, [field])

    const mode = useMemo(() => {
        const index = filterModeIndex[operator]
        if (!index && index !== 0) {
            return
        }
        if (!field || !field.type) {
            return
        }
        return propertyMode?.[field.type]?.[index]
    }, [operator, field])

    const options = useMemo(() => {
        const currentField = schema[field.id]
        if (!currentField) {
            return []
        }
        if (currentField.type === 'select' || currentField.type === 'selectGenerationByText') {
            const options = currentField.type === 'select' ? currentField.select.options : currentField.selectGenerationByText.options
            return options.map(item => ({
                label: item.label,
                value: item.label,
                color: item?.color
            }))
        }
        if (currentField.type === 'checkbox') {
            return [
                {
                    label: '是',
                    value: true
                },
                {
                    label: '否',
                    value: false
                }
            ]
        }
        return []
    }, [field.id, schema])

    const showTime = useMemo(() => {
        if (field?.type === 'formula' || field?.type === 'aggregation') {
            return true
        }

        return field?.type === 'date' && !!field?.date?.format && field.date.format.includes('HH')
    }, [field])

    const format = useMemo(() => {
        if (field?.type === 'date' && field.date?.format) {
            return field.date.format
        }
    }, [field])

    const renderParams = useCallback(
        (ctlField: ControllerRenderProps<FieldValues, `${string}.paramList`>) => {
            if (operator === 'isEmpty' || operator === 'isNotEmpty') {
                return <div />
            }
            const [firstParam = DEFAULT_FILTER_VALUE_VARIABLE, secondParam = DEFAULT_FILTER_VALUE_VARIABLE] = ctlField.value || [
                DEFAULT_FILTER_VALUE_VARIABLE,
                DEFAULT_FILTER_VALUE_VARIABLE
            ]

            const firstValue = firstParam?.valueVariable?.value
            const secondValue = secondParam?.valueVariable?.value

            switch (mode) {
                case 'date': {
                    // const showTime = field?.type === 'date' && !!field.date.format && field.date.format.includes('HH')
                    const dateValue = isNumberValue(firstValue) ? new Date(Number(firstValue)) : undefined
                    return (
                        <SC.ParamsDatePicker
                            clearable
                            style={{
                                width: 210
                            }}
                            key={`${prefixName}.paramList`}
                            {...ctlField}
                            popoverProps={{
                                // false 不行，会导致弹出框位置上翻
                                withinPortal: true
                            }}
                            format={format}
                            showTime={showTime}
                            value={dateValue}
                            placeholder="请选择"
                            // style={{ width }}
                            onChange={val => {
                                if (!val) {
                                    return ctlField.onChange([DEFAULT_FILTER_VALUE_VARIABLE])
                                }
                                ctlField.onChange([
                                    {
                                        type: VariableType.VALUE,
                                        valueVariable: { value: val.valueOf() }
                                    }
                                ])
                            }}
                        />
                    )
                }
                case 'rangeDate': {
                    const firstDateValue = isNumberValue(firstValue) ? new Date(Number(firstValue)) : undefined
                    const secondDateValue = isNumberValue(secondValue) ? new Date(Number(secondValue)) : undefined
                    return (
                        <>
                            <SC.ParamsDatePicker
                                clearable
                                key={`${prefixName}.paramList`}
                                {...ctlField}
                                popoverProps={{
                                    withinPortal: false
                                }}
                                format={format}
                                value={firstDateValue}
                                placeholder="请选择"
                                showTime={showTime}
                                // style={{ width }}
                                onChange={val => {
                                    if (!val) {
                                        return ctlField.onChange([DEFAULT_FILTER_VALUE_VARIABLE, secondParam])
                                    }
                                    ctlField.onChange([{ type: VariableType.VALUE, valueVariable: { value: val.valueOf() } }, secondParam])
                                }}
                            />
                            <SC.ParamsDatePicker
                                clearable
                                key={`${prefixName}.paramList`}
                                {...ctlField}
                                popoverProps={{
                                    withinPortal: false
                                }}
                                showTime={showTime}
                                format={format}
                                value={secondDateValue}
                                placeholder="请选择"
                                // style={{ width }}
                                onChange={val => {
                                    if (!val) {
                                        return ctlField.onChange([firstParam, DEFAULT_FILTER_VALUE_VARIABLE])
                                    }
                                    ctlField.onChange([firstParam, { type: VariableType.VALUE, valueVariable: { value: val.valueOf() } }])
                                }}
                            />
                        </>
                    )
                }

                case 'checkbox': {
                    // const [_, selectOptions] = getMultiSelectOptions(currentSchema, schema, allDataSources, personOptions, options)
                    return options.map(option => {
                        const isChecked = firstValue === option.value
                        return (
                            <CheckButton
                                key={option.label}
                                active={isChecked}
                                label={option.label}
                                onClick={() =>
                                    ctlField.onChange([
                                        { type: VariableType.VALUE, valueVariable: { value: isChecked ? undefined : option.value } }
                                    ])
                                }
                            />
                        )
                    })
                }
                case 'select': {
                    // const [_, selectOptions] = getMultiSelectOptions(currentSchema, schema, allDataSources, personOptions, options)
                    return options.map(option => {
                        const isChecked = firstValue === option.value

                        return (
                            <CheckButton
                                key={option.label}
                                active={isChecked}
                                label={option.label}
                                onClick={() =>
                                    ctlField.onChange([
                                        { type: VariableType.VALUE, valueVariable: { value: isChecked ? [] : [option.value] } }
                                    ])
                                }
                            />
                        )
                    })
                }
                case 'multiPerson':
                case 'person': {
                    const personValue = isIdsValue(firstValue) ? firstValue : []
                    return personOptionsWithCurrentUser
                        .filter(({ isDepart }) => !isDepart)
                        .map(option => {
                            const isChecked = personValue.includes(option.userId)
                            return (
                                <CheckButton
                                    key={option.userId}
                                    label={option.username}
                                    active={isChecked}
                                    onClick={() => {
                                        const value = isChecked
                                            ? personValue.filter((v: string) => v !== option.userId)
                                            : [...personValue, option.userId]
                                        ctlField.onChange([
                                            {
                                                type: VariableType.VALUE,
                                                valueVariable: {
                                                    value
                                                }
                                            }
                                        ])
                                    }}
                                />
                            )
                        })
                }

                case 'multiSelect': {
                    const selectValue = isIdsValue(firstValue) ? firstValue : []
                    // const [_, multiSelectOptions] = getMultiSelectOptions(currentSchema, schema, allDataSources, personOptions, options)
                    return options.map(option => {
                        const isChecked = selectValue.includes(option.label)
                        return (
                            <CheckButton
                                key={option.label}
                                active={isChecked}
                                label={option.label}
                                onClick={() => {
                                    ctlField.onChange([
                                        {
                                            type: VariableType.VALUE,
                                            valueVariable: {
                                                value: isChecked
                                                    ? selectValue.filter((v: string) => v !== option.value)
                                                    : [...selectValue, option.value]
                                            }
                                        }
                                    ])
                                }}
                            />
                        )
                    })
                }

                default: {
                    const currentType = field.type
                    const inputType = ['number'].includes(currentType) ? 'number' : 'text'
                    const originValue = isTextValue(firstValue) ? firstValue : ''
                    return (
                        <SC.ParamsInput
                            key={`${prefixName}.paramList`}
                            width={width}
                            {...ctlField}
                            placeholder="搜索..."
                            value={originValue}
                            type={inputType}
                            onChange={ev => {
                                const { value: newValue } = ev.target
                                ctlField.onChange([
                                    {
                                        type: 'value',
                                        valueVariable: { value: newValue }
                                    }
                                ])
                            }}
                        />
                    )
                }
            }
        },
        [field.type, format, mode, operator, options, personOptionsWithCurrentUser, prefixName, showTime, width]
    )

    if (!field || !mode) {
        return null
    }

    return (
        <Controller
            name={`${prefixName}.paramList`}
            control={control}
            render={({ field: ctlField }) => {
                return <Box isMobile={isMobile}>{renderParams(ctlField)}</Box>
            }}
        />
    )
}
