import type { FileType } from '@lighthouse/core'
import { FILE_ICON_MAP } from '@lighthouse/shared'
import * as React from 'react'
import { useState } from 'react'
import styled, { css } from 'styled-components'

import * as CM from './styles'

interface FileItemProps extends Pick<React.CSSProperties, 'aspectRatio' | 'objectFit' | 'borderRadius'> {
    src?: string
    alt?: string
    title?: string
    type?: FileType
    name?: string
    isPreview?: boolean
    onClick?: React.MouseEventHandler<HTMLImageElement>
    className?: string
}
export const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FileItem: React.FunctionComponent<FileItemProps> = ({ type = 'image', name, onClick, className, ...rest }) => {
    return (
        <SCxContainer className={className}>
            {type === 'image' ? (
                <CM.MImage {...rest} onClick={onClick} />
            ) : (
                <CM.FileWrapper {...rest} onClick={onClick}>
                    <CM.Icon type={FILE_ICON_MAP[type]} size={124} />
                    <CM.FileName>{name}</CM.FileName>
                </CM.FileWrapper>
            )}
        </SCxContainer>
    )
}

export default FileItem
