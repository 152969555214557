import type { NotesValue, RichTextContentProtocol } from '@lighthouse/core'
import { Modal } from '@mantine/core'
import type { Editor, JSONContent } from '@tiptap/core'
import type { Transaction } from '@tiptap/pm/state'
import React, { useCallback, useMemo, useState } from 'react'

import { useApplicationContext } from '../../../../../contexts'
import { RichTextEditor } from '../../../../RichText'
import type { ToolbarProps } from '../../../../RichText/Toolbar'
import type { FieldBaseProps } from '../../../types'
import * as SC from './styles'

interface NotesModalFieldProps extends FieldBaseProps {
    value?: NotesValue
}

export const NotesModalField: React.FunctionComponent<NotesModalFieldProps> = props => {
    const {
        readOnly,
        onCellChange,
        onSaveCellChange,
        value,
        isControlled,
        record,
        config: { title, previewType, richTextUploadOptions, fieldPointer }
    } = props

    const { pageTarget } = useApplicationContext()

    const [opened, setOpened] = useState(false)

    const [data, setData] = useState<RichTextContentProtocol | null>(value ?? null)

    const usedValue = useMemo(() => (isControlled ? value : data), [isControlled, data, value])

    const config: ToolbarProps['config'] = useMemo(
        () => ({
            history: true,
            heading: true,
            variable: false,
            align: true,
            lineHeight: true,
            image: richTextUploadOptions ? { uploadOptions: {} } : false
        }),
        [richTextUploadOptions]
    )

    const handleChange = useCallback(
        (json: JSONContent, isFinish?: boolean) => {
            setData(json)
            if (isFinish) {
                setOpened(false)
                onSaveCellChange?.({ type: 'notes', value: json })
                onCellChange?.({ type: 'notes', value: json })
            }
        },
        [onCellChange, onSaveCellChange]
    )

    return (
        <SC.Container data-field-border={opened} data-input-type="notes">
            <SC.RichTextWrapper onClick={() => !readOnly && setOpened(true)}>
                <RichTextEditor
                    styles={{
                        editorContent: {
                            padding: readOnly ? 0 : 8,
                            cursor: 'text'
                        }
                    }}
                    minHeight={readOnly ? undefined : 200}
                    config={config}
                    readonly
                    disableToolbar
                    key={`${record?.id}-${fieldPointer}`}
                    value={usedValue}
                />
            </SC.RichTextWrapper>

            <Modal
                opened={opened}
                target={pageTarget}
                styles={{
                    root: {
                        position: 'absolute'
                    },
                    modal: {
                        width: '100%',
                        height: '100%',
                        borderBottomLeftRadius: pageTarget ? 55 : 0,
                        borderBottomRightRadius: pageTarget ? 55 : 0
                    },
                    inner: {
                        padding: 0
                    },
                    close: {
                        display: 'none'
                    },
                    overlay: {
                        borderRadius: pageTarget ? '55px!important' : 0
                    },
                    header: {
                        marginBottom: 0
                    },
                    body: {
                        position: 'relative',
                        height: '100%'
                    }
                }}
                padding={0}
                closeOnEscape
                data-ignore-click-away
                onClose={() => setOpened(false)}
            >
                <SC.Header>
                    <SC.LeftExtra onClick={() => setOpened(false)}>
                        <SC.Icon type="Close" size={24} />
                    </SC.LeftExtra>
                    <SC.Title size={16}>{title}</SC.Title>
                    <SC.RightFill>
                        <SC.Text
                            size={16}
                            color="var(--color-main)"
                            onClick={() => data && handleChange(data, true)}
                            style={{ fontWeight: 600 }}
                        >
                            完成
                        </SC.Text>
                    </SC.RightFill>
                </SC.Header>

                <SC.EditorContent>
                    <RichTextEditor
                        style={{ minHeight: 200 }}
                        config={{
                            history: true,
                            heading: true,
                            variable: false,
                            align: true,
                            image: richTextUploadOptions ? { uploadOptions: richTextUploadOptions } : false
                        }}
                        readonly={readOnly}
                        key={`${record?.id}-${fieldPointer}`}
                        value={usedValue}
                        onChange={val => handleChange(val)}
                        previewType={previewType}
                        styles={{
                            toolbarContainer: {
                                flexWrap: 'wrap'
                            },
                            toolbarMenuList: {
                                flexWrap: 'wrap'
                            }
                        }}
                    />
                </SC.EditorContent>
            </Modal>
        </SC.Container>
    )
}
