import type { AppUser, UserGroup } from '@lighthouse/core'
import type { FC, PropsWithChildren } from 'react'
import React, { createContext, useContext } from 'react'

export type ApplicationContextType = {
    personOptions: AppUser[]
    groups?: UserGroup[]
    pageTarget?: string
    isRealityMobile?: boolean
    isBuilder: boolean
    /** 获取阿里云播放凭证 */
    fetchVideoPlayAuth?: (videoId: string) => Promise<string>

    /** 是否有更新 */
    hasUpdate?: boolean
    mutateHasUpdate?: (v?: boolean) => void
}

export const ApplicationContext = createContext<ApplicationContextType>({
    personOptions: [],
    groups: [],
    isRealityMobile: false,
    isBuilder: false,
    hasUpdate: false,
    mutateHasUpdate: () => void 0
})

type ApplicationProviderProps = PropsWithChildren<{
    value: ApplicationContextType
}>

export const ApplicationProvider: FC<ApplicationProviderProps> = props => <ApplicationContext.Provider {...props} />

export const useApplicationContext = () => useContext(ApplicationContext)
