import type { Data, UniqueIdentifier } from '@dnd-kit/core'
import type { AnimateLayoutChanges } from '@dnd-kit/sortable'
import { defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type {
    ActionsProtocol,
    AiFieldStatus,
    AppUser,
    ButtonAction,
    DataSourceAbstract,
    Field,
    FieldCellValue,
    RecordLikeProtocol,
    RichTextContentProtocol,
    ViewField
} from '@lighthouse/core'
import type { atomWithImmer } from 'jotai-immer'
import type { FC } from 'react'
import React from 'react'

import { KanbanBoardColumn } from './Column'
import type { SortItemsDataWithKey } from './utils'

const animateLayoutChanges: AnimateLayoutChanges = args => defaultAnimateLayoutChanges({ ...args, wasDragging: true })

const TYPE = 'container'

interface Props extends ActionsProtocol {
    disabled?: boolean
    clickable?: boolean
    creatable?: boolean
    selectedList: string[]

    enableHidden?: boolean
    id: UniqueIdentifier
    list: SortItemsDataWithKey[]

    personOptions?: AppUser[]
    schema: Record<string, Field>
    columns: ViewField[]
    fieldSchema: Field
    groupedFieldValue?: FieldCellValue
    collapsed?: boolean
    recordEditOpenable: boolean
    recordDeleteAble: boolean
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    handleCollapse?: () => void
    handleHidden?: () => void
    handleClickAdd?: () => void
    onRecordClick?: (id: string) => void
    onSelectChange?: (selected: boolean, id: string) => void
    onRecordEdit: (recordId: string) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
}

export const DroppableContainer: FC<Props> = ({
    id,
    list,
    actions,
    disabled,
    clickable,
    creatable,
    selectedList,
    enableHidden,
    personOptions,
    schema,
    columns,
    fieldSchema,
    groupedFieldValue,
    collapsed,
    recordEditOpenable,
    recordDeleteAble,
    aiFieldStatusListAtom,
    handleCollapse,
    handleHidden,
    handleClickAdd,
    onRecordClick,
    onRecordEdit,
    onSelectChange,
    onRecordDelete,
    onAiGeneration,
    onRecordOperatorActionTrigger,
    onRecordClickedActionTrigger,
    onRenderButtonTitle
}) => {
    const items = list.map(item => item.key)
    const { active, attributes, isDragging, listeners, over, setNodeRef, setActivatorNodeRef, transition, transform } = useSortable({
        id,
        disabled,
        data: { type: TYPE, children: items },
        animateLayoutChanges
    })

    const isOverContainer = over ? (over.id === id && active?.data.current?.type !== TYPE) || items.includes(over.id as string) : false

    return (
        <KanbanBoardColumn
            ref={disabled ? undefined : setNodeRef}
            style={{
                transition,
                transform: CSS.Translate.toString(transform),
                opacity: isDragging ? 0.5 : undefined
            }}
            actions={actions}
            disabled={disabled}
            creatable={creatable}
            clickable={clickable}
            selectedList={selectedList}
            onSelectChange={onSelectChange}
            enableHidden={enableHidden}
            handleProps={{ ...attributes, ...listeners, ref: setActivatorNodeRef }}
            isOverContainer={isOverContainer}
            list={list}
            personOptions={personOptions}
            schema={schema}
            columns={columns}
            groupedFieldValue={groupedFieldValue}
            fieldSchema={fieldSchema}
            collapsed={collapsed}
            recordEditOpenable={recordEditOpenable}
            recordDeleteAble={recordDeleteAble}
            aiFieldStatusListAtom={aiFieldStatusListAtom}
            handleCollapse={handleCollapse}
            handleHidden={handleHidden}
            handleClickAdd={handleClickAdd}
            onRecordClick={onRecordClick}
            onRecordEdit={onRecordEdit}
            onRecordDelete={onRecordDelete}
            onAiGeneration={onAiGeneration}
            onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
            onRecordClickedActionTrigger={onRecordClickedActionTrigger}
            onRenderButtonTitle={onRenderButtonTitle}
        />
    )
}
