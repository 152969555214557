import { noButtons } from '@byecode/ui'
import { Toast } from '@byecode/ui/components/Toast'
import type { ApplicationSettingAuthentication, ApplicationSettingNavbar, DataSourceAbstract } from '@lighthouse/core'
import { useUncontrolled } from '@lighthouse/tools'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { emailRegex, mobileRegex } from '../../constants'
import type { ApplicationPreviewEnum, AppUserGroup, ScanStatus } from '../../types'
import { AppNavbarStylesContextProvider } from '../ApplicationContainer/AppNavbarStylesContext'
import { BindEmail } from './BindEmail'
import { BindMobile } from './BindMobile'
import { defaultConfig } from './constant'
import { Home } from './Home'
import { Login } from './Login'
import { Perfect } from './Perfect'
import { Register } from './Register'
import type {
    AccountBindAccountForm,
    AccountLoginForm,
    AccountMergeAccount,
    AccountPerfectForm,
    AccountRegisterForm,
    AppLoginMode
} from './types'
import { WechatLogin } from './WeChatLogin'

interface AccountPageProps {
    mode?: AppLoginMode
    defaultMode?: AppLoginMode
    authentication?: ApplicationSettingAuthentication
    previewType?: ApplicationPreviewEnum
    style?: React.CSSProperties
    groups?: AppUserGroup[]
    dataSource?: DataSourceAbstract
    disableEvent?: boolean
    navbar: ApplicationSettingNavbar
    language: string
    isWxOfficialAccount?: boolean
    onChangeLanguage: (v: string) => void
    onChangeMode?: (mode: AppLoginMode) => void
    onRegister?: (params: AccountRegisterForm) => void
    onLogin?: (params: AccountLoginForm) => void
    onPerfect?: (params: AccountPerfectForm) => void
    onHomeBack?: () => void
    onCheckWechatLogin?: (mark: string) => Promise<ScanStatus>
    onWeChatLogin?: () => Promise<{ url: string; mark: string } | undefined>
    onBindAccount?: (params: AccountBindAccountForm) => Promise<boolean>
    onSendCode?: (type: 'email' | 'mobile', account: string) => Promise<boolean>
}

const SCxAccountContainer = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`
const SCxWrapper = styled.div`
    width: 100%;
    max-width: 390px;

    position: relative;
    margin: 60px 0;
    border-radius: 32px;
    border: 1px solid var(--color-gray-200);
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(132, 146, 167, 0.12);

    max-height: 80%;
    overflow: hidden auto;
    ${noButtons('0px')}
`
export const AccountPage: React.FunctionComponent<AccountPageProps> = ({
    authentication = defaultConfig,
    defaultMode,
    dataSource,
    previewType,
    mode: propMode,
    groups,
    disableEvent,
    language,
    navbar,
    style,
    isWxOfficialAccount,
    onChangeLanguage,
    onSendCode,
    onLogin,
    onRegister,
    onChangeMode,
    onPerfect,
    onWeChatLogin,
    onBindAccount,
    onHomeBack,
    onCheckWechatLogin
}) => {
    const [mode, setMode] = useUncontrolled({ value: propMode, onChange: onChangeMode, defaultValue: defaultMode })

    const { t } = useTranslation()

    const handleSendCode = useCallback(
        (type: 'email' | 'mobile', value: string) => {
            const isValid = type === 'email' ? emailRegex.test(value) : mobileRegex.test(value)
            // return Promise.resolve(true)
            if (!isValid) {
                Toast.error(type === 'email' ? t('emailFormatIsIllegal') : t('mobilePhoneNumberFormatIsIllegal'))
                return Promise.resolve(false)
            }
            return onSendCode?.(type, value)
        },
        [t, onSendCode]
    )

    const ele = useMemo(() => {
        switch (mode) {
            case 'home': {
                return (
                    <Home
                        authentication={authentication}
                        language={language}
                        disableEvent={disableEvent}
                        previewType={previewType}
                        isWxOfficialAccount={isWxOfficialAccount}
                        onChangeLanguage={onChangeLanguage}
                        onWeChatLogin={onWeChatLogin}
                        onChangeMode={setMode}
                        onHomeBack={onHomeBack}
                    />
                )
            }
            case 'bindWechat':
            case 'weChatLogin': {
                return (
                    <WechatLogin
                        language={language}
                        authentication={authentication}
                        onChangeMode={setMode}
                        onWeChatLogin={onWeChatLogin}
                        onChangeLanguage={onChangeLanguage}
                        onCheckWechatLogin={onCheckWechatLogin}
                    />
                )
            }
            case 'register': {
                return (
                    <Register
                        authentication={authentication}
                        disableEvent={disableEvent}
                        language={language}
                        previewType={previewType}
                        onChangeLanguage={onChangeLanguage}
                        onChangeMode={setMode}
                        onSendCode={handleSendCode}
                        onRegister={onRegister}
                    />
                )
            }
            case 'emailLogin': {
                return (
                    <Login
                        key="email"
                        type="email"
                        previewType={previewType}
                        language={language}
                        onChangeLanguage={onChangeLanguage}
                        authentication={authentication}
                        disableEvent={disableEvent}
                        onChangeMode={setMode}
                        onSendCode={handleSendCode}
                        onLogin={onLogin}
                    />
                )
            }
            case 'mobileLogin': {
                return (
                    <Login
                        key="mobile"
                        type="mobile"
                        authentication={authentication}
                        disableEvent={disableEvent}
                        language={language}
                        previewType={previewType}
                        onChangeLanguage={onChangeLanguage}
                        onChangeMode={setMode}
                        onSendCode={handleSendCode}
                        onLogin={onLogin}
                    />
                )
            }
            case 'bindEmail': {
                return (
                    <BindEmail
                        authentication={authentication}
                        language={language}
                        previewType={previewType}
                        onChangeLanguage={onChangeLanguage}
                        onChangeMode={setMode}
                        onSendCode={handleSendCode}
                        onBindAccount={onBindAccount}
                    />
                )
            }
            case 'bindMobile': {
                return (
                    <BindMobile
                        authentication={authentication}
                        language={language}
                        previewType={previewType}
                        onChangeLanguage={onChangeLanguage}
                        onChangeMode={setMode}
                        onSendCode={handleSendCode}
                        onBindAccount={onBindAccount}
                    />
                )
            }

            case 'perfect': {
                return (
                    <Perfect
                        authentication={authentication}
                        dataSource={dataSource}
                        previewType={previewType}
                        groups={groups}
                        onPerfect={onPerfect}
                    />
                )
            }
            default: {
                return null
            }
        }
    }, [
        authentication,
        dataSource,
        disableEvent,
        groups,
        handleSendCode,
        isWxOfficialAccount,
        language,
        mode,
        onBindAccount,
        onChangeLanguage,
        onCheckWechatLogin,
        onHomeBack,
        onLogin,
        onPerfect,
        onRegister,
        onWeChatLogin,
        previewType,
        setMode
    ])

    return (
        <AppNavbarStylesContextProvider navbar={navbar}>
            <SCxAccountContainer style={style}>
                <SCxWrapper>{ele}</SCxWrapper>
            </SCxAccountContainer>
        </AppNavbarStylesContextProvider>
    )
}
