import { Button, IconFont } from '@byecode/ui'
import { SegmentedControl } from '@byecode/ui/components/SegmentedControl'
import { nanoid } from '@lighthouse/tools'
import { Text } from '@mantine/core'
import React, { useCallback, useMemo, useState } from 'react'

import type { WithStatusFile } from '../../../types'
import { Pexels } from '../../Pexels'
import type { UseUploadFileSParameter } from '../../UploadManage'
import { UploadDropZone } from '../../UploadManage'
import * as SC from './styles'

export interface FileUploaderProps {
    children?: React.ReactNode
    accept?: string
    isPexels?: boolean
    isUsedLink?: boolean
    uploadyOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    onImageLinkSave?: (file: WithStatusFile) => void
}

const TABS = [
    { value: '0', label: '上传', icon: <SC.TabIcon type="UploadSimple" /> },
    { value: '1', label: '链接', icon: <SC.TabIcon type="LinkSimple" /> },
    { value: '2', label: 'Pexels', icon: <SC.TabIcon type="Pexels" /> }
]

export const FileUploader: React.FC<FileUploaderProps> = ({
    isPexels = true,
    isUsedLink = true,
    accept,
    uploadyOptions,
    onImageLinkSave
}) => {
    const [currentTab, setCurrentTab] = useState(TABS[0]?.value)
    const [imgUrl, setImgUrl] = useState('')
    const [isSubmit, setIsSubmit] = useState(false)

    const currentTabs = useMemo(
        () =>
            TABS.filter(item => {
                if (item.value === '2') {
                    return isPexels
                }
                if (item.value === '1') {
                    return isUsedLink
                }
                return true
            }),
        [isPexels, isUsedLink]
    )

    const handleImageLinkSave = useCallback(
        (url?: string) => {
            setIsSubmit(true)

            const newImageInfo: WithStatusFile = {
                type: 'image',
                name: `新图片-${nanoid(12)}`,
                url: url ?? imgUrl,
                status: 'success'
            }
            onImageLinkSave?.(newImageInfo)
        },
        [imgUrl, onImageLinkSave]
    )

    const tabContent = useMemo(() => {
        if (!currentTab) {
            return null
        }
        return [
            <UploadDropZone accept={accept} multiple uploadOptions={uploadyOptions} key={TABS[0]?.value}>
                <SC.UploaderArea>
                    <IconFont color="var(--color-gray-400)" type="MouseClick" />
                    <Text>选择文件</Text>
                </SC.UploaderArea>
            </UploadDropZone>,
            <SC.ImageUrlAdderWrapper key={TABS[1]?.value}>
                {/* <ErrorMessage style={{ flex: 1 }} name="url" message={isError && isSubmit ? '请输入正确的图片链接' : ''}> */}
                <SC.ImageUrlInput
                    value={imgUrl}
                    placeholder="http://"
                    onChange={ev => {
                        setImgUrl(ev.target.value)
                    }}
                    onFocus={() => {
                        setIsSubmit(false)
                    }}
                />
                {/* </ErrorMessage> */}
                <Button type="primary" onClick={e => handleImageLinkSave()}>
                    确定
                </Button>
            </SC.ImageUrlAdderWrapper>,
            <SC.PexelsContainer key={TABS[2]?.value}>
                <Pexels
                    onClick={file => {
                        handleImageLinkSave(file.url)
                    }}
                />
            </SC.PexelsContainer>
        ][Number(currentTab)]
    }, [accept, currentTab, handleImageLinkSave, imgUrl, uploadyOptions])

    return (
        <SC.FileUploaderWrapper>
            {currentTabs.length > 1 && (
                <SC.TabWrapper>
                    <SegmentedControl data={currentTabs} fullWidth value={currentTab} onChange={setCurrentTab} />
                </SC.TabWrapper>
            )}
            <SC.TabContentWrapper>{tabContent}</SC.TabContentWrapper>
        </SC.FileUploaderWrapper>
    )
}
