import clsx from 'clsx'
import React, { useCallback, useMemo } from 'react'
import { useToggle } from 'react-use'

import { useUncontrolled } from '../../hooks/useUncontrolled'
import { Box } from '../Box'
import type { CascadeOption, CascadeValue } from '../CascadeList'
import { CascadeList } from '../CascadeList'
import { Flex } from '../Flex'
import { IconFont } from '../IconFont'
import { Popover } from '../Popover'
import { Text } from '../Text'
import { useStyles } from './CascadeSelect.styles'
import type { CascadeSelectProps } from './CascadeSelect.types'

export const CascadeSelect = React.forwardRef<HTMLDivElement, CascadeSelectProps>(
    (
        {
            data,
            options = [],
            withinPortal,
            className,
            style,
            clearable = false,
            downIcon = 'CaretDown',
            styles,
            multiple,
            columnWidth,
            searchable,
            lastLevel,
            listProps,
            onChange,
            onClear
        },
        ref
    ) => {
        const [opened, toggle] = useToggle(false)

        const [_value = [], _Change] = useUncontrolled({ value: data, onChange })

        const { classes } = useStyles({ clearable: _value.length > 0 && clearable, isOpen: opened }, { name: 'Cascade', styles })

        const flatOptions = useMemo(() => {
            function getChildOption(option: CascadeOption, list: CascadeOption[]) {
                const { value, label, path, labelPath, children = [] } = option
                let arrList = list
                for (const child of children) {
                    const newChild = { ...child, path: `${path}/${child.value}`, labelPath: `${labelPath}/${child.label}` }
                    arrList = [...arrList, newChild, ...getChildOption(newChild, [])]
                }
                return arrList
            }
            return options.reduce<CascadeOption[]>((pre, cur) => {
                const newOption = { ...cur, path: cur.value, labelPath: cur.label }
                return [...pre, newOption, ...getChildOption(newOption, [])]
            }, [])
        }, [options])

        const handleClear = useCallback(
            (event: React.MouseEvent<HTMLSpanElement>) => {
                event.stopPropagation()
                _Change([])
                onClear?.(event)
            },
            [_Change, onClear]
        )

        const handleRemove = useCallback(
            (index: number) => {
                const newValue = _value.filter((item, i) => {
                    return index !== i
                })
                _Change(newValue)
            },
            [_Change, _value]
        )

        const handleChange = useCallback(
            (v: CascadeValue) => {
                _Change(v)
                toggle(false)
            },
            [_Change, toggle]
        )

        return (
            <Box ref={ref} className={clsx(className, classes.root)} style={style}>
                <Popover opened={opened} onChange={toggle} withinPortal={withinPortal}>
                    <Popover.Target>
                        <Box className={classes.target}>
                            <Flex className={classes.targetWrapper}>
                                {_value.map((v, index) => {
                                    const option = flatOptions?.find(option => option.path === v)
                                    return (
                                        option && (
                                            <Box className={classes.tag} key={v}>
                                                <Text>{option.labelPath}</Text>
                                                <IconFont
                                                    type="Close"
                                                    onClick={() => {
                                                        handleRemove(index)
                                                    }}
                                                />
                                            </Box>
                                        )
                                    )
                                })}
                            </Flex>
                            <Box className={classes.iconWrapper}>
                                <IconFont className={classes.arrowIcon} type={downIcon} style={{ color: 'inherit' }} />
                                <IconFont onClick={handleClear} className={classes.clearIcon} type="Close" />
                            </Box>
                        </Box>
                    </Popover.Target>
                    <Popover.Dropdown compact>
                        <CascadeList
                            data={_value}
                            onChange={handleChange}
                            multiple={multiple}
                            columnWidth={columnWidth}
                            searchable={searchable}
                            lastLevel={lastLevel}
                            options={options}
                            {...listProps}
                        />
                    </Popover.Dropdown>
                </Popover>
            </Box>
        )
    }
)
