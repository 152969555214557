import { ScrollArea, Tabs } from '@mantine/core'
import styled from 'styled-components'

export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 6px 12px;
`

export const TableScrollerContent = styled(ScrollArea)`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    box-shadow: 0 0 0 1px var(--color-gray-200);
    border-radius: 8px;
    height: 100%;

    & > div[data-radix-scroll-area-viewport] {
        padding-right: 0;
    }

    /* ScrollArea 缺省页面 sticky 兼容处理  */
    /* & div[data-radix-scroll-area-viewport] > div {
            overflow: auto;
            width: 100%;
            display: block!important;
    } */
`

export const TableNoDataContent = styled.div<{ width?: number }>`
    width: ${({ width }) => (width ? `${width - 2}px` : '100%')};
    padding: 40px 0;
    position: sticky;
    left: 0;
    top: 0;
`

export const TableTitle = styled.p``
