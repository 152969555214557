import type { MakeADT } from 'ts-adt/MakeADT'

import type { FieldBlockAbstract, FieldInputValue, SmsConfig } from '../abstracts'
import { type FieldOptionsProtocol } from '../abstracts'
import type { BlockVisibilityProtocol } from './blocks'
import type { DTFile, FileSourceType, ScannerType } from './data'
import type { FieldCellValue, FieldType, FieldValue } from './field'
import type { FilterFormType } from './filter'
import type { MultipleChoiceProtocol, SortableProtocol, ViewRecordOperateProtocol } from './general'
import type { Direction } from './layout'
import type { RichTextContentProtocol } from './richtext'
import type { ViewField } from './view'

export const fieldBlockInput = [
    'text',
    'checkbox',
    'email',
    'number',
    'url',
    'phoneNumber',
    'relativeSelect',
    // 'select',
    'cascade',
    'person',
    'date',
    'file',
    'notes',
    'slider'
] as const

export type FieldInputType = typeof fieldBlockInput[number]

export type DateRange = 'ALL' | 'BEFORE_TODAY' | 'AFTER_TODAY' | 'AFTER_CUSTOM'

export type NumberConfig = {
    number?: {
        min?: number
        max?: number
    }
} & FieldOptionsProtocol

export type PhoneNumberConfig = {
    //
    phoneNumber?: {
        areaCode: string

        isOpened: boolean

        isUsedAreaCode?: boolean

        selectAreaCode?: string[]
    }
} & FieldOptionsProtocol

export type UrlConfig = {
    //
} & FieldOptionsProtocol

export type EmailConfig = {
    //
} & FieldOptionsProtocol

export type TextConfig = {
    text?: {
        minFontNumber?: number
        maxFontNumber?: number
        regular?: string
        regularErrorLabel?: string
        disableInputs?: ('input' | 'album')[]
        scannerList?: ScannerType[]
        enableWxScanner?: boolean
    }
} & FieldOptionsProtocol

// export type SelectConfig = {
//     //
//     select?: MultipleChoiceProtocol
// } & FieldOptionsProtocol

export type DateConfig = {
    date?: {
        dateFormat?: string
        range?: DateRange
        customDays?: number
    }
} & FieldOptionsProtocol

export type FileConfig = {
    //
} & FieldOptionsProtocol

export type NotesConfig = {
    //
} & FieldOptionsProtocol

export type PersonConfig = {
    person?: {
        filter?: FilterFormType
    } & MultipleChoiceProtocol
} & FieldOptionsProtocol

export enum CheckboxShapeEnum {
    'collect' = 'collect',
    'heart' = 'heart',
    'flag' = 'flag',
    'like' = 'like',
    'checkbox' = 'checkbox'
}

export type CheckboxConfig = {
    //
    checkbox?: {
        type?: 'switch' | 'checkbox'
        shape?: CheckboxShapeEnum
        gapType?: 'auto' | 'fixed'
        gap?: number
        describe?: RichTextContentProtocol
        describePosition?: 'left' | 'right'
    }
} & FieldOptionsProtocol

export type SliderConfig = {
    slider?: {
        /** 	Minimal possible value, 0 by default */
        min: number

        /** Maximum possible value, 100 by default */
        max: number

        /** 通过拇指拖动和箭头来增加/减少值的数字，默认为 1 */
        step: number

        /** 是否启用输入框组件 */
        isInput: boolean

        /** 颜色模式 */
        colorMode: 'theme' | 'custom'

        /** 滑块颜色 */
        color: string
    }
} & FieldOptionsProtocol

export type RelativeSelectConfig = {
    relativeSelect?: {
        relativePointer?: string
        relativeFieldPointer?: string
        relativeShowFieldPointer?: string
        showType: 'table' | 'list'
        filter?: FilterFormType
        disabledFilter?: FilterFormType
        viewFieldSettings: ViewField[]
        // viewPrimaryField?: string
        direction: Direction
        showMode?: 'input' | 'checkbox' | 'tag'
    } & ViewRecordOperateProtocol &
        MultipleChoiceProtocol &
        SortableProtocol
} & FieldOptionsProtocol

export type CascadeConfig = {
    cascade?: {
        // 选项来源
        cascadePointer?: string
        // 选项名
        cascadeFieldPointer?: string
        // 显示字段
        cascadeShowFieldPointer?: string
        // 父层级
        parentFieldPointer?: string
        // 排序字段
        sortFieldPointer?: string
        // 筛选数据
        filter?: FilterFormType
        // 结果显示层级路径
        showPath?: boolean
        // 必须选到最终层级
        isLastLevel?: boolean
    }
} & FieldOptionsProtocol

export type FieldInputConfigProtocol = MakeADT<
    'inputType',
    {
        text: TextConfig
        number: NumberConfig
        email: EmailConfig
        phoneNumber: PhoneNumberConfig
        url: UrlConfig
        // select: SelectConfig
        date: DateConfig
        file: FileConfig
        notes: NotesConfig
        relativeSelect: RelativeSelectConfig
        person: PersonConfig
        checkbox: CheckboxConfig
        slider: SliderConfig
        cascade: CascadeConfig
    }
>

export type SimpleInputValue = {
    value: string
}

export type NumberInputValue = {
    value: string | number
}

export type SelectInputValue = {
    value: string[]
}

export type DateInputValue = {
    value: number | string
}

export type FileInputValue = {
    value: DTFile[]
}

export type NotesInputValue = {
    value: RichTextContentProtocol
}

export type RelativeSelectInputValue = {
    value: string | string[]
}

export type PersonInputValue = {
    value: string[]
}

export type CheckboxInputValue = {
    value: boolean
}

export type FieldInputADTValue = MakeADT<
    'type',
    {
        text: SimpleInputValue
        number: NumberInputValue
        email: SimpleInputValue
        phoneNumber: SimpleInputValue
        url: SimpleInputValue
        // select: SelectInputValue
        date: DateInputValue
        file: FileInputValue
        notes: NotesInputValue
        person: PersonInputValue
        relativeSelect: RelativeSelectInputValue
        checkbox: CheckboxInputValue
        slider: NumberInputValue
        cascade: SimpleInputValue
    }
>

export type InputValueItem = FieldInputADTValue &  {
    id: string
    fieldId: string
    dsId?: string
    isCheckRepeat?: boolean
    fieldType?: FieldType
    source: 'form' | 'field' | 'subForm'
    code?: string
}

export type FormInputItem = {
    id: string
} & FieldInputConfigProtocol

export type FormOptions = {
    pointer: string

    submitButtonTitle: string

    inputList: FormInputItem[]
}

export interface ApprovalFieldInput {
    fieldId: string
    fieldName: string
    fieldType: FieldInputType
    permission: 'READ' | 'WRITE'
    value: FieldValue
}

export type FieldBlockWithDsId = FieldBlockAbstract & {
    dsId?: string
    parentFormContainerId?: string
}
