import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import { CssComponent } from '@stitches/react/types/styled-component'

interface TextStylesParams {}

export const useStyles = createStyles(({}: TextStylesParams) => {
    return {
        root: css({
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%'
        }),
        wrapper: css({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            gap: '8px'
        }),
        content: css({
            fontSize: 'var(--font-size-normal)',
            color: '$colorGray400',
            textAlign: 'center'
        }),
        title: css({
            fontSize: 'var(--font-size-normal)',
            color: '$colorGray900',
            lineHeight: '22px',

            fontWeight: 600
        })
    }
})
