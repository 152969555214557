import type {
    DataSourceAbstract,
    FieldCellValue,
    FilterFormType,
    RecordLikeProtocol,
    SystemVariable,
    VariableADTvalue
} from '@lighthouse/core'
import { VariableType, WechatPayType } from '@lighthouse/core'
import { isWechatBrowser } from '@lighthouse/tools'
import { lightFormat, startOfToday, startOfTomorrow, startOfYesterday } from 'date-fns'
import type { AnyObject } from 'immer/dist/internal'
import JSZip from 'jszip'
import { find } from 'rambda'

import { type PreviewFile, type TiptapEditorJSONContent, Params } from '../../components'
import type {
    ActionFlowNode,
    ActiveDingTalkRobotActionPayload,
    ConditionFlowNode,
    CreateRecordActionConfig,
    DownloadFileActionConfig,
    EmailParticipant,
    FindSingleRecordActionConfig,
    FlowEdge,
    IClickActionConfig,
    NodeFieldContent,
    SendMsgDingTalkRobotConfig,
    UpdateRecordActionConfig
} from '../../types'
import { ApplicationPreviewEnum } from '../../types'
import { getUpstreamRealDsId } from '../flow'
import { getApplicationPreviewType } from '../getApplicationPreviewType'
import { getFileNameByUrl, getFileTypeByUrl } from '../helper'
import { resolveFilter } from './filterResolver'

export const waitFor = (ms: number) =>
    new Promise(resolve => {
        setTimeout(resolve, ms)
    })

interface NodesTopToBottomTree {
    node: string
    level: number
    levelIndex: number
    levelCount: number
    data?: FlowEdge
}

export type NodeInfo = {
    data: ActionFlowNode | ConditionFlowNode
    children: NodeInfo[]
}

export type ResolveFilterParams = {
    filter: FilterFormType
    extraParams?: AnyObject
    shouldUseEmptyPlaceholder?: boolean
    useInFilterRecordChecker?: boolean
}

const getFlowNodeTreeMap = (edges: FlowEdge[], nodes: (ActionFlowNode | ConditionFlowNode)[]) => {
    const edgedNodes = new Set<string>()
    const nodesWithNoIncomingEdges = new Set<string>()
    const nodesWithNoOutgoingEdges = new Set<string>()

    for (const edge of edges) {
        edgedNodes.add(edge.source)
        edgedNodes.add(edge.target)

        if (nodesWithNoIncomingEdges.has(edge.target)) {
            nodesWithNoIncomingEdges.delete(edge.target)
        } else {
            nodesWithNoOutgoingEdges.add(edge.target)
        }

        if (nodesWithNoOutgoingEdges.has(edge.source)) {
            nodesWithNoOutgoingEdges.delete(edge.source)
        } else {
            nodesWithNoIncomingEdges.add(edge.source)
        }
    }

    const rootNodeId = [...nodesWithNoIncomingEdges][0]

    const nodeTree = new Map<string, { children: string[]; data: FlowEdge }>()
    for (const node of edgedNodes) {
        nodeTree.set(node, {
            children: [],
            data: edges.find(e => e.source === node) as FlowEdge
        })
    }

    for (const edge of edges) {
        nodeTree.get(edge.source)?.children?.push(edge.target)
    }

    const nodeMaps = nodes.reduce<Record<string, ActionFlowNode | ConditionFlowNode>>((prev, cur) => {
        prev[cur.id] = cur
        return prev
    }, {})

    return { rootNodeId, nodeTree, nodeMaps }
}

export const getFlowByNodes = (edges: FlowEdge[], nodes: (ActionFlowNode | ConditionFlowNode)[]) => {
    const { rootNodeId, nodeTree, nodeMaps } = getFlowNodeTreeMap(edges, nodes)
    const nodesTopToBottom: NodesTopToBottomTree[] = []
    const queue = [
        {
            level: 0,
            levelIndex: 0,
            node: rootNodeId,
            levelCount: 1,
            data: nodeTree.get(rootNodeId)?.data
        }
    ]
    while (queue.length > 0) {
        const node = queue.shift()
        const treeNode = node && nodeTree.get(node.node)
        if (!node || !treeNode) {
            continue
        }
        nodesTopToBottom.push(node)
        const { children, data } = treeNode

        for (let index = 0; index < children.length; index++) {
            const child = children[index]
            queue.push({
                level: node.level + 1,
                levelIndex: index,
                levelCount: children.length,
                node: child,
                data
            })
        }
    }

    return nodesTopToBottom.map(item => nodeMaps[item.node]).slice(1, -1)
}

// get flow tree base CONDITION node
export const getFlowTreeByNodes = (edges: FlowEdge[], nodes: (ActionFlowNode | ConditionFlowNode)[]) => {
    const { rootNodeId, nodeTree, nodeMaps } = getFlowNodeTreeMap(edges, nodes)

    const assembling = (nodeId: string): NodeInfo => {
        const treeNode = nodeTree.get(nodeId)
        if (!treeNode?.children || treeNode?.children?.length === 0) {
            return {} as NodeInfo
        }

        const { children, data } = treeNode

        return {
            data: nodeMaps[data.source],
            children: children.map(childId => {
                return assembling(childId)
            })
        }
    }

    return assembling(rootNodeId)
}

const getSystemVariableValue = (variable: SystemVariable) => {
    if (!variable.systemVariable?.value) {
        return ''
    }
    const value = variable.systemVariable?.value

    switch (value) {
        case 'TOMORROW': {
            return startOfTomorrow().valueOf()
        }

        case 'TODAY': {
            return startOfToday().valueOf()
        }
        case 'YESTERDAY': {
            return startOfYesterday().valueOf()
        }
        case 'NOW': {
            return Date.now()
        }
        default: {
            return ''
        }
    }
}

export const generateAdtValue = (variableData: VariableADTvalue, extraParams?: AnyObject, isFormat?: boolean) => {
    // 从之前数据拿过来的，用于处理数据拍平等问题
    // 2024-01-12 填上游数据转换，出来数据是两层数组的坑
    let needFlat = false

    switch (variableData.type) {
        case VariableType.UPSTREAM: {
            if (!variableData.upstreamVariable) {
                return ''
            }
            const { nodeId = '', fieldId = '' } = variableData.upstreamVariable
            const record = extraParams?.[nodeId]?.record as RecordLikeProtocol
            const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList
            const sourceDataSource = dataSourceList?.find(({ id }) => id === extraParams?.sourceDataSourceId)
            const sourceFieldId = extraParams?.sourceFieldId
            const sourceField = sourceDataSource?.schema[sourceFieldId]
            if (!record || !record.content[fieldId]) {
                return ''
            }

            const { dsId, content } = record
            const { value } = content[fieldId]
            const dataSource = dataSourceList?.find(({ id }) => id === dsId)
            const field = dataSource?.schema[fieldId]
            // 聚合字段通过getRecord接口获取的cellValue， 会被全部改造成数组

            if (field?.innerType === 'DATE' && isFormat) {
                const dateValue = Array.isArray(value) ? value[0] : value
                return typeof dateValue === 'number' ? lightFormat(dateValue, 'yyyy-MM-dd HH:mm:ss') : dateValue
            }

            if (field?.innerType && ['NUMBER', 'TEXT', 'BOOLEAN', 'DATE'].includes(field.innerType)) {
                return Array.isArray(value) ? value[0] : value
            }

            //   处理将数组类型数据写入到文本相关类型字段的情况
            if (field?.innerType === 'ARRAY' && ['NUMBER', 'TEXT', 'BOOLEAN', 'DATE'].includes(sourceField?.innerType ?? 'TEXT')) {
                return Array.isArray(value) ? value.join(',') : value
            }

            //   解决上游数据是数组但被转为字符串的问题
            if (Array.isArray(value)) {
                needFlat = true
            }
            return needFlat ? { needFlat, value } : value
        }
        case VariableType.SYSTEM: {
            const systemValue = getSystemVariableValue(variableData)

            if (isFormat) {
                return typeof systemValue === 'number' ? lightFormat(systemValue, 'yyyy-MM-dd HH:mm:ss') : systemValue
            }
            return systemValue
        }

        case VariableType.VALUE: {
            const value = variableData.valueVariable?.value
            if (Array.isArray(value)) {
                if (variableData.valueVariable?.type === 'date' && isFormat) {
                    return value
                        .map(item => {
                            if (typeof item === 'number') {
                                return lightFormat(item, 'yyyy-MM-dd HH:mm:ss')
                            }
                            return item
                        })
                        .join(',')
                }

                return value
                    .map(item => {
                        if (item === '{currentUserId}') {
                            return extraParams?.clickTriggerNodeParams.currentUserId
                        }
                        return item
                    })
                    .join(',')
            }

            if (variableData.valueVariable?.type === 'date' && isFormat) {
                return typeof value === 'number' ? lightFormat(value, 'yyyy-MM-dd HH:mm:ss') : value
            }

            if (value === '{currentUserId}') {
                return extraParams?.clickTriggerNodeParams.currentUserId
            }
            return value
        }

        //   case VariableType.VARIABLE: {
        //       const fieldId = variableData.fieldVariable?.fieldId ?? ''
        //       const record = extraParams?.clickTriggerNodeParams?.record as RecordLikeProtocol
        //       if (!record || !record.content[fieldId]) {
        //           return ''
        //       }
        //       const { value } = record.content[fieldId]
        //       //   解决上游数据是数组但被转为字符串的问题
        //       if (Array.isArray(value)) {
        //           isArray = true
        //           needFlat = true
        //       }
        //       return value
        //   }

        case VariableType.PAGE: {
            const fieldId = variableData.pageVariable?.fieldId ?? ''
            const pageVariableType = variableData.pageVariable?.type
            let record: RecordLikeProtocol | null = null
            if (pageVariableType === 'page') {
                record = extraParams?.pageRecord as RecordLikeProtocol
            } else {
                record = extraParams?.clickTriggerNodeParams?.prevRecord as RecordLikeProtocol
            }

            if (!record || !record.content[fieldId]) {
                return ''
            }
            let { value } = record.content[fieldId]

            //   解决上游数据是数组但被转为字符串的问题
            if (Array.isArray(value)) {
                needFlat = true
            }
            const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList
            const dataSource = find(item => item.id === record?.dsId, dataSourceList)
            const isDateField = dataSource?.schema[fieldId]?.type === 'date'
            if (isFormat && isDateField) {
                value = typeof value === 'number' ? lightFormat(value, 'yyyy-MM-dd HH:mm:ss') : value
            }

            return needFlat ? { needFlat, value } : value
        }

        case VariableType.VIEW: {
            const fieldId = variableData.viewVariable?.fieldId ?? ''
            const record = extraParams?.viewRecord as RecordLikeProtocol
            if (!record || !record.content[fieldId]) {
                return ''
            }
            let { value } = record.content[fieldId]
            //   解决上游数据是数组但被转为字符串的问题
            //   解决上游数据是数组但被转为字符串的问题
            if (Array.isArray(value)) {
                needFlat = true
            }
            const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList
            const dataSource = find(item => item.id === record.dsId, dataSourceList)
            const isDateField = dataSource?.schema[fieldId]?.type === 'date'
            if (isFormat && isDateField) {
                value = typeof value === 'number' ? lightFormat(value, 'yyyy-MM-dd HH:mm:ss') : value
            }
            return needFlat ? { needFlat, value } : value
        }

        case VariableType.USER: {
            const fieldId = variableData.userVariable?.fieldId ?? ''
            const userRecord = extraParams?.userRecord?.record as RecordLikeProtocol
            if (!userRecord || !userRecord.content[fieldId]) {
                return ''
            }
            let { value } = userRecord.content[fieldId]
            if (Array.isArray(value)) {
                needFlat = true
            }
            const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList
            const dataSource = find(item => item.id === userRecord.dsId, dataSourceList)
            const isDateField = dataSource?.schema[fieldId]?.type === 'date'
            if (isFormat && isDateField) {
                value = typeof value === 'number' ? lightFormat(value, 'yyyy-MM-dd HH:mm:ss') : value
            }

            return needFlat ? { needFlat, value } : value
        }

        case VariableType.FORM: {
            const fieldId = variableData.formVariable?.fieldId ?? ''
            const formRecord = extraParams?.formRecord as RecordLikeProtocol
            if (!formRecord || !formRecord.content[fieldId]) {
                return ''
            }
            let { value } = formRecord.content[fieldId]
            if (Array.isArray(value)) {
                needFlat = true
            }
            const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList
            const dataSource = find(item => item.id === formRecord.dsId, dataSourceList)
            const isDateField = dataSource?.schema[fieldId]?.type === 'date'
            if (isFormat && isDateField) {
                value = typeof value === 'number' ? lightFormat(value, 'yyyy-MM-dd HH:mm:ss') : value
            }

            return needFlat ? { needFlat, value } : value
        }
        case VariableType.PAGE_LINK: {
            const value = variableData.pageLinkVariable?.value
            return value === 'CURRENT_PAGE' ? extraParams?.getCurrentPageLink?.() : ''
        }
        case VariableType.INPUT: {
            const pageStackFormState = extraParams?.pageStackFormState
            const { inputVariable } = variableData
            const { blockId = '' } = inputVariable ?? {}
            if (!pageStackFormState) {
                return ''
            }
            const formState = pageStackFormState?.[blockId]
            if (!formState) {
                return ''
            }
            const formStateValue = formState.value
            if (formState.fieldType === 'date' && isFormat) {
                return typeof formStateValue === 'number' ? lightFormat(formStateValue, 'yyyy-MM-dd HH:mm:ss') : formStateValue
            }

            return formStateValue
        }
        default: {
            return ''
        }
    }
}

const generateSingleVariableValue = (c: TiptapEditorJSONContent, extraParams?: AnyObject, isFormat?: boolean) => {
    // const c = jsonContent.content?.[0]
    let isArray = false
    // 2024-01-12 填上游数据转换，出来数据是两层数组的坑
    let needFlat = false

    const variableValue = c
        ? c.content?.map(item => {
              if (item.type === 'text') {
                  return item.text ?? ''
              }
              if (item.type === 'date') {
                  if (isFormat) {
                      const date = item.attrs?.value?.valueVariable.value
                      return date ? lightFormat(date, 'yyyy-MM-dd HH:mm:ss') : ''
                  }
                  return item.attrs?.value?.valueVariable.value
              }
              if (item.type === 'select') {
                  isArray = true
                  return item.attrs?.value?.valueVariable.value
              }
              if (item.type === 'checkbox') {
                  const val = item.attrs?.value?.valueVariable.value
                  return val === undefined ? undefined : val
              }
              if (item.type === 'person') {
                  isArray = true
                  const userId = item.attrs?.value?.valueVariable.value
                  if (!userId) {
                      return ''
                  }
                  // const [variableType] = user
                  if (userId === '{currentUserId}') {
                      return extraParams?.clickTriggerNodeParams.currentUserId
                  }
                  return userId
              }
              if (item.type === 'variable') {
                  if (!item?.attrs?.value) {
                      return ''
                  }
                  const variableData = item.attrs.value as VariableADTvalue

                  //   针对传入 valueVariable 的数据进行处理，如果是 select、checkbox、person 类型的数据，需要返回数组
                  if (
                      variableData.type === 'VALUE' &&
                      variableData.valueVariable?.type &&
                      ['select', 'person'].includes(variableData.valueVariable?.type)
                  ) {
                      isArray = true
                  }
                  const res = generateAdtValue(variableData, extraParams, isFormat)

                  if (res.needFlat) {
                      isArray = true
                      needFlat = true
                      return res.value
                  }
                  return res
              }
              return ''
          }) || ['']
        : ['']

    return isArray ? (needFlat ? variableValue?.flat().filter(Boolean) : variableValue?.filter(Boolean)) : variableValue?.join('') || ''
}

export const generateVariableValue = (params: {
    jsonContent: TiptapEditorJSONContent
    extraParams?: AnyObject
    isRichText?: boolean
    isFormat?: boolean
}) => {
    const { jsonContent, extraParams, isRichText, isFormat } = params
    if (!isRichText) {
        const c = jsonContent.content?.[0]

        return c ? generateSingleVariableValue(c, extraParams, isFormat) : ''
    }

    return jsonContent.content?.map(c => generateSingleVariableValue(c, extraParams, isFormat)).join('\n') ?? ''
}

export const generateFieldVariableValue = (field: NodeFieldContent, extraParams?: AnyObject) => {
    const { fieldId, value } = field

    const v = generateVariableValue({ jsonContent: value, extraParams: { ...extraParams, sourceFieldId: fieldId } })

    return {
        fieldId,
        value: v
    }
}

export const getCreateRecordVariableValue = (config: CreateRecordActionConfig, extraParams?: AnyObject) => {
    const { fields } = config

    return fields.map(field => generateFieldVariableValue(field, extraParams))
}

export const getUpdateRecordVariableValue = (config: UpdateRecordActionConfig, extraParams?: AnyObject) => {
    const { fields, selectType, nodeId } = config

    const fieldsParams = fields.map(field => generateFieldVariableValue(field, extraParams))

    // 暂时这样处理，去掉了选择指定记录的逻辑，目前这块逻辑只有选择上游节点
    const recordId = selectType === 'UPSTREAM' ? extraParams?.[nodeId]?.record?.id : ''

    return {
        recordId,
        fields: fieldsParams
    }
}

export const getFindSingleRecordVariableValue = (config: FindSingleRecordActionConfig, extraParams?: AnyObject) => {
    const { dataSourceId, sort, filter } = config
    const { currentAppId, currentEnvId } = extraParams?.clickTriggerNodeParams || {}
    const resolvedFilter = resolveFilter({ filter, extraParams })

    // const pageId = extraParams?.getCurrentPageDeps()?.pageId ?? ''

    return {
        appId: currentAppId,
        envId: currentEnvId,
        dsId: dataSourceId,
        sort,
        filter: resolvedFilter
        // pageId
    }
}

export const getActiveAddIClickUserVariableValue = (config: IClickActionConfig, extraParams?: AnyObject) => {
    const { iClickId, name, mobile, email } = config

    if (!name || !mobile || !email) {
        return
    }

    const nameVariableValues = generateVariableValue({ jsonContent: name, extraParams })
    const mobileVariableValues = generateVariableValue({ jsonContent: mobile, extraParams })
    const emailVariableValues = generateVariableValue({ jsonContent: email, extraParams })

    const nameString = Array.isArray(nameVariableValues) ? '' : nameVariableValues
    const mobileString = Array.isArray(mobileVariableValues) ? '' : mobileVariableValues
    const emailString = Array.isArray(emailVariableValues) ? '' : emailVariableValues

    return {
        iClickId,
        name: nameString,
        mobile: mobileString,
        email: emailString
        // pageId: extraParams?.getCurrentPageDeps()?.pageId ?? ''
    }
}

export const getEmails = (emailParticipants: EmailParticipant[], extraParams?: AnyObject) => {
    return emailParticipants.map(item => {
        const { type, input, ref, identifier } = item
        switch (type) {
            case 'COMMITTER': {
                return ''
            }
            case 'USER_IDENTIFIER': {
                // TODO: 暂时没有哦，后续再处理，有邮箱字段了再说
                // return identifier
                return ''
            }
            case 'UPSTREAM': {
                if (!ref) {
                    return ''
                }
                const { nodeId, fieldId } = ref
                const record = extraParams?.[nodeId]?.record
                if (!record || !record.content[fieldId]) {
                    return ''
                }
                return record.content[fieldId].value as string
            }
            default: {
                return input ?? ''
            }
        }
    })
}

export const getDingTalkRobotVariableValue = (
    config: SendMsgDingTalkRobotConfig,
    extraParams?: AnyObject
): ActiveDingTalkRobotActionPayload['config'] => {
    const { msgtype, at, text, link, markdown, id } = config

    switch (msgtype) {
        case 'link': {
            const linkTitleValue = link ? generateVariableValue({ jsonContent: link.editorTitle, extraParams }) : ''
            const linkTextValue = link ? generateVariableValue({ jsonContent: link.editorText, extraParams }) : ''
            const linkMessageValue = link ? generateVariableValue({ jsonContent: link?.editorMessageUrl, extraParams }) : ''
            const linkValue = {
                title: Array.isArray(linkTitleValue) ? '' : linkTitleValue,
                text: Array.isArray(linkTextValue) ? '' : linkTextValue,
                picUrl: link ? link.picUrl : '',
                messageUrl: Array.isArray(linkMessageValue) ? '' : linkMessageValue
            }
            return {
                id,
                msgtype,
                at,
                link: linkValue
            }
        }
        case 'markdown': {
            const markdownTextValue = markdown ? generateVariableValue({ jsonContent: markdown.editorText, extraParams }) : ''
            return {
                id,
                msgtype,
                text: { content: Array.isArray(markdownTextValue) ? '' : markdownTextValue }
            }
        }

        default: {
            const textContentValue = text ? generateVariableValue({ jsonContent: text.editorContent, extraParams }) : ''
            return {
                id,
                msgtype,
                text: { content: Array.isArray(textContentValue) ? '' : textContentValue },
                at
            }
        }
    }
}

type DownloadFileInfo = {
    fieldName: string
    files: PreviewFile[]
}

/**
 * 下载动作与动作流解析附件变量
 * @param config 下载动作配置
 * @param extraParams
 * @returns
 */
export const resolveDownloadFileUrls = (config: DownloadFileActionConfig, extraParams?: AnyObject): DownloadFileInfo | undefined => {
    const { fileUrl } = config

    if (!fileUrl) {
        return
    }

    const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList

    switch (fileUrl.type) {
        case VariableType.UPSTREAM: {
            const { upstreamVariable } = fileUrl

            if (!upstreamVariable || !upstreamVariable.nodeId || !upstreamVariable.fieldId) {
                return
            }

            const { nodeId, fieldId } = upstreamVariable

            const record = extraParams?.[nodeId]?.record

            const dsId = getUpstreamRealDsId(nodeId, extraParams?.nodes)

            const dataSource = dataSourceList?.find(({ id }) => id === dsId)
            const fieldName = dataSource?.schema[fieldId]?.name ?? ''
            const fieldValue = record?.content?.[fieldId]?.value
            if (!Array.isArray(fieldValue)) {
                return
            }
            const files = fieldValue.map(url => {
                const fileName = getFileNameByUrl(url) || ''
                return {
                    name: fileName,
                    type: getFileTypeByUrl(fileName),
                    url
                }
            })
            return { fieldName, files }
        }
        case VariableType.USER: {
            const { userVariable } = fileUrl

            if (!userVariable || !userVariable.fieldId) {
                return
            }

            const { fieldId } = userVariable

            const userRecord = extraParams?.userRecord?.record

            const fieldValue = userRecord?.content?.[fieldId]?.value
            if (Array.isArray(fieldValue)) {
                const files = fieldValue.map(url => {
                    const fileName = getFileNameByUrl(url) || ''
                    return {
                        name: fileName,
                        type: getFileTypeByUrl(fileName),
                        url
                    }
                })
                return { fieldName: '头像', files }
            }
            return
        }
        case VariableType.VIEW: {
            const { viewVariable } = fileUrl

            if (!viewVariable || !viewVariable.fieldId) {
                return
            }

            const { fieldId } = viewVariable

            const viewRecord = extraParams?.viewRecord

            const fieldValue = viewRecord?.content?.[fieldId]?.value
            if (Array.isArray(fieldValue)) {
                const files = fieldValue.map(url => {
                    const fileName = getFileNameByUrl(url) || ''
                    return {
                        name: fileName,
                        type: getFileTypeByUrl(fileName),
                        url
                    }
                })
                return { fieldName: '头像', files }
            }
            return
        }
        case VariableType.VALUE: {
            const { valueVariable } = fileUrl

            if (!valueVariable) {
                return
            }

            const { type, value } = valueVariable

            if (type !== 'file') {
                return undefined
            }
            const files = value.map(url => {
                const fileName = getFileNameByUrl(url) || ''
                return {
                    name: fileName,
                    type: getFileTypeByUrl(fileName),
                    url
                }
            })
            return { fieldName: '', files }
        }
        case VariableType.PAGE: {
            const { pageVariable } = fileUrl
            const { type, fieldId = '', dsId } = pageVariable ?? {}
            let record = null
            if (type === 'page') {
                record = extraParams?.pageRecord
            } else {
                record = extraParams?.clickTriggerNodeParams?.prevRecord
            }

            const dataSource = dataSourceList?.find(({ id }) => id === dsId)
            const fieldName = dataSource?.schema[fieldId]?.name ?? ''

            const fieldValue = record?.content?.[fieldId]?.value
            if (!Array.isArray(fieldValue)) {
                return
            }
            const files = fieldValue.map(url => {
                const fileName = getFileNameByUrl(url) || ''
                return {
                    name: fileName,
                    type: getFileTypeByUrl(fileName),
                    url
                }
            })
            return { fieldName, files }
        }
        default:
    }
}

export const downloadFileWithUrl = async (params: { fileName?: string; url: string }) => {
    const { fileName = '', url } = params

    try {
        const response = await fetch(url, { cache: 'no-cache' })
        const blob = await response.blob()

        // 创建一个隐藏的<a>元素，并设置其属性
        const downloadLink = document.createElement('a')
        downloadLink.href = window.URL.createObjectURL(blob)
        downloadLink.download = fileName

        // 触发点击事件以下载文件
        downloadLink.click()
        downloadLink.remove()

        // 释放URL对象
        window.URL.revokeObjectURL(downloadLink.href)
    } catch (error) {
        console.error('Error downloading image:', error)
    }
}

/**
 * 专用于下载动作、动作流中附件内容
 * @param downloadFileUrl
 */
export const downloadFile = async (downloadFileParams: DownloadFileInfo) => {
    const { fieldName, files } = downloadFileParams
    // 单个文件直接下载
    if (files?.length === 1) {
        downloadFileWithUrl({ url: files[0].url })
    }

    if (files?.length > 1) {
        const zip = new JSZip()
        const folder = zip.folder(fieldName)
        const withBlobFiles = await Promise.all(
            files.map(async file => ({
                name: file.name,
                blob: await fetch(file.url, { cache: 'no-cache' }).then(response => response.blob())
            }))
        )

        for (const withBlobFile of withBlobFiles) {
            folder?.file(withBlobFile.name, withBlobFile.blob)
        }

        const content = await zip.generateAsync({ type: 'blob' })

        const url = URL.createObjectURL(content)
        const fileName = `${fieldName}.zip`

        downloadFileWithUrl({ fileName, url })
    }
}

/**
 *  变量解析
 * @param variable 变量
 * @param extraParams
 * @returns
 */
export const resolveVariable = (variable: VariableADTvalue, extraParams?: AnyObject): FieldCellValue | undefined => {
    if (!variable) {
        return
    }

    switch (variable.type) {
        case VariableType.UPSTREAM: {
            const { upstreamVariable } = variable

            if (!upstreamVariable || !upstreamVariable.nodeId || !upstreamVariable.fieldId) {
                return
            }

            const { nodeId, fieldId } = upstreamVariable

            const record = extraParams?.[nodeId]?.record
            return record?.content?.[fieldId]?.value
        }
        case VariableType.USER: {
            const { userVariable } = variable

            if (!userVariable || !userVariable.fieldId) {
                return
            }

            const { fieldId } = userVariable

            const userRecord = extraParams?.userRecord?.record

            return userRecord?.content?.[fieldId]?.value
        }
        case VariableType.VALUE: {
            const { valueVariable } = variable

            if (!valueVariable) {
                return
            }

            const { type, value } = valueVariable

            return value
        }
        case VariableType.PAGE: {
            const { pageVariable } = variable
            const { type, fieldId = '', dsId } = pageVariable ?? {}
            let record = null
            if (type === 'page') {
                record = extraParams?.pageRecord
            } else {
                record = extraParams?.clickTriggerNodeParams?.prevRecord
            }

            return record?.content?.[fieldId]?.value
        }
        case VariableType.VIEW: {
            const { viewVariable } = variable
            const { fieldId = '' } = viewVariable ?? {}
            const record = extraParams?.viewRecord
            return record?.content?.[fieldId]?.value
        }
        case VariableType.PAGE_LINK: {
            const value = variable.pageLinkVariable?.value
            return value === 'CURRENT_PAGE' ? extraParams?.getCurrentPageLink?.() : ''
        }
        default:
    }
}

/**
 * ------------------  runner action engine  ------------------
 */
export const getWechatPayType = function () {
    const isMobile = getApplicationPreviewType() === ApplicationPreviewEnum.mobile
    const isWechat = isWechatBrowser()
    if (isMobile) {
        if (isWechat) {
            return WechatPayType.JSAPI
        }
        return WechatPayType.H5
    }
    return WechatPayType.NATIVE
}

export const getSearchParams = function (params: Record<string, string | number>) {
    return Object.entries(params).reduce((pre, [k, v]) => (v === undefined ? pre : `${pre}${pre === '' ? '' : '&'}${k}=${v}`), '')
}
