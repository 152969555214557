import type { DTFile, FieldADTValue, FieldProtocol, FileValue } from '@lighthouse/core'
import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'

import { useApplicationContext } from '../../contexts'
import type { EnableActionsParams } from '../../types'
import { FileUploader } from '../FileUploader'
import type { UseUploadFileSParameter } from '../UploadManage'

const SCxDiv = styled.div`
    width: 100%;
`

interface FileValueEditorProps {
    recordId: string
    field: FieldProtocol
    data?: FileValue
    enableFileDownload?: boolean
    uploadyOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    onCellChange?: (recordId: string, data: FieldADTValue) => Promise<boolean>
}

export const FileValueEditor: React.FC<FileValueEditorProps> = ({
    recordId,
    data,
    field,
    uploadyOptions,
    enableFileDownload,
    onCellChange
}) => {
    const enableItemActions: EnableActionsParams = { canDownload: enableFileDownload, canPreview: true, canRename: true, canDelete: true }
    const { isBuilder } = useApplicationContext()
    const handleChange = useCallback(
        (data: DTFile[]) => {
            onCellChange?.(recordId, { ...field, type: 'file', value: data })
        },
        [field, onCellChange, recordId]
    )

    return (
        <SCxDiv>
            <FileUploader
                isPexels={isBuilder}
                uploadyOptions={uploadyOptions}
                enableItemActions={enableItemActions}
                onChange={handleChange}
                value={data}
            />
        </SCxDiv>
    )
}
