const assetsEnvironment = ['dev', 'dev01', 'dev02', 'dev03', 'test01', 'pre', 'prod'] as const
type AssetsEnvironment = typeof assetsEnvironment[number]

// 用户信息相关静态资源 oss 地址
export const OSS_USER_DATA_PREFIX = 'https://byecode-userdata-prod.oss-cn-shanghai.aliyuncs.com'

// 开发和测试环境，直接走 oss 地址，生产环境走 cdn 地址
/**
 * oss 地址区分：
 * 开发环境：https://byecode-libs.oss-cn-shanghai.aliyuncs.com/libs-dev/frontend/{builder|runner}
 * 测试环境：https://byecode-libs.oss-cn-shanghai.aliyuncs.com/libs-test/frontend/{builder|runner}
 */
/**
 * cdn 地址区分：
 * 生产环境：https://cdn.lib.byecode.com/frontend/{builder|runner}
 */

const { VITE_SENTRY_ENVIRONMENT, VITE_CLIENT } = import.meta.env

// oss 地址前缀
const OSS_DOMAIN_BASE_PREFIX = `https://byecode-libs.oss-cn-shanghai.aliyuncs.com/frontend-${VITE_SENTRY_ENVIRONMENT}`
// 生产环境 cdn 地址前缀
const CDN_DOMAIN_PREFIX = 'https://cdn.lib.byecode.com/frontend'

const WITH_ENVIRONMENTAL_ASSETS_PREFIX_URL = VITE_SENTRY_ENVIRONMENT
    ? VITE_SENTRY_ENVIRONMENT === 'prod'
        ? CDN_DOMAIN_PREFIX
        : OSS_DOMAIN_BASE_PREFIX
    : ''

// 区分管理端、应用端，后续需要通过构建端环境变量区分
const APP_PREFIX = VITE_CLIENT

export const OSS_DOMAIN_PREFIX = `${WITH_ENVIRONMENTAL_ASSETS_PREFIX_URL}/${APP_PREFIX}/static` as const
export const OSS_EMPTY_THUMB_PREFIX = `${OSS_DOMAIN_PREFIX}/empty/` as const
export const OSS_COMMON_THUMB_PREFIX = `${OSS_DOMAIN_PREFIX}/common/` as const
export const OSS_BLOCKS_THUMB_PREFIX = `${OSS_DOMAIN_PREFIX}/blocks/` as const
export const OSS_BLOCKS_BACKEND_PREFIX = `${OSS_DOMAIN_PREFIX}/backend/` as const
export const OSS_FLAG_THUMB_PREFIX = `${OSS_DOMAIN_PREFIX}/flag/` as const
export const I_CLICK_OSS_DOMAIN_PREFIX = `${OSS_DOMAIN_PREFIX}/iclick/` as const
export const OSS_FONTS_PREFIX = `${OSS_DOMAIN_PREFIX}/font/` as const

// 解决之前静态文件不规范的问题，最开始有项目更目录下的 public 文件夹，后来统一放到 @lighthouse/assets 对应静态资源的
export const OSS_ALIPLAYER_PREFIX = `${OSS_DOMAIN_PREFIX}/aliplayer/` as const
export const OSS_ICONS_PREFIX = `${OSS_DOMAIN_PREFIX}/icons/` as const
export const OSS_JS_PREFIX = `${OSS_DOMAIN_PREFIX}/js/` as const
export const OSS_GUIDE_PREFIX = `${OSS_DOMAIN_PREFIX}/guide/` as const
export const OSS_SPACE_PREFIX = `${OSS_DOMAIN_PREFIX}/space/` as const
// 给柯霖新加的 application
export const OSS_APPLICATION_PREFIX = `${OSS_DOMAIN_PREFIX}/application/` as const
// 纹理
export const OSS_VEINS_PREFIX = `${OSS_DOMAIN_PREFIX}/veins/` as const
// 应用发布海报
export const OSS_POSTER_PREFIX = `${OSS_DOMAIN_PREFIX}/poster/` as const
// lottie 动效文件
export const OSS_LOTTIE_PREFIX = `${OSS_DOMAIN_PREFIX}/lottie/` as const

type AssetsPrefix =
    | typeof OSS_EMPTY_THUMB_PREFIX
    | typeof OSS_COMMON_THUMB_PREFIX
    | typeof OSS_BLOCKS_THUMB_PREFIX
    | typeof OSS_BLOCKS_BACKEND_PREFIX
    | typeof OSS_FLAG_THUMB_PREFIX
    | typeof I_CLICK_OSS_DOMAIN_PREFIX
    | typeof OSS_FONTS_PREFIX
    /**
     * 项目老资源遗留
     */
    // 阿里云播放器
    | typeof OSS_SPACE_PREFIX
    | typeof OSS_ALIPLAYER_PREFIX
    // 图标选择器
    | typeof OSS_ICONS_PREFIX
    // js 资源
    | typeof OSS_JS_PREFIX
    // 新手引导
    | typeof OSS_GUIDE_PREFIX
    | typeof OSS_APPLICATION_PREFIX
    | typeof OSS_VEINS_PREFIX
    | typeof OSS_POSTER_PREFIX
    | typeof OSS_LOTTIE_PREFIX

type AssetsNamespace<U> = U extends `${typeof OSS_DOMAIN_PREFIX}/${infer N}/` ? N : never

type Namespace = AssetsNamespace<AssetsPrefix>

export const getAssetUrl = (namespace: Namespace, fileName: string) => {
    if (!VITE_SENTRY_ENVIRONMENT) {
        return `/static/${namespace}/${fileName}`
    }
    return `${OSS_DOMAIN_PREFIX}/${namespace}/${fileName}`
}

// export {}
