import { backgroundTransitionOnClick, IconFont } from '@byecode/ui'
import React from 'react'
import styled, { css } from 'styled-components'

interface TagIconProps extends React.InputHTMLAttributes<HTMLDivElement> {
    icon?: string
    iconSize?: number
    iconColor?: string
    size?: number
    label?: string | React.ReactNode
    className?: string
    radius?: number
    color?: string
    enableHover?: boolean
    hoverColor?: string
    background?: string
    isActive?: boolean
    style?: React.CSSProperties
    onClick?: React.MouseEventHandler
}

const SCxIconWrapper = styled.div<{
    size?: number
    radius?: number
    background?: string
    enableHover?: boolean
    hoverColor?: string
    isActive?: boolean
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ size }) => size ?? 16}px;
    height: ${({ size }) => size ?? 16}px;
    border-radius: ${({ radius }) => radius}px;
    color: ${({ color }) => color};
    background: ${({ background }) => background};

    ${({ enableHover, hoverColor }) =>
        enableHover &&
        css`
            &:hover {
                cursor: pointer;
                background-color: ${hoverColor};
                ${backgroundTransitionOnClick}
            }
        `}

    ${({ isActive, hoverColor }) =>
        isActive &&
        css`
            background-color: ${hoverColor ?? 'var(--color-gray-100)'};
        `}
`

export const TagIcon = React.forwardRef<HTMLDivElement, TagIconProps>(
    ({ iconSize = 16, radius, label, icon, size, color, iconColor, background, className, enableHover, ...rest }, ref) => {
        return (
            <SCxIconWrapper
                ref={ref}
                className={className}
                size={size}
                radius={radius}
                enableHover={enableHover}
                color={color}
                background={background}
                {...rest}
            >
                {label}
                {icon && <IconFont size={iconSize} type={icon} color={iconColor} />}
            </SCxIconWrapper>
        )
    }
)
