import type { RecordLikeProtocol } from '@lighthouse/core'
import { useCallback } from 'react'
import { useSetState } from 'react-use'

import type { RelationRecordSelectProps, RelationRecordSelectState } from './types'

export interface RelativeDatasourceState {
    loading: boolean
    initLoading: boolean
    noNexData: boolean
    keywords: string
    searchRecordList: RecordLikeProtocol[]
}

export const useRelativeDatasource = function (params: Pick<RelationRecordSelectProps, 'onFetchDataSource' | 'onLoadMoreData' | 'dsId'>) {
    const { onFetchDataSource, onLoadMoreData, dsId } = params

    const [state, setState] = useSetState<RelativeDatasourceState>({
        loading: false,
        initLoading: false,
        noNexData: false,
        keywords: '',
        searchRecordList: []
    })
    const { searchRecordList, keywords } = state

    const handleFetchData = useCallback(
        async (search?: string) => {
            if (onFetchDataSource) {
                setState({ noNexData: false })
                const dataSource = await onFetchDataSource({ dsId, pagination: { currentPage: 1, pageSize: 50 }, search })
                setState({ initLoading: false })

                if (dataSource) {
                    setState({ searchRecordList: dataSource?.records ?? [] })
                }
            }
        },
        [dsId, onFetchDataSource, setState]
    )

    const handleLoadMoreData = useCallback(
        async (page: number) => {
            setState({ loading: true })
            const recordsData = await onLoadMoreData?.({
                dsId,
                pagination: { currentPage: page, pageSize: 50 },
                search: keywords
            })
            setState({ loading: false })
            if ((recordsData ?? [])?.length < 50) {
                setState({ noNexData: true })
            }
            if (recordsData) {
                setState({ searchRecordList: [...searchRecordList, ...(recordsData ?? [])] })
            }
        },
        [dsId, keywords, onLoadMoreData, searchRecordList, setState]
    )

    const handleChangeSearch = useCallback(
        (value: string) => {
            if (value === '') {
                handleFetchData('')
            }
            setState({ keywords: value })
        },
        [handleFetchData, setState]
    )

    const handleChangeInitLoading = useCallback(
        (value: boolean) => {
            setState({ initLoading: value })
        },
        [setState]
    )

    return { state, handleLoadMoreData, handleFetchData, handleChangeSearch, handleChangeInitLoading }
}
