import { CascadeList, Popover } from '@byecode/ui'
import { type FilterBlockItemConfig, type FilterOption, FilterSelectWay } from '@lighthouse/core'
import { CheckButton } from '@lighthouse/shared'
import { intersection } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

const SCxTileFilterList = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;
    flex-wrap: wrap;
`
interface TileCascadeFilterItemProps {
    config: FilterBlockItemConfig
    option: FilterOption
    value: string[]
    onChange: (val: string[]) => void
}

export const TileCascadeFilterItem: React.FC<TileCascadeFilterItemProps> = ({ config, option, value, onChange }) => {
    const [open, setOpen] = useState(false)

    // const [val, setVal] = useState<string[]>(value)

    const handleSelect = useCallback(
        (v: string) => {
            if (value.includes(v)) {
                const newVal = value.filter(d => d !== v)
                // setVal(newVal)
                onChange(newVal)
                return
            }
            onChange([v])
        },
        [onChange, value]
    )

    const handleChange = useCallback(
        (v: string[]) => {
            const realVal = intersection(value, v)
            onChange(realVal.length > 0 ? [] : v)
            setOpen(false)
        },
        [onChange, value]
    )

    const getFlatOptions = useCallback((opts: FilterOption[] = []) => {
        return opts.reduce<FilterOption[]>((prev, cur) => {
            prev.push({
                label: cur.label,
                value: cur.value
            })
            if (cur.children) {
                prev = [...prev, ...getFlatOptions(cur.children)]
            }
            return prev
        }, [])
    }, [])

    const isActive = useMemo(() => {
        const opts = [{ label: option.label, value: option.value }, ...getFlatOptions(option.children)]
        const values = opts.map(item => item.value)
        const selectValues = intersection(values, value)
        return selectValues.length > 0
    }, [getFlatOptions, option.children, option.label, option.value, value])

    return useMemo(() => {
        if (!option?.children || option.children.length === 0) {
            return <CheckButton key={option.value} label={option.label} active={isActive} onClick={() => handleSelect(option.value)} />
        }
        return (
            <Popover opened={open} onChange={setOpen} withinPortal width="auto">
                <Popover.Target>
                    <CheckButton key={option.value} label={option.label} active={isActive} isSuffixIcon />
                </Popover.Target>
                <Popover.Dropdown>
                    <CascadeList
                        data={value}
                        onChange={handleChange}
                        multiple={config.selectWay === FilterSelectWay.multiSelect}
                        lastLevel
                        options={option.children}
                    />
                </Popover.Dropdown>
            </Popover>
        )
    }, [config.selectWay, handleChange, handleSelect, isActive, open, option.children, option.label, option.value, value])
}

interface TileCascadeFilterProps extends Pick<TileCascadeFilterItemProps, 'config' | 'value'> {
    options: FilterOption[]
    onChange: (val: string[]) => void
}

export const TileCascadeFilter: React.FC<TileCascadeFilterProps> = ({ config, value, options, onChange }) => {
    return (
        <SCxTileFilterList>
            {options.map(option => {
                const { label, value: itemValue, children } = option
                // const childValues = [{ label, value: itemValue }, ...getFlatOptions(children || [])].map(item => item.value)
                // const realValue = intersection(childValues, value)
                return <TileCascadeFilterItem key={itemValue} option={option} value={value} config={config} onChange={onChange} />
            })}
        </SCxTileFilterList>
    )
}
