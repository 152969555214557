import type {
    AiFieldState,
    ApprovalFieldInput,
    DataSourceAbstract,
    DataSourceIdentity,
    DataSourceSynchronized,
    DataSourceType,
    Field,
    FieldInputADTValue,
    FieldInputValue,
    FilterCommonCondition,
    FilterFormType,
    RecordLikeProtocol,
    SchemaProtocol,
    SelectedMode,
    Sorter
} from '@lighthouse/core'

import type { InsertDirection } from '../components'
import type { ErrorCode } from '../constants'
import type { SendMsgDingTalkRobotConfig, SendMsgWxRootConfig } from './flow'

export interface RoleOption {
    label: string
    value: string
    describe?: string
    color?: string
    disable?: boolean
}

export interface Workspace {
    icon?: string
    createdBy: string
    createdTime: number
    members: number
    name: string
    id: string
    updatedBy: string
    updatedTime: number
}

export interface CreateTeamPayload {
    name: string
    parentId?: string
}

export interface UpdateTeamPayload {
    name: string
    avatar?: string
}

export interface ResetPasswordPayload {
    mobile: string
    smsCode: string
    password: string
}
/** @description  0 未发布 1 已发布 2 已下线 */
export type ApplicationPublishState = 0 | 1 | 2

export type ApplicationPublishUpdateState = 0 | 1 // 0 无更新 1 可以更新
export interface ApplicationPublishInfo {
    /** @description  0 未发布 1 已发布 2 已下线 */
    status: ApplicationPublishState
    hasChanged: boolean
    domain: string
    suffix: string
    customDomain?: string
    bindStatus: boolean
    publishTime?: number
    env: 'dev' | 'test'
}

export type DataSourceItemAction = 'DELETE' | 'RENAME' | 'DUPLICATE' | 'FINDUSE'

export type DataSourceAction = 'CREATE' | 'IMPORT' | 'SYNCHRONIZE' | 'CREATE_AGGREGATE'

export type SynchronizeParams = {
    appId: string
    envId: string
    dsId: string
}

export type AggregatorParams = {
    appId: string
    dsId: string
}

export type JoinParams = {
    appId: string
    dsId: string
}

export type ImportParams = {
    envId: string
    file: File
    sheetName: string
}

export type ExportParams = {
    dsId: string
    envId: string
    name: string
    fileType: 'xlsx' | 'csv' | 'xls'
}

export type AppendParams = {
    file: File
    sheetDto: {
        envId: string
        dsId: string
        sheetName: string
        fields: {
            sheetFieldNo: string
            dsFieldId: string
            matchFieldId: string
        }[]
        triggerWorkflow?: boolean
        repeat?: boolean
        importMode?: string
        compareFields?: string[]
    }
}

export type ViewAppendParams = {
    file: File
    sheetDto: {
        dsId: string
        sheetName: string
        fields: {
            sheetFieldNo: string
            dsFieldId: string
            matchFieldId: string
        }[]
        triggerWorkflow?: boolean
        repeat?: boolean
        importMode?: string
        compareFields?: string[]
    }
}

export type DsExportParams = {
    envId: string
    search?: string
    dsId: string
    mode?: SelectedMode
    recordIds?: string[]
    // exportFields?: string[]
    fileType: 'xlsx' | 'csv'
    filter?: FilterFormType
    sorts?: Sorter[]
}

export type ViewPrintWithTemplateParams = {
    appId: string
    search?: string
    dsId: string
    quickFilters?: FilterCommonCondition[]
    sorts: Sorter[]
    templateId: string
    mode?: SelectedMode
    recordIds?: string[]
    currentRecordId?: string
    parentRecordId?: string
    linkFilter?: FilterFormType
}

export type ViewExportParams = {
    search?: string
    // dsId: string
    viewId: string
    quickFilters?: FilterCommonCondition[]
    sorts: Sorter[]
    mode?: SelectedMode
    recordIds?: string[]
    // exportFields?: string[]
    fileType: 'xlsx' | 'csv'
    currentRecordId?: string
    parentRecordId?: string
    linkFilter?: FilterFormType
}

export type ChartExportParams = {
    blockId: string
    currentRecordId?: string
    parentRecordId?: string
    linkFilter?: FilterFormType
}

export interface WxRootItem {
    webhook: string
}

export interface DingTalkRobotItem {
    webhook: string
    secret?: string
}

export interface IClickAccountItem {
    token: string
}

export interface WeChatPublicAccountItem {
    appId: string
    appSecret: string
    domainFileName: string
    domainFileContent: string
    scope: 'snsapi_userinfo' | 'snsapi_base'
    encodingAesKey?: string
    token?: string
}

export interface WeChatWebsiteAccountItem {
    appId: string
    appSecret: string
}

export interface WeChatPayAccountItem {
    mchId: string
    appId: string
    apiV3Key: string
    mchPrivateKey: string
    mchSerialNo: string
    appSecret: string
}
export interface AliyunSmsAccountItem {
    secret: string
    accessKey: string
}

export interface StripeAccountItem {
    publicKey: string
    secretKey: string
}

export interface AliyunMessageInfoPayload {
    id: string
}

export interface WeComInternalApp {
    cropId: string
    corpSecret: string
    callbackUrl: string
}
export interface AliyunMessageInfoRes {
    signatures: { name: string }[]
    templates: { id: string; name: string; content: string }[]
}

export type WxMessageInfoRes = { templateId: string; title: string; content: string }[]

export interface BrowsePath {
    spaceId?: string
    appId?: string
    pageId?: string
    viewId?: string
    recordId?: string
}

export interface EmailAccountItem {
    senderName: string
    emailAddress: string
    password: string
}

export type SenderAccountType = 'WE_COM_MAIL' | 'NET_EASE_126_MAIL'

export type EmailParticipantRef = {
    nodeId: string
    fieldId: string
}

export type ApproverUser = {
    identifierId: string
    identifierType: 'USER' | 'ROLE'
}

export type EmailParticipant = {
    type: 'COMMITTER' | 'USER_IDENTIFIER' | 'UPSTREAM' | 'MANUAL_INPUT'
    identifier?: ApproverUser //  USER_IDENTIFIER COMMITTER
    ref?: EmailParticipantRef // UPSTREAM
    input?: string // MANUAL_INPUT
}

/** 头像大小规格 */
export type AvatarSize = 'large' | 'middle' | 'small' | 'xLarge' | number

export interface SelectOption {
    label: string
    value: string
}

export enum ApplicationPreviewEnum {
    desktop = 'desktop',
    mobile = 'mobile'
}

/**
 * file uploader enable actions
 */
export type EnableActionsParams =
    | {
          canDownload?: boolean
          canPreview?: boolean
          canRename?: boolean
          canDelete?: boolean
      }
    | boolean

export interface GenerateAiTableParams {
    prompt: string
    pageId: string
    blockId: string
}

const RelationTypes = ['oneToMany', 'oneToOne', 'manyToOne', 'manyToMany'] as const
export type RelationType = typeof RelationTypes[number]

export type DsGraphRawData = {
    dataSources: ({
        appId: string
        id: string
        name: string
        type: DataSourceType
        sync: DataSourceSynchronized
        viewOptions: { tableProps: { id: string; visible: boolean; width: number }[] }
    } & SchemaProtocol)[]
    refs: {
        id: string
        source: { appId: string; dsId: string; fieldId: string }
        target: { appId: string; dsId: string; fieldId: string }
        data: {
            relationType: RelationType
        }
    }[]
}

export type GenerateRelationshipParams = {
    id: string
    source: { appId: string; dsId: string; fieldId: string }
    target: { appId: string; dsId: string; fieldId: string }
    data: {
        relationType: RelationType
    }
}

export type ErCreateFieldParams = {
    appId: string
    dsId: string
    field: Field
    sourceId?: string
    direction?: InsertDirection
}

export type ErUpdateFieldParams = {
    appId: string
    dsId: string
    field: Field
}

export type ErDeleteFieldParams = {
    appId: string
    dsId: string
    field: Field
}

export type ErDisplayParams = {
    appId: string
    dsId: string
    viewOptions: DataSourceAbstract['viewOptions']
}

export type AuditStatus = 'ACCEPT' | 'REJECT'

export type MessageType = 'CARBON_COPY' | 'APPROVAL' | 'APPROVE_REFUSED' | 'APPROVE_PASSED' | 'STATION_MESSAGE'

type MessageListBaseMessageData = {
    id: string
    type: MessageType
    createdTime: string
    appName: string
}

export type MessageListStationMessageData = MessageListBaseMessageData & {
    type: 'STATION_MESSAGE'
    title: string
    content: string
}

export type MessageListApprovalMessageData = MessageListBaseMessageData & {
    type: 'CARBON_COPY' | 'APPROVAL' | 'APPROVE_REFUSED' | 'APPROVE_PASSED'
    recordId: string
    appName: string
    username: string
    auditStatus?: AuditStatus
    values: {
        fieldName: string
        value: string
    }[]
}

export type MessageListData = MessageListStationMessageData | MessageListApprovalMessageData

export type MessageListDataRes = {
    count: number
    messages: MessageListData[]
}

type MessageDetailBaseMessageData = {
    id: string
    type: MessageType
    createdTime: string
    appName: string
}

export type MessageDetailStationMessageData = MessageDetailBaseMessageData & {
    type: 'STATION_MESSAGE'
    title: string
    content: string
}

export type MessageDetailApprovalMessageData = MessageDetailBaseMessageData & {
    dsId: string
    recordId: string
    username: string
    schema: SchemaProtocol['schema']
    auditStatus?: AuditStatus
    values: ApprovalFieldInput[]
}

export type MessageDetailDataRes = MessageDetailStationMessageData | MessageDetailApprovalMessageData

export type BaseMessageData = {
    id: string
    // type: MessageType
}

export type StationMessageData = BaseMessageData & {
    type: 'STATION_MESSAGE'
    appName: string
    createTime: Date
    title: string
}

export type AiFieldMessageData = BaseMessageData & {
    type: 'TEXT_GEN_INVOKE'
    dataSourceId: string
    fieldId: string
    recordId: string
    state: AiFieldState
    content?: string
}

export type ApprovalMessageData = BaseMessageData & {
    type: 'CARBON_COPY' | 'APPROVAL' | 'APPROVE_REFUSED' | 'APPROVE_PASSED'
    // nodeId: string
    // workflowId: string
    // workflowInstanceId: string
    id: string
    title: string
    username: string
    fieldDesc: {
        fieldName: string
        value: string
    }[]
}

export type NotificationMessageData = StationMessageData | ApprovalMessageData | AiFieldMessageData

export type ApprovePayload = {
    msgId: string
    comment?: string
    record: {
        dsId: string
        recordId: string
        fields: {
            fieldId: string
            value: FieldInputADTValue['value']
        }[]
    }
}

export type MessageEvents = {
    onFetchMessages?: () => Promise<MessageListDataRes>
    onFetchMessageDetail?: (id: string) => Promise<MessageDetailDataRes>
    approveReject?: (payload: ApprovePayload) => Promise<boolean>
    approveAccept?: (payload: ApprovePayload) => Promise<boolean>
}

/**
 * 用于工作流中创建行时，传递数据
 */
export interface CreateRecordWithFieldsPayload {
    appId: string
    envId: string
    dsId: string
    fields: {
        fieldId: string
        value: FieldInputValue
    }[]
}

/**
 * 用于工作流中更新行时，传递数据
 */
export interface UpdateRecordWithFieldsPayload {
    appId: string
    envId: string
    dsId: string
    recordId: string
    fields: {
        fieldId: string
        value: FieldInputValue
    }[]
}

/**
 * 用于工作流中查询单行时，传递数据
 */
export interface ActiveFindSingleRecordActionPayload extends DataSourceIdentity {
    filter: FilterFormType
    sort: Sorter[]
}

export interface ActiveAddIClickUserActionPayload {
    iClickId: string
    name: string
    mobile: string
    email: string
}

// 动作、动作流用于发送微信消息接口
export interface ActiveWxRobotActionPayload {
    config: Omit<SendMsgWxRootConfig, 'msgtype' | 'text'> & {
        msgtype: SendMsgWxRootConfig['msgtype']
        text: {
            content: string
            mentioned_list: string[]
            mentioned_mobile_list: string[]
        }
    }
}

// 动作、动作流用于发送钉钉消息接口
export interface ActiveDingTalkRobotActionPayload {
    config: {
        id: string
        msgtype: SendMsgDingTalkRobotConfig['msgtype']
        text?: { content: string }
        at?: SendMsgDingTalkRobotConfig['at']
        link?: {
            title: string
            text: string
            picUrl: string
            messageUrl: string
        }
        markdown?: {
            title: string
            text: string
        }
    }
}

// 动作、动作流用于发送站内信接口
export interface ActiveStationMessageActionPayload {
    personList: ApproverUser[]
    title: string
    content: string
}

export interface WxTemplateMessageActionPayload {
    userIds: string[]
    templateId: string
    url: string
    values: { variableName: string; value: string }[]
}

export interface ActiveSendEmailActionPayload {
    senderAccountId: string
    title: string
    content: string
    // cc 抄送人
    cc: string[]
    // 收件人
    recipients: string[]
}

export interface AliyunMessageActionPayload {
    id: string
    mobileList: string
    signatureId: string
    templateId: string
    values: { variableName: string; value: string }[]
}

export type UserDataSourceData = {
    datasource?: DataSourceAbstract
    record?: RecordLikeProtocol
}
export interface TriggerAutomationActionPayload {
    flowId: string
    recordId?: string
}

type I18DateKey = 'beforeYesterday' | 'today' | 'tomorrow' | 'yesterday' | 'afterTomorrow'

export type I18TranslationKey =
    | 'bindMobile'
    | 'bindEmail'
    | 'firstLoginNeedAccount'
    | 'mobileCode'
    | 'noVerificationCodeReceived'
    | 'noNeedLogin'
    | 'registerAccount'
    | 'mobileLogin'
    | 'emailLogin'
    | 'wechatLogin'
    | 'emailUrl'
    | 'noAccount'
    | 'next'
    | 'goLogin'
    | 'alreadyHaveAnAccount'
    | 'selectRegisterWay'
    | 'perfectInfo'
    | 'verificationCode'
    | 'sure'
    | 'yourRole'
    | 'emailFormatIsIllegal'
    | 'mobilePhoneNumberFormatIsIllegal'
    | 'mobileRegister'
    | 'emailRegister'
    | 'email'
    | 'shortMessage'
    | 'pleaseInputYourEmail'
    | 'pleaseInputYourMobile'
    | 'pleaseInputYourValidEmail'
    | 'pleaseInputYourValidMobile'
    | 'getVerificationCode'
    | 'getAgain'
    | 'resendTime'
    | 'verificationMessage'
    | 'verificationSMS'
    | 'inputVerificationCode'
    | 'register'
    | 'notEmpty'
    | 'pleaseFillOut'
    | 'loginSuccess'
    | 'confirmMergeAccounts'
    | 'merge'
    | 'iAwareRisks'
    | 'deleteWechatAccountWarn'
    | 'mobileAlreadyRegister'
    | 'mobileAlreadyRegisterDesc'
    | 'cancel'
    | 'changeBindMobile'
    | 'mergeAccount'
    | 'MergeWeChatAccountDescription'
    | 'keepMobileAndDeleteWechat'
    | 'keepWechatAndDeleteMobile'
    | 'youAccountUnderReview'
    | 'backLoginPage'
    | 'search'
    | 'personNameOrPersonId'
    | 'selected'
    | 'noFindData'
     | 'noFindPerson'
    | 'goHome'
    | 'pleaseWeChatLoginInWeChatBrowser'
    | 'refresh'
    | 'expiredPleaseRefresh'
    | 'scanWechatLogin'
    | 'accountSetting'
    | 'logout'
    | 'notBind'
    | 'mobileNumber'
    | 'wechat'
    | 'removeBindAccountDescribe'
    | 'knew'
    | 'continueUnbinding'
    | 'notEnabledAccountLogin'
    | 'bindAfterUsedAccountLogin'
    | 'emailAccount'
    | 'alreadyBind'
    | 'wechatAccount'
    | 'bindAccountAfterAccountNotEnable'
    | 'bindMeetQuestion'
    | 'changeAccount'
    | 'goBind'
    | 'unbind'
    | 'notEnable'
    | 'bindMobileNumber'
    | 'bindMobileNumberAfterLoginApp'
    | 'enterSmsCode'
    | 'confirmUnbinding'
    | 'unBindNotLoginConfirmDescribe'
    | 'alreadyUnbindAccount'
    | ErrorCode
    | I18DateKey
