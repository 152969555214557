import type { DataSourceAbstract, RelativeSelectConfig, SelectField } from '@lighthouse/core'
import { max } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { minPopoverWidth, outlineWidth } from '../../constant'
import type { FieldBaseProps } from '../../types'
import { CheckBoxGroup } from './CheckBoxGroup'
import MultiSelect from './MultiSelect'
import * as SC from './styles'
import { TagGroup } from './TagGroup'

interface SelectFieldInputProps extends FieldBaseProps {
    value: string[]
    field?: SelectField | null
    relativeSelectConfig: RelativeSelectConfig
}

const SelectFieldInput: React.FC<SelectFieldInputProps> = props => {
    const { onCellChange, field, value: data, isControlled, readOnly, dataSource, relativeSelectConfig, onFetchDataSourceMeta } = props
    const { id: pointer } = dataSource ?? {}
    const { fieldPointer = '', placeholder, relativeSelect } = relativeSelectConfig
    const { showMode, direction } = relativeSelect ?? {}
    const { select } = field ?? {}
    const { options: selectOptions = [], multiple: canMultipleChoice } = select ?? {}

    const containerRef = useRef<HTMLDivElement>(null)
    const [value, setValue] = useState(data ?? [])
    const [newDataSource, setNewDataSource] = useState<DataSourceAbstract>()

    const [opened, setOpened] = useState(false)

    const usedValue = useMemo(() => (isControlled ? data : value), [data, isControlled, value])

    const options = useMemo(() => {
        if (newDataSource) {
            return ((newDataSource.schema?.[fieldPointer] as SelectField)?.select?.options ?? []).map(({ label, color }) => ({
                label,
                value: label,
                color,
                isDelete: !readOnly
            }))
        }
        return selectOptions.map(({ label, color }) => ({ label, value: label, color, isDelete: !readOnly }))
    }, [fieldPointer, newDataSource, readOnly, selectOptions])

    const inputWidth = containerRef.current?.getBoundingClientRect()?.width ?? minPopoverWidth

    const popoverWidth = useMemo(() => max(inputWidth, minPopoverWidth) + outlineWidth * 2, [inputWidth])

    const handleMultiChange = useCallback(
        (value: string[]) => {
            setValue(value)
            onCellChange?.({ type: 'relativeSelect', value })
        },
        [onCellChange]
    )

    useEffect(() => {
        if (pointer && opened) {
            onFetchDataSourceMeta?.({ dsId: pointer }).then(v => {
                setNewDataSource(dataSource)
            })
        }
    }, [dataSource, onFetchDataSourceMeta, opened, pointer])

    return useMemo(() => {
        switch (showMode) {
            case 'checkbox': {
                return (
                    <SC.Container>
                        <CheckBoxGroup
                            options={options}
                            isMultiple={canMultipleChoice}
                            direction={direction}
                            value={usedValue}
                            disabled={readOnly}
                            onChange={handleMultiChange}
                        />
                    </SC.Container>
                )
            }
            case 'tag': {
                return (
                    <SC.Container>
                        <TagGroup
                            options={options}
                            isMultiple={canMultipleChoice}
                            direction={direction}
                            value={usedValue}
                            disabled={readOnly}
                            onChange={handleMultiChange}
                        />
                    </SC.Container>
                )
            }
            default: {
                return (
                    <SC.Container data-field-border={opened} ref={containerRef}>
                        <MultiSelect
                            options={options}
                            opened={opened}
                            width={popoverWidth}
                            value={usedValue}
                            placeholder={placeholder}
                            readOnly={readOnly}
                            isMultiple={canMultipleChoice}
                            onChange={handleMultiChange}
                            onChangePopover={setOpened}
                        />
                    </SC.Container>
                )
            }
        }
    }, [canMultipleChoice, direction, handleMultiChange, opened, options, placeholder, popoverWidth, readOnly, showMode, usedValue])
}

export default SelectFieldInput
