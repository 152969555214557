import { createContext, useContext } from 'react'

/** 根页面id */
export const RootPageContext = createContext('')
export const RootPageProvider = RootPageContext.Provider
export const useRootPageContext = () => useContext(RootPageContext)

/** 当前页面id */
export const PageContext = createContext<{ pageId: string; refreshPage?: () => void }>({
    pageId: ''
})
export const PageProvider = PageContext.Provider
export const useCurrentPageContext = () => useContext(PageContext)

/** 当前stack id */
export const PageStackContext = createContext<string>('')
export const PageStackProvider = PageStackContext.Provider
export const useCurrentStackIdContext = () => useContext(PageStackContext)
