import { Checkbox, Radio } from '@byecode/ui'
import { Flex, Text } from '@mantine/core'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { COLORS_MAP } from '../../../../../constants/color'
import { directionMap } from '../../../constant'
import { Item } from './Item'

type Option = {
    label: string
    value: string
    color?: string
}

interface TagGroupProps {
    value?: string[]
    options: Option[]
    direction?: 'vertical' | 'horizontal'
    isMultiple?: boolean
    colorless?: boolean
    disabled?: boolean
    onChange: (v: string[]) => void
}

const SCxGroup = styled.div<Pick<React.CSSProperties, 'flexDirection'>>`
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${({ flexDirection }) => flexDirection};
    align-items: ${({ flexDirection }) => (flexDirection === 'column' ? 'flex-start' : 'center')};
    gap: 12px;
`
export const TagGroup: React.FunctionComponent<TagGroupProps> = ({
    value = [],
    options,
    direction = 'horizontal',
    colorless,
    isMultiple,
    disabled,
    onChange
}) => {
    const handleChange = useCallback(
        (v: string[]) => {
            onChange(isMultiple ? v : v.slice(-1))
        },
        [isMultiple, onChange]
    )

    return (
        <SCxGroup flexDirection={directionMap[direction]}>
            {options.map(option => {
                const checked = value?.includes(option.value)
                const color = option.color && `var(${COLORS_MAP[option.color]})`
                return isMultiple ? (
                    <Item
                        key={option.value}
                        data={option}
                        colorless={colorless}
                        prefix={
                            colorless ? null : (
                                <Flex gap={8}>
                                    <Checkbox checked={checked} radius="50%" color={color} size="xs" value={option.value} />
                                    <Text>{option.label}</Text>
                                </Flex>
                            )
                        }
                        onMouseDown={() => !disabled && handleChange(checked ? value?.filter(v => v !== option.value) : [...value, option.value])}
                    />
                ) : (
                    <Item
                        isActive={checked}
                        prefix={
                            colorless ? null : (
                                <Radio
                                    size="xs"
                                    style={{ padding: 0, gap: 6 }}
                                    styles={{
                                        radio: {
                                            backgroundColor: 'var(--color-white)',
                                            borderRadius: '100%'
                                        },
                                        input: {
                                            width: '16px!important',
                                            height: '16px!important',
                                            borderColor: `${checked ? color : 'rgba(0,0,0,0.1)'}!important`
                                        },
                                        icon: {
                                            width: '8px!important',
                                            height: '8px!important',
                                            left: '4px!important',
                                            top: '4px!important'
                                        }
                                    }}
                                    label={option.label}
                                    color={checked ? color : 'transparent'}
                                    checked={checked}
                                    disabled={disabled}
                                    onClick={e => handleChange(checked ? [] : [option.value])}
                                />
                            )
                        }
                        colorless={colorless}
                        key={option.value}
                        data={option}
                    />
                )
            })}
        </SCxGroup>
    )
}
