import { backgroundTransitionOnClick, flex, IconFont, pointer, singleTextEllipsis, tinyButtons } from '@byecode/ui'
import { clsx, ScrollArea } from '@mantine/core'
import cls from 'classnames'
import * as React from 'react'
import styled from 'styled-components'

export type ListItem = { label: string; value: string; icon?: string; disabled?: boolean }

interface FieldListProps {
    value: string[]
    options: ListItem[]
    tableWidth?: number
    tableHeight?: number

    onChange: (val: string) => void
    onScrollPositionChange?: (position: { x: number; y: number }) => void
}

export const SCxSelectContain = styled.div`
    padding: 8px 0px;
    max-height: 500px;
    width: 200px !important;
    overflow-y: auto;
    ${tinyButtons};
`
export const SCxText = styled.span<{ color?: string }>`
    font-size: var(--font-size-normal);
    color: ${({ color }) => color ?? 'var(--color-black)'};
    line-height: 16px;
    ${pointer}
    ${singleTextEllipsis}
`

export const SCxSelectItem = styled.div`
    width: 100%;
    padding: 8px 16px;
    ${flex}
    align-items: center;
    justify-content: space-between;
    height: 38px;
    line-height: 38px;
    ${backgroundTransitionOnClick}
    font-size: var(--font-size-normal);
    &.disabled {
        cursor: not-allowed;
        ${SCxText} {
            color: var(--color-gray-400);
        }
    }
`

export const SCxIcon = styled(IconFont)<{ size?: number; color?: string; bgColor?: string; cursor?: string }>`
    font-size: ${({ size }) => size ?? 16}px;
    color: ${({ color }) => color ?? 'var(--color-black)'};
    cursor: ${({ cursor }) => cursor ?? 'pointer'};
`

export const SCxLeftFill = styled.div`
    display: flex;
    align-items: center;
`

export const SCxRightFill = styled.div`
    display: flex;
    align-items: center;
`

const RelatedRecorderContent = styled(ScrollArea)<{ height?: number }>`
    position: relative;
    overflow: visible;
    width: 100%;
    height: 100%;

    & .mantine-ScrollArea-viewport {
        height: auto;
        max-height: ${({ height }) => (height ? `${height}px` : '100%')};
    }
`

const FieldList = React.forwardRef<HTMLDivElement, FieldListProps>(
    ({ options, value, onChange, onScrollPositionChange, tableHeight }, ref) => {
        return (
            <RelatedRecorderContent ref={ref} height={tableHeight} onScrollPositionChange={onScrollPositionChange}>
                {options.map(({ label, value: itemValue, icon, disabled }, index) => (
                    <SCxSelectItem key={itemValue} className={cls({ disabled })} onClick={() => !disabled && onChange(itemValue)}>
                        <SCxLeftFill>
                            <SCxText>{typeof label === 'object' ? null : label}</SCxText>
                        </SCxLeftFill>
                        <SCxRightFill>{value.includes(itemValue) && <SCxIcon type="Tick" color="var(--color-app-main)" />}</SCxRightFill>
                    </SCxSelectItem>
                ))}
            </RelatedRecorderContent>
        )
    }
)

export default FieldList
