import type { StyleComponentProps } from '@byecode/ui/theme/types'
import { FloatingArrow, FloatingPortal, useMergeRefs } from '@floating-ui/react'
import React, { useMemo } from 'react'

import { Box } from '../../Box'
import { ARROW_HEIGHT, ARROW_WIDTH } from '../hook'
import { useTooltipContext } from '../Tooltip.context'
import { useStyles } from './index.style'

export interface TooltipContentProps extends StyleComponentProps {
    withArrow?: boolean
    disablePortal?: boolean
    zIndex?: number
    arrowRef: React.RefObject<SVGSVGElement>
    children?: string | React.ReactNode
}

export const TooltipContent = React.forwardRef<HTMLDivElement, TooltipContentProps>(
    ({ withArrow, arrowRef, disablePortal, zIndex = 200, styles, classNames, unstyled, children }, propRef) => {
        const ctx = useTooltipContext()
        const { open, refs, floatingStyles, context, width } = ctx
        const ref = useMergeRefs([refs.setFloating, propRef])
        const { classes } = useStyles({}, { name: 'tooltip', classNames, styles, unstyled })

        const content = useMemo(
            () => (
                <Box
                    ref={ref}
                    className={classes.tooltip}
                    style={{
                        zIndex,
                        width: width === 'target' ? undefined : width,
                        ...floatingStyles
                    }}
                >
                    {withArrow && (
                        <FloatingArrow
                            ref={arrowRef}
                            context={context}
                            width={ARROW_WIDTH}
                            height={ARROW_HEIGHT}
                            fill="var(--color-gray-900)"
                        />
                    )}
                    {children}
                </Box>
            ),
            [arrowRef, children, classes.tooltip, context, floatingStyles, ref, width, withArrow, zIndex]
        )

        const container = useMemo(() => {
            if (!disablePortal) {
                return <FloatingPortal>{content}</FloatingPortal>
            }
            return content
        }, [content, disablePortal])

        return open ? container : null
    }
)
