import type { AppUser, DataSourceAbstract } from '@lighthouse/core'
import { useAtom } from 'jotai'
import { atomWithImmer } from 'jotai-immer'
import { useMemo } from 'react'
import useSWR from 'swr'

import { useApplication } from '@/hooks/useApplication'
import * as srv from '@/services'

export const appUserListAtom = atomWithImmer<AppUser[]>([])
/**
 * @description 获取应用用户列表
 * @export
 * @return {*}
 */
export function useAppUsers() {
    const application = useApplication()
    const id = application?.id
    const [data, set] = useAtom(appUserListAtom)
    const { mutate } = useSWR(
        `/app/getUsers/-${id}`,
        async () => {
            if (!id) {
                set([])
                return
            }
            const resp = await srv.getAppUsers(id)
            set(resp)
        },
        {
            revalidateOnFocus: false,
            keepPreviousData: true,
            errorRetryCount: 0
        }
    )

    // // set(resp.data)
    return { data, update: mutate, set }
}

export function useAppGroups() {
    const { data, mutate } = useSWR(
        `/app/getGroups`,
        () => srv.getAppGroups(),

        {
            revalidateOnFocus: false,
            keepPreviousData: true,
            errorRetryCount: 0
        }
    )

    // // set(resp.data)
    return { data, update: mutate }
}

/**
 * @description 获取数据源配置
 * @export
 * @return {*}
 */
export function useSWRDataSourceMeta(appId: string, dsId: string, pageId: string) {
    const { data, mutate } = useSWR<DataSourceAbstract | undefined>(
        `/app/getDsMeta/${appId} - ${dsId}`,
        () => {
            if (!dsId || !appId) {
                return
            }
            return srv.getDsMeta(appId, dsId, pageId)
        },
        {
            revalidateOnFocus: false,
            keepPreviousData: true
        }
    )
    return { data, update: mutate }
}
