import { Toast } from '@byecode/ui'
import type { IsSuccessfulCall } from '@rpldy/shared'

import { getFileSizeToMB } from '../utils'
import type { ErrorCode } from './api'
import { errorCodeMap, limitExceededCodeList } from './api'

export const avatarMaxFileSize = 1024 * 1024 * 5

export const logoMaxUploadSize = 1024 * 1024 * 2

export const videoMaxUploadSize = 1024 * 1024 * 300

export const fileMaxUploadSize = 1024 * 1024 * 300

export const aliyunVideoProtocolList = ['mp4']

export const fieldFileFilter = (file: string | File, index: number) => {
    if (file instanceof File && file.size > fileMaxUploadSize) {
        Toast.error(`不能上传大于 ${getFileSizeToMB(fileMaxUploadSize)}mb 的文件`)
        return false
    }
    return true
}

export const isSuccessfulCall: IsSuccessfulCall = xhr => {
    if (xhr.status === 200) {
        const res = JSON.parse(xhr.response)
        if (res.code === '0030000') {
            return false
        }
        if (limitExceededCodeList.includes(res.code)) {
            return false
        }
    }
    return true
}

export const isSuccessfulCallToToast: IsSuccessfulCall = xhr => {
    if (xhr.status === 200) {
        const res = JSON.parse(xhr.response)
        if (res.code === '0030000') {
            return false
        }
        if (limitExceededCodeList.includes(res.code)) {
            Toast.error(`${errorCodeMap[res.code as ErrorCode]}`)
            return false
        }
    }
    return true
}
