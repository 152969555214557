import { backgroundTransitionOnClick, IconFont, singleTextEllipsis } from '@byecode/ui'
import type { AppUser, FieldADTValue, PersonField, PersonValue } from '@lighthouse/core'
import { filter } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { getColorById } from '../../utils'
import type { SelectChangeEvent, TagSelectOption } from '../TagSelect'
import { TagSelect } from '../TagSelect'
import * as SC from './styles'

interface PersonValueEditorProps {
    recordId: string
    field: PersonField
    data: PersonValue
    personOptions?: AppUser[]
    enableCurrentUser?: boolean
    isHiddenId?: boolean
    style?: React.CSSProperties
    autoFocus?: boolean
    onCellChange?: (recordId: string, field: FieldADTValue) => Promise<boolean>
    onClose?: (isActive?: boolean) => void
}

const SCxTagItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 4px;
    overflow: hidden;
`

const SCxListItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    margin-right: 2px;
    cursor: pointer;
    ${backgroundTransitionOnClick}
`

const SCxItemIcon = styled.div<{ background?: string; color?: string; border?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    font-size: var(--font-size-sm);
    margin-right: 4px;
    border-radius: 50%;
    border: ${({ border }) => (border ? '1px solid var(--color-gray-200)' : 'none')};
    flex-shrink: 0;
    overflow: hidden;
    color: ${({ color }) => color || 'var(--color-white)'};
    background-color: ${({ background }) => background || 'var(--color-gray-2)'};
`

const SCxItemAvatar = styled.img`
    width: 22px;
    height: 22px;
    margin-right: 4px;
    border-radius: 50%;
    flex-shrink: 0;
`

const SCxIcon = styled(IconFont)`
    margin-right: 4px;
`

const SCxItemLabel = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    color: var(--color-black);
    font-size: var(--font-size-normal);
    overflow: hidden;
    text-overflow: ellipsis;
`

const SCxItemLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
`

const SCxItemName = styled.div`
    flex: 1;
    ${singleTextEllipsis}
    color: var(--color-black);
    font-size: var(--font-size-normal);
`

const SCxListItemDescribe = styled.div`
    margin-left: 4px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
    max-width: 45%;
    overflow: hidden;
    text-overflow: ellipsis;
`

const SCxTagItemDescribe = styled.div`
    margin-left: 4px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
    overflow: hidden;
    text-overflow: ellipsis;
`

export const PersonValueEditor: React.FC<PersonValueEditorProps> = ({
    recordId,
    field,
    data,
    personOptions = [],
    enableCurrentUser,
    isHiddenId,
    style,
    autoFocus,
    onCellChange,
    onClose
}) => {
    const [personValues, setPersonValues] = React.useState<PersonValue>(data)
    const {
        person: { multiple }
    } = field
    const options = useMemo(() => {
        const persons = personOptions.map(({ userId, username, avatar, isDepart }) => ({
            label: isDepart ? `${username}(已注销)` : username,
            value: userId,
            img: avatar,
            describe: isHiddenId ? '' : userId,
            isHidden: isDepart
        }))
        if (enableCurrentUser) {
            persons.unshift({
                label: '当前用户',
                value: '{currentUserId}',
                img: undefined,
                describe: '',
                isHidden: false
            })
        }
        return persons
    }, [enableCurrentUser, isHiddenId, personOptions])

    const renderUserAvatar = useCallback((item: TagSelectOption) => {
        if (item.value === '{currentUserId}') {
            return <SCxIcon size={22} type="UserCircle" />
        }
        const background = getColorById(item.value)
        return item.img ? <SCxItemAvatar src={item.img} /> : <SCxItemIcon background={background}>{item.label.charAt(0)}</SCxItemIcon>
    }, [])

    const renderTagItem = useCallback(
        (item: TagSelectOption) => {
            // const background = getColorById(item.value)
            return (
                <SCxTagItemWrapper>
                    {renderUserAvatar(item)}
                    <SCxItemLabel>
                        <SCxItemName>{item.label}</SCxItemName>
                        {item.describe && <SCxTagItemDescribe>{item.describe}</SCxTagItemDescribe>}
                    </SCxItemLabel>
                </SCxTagItemWrapper>
            )
        },
        [renderUserAvatar]
    )

    const renderEmptyItem = useCallback((id: string) => {
        return (
            <SCxTagItemWrapper>
                <SCxItemIcon background="var(--color-gray-50)" color="var(--color-black)" border>
                    ?
                </SCxItemIcon>
                <SCxItemLabel>
                    <SCxItemName>无权限用户</SCxItemName>
                    <SCxTagItemDescribe>{id}</SCxTagItemDescribe>
                </SCxItemLabel>
            </SCxTagItemWrapper>
        )
    }, [])

    const renderListItem = useCallback(
        (item: TagSelectOption) => {
            // const background = getColorById(item.value)
            return (
                <SCxListItemWrapper>
                    <SCxItemLeft>
                        {renderUserAvatar(item)}
                        <SCxItemName>{item.label}</SCxItemName>
                    </SCxItemLeft>
                    {item.describe && <SCxListItemDescribe>{item.describe}</SCxListItemDescribe>}
                </SCxListItemWrapper>
            )
        },
        [renderUserAvatar]
    )

    const handleChange = useCallback(
        (ev: SelectChangeEvent) => {
            const { action, option } = ev
            switch (action) {
                case 'add': {
                    let value: string[] = []
                    if (multiple) {
                        value = personValues.includes(option) ? personValues : [...personValues, option]
                    } else {
                        value = [option]
                    }
                    setPersonValues(value)
                    onCellChange?.(recordId, { ...field, type: 'person', value })
                    if (!multiple) {
                        onClose?.()
                    }
                    break
                }
                case 'delete': {
                    let value: string[] = []
                    const newPersons = filter(val => {
                        return val !== option
                    }, personValues)
                    value = newPersons
                    setPersonValues(value)
                    onCellChange?.(recordId, { ...field, type: 'person', value })
                    if (!multiple) {
                        onClose?.()
                    }
                    break
                }
                default: {
                    break
                }
            }
        },
        [multiple, onCellChange, recordId, field, personValues, onClose]
    )

    return (
        <SC.TagSelectWrapper style={style}>
            <TagSelect
                // loading={isLoading}
                multiple={multiple}
                sortable={false}
                description="从成员中选择"
                options={options}
                value={personValues}
                autoFocus={autoFocus}
                onChange={handleChange}
                emptyItemRender={renderEmptyItem}
                tagItemRender={renderTagItem}
                listItemRender={renderListItem}
            />
        </SC.TagSelectWrapper>
    )
}

