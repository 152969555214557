import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'

export const useStyles = createStyles(() => ({
    root: css({
        position: 'relative'
    }),
    loading: css({
        position: 'absolute',
        inset: 0,
        backgroundColor: 'rgba(56 56 56 / 20%)'
    }),
    pagination: css({
        marginTop: 12
    })
}))
