import { IconFont, singleTextEllipsis, Tooltip } from '@byecode/ui'
import type { AppNavAbstract, BaseApplicationAbstract } from '@lighthouse/core'
import { useResizeObserver } from '@mantine/hooks'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const ITEM_WIDTH = 80

const Container = styled.div`
    margin: 4px;
    display: flex;
    flex-wrap: wrap;
`
const AppLink = styled.a`
    width: ${ITEM_WIDTH}px;
    height: ${ITEM_WIDTH}px;
    font-size: 12px;
    color: var(--color-black);
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
`
const IconWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px;
    border-radius: 12px;
`

const WillBoldText = styled.span`
    width: 100%;
    text-align: center;
    ${singleTextEllipsis}
    &::before {
        display: block;
        content: attr(aria-label);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
`

interface AppNavBlockProps {
    blockData: AppNavAbstract
    appList: BaseApplicationAbstract[]
}

export const AppNavBlock: FC<AppNavBlockProps> = ({ blockData, appList }) => {
    const { list } = blockData.config
    const [ref, rect] = useResizeObserver()

    const gap = useMemo(() => {
        const cols = Math.floor(rect.width / ITEM_WIDTH)
        const rest = rect.width % ITEM_WIDTH
        if (cols <= 1) {
            return
        }
        return rest / (cols - 1)
    }, [rect.width])

    const visibleAppList = useMemo(() => {
        return (list ?? []).map(item => appList.find(app => app.id === item)).filter(Boolean) as BaseApplicationAbstract[]
    }, [appList, list])

    return (
        <Container ref={ref} style={{ columnGap: gap }}>
            {visibleAppList.map(item => (
                <Tooltip key={item.id} title={item.name} placement="top" offset={0}>
                    <AppLink href={`//${item.url}`} target="_blank" data-stop-action-propagation>
                        <IconWrap style={{ background: item.primaryColor }}>
                            <IconFont size={32} color="#fff" type={item.icon} />
                        </IconWrap>
                        <WillBoldText aria-label={item.name}>{item.name}</WillBoldText>
                    </AppLink>
                </Tooltip>
            ))}
        </Container>
    )
}
