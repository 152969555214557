import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React, { forwardRef, useCallback, useMemo, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import { useLatest, useUpdateEffect } from 'react-use'

import { Box } from '../Box'
import type { FlexProps } from '../Flex'
import { Flex } from '../Flex'
import { IconFont } from '../IconFont'
import type { SwitchProps } from '../Switch'
import { Switch } from '../Switch'
import { useStyles } from './styles'

const collapsedStyle = {
    display: 'none',
    height: 0,
    overflow: 'hidden'
}

export interface GroupProps extends StyleComponentProps<Selectors<typeof useStyles>>, React.ComponentPropsWithoutRef<'div'> {
    defaultOpen?: boolean
    opened?: boolean
    onCollapseChange?: (v: boolean) => void
    collapsible?: boolean
    /** 分组名称 */
    label: React.ReactNode
    /** 名称tooltip */
    tooltip?: React.ReactNode
    renderRightIcon?: (open: boolean, onChange: (v: boolean) => void) => React.ReactNode
    /** 右侧额外内容 */
    extra?: React.ReactNode
    /** 分组模式 */
    mode?: 'switch' | 'default' | 'none'
    /** 展开收起图标颜色 */
    iconColor?: string

    switchProps?: SwitchProps
    wrapperProps?: FlexProps
    collapseProps?: React.ComponentPropsWithoutRef<'div'>
}

export const Group = forwardRef<HTMLDivElement, GroupProps>((props, ref) => {
    const {
        defaultOpen = true,
        opened,
        onCollapseChange,
        collapsible = true,
        label,
        tooltip,
        renderRightIcon,
        extra,
        mode = 'default',
        iconColor = 'var(--color-gray-500)',
        switchProps,
        wrapperProps,
        collapseProps,
        className,
        children,
        classNames,
        styles,
        ...rest
    } = props

    const { className: wrapperClassName, onClick, alignItems, style: wrapperStyle, ...restWrapperProps } = wrapperProps ?? {}
    const { className: collapseClassName, onTransitionEnd, style: collapseStyle, ...restCollapseProps } = collapseProps ?? {}

    const { classes } = useStyles({}, { name: 'group', classNames, styles })

    const el = useRef<HTMLDivElement | null>(null)

    const [open, setOpen] = useUncontrolled({ value: opened, onChange: onCollapseChange, defaultValue: defaultOpen })

    const [internalCollapseStyle, setInternalCollapseStyle] = useState<React.CSSProperties>(open ? {} : collapsedStyle)

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (!collapsible) {
                return
            }
            onClick?.(e)

            if (mode !== 'switch') {
                setOpen(!open)
            }
        },
        [collapsible, mode, onClick, open, setOpen]
    )

    useUpdateEffect(() => {
        if (open) {
            requestAnimationFrame(() => {
                flushSync(() => {
                    setInternalCollapseStyle(s => ({ ...s, willChange: 'height', display: 'block', overflow: 'hidden' }))
                })
                requestAnimationFrame(() => {
                    const height = el.current ? el.current.scrollHeight : 'auto'
                    flushSync(() => {
                        setInternalCollapseStyle(s => ({ ...s, transition: `height 0.2s`, height }))
                    })
                })
            })
        } else {
            requestAnimationFrame(() => {
                const height = el.current ? el.current.scrollHeight : 'auto'
                flushSync(() => {
                    setInternalCollapseStyle(s => ({ ...s, transition: `height 0.2s`, height, willChange: 'height' }))
                })
                requestAnimationFrame(() => {
                    flushSync(() => {
                        setInternalCollapseStyle(s => ({ ...s, height: 0, overflow: 'hidden' }))
                    })
                })
            })
        }
    }, [open])

    const transitionEndHandle = useCallback(
        (e: React.TransitionEvent<HTMLDivElement>) => {
            if (e.target !== el.current || e.propertyName !== 'height') {
                return
            }
            if (open) {
                setInternalCollapseStyle({})
            } else {
                setInternalCollapseStyle(collapsedStyle)
            }
            onTransitionEnd?.(e)
        },
        [onTransitionEnd, open]
    )

    const rightIcon = useMemo(() => {
        if (renderRightIcon) {
            return renderRightIcon(open, setOpen)
        }

        if (mode === 'none') {
            return null
        }
        return mode === 'default' ? (
            <IconFont
                type="ArrowDownSmall"
                size={16}
                color={iconColor}
                style={{ transform: open ? 'rotate(-180deg)' : undefined, transition: 'transform 0.2s' }}
            />
        ) : (
            <Switch checked={open} onChange={e => setOpen(e.currentTarget.checked)} {...switchProps} />
        )
    }, [iconColor, mode, open, renderRightIcon, setOpen, switchProps])

    return (
        <Box ref={ref} className={clsx(className, classes.root)} {...rest}>
            <Flex
                {...restWrapperProps}
                style={{ cursor: mode === 'default' ? 'pointer' : undefined, ...wrapperStyle }}
                alignItems={alignItems ?? 'center'}
                className={clsx(classes.wrapper, wrapperClassName)}
                onClick={handleClick}
            >
                <Box className={clsx(classes.label)}>{label}</Box>
                {tooltip && (
                    <IconFont type="Question" className={clsx(classes.tooltip)}>
                        {tooltip}
                    </IconFont>
                )}
                <Flex className={clsx(classes.rightIcon)}>
                    {extra}
                    <Flex style={{ marginLeft: 6 }}>{rightIcon}</Flex>
                </Flex>
            </Flex>
            <Box
                {...restCollapseProps}
                ref={el}
                onTransitionEnd={transitionEndHandle}
                className={clsx(classes.collapse, collapseClassName)}
                style={{ ...internalCollapseStyle, ...collapseStyle }}
            >
                {children}
            </Box>
        </Box>
    )
})
