import type { BasicPageStackMeta } from '@lighthouse/core'
import { RecordOpenType } from '@lighthouse/core'

/** ******************************************************* */
/** ******************************************************* */
/**                     页面栈路径规则                       */
/**    页面形式时以`P`开头、弹窗形式为`M`、侧边栏形式为`D`     */
/**    其他参数之间的映射为                                  */
/**    `appId` => `a`                                       */
/**    `dsId` => `d`                                        */
/**    `viewId` => `v`                                      */
/**    `recordId` => 'r                                     */
/**    `initialRecordValue` => `i`                          */
/** ******************************************************* */
/** ******************************************************* */

/**
 * 页面栈路由名称映射
 */
export const PAGE_STACK_URL_KEY_MAP: Record<string, keyof BasicPageStackMeta> = {
    P: 'pageId',
    M: 'pageId',
    D: 'pageId',
    a: 'appId',
    d: 'dsId',
    r: 'recordId',
    v: 'viewId',
    i: 'initialRecordValue'
}

// eslint-disable-next-line unicorn/no-unsafe-regex
export const PAGE_URL_REG = /((([DMP])\/\w+)|([\dA-Za-z-]+))((\/a\/\w+)?(\/d\/\w+)?(\/v\/[\w-]+)?(\/r\/[\w-]+)?(\/i\/\S+)?)*/g

// eslint-disable-next-line unicorn/no-unsafe-regex
export const CUSTOM_ROUTE_PAGE_URL_REG = /^[\dA-Za-z-]+((\/a\/\w+)?(\/d\/\w+)?(\/v\/[\w-]+)?(\/r\/[\w-]+)?(\/i\/\S+)?)*$/

/**
 * 根据url路径名获取打开方式
 * @param name 路径名
 * @returns
 */
export function getOpenTypeByUrlName(name: string): RecordOpenType {
    switch (name) {
        case 'M': {
            return RecordOpenType.modal
        }
        case 'D': {
            return RecordOpenType.drawer
        }
        default: {
            return RecordOpenType.page
        }
    }
}

/**
 * 根据打开方式获取url上的路径名
 * @param openType 打开方式
 * @returns
 */
export function getUrlNameByOpenType(openType?: RecordOpenType): string {
    switch (openType) {
        case RecordOpenType.modal: {
            return 'M'
        }
        case RecordOpenType.drawer: {
            return 'D'
        }
        default: {
            return 'P'
        }
    }
}

/**
 * 确保对应参数存在
 */
export function ensureUrlParams(key: keyof typeof PAGE_STACK_URL_KEY_MAP, val?: string): string {
    return val ? `/${key}/${val}` : ''
}
