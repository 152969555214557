import { Empty } from '@byecode/ui/components/Empty'
import { Flex } from '@byecode/ui/components/Flex'
import type { ButtonAction, DataSourceAbstract, RecordLikeProtocol, ViewBlockAbstract } from '@lighthouse/core'
import { ARRANGEMENT, BlockSize, CUSTOM_VIEW_LAYOUT, DIRECTION } from '@lighthouse/core'
import type { FlowContainerContextValue, FlowLayoutCustomNode, FlowLayoutNode } from '@lighthouse/shared'
import {
    EmptyNoRecordSvg,
    FLOW_LAYOUT_NODE_ROWS,
    FlowContainerProvider,
    SortableContainerContext,
    useFlowContainerContext,
    useMediaQueryRemBase
} from '@lighthouse/shared'
import { BreakPointSize } from '@lighthouse/tools'
import React, { forwardRef, useMemo } from 'react'

import { HorizontalScrollView } from './HorizontalScrollView'
import { VerticalView } from './VerticalView'

export interface CustomViewBlockProps {
    blockData: ViewBlockAbstract
    records?: RecordLikeProtocol[]
    dataSource: DataSourceAbstract
    node: FlowLayoutNode
    blockWidth: number
    breakPoint: BreakPointSize
    readonly?: boolean
    scale?: number
    showAction?: boolean

    onLoadMoreData?: (pageNum: number) => Promise<RecordLikeProtocol[]>
    onFilterNode?: (node: FlowLayoutNode, record: RecordLikeProtocol) => FlowLayoutNode
    onRecordClick?: (recordId: string) => void
    onRecordClickedActionTrigger?: (action: ButtonAction, record: RecordLikeProtocol) => void
}

export const CustomViewBlock = forwardRef<HTMLDivElement, CustomViewBlockProps>((props, ref) => {
    const {
        blockData,
        node,
        blockWidth,
        breakPoint,
        records,
        dataSource,
        readonly,
        scale,
        showAction,
        onLoadMoreData,
        onFilterNode,
        onRecordClick,
        onRecordClickedActionTrigger
    } = props
    const {
        id,
        title,
        config: {
            pagination,
            pointer,
            direction: viewDirection = DIRECTION.vertical,
            layout = CUSTOM_VIEW_LAYOUT.waterfall,
            arrangement = ARRANGEMENT.auto,
            size = BlockSize.md,
            cols,
            gap = 0,
            design,
            actions
        }
    } = blockData
    const isEmptyImg = blockWidth && blockWidth >= 200
    const showEmpty = !pointer || !dataSource || !records || records.length === 0

    // const { ref: innerRef, breakPoint, width } = useBreakpoint([showEmpty, layout, viewDirection])
    const autoCols = useMemo(() => {
        const xLargeSize = new Map([
            [BreakPointSize.lg, 2],
            [BreakPointSize.md, 1],
            [BreakPointSize.sm, 1],
            [BreakPointSize.xs, 1]
        ])
        const largeSize = new Map([
            [BreakPointSize.lg, 3],
            [BreakPointSize.md, 2],
            [BreakPointSize.sm, 1],
            [BreakPointSize.xs, 1]
        ])
        const middleSize = new Map([
            [BreakPointSize.lg, 4],
            [BreakPointSize.md, 3],
            [BreakPointSize.sm, 2],
            [BreakPointSize.xs, 1]
        ])
        const smallSize = new Map([
            [BreakPointSize.lg, 5],
            [BreakPointSize.md, 4],
            [BreakPointSize.sm, 3],
            [BreakPointSize.xs, 2]
        ])

        const sizeMap = new Map([
            [BlockSize.xl, xLargeSize],
            [BlockSize.lg, largeSize],
            [BlockSize.md, middleSize],
            [BlockSize.sm, smallSize]
        ])

        return (breakPoint && sizeMap.get(size)?.get(breakPoint)) ?? 3
    }, [breakPoint, size])

    const isHorizontalGrid = layout === CUSTOM_VIEW_LAYOUT.grid && viewDirection === DIRECTION.horizontal
    const isMobile = breakPoint === BreakPointSize.xs
    const finalCols =
        arrangement === ARRANGEMENT.fixed && cols
            ? // ? isMobile && viewDirection === DIRECTION.vertical
              //     ? Math.min(2, cols)
              //     : cols
              cols
            : isHorizontalGrid
            ? autoCols + 0.3
            : autoCols

    const baseRem = useMediaQueryRemBase()
    const { direction, mobileAdaptive } = useFlowContainerContext()

    const childWidth =
        direction === DIRECTION.horizontal && isMobile && mobileAdaptive ? FLOW_LAYOUT_NODE_ROWS : FLOW_LAYOUT_NODE_ROWS / finalCols

    const contextValue: FlowContainerContextValue = useMemo(() => {
        return {
            fullWidth: blockWidth,
            contentWidth: blockWidth,
            nodeUnitWidth: (blockWidth - (finalCols - 1) * gap * baseRem) / FLOW_LAYOUT_NODE_ROWS,
            gap
        }
    }, [blockWidth, finalCols, gap, baseRem])

    if (showEmpty) {
        return (
            <Flex alignItems="center" justifyContent="center">
                <Empty icon={blockWidth ? <EmptyNoRecordSvg color='var(--color-app-main)' /> : undefined} description="未找到数据" />
            </Flex>
        )
    }

    return (
        <FlowContainerProvider value={contextValue}>
            <SortableContainerContext parentId={id} items={records.map(item => item.id)}>
                {isHorizontalGrid ? (
                    <HorizontalScrollView
                        // ref={innerRef}
                        blockData={blockData}
                        showAction={showAction}
                        node={node as FlowLayoutCustomNode}
                        title={title}
                        viewId={id}
                        pointer={pointer}
                        records={records}
                        cols={finalCols}
                        gap={gap}
                        layout={layout}
                        design={design}
                        childWidth={childWidth}
                        baseRem={baseRem}
                        readonly={readonly}
                        pagination={pagination}
                        scale={scale}
                        actions={actions}
                        onLoadMoreData={onLoadMoreData}
                        onFilterNode={onFilterNode}
                        onRecordClick={onRecordClick}
                        onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                    />
                ) : (
                    <VerticalView
                        // ref={innerRef}
                        blockData={blockData}
                        showAction={showAction}
                        node={node as FlowLayoutCustomNode}
                        title={title}
                        viewId={id}
                        pointer={pointer}
                        records={records}
                        cols={finalCols}
                        gap={gap}
                        layout={layout}
                        design={design}
                        childWidth={childWidth}
                        baseRem={baseRem}
                        readonly={readonly}
                        scale={scale}
                        actions={actions}
                        onFilterNode={onFilterNode}
                        onRecordClick={onRecordClick}
                        onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                    />
                )}
            </SortableContainerContext>
        </FlowContainerProvider>
    )
})
