import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import { styled } from '@stitches/react'
import { number } from 'prop-types'

export const useStyles = createStyles((params: { columnWidth: number | string }) => {
    const { columnWidth } = params
    return {
        root: css({
            //
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflow: 'hidden'
        }),
        path: css({
            margin: '12px 0'
        }),
        search: css({}),
        empty: css({}),
        content: css({
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
        }),
        cursor: css({
            padding: 8,
            borderBottom: '1px solid $colorGray200'
        }),
        columns: css({ display: 'flex', flexFlow: 'row nowrap', overflow: 'auto hidden', prettyScroll: true, flex: 1 }),
        column: css({
            width: typeof columnWidth === 'number' ? `${columnWidth}px` : columnWidth,
            borderRight: '1px solid #dfdfdd',
            padding: '8px 0',
            flexShrink: 0
        }),
        list: css({ width: '100%', height: '100%', overflowY: 'auto', prettyScroll: true }),
        item: css({
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            height: 32,
            fontSize: 14,
            padding: '0 12px',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '$colorGray100'
            }
        }),
        label: css({
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: 'var(--color-black)',
            flex: 1,
            height: 32,
            lineHeight: '32px'
        }),
        arrow: css({
            height: 32,
            lineHeight: '32px'
        }),
        activeIcon: css({}),
        dropDown: css({
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            width: '100%',
            minHeight: 300
        }),
        dropContent: css({
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            maxHeight: ' 100%',
            overflow: 'hidden',
            flex: 1
        })
    }
})
