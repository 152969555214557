import { Flex, noButtons } from '@byecode/ui'
import type { FilterBlockValue } from '@lighthouse/core'
import { type FilterBlockItemConfig, FilterBlockMode, FilterMode } from '@lighthouse/core'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import type { FilterItemProps } from '../types'
import { NormalFilterItem } from './NormalFilterItem'
import { TileFilterItem } from './TileFilterItem'

interface MobileFilterProps extends Pick<FilterItemProps,'labelWidth' | 'recordId' | 'dataSourceList' | 'onChange' | 'onFetchTextOptions' | 'onFetchCascadeOptions'> {
    list: FilterBlockItemConfig[]
    mode: FilterBlockMode
    filterData: FilterBlockValue
}
const SCxContainer = styled(Flex)`
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

export const MobileFilter: React.FC<MobileFilterProps> = props => {
    const { mode, list, ...rest } = props

    const renderItem = useCallback(
        (config: FilterBlockItemConfig) => {
            if (mode === FilterBlockMode.tile) {
                return <TileFilterItem config={config} {...rest} />
            }
            return <NormalFilterItem config={config} {...rest} />
        },
        [mode, rest]
    )

    return (
        <SCxContainer direction={mode === FilterBlockMode.tile ? 'column' : 'row'} gap={mode === FilterBlockMode.tile ? 0 : 12} wrap="wrap">
            {list.map(item => renderItem(item))}
        </SCxContainer>
    )
}
