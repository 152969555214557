import clsx from 'clsx'
import React, { forwardRef, useMemo, useState } from 'react'

import { useComponentConfig } from '../../hooks/useComponentConfig'
import { useUncontrolled } from '../../hooks/useUncontrolled'
import { styled } from '../../theme/stitches.config'
import { Box } from '../Box'
import { IconFont } from '../IconFont'
import { Popover } from '../Popover'
import { Tooltip } from '../Tooltip'
import { useStyles } from './Select.style'
import { SelectDropdown } from './SelectDropdown/SelectDropdown'
import type { Option, SelectProps, SelectValueType } from './types'

const SCxIcon = styled(IconFont, {
    color: '$colorGray400',
    '&:hover': {
        color: '$colorGray800'
    }
})

export function isOutsideEvent(event: React.FocusEvent<HTMLInputElement>, container?: Element) {
    const containerElement = container || (event.currentTarget as Element)
    const relatedTarget = event.relatedTarget as HTMLElement | null
    return !relatedTarget || !containerElement.contains(relatedTarget)
}

export const Select = forwardRef<HTMLInputElement, SelectProps>((props, ref) => {
    const {
        name,
        options,
        optionComponent,
        defaultValue,
        value,
        disabledPortal,
        position = 'bottom-start',
        onChange,
        dropdownProps,
        suffix,
        clearable,
        searchable,
        prefix,
        size = 'md',
        className,
        classNames,
        styles,
        unstyled,
        onFocus,
        highlighting,
        disabled: _disabled,
        target,
        dropdownWidth,
        zIndex,
        trapFocus = false,
        customInputValueRender: CustomInputValueRender,
        hiddenEmpty = false,
        placeholder = '请选择',
        emptyComponent,
        ...rest
    } = props
    const { disabled } = useComponentConfig('select', { disabled: _disabled })
    const [_value, _setValue] = useUncontrolled({ defaultValue, value, onChange })

    // 拍平 options
    const flatOptions = useMemo(() => {
        const result: Option[] = []
        options?.forEach(item => {
            if (item.children) {
                result.push(...item.children)
            } else {
                result.push(item)
            }
        })
        return result
    }, [options])
    // 查找当前选中 option

    const currentOption = useMemo(() => flatOptions?.find(item => item.value === _value), [flatOptions, _value])

    const [open, setOpen] = useState(false)

    const internalDropdownProps = useMemo(
        () => ({
            options,
            optionComponent,
            searchable,
            value: _value,
            styles,
            hiddenEmpty,
            emptyComponent,
            onSelect: (v: SelectValueType) => {
                _setValue(v)
                setOpen(false)
            },
            ...dropdownProps
        }),
        [_setValue, _value, dropdownProps, emptyComponent, hiddenEmpty, optionComponent, options, searchable, styles]
    )

    const prefixDom = useMemo(() => {
        if (prefix) {
            return prefix
        }
        if (prefix === false) {
            return
        }
        return currentOption?.icon && typeof currentOption.icon === 'string' ? <IconFont type={currentOption.icon} /> : currentOption?.icon
    }, [currentOption?.icon, prefix])

    const selectSuffixDom = useMemo(() => {
        if (suffix) {
            return suffix
        }

        if (_value && clearable && !disabled) {
            return (
                <SCxIcon
                    type="CloseCircle"
                    style={{ cursor: 'pointer' }}
                    size={16}
                    onClick={e => {
                        e.stopPropagation()
                        _setValue('')
                    }}
                />
            )
        }

        return <IconFont size={16} type="ArrowDownSmall" />
    }, [_setValue, _value, clearable, disabled, suffix])

    const { classes } = useStyles(
        { size, disabled, hasPrefix: !!prefixDom, focused: open, highlighting },
        { name: 'select', classNames, styles, unstyled }
    )

    const selectedLabel = useMemo(() => {
        return currentOption ? (typeof currentOption.label === 'string' ? currentOption.label : currentOption.value) : ''
    }, [currentOption])

    const inputDom = useMemo(() => {
        if (CustomInputValueRender && currentOption) {
            return <CustomInputValueRender {...currentOption} placeholder={placeholder} />
        }

        if (currentOption) {
            return (
                <Tooltip title={selectedLabel}>
                    <Box className={classes.selector}>{selectedLabel}</Box>
                </Tooltip>
            )
        }

        return <Box className={classes.placeholder}>{placeholder}</Box>
    }, [CustomInputValueRender, classes.placeholder, classes.selector, currentOption, placeholder, selectedLabel])

    return (
        <Popover
            withinPortal={!disabledPortal}
            opened={open}
            zIndex={zIndex}
            onChange={setOpen}
            position={position}
            trapFocus={trapFocus}
            width={dropdownWidth}
            disabled={disabled}
        >
            <Popover.Target>
                <Box className={classes.root} {...rest}>
                    <input
                        ref={ref}
                        name={name}
                        type="search"
                        className={clsx(classes.input, className)}
                        disabled={disabled}
                        placeholder={placeholder}
                    />
                    {prefixDom && <Box className={classes.prefix}>{prefixDom}</Box>}

                    {inputDom}

                    {selectSuffixDom && <Box className={classes.suffix}>{selectSuffixDom}</Box>}
                </Box>
            </Popover.Target>
            <Popover.Dropdown
                py={8}
                styles={{ dropdown: { minWidth: 230, ...(styles ? (styles.dropdown as Record<string, string>) : {}) } }}
            >
                <SelectDropdown {...internalDropdownProps} />
            </Popover.Dropdown>
        </Popover>
    )
})
