import type { RichTextContentProtocol } from '@lighthouse/core'
import { generateHTML } from '@tiptap/react'
import React from 'react'
import styled from 'styled-components'

import type { RichTextEditorProps } from '../RichText'
import { getExtensions } from '../RichText/helper'

const Content = styled.div.withConfig<{ minHeight?: number | string }>({ shouldForwardProp: p => p !== 'minHeight' })`
    caret-color: #000;
    color: var(--color-black);
    font-size: 16px;
    line-height: 1.5;
    word-break: break-word;

    .ProseMirror {
        // 清除默认的层叠属性，会影响到文本渐变属性
        position: unset;
        min-height: ${({ minHeight }) => (typeof minHeight === 'number' ? `${minHeight}px` : minHeight)};
        [contenteditable='false'] {
            white-space: unset;
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
            margin: 16px 0 8px !important;
        }

        h1 {
            font-size: 28px;
            line-height: 1.35;
        }
        h2 {
            font-size: 22px;
            line-height: 1.45;
        }
        h3 {
            font-size: 18px;
            line-height: 1.55;
        }

        p.editor-placeholder:first-child::before {
            color: var(--color-gray-300);
            content: attr(data-placeholder);
            float: left;
            height: 0;
            pointer-events: none;
        }

        & > *:not(:last-child) {
            margin-bottom: 4px;
        }

        img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            vertical-align: middle;
            /* &.ProseMirror-selectednode {
                outline: 2px solid var(--color-main);
                outline-offset: -2px;
            } */
        }

        ul,
        ol {
            padding: 0 1.25rem;
            margin: 0.2em 0 0.2em 0.6em;
            li {
                margin: 3px 0;
            }
            &[data-type='taskList'] li {
                align-items: unset !important;
            }
        }

        ul[data-type='taskList'] {
            list-style: none;
            padding: 0;

            li {
                display: flex;
                align-items: center;

                > label {
                    flex: 0 0 auto;
                    margin-right: 0.5rem;
                    user-select: none;
                }

                > div {
                    flex: 1 1 auto;
                }
            }

            input[type='checkbox'] {
                cursor: pointer;
            }
        }

        blockquote {
            padding-left: 1.25rem;
            border-left: 2px solid rgba(13, 13, 13, 0.1);
        }

        hr {
            border: none;
            border-top: 2px solid rgba(13, 13, 13, 0.1);
            margin: 1rem 0;
        }

        a {
            color: var(--color-main);
            text-decoration: underline;
        }
    }
`

interface RichtextPureRenderProps extends Pick<RichTextEditorProps, 'config' | 'styles' | 'minHeight'> {
    value: RichTextContentProtocol | null
}

export const RichtextPureRender = ({ value, config, minHeight, styles }: RichtextPureRenderProps) => {
    return (
        <div style={styles?.editorContainer}>
            <Content minHeight={minHeight} style={styles?.editorContent}>
                <div
                    translate="no"
                    className="tiptap ProseMirror"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: value ? generateHTML(value, getExtensions(config)) : ''
                    }}
                />
            </Content>
        </div>
    )
}
