import type { I18TranslationKey } from '../types'

export const en: Record<I18TranslationKey, string> = {
    bindMobile: 'Bind mobile number',
    bindEmail: 'Bind email',
    firstLoginNeedAccount: 'First WeChat login requires binding {{account}}',
    mobileCode: 'Mobile Number',
    noVerificationCodeReceived: 'Did not receive the verification code?',
    noNeedLogin: 'Login temporarily unsupported',
    registerAccount: 'Register Account',
    mobileLogin: 'Mobile Number Login',
    emailLogin: 'Email Login',
    wechatLogin: 'WeChat Login',
    emailUrl: 'Email Address',
    noAccount: "Don't have an account yet?",
    next: 'Next',
    goLogin: 'Go to Login',
    alreadyHaveAnAccount: 'Already have an account?',
    selectRegisterWay: 'Choose registration method',
    perfectInfo: 'Complete Information',
    verificationCode: 'Verification Code',
    sure: 'Confirm',
    yourRole: 'Your Role',
    emailFormatIsIllegal: 'Invalid email format',
    mobilePhoneNumberFormatIsIllegal: 'Invalid phone number format',
    mobileRegister: 'Phone',
    emailRegister: 'Email',
    email: 'Email',
    shortMessage: 'SMS',
    pleaseInputYourEmail: 'Please enter email',
    pleaseInputYourMobile: 'Please enter a valid email',
    pleaseInputYourValidEmail: 'Please enter phone number',
    pleaseInputYourValidMobile: 'Please enter a valid phone number',
    getVerificationCode: 'Get Verification Code',
    register: 'Register',
    pleaseFillOut: 'Please fill out',
    notEmpty: 'Can not be empty',
    getAgain: 'Resend',
    loginSuccess: 'login successful',
    confirmMergeAccounts: `Confirm to merge accounts?`,
    merge: 'merge',
    iAwareRisks: 'I am aware of the risks',
    cancel: 'cancel',
    changeBindMobile: 'Change mobile phone number',
    mergeAccount: 'Merge accounts',
    keepMobileAndDeleteWechat: 'Keep the mobile phone number account Delete the WeChat account',
    keepWechatAndDeleteMobile: 'Keep WeChat account Delete mobile phone account',
    youAccountUnderReview: 'Your account is under review',
    backLoginPage: 'Return to login page',
    MergeWeChatAccountDescription:
        'To merge the accounts registered with WeChat and mobile phone numbers into one account, please select the account to keep:',
    deleteWechatAccountWarn: 'This operation will delete your WeChat account and cannot be restored, so please operate with caution!',
    mobileAlreadyRegister: 'Mobile phone number has been registered',
    mobileAlreadyRegisterDesc:
        'The mobile phone number has been registered as: {{name}} ({{userId}}). If you still want to bind the mobile phone number, please click "Merge Accounts".',
    goHome: 'Return to Homepage',
    resendTime: `Resend after {{v}} s`,
    verificationMessage: `We have sent a verification code to {{v}}, please check your email`,
    verificationSMS: `We have sent a SMS verification code to {{v}}`,
    inputVerificationCode: `Enter {{v}} verification code`,
    search: 'Search',
    personNameOrPersonId: 'Personnel name, personnel number',
    noFindData: 'No data',
    noFindPerson: 'No user data',
    selected: 'Selected',
    pleaseWeChatLoginInWeChatBrowser: 'Please log in with WeChat in the WeChat browser.',
    refresh: 'Refresh',
    expiredPleaseRefresh: 'Expired, please refresh.',
    scanWechatLogin: 'Scan the QR code with WeChat to follow the official account to log in.',
    accountSetting: 'Account Settings',
    logout: 'Logout',
    notBind: 'Unable to Unbind',
    mobileNumber: 'Mobile Number',
    wechat: 'WeChat',
    removeBindAccountDescribe:
        '{{account}} is the only login method for this account. Please first bind another account as an alternative login method.',
    knew: 'Understood',
    continueUnbinding: 'Continue Unbinding',
    emailAccount: 'Email Account',
    notEnabledAccountLogin: '{{account}} login function is not enabled',
    alreadyBind: 'Already Bound',
    wechatAccount: 'WeChat Account',
    bindAfterUsedAccountLogin: 'After binding, you can log in using {{account}}',
    bindAccountAfterAccountNotEnable:
        'This {{account}} is already registered. Please change your {{account}}. If you still wish to bind, please first disassociate the previously failed {{account}} from its original account before binding it to the currently logged-in account.',
    bindMeetQuestion: 'Encountered a problem during binding',
    changeAccount: 'Change {{account}}',
    goBind: 'Go to Bind',
    unbind: 'Unbind',
    notEnable: 'Not Enabled',
    bindMobileNumber: 'Bind Mobile Number',
    bindMobileNumberAfterLoginApp: 'After binding, you can log in to the app using your mobile number.',
    enterSmsCode: 'Enter SMS Verification Code',
    confirmUnbinding: 'Are you sure you want to continue with the unbinding?',
    unBindNotLoginConfirmDescribe:
        'After unbinding, you will no longer be able to log in via {{account}}. Are you sure you want to continue with the unbinding?',
    alreadyUnbindAccount: ' Mobile {{account}} binding has been unlinked',
    // date
    beforeYesterday: 'The day before yesterday',
    yesterday: 'Yesterday',
    today: 'Today',
    tomorrow: 'Tomorrow',
    afterTomorrow: 'The day after tomorrow',

    '0000000': 'Success',
    '0011004': 'Application does not exist on client side',
    '0011005': 'Page has no access permission',
    '0011010': 'Name duplicate',
    '0011013': 'Unable to enable workflow, please check the configuration and try again',
    '0011023': 'Test account connection failed',
    '0011024': 'Number of applications reached the limit',
    '0011025': 'Number of records reached the limit',
    '0011027': 'Attachment capacity reached the limit',
    '0011028': 'AI usage limit reached',
    '0011030': 'Duplicate phone number',
    '0011031': 'Department name duplicate',
    '0011032': 'Number of users reached the limit',
    '0011033': 'Number of applications with bound custom domain reached the limit',
    '0011034': 'Data query computation power reached the limit',
    '0011035': 'Monthly data table query computation power reached the limit',
    '0011036': 'Export exceeds 500 records',
    '0011037': 'Export exceeds 50MB',
    '0011039': 'Account exception, please contact administrator',
    '0011042': 'WeChat official account unauthorized',
    '0011043': 'Duplicate email',
    '0011044': 'Phone number already bound',
    '0011045': 'WeChat payment exception',
    '0011048': 'Cannot delete user through action',
    '0011049': 'No records found',
    '0011050': '',
    '0011051': 'WeChat template message sending message parameter does not meet the specification',
    '0011052': 'WeChat template is unavailable',
    '0011053': 'Failed to obtain the WeChat template. Please check the relevant configuration',
    '0011054': 'Environment has anomalies, merging is prohibited',
    '0011055': 'Modifications are prohibited in the production environment',
    '0011057':
        'The user of this application has already been bound by other applications and cannot be bound to other applications any more.',
    '0011058': 'Private template is forbidden to operate',
    '0011059': 'WeChat cannot be linked repeatedly',
    '0020000': 'Page does not exist or page not public!',
    '0021002': 'User not registered',
    '0021003': 'Space does not exist',
    '0021004': 'Application does not exist',
    '0021005': 'Page does not exist',
    '0021006': 'Invalid invitation token',
    '0021007': 'Invitation space deleted',
    '0021008': 'Data source has been deleted',
    '0021009': 'User does not exist',
    '0021011': 'Verification code has expired',
    '0021012': 'Invalid invitation code',
    '0021013': 'No permission to perform this operation',
    '0021014': 'Inheritance exception',
    '0021017': 'Application has been taken offline',
    '0021018': 'Domain name already exists, please re-enter',
    '0011019': 'User already registered',
    '0021020': 'Please refresh and try again',
    '0011021': 'Your account is under review',
    '0021029': 'No permission for application',
    '0021038': 'Data source associated with excessive data',
    '0021039': 'Application template does not exist',
    '0030000': 'Filter exception, no need to throw',
    '0031000': 'Circular reference',
    '0120001': 'No operation permission',
    '0311002': 'Please login first',
    '0311004': 'Session expired, please login again',
    '0311005': 'Authentication expired',
    '0321001': 'Incorrect username or password',
    '0321003': 'Incorrect verification code, please re-enter',
    '0321004': 'Token has expired',
    '0321006': 'Account does not exist, please register'
}
