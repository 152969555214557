// import { CollapseBlock } from '@lighthouse/block'
import { useCustomViewBlockContext } from '@lighthouse/block'
import type { CollapseBlockAbstract } from '@lighthouse/core'
import type { RichTextEditorProps } from '@lighthouse/shared'
import { useApplicationContext } from '@lighthouse/shared'
import type { FC } from 'react'
import React, { Suspense, useMemo } from 'react'

import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'

const CollapseBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.CollapseBlock })))

interface CollapseBlockControllerProps {
    blockData: CollapseBlockAbstract
}

const CollapseBlockController: FC<CollapseBlockControllerProps> = ({ blockData }) => {
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const {
        prev: { recordId: parentRecordId },
        curr: { recordId }
    } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const { renderLabel } = useVariableValueRender(pageId, parentRecordId, recordId)
    const { personOptions } = useApplicationContext()

    const { record } = useCustomViewBlockContext()

    const config: RichTextEditorProps['config'] = useMemo(
        () => ({ variable: { renderLabel: v => renderLabel(v.attrs.value, { personOptions, viewRecord: record }) } }),
        [personOptions, record, renderLabel]
    )
    return (
        <Suspense fallback={<div />}>
            <CollapseBlock blockData={blockData} config={config} />
        </Suspense>
    )
}

export default CollapseBlockController
