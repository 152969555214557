import React, { useRef } from 'react'

import type { TooltipOptions } from './hook'
import { useTooltip } from './hook'
import type { TooltipWidth } from './Tooltip.context'
import { TooltipProvider } from './Tooltip.context'
import type { TooltipContentProps } from './TooltipContent'
import { TooltipContent } from './TooltipContent'
import { TooltipTarget } from './TooltipTarget'

export interface TooltipProps extends TooltipOptions, Omit<TooltipContentProps, 'arrowRef'> {
    children: React.ReactNode
    title: string | React.ReactNode
    disabled?: boolean
    width?: TooltipWidth
    minWidth?: number
}

export const Tooltip = React.forwardRef<HTMLElement, TooltipProps>(
    (
        {
            children,
            initialOpen,
            open,
            disabled,
            interactive,
            offset,
            placement,
            title,
            onOpenChange,
            width,
            minWidth,
            arrowRef: propArrowRef,
            withArrow,
            disablePortal,
            zIndex,
            classNames,
            unstyled,
            styles,

            ...props
        },
        ref
    ) => {
        const arrowRef = useRef<SVGSVGElement>(null)
        const tooltip = useTooltip({ initialOpen, placement, open, disabled, interactive, offset, onOpenChange, width, minWidth, arrowRef })
        return (
            <TooltipProvider value={tooltip}>
                <TooltipTarget ref={ref} {...props}>
                    {children}
                </TooltipTarget>
                <TooltipContent
                    disablePortal={disablePortal}
                    withArrow={withArrow}
                    arrowRef={propArrowRef ?? arrowRef}
                    zIndex={zIndex}
                    styles={styles}
                    classNames={classNames}
                    unstyled={unstyled}
                >
                    {title}
                </TooltipContent>
            </TooltipProvider>
        )
    }
)
