import { useCustomViewBlockContext } from '@lighthouse/block'
import type { TabsBlockAbstract, VariableADTvalue } from '@lighthouse/core'
import { useAppContainerContext, useAtomAction, useAtomData } from '@lighthouse/shared'
import produce from 'immer'
import React, { Suspense, useCallback, useMemo } from 'react'

import { pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { useCurrentAppId, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'
import { useVisibilityFilter } from '@/hooks/useVisibilityFilter'

const TabsBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.TabsBlock })))

interface TabsControllerProps {
    blockData: TabsBlockAbstract
}

const TabsController = ({ blockData }: TabsControllerProps) => {
    const { associatedContainer, baseList } = blockData.config

    const { scale } = useAppContainerContext()

    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()

    const currentTab = useAtomData(
        pageStackAtomFamily({ pageId, stackId }),
        useCallback(
            s => {
                if (!s) {
                    return
                }
                if (associatedContainer) {
                    const view = s.blockRuntimeState.container?.[associatedContainer].currentView
                    if (view) {
                        return baseList.find(item => item.associatedView === view)?.id
                    }
                }

                // return s.state.blockRuntimeState.tabs?.[blockData.id].currentTab
            },
            [associatedContainer, baseList]
        )
    )

    const { run: setPageStack } = useAtomAction(pageStackAtom)

    const handleTabsChange = useCallback(
        (id: string) => {
            setPageStack(draft => {
                const pageStack = draft.find(equalPageStack(pageId, stackId))
                if (!pageStack) {
                    return
                }

                const view = baseList.find(item => item.id === id)?.associatedView
                if (view) {
                    pageStack.blockRuntimeState.container = {
                        ...pageStack.blockRuntimeState.container,
                        [associatedContainer]: {
                            ...pageStack.blockRuntimeState.container?.[associatedContainer],
                            currentView: view
                        }
                    }
                }

                pageStack.blockRuntimeState.tabs = {
                    ...pageStack.blockRuntimeState.tabs,
                    [blockData.id]: {
                        ...pageStack.blockRuntimeState.tabs?.[blockData.id],
                        currentTab: id
                    }
                }
            })
        },
        [associatedContainer, blockData.id, baseList, pageId, setPageStack, stackId]
    )

    const { prev, curr } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })
    const visibilityFilter = useVisibilityFilter({ prev, curr })

    const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const { record: customViewRecord, pointer } = useCustomViewBlockContext()
    const dataSource = useDataSource(appId, envId, pointer)
    const visibleList = useMemo(
        () =>
            baseList.filter(item =>
                visibilityFilter({
                    visibilityFilter: item.visibilityFilter,
                    viewRecord: {
                        record: customViewRecord,
                        datasource: dataSource
                    }
                })
            ),
        [baseList, visibilityFilter, customViewRecord, dataSource]
    )

    const newData = useMemo(
        () =>
            produce(blockData, draft => {
                draft.config.baseList = visibleList
            }),
        [blockData, visibleList]
    )

    /** **************************** 解析背景图片需要使用的参数 start **************************** */
    const { renderLabel } = useVariableValueRender(pageId, prev.recordId, curr.recordId)
    const parseBackgroundVariableImage = useCallback(
        (value: VariableADTvalue | undefined) => renderLabel(value, { useFileUrl: true }),
        [renderLabel]
    )
    /** **************************** 解析背景图片需要使用的参数 end ****************************** */

    return (
        <Suspense fallback={<div />}>
            <TabsBlock
                blockData={newData}
                scale={scale}
                value={currentTab}
                onChange={handleTabsChange}
                parseVariableImage={parseBackgroundVariableImage}
            />
        </Suspense>
    )
}

export default TabsController
