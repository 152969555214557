import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type {
    ActionsProtocol,
    AiFieldStatus,
    ButtonAction,
    DataSourceAbstract,
    Field,
    FieldADTValue,
    RecordLikeProtocol,
    RichTextContentProtocol,
    ViewField
} from '@lighthouse/core'
import {
    fieldConvertValue,
    hasIconFieldType,
    useActionRunningLoadings,
    useApplicationContext,
    ViewFieldStatusPreviewer
} from '@lighthouse/shared'
import type { atomWithImmer } from 'jotai-immer'
import type { FC, HTMLAttributes } from 'react'
import React, { forwardRef, memo, useCallback } from 'react'

import { RecordOperator } from '../../components/RecordOperator'
import * as SC from './styles'

export interface RecordRenderProps extends ActionsProtocol {
    schema: Record<string, Field>
    record: RecordLikeProtocol
    columns: ViewField[]
    clickable?: boolean
    selected?: boolean
    disabled?: boolean
    recordEditOpenable: boolean
    recordDeleteAble: boolean
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    onSelectChange?: (selected: boolean, id: string) => void
    onRecordClick?: (id: string) => void
    onRecordEdit: (recordId: string) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
}

interface RecordItemProps extends RecordRenderProps, HTMLAttributes<HTMLDivElement> {}

interface SortableRecordItemProps extends RecordItemProps {
    id: string
}

export const RecordItem = forwardRef<HTMLDivElement, RecordItemProps>((props, ref) => {
    const {
        actions,
        record,
        schema,
        columns,
        clickable,
        selected,
        disabled,
        recordEditOpenable,
        recordDeleteAble,
        aiFieldStatusListAtom,
        onRecordClick,
        onRecordDelete,
        onRecordEdit,
        onSelectChange,
        onAiGeneration,
        onRecordOperatorActionTrigger,
        onRecordClickedActionTrigger,
        onRenderButtonTitle,
        ...rest
    } = props
    const { id: recordId, dsId, content } = record
    const { personOptions } = useApplicationContext()
    const titleFieldId = columns[0].fieldId
    const titleField = schema[titleFieldId]

    const { loadings, handleActionTriggerWithLoading } = useActionRunningLoadings()

    const handleAiGeneration = useCallback(
        (fieldId: string) => {
            if (!onAiGeneration) {
                return Promise.resolve(false)
            }
            return onAiGeneration(recordId, fieldId)
        },
        [onAiGeneration, recordId]
    )

    const handleRecordClick = useCallback(() => {
        const recordClickedActionParams = actions?.recordClicked
        if (recordClickedActionParams?.customized && recordClickedActionParams?.action) {
            handleActionTriggerWithLoading({
                type: 'click',
                id: recordId,
                action: recordClickedActionParams.action,
                record,
                trigger: onRecordClickedActionTrigger
            })
            return
        }
        clickable && onRecordClick?.(recordId)
    }, [actions?.recordClicked, clickable, handleActionTriggerWithLoading, onRecordClick, onRecordClickedActionTrigger, record, recordId])

    return (
        <SC.RecordItemWrapper>
            <SC.OperatorContainer>
                <RecordOperator
                    dsId={dsId}
                    actions={actions?.recordOperator?.actionGroup?.list}
                    customized={actions?.recordOperator?.customized}
                    recordId={recordId}
                    record={record}
                    onRecordEdit={onRecordEdit}
                    onRecordDelete={onRecordDelete}
                    config={{ canEdit: recordEditOpenable, canDelete: recordDeleteAble }}
                    // eslint-disable-next-line no-return-await
                    onActionTrigger={async action => await onRecordOperatorActionTrigger?.(action, record)}
                    onRenderButtonTitle={v => onRenderButtonTitle(v, record)}
                />
            </SC.OperatorContainer>
            {/* 2023-09-06 14:15:01 灿白说去掉，后面只有表格和高级表格有 CheckBox */}
            {/* {onSelectChange && (
                <SC.CheckboxContainer>
                    <Checkbox
                        size="xs"
                        styles={{
                            input: {
                                '&:checked': {
                                    backgroundColor: 'var(--color-app-main)',
                                    borderColor: 'var(--color-app-main)'
                                }
                            }
                        }}
                        checked={selected}
                        onChange={e => {
                            onSelectChange?.(e.target.checked, record.id)
                        }}
                    />
                </SC.CheckboxContainer>
            )} */}
            <SC.RecordItemContainer
                ref={ref}
                {...rest}
                style={{ cursor: loadings[recordId] ? 'not-allowed' : 'pointer' }}
                onClick={handleRecordClick}
                hasHoverStyle={clickable || !disabled}
            >
                {titleField && (
                    <ViewFieldStatusPreviewer
                        dsId={dsId}
                        recordId={recordId}
                        isBold
                        enableEdit={false}
                        field={titleField}
                        disabled
                        data={content?.[titleField.id]?.value}
                        personOptions={personOptions}
                        aiFieldStatusListAtom={aiFieldStatusListAtom}
                        onAiGeneration={handleAiGeneration}
                    />
                )}
                {columns.slice(1).map(col => {
                    const field = schema[col.fieldId]
                    const data = content?.[col.fieldId]?.value
                    const fieldValue = { ...field, value: data } as FieldADTValue
                    const realValue = fieldConvertValue(fieldValue)
                    const isRender = field && (realValue || hasIconFieldType.has(field.type))
                    if (!col.visible || !isRender) {
                        return null
                    }
                    return (
                        <SC.RecordItemField key={col.fieldId}>
                            <SC.RecordItemFieldHead>
                                {/* <IconFont type={getFieldIcon(col.fieldId, col.type)} /> */}
                                <div>{col.title}</div>
                            </SC.RecordItemFieldHead>
                            <ViewFieldStatusPreviewer
                                dsId={dsId}
                                recordId={recordId}
                                enableEdit={false}
                                field={field}
                                data={data}
                                disabled
                                personOptions={personOptions}
                                aiFieldStatusListAtom={aiFieldStatusListAtom}
                                onAiGeneration={handleAiGeneration}
                            />
                        </SC.RecordItemField>
                    )
                })}
            </SC.RecordItemContainer>
        </SC.RecordItemWrapper>
    )
})

export const SortableRecordItem: FC<SortableRecordItemProps> = memo(({ disabled, id, ...rest }) => {
    const { setNodeRef, listeners, attributes, isDragging, transition, transform } = useSortable({
        id,
        disabled
    })

    return (
        <RecordItem
            ref={disabled ? undefined : setNodeRef}
            style={{ transition, transform: CSS.Transform.toString(transform), opacity: isDragging ? 0.5 : undefined }}
            {...attributes}
            {...listeners}
            {...rest}
            disabled={disabled}
        />
    )
})
