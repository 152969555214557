import { Image, pointer, Tooltip } from '@byecode/ui'
import React, { forwardRef, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { avatarSize } from '../../constants'
import type { AvatarSize } from '../../types'

interface UserAvatarProps {
    id?: string
    avatar?: string
    name: string
    size?: AvatarSize | number
    fontSize?: number
    isDot?: boolean
    displayHover?: boolean
    background?: string
    borderColor?: string
    color?: string
    style?: React.CSSProperties
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const Container = styled.div<{ size?: number,borderColor?: string; }>`
    width: fit-content;
    height: fit-content;
    border-radius: 100%;
    overflow: hidden;


    ${({ borderColor }) => {
        return (
            borderColor &&
            css`
                border: 1px solid ${borderColor};
            `
        )
    }}
`

const Color = styled.div<{ background?: string }>`
    position: absolute;
    right: 0px;
    top: 0px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid var(--color-white);
    ${({ background }) => {
        return (
            background &&
            css`
                background: ${background};
            `
        )
    }}
    z-index: 101;
`

const Avatar = styled.div<{ background?: string; size?: number }>`
    width: ${({ size }) => (size ? `${size}px` : '24px')};
    height: ${({ size }) => (size ? `${size}px` : '24px')};
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;
    ${pointer}
`

export const UserBoxText = styled.div<{ backgroundColor?: string; color?: string;  size?: number; fontSize: number }>`
    width: ${({ size }) => (size ? `${size}px` : '24px')};
    height: ${({ size }) => (size ? `${size}px` : '24px')};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${({ fontSize }) => `${fontSize}px`};

    color: ${({ color }) => color};

    ${({ backgroundColor }) => {
        return (
            backgroundColor &&
            css`
                background: ${backgroundColor};
            `
        )
    }}


    ${pointer}
`

export const SCxText = styled.div`
    width: fit-content;
    margin: 0 auto;
`

// const BlockTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)

export const UserAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(
    (
        {
            id,
            avatar,
            name,
            displayHover = false,
            background,
            borderColor = 'var(--color-gray-200)',
            size = 'middle',
            fontSize = 12,
            color = 'var(--color-black)',
            isDot,
            onClick,
            ...rest
        },
        ref
    ) => {
        const userSize = useMemo(() => avatarSize[size] ?? size, [size])

        const backgroundColor = background || 'var(--color-gray-50)'
        const renderAvatar = useMemo(() => {
            if (avatar) {
                return (
                    <Avatar size={userSize} >
                        <Image src={avatar} fit='cover' width='100%' height='100%' />
                    </Avatar>
                )
            }
            return (
                <UserBoxText size={userSize} color={color} fontSize={fontSize} backgroundColor={backgroundColor} >
                    {name.slice(0, 1)}
                </UserBoxText>
            )
        }, [avatar, backgroundColor, color, fontSize, name, userSize])

        return (
            <Tooltip title={name} placement="top" disabled={displayHover} withArrow ref={ref}>
                <Container onClick={onClick} borderColor={borderColor} {...rest} >
                    {renderAvatar}
                    {isDot && <Color background={background} />}
                </Container>
            </Tooltip>
        )
    }
)
