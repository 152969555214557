import type {
    ActionsProtocol,
    AiFieldStatus,
    ButtonAction,
    HighLightConditions,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SchemaProtocol,
    TableViewOptions,
    ViewField
} from '@lighthouse/core'
import { useActionRunningLoadings } from '@lighthouse/shared'
import { Checkbox, Image } from '@mantine/core'
import type { Virtualizer } from '@tanstack/react-virtual'
import type { atomWithImmer } from 'jotai-immer'
import React, { useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { RecordOperator } from '../../components/RecordOperator'
import { getImageFromField } from '../../utils'
import { SCxCheckerPrefix, SCxTableCheckerContent, TableChecker } from './TableChecker'
import { TableValue } from './TableValue'
import { getAvatarWidth, getDesignStyleMap } from './utils'

export const SCxTableCells = styled.div<{ paddingTop?: boolean; borderTop?: boolean; isStripe?: boolean }>`
    width: 100%;
    border-top: ${({ borderTop }) => (borderTop ? '1px solid var(--color-gray-200)' : 'none')};
    background-color: ${({ isStripe }) => (isStripe ? 'var(--color-gray-100)' : 'transparent')};
`

const SCxTableSuffix = styled.div<{ sticky?: boolean; borderTop?: boolean }>`
    width: 8px;
    height: 100%;
    background: var(--color-white);
    overflow-y: visible;
    border-top: ${({ borderTop }) => (borderTop ? '1px solid var(--color-gray-200)' : 'none')};
    ${props =>
        props.sticky &&
        css`
            position: sticky;
            top: 0;
            right: 0;
            z-index: 1;
        `}
`

const SCxTableDot = styled.div<{ borderTop?: boolean }>`
    width: 36px;
    height: 100%;
    background: var(--color-white);
    overflow-y: visible;
    border-top: ${({ borderTop }) => (borderTop ? '1px solid var(--color-gray-200)' : 'none')};
    position: sticky;
    top: 0;
    right: 8px;
    z-index: 1;
`

const SCxTableOperator = styled.div<{ isStripe?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 100%;
    background-color: ${({ isStripe }) => (isStripe ? 'var(--color-gray-100)' : 'transparent')};
`

const SCxRemain = styled.div<{ borderRadius?: boolean; isStripe?: boolean }>`
    width: 8px;
    height: 100%;
    background-color: ${({ isStripe }) => (isStripe ? 'var(--color-gray-100)' : 'transparent')};
    ${props =>
        props.borderRadius &&
        css`
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        `}
    overflow: hidden;
`

const SCxTableAvatarWrapper = styled.div<{ width: number; borderTop?: boolean }>`
    position: sticky;
    top: 0;
    left: 8px;
    height: 100%;
    width: ${({ width }) => `${width}px`};
    overflow: hidden;
    z-index: 1;
    background-color: var(--color-white);
    flex-shrink: 0;
    font-size: var(--font-size-normal);
    border-top: ${({ borderTop }) => (borderTop ? '1px solid var(--color-gray-200)' : 'none')};
`

const SCxTableAvatarBackground = styled.div<{ isStripe?: boolean }>`
    width: 100%;
    height: 100%;
    padding-left: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: ${({ isStripe }) => (isStripe ? 'var(--color-gray-100)' : 'var(--color-white)')};
`

export const SCxTablePrimary = styled.div<{ borderTop?: boolean; left: number }>`
    position: sticky;
    top: 0;
    left: ${({ left }) => `${left + 8}px`};
    height: 36px;
    max-width: calc(100% - 60px);
    line-height: 36px;
    padding-top: 6px;
    padding-left: 0;
    overflow: hidden;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    font-size: var(--font-size-normal);
    border-top: ${({ borderTop }) => (borderTop ? '1px solid var(--color-gray-200)' : 'none')};
`

interface SCxTableRecordWrapperStyledParams {
    clickable?: boolean
    isMobile?: boolean
    isStripe?: boolean
    hoverColor: string
}
export const SCxTableRecordWrapper = styled.div<SCxTableRecordWrapperStyledParams>`
    ${props => {
        return (
            props.isStripe &&
            props.isMobile &&
            css`
                background-color: var(--color-gray-100);
            `
        )
    }}

    &.record-active {
        ${props => {
            if (props.isMobile) {
                return css`
                    ${SCxTableCells} {
                        background-color: transparent;
                    }

                    ${SCxRemain} {
                        background-color: ${props.hoverColor};
                    }

                    ${SCxTableOperator} {
                        background-color: ${props.hoverColor};
                    }

                    ${SCxCheckerPrefix} {
                        background-color: ${props.hoverColor};
                    }

                    ${SCxTableCheckerContent} {
                        background-color: transparent;
                    }

                    ${SCxTableAvatarBackground} {
                        background-color: ${props.hoverColor};
                    }
                `
            }
            return css`
                ${SCxTableCells} {
                    background-color: ${props.hoverColor};
                }

                ${SCxRemain} {
                    background-color: ${props.hoverColor};
                }

                ${SCxTableOperator} {
                    background-color: ${props.hoverColor};
                }

                ${SCxCheckerPrefix} {
                    background-color: ${props.hoverColor};
                }

                ${SCxTableCheckerContent} {
                    background-color: ${props.hoverColor};
                }

                ${SCxTableAvatarBackground} {
                    background-color: ${props.hoverColor};
                }
            `
        }}
    }

    ${props => {
        if (props.clickable) {
            if (props.isMobile) {
                return css`
                    &:hover {
                        background-color: ${props.hoverColor};
                    }
                    &:hover ${SCxTableCheckerContent} {
                        background-color: transparent;
                    }

                    &:hover ${SCxCheckerPrefix} {
                        background-color: ${props.hoverColor};
                    }

                    &:hover ${SCxRemain} {
                        background-color: ${props.hoverColor};
                    }

                    &:hover ${SCxTableOperator} {
                        background-color: ${props.hoverColor};
                    }

                    &:hover ${SCxTableCells} {
                        background-color: transparent;
                    }
                    &:hover ${SCxTableAvatarBackground} {
                        background-color: ${props.hoverColor};
                    }
                `
            }

            return css`
                cursor: pointer;

                &:hover ${SCxTableCheckerContent} {
                    background-color: ${props.hoverColor};
                }

                &:hover ${SCxCheckerPrefix} {
                    background-color: ${props.hoverColor};
                }

                &:hover ${SCxRemain} {
                    background-color: ${props.hoverColor};
                }

                &:hover ${SCxTableOperator} {
                    background-color: ${props.hoverColor};
                }

                &:hover ${SCxTableCells} {
                    background-color: ${props.hoverColor};
                }

                &:hover ${SCxTableAvatarBackground} {
                    background-color: ${props.hoverColor};
                }
            `
        }
    }}
`

export const SCxTableRecord = styled.div`
    position: relative;
    display: flex;
    height: 100%;
`

interface TableRecordProps extends ActionsProtocol {
    record: RecordLikeProtocol
    schema: SchemaProtocol['schema']
    blockId: string
    rowHeight: number
    rowWidth: number
    isMobile?: boolean
    recordOpenable?: boolean
    recordEditOpenable: boolean
    recordDeleteAble: boolean
    selectedRecords: string[]
    checkable?: boolean
    index: number
    scrollRef: React.RefObject<HTMLDivElement>
    blockWidth: number
    columnVirtualizer: Virtualizer<HTMLDivElement, Element>
    viewFields: ViewField[]
    primaryFieldId?: string
    rowStyleConditions: Record<string, HighLightConditions>
    cellStyleConditions: Record<string, HighLightConditions>
    designConfig: TableViewOptions
    hoverColor: string
    stripeHoverColor: string
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    onRecordClick?: (recordId: string) => void
    onRecordSelect?: (recordIds: string[]) => void
    onRecordEdit?: (recordId: string) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
}

const TableRecord: React.FC<TableRecordProps> = ({
    record,
    actions,
    schema,
    blockId,
    rowHeight,
    isMobile,
    recordOpenable,
    recordEditOpenable,
    recordDeleteAble,
    selectedRecords = [],
    checkable,
    index,
    columnVirtualizer,
    viewFields,
    primaryFieldId = '',
    cellStyleConditions,
    designConfig,
    hoverColor,
    stripeHoverColor,
    aiFieldStatusListAtom,
    onRecordClick,
    onRecordSelect,
    onRecordEdit,
    onRecordDelete,
    onAiGeneration,
    onRecordOperatorActionTrigger,
    onRecordClickedActionTrigger,
    onRenderButtonTitle
}) => {
    const { id: recordId, dsId, content: recordContent } = record
    const { designStyle, listAvatar = '', isShowListAvatar, avatarType } = designConfig
    const isAvatar = useMemo(() => isShowListAvatar && listAvatar && schema?.[listAvatar], [isShowListAvatar, listAvatar, schema])
    // const realViewFields = useMemo(() => {
    //     let fields: ViewField[] = []
    //     if (isMobile) {
    //         fields = viewFields.slice(1)
    //     }
    //     if (isAvatar) {
    //         fields = fields.filter(item => item.fieldId !== listAvatar)
    //     }
    //     return fields
    // }, [isAvatar, isMobile, listAvatar, viewFields])
    const { loadings, handleActionTriggerWithLoading } = useActionRunningLoadings()
    const avatarWidth = useMemo(() => {
        if (isAvatar) {
            return getAvatarWidth(isMobile)
        }
        return 0
    }, [isAvatar, isMobile])
    const recordTop = useMemo(() => (isMobile ? 36 : 0), [isMobile])
    // const avatarUrl = useMemo(() => {
    //     if (!isShowListAvatar || !listAvatar) {
    //         return
    //     }
    //     const fileValue = recordContent?.[listAvatar]?.value
    //     return isFileValue(fileValue) ? fileValue?.[0].url : ''
    // }, [isShowListAvatar, listAvatar, recordContent])
    const designStyleOption = useMemo(() => getDesignStyleMap(designStyle), [designStyle])
    const { isStripe, isBorderTop, isBorderRadius, isExtraBorderTop } = designStyleOption
    const isRecordBorderTop = useMemo(() => isBorderTop && index > 0, [index, isBorderTop])
    const isRecordStripe = isStripe && index % 2 === 0
    const isRecordExtraBorderTop = useMemo(() => isExtraBorderTop && index > 0, [index, isExtraBorderTop])

    const handleSelectRecord = useCallback(
        (checked: boolean, id: string) => {
            if (checked) {
                onRecordSelect?.([...selectedRecords, id])
            } else {
                onRecordSelect?.(selectedRecords.filter(rId => rId !== id))
            }
        },
        [onRecordSelect, selectedRecords]
    )

    const handleAiGeneration = useCallback(
        (fieldId: string) => {
            if (!onAiGeneration) {
                return Promise.resolve(false)
            }
            return onAiGeneration(recordId, fieldId)
        },
        [onAiGeneration, recordId]
    )

    const handleRecordClick = useCallback(() => {
        const recordClicked = actions?.recordClicked
        if (recordClicked?.customized && recordClicked?.action) {
            if (loadings[recordId]) {
                return
            }
            handleActionTriggerWithLoading({
                type: 'click',
                id: recordId,
                action: recordClicked.action,
                record,
                trigger: onRecordClickedActionTrigger
            })
            return
        }
        recordOpenable && onRecordClick?.(recordId)
    }, [
        actions?.recordClicked,
        handleActionTriggerWithLoading,
        loadings,
        onRecordClick,
        onRecordClickedActionTrigger,
        record,
        recordId,
        recordOpenable
    ])

    const handleContent = useMemo(() => {
        if (!recordEditOpenable && !recordDeleteAble && !actions?.recordOperator?.customized) {
            return null
        }
        return (
            <SCxTableDot borderTop={isRecordBorderTop} onClick={ev => ev.stopPropagation()}>
                <SCxTableOperator isStripe={isRecordStripe}>
                    {isMobile && selectedRecords && selectedRecords.length > 0 ? (
                        <Checkbox
                            size="xs"
                            disabled={!checkable}
                            checked={selectedRecords.includes(recordId)}
                            onChange={ev => {
                                if (!checkable) {
                                    return
                                }
                                handleSelectRecord(ev.target.checked, recordId)
                            }}
                        />
                    ) : (
                        <RecordOperator
                            dsId={dsId}
                            actions={actions?.recordOperator.actionGroup?.list}
                            customized={actions?.recordOperator?.customized}
                            config={{ canEdit: recordEditOpenable, canDelete: recordDeleteAble }}
                            onRecordDelete={onRecordDelete}
                            onRecordEdit={onRecordEdit}
                            recordId={recordId}
                            record={record}
                            // eslint-disable-next-line no-return-await
                            onActionTrigger={async action => await onRecordOperatorActionTrigger?.(action, record)}
                            onRenderButtonTitle={v => onRenderButtonTitle(v, record)}
                        />
                    )}
                </SCxTableOperator>
            </SCxTableDot>
        )
    }, [
        recordEditOpenable,
        recordDeleteAble,
        actions?.recordOperator?.customized,
        actions?.recordOperator.actionGroup?.list,
        isRecordBorderTop,
        isRecordStripe,
        isMobile,
        selectedRecords,
        checkable,
        recordId,
        dsId,
        onRecordDelete,
        onRecordEdit,
        handleSelectRecord,
        onRecordOperatorActionTrigger,
        onRenderButtonTitle,
        record
    ])

    return (
        <SCxTableRecordWrapper
            key={recordId}
            hoverColor={isRecordStripe ? stripeHoverColor : hoverColor}
            isMobile={isMobile}
            isStripe={isRecordStripe}
            style={{ height: `${rowHeight}px`, cursor: loadings[recordId] ? 'not-allowed' : 'pointer' }}
            clickable={recordOpenable}
            data-key={`${blockId}&${recordId}`}
            onClick={handleRecordClick}
        >
            <SCxTableRecord>
                {/* <RowHighlighter viewFields={viewFields} recordContent={recordContent} recordConditions={rowStyleConditions} /> */}

                <TableChecker
                    disableCheck={isMobile}
                    borderTop={isRecordBorderTop}
                    extraBorderTop={isRecordExtraBorderTop}
                    borderRadius={isBorderRadius}
                    isStripe={isRecordStripe}
                    height={rowHeight}
                    checkable={checkable}
                    isRecordSelecting={selectedRecords.length > 0}
                    index={index}
                    // isLast={isLast}
                    checked={selectedRecords.includes(recordId)}
                    onChange={checked => handleSelectRecord(checked, recordId)}
                    onRecordOpen={() => onRecordClick?.(recordId)}
                />
                {isAvatar && (
                    <SCxTableAvatarWrapper width={avatarWidth} borderTop={isRecordBorderTop}>
                        <SCxTableAvatarBackground isStripe={isRecordStripe}>
                            <Image
                                src={getImageFromField(recordContent?.[listAvatar])}
                                radius={avatarType === 'round' ? 16 : 4}
                                width={32}
                                height={32}
                                alt="Norway"
                                withPlaceholder
                                // styles={{
                                //     placeholder: {
                                //         overflow: 'hidden'
                                //     }
                                // }}
                                placeholder={<div style={{ background: 'var(--color-gray-200)' }} />}
                            />
                        </SCxTableAvatarBackground>
                    </SCxTableAvatarWrapper>
                )}
                {isMobile && (
                    <SCxTablePrimary borderTop={isRecordBorderTop} left={avatarWidth}>
                        {schema?.[primaryFieldId] && (
                            <TableValue
                                isBold
                                dsId={dsId}
                                recordId={recordId}
                                field={schema[primaryFieldId]}
                                // style={{ width: rowWidth - 85 }}
                                data={recordContent?.[primaryFieldId]?.value}
                                aiFieldStatusListAtom={aiFieldStatusListAtom}
                                onAiGeneration={handleAiGeneration}
                            />
                        )}
                    </SCxTablePrimary>
                )}
                <SCxTableCells borderTop={isRecordBorderTop} isStripe={isRecordStripe}>
                    {columnVirtualizer.getVirtualItems().map(virtualColumn => {
                        const curField = viewFields[virtualColumn.index]
                        if (!curField) {
                            return null
                        }
                        const cId = curField.fieldId
                        const field = schema?.[cId]
                        const cData = recordContent?.[cId]

                        if (!field) {
                            return null
                        }
                        const styleConditions = cellStyleConditions?.[cId] || []
                        return (
                            <TableValue
                                key={cId}
                                isBold={!isMobile && virtualColumn.index === 0}
                                dsId={dsId}
                                recordId={recordId}
                                style={{
                                    position: 'absolute',
                                    top: recordTop,
                                    bottom: 0,
                                    left: 0,
                                    width: `${virtualColumn.size}px`,
                                    transform: `translateX(${virtualColumn.start}px)`
                                }}
                                width={curField.width}
                                field={field}
                                data={cData?.value}
                                styleConditions={styleConditions}
                                aiFieldStatusListAtom={aiFieldStatusListAtom}
                                onAiGeneration={handleAiGeneration}
                            />
                        )
                    })}
                </SCxTableCells>
                {handleContent}
                <SCxTableSuffix sticky borderTop={isExtraBorderTop && index > 0}>
                    <SCxRemain borderRadius={isBorderRadius} isStripe={isRecordStripe} />
                </SCxTableSuffix>
            </SCxTableRecord>
        </SCxTableRecordWrapper>
    )
}

export default TableRecord
