import { hideScrollBar } from '@byecode/ui'
import { type VariableADTvalue, RecordOpenType } from '@lighthouse/core'
import {
    combineBackgroundStyle,
    FlowLayout,
    getBackgroundStyle,
    getVeinsStyle,
    PAGE_SCROLL_PARENT_CONTENT,
    PageContainerProvider,
    SortableMonitorProvider,
    transformNode2FlowLayoutNode,
    useAppContainerContext,
    useAtomData,
    useFillPickerContext,
    useManualSetMediaQueryRemBase
} from '@lighthouse/shared'
import { findParentScroller } from '@lighthouse/tools'
import React, { forwardRef, useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { pageAtomFamily, pageBlocksAtom, pageNodesAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/context/PageContext'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'
import { useVisibilityFilter } from '@/hooks/useVisibilityFilter'

import { PageSuspendPagination } from '../PageSuspendPagination'
import { BlockRender } from './BlockRender'

const PageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`

const ScrollerArea = styled.div`
    /* background-color: var(--color-white); */
    /* overflow: hidden auto; */
    overflow: hidden auto;
    width: 100%;
    height: 100%;
    ${hideScrollBar}
`

interface PageContentProps {
    loading?: boolean
}

export const PageContent = forwardRef<HTMLDivElement, PageContentProps>((props, ref) => {
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const rootPageId = useRootPageContext()
    const isRootPage = pageId === rootPageId
    const endPoint = useManualSetMediaQueryRemBase()
    const { disabledPageScroll } = useAppContainerContext()
    // const { shareUrl: currentPageLink } = usePageURI({ isCurrentPage: true })
    const pageDesign = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.design, [])
    )

    const pageNodes = useAtomData(
        pageNodesAtom,
        useCallback(s => s[pageId] ?? [], [pageId])
    )
    const pageBlocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s[pageId] ?? [], [pageId])
    )
    const blockRuntimeState = useAtomData(
        pageStackAtomFamily({ pageId, stackId }),
        useCallback(s => s?.blockRuntimeState, [])
    )
    const isPageOpenedAsPage = useAtomData(
        pageStackAtomFamily({ pageId, stackId }),
        useCallback(s => s?.stackDisplayType === RecordOpenType.page, [])
    )
    const { prev, curr } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })
    const getIsVisible = useVisibilityFilter({ prev, curr })

    const flowNodes = useMemo(
        () => transformNode2FlowLayoutNode(pageNodes, pageBlocks, blockRuntimeState, getIsVisible),
        [blockRuntimeState, getIsVisible, pageBlocks, pageNodes]
    )

    /** **************************** 解析背景图片需要使用的参数 start **************************** */
    const { renderLabel } = useVariableValueRender(pageId, prev.recordId, curr.recordId)
    const parseBackgroundVariableImage = useCallback(
        (value: VariableADTvalue | undefined) => renderLabel(value, { useFileUrl: true }),
        [renderLabel]
    )
    /** **************************** 解析背景图片需要使用的参数 end ****************************** */

    const background = isRootPage ? undefined : pageDesign?.background

    const { palettes } = useFillPickerContext()

    const backgroundStyle: React.CSSProperties = useMemo(() => {
        const styles = {
            ...combineBackgroundStyle([
                getBackgroundStyle(background, parseBackgroundVariableImage, palettes),
                getVeinsStyle(pageDesign?.veins, palettes)
            ])
        }

        return {
            ...styles,
            backgroundColor: background && (styles.backgroundColor || '#fff')
        }
    }, [background, pageDesign?.veins, palettes, parseBackgroundVariableImage])

    const layerLayoutStyle = useMemo(() => {
        // 如果以页面形式打开，我们已经做了页面背景的处理，不需额外给页面容器添加背景
        if (isPageOpenedAsPage) {
            return
        }

        return {
            ...backgroundStyle,
            backgroundAttachment: 'fixed'
        }
    }, [backgroundStyle, isPageOpenedAsPage])

    return (
        <PageContainer>
            {isPageOpenedAsPage &&
                createPortal(
                    <div
                        style={{
                            ...backgroundStyle,
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            inset: 0,
                            zIndex: -1,
                            pointerEvents: 'none'
                        }}
                    />,
                    document.body
                )}
            <PageContainerProvider endpoint={endPoint}>
                <ScrollerArea ref={ref} id={PAGE_SCROLL_PARENT_CONTENT}>
                    <SortableMonitorProvider key={pageId}>
                        <FlowLayout
                            disabled
                            data={flowNodes}
                            size={pageDesign?.size}
                            direction={pageDesign?.direction}
                            gap={pageDesign?.gap}
                            padding={pageDesign?.padding}
                            alignX={pageDesign?.alignX}
                            alignY={pageDesign?.alignY}
                            veins={pageDesign?.veins}
                            background={isRootPage ? undefined : pageDesign?.background}
                            style={layerLayoutStyle}
                            parseBackgroundVariableImage={parseBackgroundVariableImage}
                            nodeRender={BlockRender}
                        />
                    </SortableMonitorProvider>
                    {/* {stackDisplayType === 'page' && <DomainFiling data={domain} isMobile={isMobile} />} */}
                </ScrollerArea>
                {/* 悬浮视图工具栏 */}
                <PageSuspendPagination />
            </PageContainerProvider>
        </PageContainer>
    )
})
