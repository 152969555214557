import type { CascadeOption, MultiOption, MultiSelectItemProps } from '@byecode/ui'
import { Box, CascadeList, Checkbox, MultiSelectDropdown, MultiSelectItem, Popover } from '@byecode/ui'
import type { FilterOption, NumberFilterConfig, NumberRangeArray } from '@lighthouse/core'
import { FilterSelectWay, FilterWay, NumberRangeMode } from '@lighthouse/core'
import { NormalButton } from '@lighthouse/shared'
import { find, intersection } from 'rambda'
import React, { forwardRef, useCallback, useMemo, useRef, useState } from 'react'
import { useUpdate, useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { CustomDateFilter } from '../CustomDateFilter'
import { CustomNumberFilter } from '../CustomNumberFilter'
import { CustomNumberSliderFilter } from '../CustomNumberSliderFilter'
import { useGetOption } from '../hook'
import type { CustomProps, FilterItemProps } from '../types'
import { getChildrenOptions } from '../utils'

const SCxOptionItem = styled.div`
    word-break: break-word;
    font-size: 14px;
    display: flex;
    flex-direction: column;
`

const SCxOptionItemLabel = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 16px;
    padding: 10px 16px;
    overflow: hidden;
    word-break: break-word;
    cursor: pointer;
    &:hover {
        background-color: var(--color-gray-100);
    }
`

const SCxCustomNumberSliderFilterWrapper = styled.div`
    padding: 0 16px;
`

const DateOptionItem = forwardRef<HTMLDivElement, CustomProps & MultiSelectItemProps>((props, ref) => {
    const { customValue, onCustomChange, ...rest } = props
    const { isActive, label, value, onItemChange } = rest
    const handleSelect = useCallback(() => {
        onItemChange?.(value)
    }, [onItemChange, value])

    if (value === 'custom' && isActive) {
        return (
            <SCxOptionItem>
                <SCxOptionItemLabel onMouseDown={handleSelect}>
                    <Checkbox size="xs" checked={isActive} color="var(--color-app-main)" />
                    <Box style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{label}</Box>
                </SCxOptionItemLabel>
                <CustomDateFilter customValue={customValue} onCustomChange={onCustomChange} />
            </SCxOptionItem>
        )
    }
    return <MultiSelectItem ref={ref} {...rest} />
})

const NumberSliderOptionItem = forwardRef<
    HTMLDivElement,
    {
        onUpdate?: () => void
        step?: number
        numberRange?: NumberRangeArray
    } & CustomProps &
        MultiSelectItemProps
>((props, ref) => {
    const { step = 1, numberRange, onUpdate, customValue, onCustomChange, ...rest } = props
    const { isActive, label, value, onItemChange } = rest
    // const { step = 1, numberRange, value, isActive, label, customValue, onItemChange, onCustomChange, onUpdate } = props
    const [min, max] = numberRange || []
    const handleSelect = useCallback(() => {
        onItemChange?.(value)
    }, [onItemChange, value])

    useUpdateEffect(() => {
        onCustomChange?.(numberRange || [undefined, undefined])
        onUpdate?.()
    }, [numberRange])

    if (value === 'custom') {
        return (
            <SCxOptionItem>
                <SCxOptionItemLabel onMouseDown={handleSelect}>
                    <Checkbox size="xs" checked={isActive} color="var(--color-app-main)" />
                    <Box style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{label}</Box>
                </SCxOptionItemLabel>
                {min !== undefined && max !== undefined && isActive && (
                    <SCxCustomNumberSliderFilterWrapper>
                        <CustomNumberSliderFilter
                            max={max}
                            min={min}
                            step={step}
                            customValue={customValue}
                            onCustomChange={onCustomChange}
                        />
                    </SCxCustomNumberSliderFilterWrapper>
                )}
            </SCxOptionItem>
        )
    }
    return <MultiSelectItem ref={ref} {...rest} />
})

const NumberOptionItem = forwardRef<HTMLDivElement, CustomProps & MultiSelectItemProps & { config: NumberFilterConfig }>((props, ref) => {
    const { config, customValue, onCustomChange, ...rest } = props
    const { isActive, label, value, onItemChange } = rest

    const handleSelect = useCallback(() => {
        onItemChange?.(value)
    }, [onItemChange, value])

    if (value === 'custom' && isActive) {
        return (
            <SCxOptionItem>
                <SCxOptionItemLabel onMouseDown={handleSelect}>
                    <Checkbox size="xs" checked={isActive} color="var(--color-app-main)" />
                    <Box style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{label}</Box>
                </SCxOptionItemLabel>
                <CustomNumberFilter customValue={customValue} onCustomChange={onCustomChange} />
            </SCxOptionItem>
        )
    }
    return <MultiSelectItem ref={ref} {...rest} />
})

export const NormalFilterItem: React.FC<FilterItemProps> = ({
    config,
    value,
    dataSourceList,
    recordId,
    customValue: propCustomValue,
    onChange,
    onFetchCascadeOptions,
    onFetchTextOptions
}) => {
    const update = useUpdate()
    const [open, setOpen] = useState(false)
    // const [customValue, setCustomValue] = useState(propCustomValue)
    const customValueRef = useRef(propCustomValue)
    const { options } = useGetOption({ value, config, recordId, dataSourceList, onFetchCascadeOptions, onFetchTextOptions })
    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const optionsValues = useMemo(() => {
        if (config.filterWay === FilterWay.cascadeFilter) {
            const childrenOptions = getChildrenOptions(options)
            return childrenOptions.map(item => item.value)
        }
        return options.map(item => item.value)
    }, [config.filterWay, options])

    const getChildOption = useCallback((option: CascadeOption, list: CascadeOption[]) => {
        const { value, label, path, labelPath, children = [] } = option
        let arrList = list
        for (const child of children) {
            const newChild = {
                ...child,
                path: `${path}/${child.value}`,
                labelPath: `${labelPath}/${child.label}`,
                isLast: (child.children ?? []).length === 0
            }
            arrList = [...arrList, newChild, ...getChildOption(newChild, [])]
        }
        return arrList
    }, [])

    const flatOptions = useMemo(() => {
        if (config.filterWay === FilterWay.cascadeFilter) {
            return options.reduce<CascadeOption[]>((pre, cur) => {
                const newOption = { ...cur, path: cur.value, labelPath: cur.label, isLast: (cur.children ?? []).length === 0 }
                return [...pre, newOption, ...getChildOption(newOption, [])]
            }, [])
        }
        return options
    }, [config.filterWay, getChildOption, options])

    const isActive = useMemo(() => intersection(optionsValues, value || []).length > 0, [optionsValues, value])

    const handleChange = useCallback(
        (val: string[]) => {
            const realVal = intersection(optionsValues, val)
            onChange?.(config.id, { type: config.filterWay, value: realVal, customValue: customValueRef.current })
            // customValueRef.current && onCustomChange?.(config.id, customValueRef.current)
        },
        [config.filterWay, config.id, onChange, optionsValues]
    )

    const handleCascadeChange = useCallback(
        (val: string[]) => {
            const realVal = intersection(optionsValues, val)
            const selectValue = intersection(value || [], realVal)
            onChange?.(config.id, {
                type: config.filterWay,
                value: selectValue.length > 0 ? [] : realVal,
                customValue: customValueRef.current
            })
        },
        [config.filterWay, config.id, onChange, optionsValues, value]
    )

    const popoverWidth = useMemo(() => (config.filterWay === FilterWay.cascadeFilter ? 409 : 252), [config.filterWay])

    const description = useMemo(() => {
        return value
            ?.reduce<string[]>((prev, val) => {
                const opt = find(opt => opt.value === val, flatOptions)
                if (!opt) {
                    return prev
                }
                prev.push(opt.label)
                return prev
            }, [])
            .join(',')
    }, [flatOptions, value])

    const handleClear = useCallback(() => {
        onChange?.(config.id, { type: config.filterWay, value: [], customValue: propCustomValue })
    }, [config.filterWay, config.id, onChange, propCustomValue])

    const optionComponent: React.FC<Omit<React.ComponentPropsWithRef<'div'>, 'value' | 'children'> & MultiOption> | undefined =
        useMemo(() => {
            if (config.filterWay === FilterWay.numberFilter) {
                if (config.numberRangeMode === NumberRangeMode.custom) {
                    return ({ ...rest }) => (
                        <NumberOptionItem
                            {...rest}
                            config={config}
                            customValue={customValueRef.current}
                            onCustomChange={v => {
                                customValueRef.current = v
                            }}
                        />
                    )
                }
                if (config.numberRangeMode === NumberRangeMode.slider) {
                    const { step, numberRange } = config
                    return ({ ...rest }) => (
                        <NumberSliderOptionItem
                            {...rest}
                            step={step}
                            numberRange={numberRange}
                            // min={min}
                            // max={max}
                            onUpdate={update}
                            customValue={customValueRef.current}
                            onCustomChange={v => {
                                customValueRef.current = v
                            }}
                        />
                    )
                }
            }
            if (config.filterWay === FilterWay.dateFilter) {
                return ({ ...rest }) => (
                    <DateOptionItem
                        {...rest}
                        customValue={customValueRef.current}
                        onCustomChange={v => {
                            customValueRef.current = v
                        }}
                    />
                )
            }
            return undefined
        }, [config, update])

    const popoverContent = useMemo(() => {
        if (config.filterWay === FilterWay.cascadeFilter) {
            return (
                <CascadeList
                    data={value}
                    onChange={handleCascadeChange}
                    multiple={config.selectWay === FilterSelectWay.multiSelect}
                    lastLevel
                    options={options}
                />
            )
        }
        return (
            <MultiSelectDropdown
                isMulti={config.selectWay === FilterSelectWay.multiSelect}
                options={options}
                value={value}
                onMultiSelect={handleChange}
                themeColor="var(--color-app-main)"
                optionComponent={optionComponent}
                onClose={handleClose}
            />
        )
    }, [config.filterWay, config.selectWay, handleCascadeChange, handleChange, handleClose, optionComponent, options, value])

    return (
        <Popover opened={open} width={popoverWidth} withinPortal position="bottom-start" onChange={setOpen}>
            <Popover.Target>
                <NormalButton active={isActive} title={config.title} description={description} enableClear onClear={handleClear} />
            </Popover.Target>
            <Popover.Dropdown>{popoverContent}</Popover.Dropdown>
        </Popover>
    )
}
