import { type DataSourceAbstract, PageType } from '@lighthouse/core'
import type { CurrPageDatasourceForVariable, PrevPageDatasourceForVariable } from '@lighthouse/shared'
import { getMainTableRecordId, PAGE_URL_REG, useAtomData, VariableSourceType } from '@lighthouse/shared'
import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router'

import { pageAtomFamily, pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'

import { useCurrentAppId } from './useApplication'
import { useDataSourceList } from './useDataSource'
import { parseUrlParams } from './useSubscriptRouteForPageStack'

export const usePage = (id: string) => {
    return useAtomData(pageAtomFamily(id))
}

/**
 * 为数据选择器提供的页面数据，根据页面类型区分上游/当前页面数据
 * 1. 详情页和编辑页取当前页的数据
 * 2. 表单页取上游页面的数据
 * 3. 通过按钮动作打开的页面可能会携带recordId，这时也要取上游页面，但可能上游页面没有数据，需要根据传入的recordId判断dataSource的逻辑
 */
export const usePageDataSourceForVariableSelector = ({
    pageId,
    stackId
}: {
    stackId: string
    pageId: string
}): {
    dataSourceList: DataSourceAbstract[]
    prev: PrevPageDatasourceForVariable
    curr: CurrPageDatasourceForVariable
} => {
    const currentAppID = useCurrentAppId()
    const dataSourceList = useDataSourceList(currentAppID)

    const { pathname } = useLocation()

    const page = useAtomData(pageAtomFamily(pageId))
    const isRecordPage = page?.type === PageType.document || page?.type === PageType.edit
    const pageStack = useAtomData(pageStackAtomFamily({ pageId, stackId }))
    const pageDatasource = useMemo(
        () => (isRecordPage ? dataSourceList.find(item => item.id === pageStack?.dsId) : undefined),
        [dataSourceList, isRecordPage, pageStack?.dsId]
    )
    const pageRecordId = isRecordPage ? pageStack?.recordId : undefined

    // const isFormPageCarryingTheUpstreamPageRecordId = !!pageRecordId && !isRecordPage

    const upstreamPageStack = useAtomData(
        pageStackAtom,
        useCallback(
            list => {
                const urlParams = pathname.slice(1).match(PAGE_URL_REG)

                if (!urlParams) {
                    return
                }

                /** 查找上游带有数据信息的栈 */
                for (let i = urlParams.length - 2; i >= 0; i--) {
                    // const [, pageId] = urlParams[i].split('/')
                    const obj = parseUrlParams(urlParams[i], currentAppID)
                    const stack = list.findLast(
                        stack => stack.pageId === obj.pageId && stack.dsId === obj.dsId && stack.recordId === obj.recordId
                    )
                    if (!stack) {
                        continue
                    }

                    if (stack.dsId && stack.recordId /* || isFormPageCarryingTheUpstreamPageRecordId */) {
                        return stack
                    }
                }
            },
            [currentAppID, pathname]
        )
    )

    const upstreamDatasource = useMemo(
        () =>
            dataSourceList.find(
                item => item.id === /* isFormPageCarryingTheUpstreamPageRecordId ? pageStack?.dsId :  */ upstreamPageStack?.dsId
            ),
        [dataSourceList, upstreamPageStack?.dsId]
    )
    const upstreamPageRecordId = /* isFormPageCarryingTheUpstreamPageRecordId ? pageRecordId : */ upstreamPageStack?.recordId
    const upstreamPage = useAtomData(pageAtomFamily(upstreamPageStack?.pageId || ''))

    return useMemo(() => {
        return {
            prev: {
                variableSourceType: VariableSourceType.parentPage,
                page: upstreamPage,
                recordId: getMainTableRecordId(upstreamPageRecordId),
                datasource: upstreamDatasource,
                // formState: upstreamPageStack?.formState
            },
            curr: {
                variableSourceType: VariableSourceType.page,
                page,
                recordId: getMainTableRecordId(pageRecordId),
                datasource: pageDatasource,
                // formState: pageStack?.formState
            },
            dataSourceList
        }
    }, [
        dataSourceList,
        page,
        pageDatasource,
        pageRecordId,
        upstreamDatasource,
        upstreamPage,
        upstreamPageRecordId
    ])
}
