import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React, { forwardRef } from 'react'

import { Box } from '../Box'

interface DividerStylesParams {
    color?: string
    direction?: 'horizontal' | 'vertical'
}

const useStyles = createStyles((params: DividerStylesParams) => {
    return {
        root:
            params.direction === 'vertical'
                ? css({
                      border: 'none',
                      borderWidth: 0,
                      borderLeftWidth: 1,
                      borderColor: params.color ?? '$colorGray200',
                      borderStyle: 'solid'
                  })
                : css({
                      border: 'none',
                      borderWidth: 0,
                      borderTopWidth: 1,
                      borderColor: params.color ?? '$colorGray200',
                      borderStyle: 'solid'
                  })
    }
})

export interface DividerProps
    extends StyleComponentProps<Selectors<typeof useStyles>>,
        React.ComponentPropsWithoutRef<'div'>,
        DividerStylesParams {}

export const Divider = forwardRef<HTMLDivElement, DividerProps>((props, ref) => {
    const { color, direction, classNames, styles, className, unstyled, ...rest } = props
    const { classes } = useStyles({ color, direction }, { name: 'divider', classNames, styles, unstyled })

    return <Box ref={ref} className={clsx(classes.root, className)} {...rest} />
})
