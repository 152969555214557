import type { PanelMode } from '@byecode/ui';
import { Calendar, IconFont, Input } from '@byecode/ui'
import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import { isValid, lightFormat, parse } from 'date-fns'
import React, { forwardRef, startTransition, useCallback, useEffect, useState } from 'react'

export interface DataSourceDatePickerProps {
    value?: Date
    format?: string
    clearable?: boolean
    showTime?: boolean
    mode?: PanelMode
    onChange?: (val: Date | undefined) => void
    inputRender?: (props: React.ComponentPropsWithRef<'input'> & { hoverValue?: string }) => React.ReactNode
}

export const DataSourceDatePicker = forwardRef<HTMLInputElement, DataSourceDatePickerProps>((props, ref) => {
    const { value, format = 'yyyy-MM-dd HH:mm:ss', clearable, showTime = true, mode, onChange, inputRender } = props
    // 各种事件变化中的预览日期
    const [internalDate, setInternalDate] = useState<Date | undefined>(value || new Date())
    // input预览值跟随鼠标移入日期格子变化
    const [hoverValue, setHoverValue] = useState('')
    // 输入框中预览的日期
    const [inputValue, setInputValue] = useState(value ? lightFormat(value, format) : '')

    useEffect(() => {
        if (value) {
            setInternalDate(value)
        }
    }, [format, value, setInternalDate])

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        setInputValue(value)

        if (!value) {
            setInternalDate(undefined)
            return
        }

        if (value.length !== format.length) {
            return
        }
        const newDate = parse(value, format, new Date())
        if (isValid(newDate)) {
            setInternalDate(newDate)
        }
    }

    useEffect(() => {
        setInputValue(internalDate ? lightFormat(internalDate, format) : '')
    }, [format, internalDate])

    const onCellMouseLeave = useCallback((date: Date) => {
        startTransition(() => setHoverValue(''))
    }, [])

    const onCellMouseEnter = useCallback(
        (date: Date) => {
            setHoverValue(lightFormat(date, format))
        },
        [format]
    )

    const onInternalConfirm = useCallback(
        (date: Date | undefined) => {
            // setSelectedValue(date)
            onChange?.(date)
        },
        [onChange]
    )

    const handleClear = useCallback(() => {
        onChange?.(undefined)
    }, [onChange])
    return (
        <>
            {inputRender ? (
                inputRender({ hoverValue, value: hoverValue || inputValue, onChange: onInputChange })
            ) : (
                <Input
                    prefix={<IconFont type="PropertyCalendar" />}
                    styles={{
                        input: {
                            // @todo input组件替换bui样式系统后，需要替换该css变量为bui样式系统token: $colorGray400
                            color: hoverValue ? 'var(--color-gray-400)' : undefined
                        }
                    }}
                    value={hoverValue || inputValue}
                    onChange={onInputChange}
                    onClear={handleClear}
                    clearable={clearable && !!value}
                />
            )}

            <Calendar
                showTime={showTime}
                date={internalDate}
                mode={mode}
                onDateChange={setInternalDate}
                onCellMouseEnter={onCellMouseEnter}
                onCellMouseLeave={onCellMouseLeave}
                onConfirm={onInternalConfirm}
                // {...calendarProps}
            />
        </>
    )
})
