import type { ApplicationSettingAuthenticationField, FieldInputValue } from '@lighthouse/core'

export type AppLoginMode =
    | 'mobileLogin'
    | 'register'
    | 'home'
    | 'emailLogin'
    | 'weChatLogin'
    | 'bindMobile'
    | 'perfect'
    | 'bindEmail'
    | 'bindWechat'
export interface AppModeProperty {
    title: string
    catLeft: number
}

export type PerfectFieldItem = { value: FieldInputValue } & ApplicationSettingAuthenticationField

export enum LoginAuthType {
    mobile = 1,
    email,
    wechat,
    pc_wechat,
    google,
    follow_wechat = 7
}

export interface AccountRegisterForm {
    mobile: string
    email: string
    code: string
    authType: LoginAuthType
}

export interface AccountLoginForm {
    mobile: string
    email: string
    code: string
    mark: string
    groupId?: string
    fields: PerfectFieldItem[]
    authType: LoginAuthType
}

export interface AccountBindMobileForm {
    mobile: string
    code: string
}

export interface AccountBindAccountForm {
    account?: string
    code: string
    type?: LoginAuthType
}

export interface AccountMergeAccount {
    isLoggedInAccount: boolean
    mobile: string
}
export type SendCodeType = 'email' | 'mobile'

export interface AccountPerfectForm {
    groupId?: string
    fields: PerfectFieldItem[]
}

export enum BindMobileResultStatus {
    'SUCCESS' = 'SUCCESS',
    'PHONE_BOUND' = 'PHONE_BOUND',
    'COMBINED_ACCOUNT' = 'COMBINED_ACCOUNT'
}

export type BindMobileResult = {
    status: BindMobileResultStatus
    name: string
    uniqueUserId: string
    userId: string
}
