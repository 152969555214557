import { Empty } from '@byecode/ui'
import type { ButtonAction, ImageBlockAbstract, ImageSwiperItem } from '@lighthouse/core'
import { FilePreviewer, RATIO_OPTIONS, useActionRunningLoadings } from '@lighthouse/shared'
import { useElementSize, useSetState } from '@mantine/hooks'
import { useUpdateEffect } from '@react-hookz/web'
import cls from 'classnames'
import Carousel from 'nuka-carousel'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import FileItem from './FileItem'
import type { DragState } from './GuideLine'
import GuideLine from './GuideLine'
import * as SC from './styles'

interface ImageBlockProps {
    blockData: ImageBlockAbstract
    disabled?: boolean
    isDefault?: boolean
    value: ImageSwiperItem[]
    onClick?: (link: string) => void
    onActionTrigger?: (params: ButtonAction) => Promise<boolean | undefined>
    onBlockChange?: (values: ImageBlockAbstract, origin: ImageBlockAbstract) => void
}

interface State {
    deltaX: number
    eleWidth: string
    slideIndex: number
    opened: boolean
    previewFileIndex: number
    isHover: boolean
    isToggle: boolean
}
export const minImageContentWidth = 10

export const deleteUnitToNumber = (param: string): number => {
    return Number(param.replace('px', '')) || 0
}

const ImageBlock: React.FC<ImageBlockProps> = ({
    blockData,
    disabled = false,
    isDefault = false,
    value,
    onClick,
    onActionTrigger,
    onBlockChange
}) => {
    const { config, id } = blockData
    const {
        variant,
        sources = [],
        fitType,
        align,
        radius,
        ratio,
        width = '100%',
        shape,
        isPreview = true,
        sourceType = 'default',
        imageClickEvent = 'none',
        imageSource,
        action,
        showTitle,
        swipeSpeed = 2,
        autoLoop = false
    } = config

    const [{ deltaX, eleWidth, slideIndex, opened, previewFileIndex, isHover, isToggle }, setState] = useSetState<State>({
        deltaX: 0,
        eleWidth: width.replace('px', ''),
        opened: false,
        previewFileIndex: 0,
        slideIndex: 0,
        isHover: false,
        isToggle: false
    })

    const { loadings, handleActionTriggerWithLoading } = useActionRunningLoadings()
    const { ref: containRef, width: containerWidth } = useElementSize()
    const swiperRef = useRef<HTMLDivElement>(null)

    const alginItem = find(item => item.value === ratio, RATIO_OPTIONS)

    const computeWidth = useMemo(() => {
        if (eleWidth === '100%') {
            return containerWidth
        }
        const dragWidth = deleteUnitToNumber(eleWidth) + deltaX
        // 最大值设限
        if (dragWidth >= containerWidth) {
            return containerWidth
        }
        // 最小值设限
        if (dragWidth <= minImageContentWidth) {
            return minImageContentWidth
        }
        return dragWidth
    }, [containerWidth, deltaX, eleWidth])

    const isFill = useMemo(() => containerWidth === computeWidth, [computeWidth, containerWidth])

    const currentHeight = useMemo(() => {
        if (alginItem?.value === '0') {
            return 'auto'
        }
        return computeWidth * (alginItem?.label?.split(':').reduce((preVal, curVal) => Number(curVal) / preVal, 1) ?? 1)
    }, [alginItem?.label, alginItem?.value, computeWidth])

    const isDrag = !disabled && value.length > 0

    const isEmpty = useMemo(() => value.length === 0 && isDefault, [isDefault, value.length])

    const alignStyles = useMemo(() => {
        switch (align) {
            case 'left': {
                return { marginRight: 'auto' }
            }
            case 'right': {
                return { marginLeft: 'auto' }
            }
            default: {
                return { margin: '0 auto' }
            }
        }
    }, [align])

    const handleDragStart = useCallback(() => {
        // 拖拽时需要计算位移， 因此转换为px单位进行计算
        setState({ eleWidth: isFill ? `${containerWidth}px` : eleWidth })
    }, [containerWidth, eleWidth, isFill, setState])

    const handleDragMove = useCallback(
        (state: DragState) => {
            const { delta } = state
            const isCenter = align === 'center'
            const newDeltaX = isCenter ? delta.x * 2 : delta.x
            const contentWidth = computeWidth
            if ((contentWidth >= containerWidth && newDeltaX > deltaX) || (contentWidth <= minImageContentWidth && newDeltaX < deltaX)) {
                return
            }
            setState({ deltaX: newDeltaX })
        },
        [align, computeWidth, containerWidth, deltaX, setState]
    )

    const handleDragEnd = useCallback(
        (state: DragState) => {
            setState({ eleWidth: isFill ? '100%' : computeWidth.toString(), deltaX: 0 })
            onBlockChange?.({ ...blockData, config: { ...config, width: isFill ? '100%' : computeWidth.toString() } }, blockData)
        },
        [blockData, computeWidth, config, isFill, onBlockChange, setState]
    )

    const handImageClick = useCallback(
        (index: number) => {
            switch (imageClickEvent) {
                case 'preview': {
                    setState({ opened: isPreview, previewFileIndex: index })
                    break
                }
                case 'custom': {
                    if (loadings[`${index}`]) {
                        return
                    }
                    handleActionTriggerWithLoading({
                        action,
                        id,
                        type: 'click',
                        trigger: onActionTrigger
                    })
                    // onActionTrigger?.(action)
                    break
                }
                case 'jump': {
                    onClick?.(value[slideIndex]?.link ?? '')
                    break
                }
                case 'none': {
                    break
                }
                default: {
                    break
                }
            }
        },
        [
            imageClickEvent,
            setState,
            isPreview,
            loadings,
            handleActionTriggerWithLoading,
            action,
            id,
            onActionTrigger,
            onClick,
            value,
            slideIndex
        ]
    )

    const imageExtraStyles = useMemo<React.CSSProperties>(() => {
        if (loadings[id]) {
            return { cursor: 'not-allowed' }
        }
        return {}
    }, [id, loadings])

    useUpdateEffect(() => {
        setState({ slideIndex: 0 })
    }, [eleWidth, ratio, currentHeight])

    const imageContent = useMemo(
        () =>
            variant === 'swiper' ? (
                <Carousel
                    style={{ width: eleWidth, height: currentHeight /* , touchAction: 'pan-x' */ }}
                    autoplayInterval={swipeSpeed.toString() === '' ? undefined : Number(swipeSpeed) * 1000}
                    ref={swiperRef}
                    speed={600}
                    autoplay={autoLoop && !isHover}
                    wrapAround
                    pauseOnHover
                    zoomScale={1}
                    tabbed
                    beforeSlide={(currentIndex, endIndex) => {
                        setState({ slideIndex: endIndex, isToggle: true })
                    }}
                    defaultControlsConfig={{
                        nextButtonText: <SC.Icon type="ArrowRightSmall" color="#fff" />,
                        prevButtonText: <SC.Icon type="ArrowLeftSmall" color="#fff" />,
                        pagingDotsClassName: 'dot',
                        pagingDotsContainerClassName: 'dotContainer'
                    }}
                >
                    {value?.map((item, index) => (
                        <FileItem
                            {...item}
                            src={item.url}
                            objectFit={fitType}
                            className={cls({ fileSlide: true, active: slideIndex === index, animation: isToggle })}
                            data-stop-action-propagation={
                                imageClickEvent === 'none' || (imageClickEvent === 'custom' && action.type === 'none') ? undefined : true
                            }
                            onClick={() => handImageClick(index)}
                            alt={item.title}
                            key={item.url}
                        />
                    ))}
                </Carousel>
            ) : (
                <FileItem
                    {...value?.[0]}
                    src={value?.[0]?.url}
                    aspectRatio={alginItem?.value === '0' ? 'auto' : alginItem?.label.replace(':', '/') ?? 'auto'}
                    objectFit={fitType}
                    data-stop-action-propagation={
                        imageClickEvent === 'none' || (imageClickEvent === 'custom' && action.type === 'none') ? undefined : true
                    }
                    onClick={() => handImageClick(0)}
                />
            ),
        [
            variant,
            eleWidth,
            currentHeight,
            swipeSpeed,
            autoLoop,
            isHover,
            value,
            alginItem?.value,
            alginItem?.label,
            fitType,
            imageClickEvent,
            action.type,
            setState,
            slideIndex,
            isToggle,
            handImageClick
        ]
    )

    return (
        <SC.ImageContainer data-block-id={id} ref={containRef}>
            {isEmpty ? (
                <Empty
                    styles={{
                        root: {
                            width: '100%',
                            // height: containerWidth / 4,
                            aspectRatio: '4 / 1',
                            backgroundColor: 'var(--color-gray-50)',
                            border: '1px solid var(--color-gray-200)',
                            borderRadius: 'inherit'
                        }
                    }}
                    icon="BlockImage"
                    description=""
                />
            ) : (
                value.length > 0 && (
                    <SC.ImageContent
                        borderRadius={shape === 'round' ? '50%' : `${radius?.toString()?.replaceAll('px', '') || 0}px`}
                        style={{ ...alignStyles, ...imageExtraStyles, width: computeWidth }}
                    >
                        {align !== 'left' && isDrag && (
                            <GuideLine
                                position="left"
                                key="leftGuide"
                                onDragStart={handleDragStart}
                                onDragMove={handleDragMove}
                                onDragEnd={handleDragEnd}
                            />
                        )}
                        <SC.ImageWrapper
                            onMouseEnter={() => setState({ isHover: true })}
                            onMouseLeave={() => setState({ isHover: false })}
                            onTouchStart={() => setState({ isHover: true })}
                            onTouchEnd={() => setState({ isHover: false })}
                        >
                            {imageContent}
                        </SC.ImageWrapper>
                        {align !== 'right' && isDrag && (
                            <GuideLine
                                position="right"
                                key="rightGuide"
                                onDragStart={handleDragStart}
                                onDragMove={handleDragMove}
                                onDragEnd={handleDragEnd}
                            />
                        )}
                        {/* {fileList?.[slideIndex]?.title && showTitle && <SC.ImageTitle>{fileList[slideIndex]?.title}</SC.ImageTitle>} */}
                    </SC.ImageContent>
                )
            )}
            <FilePreviewer opened={opened} defaultIndex={previewFileIndex} onClose={() => setState({ opened: false })} fileList={value} />
        </SC.ImageContainer>
    )
}

export default ImageBlock
