import type { FieldInputType, FieldType } from '@lighthouse/core'

export const silentInputTypes = new Set<FieldInputType>(['file', 'checkbox', 'slider'])

// TODO: 这里可以直接使用innerType 来做处理 备注者: 黄柯霖
export const fieldSettingMap: Record<FieldInputType, FieldType[]> = {
    text: ['text', 'url', 'email', 'phoneNumber', 'id'],
    url: ['text', 'url', 'email', 'phoneNumber', 'id'],
    email: ['text', 'url', 'email', 'phoneNumber', 'id'],
    phoneNumber: ['text', 'url', 'email', 'phoneNumber', 'id'],
    // select: ['select'],
    relativeSelect: ['text', 'url', 'email', 'phoneNumber', 'select', 'number'],
    number: ['number'],
    date: ['date'],
    file: ['file', 'video'],
    notes: ['notes'],
    person: ['person'],
    checkbox: ['checkbox'],
    slider: ['number'],
    cascade: ['text', 'url', 'email', 'phoneNumber', 'id']
}

// TODO: 这里可以直接使用innerType 来做处理 备注者: 黄柯霖
export const formSettingMap: Record<FieldInputType, FieldType[]> = {
    text: ['text', 'url', 'email', 'phoneNumber'],
    url: ['text', 'url', 'email', 'phoneNumber'],
    email: ['text', 'url', 'email', 'phoneNumber'],
    phoneNumber: ['text', 'url', 'email', 'phoneNumber'],
    // select: ['select'],
    relativeSelect: ['text', 'url', 'email', 'phoneNumber', 'select', 'number'],
    number: ['number'],
    date: ['date'],
    file: ['file', 'video'],
    notes: ['notes'],
    person: ['person'],
    checkbox: ['checkbox'],
    slider: ['number'],
    cascade: ['text', 'url', 'email', 'phoneNumber']
}

export const relativeSelectShowFieldList: FieldType[] = [
    'text',
    'url',
    'email',
    'phoneNumber',
    'date',
    'person',
    'id',
    'number',
    'aggregation',
    'formula',
    'textGeneration'
]

export const PURE_TEXT_INPUT: FieldInputType[] = ['text', 'email', 'phoneNumber', 'url']

export const NO_FIELD_INPUT: FieldInputType[] = [
    'text',
    'email',
    'phoneNumber',
    'url',
    'slider',
    'checkbox',
    'date',
    'notes',
    'person',
    'number'
]

export const RELATIVE_SELECT_FIELD_LIST: FieldType[] = ['id', 'text', 'url', 'phoneNumber', 'email', 'notes']

export const mathUserFieldList: FieldType[] = ['text', 'url', 'email', 'phoneNumber', 'notes', 'id', 'number']

export const multipleFieldInputList: FieldInputType[] = ['person']

export const fieldInputTypeList = new Set<FieldInputType>([
    'date',
    'email',
    'file',
    'notes',
    'number',
    'person',
    'phoneNumber',
    'relativeSelect',
    'text',
    // 'select',
    'url',
    'checkbox'
])

export const FieldTypeToInputTypeMap: Record<FieldType, FieldInputType | null> = {
    text: 'text',
    number: 'number',
    email: 'email',
    url: 'url',
    person: 'person',
    phoneNumber: 'phoneNumber',
    date: 'date',
    select: 'relativeSelect',
    file: 'file',
    notes: 'notes',
    checkbox: 'checkbox',
    aggregation: null,
    booleanGenerationByText: null,
    id: null,
    video: null,
    formula: null,
    userGroup: null,
    userDepartment: null,
    textGeneration: null,
    textGenerationByAudio: null,
    textGenerationByImage: null,
    selectGenerationByText: null,
    dateGenerationByText: null,
    numberGenerationByText: null,
    textGenerationByFile: null
}
