import { backgroundTransitionOnClick, IconFont } from '@byecode/ui'
import type { VariableADTvalue } from '@lighthouse/core'
import { Text } from '@mantine/core'
import React from 'react'
import styled from 'styled-components'

import { FieldTypeTag } from '../../../FieldTypeTag'
import type { VariableChildren } from '../../types'

interface ListItemProps {
    data: VariableChildren
    active?: boolean
    showArrowIcon?: boolean
    value?: VariableADTvalue
    style?: React.CSSProperties
    onChange?: (value: VariableChildren) => void
}

const SCxListItem = styled.div`
    display: flex;
    align-items: center;
    padding: 7px 16px;
    gap: 8px;
    max-height: 50px;
    min-height: 36px;
    ${backgroundTransitionOnClick}
`

const SCxInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
`

const SCxRightFill = styled.div`
    min-width: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 4px;
`
const SCxLeftFill = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    flex: 1;
`

export const ListItem: React.FunctionComponent<ListItemProps> = ({ data, active, showArrowIcon = true, style, onChange }) => {
    return (
        <SCxListItem onClick={() => onChange?.(data)} style={style} onMouseLeave={data.onMouseLeave} onMouseEnter={data.onMouseEnter}>
            <SCxLeftFill>
                {data.icon && <IconFont size={16} type={data.icon} fill="var(--color-gray-400)" />}
                <SCxInfo>
                    <Text style={{ lineHeight: '16px', wordBreak: 'break-all' }} lineClamp={1} variant="text" size={14}>
                        {data.title}
                    </Text>
                    {data.description && (
                        <Text color="var(--color-gray-600)" style={{ lineHeight: '16px' }} size={12} lineClamp={1}>
                            {data.description}
                        </Text>
                    )}
                </SCxInfo>
            </SCxLeftFill>
            <SCxRightFill>
                {active ? (
                    <IconFont size={16} type="Tick" color="var(--color-purple-500)" />
                ) : (
                    <FieldTypeTag type={data.fieldType} innerType={data.fieldInnerType} />
                )}
                {showArrowIcon && <IconFont color="Var(--color-gray-400)" size={16} type="ArrowRightSmall" />}
            </SCxRightFill>
        </SCxListItem>
    )
}
