import type { SpaceUser, UserGroup, UserProtocol } from '@lighthouse/core'

export interface AppUserGroup extends UserGroup {
    visiblePageCount: number
}

export enum ScanStatus {
    NOT_SCAN = 0,
    LOGIN = 1,
    REGISTER = 2,
    QR_EXPIRED = 3
}

export interface AddUsersPayload {
    teamIds: string[]
    userIds: string[]
}

export interface AppUserDepartment {
    departmentId: string
    departmentName: string
    departmentChild?: AppUserDepartment[]
    departmentManager: string
    departParentId?: string
}

export interface TeamBase {
    teamId: string
    name: string
    parentId?: string
    avatar?: string
}

export interface Team extends TeamBase {
    teamUsers: SpaceUser[]
    spaceId: string
}

export interface TeamTree extends Team {
    children?: TeamTree[]
}

export interface LoginUser {
    accessToken: string
    refreshToken: string
}
