import { Enum } from '@lighthouse/tools'

import type { Direction, Fit, FlexAlign, HeightRule, MaxContainerWidthSize, VariableADTvalue } from '../protocols'

/** 背景类型 */
export const BACKGROUND_TYPE = Enum('color', 'gradient', 'image')
export type BackgroundType = Enum<typeof BACKGROUND_TYPE>

export const GRADIENT_MODE = Enum('linear', 'radial', 'conic')
export type GradientMode = Enum<typeof GRADIENT_MODE>
/** 渐变节点 */
export type GradientStop = {
    color: string
    percentage: number
}
/** 渐变色协议 */
export interface GradientProtocol {
    mode: GradientMode
    angle: number
    width?: number
    height?: number
    stops: GradientStop[]
}

export interface BackgroundColorProtocol {
    color?: string
}
export interface BackgroundGradientProtocol {
    gradient?: GradientProtocol
}
export interface BackgroundImageProtocol {
    image?: {
        resource: VariableADTvalue
        fit: Fit
    }
}
export interface BackgroundProtocol extends BackgroundColorProtocol, BackgroundGradientProtocol, BackgroundImageProtocol {
    type: BackgroundType
}

export interface BackgroundNoImageProtocol extends BackgroundColorProtocol, BackgroundGradientProtocol {
    type: Exclude<BackgroundType, 'image'>
}

// export type BackgroundProtocol = MakeADT<
//     'type',
//     {
//         [BACKGROUND_TYPE.color]: BackgroundColorProtocol
//         [BACKGROUND_TYPE.gradient]: BackgroundGradientProtocol
//         [BACKGROUND_TYPE.image]: BackgroundImageProtocol
//     }
// >

// 四个边/角的设置
export type EdgeValue = [number | undefined, number | undefined, number | undefined, number | undefined]
export const EDGE_MODE = Enum('all', 'each')
export type EdgeMode = Enum<typeof EDGE_MODE>
export type EdgeConfig = {
    mode?: EdgeMode
    all?: number
    each?: EdgeValue
}

/** 纹理 */
export type Veins = {
    value?: string
    color?: string
    strokeWidth?: number
    size?: number
}

/** 边框 */
export type Border = BackgroundNoImageProtocol & EdgeConfig

/** 阴影 */
export type Shadow = {
    color?: string
    x?: number
    y?: number
    blur?: number
    diffusion?: number
}

/** 宽度策略 */
// export const SIZE_STRATEGY = Enum('fill', 'custom')
// export type SizeStrategy = Enum<typeof SIZE_STRATEGY>

export interface DesignLayout {
    // sizeStrategy?: SizeStrategy

    size?: MaxContainerWidthSize

    heightRule?: HeightRule

    crop?: boolean

    direction?: Direction

    mobileAdaptive?: boolean

    padding?: EdgeValue

    gap?: number

    alignX?: FlexAlign
    alignY?: FlexAlign
}

export interface DesignStyle {
    radius?: EdgeValue

    background?: BackgroundProtocol

    veins?: Veins

    border?: Border

    shadow?: Shadow
}

/** 设计属性 */
export type DesignProtocol = DesignLayout & DesignStyle
