import type { DrawerProps } from '@mantine/core'

import type { FileItemAction } from '../../types'
import type { UploadCapture } from '../UploadManage'
import type { FileOperationOption } from './types'

export const SELECT_FILE_UPLOAD_OPTIONS: {
    label: string
    capture?: UploadCapture
    accept?: string
}[] = [
    {
        label: '拍照',
        capture: 'environment',
        accept: 'image/*'
    },
    {
        label: '从手机相册选择',
        accept: 'image/*'
    },
    {
        label: '本地文件',
        accept: '*'
    }
]

export const SELECT_VIDEO_UPLOAD_OPTIONS: {
    label: string
    capture?: UploadCapture
    accept?: string
}[] = [
    {
        label: '录像',
        capture: 'environment',
        accept: 'video/*'
    },
    {
        label: '从手机相册选择',
        accept: 'video/*'
    },
    {
        label: '本地文件',
        accept: '*'
    }
]

export const FileItemActionLabelMap: Record<
    FileItemAction,
    {
        label: string
        icon: string
    }
> = {
    download: {
        label: '下载',
        icon: 'ArrowLineDown'
    },
    preview: {
        label: '查看',
        icon: 'NoHide'
    },
    rename: {
        label: '重命名',
        icon: ''
    },
    remove: {
        label: '删除',
        icon: 'Trash'
    }
}

export const FILE_OPERATION_OPTIONS: FileOperationOption[] = [
    {
        label: FileItemActionLabelMap['download'].label,
        value: 'download'
    },
    {
        label: FileItemActionLabelMap['preview'].label,
        value: 'preview'
    },
    {
        label: FileItemActionLabelMap['rename'].label,
        value: 'rename'
    },
    {
        label: FileItemActionLabelMap['remove'].label,
        value: 'remove'
    }
]

export const drawerConfig: Pick<DrawerProps, 'zIndex' | 'styles' | 'position' | 'withinPortal'> = {
    zIndex: 200,
    styles: {
        header: {
            display: 'none'
        },
        drawer: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            overflow: 'hidden',
            height: 'auto'
        },
        body: {
            backgroundColor: 'var(--color-gray-50)'
        }
    },
    position: 'bottom'
    // withinPortal: true
}
