import type { AnyObject } from '@byecode/ui/types'

import { errorCodeMap } from '../constants'
import type { I18TranslationKey } from '../types'

export const zhCN: Record<I18TranslationKey, string> = {
    // 登录注册页面文本
    bindMobile: '绑定手机号',
    bindEmail: '绑定邮箱',
    firstLoginNeedAccount: '首次微信登录需绑定{{account}}',
    mobileCode: '手机号',
    noVerificationCodeReceived: '没有收到验证码？',
    noNeedLogin: '暂不支持登录',
    registerAccount: '注册账号',
    mobileLogin: '手机号登录',
    emailLogin: '邮箱登录',
    wechatLogin: '微信登录',
    emailUrl: '邮箱地址',
    noAccount: '还没有账号？',
    next: '下一步',
    goLogin: '去登录',
    alreadyHaveAnAccount: '已有账号？',
    selectRegisterWay: '选择注册方式',
    perfectInfo: '完善信息',
    verificationCode: '验证码',
    sure: '确定',
    yourRole: '您的角色',
    emailFormatIsIllegal: '邮箱格式不合法',
    mobilePhoneNumberFormatIsIllegal: '手机号格式不合法',
    mobileRegister: '手机号注册',
    emailRegister: '邮箱注册',
    email: '邮箱',
    shortMessage: '短信',
    pleaseInputYourEmail: '请输入邮箱',
    pleaseInputYourMobile: '请输入手机号',
    pleaseInputYourValidEmail: '请输入正确的邮箱',
    pleaseInputYourValidMobile: '请输入正确的手机号',
    getVerificationCode: '获取验证码',
    getAgain: '再次获取',
    register: '注册',
    notEmpty: '不能为空',
    pleaseFillOut: '请填写',
    loginSuccess: '登录成功',
    pleaseWeChatLoginInWeChatBrowser: '请在微信浏览器中使用微信登录',
    refresh: '刷新',
    expiredPleaseRefresh: '已过期，刷新',
    scanWechatLogin: '使用微信扫码关注公众号即可登录',
    accountSetting: '账号设置',
    // 动态文本
    resendTime: `{{v}} s后重发`,
    verificationMessage: `我们已向 {{v}} 发送了验证码，请查看您的邮箱`,
    verificationSMS: `我们已向 {{v}} 发送了短信验证码`,
    inputVerificationCode: `输入{{v}}验证码`,
    confirmMergeAccounts: `确认合并账号？`,
    merge: '合并',
    mergeAccount: '合并账号',
    iAwareRisks: '我已知晓风险',
    cancel: '取消',
    backLoginPage: '返回登录页',
    youAccountUnderReview: '你的账号正在审核',
    keepMobileAndDeleteWechat: '保留手机号的账号 删除微信的账号',
    keepWechatAndDeleteMobile: ' 保留微信的账号 删除手机号的账号',
    MergeWeChatAccountDescription: '将微信和手机号注册的账号合并为一个账号请选择保留的账号：',
    changeBindMobile: '换绑手机号',
    deleteWechatAccountWarn: '此操作将删除微信的账号，且不可恢复，请谨慎操作！',
    mobileAlreadyRegister: '手机号已经注册',
    search: '搜索',
    personNameOrPersonId: '人员名称、人员编号',
    noFindData: '没有找到数据',
    noFindPerson: '没有找到人员',
    selected: '只看已选',
    goHome: '回到主页',
    mobileAlreadyRegisterDesc: '该手机号已被注册为：{{name}} ({{userId}})，若仍想绑定该手机号，请点击「合并账号」。',
    logout: '退出登录',
    notBind: '无法解绑',
    mobileNumber: '手机号',
    wechat: '微信',
    removeBindAccountDescribe: '{{account}} 作为本账号唯一登录方式，请先绑定其他账号作为替代登录方式。',
    knew: '知道了',
    continueUnbinding: '继续解绑',
    emailAccount: '邮箱账号',
    alreadyBind: '已绑定',
    wechatAccount: '微信账号',
    notEnabledAccountLogin: '未启用{{account}}登录功能',
    bindAfterUsedAccountLogin: '绑定后可使用{{account}}登录',
    bindAccountAfterAccountNotEnable: '该{{account}}已经注册过账号，请更换{{account}}若您仍想绑定，请先解除刚刚绑定失败的{{account}}的原绑定关系后再将其与当前登录的账号绑定',
    bindMeetQuestion: '绑定遇到问题',
    changeAccount: '更换{{account}}',
    goBind: '去绑定',
    unbind: '解绑',
    notEnable: '未启用',
    bindMobileNumber: '绑定手机号',
    bindMobileNumberAfterLoginApp: '绑定后可使用手机号登录应用',
    enterSmsCode: '输入短信验证码',
    confirmUnbinding: '确认解绑',
    unBindNotLoginConfirmDescribe: '解绑后无法再通过{{account}}途经登录，确认继续解绑？',
    alreadyUnbindAccount: '已解除{{account}}绑定',
    // date
    beforeYesterday: '前天',
    yesterday: '昨天',
    today: '今天',
    tomorrow: '明天',
    afterTomorrow: '后天',
    // toast
    ...errorCodeMap
}

// export const example: Record<I18TranslationKey, string | Function> = {
//     "bindMobile": "",
//     "firstLoginNeedMobile": "",
//     "mobileCode": "",
//     "noVerificationCodeReceived": "",
//     "noNeedLogin": "",
//     "registerAccount": "",
//     "mobileLogin": "",
//     "emailLogin": "",
//     "wechatLogin": "",
//     "emailUrl": "",
//     "noAccount": "",
//     "next": "",
//     "goLogin": "",
//     "alreadyHaveAnAccount": "",
//     "selectRegisterWay": "",
//     "perfectInfo": "",
//     "verificationCode": "",
//     "sure": "",
//     "yourRole": "",
//     "emailFormatIsIllegal": "",
//     "mobilePhoneNumberFormatIsIllegal": "",
//     "mobileRegister": "",
//     "verificationMessage": "",
//     "verificationSMS": "",
//     "inputVerificationCode": "",
//     "emailRegister": "",
//     "email": "",
//     "shortMessage": "",
//     "pleaseInputYourEmail": "",
//     "pleaseInputYourMobile": "",
//     "pleaseInputYourValidEmail": "",
//     "pleaseInputYourValidMobile": "",
//     "getVerificationCode": "",
//     "resendTime": "",
//     "getAgain": "",
//     "0000000": "",
//     "0011004": "",
//     "0011005": "",
//     "0011010": "",
//     "0011013": "",
//     "0011023": "",
//     "0011024": "",
//     "0011025": "",
//     "0011027": "",
//     "0011028": "",
//     "0011030": "",
//     "0011031": "",
//     "0011032": "",
//     "0011033": "",
//     "0011034": "",
//     "0011035": "",
//     "0011036": "",
//     "0011037": "",
//     "0011039": "",
//     "0011042": "",
//     "0011043": "",
//     "0011044": "",
//     "0011045": "",
//     "0020000": "",
//     "0021002": "",
//     "0021003": "",
//     "0021004": "",
//     "0021005": "",
//     "0021006": "",
//     "0021007": "",
//     "0021008": "",
//     "0021009": "",
//     "0021011": "",
//     "0021012": "",
//     "0021013": "",
//     "0021014": "",
//     "0021017": "",
//     "0021018": "",
//     "0021019": "",
//     "0021020": "",
//     "0021021": "",
//     "0021029": "",
//     "0021038": "",
//     "0031000": "",
//     "0120001": "",
//     "0311002": "",
//     "0311004": "",
//     "0311005": "",
//     "0321001": "",
//     "0321003": "",
//     "0321004": "",
//     "0321006": "",
//     register: '注册',
//     notEmpty: '不能为空',
//     pleaseFillOut: '请填写'
// }
