import type { PageAbstract } from '@lighthouse/core'
import { RecordOpenType } from '@lighthouse/core'
import {
    CUSTOM_ROUTE_PAGE_URL_REG,
    getOpenTypeByUrlName,
    PAGE_STACK_URL_KEY_MAP,
    PAGE_URL_REG,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { Action } from '@remix-run/router'
import { mergeDeepRight, omit } from 'rambda'
import { useCallback, useEffect, useRef } from 'react'
import { useLocation, useNavigationType } from 'react-router-dom'
import { useLatest } from 'react-use'

import { pageStackAtom } from '@/atoms/page/state'
import type { PageMetaData } from '@/atoms/page/type'

import { useCurrentAppId } from './useApplication'

/**
 * 根据url解析page meta
 * @param url url params
 * @param rootPageId 根页面id
 * @returns {PageMetaData}
 */
export function parseUrlParams(url: string, appId: string, rootPageId?: string): PageMetaData {
    const list = url.split('/')
    const [pageKey, pageId] = list
    const entries: [keyof PageMetaData, unknown][] = [
        ['appId', appId],
        ['rootPageId', rootPageId ?? pageId],
        ['stackId', nanoid()],
        ['stackDisplayType', getOpenTypeByUrlName(pageKey)],
        ['blockRuntimeState', {}]
    ]
    for (let index = 0; index < list.length; index += 2) {
        const k = PAGE_STACK_URL_KEY_MAP[list[index]]
        const v = list[index + 1]
        if (!v) {
            continue
        }
        entries.push([k, v])
    }
    return Object.fromEntries(entries) as unknown as PageMetaData
}

/**
 * 根据url获取当前的stack
 * @param path url路径
 * @returns
 */
function getLastStackFromUrl(path: string, appId: string, pageList: PageAbstract[]) {
    const urlParams = path.slice(1).match(PAGE_URL_REG)
    if (!urlParams) {
        return
    }
    const first = urlParams[0]
    const last = urlParams[urlParams.length - 1]
    const isSingle = urlParams.length === 1

    const firstUrl = CUSTOM_ROUTE_PAGE_URL_REG.test(first) ? getCustomRoutePageUrl(first.split('/')[0], pageList) : first
    const rootPageId = parseUrlParams(firstUrl, appId).pageId

    const lastUrl = isSingle && CUSTOM_ROUTE_PAGE_URL_REG.test(last) ? getCustomRoutePageUrl(last.split('/')[0], pageList) : last
    return parseUrlParams(lastUrl, rootPageId)
}

export function getCustomRoutePageUrl(route: string, pageList: PageAbstract[]) {
    const page = pageList.find(item => item.route === route)
    if (!page) {
        return ''
    }

    return `P/${page.id}`
}

/** 订阅浏览器路由操作 */
export const useSubscriptRouteForPageStack = (pageList: PageAbstract[]) => {
    const routeStackKeysRef = useRef<string[]>([])
    const { run: setPageStack } = useAtomAction(pageStackAtom)
    const actionType = useNavigationType()
    const location = useLocation()
    const appId = useCurrentAppId()

    const isEmptyPageList = pageList.length === 0
    const latestRef = useLatest(pageList)

    const prevKey = useRef<string>('default')
    const prevPathname = useRef('')

    useEffect(() => {
        const pageList = latestRef.current
        if (!appId || isEmptyPageList /* || prevPathname.current === location.pathname */) {
            return
        }

        switch (actionType) {
            case Action.Push: {
                routeStackKeysRef.current.push(location.key)
                const lastStack = getLastStackFromUrl(location.pathname, appId, pageList)
                if (!lastStack) {
                    return
                }
                setPageStack(s => {
                    // 合并之前的一些临时状态： runtime state
                    const oldStack = s.findLast(item => item.pageId === lastStack.pageId)
                    oldStack && Reflect.deleteProperty(oldStack?.blockRuntimeState, 'formContainer')
                    s.push(mergeDeepRight(oldStack || {}, lastStack))
                })
                break
            }
            case Action.Pop: {
                // 如果已记录了该key，并且不是在当前的key，则说明是后退，否则是前进
                if (routeStackKeysRef.current.includes(location.key)) {
                    const prevIndex = routeStackKeysRef.current.indexOf(prevKey.current)
                    const nextIndex = routeStackKeysRef.current.indexOf(location.key)
                    if (prevIndex < nextIndex) {
                        const lastStack = getLastStackFromUrl(location.pathname, appId, pageList)
                        if (!lastStack) {
                            return
                        }
                        setPageStack(s => {
                            const oldStack = s.findLast(item => item.pageId === lastStack.pageId)
                            s.push(mergeDeepRight(oldStack || {}, lastStack))
                        })
                    } else {
                        setPageStack(s => void s.pop())
                    }
                } else {
                    // 初始进入时
                    routeStackKeysRef.current.push(location.key)
                    const path = location.pathname.slice(1)
                    const urlParams = path.match(PAGE_URL_REG)
                    if (!urlParams) {
                        return
                    }

                    // 获取路径上所有的stack
                    let rootPageId: string | undefined
                    const stacks = urlParams.map((item, index) => {
                        const isCustomRoute = index === 0 && CUSTOM_ROUTE_PAGE_URL_REG.test(item)
                        const data = isCustomRoute ? getCustomRoutePageUrl(item.split('/')[0], pageList) : item
                        const stack = parseUrlParams(data, appId, rootPageId)
                        if (index === 0) {
                            rootPageId = stack.pageId
                        }
                        return stack
                    })

                    setPageStack(s => {
                        // 过滤掉atom内已经有的stack
                        const filtered = stacks.filter((item, index, arr) => {
                            if (index === arr.length - 1) {
                                return true
                            }

                            return !s.some(r => r.pageId === item.pageId && r.rootPageId === item.rootPageId)
                        })
                        s.push(...filtered)
                    })
                }
                break
            }

            case Action.Replace: {
                routeStackKeysRef.current.push(location.key)
                const lastStack = getLastStackFromUrl(location.pathname, appId, pageList)
                if (!lastStack) {
                    return
                }
                setPageStack(s => {
                    const oldStack = s.find(item => item.pageId === lastStack.pageId)

                    s.splice(-1, 1, mergeDeepRight(oldStack || {}, lastStack))
                })
                break
            }
            default: {
                break
            }
        }
    }, [actionType, location.key, location.pathname, latestRef, setPageStack, appId, isEmptyPageList])

    useEffect(() => {
        prevPathname.current = location.pathname
    }, [location.pathname, actionType])

    useEffect(() => {
        prevKey.current = location.key
    }, [location.key])
}

/** 获取当前页面形式的pageId有多少个打开的layer stacks */
export const useGetInnerStacks = (pageId: string) => {
    // const { pathname } = useLocation()
    return useAtomData(
        pageStackAtom,
        useCallback(
            s => {
                const currentIndex = s.findLastIndex(item => item.stackDisplayType === RecordOpenType.page && item.pageId === pageId)
                return s.slice(currentIndex + 1)
            },
            [pageId]
        )
    )

    // return useMemo(() => {
    //     const regexArr = pathname.slice(1).match(PAGE_URL_REG)
    //     if (!regexArr) {
    //         return []
    //     }
    //     let rootPageId: string | undefined
    //     const _stacks = regexArr.map((item, index) => {
    //         const stack = parseUrlParams(item, rootPageId)
    //         if (index === 0) {
    //             rootPageId = stack.pageId
    //         }

    //         return stack
    //     })

    //     const currentIndex = _stacks.findIndex(item => item.pageId === currentPageTypePageId)
    //     if (currentIndex === -1) {
    //         return []
    //     }

    //     return _stacks
    //         .slice(currentIndex + 1)
    //         .map(item =>
    //             stacks.findLast(
    //                 i =>
    //                     i.pageId === item.pageId &&
    //                     i.rootPageId === item.rootPageId &&
    //                     i.recordId === item.recordId &&
    //                     i.stackDisplayType === item.stackDisplayType
    //             )
    //         )
    //         .filter(Boolean)
    // }, [currentPageTypePageId, pathname, stacks])
}
