import type { DataSourceAbstract, FieldInputADTValue, InputValueItem } from '@lighthouse/core'
import { pick } from 'rambda'
import React, { createContext, useContext, useMemo } from 'react'

import type { FieldInputErrors } from '../components'

type SubFormRecord = {
    id: string
    content: Record<string, InputValueItem>
}

export type FormModuleType = {
    defaultValue: {
        inputList: InputValueItem[]
        initInputList: InputValueItem[]
        subFormDataMap: Record<string, SubFormRecord[]>
    }
    disabled?: boolean
    pointer: string
    isValid?: boolean
    dataSource?: DataSourceAbstract
    children?: React.ReactNode
    errors?: FieldInputErrors
    type: 'form' | 'field'
    onChange?: (blockId: string, value: FieldInputADTValue) => void
    onCodeChange?: (blockId: string, code: string) => void
    onChangeSubForm?: (v: (v: Record<string, SubFormRecord[]>) => void) => void
}

const FormModuleContext = createContext<FormModuleType>({
    pointer: '',
    defaultValue: {
        inputList: [],
        initInputList: [],
        subFormDataMap: {}
    },
    type: 'field'
})

export const FormModuleProvider = ({ children, value }: { value: FormModuleType; children?: React.ReactNode }) => {
    return <FormModuleContext.Provider value={value}> {children}</FormModuleContext.Provider>
}

export const useFormModuleContext = () => {
    return useContext(FormModuleContext)
}

export const useFormModuleSubForm = (id: string) => {
    const v = useFormModuleContext()
    return useMemo<[SubFormRecord[], ((v: (v: Record<string, SubFormRecord[]>) => void) => void) | undefined]>(
        () => [v.defaultValue.subFormDataMap[id] ?? [], v.onChangeSubForm],
        [id, v.defaultValue.subFormDataMap, v.onChangeSubForm]
    )
}
