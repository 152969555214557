import type { AppUser, Field, HighLightConditions, PersonValue } from '@lighthouse/core'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { PERSON_ID, PERSON_MAP_NAME } from '../../constants'
import { NotExistPersonPreview, PersonItemPreviewer } from '../FieldValuePreviewer/PersonValuePreviewer'
import { CellHighlighter } from '../Highlighter'
import type { TooltipListItem } from '../TooltipList'
import { TooltipList } from '../TooltipList'

const PersonListWrapper = styled.div<{ isWrap: boolean; isPadding?: boolean }>`
    position: relative;
    max-width: 100%;
    display: flex;
    gap: 6px;
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'nowrap')};
    padding-right: ${({ isPadding }) => (isPadding ? '20px' : '0')};
    overflow: hidden;
`

interface PersonValuePreviewerProps {
    field: Field
    children?: React.ReactNode
    size?: 'default' | 'lg'
    data?: PersonValue
    isWrap?: boolean
    personOptions?: AppUser[]
    width?: number
    direction?: 'horizontal' | 'vertical'
    styleConditions?: HighLightConditions
    currentUserId?: string
}

export const PersonValuePreviewer: React.FC<PersonValuePreviewerProps> = ({
    field,
    data = [],
    isWrap = true,
    personOptions = [],
    width = 160,
    direction,
    size = 'default',
    styleConditions,
    currentUserId
}) => {
    const { id } = field
    const text = data.join(',')

    const list = useMemo(() => {
        return data.reduce<TooltipListItem[]>((list, id) => {
            if (PERSON_ID.has(id)) {
                list.push({ label: PERSON_MAP_NAME[id], value: id })
                return list
            }
            const user = find(person => person.userId === id, personOptions)
            if (user) {
                const username = user.isDepart ? `${user.username}(已注销)` : user.username
                list.push({
                    label: username,
                    value: user.userId
                })
            }
            return list
        }, [])
    }, [data, personOptions])
    const key = `${data.join('-')}-${width}`
    return (
        <>
            <CellHighlighter currentUserId={currentUserId} fieldId={id} type="person" value={text} conditions={styleConditions} />

            <TooltipList
                data={list.map((item, index) => ({ label: item.label, value: String(index) }))}
                width={width}
                key={key}
                render={visibleNum => {
                    const renderList = data.slice(0, visibleNum)
                    return (
                        <PersonListWrapper isWrap={isWrap} isPadding={visibleNum < data.length}>
                            {renderList.map((id, index) => {
                                if (PERSON_ID.has(id)) {
                                    return (
                                        <PersonItemPreviewer
                                            id={String(index)}
                                            isLast={index === renderList.length - 1}
                                            key={id}
                                            user={{ userId: id, uniqueUserId: id, username: PERSON_MAP_NAME[id], mobile: '' }}
                                        />
                                    )
                                }
                                const user = find(person => person.userId === id, personOptions)
                                if (!user) {
                                    return (
                                        <NotExistPersonPreview
                                            direction={direction}
                                            key={id}
                                            id={id}
                                            isLast={index === renderList.length - 1}
                                        />
                                    )
                                }
                                const userData = { ...user, username: user.isDepart ? `${user.username}(已注销)` : user.username }
                                return (
                                    <PersonItemPreviewer
                                        id={String(index)}
                                        direction={direction}
                                        isLast={index === renderList.length - 1}
                                        key={id}
                                        user={userData}
                                    />
                                )
                            })}
                        </PersonListWrapper>
                    )
                }}
            />
        </>
    )
}
