import { Flex, getCascadeFlatOption, noButtons, Text } from '@byecode/ui'
import type { FilterBlockValue, FilterOption, NumberRangeArray } from '@lighthouse/core'
import { FilterSelectWay, FilterWay } from '@lighthouse/core'
import { CascadeDrawer, CheckButton, isDateValue, TooltipText, useApplicationContext } from '@lighthouse/shared'
import { lightFormat } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { CUSTOM_VALUE, filterTitleMobileWidth } from '../constants'
import { useGetOption } from '../hook'
import type { FilterItemProps } from '../types'
import { DateDrawer } from './DateDrawer'
import { NumberDrawer } from './NumberDrawer'

interface TileFilterItemProps
    extends Pick<
        FilterItemProps,
        'labelWidth' | 'recordId' | 'config' | 'dataSourceList' | 'onChange' | 'onFetchTextOptions' | 'onFetchCascadeOptions'
    > {
    filterData: FilterBlockValue
}

const SCxContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    padding: 6px 0 6px 2px;
`

const SCxScrollArea = styled(Flex)`
    flex: 1;
    overflow: auto hidden;
    ${noButtons('0px')}
`

export const TileFilterItem: React.FunctionComponent<TileFilterItemProps> = ({
    config,
    labelWidth,
    dataSourceList,
    recordId,
    filterData,
    onChange,
    onFetchTextOptions,
    onFetchCascadeOptions
}) => {
    const { filterWay, id, title, selectWay } = config

    const isMultiple = selectWay === FilterSelectWay.multiSelect
    const [opened, setOpened] = useState(false)
    const [cascadeActiveId, setCascadeActiveId] = useState('')
    const { pageTarget } = useApplicationContext()
    const { value, customValue } = useMemo(() => filterData?.[id] ?? { value: [], customValue: [] }, [filterData, id])

    const { options } = useGetOption({ value, onFetchTextOptions, onFetchCascadeOptions, dataSourceList, recordId, config })

    const numberConfig = useMemo(() => {
        if (filterWay === FilterWay.numberFilter) {
            return config
        }
        return null
    }, [config, filterWay])

    const handleChange = useCallback(
        (option: FilterOption, isActive: boolean) => {
            if (option.children && option.children.length > 0) {
                setCascadeActiveId(option.value)
                return
            }
            if (option.value === CUSTOM_VALUE) {
                setOpened(true)
                return
            }
            if (isMultiple) {
                const newValue = isActive ? value.filter(v => v !== option.value) : [...value, option.value]
                onChange?.(id, { type: filterWay, value: newValue, customValue })
                return
            }
            const newValue = isActive ? [] : [option.value]
            onChange?.(id, { type: filterWay, value: newValue, customValue })
        },
        [customValue, filterWay, id, isMultiple, onChange, value]
    )

    const handleCustomChange = useCallback(
        (_: string[], customVal?: NumberRangeArray) => {
            // const isActive = value?.some(v => v === CUSTOM_VALUE)
            const newValue = customVal ? [...value, CUSTOM_VALUE] : value.filter(v => v !== CUSTOM_VALUE)
            onChange?.(id, { type: filterWay, value: newValue, customValue: customVal })
        },
        [filterWay, id, onChange, value]
    )

    const customLabel = useMemo(() => {
        if (!customValue || !value?.includes(CUSTOM_VALUE)) {
            return '自定义'
        }
        const [start, end] = customValue
        const isDateFilter = filterWay === FilterWay.dateFilter
        const isDateStartValue = isDateValue(start)
        const isDateEndValue = isDateValue(end)
        const startLabel = isDateFilter && isDateStartValue ? lightFormat(start, 'yyyy-MM-dd') : start ?? ''
        const endLabel = isDateFilter && isDateEndValue ? lightFormat(end, 'yyyy-MM-dd') : end ?? ''

        if (isDateStartValue && isDateEndValue) {
            return `${startLabel} ~ ${endLabel}`
        }
        if (isDateStartValue) {
            return `> ${startLabel}`
        }
        if (isDateEndValue) {
            return `< ${endLabel}`
        }
        return '自定义'
    }, [filterWay, customValue, value])

    const drawerEle = useMemo(() => {
        if (filterWay === FilterWay.numberFilter) {
            return (
                <NumberDrawer
                    opened={opened}
                    title={title}
                    target={pageTarget}
                    numberRangeMode={numberConfig?.numberRangeMode}
                    min={numberConfig?.numberRange?.[0]}
                    max={numberConfig?.numberRange?.[1]}
                    step={numberConfig?.step}
                    customValue={customValue}
                    onClose={() => setOpened(false)}
                    onFinish={handleCustomChange}
                />
            )
        }
        if (filterWay === FilterWay.dateFilter) {
            return (
                <DateDrawer
                    opened={opened}
                    title={title}
                    target={pageTarget}
                    customValue={customValue}
                    showCustom
                    onClose={() => setOpened(false)}
                    onFinish={handleCustomChange}
                />
            )
        }
        if (filterWay === FilterWay.cascadeFilter) {
            return (
                <CascadeDrawer
                    opened={Boolean(cascadeActiveId)}
                    value={value}
                    title={title}
                    target={pageTarget}
                    isLastLevel
                    options={options.find(option => option.value === cascadeActiveId)?.children}
                    showFooterBtn
                    onClose={() => setCascadeActiveId('')}
                    // 单独处理onChange
                    onFinish={v => {
                        onChange?.(id, {
                            type: filterWay,
                            value: v,
                            customValue
                        })
                    }}
                />
            )
        }
        return null
    }, [
        cascadeActiveId,
        customValue,
        filterWay,
        handleCustomChange,
        id,
        numberConfig?.numberRange,
        numberConfig?.numberRangeMode,
        numberConfig?.step,
        onChange,
        opened,
        options,
        pageTarget,
        title,
        value
    ])

    return (
        <SCxContainer>
            <TooltipText
                title={title}
                render={ref => {
                    return (
                        <Text
                            ref={ref}
                            style={{ width: labelWidth ?? filterTitleMobileWidth, flexShrink: 0 }}
                            lineHeight="24px"
                            size={14}
                            algin="left"
                        >
                            {title}
                        </Text>
                    )
                }}
            />
            <SCxScrollArea gap={7}>
                {options.map(option => {
                    const options = [option, ...getCascadeFlatOption(option, [])]
                    const isActive = value.some(v => options.some(option => option.value === v))
                    return (
                        <CheckButton
                            key={option.value}
                            label={option.value === CUSTOM_VALUE ? customLabel : option.label}
                            active={isActive}
                            isBackground
                            onClick={() => handleChange?.(option, isActive)}
                            isSuffixIcon={Boolean(option.children && option.children.length > 0)}
                        />
                    )
                })}
            </SCxScrollArea>
            {drawerEle}
        </SCxContainer>
    )
}
