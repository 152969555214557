import { Toast } from '@byecode/ui'
import { Button } from '@byecode/ui/components/Button'
import { Input } from '@byecode/ui/components/Input'
import type { ApplicationSettingAuthentication } from '@lighthouse/core'
import { Text } from '@mantine/core'
import { clone } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type { ApplicationPreviewEnum } from '../../../types'
import { Code } from '../Code'
import { LOGIN_AUTH_CODE_MAP } from '../constant'
import { useFormRuleMap, useSendCode } from '../hooks'
import LoginBox from '../LoginBox'
import * as CM from '../styles'
import { type AccountLoginForm, type AppLoginMode, LoginAuthType } from '../types'

const SCxInput = styled(Input)`
    &:focus-within {
        border-color: var(--color-app-main);
    }
`

interface LoginProps {
    type: 'email' | 'mobile'
    authentication: ApplicationSettingAuthentication
    disableEvent?: boolean
    language: string
    previewType?: ApplicationPreviewEnum
    onChangeLanguage: (v: string) => void
    onChangeMode: (v: AppLoginMode) => void
    onLogin?: (v: AccountLoginForm) => void
    onSendCode?: (v: 'email' | 'mobile', sendCodeInfo: string) => Promise<boolean> | undefined
}

export const Login: React.FunctionComponent<LoginProps> = ({
    type,
    authentication,
    disableEvent,
    language,
    previewType,
    onChangeLanguage,
    onChangeMode,
    onSendCode,
    onLogin
}) => {
    const {
        register: { allowRegister },
        login,
        name,
        logo,
        describe
    } = authentication
    const methods = useForm<AccountLoginForm>({
        mode: 'onChange',
        shouldFocusError: false,
        defaultValues: {
            email: '',
            code: '',
            mobile: '',
            authType: LOGIN_AUTH_CODE_MAP[type]
        }
    })
    const { t } = useTranslation()

    const formRuleMap = useFormRuleMap()

    const { register, handleSubmit, getValues, watch } = methods
    const info = useMemo(
        () =>
            ({
                email: { placeholder: t('emailUrl') },
                mobile: { placeholder: t('mobileCode') }
            }[type]),
        [t, type]
    )

    const isShowRegister = useMemo(
        () => allowRegister && (login.email.isOpened || login.phone.isOpened),
        [allowRegister, login.email.isOpened, login.phone.isOpened]
    )
    const [openedCode, setOpenedCode] = useState(false)

    const handleGetCodeHandle = useCallback(
        async (resolve?: () => void, reject?: () => void) => {
            setOpenedCode(true)
            const result = await onSendCode?.(type, getValues(type))
            return result ? resolve?.() : reject?.()
        },
        [getValues, onSendCode, type]
    )

    const { state, getPhoneCode } = useSendCode(type, handleGetCodeHandle)

    const handleGetPhoneCode = useCallback(() => {
        if (!formRuleMap[type].pattern.value.test(getValues(type))) {
            Toast.error(formRuleMap[type].pattern.message)
            return
        }

        !disableEvent && getPhoneCode()
    }, [disableEvent, formRuleMap, getPhoneCode, getValues, type])

    useEffect(() => {
        const { unsubscribe } = watch(value => {
            if (value.code?.length === 6) {
                onLogin?.(clone(value) as AccountLoginForm)
            }
        })
        return unsubscribe
    }, [handleSubmit, onLogin, watch])

    const ele = useMemo(() => {
        if (openedCode) {
            return (
                <Code
                    type={type}
                    account={getValues(type)}
                    authentication={authentication}
                    label={state.codeText}
                    disabled={state.codeStep > 0 || state.disable}
                    language={language}
                    previewType={previewType}
                    onChangeLanguage={onChangeLanguage}
                    onSendCode={getPhoneCode}
                    onBack={() => {
                        setOpenedCode(false)
                    }}
                />
            )
        }
        return (
            <LoginBox
                name={name}
                logo={logo}
                describe={describe}
                language={language}
                previewType={previewType}
                onChangeLanguage={onChangeLanguage}
                onBack={() => onChangeMode('home')}
                // customBtn={
                //     isShowRegister ? (
                //         <Button
                //             style={{ height: 44, marginTop: 26, backgroundColor: 'var(--color-app-main-tint)', borderStyle: 'none' }}
                //             onClick={() => onChangeMode('register')}
                //             radius={10}
                //             block
                //         >
                //             <Text size={16} color="var(--color-app-main)">
                //                 {t('registerAccount')}
                //             </Text>
                //         </Button>
                //     ) : null
                // }
            >
                <SCxInput
                    {...register(type, formRuleMap[type])}
                    style={{ height: 44, borderRadius: 10 }}
                    placeholder={info.placeholder}
                    onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                />
                <Button
                    block
                    style={{ height: 44, marginTop: 16, backgroundColor: 'var(--color-app-main)', borderStyle: 'none' }}
                    type="primary"
                    radius={10}
                    disabled={state.codeStep > 0 || state.disable}
                    onClick={handleGetPhoneCode}
                >
                    {state.codeText}
                </Button>
            </LoginBox>
        )
    }, [
        authentication,
        describe,
        formRuleMap,
        getPhoneCode,
        getValues,
        handleGetPhoneCode,
        info.placeholder,
        language,
        logo,
        name,
        onChangeLanguage,
        onChangeMode,
        openedCode,
        previewType,
        register,
        state.codeStep,
        state.codeText,
        state.disable,
        type
    ])

    return (
        <CM.FormContainer>
            <FormProvider {...methods}>{ele}</FormProvider>
        </CM.FormContainer>
    )
}
