import { Empty, hoverScrollBar, IconFont, Text, Toast } from '@byecode/ui'
import type { ValueVariable, VariableADTvalue } from '@lighthouse/core'
import {
    type DataSourceAbstract,
    type FieldInputADTValue,
    type InputValueItem,
    type SubFormBlockAbstract,
    VariableType
} from '@lighthouse/core'
import {
    type AppendParams,
    type ApplicationPreviewEnum,
    type CascadeParam,
    type CheckBoxParam,
    type PersonParam,
    type PhoneNumberParam,
    type RelativeFieldParam,
    type UseUploadFileSParameter,
    getFieldInputInitialValue
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { Flex, ScrollArea } from '@mantine/core'
import type { UploadyProps } from '@rpldy/uploady'
import equal from 'fast-deep-equal'
import { find, findIndex } from 'rambda'
import React, { useCallback, useMemo, useRef } from 'react'
import { useAsyncRetry, useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import type { FieldBlockInfo } from '../../components'
import { parseExcel } from './help'
import { SubFormContent } from './SubFormContent'
import type { SubFormFieldInputContextType } from './SubFormFieldInputProvider'
import { SubFormFieldInputProvider } from './SubFormFieldInputProvider'
import { SubFormFooter } from './SubFormFooter'
import { SubFormHeader } from './SubFormHeader'
import type { SubFormRecord } from './types'

export * from './types'

interface SubFormBlockProps extends RelativeFieldParam, CascadeParam, Pick<PhoneNumberParam, 'onFetchSmsCode'>, PersonParam, CheckBoxParam {
    dataList: SubFormRecord[]
    appId: string
    envId?: string
    blockData: SubFormBlockAbstract
    // field?: Field
    previewType?: ApplicationPreviewEnum
    // uploadyOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    language: string
    dataSource?: DataSourceAbstract
    // richTextUploadOptions: UploadyProps
    isValid: boolean
    getUploadyOptions: (fieldId: string) => Pick<UseUploadFileSParameter, 'info' | 'options'>
    getRichTextUploadOptions: (fieldId: string) => UploadyProps
    getVideoUploadyOption: (fieldId: string) => Pick<UseUploadFileSParameter, 'info' | 'options'>
    onChange?: (v: (v: Record<string, SubFormRecord[]>) => void) => void
    onVariableValueRender: (fieldBlockInfo: FieldBlockInfo, fieldBlockValueMap?: Record<string, InputValueItem>) => Promise<InputValueItem>
    onBlockChange?: (values: SubFormBlockAbstract, origin: SubFormBlockAbstract) => Promise<void> | void
}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: visible;
    background-color: #ffffff;
    font-size: var(---font-size-normal);
`

const SCxWrapper = styled.div`
    max-width: 100%;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    max-width: 100%;
    overflow: auto hidden;
    display: flex;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    ${hoverScrollBar}
    ::-webkit-scrollbar-thumb {
        background: #ccc;
    }
`

const SCxTable = styled.div`
    display: inline-flex;
    flex-direction: column;
`

const SubForm: React.FunctionComponent<SubFormBlockProps> = ({
    appId,
    envId,
    blockData,
    dataList,
    relativeDataSource,
    isValid,
    language,
    previewType,
    dataSource,
    dataSourceList,
    getUploadyOptions,
    getRichTextUploadOptions,
    getVideoUploadyOption,
    onVariableValueRender,
    onFetchCascadeOptions,
    onFetchDataSource,
    onFetchDataSourceMeta,
    onFetchPersonOptions,
    onFetchSmsCode,
    onLoadMoreData,
    onOpenPage,
    onRenderTitle,
    onChange,
    onBlockChange
}) => {
    const {
        config,
        id: blockId,
        config: { columns, pointer = '' }
    } = blockData

    const initColumnsWidth = useMemo(() => Object.fromEntries(columns.map(({ id, width }) => [id, width])), [columns])
    const [columnsWidth, setColumnsWidth] = useImmer(initColumnsWidth)

    const { value: initRecordArr } = useAsyncRetry(() => {
        return Promise.all(
            columns.map(async column => {
                const { id, config } = column
                const { inputType, fieldPointer = '' } = config
                const field = dataSource?.schema[id]
                const inputItem: FieldBlockInfo = { id, fieldId: fieldPointer, dsId: pointer, inputType, config }
                const fieldInput = await onVariableValueRender(inputItem)
                return [
                    id,
                    {
                        id: blockId,
                        fieldId: fieldPointer,
                        type: inputType,
                        dsId: pointer,
                        fieldType: field?.type,
                        source: 'subForm',
                        value: fieldInput.value
                    }
                ]
            })
        )
    }, [columns, onVariableValueRender, dataSource, pointer])

    const handleChangeColumnWidth = useCallback(
        (id: string, width: number, isFinished: boolean) => {
            setColumnsWidth(draft => {
                draft[id] = width
                if (isFinished) {
                    const newColumns = columns.map(column => ({ ...column, width: draft[column.id] }))
                    onBlockChange?.({ ...blockData, config: { ...config, columns: newColumns } }, blockData)
                }
            })
        },
        [blockData, columns, config, onBlockChange, setColumnsWidth]
    )

    const handleAddRecord = useCallback(() => {
        if (!initRecordArr) {
            return
        }
        const newRecord: SubFormRecord = {
            id: nanoid(),
            content: Object.fromEntries(initRecordArr)
        }
        onChange?.(draft => {
            if (!draft[blockId]) {
                draft[blockId] = []
            }
            if (draft[blockId].length >= 100) {
                Toast.warning('最多添加100条')
                return
            }
            draft[blockId].push(newRecord)
        })
    }, [blockId, initRecordArr, onChange])

    const handleRecordCopy = useCallback(
        (id: string) => {
            onChange?.(draft => {
                if (!draft[blockId]) {
                    draft[blockId] = []
                }
                const newRecord = find(i => i.id === id, draft[blockId])
                if (!newRecord) {
                    return
                }
                draft[blockId].push({ ...newRecord, id: nanoid() })
            })
        },
        [onChange, blockId]
    )

    const handleRecordRemove = useCallback(
        (id: string) => {
            onChange?.(draft => {
                if (!draft[blockId]) {
                    draft[blockId] = []
                }
                const index = findIndex(i => i.id === id, draft[blockId])
                if (index === -1) {
                    return
                }
                draft[blockId].splice(index, 1)
            })
        },
        [onChange, blockId]
    )

    const handleCellChange = useCallback(
        (recordId: string, columnId: string, fieldValue: FieldInputADTValue) => {
            onChange?.(draft => {
                if (!draft[blockId]) {
                    draft[blockId] = []
                }
                const record = find(i => i.id === recordId, draft[blockId])
                if (!record) {
                    return
                }
                record.content[columnId].value = fieldValue.value
            })
        },
        [blockId, onChange]
    )

    const handleSmsCodeChange = useCallback(
        (recordId: string, columnId: string, code: string) => {
            onChange?.(draft => {
                if (!draft[blockId]) {
                    draft[blockId] = []
                }
                const record = find(i => i.id === recordId, draft[blockId])
                if (!record) {
                    return
                }
                record.content[columnId].code = code
            })
        },
        [blockId, onChange]
    )

    const handleAppend = useCallback(
        async (v: AppendParams) => {
            const {
                file,
                sheetDto: { fields = [], sheetName }
            } = v
            const { records: list } = await parseExcel(file, sheetName)
            const newList: SubFormRecord[] = await Promise.all(
                list.map(async (item, index) => {
                    const arrContent = await Promise.all(
                        columns.map(async column => {
                            const { id, config } = column
                            const { inputType, fieldPointer = '' } = config
                            const field = dataSource?.schema[id]
                            const keyIndex = fields.find(i => i.dsFieldId === fieldPointer)?.sheetFieldNo
                            const cellValue = item[Number(keyIndex ?? '0') + 1]
                            const valueVariable: VariableADTvalue = {
                                type: VariableType.VALUE,
                                valueVariable: { value: cellValue }
                            } as ValueVariable
                            const newValue = await getFieldInputInitialValue({
                                config: {
                                    ...config,
                                    initialValue: keyIndex === undefined ? keyIndex : valueVariable
                                }
                            })
                            return [
                                id,
                                {
                                    id: blockId,
                                    fieldId: fieldPointer,
                                    type: inputType,
                                    dsId: pointer,
                                    fieldType: field?.type,
                                    source: 'subForm',
                                    value: newValue
                                }
                            ]
                        })
                    )
                    return { id: nanoid(), content: Object.fromEntries(arrContent) }
                })
            )

            onChange?.(draft => {
                if (!draft[blockId]) {
                    draft[blockId] = []
                }
                if (newList.length + draft[blockId].length > 100) {
                    Toast.warning('最多添加100条')
                    return
                }
                newList.forEach(item => {
                    draft[blockId].push(item)
                })
            })
        },
        [columns, dataSource?.schema, blockId, onChange, pointer]
    )

    const prevInitRecordArrRef = useRef(initRecordArr)
    useUpdateEffect(() => {
        if (!initRecordArr || !prevInitRecordArrRef.current) {
            prevInitRecordArrRef.current = initRecordArr
            return
        }
        const isChangeNum = prevInitRecordArrRef.current.length !== initRecordArr.length
        if (isChangeNum) {
            onChange?.(draft => {
                if (!draft[blockId]) {
                    return
                }
                draft[blockId] = draft[blockId].map(v => ({ id: v.id, content: Object.fromEntries(initRecordArr) }))
            })
            prevInitRecordArrRef.current = initRecordArr
            return
        }
        const isEqual = initRecordArr.every((v, i) => equal(v[1], prevInitRecordArrRef.current?.[i]?.[1]))
        if (!isEqual) {
            onChange?.(draft => {
                if (!draft[blockId]) {
                    return
                }
                draft[blockId] = draft[blockId].map(v => ({ id: v.id, content: Object.fromEntries(initRecordArr) }))
            })
            prevInitRecordArrRef.current = initRecordArr
        }
    }, [initRecordArr, blockId])

    useUpdateEffect(() => {
        setColumnsWidth(initColumnsWidth)
    }, [initColumnsWidth])

    const SubFormFieldInputProviderValue: SubFormFieldInputContextType = useMemo(
        () => ({
            language,
            isValid,
            columnsWidth,
            relativeDataSource,
            previewType,
            dataSource,
            dataSourceList,
            getUploadyOptions,
            getRichTextUploadOptions,
            getVideoUploadyOption,
            onChangeColumnWidth: handleChangeColumnWidth,
            onCellChange: handleCellChange,
            onRecordCopy: handleRecordCopy,
            onRecordRemove: handleRecordRemove,
            onChangeSmsCode: handleSmsCodeChange,
            onFetchCascadeOptions,
            onFetchDataSource,
            onFetchDataSourceMeta,
            onFetchPersonOptions,
            onFetchSmsCode,
            onLoadMoreData,
            onOpenPage,
            onRenderTitle
        }),
        [
            language,
            isValid,
            columnsWidth,
            relativeDataSource,
            previewType,
            dataSource,
            dataSourceList,
            getUploadyOptions,
            getRichTextUploadOptions,
            getVideoUploadyOption,
            handleChangeColumnWidth,
            handleCellChange,
            handleRecordCopy,
            handleRecordRemove,
            handleSmsCodeChange,
            onFetchCascadeOptions,
            onFetchDataSource,
            onFetchDataSourceMeta,
            onFetchPersonOptions,
            onFetchSmsCode,
            onLoadMoreData,
            onOpenPage,
            onRenderTitle
        ]
    )

    return (
        <SCxContainer>
            <SCxWrapper>
                <SubFormFieldInputProvider value={SubFormFieldInputProviderValue}>
                    <SubFormHeader blockData={blockData} onBlockChange={onBlockChange} />
                    <SCxTable>
                        <SubFormContent data={dataList} columns={columns} />
                        {columns.length === 0 && (
                            <Empty
                                styles={{
                                    root: {
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 0,
                                        right: 101,
                                        left: 72,
                                        width: 'auto',
                                        backgroundColor: '#fff'
                                    }
                                }}
                                description={
                                    <Flex gap={8}>
                                        <IconFont type="Add" color="var(--color-main)" />
                                        <Text color="var(--color-gray-400)">请在配置中添加表单列</Text>
                                    </Flex>
                                }
                            />
                        )}
                    </SCxTable>
                </SubFormFieldInputProvider>
            </SCxWrapper>
            {columns.length > 0 && (
                <SubFormFooter appId={appId} envId={envId} dataSource={dataSource} onRecordsAdd={handleAddRecord} onAppend={handleAppend} />
            )}
        </SCxContainer>
    )
}

export const SubFormBlock = React.memo(SubForm)
