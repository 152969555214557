import { tinyButtons } from '@byecode/ui'
import type {
    AiFieldStatus,
    BaseFieldConfig,
    ButtonAction,
    GalleryViewOptions,
    HighLightConditions,
    ObjectFitConfig,
    Pagination,
    RatioConfig,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SchemaProtocol,
    ViewField,
    ViewOptions
} from '@lighthouse/core'
import { useUpdateEffect } from '@react-hookz/web'
import { useVirtualizer } from '@tanstack/react-virtual'
import type { atomWithImmer } from 'jotai-immer'
import type { ReactNode } from 'react'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import styled from 'styled-components'

import { GallerySpacing } from './constants'
import { GalleryRecord } from './GalleryRecord'

const Root = styled.div`
    overflow: auto hidden;
    padding-bottom: 4px;
    ${tinyButtons}
`

const List = styled.div`
    position: relative;
`

const ItemWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    & > * {
        min-height: 100%;
    }
`

interface HorizontalScrollViewProps {
    scale?: number
    cols: number
    pagination: Pagination
    records: RecordLikeProtocol[]
    canViewRecord?: boolean
    headCover?: Omit<BaseFieldConfig, 'align'> & ObjectFitConfig & RatioConfig
    schema: SchemaProtocol['schema']
    columns: ViewField[]
    rowStyleConditions: Record<string, HighLightConditions>
    selectedRecords: string[]
    recordEditOpenable: boolean
    recordDeleteAble: boolean
    galleryItemCheckable?: boolean
    headAvatar: GalleryViewOptions['headAvatar']
    headTitle?: BaseFieldConfig
    headTags: GalleryViewOptions['headTags']
    contentTags: GalleryViewOptions['contentTags']
    footTags: GalleryViewOptions['footTags']
    recordStyle: GalleryViewOptions['style']
    actions: ViewOptions['actions']
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    renderItem?: (record: RecordLikeProtocol) => ReactNode
    onClickItem?: (recordId: string) => void
    onSelectRecord?: (checked: boolean, id: string) => void
    onRecordEdit: (recordId: string) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (params: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (params: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
    onLoadMoreData?: (pageNum: number) => Promise<RecordLikeProtocol[]>
}

export const HorizontalScrollView = (props: HorizontalScrollViewProps) => {
    const {
        scale = 1,
        cols,
        pagination,
        records,
        canViewRecord,
        headCover,
        schema,
        columns,
        recordDeleteAble,
        recordEditOpenable,
        recordStyle,
        rowStyleConditions,
        renderItem,
        selectedRecords,
        contentTags,
        onRecordDelete,
        onRecordEdit,
        onRenderButtonTitle,
        onAiGeneration,
        onClickItem,
        onRecordClickedActionTrigger,
        onRecordOperatorActionTrigger,
        onSelectRecord,
        headAvatar,
        headTags,
        headTitle,
        actions,
        aiFieldStatusListAtom,
        footTags,
        galleryItemCheckable,
        onLoadMoreData
    } = props
    const innerRef = useRef<HTMLDivElement | null>(null)

    const [containerWidth, setContainerWidth] = useState(innerRef.current?.clientWidth ?? 0)
    const itemWidth = (containerWidth - (Math.ceil(cols) - 1) * GallerySpacing) * (1 / cols)
    useLayoutEffect(() => {
        const el = innerRef.current
        if (!el) {
            return
        }

        let raf: number
        const observer = new ResizeObserver(([e]) => {
            raf = requestAnimationFrame(() => {
                flushSync(() => {
                    setContainerWidth(e.contentRect.width)
                })
            })
        })

        observer.observe(el)

        return () => {
            if (raf) {
                cancelAnimationFrame(raf)
            }

            if (observer) {
                observer.disconnect()
            }
        }
    }, [])

    const virtualizer = useVirtualizer({
        horizontal: true,
        count: records.length,
        getItemKey: i => records[i].id,
        getScrollElement: () => innerRef.current,
        estimateSize: () => containerWidth * (1 / cols),
        overscan: 6
        // gap: GallerySpacing
    })
    const items = virtualizer.getVirtualItems()

    // 滚动加载
    const { currentPage = 1, pageSize = 10, rowTotal = 0 } = pagination
    const loadingRef = useRef(false)
    useUpdateEffect(() => {
        void (async () => {
            if (!rowTotal || loadingRef.current || !virtualizer.range) {
                return
            }

            const rangOut = currentPage >= Math.ceil(rowTotal / pageSize)
            const isReachRight = virtualizer.range.endIndex >= records.length - 1 - 6
            const hasMore = rowTotal > records.length
            if (!rangOut && isReachRight && hasMore && onLoadMoreData) {
                loadingRef.current = true
                await onLoadMoreData(currentPage + 1)
                // eslint-disable-next-line require-atomic-updates
                loadingRef.current = false
            }
        })()
    }, [currentPage, records.length, rowTotal, virtualizer.range?.endIndex])

    // 收集子项高度
    const itemHeightRef = useRef<Map<React.Key, HTMLElement | null>>(new Map())
    const [currentMaxHeight, setCurrentMaxHeight] = useState<number>()
    useLayoutEffect(() => {
        let maxHeight = 0
        let raf: number

        function handleResize() {
            items.forEach(item => {
                const el = itemHeightRef.current.get(item.key)
                if (!el) {
                    return
                }
                const { height } = el.getBoundingClientRect()
                maxHeight = Math.max(maxHeight, height)
            })

            if (maxHeight !== currentMaxHeight) {
                flushSync(() => {
                    setCurrentMaxHeight(maxHeight)
                })
            }
        }

        const observer = new ResizeObserver(() => {
            raf = requestAnimationFrame(handleResize)
        })

        items.forEach(item => {
            const el = itemHeightRef.current.get(item.key)
            if (!el) {
                return
            }
            observer.observe(el)
        })

        return () => {
            if (raf) {
                cancelAnimationFrame(raf)
            }

            if (observer) {
                observer.disconnect()
            }
        }
    }, [currentMaxHeight, items])

    return (
        <Root ref={innerRef}>
            <List
                style={{
                    height: currentMaxHeight && currentMaxHeight / scale,
                    width: virtualizer.getTotalSize() / scale
                }}
            >
                {items.map(item => (
                    <ItemWrapper
                        data-view-item={item.index}
                        key={item.key}
                        style={{
                            width: itemWidth / scale,
                            transform: `translateX(${item.start / scale}px)`
                        }}
                    >
                        <GalleryRecord
                            ref={r => itemHeightRef.current.set(item.key, r)}
                            record={records[item.index]}
                            canViewRecord={canViewRecord}
                            headCover={headCover}
                            schema={schema}
                            columns={columns}
                            recordEditOpenable={recordEditOpenable}
                            recordDeleteAble={recordDeleteAble}
                            rowStyleConditions={rowStyleConditions}
                            selectedRecords={selectedRecords}
                            galleryItemCheckable={galleryItemCheckable}
                            headAvatar={headAvatar}
                            headTitle={headTitle}
                            headTags={headTags}
                            contentTags={contentTags}
                            footTags={footTags}
                            recordStyle={recordStyle}
                            aiFieldStatusListAtom={aiFieldStatusListAtom}
                            actions={actions}
                            renderItem={renderItem}
                            onClickItem={onClickItem}
                            onRecordEdit={onRecordEdit}
                            onRecordDelete={onRecordDelete}
                            onSelectRecord={onSelectRecord}
                            onAiGeneration={onAiGeneration}
                            onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
                            onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                            onRenderButtonTitle={onRenderButtonTitle}
                        />
                    </ItemWrapper>
                ))}
            </List>
        </Root>
    )
}
