import styled from 'styled-components'

export const ValueEditorWrapper = styled.div<{ width?: string; minWidth?: string }>`
    width: ${({ width }) => width || '100%'};
    min-width: ${({ minWidth }) => minWidth || 'unset'};
    height: calc(100% - 4px);
`

export const TagSelectWrapper = styled.div`
    padding-bottom: 10px;
    height: 100%;
`
