import { useAtomData } from '@lighthouse/shared'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useCallback, useMemo } from 'react'

import { dataSourceAtomFamily, dataSourceListIdsAtom, dataSourcePoolAtom, recordAtomFamily, recordPoolAtom } from '@/atoms/dataSource/state'

export const useDataSourcePool = () => {
    return useAtomData(dataSourcePoolAtom)
}

export const useDataSourceListIds = (id: string) => {
    return useAtomValue(dataSourceListIdsAtom)
}

export const useDataSource = (appId: string, envId: string, dsId: string) => {
    return useAtomValue(dataSourceAtomFamily({ appId, envId, dsId }))
}

export const useRecordList = () => {
    return useAtomValue(recordPoolAtom)
}

export const useRecord = (appId: string, envId: string, dsId: string, recordId: string) => {
    return useAtomValue(recordAtomFamily({ appId, envId, dsId, recordId }))
}

export const useDataSourceRecordList = (appId: string, dsId: string) => {
    return useAtomData(
        recordPoolAtom,
        useCallback(draft => draft.filter(item => item.appId === appId && item.dsId === dsId), [appId, dsId])
    )
}

// export const useDataSourcePool = () => {
//     return useAtomValue(dataSourcePoolAtom)
// }

// export const useDataSourceListIds = () => {
//     return useAtomValue(dataSourceListIdsAtom)
// }

// export const useDataSource = (appId: string, dsId: string) => {
//     return useAtomValue(dataSourceAtomFamily({ appId, dsId }))
// }

// export const useRecordList = () => {
//     return useAtomValue(recordPoolAtom)
// }

// export const useCurrentDataSourceId = () => {
//     return useAtomValue(currentDataSourceIdAtom) || 'USER_DATASOURCE'
// }

// export const useDataSourceRecordList = (appId: string, dsId: string) => {
//     return useAtomData(recordPoolAtom, useCallback(draft => draft.filter(item => item.appId === appId && item.dsId === dsId), [appId, dsId]))
// }

export const useDataSourceList = (appId: string) => {
    return useAtomData(
        useMemo(() => selectAtom(dataSourcePoolAtom, draft => draft.filter(dataSource => dataSource.appId === appId)), [appId])
    )
}
