import { IconFont, Text } from '@byecode/ui'
import type { SubFormColumn } from '@lighthouse/core'
import type { ResizeOffset } from '@lighthouse/shared'
import { FieldInputIconTypeMap, FieldInputNameMap, Resize, useApplicationContext } from '@lighthouse/shared'
import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'

import type { DragState } from './GuideLine'
import GuideLine from './GuideLine'
import { useSubFormFieldInputContext } from './SubFormFieldInputProvider'

interface RecordIndexProps {
    index: number
    column: SubFormColumn
}

const SCxContainer = styled.div<{ width: number }>`
    position: relative;
    width: 100%;
    height: 42px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: ${({ width }) => width}px;
`

export const RecordIndex: React.FunctionComponent<RecordIndexProps> = ({ index, column }) => {
    const { config, id, width } = column
    const { inputType = 'text', title } = config ?? {}

    const { onChangeColumnWidth, columnsWidth } = useSubFormFieldInputContext()

    const { isBuilder } = useApplicationContext()

    const initWidth = columnsWidth?.[id] || width
    const initWidthRef = useRef(initWidth)
    const headerFieldWrapperRef = useRef<HTMLDivElement>(null)

    const handleDragMove = useCallback(
        (state: DragState) => {
            const { delta } = state
            const offsetWidth = initWidthRef.current - delta.x
            const newWidth = offsetWidth < 160 ? 160 : offsetWidth
            onChangeColumnWidth?.(id, newWidth, false)
        },
        [id, onChangeColumnWidth]
    )

    const handleDragEnd = useCallback(
        (state: DragState) => {
            initWidthRef.current = initWidth
            onChangeColumnWidth?.(id, initWidth, true)
        },
        [id, initWidth, onChangeColumnWidth]
    )

    return (
        <SCxContainer width={initWidth} ref={headerFieldWrapperRef}>
            <IconFont color="var(--color-gray-400)" type={FieldInputIconTypeMap[inputType]} />
            <Text size={14} color="var(--color-black)">
                {title || FieldInputNameMap[inputType]}
            </Text>
            {isBuilder && <GuideLine onDragMove={handleDragMove} onDragEnd={handleDragEnd} />}
        </SCxContainer>
    )
}
