import type { ApplicationSettingAuthentication } from '@lighthouse/core'
import { ApplicationSettingPerfectMode, SMS_TYPE } from '@lighthouse/core'

import type { FieldInputConfig } from '../FieldInput/types'
import type { SendCodeType } from './types'
import { LoginAuthType } from './types'

// export const appLoginModeProperty: Record<AppLoginMode, AppModeProperty> = {
//     login: {
//         title: '账号密码登录',
//         catLeft: 69
//     },
//     register: {
//         title: '注册',
//         catLeft: 69
//     }
// }

export const appLoginLabelMap: Record<
    'email' | 'mobile',
    {
        name: string
    }
> = {
    email: {
        name: 'email'
    },
    mobile: {
        name: 'shortMessage'
    }
}

export const REGISTER_WAY_OPTIONS = [
    {
        label: 'mobileRegister',
        value: LoginAuthType.mobile.toString()
    },
    {
        label: 'emailRegister',
        value: LoginAuthType.email.toString()
    }
]

export const commonFieldInputConfig: Pick<FieldInputConfig, 'canEdit' | 'canDownload' | 'direction' | 'showTitle'> = {
    canEdit: true,
    canDownload: true,
    direction: 'column',
    showTitle: true
}

export const codeBtnStyle: React.CSSProperties = {
    width: 108,
    height: 36,
    border: 'none',
    boxShadow: '0 0 0 1px var(--color-gray-200)',
    color: 'var(--color-black)',
    borderRadius: '7px',
    flexShrink: 0,
    flexBasis: 108
}

export const defaultConfig: ApplicationSettingAuthentication = {
    logo: {
        type: 'icon',
        value: 'xxx'
    },
    name: 'xxx',
    describe: 'xxx',
    login: {
        phone: {
            isOpened: true,
        },
        email: {
            isOpened: true
        },
        weChat: {
            // isOpenedH5Auth: false,
            isOpenedH5QrcodeLogin: false,
            isOpened: true,
            isBindPhone: true
        },
        google: {
            isOpened: false,
            accountId: 'xxx'
        },
        landPageId: 'xxx'
    },
    register: {
        allowRegister: true,
        isReviewed: false
    },
    perfect: {
        title: '',
        describe: '',
        selectRoleTitle: '',
        selectRoleDescribe: '',
        isOpened: false,
        mode: ApplicationSettingPerfectMode.common,
        groupSetting: [
            {
                groupId: 'xx',
                groupName: 'xxx',
                isShown: true,
                fields: [
                    {
                        fieldId: 'xxx',
                        title: 'xxx',
                        placeHolder: 'xxx',
                        required: false
                    }
                ]
            }
        ],
        commonSetting: {
            fields: [
                {
                    fieldId: 'xxx',
                    title: 'xxx',
                    placeHolder: 'xxx',
                    required: false
                }
            ]
        }
    }
}

export const LOGIN_AUTH_CODE_MAP: Record<SendCodeType, LoginAuthType> = {
    email: LoginAuthType.email,
    mobile: LoginAuthType.mobile
}
