import type { Direction } from '@lighthouse/core'
import { DIRECTION, EDGE_MODE, MAX_CONTAINER_WIDTH_SIZE } from '@lighthouse/core'
import type { FlowLayoutContainerNode } from '@lighthouse/shared'
import {
    SIZE_OPTIONS,
    useFlowContainerContext,
    useFlowLayoutContext,
    useSharedConfigDisabledWithVersion,
    useSortableContext
} from '@lighthouse/shared'
import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'

import { EmptyContainer } from '../../components/EmptyContainer'

const Root = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    min-height: inherit;
`

const ROW_CSS = css`
    flex-direction: row;
    justify-content: var(--container-align-x);
    align-items: var(--container-align-y);
    & > & {
        flex-grow: 1;
    }
`
const COLUMN_CSS = css`
    flex-direction: column;
    justify-content: var(--container-align-y);
    align-items: var(--container-align-x);
`

type StyledProps = {
    direction?: Direction
    mobileAdaptive?: boolean
    isMobile: boolean
}

const StyledActualUsedWidthContent = styled.div<StyledProps>`
    flex: 1;
    position: relative;
    display: flex;
    padding-top: var(--container-padding-top);
    padding-right: var(--container-padding-right);
    padding-bottom: var(--container-padding-bottom);
    padding-left: var(--container-padding-left);

    ${({ direction, mobileAdaptive, isMobile }) =>
        direction === DIRECTION.horizontal
            ? [
                  ROW_CSS,
                  isMobile &&
                      mobileAdaptive &&
                      css`
                          ${COLUMN_CSS}
                      `
              ]
            : COLUMN_CSS}
`

interface ContainerLayoutProps extends React.ComponentPropsWithoutRef<'div'> {
    disabled?: boolean
    node: FlowLayoutContainerNode
    children?: React.ReactNode
}
export const ContainerLayout = forwardRef<HTMLDivElement, ContainerLayoutProps>(
    ({ disabled: propDisabled, node, children, ...rest }, ref) => {
        const disabledWithVersion = useSharedConfigDisabledWithVersion()
        const {
            size = MAX_CONTAINER_WIDTH_SIZE.unlimited,
            direction = DIRECTION.vertical,
            mobileAdaptive = false,
            padding,
            border,
            crop
        } = node.data
        const [paddingLeft = 0, paddingTop = 0, paddingRight = 0, paddingBottom = 0] = padding || []

        const { mode: borderMode, all: borderAll, each: borderEach } = border ?? {}
        const [borderLeft = 0, , borderRight = 0] =
            borderMode === EDGE_MODE.each ? borderEach || [] : Array.from({ length: 4 }).map(() => borderAll || 0)

        const { isMobile, disabled: globalDisabled } = useFlowLayoutContext()

        const disabled = (propDisabled ?? globalDisabled) || disabledWithVersion
        const noChildren = !node.children || node.children.length === 0

        const { parentId } = useSortableContext()

        const contextValue = useFlowContainerContext()

        const restX = (contextValue.fullWidth - borderLeft - borderRight - contextValue.contentWidth) / 2

        return (
            <Root ref={ref} {...rest}>
                {restX > 0 && (
                    <div
                        data-type="placeholder-begin"
                        data-relative-id={node.id}
                        data-actual-relative-id={node.virtual ? parentId : undefined}
                        data-virtual-parent-id={node.virtual ? node.id : undefined}
                        style={{ width: restX }}
                    />
                )}
                <StyledActualUsedWidthContent
                    direction={direction}
                    isMobile={isMobile}
                    mobileAdaptive={mobileAdaptive}
                    style={{
                        overflow: crop ? 'hidden' : undefined,
                        maxWidth: size === MAX_CONTAINER_WIDTH_SIZE.unlimited ? undefined : SIZE_OPTIONS[size]
                    }}
                >
                    {children}
                    {!disabled && noChildren && (
                        <EmptyContainer
                            style={{
                                left: `${paddingLeft}rem`,
                                top: `${paddingTop}rem`,
                                right: `${paddingRight}rem`,
                                bottom: `${paddingBottom}rem`
                            }}
                        />
                    )}
                </StyledActualUsedWidthContent>
                {restX > 0 && (
                    <div
                        data-type="placeholder-after"
                        data-relative-id={node.id}
                        data-actual-relative-id={node.virtual ? parentId : undefined}
                        data-virtual-parent-id={node.virtual ? node.id : undefined}
                        style={{ width: restX }}
                    />
                )}
            </Root>
        )
    }
)
