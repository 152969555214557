import { Button, IconFont, Popover } from '@byecode/ui'
import type { VariableADTvalue } from '@lighthouse/core'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import { VariableSelectDropDown } from '../Variable'
import type { VariablePickerProps } from './types'

const AddIcon = styled.div`
    width: 16px;
    height: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 0;
    background-color: var(--color-main);
    border-radius: 3px;
`

const Text = styled.span`
    font-size: 12px;
    color: var(--color-black);
`

export function VariablePicker({
    field,
    triggerWrapper: Target = Button,
    options,
    systemOption,
    userOption,
    dataSourceOption,
    enablePageLink,
    viewOption,
    formOption,
    onSelect,
    disabled
}: VariablePickerProps) {
    const [open, setOpen] = useState(false)

    const handleSelect = useCallback(
        (value: VariableADTvalue) => {
            setOpen(false)
            onSelect?.(value)
        },
        [onSelect]
    )

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const _disabled = disabled || (!options && !systemOption && !userOption && !dataSourceOption && !viewOption)

    return (
        <Popover width={248} withinPortal withinOverlay opened={open} disabled={_disabled} onChange={setOpen}>
            <Popover.Target>
                <Target>
                    <AddIcon>
                        <IconFont type="Add" fill="var(--color-white)" />
                    </AddIcon>
                    <Text>插入变量</Text>
                </Target>
            </Popover.Target>

            <Popover.Dropdown>
                <VariableSelectDropDown
                    // maxHeight={`${height}px`}
                    field={field}
                    // disableInput
                    options={options}
                    onClose={handleClose}
                    systemOption={systemOption}
                    userOption={userOption}
                    formOption={formOption}
                    viewOption={viewOption}
                    dataSourceOption={dataSourceOption}
                    enablePageLink={enablePageLink}
                    onChange={handleSelect}
                />
            </Popover.Dropdown>
        </Popover>
    )
}
