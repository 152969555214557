import type { FieldInputADTValue } from '@lighthouse/core'
import React, { useCallback, useMemo } from 'react'

import { NO_FIELD_INPUT, numberReg } from '../../../constants'
import {
    isDateValue,
    isEmptyFieldInputValue,
    isFieldNumberValue,
    isFileValue,
    isIdsValue,
    isNumberValue,
    isRichTextValue,
    isTextValue
} from '../../../utils'
import { CascadeDrawerField } from '../BaseField/CascadeField/mobile'
import { CheckboxField } from '../BaseField/CheckboxField'
import { DateDrawerField } from '../BaseField/DateField/mobile'
import EmptyField from '../BaseField/EmptyField'
import { FileDrawerField } from '../BaseField/FileField/mobile'
import { NotesModalField } from '../BaseField/NoteField/mobile'
import NumberField from '../BaseField/NumberField'
import { PersonDrawerField } from '../BaseField/PersonField/mobile'
import PhoneNumberField from '../BaseField/PhoneNumberField'
import { RelativeSelectDrawerField } from '../BaseField/RelativeSelectField/mobile'
import { SelectDrawerField } from '../BaseField/SelectField/mobile'
import SimpleTextField from '../BaseField/SimpleTextField'
import { SliderField } from '../BaseField/SliderField'
import TextField from '../BaseField/TextField'
import StyleContent from '../StyleContent'
import type { FieldProps } from '../types'

interface MobileFieldProps extends FieldProps {}

const MobileField: React.FunctionComponent<MobileFieldProps> = props => {
    const { value, config, onChange, onSaveChange, dataSource, isValid = false, mode = 'normal', error } = props
    const { canEdit, inputType, fieldPointer = '', required } = config
    const field = dataSource?.schema?.[fieldPointer]

    const isEmptyValue = useMemo(
        () => isEmptyFieldInputValue({ value, type: inputType } as FieldInputADTValue, field?.type),
        [field?.type, inputType, value]
    )

    const isControlled = useMemo(() => value !== undefined, [value])

    const handleCellChange = useCallback(
        (fieldValue: FieldInputADTValue) => {
            const res = onChange?.(fieldValue)
            return res || false
        },
        [onChange]
    )

    const handleCellSaveChange = useCallback(
        (fieldValue: FieldInputADTValue) => {
            const res = onSaveChange?.(fieldValue)
            return res || false
        },
        [onSaveChange]
    )
    const handleAllChange = useCallback(
        (fieldValue: FieldInputADTValue) => {
            handleCellChange(fieldValue)
            handleCellSaveChange(fieldValue)
        },
        [handleCellChange, handleCellSaveChange]
    )

    const fieldProps = useMemo(
        () => ({
            isControlled,
            readOnly: mode === 'normal' ? !canEdit : !canEdit || (!fieldPointer && !NO_FIELD_INPUT.includes(inputType))
        }),
        [canEdit, fieldPointer, inputType, isControlled, mode]
    )

    return (
        <StyleContent inputType={inputType} error={error} isReadOnly={fieldProps.readOnly} config={config}>
            {useMemo(() => {
                const isEmpty =
                    (isEmptyFieldInputValue({ value, type: inputType } as FieldInputADTValue, field?.type) && !canEdit) || !fieldPointer
                if (isEmpty && !NO_FIELD_INPUT.includes(inputType)) {
                    return <EmptyField type={inputType} config={config} />
                }
                switch (inputType) {
                    case 'text': {
                        return (
                            <TextField
                                {...props}
                                {...fieldProps}
                                isMobile
                                textConfig={config}
                                value={isTextValue(value) ? value : ''}
                                onCellChange={handleCellChange}
                                onSaveCellChange={handleCellSaveChange}
                            />
                        )
                    }
                    case 'phoneNumber': {
                        return (
                            <PhoneNumberField
                                {...props}
                                {...fieldProps}
                                phoneNumberConfig={config}
                                value={isTextValue(value) ? value : ''}
                                onCellChange={handleCellChange}
                                onSaveCellChange={handleCellSaveChange}
                            />
                        )
                    }
                    case 'url':
                    case 'email': {
                        return (
                            <SimpleTextField
                                {...props}
                                {...fieldProps}
                                value={isTextValue(value) ? value : ''}
                                onCellChange={handleCellChange}
                                onSaveCellChange={handleCellSaveChange}
                            />
                        )
                    }
                    case 'number': {
                        const numberValue = isFieldNumberValue(value) ? value : ''
                        const finallyValue = numberReg.test(numberValue.toString()) ? numberValue : ''
                        return (
                            <NumberField
                                {...props}
                                {...fieldProps}
                                value={finallyValue}
                                numberField={field?.type === 'number' ? field : undefined}
                                numberConfig={config}
                                onCellChange={handleCellChange}
                                onSaveCellChange={handleCellSaveChange}
                            />
                        )
                    }
                    case 'slider': {
                        const numberValue = isFieldNumberValue(value) ? value : ''
                        const finallyValue = numberReg.test(numberValue.toString()) ? numberValue : ''
                        return (
                            <SliderField
                                {...props}
                                {...fieldProps}
                                value={finallyValue}
                                sliderConfig={config}
                                onCellChange={handleCellChange}
                                onSaveCellChange={handleCellSaveChange}
                            />
                        )
                    }
                    case 'date': {
                        return (
                            <DateDrawerField
                                {...props}
                                {...fieldProps}
                                value={isDateValue(value) ? value : ''}
                                dateField={field?.type === 'date' ? field : undefined}
                                dateConfig={config}
                                onCellChange={handleAllChange}
                            />
                        )
                    }
                    case 'file': {
                        return (
                            <FileDrawerField
                                {...props}
                                {...fieldProps}
                                fileField={field?.type === 'file' || field?.type === 'video' ? field : undefined}
                                value={isFileValue(value) ? value : []}
                                onCellChange={handleAllChange}
                            />
                        )
                    }
                    case 'notes': {
                        return (
                            <NotesModalField
                                {...props}
                                {...fieldProps}
                                value={isRichTextValue(value) ? value : undefined}
                                onCellChange={handleCellChange}
                                onSaveCellChange={handleCellSaveChange}
                            />
                        )
                    }
                    case 'relativeSelect': {
                        if (field?.type === 'select') {
                            return (
                                <SelectDrawerField
                                    {...props}
                                    {...fieldProps}
                                    relativeSelectConfig={config}
                                    field={field?.type === 'select' && field ? field : null}
                                    value={isIdsValue(value) ? value : []}
                                    onCellChange={handleAllChange}
                                />
                            )
                        }
                        return (
                            <RelativeSelectDrawerField
                                {...props}
                                {...fieldProps}
                                value={isNumberValue(value) ? value.toString() : undefined}
                                relativeSelectConfig={config}
                                onCellChange={handleAllChange}
                            />
                        )
                    }
                    case 'person': {
                        return (
                            <PersonDrawerField
                                {...props}
                                {...fieldProps}
                                value={isIdsValue(value) ? value : []}
                                personField={field?.type === 'person' ? field : undefined}
                                personConfig={config}
                                onCellChange={handleAllChange}
                            />
                        )
                    }
                    case 'checkbox': {
                        return <CheckboxField {...props} {...fieldProps} value={Boolean(value)} checkBoxConfig={config}  onCellChange={handleAllChange} />
                    }
                    case 'cascade': {
                        return (
                            <CascadeDrawerField
                                {...props}
                                {...fieldProps}
                                cascadeConfig={config}
                                value={isTextValue(value) ? value : ''}
                                onCellChange={handleAllChange}
                            />
                        )
                    }
                    default: {
                        return null
                    }
                }
            }, [
                value,
                inputType,
                field,
                canEdit,
                fieldPointer,
                config,
                props,
                fieldProps,
                handleCellChange,
                handleCellSaveChange,
                handleAllChange
            ])}
        </StyleContent>
    )
}

export default MobileField
