import type { FieldInputADTValue, InputValueItem, SubFormColumn } from '@lighthouse/core'
import { FieldInput } from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { useSubFormFieldInputContext } from './SubFormFieldInputProvider'

interface SubFormCellProps {
    value: InputValueItem
    column: SubFormColumn
    recordId: string
}

const SCxCell = styled.div<{ width: number }>`
    width: ${({ width }) => width}px;
`

export const SubFormCell: React.FunctionComponent<SubFormCellProps> = ({ column, value, recordId }) => {
    const { config, id, width } = column
    const { fieldPointer } = config
    const fieldInputProps = useSubFormFieldInputContext()
    const fieldInputConfig = useMemo(
        () => ({
            ...config,
            showTitle: false,
            uploadyOptions: fieldPointer ? fieldInputProps.getUploadyOptions?.(fieldPointer) : undefined,
            richTextUploadOptions: fieldPointer ? fieldInputProps.getRichTextUploadOptions?.(fieldPointer) : undefined,
            videoUploadyOption: fieldPointer ? fieldInputProps.getVideoUploadyOption?.(fieldPointer) : undefined
        }),
        [config, fieldInputProps, fieldPointer]
    )

    const handleSaveChange = useCallback(
        (fieldValue: FieldInputADTValue) => {
            fieldInputProps.onCellChange?.(recordId, id, fieldValue)
        },
        [fieldInputProps, id, recordId]
    )

    const handleSmsCodeChange = useCallback(
        (v: string) => {
            fieldInputProps.onChangeSmsCode?.(recordId, id, v)
        },
        [fieldInputProps, id, recordId]
    )

    return (
        <SCxCell key={id} width={fieldInputProps.columnsWidth?.[id] || width}>
            <FieldInput
                value={value?.value}
                config={fieldInputConfig}
                {...fieldInputProps}
                onSaveChange={handleSaveChange}
                onChangeSmsCode={handleSmsCodeChange}
            />
        </SCxCell>
    )
}
