import type {
    ActionsProtocol,
    AiFieldStatus,
    ButtonAction,
    DataSourceAbstract,
    FieldADTValue,
    HighLightConditions,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SchemaProtocol,
    ViewField
} from '@lighthouse/core'
import type { ApplicationPreviewEnum } from '@lighthouse/shared'
import { getApplicationPreviewType, useTableSelection, VIEW_CELL_DATA_KEY, VIEW_FIELD_DATA_KEY } from '@lighthouse/shared'
import { useVirtualizer } from '@tanstack/react-virtual'
import chroma from 'chroma-js'
import type { atomWithImmer } from 'jotai-immer'
import React, { useEffect, useMemo, useRef, useTransition } from 'react'
import styled from 'styled-components'

import type { AdvancedTableContentState, AdvancedTableTooltipState } from './AdvancedTableContent'
import AdvancedTableRecord from './AdvancedTableRecord'

interface AdvancedTableBodyProps extends ActionsProtocol {
    blockId: string
    appId: string
    dsId: string
    records: RecordLikeProtocol[]
    checkable?: boolean
    recordOpenable?: boolean
    recordEditOpenable: boolean
    recordDeleteAble: boolean
    selectedRecords?: string[]
    viewFields: ViewField[]
    schema: SchemaProtocol['schema']
    scrollRef: React.RefObject<HTMLDivElement>
    highLightRules?: HighLightConditions
    blockWidth: number
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    primaryDataSourceFieldIds?: Set<string>
    onRecordClick?: (recordId: string) => void
    onRecordSelect?: (recordIds: string[]) => void
    onOpenEditor?: (data: AdvancedTableContentState) => void
    onOpenTooltip?: (data: AdvancedTableTooltipState) => void
    onCellChange?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onRecordEdit?: (recordId: string) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
}

export const SCxAdvancedTableContainer = styled.div``

export const SCxAdvancedTableBody = styled.div`
    display: flex;
    width: max-content;
    min-width: 100%;
    border-bottom: 1px solid var(--color-gray-200);
    border-radius: 8px;
    background: var(--color-white);
`

const AdvancedTableBody: React.FC<AdvancedTableBodyProps> = ({
    blockId,
    appId,
    dsId,
    actions,
    records,
    checkable,
    recordOpenable,
    recordDeleteAble,
    recordEditOpenable,
    selectedRecords,
    viewFields,
    schema,
    scrollRef,
    highLightRules,
    blockWidth,
    aiFieldStatusListAtom,
    primaryDataSourceFieldIds,
    onRecordClick,
    onRecordSelect,
    onOpenEditor,
    onOpenTooltip,
    onCellChange,
    onRecordDelete,
    onRecordEdit,
    onAiGeneration,
    onRecordOperatorActionTrigger,
    onRecordClickedActionTrigger,
    onRenderButtonTitle
}) => {
    const firstRender = useRef(true)
    useTableSelection({
        scrollRef,
        cellDataKey: VIEW_CELL_DATA_KEY,
        fieldDataKey: VIEW_FIELD_DATA_KEY
    })
    const [_, startTransition] = useTransition()

    // const visibleTableProps = useMemo(() => viewFields.filter(item => item.visible && schema[item.id]), [schema, viewFields])
    const documentStyles = getComputedStyle(document.body)
    const themeColor = documentStyles.getPropertyValue('--color-main').trim()
    const hoverCellColor = chroma(themeColor).alpha(0.3).hex()

    const overscan = useMemo(() => {
        let width = blockWidth
        return viewFields.reduce<number>((prev, cur) => {
            if (width > 0) {
                width -= cur.width || 160
                return ++prev
            }
            return prev
        }, 0)
    }, [blockWidth, viewFields])

    const columnVirtualizer = useVirtualizer({
        horizontal: true,
        count: viewFields.length,
        getScrollElement: () => scrollRef.current,
        paddingStart: 60,
        paddingEnd: 36,
        estimateSize: index => viewFields[index].width || 160,
        overscan
    })

    const previewType = useMemo(() => getApplicationPreviewType(), [])

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }
        startTransition(() => {
            columnVirtualizer.measure()
        })
    }, [columnVirtualizer, viewFields, blockWidth])
    return (
        <SCxAdvancedTableContainer
            style={{
                width: '100%',
                position: 'relative'
            }}
        >
            {records.map((record, index) => {
                return (
                    <AdvancedTableRecord
                        blockId={blockId}
                        key={record.id}
                        index={index}
                        appId={appId}
                        dsId={dsId}
                        actions={actions}
                        checkable={checkable}
                        recordOpenable={recordOpenable}
                        recordDeleteAble={recordDeleteAble}
                        recordEditOpenable={recordEditOpenable}
                        record={record}
                        selectedRecords={selectedRecords}
                        viewFields={viewFields}
                        schema={schema}
                        highLightRules={highLightRules}
                        columnVirtualizer={columnVirtualizer}
                        aiFieldStatusListAtom={aiFieldStatusListAtom}
                        hoverCellColor={hoverCellColor}
                        primaryDataSourceFieldIds={primaryDataSourceFieldIds}
                        previewType={previewType}
                        onRecordClick={onRecordClick}
                        onRecordSelect={onRecordSelect}
                        onOpenEditor={onOpenEditor}
                        onOpenTooltip={onOpenTooltip}
                        onCellChange={onCellChange}
                        onRecordEdit={onRecordEdit}
                        onRecordDelete={onRecordDelete}
                        onAiGeneration={onAiGeneration}
                        onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
                        onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                        onRenderButtonTitle={onRenderButtonTitle}
                    />
                )
            })}
        </SCxAdvancedTableContainer>
    )
}

export default AdvancedTableBody
