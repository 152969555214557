import type { AiFieldStatus, DataSourceAbstract, Field, FieldCellValue, HighLightConditions } from '@lighthouse/core'
import { useApplicationContext, ViewFieldStatusPreviewer } from '@lighthouse/shared'
import type { atomWithImmer } from 'jotai-immer'
import React from 'react'
import styled from 'styled-components'

export interface TableCellProps {
    dsId: string
    recordId: string
    field: Field
    data?: FieldCellValue
    width?: number
    styleConditions?: HighLightConditions
    style?: React.CSSProperties
    isBold?: boolean
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    onAiGeneration?: (fieldId: string) => Promise<boolean>
}

export const SCxTableCell = styled.div<{ width?: number }>`
    position: relative;
    width: ${({ width }) => (width ? `${width}px` : '160px')};
    min-height: 36px;
    padding: 1px 0 0 6px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    font-size: var(--font-size-normal);

    &.active {
        z-index: 1;
        &:before {
            content: '';
            position: absolute;
            width: calc(100%);
            height: calc(100%);
            left: 0;
            top: 0;
            border: 2px solid var(--color-app-main);
            background-color: transparent;
            z-index: 10;
        }
    }
`

export const SCxTableCellPopperAnchor = styled.div`
    position: absolute;
    left: -2px;
    top: -2px;
`

export const TableValue: React.FC<TableCellProps> = ({
    dsId,
    recordId,
    field,
    data,
    width,
    styleConditions,
    style,
    isBold,
    aiFieldStatusListAtom,
    onAiGeneration
}) => {
    const { personOptions } = useApplicationContext()

    return (
        <SCxTableCell width={width} style={style}>
            <ViewFieldStatusPreviewer
                dsId={dsId}
                recordId={recordId}
                field={field}
                isBold={isBold}
                data={data}
                isWrap={false}
                disabled
                width={width}
                personOptions={personOptions}
                styleConditions={styleConditions}
                aiFieldStatusListAtom={aiFieldStatusListAtom}
                onAiGeneration={onAiGeneration}
            />
        </SCxTableCell>
    )
}
