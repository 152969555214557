import { Enum } from '@lighthouse/tools'

import type {
    AlignMent,
    BackgroundNoImageProtocol,
    BackgroundProtocol,
    BlockProtocol,
    BlockVisibilityProtocol,
    DesignStyle,
    FilterFormType,
    TextProtocol
} from '../../protocols'
import { BlockType } from '../Basic'

export const TABS_TYPE = Enum('LINE', 'ROUND', 'CARD_SLOT', 'SEGMENTED')
export type TabsType = Enum<typeof TABS_TYPE>

export const TABS_SEGMENTED_RADIUS = Enum('xs', 'sm', 'md', 'xl')
export type TabsSegmentedRadius = Enum<typeof TABS_SEGMENTED_RADIUS>

export type TabsColorConfig = {
    color?: BackgroundNoImageProtocol
    iconColor?: BackgroundNoImageProtocol
    background?: BackgroundProtocol
    borderColor?: BackgroundNoImageProtocol
}

export interface TabBaseConfig {
    id: string
    name: string
    associatedView: string
    icon: string
    iconPosition: Exclude<AlignMent, 'center'>
    // sizeFit: SizeFit
    visibilityFilter?: FilterFormType
}

export interface TabStyleConfig extends TabsColorConfig {
    id: string
    hover?: TabsColorConfig
    active?: TabsColorConfig
}

export type TabConfig = TabBaseConfig & TabStyleConfig

export type TabVariantConfig = Record<
    TabsType,
    {
        align: AlignMent
        styleList: TabStyleConfig[]
        design: DesignStyle
    }
>

export interface TabsBlockConfig extends BlockVisibilityProtocol {
    variant: TabsType
    variantMap: TabVariantConfig

    baseList: TabBaseConfig[]

    lineColor: BackgroundNoImageProtocol
    // radius: TabsSegmentedRadius
    associatedContainer: string
    font?: TextProtocol & {
        advance: string
        align: AlignMent
    }
}

export abstract class TabsBlockAbstract implements BlockProtocol {
    abstract id: string

    type = BlockType.tabs

    abstract title: string

    abstract config: TabsBlockConfig
}
