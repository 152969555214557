import { Toast } from '@byecode/ui'
import retryEnhancer from '@rpldy/retry-hooks'
import type { Batch, BatchItem } from '@rpldy/uploady'
import Uploady, { FILE_STATES, useUploady } from '@rpldy/uploady'
import type { FC } from 'react'
import React, { useContext, useRef } from 'react'
import { debounce } from 'throttle-debounce'

import { fileMaxUploadSize, limitExceededCodeList } from '../../constants'
import { getFileSizeToMB } from '../../utils'
import type { UploadBatchCallbackParameter, UploadItemCallbackParameter } from './hooks'
import { uploadManageEvents } from './hooks/events'
import type { UploadManageContextProps } from './types'

export const defaultFileFilter = (file: File | string) => {
    if (typeof file === 'string') {
        return true
    }
    if (file.size > fileMaxUploadSize) {
        Toast.error(`不能上传大于 ${getFileSizeToMB(fileMaxUploadSize)}mb 的文件`)
        return false
    }
    return true
}

const defaultUploadyProps: UploadManageContextProps = {
    concurrent: true,
    maxConcurrent: 5,
    enhancer: retryEnhancer,
    fileFilter: defaultFileFilter,
    isSuccessfulCall: xhr => {
        if (xhr.status === 200) {
            const res = JSON.parse(xhr.response)
            if (res.code === '0030000') {
                return false
            }
            if (limitExceededCodeList.includes(res.code)) {
                return false
            }
        }
        return true
    }
}

type UploadItemListenerType = {
    callback: (data: BatchItem) => void
    id: string
    type: FILE_STATES
}

/**
 * @description Item级别的文件监听器
 * @date 9/6/2023 - 5:55:24 PM
 *
 * @class UploadBatchListener
 * @typedef {UploadBatchListener}
 */
class UploadItemListener {
    #listeners: UploadItemListenerType[] = []

    constructor() {
        uploadManageEvents.on('item-uploading', debounce(30, this.#itemStart).bind(this))
        uploadManageEvents.on('item-finished', debounce(30, this.#itemFinished).bind(this))
        uploadManageEvents.on('item-error', debounce(30, this.#itemError).bind(this))

        // uploadManageEvents.on('batch-uploading', debounce(30, this.#batchStart).bind(this))
        // uploadManageEvents.on('batch-finished', debounce(30, this.#batchFinished).bind(this))
        // uploadManageEvents.on('batch-error', debounce(30, this.#batchError).bind(this))
    }

    #itemStart({ batchItem, id }: UploadItemCallbackParameter) {
        this.#listeners.forEach(ls => {
            if (ls.id === id && ls.type === FILE_STATES.UPLOADING) {
                ls.callback(batchItem)
            }
        })
    }

    #itemFinished({ batchItem, id }: UploadItemCallbackParameter) {
        this.#listeners.forEach(ls => {
            if (ls.id === id && ls.type === FILE_STATES.FINISHED) {
                ls.callback(batchItem)
            }
        })
    }

    #itemError({ batchItem, id }: UploadItemCallbackParameter) {
        this.#listeners.forEach(ls => {
            if (ls.id === id && ls.type === FILE_STATES.ERROR) {
                ls.callback(batchItem)
            }
        })
    }

    add(callback: (data: BatchItem) => void, groupId: string, type: FILE_STATES) {
        this.#listeners.push({ callback, id: groupId, type })
    }

    remove(groupId: string) {
        this.#listeners = this.#listeners.filter(item => item.id !== groupId)
    }

    clear() {
        this.#listeners = []
    }
}

type UploadBatchListenerType = {
    callback: (data: Batch) => void
    id: string
    type: FILE_STATES
}

/**
 * @description Batch级别的文件监听器
 * @date 9/6/2023 - 5:55:24 PM
 *
 * @class UploadBatchListener
 * @typedef {UploadBatchListener}
 */
class UploadBatchListener {
    #listeners: UploadBatchListenerType[] = []

    constructor() {
        uploadManageEvents.on('batch-uploading', debounce(30, this.#batchStart).bind(this))
        uploadManageEvents.on('batch-finished', debounce(30, this.#batchFinished).bind(this))
        uploadManageEvents.on('batch-error', debounce(30, this.#batchError).bind(this))
    }

    #batchStart({ batch, id }: UploadBatchCallbackParameter) {
        this.#listeners.forEach(ls => {
            if (ls.id === id && ls.type === FILE_STATES.UPLOADING) {
                ls.callback(batch)
            }
        })
    }

    #batchFinished({ batch, id }: UploadBatchCallbackParameter) {
        this.#listeners.forEach(ls => {
            if (ls.id === id && ls.type === FILE_STATES.FINISHED) {
                ls.callback(batch)
            }
        })
    }

    #batchError({ batch, id }: UploadBatchCallbackParameter) {
        this.#listeners.forEach(ls => {
            if (ls.id === id && ls.type === FILE_STATES.ERROR) {
                ls.callback(batch)
            }
        })
    }

    add(callback: (data: Batch) => void, groupId: string, type: FILE_STATES) {
        this.#listeners.push({ callback, id: groupId, type })
    }

    remove(groupId: string) {
        this.#listeners = this.#listeners.filter(item => item.id !== groupId)
    }

    clear() {
        this.#listeners = []
    }
}

const ListenersContext = React.createContext(null) as unknown as React.Context<{
    itemListener: UploadItemListener
    batchListener: UploadBatchListener
}>
export const useListenersContext = () => useContext(ListenersContext)

export const UploadManageContext: FC<UploadManageContextProps> = props => {
    const listenersInstance = useRef({ itemListener: new UploadItemListener(), batchListener: new UploadBatchListener() })

    return (
        <ListenersContext.Provider value={listenersInstance.current}>
            <Uploady {...defaultUploadyProps} {...props} />
        </ListenersContext.Provider>
    )
}
export const useUploadManageContext = () => useUploady()
