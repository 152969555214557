import type { NotesValue } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import type { Editor, JSONContent } from '@tiptap/core'
import type { Transaction } from '@tiptap/pm/state'
import { clone } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { RichTextEditor } from '../../../RichText'
import type { ToolbarProps } from '../../../RichText/Toolbar'
import type { FieldBaseProps } from '../../types'

interface NotesFieldProps extends FieldBaseProps {
    value?: NotesValue
}

const SCxContainer = styled.div`
    width: 100%;
    font-size: var(--font-size-normal);
`

const NotesField: React.FC<NotesFieldProps> = props => {
    const {
        readOnly,
        onCellChange,
        onSaveCellChange,
        value,
        config: { richTextUploadOptions, fieldPointer = '', placeholder, initialValue },
        record
    } = props

    const [key, setKey] = useState(`${record?.id}-${fieldPointer}`)

    useUpdateEffect(() => {
        setKey(nanoid())
    }, [fieldPointer, initialValue, record?.id])

    const config: ToolbarProps['config'] = useMemo(
        () => ({
            history: true,
            heading: true,
            align: true,
            lineHeight: true,
            variable: false,
            image: richTextUploadOptions ? { uploadOptions: richTextUploadOptions } : false
        }),
        [richTextUploadOptions]
    )

    const handleChange = useCallback(
        (json: JSONContent) => {
            onSaveCellChange?.({ type: 'notes', value: json })
        },
        [onSaveCellChange]
    )

    const handleBlur = useCallback(
        ({ editor }: { editor: Editor; event: FocusEvent; transaction: Transaction }) => {
            onCellChange?.({ type: 'notes', value: clone(editor.getJSON()) })
        },
        [onCellChange]
    )

    return (
        <SCxContainer data-input-type="notes">
            <RichTextEditor
                minHeight={readOnly ? undefined : 200}
                styles={{
                    toolbarContainer: {
                        borderTopRightRadius: 6,
                        borderTopLeftRadius: 6
                    }
                }}
                key={key}
                config={config}
                readonly={readOnly}
                value={value}
                onChange={handleChange}
                onEditorBlur={handleBlur}
            />
        </SCxContainer>
    )
}

export default NotesField
