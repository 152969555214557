import { backgroundTransitionOnClick, Empty, IconFont, pointer, Popover, tinyButtons } from '@byecode/ui'
import type { DTFile, ValueVariable, VariableFieldADTValue } from '@lighthouse/core'
import { type Field, type VariableADTvalue, DateFieldRange, VariableType } from '@lighthouse/core'
import { Text } from '@mantine/core'
import { isValid } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { CURRENT_USER, defaultDateFormat, inputFieldTypes } from '../../../../constants'
import { useApplicationContext, useDataSourceContext } from '../../../../contexts'
import { getDateConfig, getRealField, isDateValue, isIdsValue } from '../../../../utils'
import type { UploadDropZoneProps } from '../../..'
import { getMultipleValue, TagItemPreviewer, UploadDropZone, useUploadBatchFinished, useUploadItemUploading } from '../../..'
import { CheckboxItemPreviewer } from '../../../FieldValuePreviewer/CheckboxValuePreviewer'
import { SelectItemPreviewer } from '../../../FieldValuePreviewer/SelectValuePreviewer'
import { AggregationInnerTypeToFieldType, ignoreEleId, variableFieldInfoMap } from '../../constant'
import { getEmptyFieldValue } from '../../help'
import DateCalendar from './CustomCalendar'
import CustomPexels from './CustomPexels'

interface VariableCustomProps {
    field?: Field
    isMultiple?: boolean
    disabledPexels?: boolean
    value?: ValueVariable
    uploadProps?: Pick<UploadDropZoneProps, 'uploadOptions' | 'accept' | 'multiple'>
    onChange?: (value: VariableADTvalue) => void
    onClose?: () => void
}

const SCxLeftFill = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    gap: 6px;
    height: 100%;
    font-size: 14px;
`

const SCxContainer = styled.div``

const SCxRightFill = styled.div``

const SCxListContainer = styled.div`
    height: auto;
    max-height: 600px;
    overflow: hidden auto;
    min-width: 200px;
    ${tinyButtons}
`

const SCxListItem = styled.div`
    width: 100%;
    height: 36px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-normal);
    overflow: hidden;
    ${backgroundTransitionOnClick}
    ${pointer}
`

const SCxTagIcon = styled(IconFont)<{ bgColor?: string }>`
    padding: 2px;
    border-radius: 2px;
    background-color: ${({ bgColor }) => bgColor};
`
export const VariableCustom: React.FunctionComponent<VariableCustomProps> = ({
    value: data,
    field,
    isMultiple,
    uploadProps,
    disabledPexels,
    onChange,
    onClose
}) => {
    const realField = useMemo(() => (field ? getRealField(field) : field), [field])

    const inputType = useMemo(() => {
        if (realField?.type === 'aggregation') {
            return AggregationInnerTypeToFieldType[realField?.innerType || 'NULL']
        }
        return realField?.type ?? 'text'
    }, [realField?.innerType, realField?.type])
    const isTextField = inputFieldTypes.has(inputType)
    const [opened, setOpened] = useState(false)
    const { personOptions: DsPersonOptions } = useDataSourceContext()
    const { personOptions: appPersonOptions } = useApplicationContext()

    const personOptions = useMemo(() => {
        return DsPersonOptions || appPersonOptions || []
    }, [DsPersonOptions, appPersonOptions])

    const personList = personOptions.filter(item => !item.isDepart)

    const initValue = useMemo(
        () => (data?.type === VariableType.VALUE ? (isIdsValue(data?.valueVariable?.value) ? data.valueVariable?.value : []) : []),

        [data]
    )
    const uploadId = uploadProps?.uploadOptions?.info.id ?? ''

    const [loading, setLoading] = useState(false)

    const fieldType = useMemo(() => (field?.type === 'video' ? 'video' : 'file'), [field?.type])

    useUploadItemUploading(uploadId, batchList => {
        setLoading(true)
    })

    useUploadBatchFinished(uploadId, batch => {
        const fileList: DTFile[] = batch.items.map(batchItem => {
            const {
                file: { name, size },
                uploadResponse: {
                    data: { content, success }
                }
            } = batchItem
            return content.url
        })

        setLoading(false)
        if (fileList.length > 0) {
            onChange?.({
                type: VariableType.VALUE,
                valueVariable: { type: fieldType, value: fileList }
            })
            onClose?.()
        }
    })

    const handleChange = useCallback(
        (value: VariableFieldADTValue, isMultiple?: boolean) => {
            !isMultiple && onClose?.()
            onChange?.({ type: VariableType.VALUE, valueVariable: value })
        },
        [onChange, onClose]
    )

    const contentEle = useMemo(() => {
        switch (inputType) {
            case 'id':
            case 'text':
            case 'userGroup':
            case 'phoneNumber':
            case 'url':
            case 'email':
            case 'number':
            case 'notes':
            case 'textGeneration': {
                return (
                    <SCxListItem onMouseEnter={e => setOpened(false)}>
                        <SCxLeftFill
                            onClick={() => {
                                handleChange(getEmptyFieldValue(inputType))
                            }}
                        >
                            <IconFont color="var(--color-gray-400)" size={16} type="InputIcon" />
                            <Text size={14} style={{ lineHeight: 1 }}>
                                输入具体值
                            </Text>
                        </SCxLeftFill>
                        <SCxRightFill>
                            {data?.type === VariableType.VALUE && <IconFont size={16} type="Tick" color="var(--color-purple-500)" />}
                        </SCxRightFill>
                    </SCxListItem>
                )
            }
            case 'video': {
                return (
                    <>
                        <SCxListItem
                            onClick={() => {
                                onClose?.()
                                handleChange(getEmptyFieldValue('file'))
                            }}
                        >
                            <SCxLeftFill>
                                <IconFont color="var(--color-gray-400)" size={16} type="PropertyLink" />
                                <Text size={14} style={{ lineHeight: 1 }}>
                                    视频链接
                                </Text>
                            </SCxLeftFill>
                        </SCxListItem>
                        {uploadProps && (
                            <UploadDropZone {...uploadProps}>
                                <SCxListItem onMouseEnter={e => setOpened(false)}>
                                    <SCxLeftFill>
                                        <IconFont color="var(--color-gray-400)" size={16} type="UploadSimple" />
                                        <Text size={14} style={{ lineHeight: 1 }}>
                                            从本地上传
                                        </Text>
                                    </SCxLeftFill>
                                </SCxListItem>
                            </UploadDropZone>
                        )}
                    </>
                )
            }
            case 'file': {
                return (
                    <>
                        {uploadProps && (
                            <UploadDropZone {...uploadProps}>
                                <SCxListItem onMouseEnter={e => setOpened(false)}>
                                    <SCxLeftFill>
                                        <IconFont color="var(--color-gray-400)" size={16} type="UploadSimple" />
                                        <Text size={14} style={{ lineHeight: 1 }}>
                                            从本地上传
                                        </Text>
                                    </SCxLeftFill>
                                </SCxListItem>
                            </UploadDropZone>
                        )}
                        {!disabledPexels && (
                            <SCxListItem onMouseEnter={e => setOpened(true)}>
                                <SCxLeftFill>
                                    <SCxTagIcon color="var(--color-white)" size={12} type="Pexels" bgColor="#05A081" />
                                    <Text size={14} style={{ lineHeight: 1 }}>
                                        Pexels
                                    </Text>
                                </SCxLeftFill>
                                <SCxRightFill>
                                    <IconFont type="ArrowRightSmall" color="var(--color-gray-400)" size={16} />
                                </SCxRightFill>
                            </SCxListItem>
                        )}
                    </>
                )
            }

            default: {
                return (
                    <SCxListItem onMouseEnter={e => setOpened(true)}>
                        <SCxLeftFill>
                            <IconFont size={16} color="var(--color-gray-400)" type={variableFieldInfoMap[inputType].icon} />
                            <Text>{variableFieldInfoMap[inputType].name} </Text>
                        </SCxLeftFill>
                        <SCxRightFill>
                            {data?.type === VariableType.VALUE && <IconFont size={16} type="Tick" color="var(--color-purple-500)" />}
                            <IconFont type="ArrowRightSmall" color="var(--color-gray-400)" size={16} />
                        </SCxRightFill>
                    </SCxListItem>
                )
            }
        }
    }, [data?.type, disabledPexels, handleChange, inputType, onClose, uploadProps])

    const listEle = useMemo(() => {
        if (!field) {
            return null
        }
        switch (inputType) {
            case 'selectGenerationByText':
            case 'select': {
                if (field.type === 'select' || field.type === 'selectGenerationByText') {
                    const selectMultiple = field.type === 'select' ? field.select.multiple : true
                    const isCurrentMultiple = isMultiple ?? selectMultiple
                    const options = field.type === 'select' ? field.select.options : field.selectGenerationByText.options
                    return (
                        <>
                            {options.map(item => {
                                return (
                                    <SCxListItem
                                        key={item.label}
                                        onClick={() =>
                                            isCurrentMultiple
                                                ? handleChange(
                                                      { type: inputType, value: getMultipleValue(initValue ?? [], item.label) },
                                                      isCurrentMultiple
                                                  )
                                                : handleChange({ type: inputType, value: [item.label] })
                                        }
                                    >
                                        <SelectItemPreviewer id="" data={item} />
                                        <SCxRightFill>
                                            {initValue?.includes(item.label) && (
                                                <IconFont color="var(--color-purple-500)" type="Tick" size={16} />
                                            )}
                                        </SCxRightFill>
                                    </SCxListItem>
                                )
                            })}
                            {options.length === 0 && <Empty icon="Nodata-8i554976" description="暂无可选项" />}
                        </>
                    )
                }
                return null
            }

            case 'date': {
                const dateValue =
                    data?.type === VariableType.VALUE
                        ? isDateValue(data?.valueVariable?.value)
                            ? new Date(data?.valueVariable?.value ?? '')
                            : new Date()
                        : new Date()

                const finallyDateVal = isValid(dateValue) ? dateValue : undefined
                const { range, format } = getDateConfig(field) || {}
                const calendarMode = range === DateFieldRange.MONTH ? 'month' : 'day'

                return <DateCalendar value={finallyDateVal} mode={calendarMode} config={{ format }} onChange={handleChange} />
            }

            case 'checkbox': {
                return (
                    <>
                        <SCxListItem onClick={() => handleChange({ type: inputType, value: true })}>
                            <SCxLeftFill>
                                <CheckboxItemPreviewer id="" data />
                                <Text>是</Text>
                            </SCxLeftFill>
                        </SCxListItem>
                        <SCxListItem onClick={() => handleChange({ type: inputType, value: false })}>
                            <SCxLeftFill>
                                <CheckboxItemPreviewer id="" data={false} />
                                <SCxRightFill>否</SCxRightFill>
                            </SCxLeftFill>
                        </SCxListItem>
                    </>
                )
            }
            case 'file': {
                return <CustomPexels onChange={handleChange} />
            }

            case 'person': {
                const newPersonOptions = [CURRENT_USER, ...personList]
                const isCurrentMultiple = isMultiple ?? (field.type === 'person' && field.person.multiple)
                return (
                    <>
                        {newPersonOptions.map(item => {
                            return (
                                <SCxListItem
                                    key={item.userId}
                                    onClick={() =>
                                        isCurrentMultiple
                                            ? handleChange(
                                                  { type: inputType, value: getMultipleValue(initValue ?? [], item.userId) },
                                                  isCurrentMultiple
                                              )
                                            : handleChange({ type: inputType, value: [item.userId] })
                                    }
                                >
                                    <TagItemPreviewer data={item} isHiddenUserId />
                                    <SCxRightFill>
                                        {initValue?.includes(item.userId) && (
                                            <IconFont type="Tick" color="var(--color-purple-500)" size={16} />
                                        )}
                                    </SCxRightFill>
                                </SCxListItem>
                            )
                        })}
                        {newPersonOptions.length === 0 && <Empty description="暂无可选人员" icon="Nodata-8i554976" />}
                    </>
                )
            }
            default: {
                return null
            }
        }
    }, [data?.type, data?.valueVariable?.value, field, handleChange, initValue, inputType, isMultiple, personList])

    return (
        <Popover
            opened={opened}
            onChange={v => !v && setOpened(false)}
            disabled={isTextField}
            width="auto"
            trigger="hover"
            position="left-start"
            withinPortal
        >
            <Popover.Target>
                <SCxContainer>{contentEle}</SCxContainer>
            </Popover.Target>
            <Popover.Dropdown compact={inputType === 'date' || inputType === 'file'}>
                <SCxListContainer id={ignoreEleId}>{listEle}</SCxListContainer>
            </Popover.Dropdown>
        </Popover>
    )
}
