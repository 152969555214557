import { BreakPointSize, useBreakpoint } from '@lighthouse/tools'
import type { Embla } from '@mantine/carousel'
import { Carousel, useAnimationOffsetEffect } from '@mantine/carousel'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { FileItem } from './FileItem'
import type { PreviewFile } from './types'

const StyledCarousel = styled(Carousel)`
    .mantine-Carousel-slide {
        height: 100%;
        overflow: hidden;
        img,
        video,
        audio {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .mantine-Carousel-control {
        box-shadow: var(--box-shadow);
        transition: all 150ms ease;
        svg {
            width: 32px;
            height: 32px;
        }
        &:not([data-inactive='true']):hover {
            background-color: #9e77ed;
            color: var(--color-white);
        }
    }
`

interface FileListCarouselProps {
    fileList: PreviewFile[]
    defaultIndex: number
    currentIndex: number
    onSlideChange: (i: number) => void
}

// 文件轮播图
export const FileCarousel = ({ fileList, defaultIndex, currentIndex, onSlideChange: propOnSlideChange }: FileListCarouselProps) => {
    const [embla, setEmbla] = useState<Embla | null>(null)
    useAnimationOffsetEffect(embla, 200)

    useEffect(() => {
        embla?.scrollTo(defaultIndex, true)
    }, [defaultIndex, embla])

    const onSlideChange = useCallback(
        (i: number) => {
            propOnSlideChange(i)
        },
        [propOnSlideChange]
    )

    const { ref, breakPoint } = useBreakpoint()
    const isMobile = BreakPointSize.sm === breakPoint || BreakPointSize.xs === breakPoint

    return (
        <StyledCarousel
            ref={ref}
            height="100%"
            sx={{ height: '100%' }}
            draggable={false}
            getEmblaApi={setEmbla}
            controlsOffset={isMobile ? 20 : 60}
            controlSize={41}
            onSlideChange={onSlideChange}
        >
            {fileList.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <FileItem key={index} file={item} active={index === currentIndex} />
            ))}
        </StyledCarousel>
    )
}
