import { IconFont } from '@byecode/ui'
import { Checkbox } from '@mantine/core'
import cls from 'classnames'
import React, { useCallback } from 'react'
import styled from 'styled-components'

export interface AdvancedTableCheckerProps {
    index?: number
    checked?: boolean
    checkable?: boolean
    showOpenerIcon?: boolean
    className?: string
    isStripe?: boolean
    isRecordSelecting?: boolean
    onChange?: (checked: boolean) => void
    onRecordOpen?: () => void
}

const SCxAdvancedTableRecordIndex = styled.p`
    position: absolute;
    color: var(--color-gray-400);
    opacity: 1;
`

const SCxAdvancedTableRecordCheckerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    opacity: 0;
`

const SCxAdvancedTableChecker = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 59px;
    height: 100%;
`

const SCxAdvancedTableExtra = styled.div`
    position: absolute;
    right: 4px;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
`

const SCxAdvancedTableOpener = styled(IconFont)`
    cursor: pointer;
    z-index: 1;
`

const SCxIcon = styled(IconFont)`
    font-size: 18px;
    color: var(--color-black);
`

const SCxAdvancedTableCheckerContent = styled.div<{ isStripe?: boolean }>`
    position: relative;
    width: 100%;
    background-color: ${({ isStripe }) => (isStripe ? 'var(--color-gray-50)' : 'none')};
`

export const SCxAdvancedTableCheckerWrapper = styled.div`
    position: sticky;
    left: 0;
    bottom: -1px;
    z-index: 8;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 41px;
    width: 60px;
    flex-shrink: 0;
    background: var(--color-white);
    border-right: 1px solid var(--color-gray-200);
    border-bottom: 1px solid var(--color-gray-200);
    font-size: var(--font-size-normal);

    &:hover {
        ${SCxAdvancedTableExtra} {
            opacity: 1;
        }
    }

    &.table-checkable {
        &.checked,
        &:not(.table-checked):hover {
            ${SCxAdvancedTableRecordCheckerWrapper} {
                opacity: 1;
            }
            ${SCxAdvancedTableRecordIndex} {
                opacity: 0;
            }
            ${SCxIcon} {
                display: none;
            }
        }
    }
`

export const SCXCheckBox = styled(Checkbox)`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    [type='checkbox']:checked {
        border-color: var(--color-app-main);
        background-color: var(--color-app-main);
    }
`

export const AdvancedTableChecker: React.FC<AdvancedTableCheckerProps> = ({
    index,
    showOpenerIcon,
    checked,
    checkable,
    className,
    isStripe,
    isRecordSelecting,
    onChange,
    onRecordOpen
}) => {
    const handleChange = useCallback(
        (ev: React.MouseEvent | React.ChangeEvent) => {
            ev.stopPropagation()
            if (!checkable) {
                return
            }
            onChange?.(!checked)
        },
        [checkable, checked, onChange]
    )
    return (
        <SCxAdvancedTableCheckerWrapper className={cls({ checked, 'table-checkable': checkable }, className)}>
            <SCxAdvancedTableCheckerContent isStripe={isStripe}>
                <SCxAdvancedTableChecker onClick={handleChange}>
                    {index === undefined ? (
                        <SCxIcon type="PropertyNumber" />
                    ) : (
                        <SCxAdvancedTableRecordIndex>{index + 1}</SCxAdvancedTableRecordIndex>
                    )}
                    <SCxAdvancedTableRecordCheckerWrapper style={isRecordSelecting ? { opacity: 1 } : {}}>
                        <SCXCheckBox size="xs" checked={checked} onChange={handleChange} />
                    </SCxAdvancedTableRecordCheckerWrapper>
                </SCxAdvancedTableChecker>
                {showOpenerIcon && (index || index === 0) && (
                    <SCxAdvancedTableExtra>
                        <SCxAdvancedTableOpener size={16} type="Open" onClick={onRecordOpen} />
                    </SCxAdvancedTableExtra>
                )}
            </SCxAdvancedTableCheckerContent>
        </SCxAdvancedTableCheckerWrapper>
    )
}
