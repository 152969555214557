import type { BaseViewField, FieldProtocol, HighLightConditions, SimpleTextValue } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import React, { useMemo } from 'react'

import { UserGroupItemPreviewer, UserGroupListPreviewer } from '../FieldValuePreviewer/UserGroupValuePreviewer'
import { CellHighlighter } from '../Highlighter'

interface UserGroupPreviewerProps {
    field: FieldProtocol
    children?: React.ReactNode
    data?: SimpleTextValue | SimpleTextValue[]
    width?: number
    isWrap?: boolean
    styleConditions?: HighLightConditions
}

export const UserGroupValuePreviewer: React.FC<UserGroupPreviewerProps> = ({
    field,
    data = '',
    width = 160,
    isWrap = true,
    styleConditions
}) => {
    const { id: fieldId, type } = field

    const value = useMemo(() => {
        if (Array.isArray(data)) {
            return data.map(String)
        }
        return String(data)
    }, [data])

    const content = useMemo(() => {
        if (!data) {
            return ''
        }
        if (Array.isArray(data)) {
            return <UserGroupListPreviewer data={data} width={width} isWrap={isWrap} />
        }
        const id = nanoid()
        return <UserGroupItemPreviewer id={id} data={data} isLast isWrap={isWrap} />
    }, [data, isWrap, width])

    return (
        <>
            <CellHighlighter fieldId={fieldId} type={type} value={value} conditions={styleConditions} />
            {content}
        </>
    )
}
