import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import { FloatingOverlay, FloatingPortal } from '@floating-ui/react'
import { getDaysInMonth, lightFormat } from 'date-fns'
import React, { forwardRef, useLayoutEffect, useMemo, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { IconFont } from '../../IconFont'
import { Input } from '../../Input'
import type { DatePickerProps } from '../DatePicker'
import { MobileDatePickerView } from './MobileDatePickerView'

export interface MobileDatePickerProps extends Omit<DatePickerProps, 'popoverProps'> {
    target?: HTMLElement | null
}

export const MobileDatePicker = forwardRef<HTMLInputElement, MobileDatePickerProps>((props, ref) => {
    const {
        styles,
        classNames,
        unstyled,

        target,

        value,
        defaultValue,
        onChange,
        format = 'yyyy-MM-dd HH:mm:ss',
        inputRender,

        // panel control props
        disabled,
        date,
        defaultDate,
        onDateChange,
        panelDate,
        defaultPanelDate,
        onPanelDateChange,
        mode,
        defaultMode,
        onModeChange,

        // panel settings props
        showTime = true,
        disableSecond,
        firstDayOfWeek,
        dayRowsCount,
        HeaderComponent,
        FooterComponent,
        CellComponent,
        onPrevYear,
        onPrevMonth,
        onNextYear,
        onNextMonth,

        ...inputProps
    } = props

    const [_value, _setValue] = useUncontrolled({
        value,
        defaultValue,
        onChange
    })

    const valueString = _value && lightFormat(_value, format)

    const [opened, setOpened] = useState(false)

    return (
        <>
            {inputRender ? (
                inputRender({
                    value: valueString,
                    onPointerDown: () => {
                        setOpened(true)
                    }
                })
            ) : (
                <Input
                    prefix={<IconFont type="PropertyCalendar" />}
                    value={valueString}
                    readOnly
                    disabled={disabled}
                    onPointerDown={() => {
                        setOpened(true)
                    }}
                    {...inputProps}
                />
            )}

            {/* @todo 暂时这样写，等bui的modal组件完善后替换 */}
            {opened && (
                <FloatingPortal root={target}>
                    <FloatingOverlay
                        style={{ zIndex: 200, backgroundColor: 'rgba(0,0,0,0.6)' }}
                        onPointerDown={() => setOpened(false)}
                        lockScroll
                    />
                    <div
                        style={{
                            position: 'fixed',
                            zIndex: 200,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            background: '#fff',
                            borderRadius: '4px 4px 0px 0px'
                        }}
                    >
                        <MobileDatePickerView value={_value} showTime={showTime} disableSecond={disableSecond} onConfirm={_setValue} onCancel={() => setOpened(false)} />
                    </div>
                </FloatingPortal>
            )}
        </>
    )
})
