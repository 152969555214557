import { IconFont } from '@byecode/ui'
import type { Field, RecordLikeProtocol } from '@lighthouse/core'
import type { VirtualItem, Virtualizer } from '@tanstack/react-virtual'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { useApplicationContext } from '../../../contexts'
import { mobileRowGap } from '../constant'
import { useFieldSelectTableContext } from '../FiledSelectTableContext'
import { TableIndex } from '../TableIndex'
import TableValue from '../TableValue'

interface TableRecordProps {
    record: RecordLikeProtocol
    value: string[] | string
    index: number
    columns: Field[]
    virtualRow: VirtualItem
    columnVirtualizer: Virtualizer<HTMLDivElement, Element>
    position?: 'left' | 'right'
    tableWidth?: number
    stickyLeft?: number
    isMobile: boolean
    onRecordClick?: (recordId: string) => void
}

export const SCxTableRecord = styled.div`
    display: flex;
    /* width: max-content; */
    width: 100%;
    min-width: 100%;
    padding-bottom: 8px;
    cursor: pointer;
`

export const SCxTableRow = styled.div``

export const SCxTableRowWrapper = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    padding: 8px 0;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    background-color: #fff;
    /* overflow: hidden; */
`

export const SCxTableRowLeftFill = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
`
export const SCxTableRowRightFill = styled.div`
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
`
export const SCxIcon = styled(IconFont)`
    font-size: 12px;
`

export const TableRecordMobile: React.FC<TableRecordProps> = ({
    record,
    index,
    columns,
    virtualRow,
    value,
    isMobile,
    stickyLeft = 0,
    position = 'right',
    columnVirtualizer
}) => {
    const { id, content, disabled } = record
    const { personOptions } = useApplicationContext()
    const { onRowClick } = useFieldSelectTableContext()
    const isChecked = useMemo(() => value.includes(id), [id, value])

    const allVirtualizer = columnVirtualizer.getVirtualItems()

    const firstField = useMemo(() => columns[0], [columns])

    const checkBoxStyle: React.CSSProperties = useMemo(() => (position === 'left' ? { left: 0, order: -1 } : { right: 1 }), [position])

    return (
        <SCxTableRecord
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: `${virtualRow?.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
                paddingBottom: `${mobileRowGap}px`,
                cursor: disabled ? 'not-allowed' : 'pointer'
            }}
            key={id}
            onClick={() => !disabled && onRowClick?.(id)}
        >
            <SCxTableRowWrapper>
                <SCxTableRowLeftFill>
                    <SCxTableRow>
                        <TableValue
                            key={firstField.id}
                            field={firstField}
                            data={content[firstField.id]?.value}
                            isBold
                            style={{
                                position: 'sticky',
                                left: stickyLeft,
                                top: 0,
                                width: isMobile ? 'calc(100% - 40px)' : '100%',
                                height: `35px`
                            }}
                        />
                    </SCxTableRow>
                    <SCxTableRow>
                        {allVirtualizer.map((virtualColumn, index) => {
                            const field = columns?.[index + 1]
                            if (!field) {
                                return null
                            }
                            const { id: cId } = field
                            const cData = content[cId]
                            return (
                                <TableValue
                                    key={cId}
                                    field={field}
                                    data={cData?.value}
                                    personOptions={personOptions}
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        width: `${virtualColumn.size}px`,
                                        height: `${(virtualRow.size - mobileRowGap) / 2 - 4}px`,
                                        transform: `translateX(${virtualColumn.start}px)`
                                    }}
                                />
                            )
                        })}
                    </SCxTableRow>
                </SCxTableRowLeftFill>
                <SCxTableRowRightFill
                    style={{
                        position: 'sticky',
                        ...checkBoxStyle
                    }}
                >
                    <TableIndex
                        recordId={id}
                        type="checkbox"
                        checked={isChecked}
                        disabled={disabled}
                        style={{
                            height: '100%',
                            backgroundColor: 'var(--color-white)'
                        }}
                        onRecordClick={onRowClick}
                        index={index}
                    />
                </SCxTableRowRightFill>
            </SCxTableRowWrapper>
        </SCxTableRecord>
    )
}
