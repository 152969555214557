import { IconFont, singleTextEllipsis } from '@byecode/ui'
import type React from 'react'
import styled, { css } from 'styled-components'

export const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
    /* 灿白要求padding 0 */
    padding: 0;
    display: flex;
    align-items: center;
    border-radius: inherit;
    overflow: hidden;
    .slider-list {
        height: 100%;
    }

    @keyframes scaleAnimation {
        0% {
            opacity: 0.5 !important;
            transform: scale(0.9) rotateX(0deg) rotateY(0deg) translateZ(0px);
        }
        100% {
            opacity: 1 !important;
            transform: none;
        }
    }
    .fileSlide {
        opacity: 0.5 !important;
        transform: scale(0.9) rotateX(0deg) rotateY(0deg) translateZ(0px);
        transition: all 0.6s ease;
    }

    .active {
        opacity: 1 !important;
        transform: none;
        transition: none;
    }

    .animation.active {
        animation: scaleAnimation 0.6s ease 1;
    }

    .slider-control-centerright {
        margin-right: 23px;
    }

    .slider-control-centerleft {
        margin-left: 23px;
    }

    @media (any-hover: hover) {
        &:hover {
            .slider-control-centerright,
            .slider-control-centerleft {
                opacity: 1;
            }
        }
    }

    .slider-control-centerright,
    .slider-control-centerleft {
        background: rgba(102, 102, 102, 0.6);
        font-size: 32px;
        border: none;
        border-radius: 100%;
        box-shadow: none;
        width: 32px;
        height: 32px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0;

        button {
            background: transparent !important;
            display: flex;
            align-items: center;

            iconpark-icon {
                /* transform: scaleY(0.75); */
                transform-origin: center center;
            }
        }
    }

    .dotContainer {
        padding: 4px !important;
        background: rgba(0, 0, 0, 0.3);
        gap: 8px;
        border-radius: 38px;
        top: -12px !important;

        .active {
            &::before {
                background: var(--color-white) !important;
            }
        }

        .dot {
            position: relative;
            width: 8px;
            height: 8px;

            &::before {
                position: absolute;
                inset: 0;
                content: '';
                background: rgba(255, 255, 255, 0.5);
                border-radius: 100%;
            }

            svg {
                display: none;
            }
        }
    }
`
export const ImageTitle = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 0 10px;
    color: var(--color-white);
    font-size: var(--font-size-normal);
    height: 37px;
    line-height: 37px;
    background: rgba(56, 56, 56, 0.4);
    backdrop-filter: blur(1px);
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    ${singleTextEllipsis}
`

export const ImageContent = styled.div<Pick<React.CSSProperties, 'borderRadius'>>`
    position: relative;
    user-select: auto;
    width: 100%;
    height: 100%;
    max-width: 100%;
    min-width: 10px;
    display: flex;
    align-items: center;
    overflow: hidden;
    ${({ borderRadius }) => css`
        border-radius: ${borderRadius};
    `}
`

export const ImageWrapper = styled.div`
    width: 100%;
    height: 100%;
`

export const Icon = styled(IconFont)`
    font-size: var(--font-size-base);
`

export const MImage = styled.img<Pick<React.CSSProperties, 'aspectRatio' | 'objectFit' | 'borderRadius'>>`
    display: block;
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    /* cursor: pointer; */
    width: 100%;
    height: 100%;
    overflow-clip-margin: content-box;
    overflow: clip;
    -webkit-user-drag: none;

    ${({ aspectRatio, objectFit, borderRadius }) => css`
        aspect-ratio: ${aspectRatio};
        object-fit: ${objectFit};
    `}
`

export const FileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const FileName = styled.div`
    font-size: var(--font-size-base);
    text-align: center;
    color: var(--color-black);
`
