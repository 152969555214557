import type { QuaggaJSResultObject } from '@ericblade/quagga2'
import Quagga from '@ericblade/quagga2'
import Compressor from 'compressorjs'
import type { QRCode } from 'jsqr'
import jsqr from 'jsqr'
import { max } from 'rambda'
import React, { useCallback, useRef, useState } from 'react'
import { useMount } from 'react-use'

import { Box } from '../Box'
import { IconFont } from '../IconFont'
import { Loading } from '../Loading'
import { Text } from '../Text'
import { useStyles } from './Scanner.styles'
import type { ScanCode, ScannerOption } from './Scanner.types'

interface ScannerProps {
    // facingMode: 'user' | 'environment'
    option?: ScannerOption
    onSuccess?: (v: string) => void
    onError?: (err: string) => void
    onClose?: () => void
}

const codeMap: Record<ScanCode, string> = { barCode: '条形码', qrCode: '二维码' }

const defaultScanType: ScanCode[] = ['barCode', 'qrCode']

export const Scanner = React.forwardRef<HTMLDivElement, ScannerProps>(({ option, onError, onSuccess, onClose }, ref) => {
    const { facingMode = 'environment', enableUpload = true, scanType = defaultScanType } = option ?? {}
    const { classes } = useStyles({}, { name: 'scanner' })
    const videoRef = useRef<HTMLVideoElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    // 获取摄像头权限loading
    const [loading, setLoading] = useState(true)
    // 是否开启了闪光灯
    const [torch, setTorch] = useState(false)
    // 是否可以使用摄像头权限
    const [isUse, setIsUse] = useState(true)
    // 识别中loading
    const [recognizing, setRecognizing] = useState(false)
    // 上传的图片是否识别失败
    const [isFailImageScan, setIsFailImageScan] = useState(false)

    const handleError = useCallback(
        (err: string) => {
            onError?.(err)
        },
        [onError]
    )

    const decodeBarImage = useCallback((url: string) => {
        return new Promise<QuaggaJSResultObject>((resolve, reject) => {
            Quagga.decodeSingle(
                {
                    inputStream: {
                        size: 1024, // 这里指定图片的大小，需要自己测试一下
                        singleChannel: false
                    },
                    locator: {
                        patchSize: 'medium',
                        halfSample: false
                    },
                    numOfWorkers: 1,
                    decoder: {
                        readers: [
                            {
                                format: 'code_128_reader', // ean_reader 这里指定ean条形码，就是国际13位的条形码   code39    code_128
                                config: {
                                    supplements: []
                                }
                            }
                        ]
                    },
                    // readers: ['code_128_reader'],
                    locate: true,
                    src: url
                },
                result => {
                    // let code = result.codeResult.code
                    resolve(result)
                }
            )
        })
    }, [])
    const decodeQrImage = useCallback((imageData: Uint8ClampedArray, width: number, height: number) => {
        return new Promise<QRCode | null>((resolve, reject) => {
            const result = jsqr(imageData, width, height)
            resolve(result)
        })
    }, [])

    const decodeImage = useCallback(
        async (imageData: Uint8ClampedArray, fileUrl: string, width: number, height: number) => {
            if (scanType.includes('qrCode')) {
                const qrResult = await decodeQrImage(imageData, width, height)
                const resultText = qrResult?.data
                if (resultText) {
                    onSuccess?.(resultText)
                    return resultText
                }
            }
            if (scanType.includes('barCode')) {
                const result = await decodeBarImage(fileUrl)
                if (result.codeResult.code) {
                    const { code } = result.codeResult
                    return code
                }
            }
            return null
        },
        [decodeBarImage, decodeQrImage, onSuccess, scanType]
    )

    const decodeFromImageData = useCallback(
        (imageData: Uint8ClampedArray, fileUrl: string, width: number, height: number) => {
            return new Promise<string | null>((resolve, reject) => {
                decodeImage(imageData, fileUrl, width, height)
                    .then(result => {
                        if (result) {
                            onClose?.()
                            onSuccess?.(result)
                            resolve(result)
                        }
                        console.log('🚀 ~ Scanner ~ result:', result)
                    })
                    .catch(error => {
                        console.log('🚀 ~ Scanner ~ error:', error)
                        reject(JSON.stringify(error))
                    })
                    .finally(() => {
                        setRecognizing(false)
                        resolve(null)
                    })
            })
        },
        [decodeImage, onClose, onSuccess]
    )

    const onTick = useCallback(async () => {
        if (!videoRef.current || recognizing) {
            return
        }
        setRecognizing(true)
        await new Promise<string | null>((resolve, reject) => {
            if (!videoRef.current || isFailImageScan) {
                return
            }
            const video = videoRef.current
            const rect = videoRef.current.getBoundingClientRect()
            const canvas = document.createElement('canvas')
            const width = max(rect?.width, 1024) || 1000
            const height = max(rect?.height, 1024) || 1000
            canvas.style.display = 'none'
            const ctx = canvas.getContext('2d')
            canvas.width = width
            canvas.height = height
            if (!ctx) {
                return
            }
            ctx.drawImage(video, 0, 0, width, height)
            const imageData = ctx.getImageData(0, 0, width, height)
            canvas.toBlob(blob => {
                if (blob) {
                    canvas.remove()
                    const fileUrl = window.URL.createObjectURL(blob)
                    decodeFromImageData(imageData.data, fileUrl, width, height)
                        .then(resolve)
                        .catch(err => {
                            resolve('')
                        })
                }
            })
        })
        setTimeout(onTick, 800)
    }, [decodeFromImageData, isFailImageScan, recognizing])

    const onScannerImg = useCallback(
        (file: File | Blob) => {
            return new Promise((resolve, reject) => {
                const fileUrl = window.URL.createObjectURL(file)
                if (fileUrl) {
                    const imgEle = new Image()
                    imgEle.src = fileUrl
                    const canvas = document.createElement('canvas')
                    const ctx = canvas.getContext('2d')
                    if (!ctx) {
                        reject(new Error('getContext 不支持'))
                        return
                    }
                    imgEle.addEventListener('load', () => {
                        const width = max(imgEle?.width, 1024) || 1000
                        const height = max(imgEle?.height, 1024) || 1000
                        canvas.width = width
                        canvas.height = height
                        ctx.drawImage(imgEle, 0, 0, width, height)
                        const imageData = ctx.getImageData(0, 0, width, height)
                        decodeFromImageData(imageData.data, fileUrl, width, height)
                            .then(resolve)
                            .finally(() => {
                                imgEle.remove()
                                canvas.remove()
                                window.URL.revokeObjectURL(fileUrl)
                            })
                            .catch(() => {
                                setIsFailImageScan(true)
                                handleError('解析失败')
                            })
                    })
                    imgEle.addEventListener('error', () => {
                        imgEle.remove()
                        canvas.remove()
                        window.URL.revokeObjectURL(fileUrl)
                        reject(new Error('图片加载失败'))
                    })
                } else {
                    reject(new Error('Failed to convert URL to Base64.'))
                }
            })
        },
        [decodeFromImageData, handleError]
    )

    const openCamera = useCallback(() => {
        // 初始化
        setLoading(true)
        try {
            navigator.mediaDevices
                .getUserMedia({
                    video: { facingMode, width: { min: 1024, ideal: 1280, max: 1920 }, height: { min: 576, ideal: 720, max: 1080 } }
                })
                .then(async mediaStream => {
                    if (!videoRef.current) {
                        return
                    }
                    const video = videoRef.current
                    video.srcObject = mediaStream
                    // const track =  mediaStream.getVideoTracks()[0]
                    await video.play()
                    onTick()
                    setLoading(false)
                })
                .catch(() => {
                    setIsUse(false)
                    setLoading(false)
                    // 未检测到摄像头
                    handleError('未检测到摄像头')
                })
        } catch {
            setIsUse(false)
            setLoading(false)
        }
    }, [facingMode, handleError, onTick])

    const openTorch = useCallback(() => {
        if (!videoRef.current || !videoRef.current?.srcObject) {
            return
        }
        const stream = videoRef.current?.srcObject
        if (stream instanceof MediaStream) {
            setTorch(v => {
                stream?.getVideoTracks()[0].applyConstraints({ advanced: [{ torch: !v } as MediaTrackConstraintSet] })
                return !v
            })
        }
    }, [])

    const clickHandler = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        inputRef.current?.click()
    }, [])

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { files } = e.target as HTMLInputElement
            if (!files) {
                return
            }
            const file = files[0]
            if (!['image/png', 'image/jpg', 'image/gif', 'image/jpeg'].includes(file.type)) {
                handleError('请上传正确的图片')
                return
            }
            if (file.size > 20 * 1024 * 1024) {
                handleError('请选择20M以内的图片')
                return
            }
            if (typeof FileReader === 'function') {
                setRecognizing(true)
                // eslint-disable-next-line no-new
                new Compressor(file, {
                    quality: 0.8,
                    strict: false,
                    convertSize: 50000,
                    success: result => {
                        try {
                            onScannerImg(result)
                        } catch (err) {
                            handleError(JSON.stringify(err))
                        }
                    },
                    error(err) {
                        handleError(err.message)
                    }
                })
            } else {
                handleError('对不起，您的浏览器暂不支持此功能！')
            }

            if (e.target) {
                ;(e.target as HTMLInputElement).value = ''
            }
        },
        [handleError, onScannerImg]
    )

    useMount(() => {
        openCamera()
    })

    return (
        <Box className={classes.root} ref={ref}>
            <button type="button" className={classes.closeBtn} onClick={onClose}>
                <IconFont type="Close" color="#fff" size={16} />
            </button>
            {enableUpload && (
                <>
                    <button type="button" className={classes.uploadBtn} onClick={clickHandler}>
                        <IconFont type="Image" color="#fff" size={24} />
                    </button>
                    <input ref={inputRef} onChange={handleChange} style={{ display: 'none' }} type="file" accept="image/*" />
                </>
            )}

            {recognizing && <Loading description="识别中" />}
            {loading && <Loading description="加载相机中" />}
            {isFailImageScan && (
                <Box
                    className={classes.fail}
                    onClick={() => {
                        setIsFailImageScan(false)
                        onTick()
                    }}
                >
                    <Text color="var(--color-white)" size={20} lineHeight="28px">
                        未发现{scanType.map(v => codeMap[v]).join('/')}
                    </Text>
                    <Text color="var(--color-gray-500)" size={14} lineHeight="22px">
                        轻触屏幕以继续扫描
                    </Text>
                </Box>
            )}

            {isUse ? (
                <>
                    <video className={classes.video} playsInline ref={videoRef} />
                    <button type="button" className={classes.flashBtn} onClick={openTorch}>
                        <IconFont type={torch ? 'Lightningforbidden' : 'Lightning'} color="#fff" size={16} />
                    </button>
                    <Box className={classes.scanning} />
                </>
            ) : (
                <Box className={classes.error}>
                    <ul>
                        <li>相机权限被拒绝，请尝试如下操作：</li>
                        <li>刷新页面后重试；</li>
                        <li>在系统中检测当前App或浏览器的相机权限是否被禁用；</li>
                        <li>如果依然不能体验，建议在微信中打开链接；</li>
                    </ul>
                </Box>
            )}
        </Box>
    )
})
