import type { ApplicationSettingThemeColor } from '@lighthouse/core'
import { ApplicationSetting } from '@lighthouse/core'

/**
 * 颜色列表
 */
export const COLORS = [
    { name: 'white', value: '#ffffff', color: '--color-white', darkColor: '--color-white', label: '白' },
    { name: 'crimson', value: '#a91e1e', color: '--color-crimson-light', darkColor: '--color-crimson', label: '朱緋' },
    { name: 'lightonion', value: '#c45b1c', color: '--color-lightonion-light', darkColor: '--color-lightonion', label: '浅葱' },
    { name: 'drygrass', value: '#8b6c1d', color: '--color-drygrass-light', darkColor: '--color-drygrass', label: '枯草' },
    { name: 'teal', value: '#187b34', color: '--color-teal-light', darkColor: '--color-teal', label: '紺藍' },
    { name: 'vineviolet', value: '#6e21ba', color: '--color-vineviolet-light', darkColor: '--color-vineviolet', label: '藤紫' },
    { name: 'wakaba', value: '#0f58bd', color: '--color-wakaba-light', darkColor: '--color-wakaba', label: '若叶' },
    {
        name: 'steamedchestnuts',
        value: '#a12b86',
        color: '--color-steamedchestnuts-light',
        darkColor: '--color-steamedchestnuts',
        label: '蒸栗'
    },
    { name: 'thingreen', value: '#08979c', color: '--color-thingreen-light', darkColor: '--color-thingreen', label: '薄青' }
]

export const COLORS_MAP: Record<string, string> = {
    white: '--color-black',
    crimson: '--color-crimson',
    lightonion: '--color-lightonion',
    drygrass: '--color-drygrass',
    teal: '--color-teal',
    vineviolet: '--color-vineviolet',
    wakaba: '--color-wakaba',
    steamedchestnuts: '--color-steamedchestnuts',
    thingreen: '--color-thingreen',
    // 新版颜色
    dustRed: '--color-dustRed',
    volcano: '--color-volcano',
    sunsetOrange: '--color-sunsetOrange',
    calendulaGold: '--color-calendulaGold',
    sunriseYellow: '--color-sunriseYellow',
    lime: '--color-lime',
    polarGreen: '--color-polarGreen',
    cyan: '--color-cyan',
    daybreakBlue: '--color-daybreakBlue',
    geekBlue: '--color-geekBlue',
    goldenPurple: '--color-goldenPurple',
    magenta: '--color-magenta'
}

export const LIGHT_COLORS_MAP: Record<string, string> = {
    white: '--color-white',
    crimson: '--color-crimson-light',
    lightonion: '--color-lightonion-light',
    drygrass: '--color-drygrass-light',
    teal: '--color-teal-light',
    vineviolet: '--color-vineviolet-light',
    wakaba: '--color-wakaba-light',
    steamedchestnuts: '--color-steamedchestnuts-light',
    thingreen: '--color-thingreen-light',
    // 新版颜色
    dustRed: '--color-dustRed-light',
    volcano: '--color-volcano-light',
    sunsetOrange: '--color-sunsetOrange-light',
    calendulaGold: '--color-calendulaGold-light',
    sunriseYellow: '--color-sunriseYellow-light',
    lime: '--color-lime-light',
    polarGreen: '--color-polarGreen-light',
    cyan: '--color-cyan-light',
    daybreakBlue: '--color-daybreakBlue-light',
    geekBlue: '--color-geekBlue-light',
    goldenPurple: '--color-goldenPurple-light',
    magenta: '--color-magenta-light'
}

/**
 * 新版颜色列表
 */
export const DARK_COLORS = [
    { name: 'white', color: '--color-white', darkColor: '--color-white', label: '白' },
    { name: 'dustRed', color: '--color-dustRed-light', darkColor: '--color-dustRed', label: '薄暮' },
    { name: 'volcano', color: '--color-volcano-light', darkColor: '--color-volcano', label: '火山' },
    { name: 'sunsetOrange', color: '--color-sunsetOrange-light', darkColor: '--color-sunsetOrange', label: '日暮' },
    { name: 'calendulaGold', color: '--color-calendulaGold-light', darkColor: '--color-calendulaGold', label: '金盏花' },
    { name: 'sunriseYellow', color: '--color-sunriseYellow-light', darkColor: '--color-sunriseYellow', label: '日出' },
    { name: 'lime', color: '--color-lime-light', darkColor: '--color-lime', label: '青柠' },
    { name: 'polarGreen', color: '--color-polarGreen-light', darkColor: '--color-polarGreen', label: '极光绿' },
    { name: 'cyan', color: '--color-cyan-light', darkColor: '--color-cyan', label: '明青' },
    { name: 'daybreakBlue', color: '--color-daybreakBlue-light', darkColor: '--color-daybreakBlue', label: '拂晓蓝' },
    { name: 'geekBlue', color: '--color-geekBlue-light', darkColor: '--color-geekBlue', label: '极客蓝' },
    { name: 'goldenPurple', color: '--color-goldenPurple-light', darkColor: '--color-goldenPurple', label: '酱紫' },
    { name: 'magenta', color: '--color-magenta-light', darkColor: '--color-magenta', label: '法师洋红' }
]

export const PRESET_PALETTES: Record<string, string> = {
    red: '#DB3553',
    volcano: '#FF537F',
    orange: '#F0B4B6',
    gold: '#EF8C0B',
    yellow: '#EEBB17',
    lime: '#DAA76B',
    green: '#96B25A',
    cyan: '#6EC395',
    blue: '#00AA93',
    geekblue: '#6492B7',
    purple: '#0D19FC',
    magenta: '#7A12FF',
    grey: '#08324E'
}

// export const PRESET_PALETTES: ApplicationSettingThemeColor[] = [
//     {
//         id: 'red',
//         label: '主题色',
//         value: '#DB3553'
//     },
//     {
//         id: 'volcano',
//         label: '主题色',
//         value: '#FF537F'
//     },
//     {
//         id: 'orange',
//         label: '主题色',
//         value: '#F0B4B6'
//     },
//     {
//         id: 'gold',
//         label: '主题色',
//         value: '#EF8C0B'
//     },
//     {
//         id: 'yellow',
//         label: '主题色',
//         value: '#EEBB17'
//     },
//     {
//         id: 'lime',
//         label: '主题色',
//         value: '#DAA76B'
//     },
//     {
//         id: 'green',
//         label: '主题色',
//         value: '#96B25A'
//     },
//     {
//         id: 'cyan',
//         label: '主题色',
//         value: '#6EC395'
//     },
//     {
//         id: 'blue',
//         label: '主题色',
//         value: '#00AA93'
//     },
//     {
//         id: 'geekblue',
//         label: '主题色',
//         value: '#6492B7'
//     },
//     {
//         id: 'purple',
//         label: '主题色',
//         value: '#0D19FC'
//     },
//     {
//         id: 'magenta',
//         label: '主题色',
//         value: '#7A12FF'
//     },
//     {
//         id: 'grey',
//         label: '主题色',
//         value: '#08324E'
//     }
// ]
